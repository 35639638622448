import React, {
  useState, useEffect, useCallback, useRef,
} from 'react';
import _ from 'lodash';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Button } from 'react-bootstrap'
import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import Tooltip from '@mui/material/Tooltip';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Form from 'react-bootstrap/Form';
import { useGraphQL } from '../providers/graphql';
import { useAuth } from '../providers/auth';
import DeleteAccount from './components/DeleteAccount'
import { BRAND_COLORS } from '../constants';

const AccountSettings = () => {
  const AccountPreferences = 'Account Preferences'
  const Profile = 'Profile'

  const DrawerListItems = [
    {
      text: AccountPreferences,
      icon: <ManageAccountsIcon fontSize="small" style={{ minWidth: '40px' }} />,
    },
    {
      text: Profile,
      icon: <AccountCircleIcon fontSize="small" style={{ minWidth: '40px' }} />,
    },

  ]
  const [selected, setSelected] = useState(AccountPreferences)
  const [sharePersonalData, setSharePersonalData] = useState(false)
  const [preferences, setPreferences] = useState({})
  const [editmode, setEditmode] = useState(false)
  const [open, setOpen] = useState(false)
  const [showSpinner, setShowSpinner] = useState(false)
  const [userId, setUserId] = useState('')
  const userNameObject = useRef()
  const [userInformation, setUserInformation] = useState({
    first_name: '',
    last_name: '',
    email: '',
  })
  const [disableEditProfileSave, setDisableEditProfileSave] = useState(true)
  const [displayMessage, setDisplayMessage] = useState('');
  const {
    getUserPreferences, updateUserPreferences, getUser, updateUserName, setRefreshUserName,
  } = useGraphQL();
  const [editProfileScreen, setEditProfileScreen] = useState(false)
  const { user } = useAuth();

  useEffect(() => {
    const getAccountPreference = async () => {
      try {
        const pref = await getUserPreferences(user.id);
        const preferences = pref.data.preferencesForUser.items[0]
        setPreferences(preferences)
        setSharePersonalData(preferences.sharePersonalData);
      // eslint-disable-next-line no-empty
      } catch (e) {
      }
    };
    if (!_.isEmpty(user)) {
      getAccountPreference();
    }
  }, [user, getUserPreferences]);
  const handleOnClick = (text) => {
    setSelected(text)
  }

  const cancelEdit = () => {
    setEditmode(false)
    setSharePersonalData(preferences.sharePersonalData)
  }

  const handleSaveUserName = async () => {
    const userInfoUpdated = await updateUserName(userId, userNameObject.current?.firstName, userNameObject.current?.lastName)
    // eslint-disable-next-line camelcase
    const { email, last_name, first_name } = userInfoUpdated.data.updateUser
    // eslint-disable-next-line camelcase
    setUserInformation({ last_name, first_name, email })
    userNameObject.current = {
      // eslint-disable-next-line camelcase
      firstName: first_name,
      // eslint-disable-next-line camelcase
      lastName: last_name,
    }
    setEditProfileScreen(false)
    setDisableEditProfileSave(true)
    const time = new Date().getTime()
    setRefreshUserName(time)
  }

  const saveChanges = async () => {
    setShowSpinner(true)
    try {
      const updatedPreferences = await updateUserPreferences({
        ...preferences,
        sharePersonalData,
      });
      setPreferences(updatedPreferences.data.updateUserPreferences);
      setDisplayMessage('success');
    } catch (error) {
      setDisplayMessage('error');
    }
    setOpen(true)
    setShowSpinner(false);
    setEditmode(false);
  }

  const userName = useCallback(async () => {
    const { id, name } = user
    try {
      const results = await getUser(id, name)
      // eslint-disable-next-line
      const {first_name, last_name, email } = results.data.getUser
      // eslint-disable-next-line
      setUserInformation({ first_name, last_name, email })
      userNameObject.current = {
        // eslint-disable-next-line camelcase
        firstName: first_name,
        // eslint-disable-next-line camelcase
        lastName: last_name,
      }
      setUserId(results.data.getUser.id)
    // eslint-disable-next-line no-empty
    } catch (error) {
    }
  }, [getUser, user])

  useEffect(() => {
    userName()
  }, [userName])

  const showUpdatePreferencesResult = (errorAlert) => {
    const message = {
      success: (
        <div className="AccountPreferences-SuccessMessage successText">
          Successfully saved account preferences
        </div>
      ),
      error: (
        <div className="AccountPreferences-ErrorMessage errorText">
          Please try again later
        </div>
      ),
      '': null,
    }
    return (
      <Stack sx={{ width: '100%' }} spacing={2}> {message[errorAlert]} </Stack>
    )
  }

  const disbaleSaveBtn = () => showSpinner || preferences.sharePersonalData === sharePersonalData

  const accountPreferencesSection = (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        marginLeft: '16px',
        marginTop: '16px',
        padding: '8px',
        minHeight: '400px',
      }}
    >
      <h4>Account Preferences</h4>
      <h5>Personal Data
        {
              editmode ? ''
                : (
                  <Tooltip title="Edit" placement="right" disableInteractive>
                    <ModeEditOutlinedIcon onClick={() => setEditmode(true)} className="editIcon" />
                  </Tooltip>
                )
          }
      </h5>
      <div>
        <FormControlLabel
          disabled={!editmode}
          label="I agree to share my personal data to enhance this platform."
          control={(
            <Checkbox
              color="secondary"
              value={sharePersonalData}
              checked={sharePersonalData}
              onChange={() => setSharePersonalData(!sharePersonalData)}
              sx={{
                paddingRight: '5px',
                color: '#C4C4C4',
                '&.Mui-checked': {
                  color: BRAND_COLORS.orange,
                },
                '&.Mui-disabled': {
                  color: '#C4C4C4',
                },
              }}
            />
              )}
        />
      </div>
      {
          editmode
            ? (
              <Stack direction="row" spacing={1}>
                {showSpinner && <CircularProgress size={30} style={{ color: BRAND_COLORS.orange }} display="inline" />}
                <Button
                  disabled={disbaleSaveBtn()}
                  size="small"
                  variant="contained"
                  className="accountSettings-saveChanges"
                  onClick={saveChanges}
                >Save Changes
                </Button>
                <Button
                  disabled={showSpinner}
                  size="small"
                  variant="text"
                  color="error"
                  className="accountSettings-cancelChanges"
                  onClick={() => cancelEdit()}
                >Cancel
                </Button>
              </Stack>
            ) : null
      }
      <Snackbar
        style={{ position: 'unset' }}
        open={open}
        autoHideDuration={2500}
        onClose={() => setOpen(false)}
        message={showUpdatePreferencesResult(displayMessage)}
      />
      <DeleteAccount preferences={preferences} />
    </Box>
  )

  const cancelEditProfile = () => {
    setEditProfileScreen(false)
    setDisableEditProfileSave(true)
    userNameObject.current = {
      firstName: userInformation.first_name,
      lastName: userInformation.last_name,
    }
  }

  const handleChangeName = (event) => {
    const { name, value } = event.target
    userNameObject.current = { ...userNameObject.current, ...{ [name]: value } }
    const { firstName, lastName } = userNameObject.current
    // eslint-disable-next-line camelcase
    const { first_name, last_name } = userInformation
    // eslint-disable-next-line camelcase
    if (firstName !== first_name || lastName !== last_name) {
      setDisableEditProfileSave(false)
    } else {
      setDisableEditProfileSave(true)
    }
  }
  const handleChangeProfileScreen = () => {
    setEditProfileScreen((prevScreen) => !prevScreen)
  }

  const newProfileSection = (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        marginLeft: '16px',
        marginTop: '16px',
        padding: '8px',
        minHeight: '400px',
      }}
    >
      <div style={{ margin: '12px', display: 'flex' }}>
        <h4>Profile Section</h4>
        <div style={{ fontSize: '22px', marginLeft: '6px', marginTop: '-4px' }}>
          { !editProfileScreen && (
          <Tooltip title="Edit" placement="right" disableInteractive>
            <ModeEditOutlinedIcon
              // eslint-disable-next-line no-unused-expressions
              onClick={handleChangeProfileScreen}
              data-testid="profile-information-save"
              sx={
                {
                  '&:hover': {
                    color: 'red',
                  },
                }
              }
            />
          </Tooltip>
          )}
        </div>
      </div>
      {editProfileScreen ? (

        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '12px' }}>
          <div style={{ display: 'flex' }}>
            <div style={{ fontSize: '20px', fontWeight: 'bold', width: '12%' }}>
              First Name:
            </div>
            <div style={{ marginLeft: '10px' }}>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Control data-testid="first-name-form" name="firstName" defaultValue={userNameObject.current?.firstName || ''} onChange={handleChangeName} />
              </Form.Group>
            </div>
          </div>
          <div style={{ display: 'flex' }}>
            <div style={{ fontSize: '20px', fontWeight: 'bold', width: '12%' }}>
              Last Name:
            </div>
            <div style={{ marginLeft: '10px' }}>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Control data-testid="last-name-form" name="lastName" defaultValue={userNameObject.current?.lastName || ''} onChange={handleChangeName} />
              </Form.Group>
            </div>
          </div>
          <div style={{ display: 'flex' }}>
            <div style={{ fontSize: '20px', fontWeight: 'bold', width: '12%' }}>
              Email:
            </div>
            <div style={{ fontSize: '18px', marginLeft: '10px' }}>
              {userInformation.email}
            </div>
          </div>
          <div style={{ marginTop: '10px' }}>
            <Button
              size="small"
              variant="contained"
              className="accountSettings-saveChanges"
              onClick={handleSaveUserName}
              data-testid="account-settings-profile-save"
              disabled={disableEditProfileSave}
            >Save Changes
            </Button>
            <Button
              size="small"
              variant="contained"
              className="accountSettings-cancelChanges"
              onClick={cancelEditProfile}
              style={{ marginLeft: '3px' }}
            >cancel
            </Button>

          </div>
        </div>
      ) : (
        <>
          <div style={{ display: 'flex' }}>
            <div style={{
              display: 'flex', width: '13%', marginLeft: '12px', flexDirection: 'column',
            }}
            >
              <div style={{ fontSize: '20px', fontWeight: 'bold', height: '40px' }}>
                First Name:
              </div>
              <div style={{ fontSize: '20px', fontWeight: 'bold', height: '40px' }}>
                Last Name:
              </div>
              <div style={{ fontSize: '20px', fontWeight: 'bold', height: '40px' }}>
                Email:
              </div>

            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ fontSize: '20px', height: '40px' }}>
                {userInformation.first_name}
              </div>
              <div style={{ fontSize: '20px', height: '40px' }}>
                {userInformation.last_name}
              </div>
              <div style={{ fontSize: '20px', height: '40px' }}>
                {userInformation.email}
              </div>
            </div>

          </div>
        </>
      )}

    </Box>
  )

  const sectionView = (sectionToSee) => {
    if (sectionToSee === AccountPreferences) {
      return accountPreferencesSection
    }
    return newProfileSection
  }

  return (
    <Box sx={{ display: 'flex' }} className="account-settings">
      <Drawer
        variant="permanent"
        sx={{
          width: '20%',
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            position: 'initial',
            borderRight: '1px solid rgba(0, 0, 0, 0.12)',
            minHeight: '100%',
          },
        }}
      >
        <List sx={{ marginTop: 2 }}>
          {DrawerListItems.map((item) => (
            <ListItem key={item.text} disablePadding onClick={() => handleOnClick(item.text)}>
              <ListItemButton selected={item.text === selected}>
                <ListItemIcon>
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
      { sectionView(selected) }

    </Box>
  )
}

export default AccountSettings
