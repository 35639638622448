import React, { useState } from 'react';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import Typography from '@mui/material/Typography';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/styles';
import { useHistory } from 'react-router-dom';
import { ProjectModel, TaskStore } from '@bryntum/gantt';
import moment from 'moment';
import CircularProgress from '@mui/material/CircularProgress';
import { useGraphQL } from '../../../providers/graphql';
import { useAuth } from '../../../providers/auth';
import { useSQS } from '../../../providers/sqs';
import { createMessageForSQS } from '../../utils.createMessage';
import { AUDIT_TRAIL_EVENT_TYPES } from '../../../constants';

const favElem = document.createElement('div');
favElem.classList.add('favoriteContainer');
favElem.id = 'favContainerOn';
favElem.innerHTML = '<svg class="MuiSvgIcon-root font-size-16" focusable="false" viewBox="0 0 24 24" aria-hidden="true" style="fill: rgb(248, 161, 49);"><path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path></svg>'

const favOffElem = document.createElement('div');
favOffElem.classList.add('favoriteContainer');
favOffElem.id = 'favContainerOff';
favOffElem.innerHTML = '<svg class="MuiSvgIcon-root font-size-16 startBdrIconCol" focusable="false" viewBox="0 0 24 24" aria-hidden="true" style="fill: rgb(248, 161, 49);"><path d="M22 9.24l-7.19-.62L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27 18.18 21l-1.63-7.03L22 9.24zM12 15.4l-3.76 2.27 1-4.28-3.32-2.88 4.38-.38L12 6.1l1.71 4.04 4.38.38-3.32 2.88 1 4.28L12 15.4z"></path></svg>'

const MILESTONES_MONTHS_RANGE = 6;

const LightTooltip = styled(({
  // eslint-disable-next-line camelcase
  className, off_hz, off_vt, ...props
}) => (
  <Tooltip
    disableInteractive
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    PopperProps={{
      popperOptions: {
        modifiers: [
          {
            name: 'offset',
            // eslint-disable-next-line camelcase
            options: { offset: [off_vt, off_hz] },
          },
        ],
      },
    }}
    classes={{
      popper: className,
    }}
  />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#FFFBEC',
    color: '#222222',
    border: '1px solid #FEE7D5',
    borderRadius: '2px',
    fontSize: '12px',
    top: '20px',
  },
}));

const emptyElem = document.createElement('input');
emptyElem.classList.add('b-filter-bar-field-input');
emptyElem.readOnly = true;
emptyElem.id = 'b-combo-1-input';

const FavoriteComponent = function ({
  record,
  // grid, row, column,
}) {
  const { addFavorite, removeFavorite } = useGraphQL();

  const { user } = useAuth();
  const { sendMessageToQueueForAudits } = useSQS()

  const auditFavoriteSelection = async (type) => {
    if (user && user.id) {
      const messageBody = await createMessageForSQS({
        // eventType: AUDIT_TRAIL_EVENT_TYPES.CHART_EXPORT,
        eventType: type,
        userId: user.id,
        projectId: record.originalData.projectCode,
        companyName: record.originalData.company,
      })
      sendMessageToQueueForAudits(messageBody)
    }
  }

  return (
    <div className="container mphs-text-align"> {/* added new class defined in mphs.css */}
      {record.favorite === false ? (
        <div className="justify-content-center">
          <StarBorderIcon
            style={{ fill: '#F8A131' }}
            className="font-size-16 startBdrIconCol"
            // eslint-disable-next-line
            onClick={() => {
              record.favorite = true;
              if (record.children) {
                record.children.map((ch) => ch.favorite = true)
              }
              addFavorite(user, record.originalData.projectCode)
              auditFavoriteSelection(AUDIT_TRAIL_EVENT_TYPES.FAVORITE_ADDED)
            }}
          />
        </div>
      ) : (
        <div className="justify-content-center mphs-text-align"> {/* added new class defined in mphs.css */}
          <StarIcon
            style={{ fill: '#F8A131' }}
            className="font-size-16"
            // eslint-disable-next-line
            onClick={() => {
              record.favorite = false;
              if (record.children) {
                record.children.map((ch) => ch.favorite = false)
              }
              removeFavorite(user, record.originalData.projectCode)
              auditFavoriteSelection(AUDIT_TRAIL_EVENT_TYPES.FAVORITE_REMOVED)
            }}
          />
        </div>
      )}
    </div>
  )
}

const NotificationComponent = function ({
  record,
  // grid, row, column,
}) {
  const {
    unsubscribeFromNotification, subscribeForNotification, updatingOptIns, setUpdatingOptIns,
  } = useGraphQL();
  const { user } = useAuth();
  const { sendMessageToQueueForAudits } = useSQS()
  const [loading, setLoading] = useState(false)

  const auditNotificationOptIn = async (type) => {
    if (user && user.id) {
      const messageBody = await createMessageForSQS({
        // eventType: AUDIT_TRAIL_EVENT_TYPES.CHART_EXPORT,
        eventType: type,
        userId: user.id,
        projectId: record.originalData.projectCode,
        companyName: record.originalData.company,
      })
      sendMessageToQueueForAudits(messageBody)
    }
  }

  return (
    <div className="container">
      {record.notification === false ? (
        <div className="justify-content-center mphs-text-align"> {/* added new class defined in mphs.css */}
          <NotificationsOutlinedIcon
            className="font-size-16"
            style={{ fill: '#056a7b' }}
            // eslint-disable-next-line
            onClick={async () => {
              if (updatingOptIns) {
                return null
              }
              setUpdatingOptIns(true)
              setLoading(true)
              await subscribeForNotification(user, record.originalData.projectCode)
              record.notification = true;
              if (record.children) {
                record.children.map((ch) => ch.notification = true)
              }
              setLoading(false)
              setUpdatingOptIns(false)
              auditNotificationOptIn(AUDIT_TRAIL_EVENT_TYPES.NOTIFICATION_OPT_IN)
            }}
          />
          {loading && <CircularProgress size={12} />}
        </div>
      ) : (
        <div className="justify-content-center mphs-text-align"> {/* added new class defined in mphs.css */}
          <NotificationsIcon
            style={{ fill: '#056a7b' }}
            className="font-size-16"
            // eslint-disable-next-line
            onClick={async () => {
              if (updatingOptIns) {
                return null
              }
              setUpdatingOptIns(true)
              setLoading(true)
              await unsubscribeFromNotification(user, record.originalData.projectCode)
              record.notification = false;
              if (record.children) {
                record.children.map((ch) => ch.notification = false)
              }
              auditNotificationOptIn(AUDIT_TRAIL_EVENT_TYPES.NOTIFICATION_OPT_OUT)
              setLoading(false)
              setUpdatingOptIns(false)
            }}
          />
          {loading && <CircularProgress size={12} />}
        </div>
      )}

    </div>
  )
}

const LinkComponent = function ({ value, className }) {
  const history = useHistory();
  // eslint-disable-next-line react/button-has-type
  return <button onClick={() => history.push(`/details/${value}`)} className={`btn-link stretched-link AvenirNextLTProRegular ${className} `}>{value}</button>;
}

const preferredAggregate = {
  kickoff: 'early',
  draft_proto: 'early',
  dosing_begins: 'early',
  dosing_ends: 'late',
  animal_term: 'late',
  draft_report: 'late',
}

const calculatePercentDone = (early, late) => {
  const now = new Date();
  if (late < now) {
    return 100
  }
  if (early > now) {
    return 0
  }
  const totalDays = late - early;
  const timeElapsed = now - early;
  return Math.ceil((timeElapsed / totalDays) * 100)
}

const calculateDuration = (early, late) => {
  const oneDay = 24 * 60 * 60 * 1000;
  return Math.round(Math.abs((late - early) / oneDay));
}

const getTitleForField = (field) => {
  const titleMap = new Map([
    ['draft_proto', 'Draft Protocol Sent'],
    ['dosing_begins', 'Dosing Start of First Model'],
    ['dosing_ends', 'Dosing End of Last Model'],
    ['animal_term', 'Termination of Last Model'],
    ['draft_report', 'Draft Report Sent'],
  ])
  return titleMap.get(field)
}

const parseMilestonesToGantt = (milestones, field, array, milestoneDetails) => {
  const { startDate, endDate } = getRelativeDateRange(MILESTONES_MONTHS_RANGE)
  const title = getTitleForField(field)
  const milestonesActualDate = milestones[field].actual
  const milestonesForecastDate = milestones[field].forecast
  if (milestonesActualDate) {
    const currentDate = new Date(milestonesActualDate)
    if (currentDate > startDate && currentDate < endDate) {
      array.push({
        ...milestoneDetails,
        name: title,
        startDate: milestonesActualDate,
        cls: 'green',
      })
    }
  }

  if (milestonesForecastDate) {
    const currentDate = new Date(milestonesForecastDate)
    if (currentDate > startDate && currentDate < endDate) {
      array.push({
        ...milestoneDetails,
        name: `${title} Forecast`,
        startDate: milestonesForecastDate,
        cls: 'black',
      })
    }
  }
}

const getRelativeDateRange = (months) => {
  const now = new Date();
  const startDate = new Date(now.getTime());
  const endDate = new Date(now.getTime());
  startDate.setMonth(startDate.getMonth() - months);
  endDate.setMonth(endDate.getMonth() + months);
  return { startDate, endDate };
}

const filterableField = (items) => ({
  filterable: {
    filterField: {
      type: 'combo',
      items,
      multiSelect: true,
      picker: {
        cls: 'siteFilter',
      },
    },
    filterFn: ({
      record, value, property,
    }) => record[property]?.split(',').map((value) => value.trim()).some((v) => value.includes(v)),
  },
})

const tooltipField = ({
  field, text, offVt = 50, offHz = -15, type, placement = 'bottom', filterData, textFieldType = 'text', filterable = true, format,
}) => (
  {
    field,
    text,
    ...(type && { type }),
    ...(format && { format }),
    width: 140,
    editor: null,
    renderer: ({ record }) => (
      <LightTooltip
        title={(
          <>
            <Typography className="AvenirNextLTProRegular">
              { textFieldType !== 'date' ? record[field] : getToolTipTextField(textFieldType, record[field]) }
            </Typography>
          </>
        )}
        placement={placement}
        off_vt={offVt}
        off_hz={offHz}
      >
        { getToolTipTextField(textFieldType, record[field]) }
      </LightTooltip>
    ),
    filterable,
    ...(filterData && filterableField(filterData)),
  }
)

const getToolTipTextField = (textFieldType, value) => {
  if (textFieldType === 'date' && value === null) {
    return <div className="truncate"> - </div>
  }
  const toolTipTextFieldMap = new Map([
    ['link', <div className="truncate"><LinkComponent value={value} className="buttonWithTransparentBackground" /></div>],
    ['text', <div className="truncate">{value}</div>],
    ['truncated', <div className="truncate">{value.length >= 4 ? `${value.substr(0, 4)}...` : value}</div>],
    ['date', <div className="truncate">{moment(value.toString()).format('DD MMM YYYY')}</div>],
  ])
  return toolTipTextFieldMap.get(textFieldType)
}

const formatMilestones = (study, favorite, notification) => {
  const { startDate, endDate } = getRelativeDateRange(MILESTONES_MONTHS_RANGE)
  const milestones = {};
  let latestDate = null;
  let earliestDate = null;
  const childrenTasks = [];

  if (study.milestones) {
    study.milestones.forEach((ms) => {
      if (ms.actual && ms.forecast) {
        ms.forecast = null;
      }
      if (ms.actual > latestDate) {
        latestDate = ms.actual;
      }
      if (!earliestDate || (ms.actual && ms.actual < earliestDate)) {
        earliestDate = ms.actual;
      }
      if (ms.forecast > latestDate) {
        latestDate = ms.forecast;
      }
      if (!earliestDate || (ms.forecast && ms.forecast < earliestDate)) {
        earliestDate = ms.forecast;
      }
      if (!milestones[ms.name]) {
        milestones[ms.name] = {
          actual: ms.actual,
          forecast: ms.forecast,
        }
      } else if (preferredAggregate[ms.name] === 'early') {
        milestones[ms.name] = {
          actual: ms.actual < milestones[ms.name] ? ms.actual : milestones[ms.name],
          forecast: ms.forecast < milestones[ms.name].forecast ? ms.forecast : milestones[ms.name].forecast,
        }
      } else {
        milestones[ms.name] = {
          actual: ms.actual > milestones[ms.name].actual ? ms.actual : milestones[ms.name].actual,
          forecast: ms.forecast > milestones[ms.name].forecast ? ms.forecast : milestones[ms.name].forecast,
        }
      }
    })

    const msDetails = {
      duration: 0,
      manuallyScheduled: true,
      rollup: true,
      company: study.company,
      projectCode: study.project_code,
      latest_release_date: study.latest_release_date,
      status: study.status,
      site: study.site,
      studyType: study.study_type,
      modelId: study.model_id,
      sponsor: study.sponsor,
      director: study.director,
      favorite,
      notification,
    }

    if (milestones.kickoff && milestones.kickoff.actual) {
      const currentDate = new Date(milestones.kickoff.actual)
      if (currentDate > startDate && currentDate < endDate) {
        childrenTasks.push({
          ...msDetails,
          name: 'Kickoff',
          startDate: milestones.kickoff.actual,
          cls: 'green',
        })
      }
    }

    if (milestones.draft_proto) {
      parseMilestonesToGantt(milestones, 'draft_proto', childrenTasks, msDetails)
    }
    if (milestones.dosing_begins) {
      parseMilestonesToGantt(milestones, 'dosing_begins', childrenTasks, msDetails)
    }
    if (milestones.dosing_ends) {
      parseMilestonesToGantt(milestones, 'dosing_ends', childrenTasks, msDetails)
    }
    if (milestones.animal_term) {
      parseMilestonesToGantt(milestones, 'animal_term', childrenTasks, msDetails)
    }
    if (milestones.draft_report) {
      parseMilestonesToGantt(milestones, 'draft_report', childrenTasks, msDetails)
    }
  }

  return { childrenTasks, latestDate, earliestDate }
}

const formatGanttData = (studies, favorites = [], notifications = [], canSubscribeNotifications = true, studiesData = {}) => {
  let result = {}
  if (studies) {
    const { startDate, endDate } = getRelativeDateRange(MILESTONES_MONTHS_RANGE)
    // high level gantt config
    result = {
      project: new ProjectModel({
        taskStore: new TaskStore({
          useRawData: true,
          reapplyFilterOnUpdate: true,
        }),
        enableProgressNotifications: true,
        startDate,
        endDate,
        tasksData: studies.map((study, index) => {
          const fav = favorites.includes(study.project_code)
          const notif = notifications.includes(study.project_code)
          const result = {
            id: index,
            latest_release_date: study.latest_release_date,
            company: study.company,
            projectCode: study.project_code,
            status: study.status,
            site: study.site,
            studyType: study.study_type,
            modelId: study.model_id,
            sponsor: study.sponsor,
            director: study.director,
            favorite: fav,
            notification: notif,
            manuallyScheduled: true,
            children: [],
          }

          let latestDate = null;
          let earliestDate = null;
          if (study.milestones) {
            const formattedMilestones = formatMilestones(study, fav, notif)
            latestDate = formattedMilestones.latestDate
            earliestDate = formattedMilestones.earliestDate
            result.children = formattedMilestones.childrenTasks
          }

          if (latestDate && earliestDate) {
            return {
              ...result,
              startDate: earliestDate,
              endDate: latestDate,
              duration: calculateDuration(earliestDate, latestDate),
              percentDone: calculatePercentDone(earliestDate, latestDate),
            }
          }
          return result
        }),
      }),
      viewPreset: 'monthAndYear',
      barMargin: 10,
      readOnly: true,
      height: 500,
      startDate,
      endDate,
      visibleDate: new Date(),
      rollupsFeature: {
        template: ({ children }) => `<div className='centerText'>
        ${children.map((child) => `<div class='milestoneTooltipEntry'>
        <div class='miles-left-l'>
          <div class='marginBelow-t med AvenirNextLTProDemi'><b>Milestone:</b></div>
          <div class='marginBelow-b med AvenirNextLTProDemi'><b>Date:</b></div>
        </div>
        <div class='miles-right-r'>
          <div class='marginBelow-t med AvenirNextLTProRegular'>${child?.name}</div>
          <div class='marginBelow-b med '>
            <span class='AvenirNextLTProRegular'>${child?.originalData?.startDate ? moment(new Date(child.originalData.startDate)).format('dddd, DD MMM YYYY') : ''}</span>
          </div>
        </div>
      </div>`).join('')}
    </div>`,
      },
      taskTooltipFeature: {
        template: ({ taskRecord }) => `<div class='customTooltip'>
                    <div class='left-l'>
                       <div class='l'>
                        <div class='marginBelow-t AvenirNextLTProDemi'>Project Code:</div>
                        <div class='marginBelow-m AvenirNextLTProDemi'>Start:     </div>
                        <div class='marginBelow-m AvenirNextLTProDemi'>End:     </div>
                        <div class='marginBelow-m AvenirNextLTProDemi'>Duration:     </div>
                        <div class='marginBelow-b AvenirNextLTProDemi'>Complete:    </div>
                      </div>
                    </div>
                    <div class='right-r'>
                    <div class='r'>
                      <div class='marginBelow-t AvenirNextLTProRegular'>${taskRecord.projectCode}</div>
                            <div class='marginBelow-m AvenirNextLTProRegular'>${taskRecord.startDate ? moment(taskRecord?.startDate).format('DD MMM YYYY')
    : ''
}</div>

                            <div class='marginBelow-m AvenirNextLTProRegular'>${taskRecord.endDate ? moment(taskRecord?.endDate).format('DD MMM YYYY')
    : ''
}   </div>
                            <div class='marginBelow-m AvenirNextLTProRegular'>${Math.floor(taskRecord.duration)} Days</div>
                            <div class='marginBelow-b AvenirNextLTProRegular'> ${taskRecord.originalData.percentDone}%</div>
                            </div>
                            </div>
                        </div>
                    `,
      },
      filterBarFeature: true,
      cellEditFeature: false,
      dependencyEditFeature: false,
      cellMenuFeature: false,
      taskEditFeature: false,
      taskMenuFeature: false,
      taskContextMenuFeature: false,
      projectLinesFeature: false,
      percentBarFeature: false,
      taskDragFeature: false,
      taskDragCreateFeature: false,
      treeFeature: false,
      eventFilterFeature: false,
      timeRangesFeature: { showCurrentTimeLine: { name: 'Today' } },
    }
    result.columns = [
      {
        field: 'favorite',
        htmlEncodeHeaderText: false,
        width: 84,
        editor: false,
        align: 'center',
        headerRenderer: () => '<svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true" style="width: 16px; fill: #F8A131"><path d="M22 9.24L 14.81 8.62L12 2L 9.19 8.63L2 9.24L7.46 13.97L 5.82 21L12 17.27L 18.18 21L 16.55 13.97L 22 9.24Z"></path></svg>',
        renderer: ({
          record, row, grid, column,
        }) => <FavoriteComponent record={record} row={row} grid={grid} column={column} />,
        filterable: {
          filterFn: ({ record, value }) => (value === 'Favorited' ? record.favorite : !record.favorite),
          filterField: {
            type: 'combo',
            editable: false,
            items: ['Favorited', 'Not Favorited'],
            picker: {
              cls: 'siteFilter',
            },
            listeners: {
              change: ({ value, oldValue }) => {
                if (value === 'Favorited') {
                  const replaceElem = oldValue ? document.getElementById('favContainerOff') : document.getElementById('b-combo-1-input');
                  replaceElem.parentNode.replaceChild(favElem, replaceElem)
                } else if (value === 'Not Favorited') {
                  const replaceElem = oldValue ? document.getElementById('favContainerOn') : document.getElementById('b-combo-1-input');
                  replaceElem.parentNode.replaceChild(favOffElem, replaceElem)
                } else if (oldValue) {
                  const toBeRemoved = oldValue === 'Favorited' ? document.getElementById('favContainerOn') : document.getElementById('favContainerOff');
                  toBeRemoved.parentNode.replaceChild(emptyElem, toBeRemoved)
                }
                return value;
              },
            },
          },
        },
      },
    ];
    if (canSubscribeNotifications) {
      result.columns.push({
        field: 'notification',
        headerRenderer: () => '<svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true" style="width: 16px; fill: #056a7b;"><path d="M12 22c1.1 0 2-.9 2-2h-4c0 1.1.89 2 2 2zm6-6v-5c0-3.07-1.64-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.63 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2z"></path></svg>',
        htmlEncodeHeaderText: false,
        filterable: false,
        width: 84,
        editor: false,
        align: 'center',
        renderer: ({
          record, row, grid, column,
        }) => <NotificationComponent record={record} row={row} grid={grid} column={column} />,
      });
    }
    result.columns.push(
      tooltipField({
        field: 'latest_release_date', text: 'Latest Study Activity', filterable: false, textFieldType: 'date', format: 'DD MMM YYYY',
      }),
      tooltipField({ field: 'company', text: 'Company' }),
      tooltipField({
        field: 'projectCode', text: 'Project Code', offVt: 22, offHz: -30, type: 'name', textFieldType: 'link', placement: 'right',
      }),
      tooltipField({ field: 'status', text: 'Status', filterData: studiesData.statuses }),
      tooltipField({
        field: 'site', text: 'Site', filterData: studiesData.sites, textFieldType: 'truncated',
      }),
      tooltipField({ field: 'studyType', text: 'Study Type', filterData: studiesData.studyTypes }),
      tooltipField({ field: 'modelId', text: 'Model ID' }),
      tooltipField({ field: 'sponsor', text: 'Project Sponsor' }),
      tooltipField({ field: 'director', text: 'Study Director' }),
    );
  }
  result.project.setCalculations({
    tasks: {
      percentDone: 'userProvidedValue',
    },
  })

  return result;
}

export default formatGanttData;
