import React from 'react';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import IconStudyDocumentsV1 from '../../IconStudyDocumentsV1.svg';
import styles from '../StudyDocuments.module.scss';

// TODO: This kind of header is used in multiple places. Consider making it a component.
const StudyDocumentsHeader = (props) => {
  const {
    openCreateFolderModalHandler,
    setShowCreatingFolderModal,
    isCustomFoldersEnabled,
  } = props;

  return (
    <>
      <div className={styles.cardHeaderIconTitle}>
        <Typography className={styles.cardHeaderTitle}>
          <img src={IconStudyDocumentsV1} alt="Study Documents Icon" />
          Study Documents
        </Typography>
      </div>
      {isCustomFoldersEnabled && (
        <div className={styles.cardHeaderButtonBox}>
          <Button
            className={styles.cardHeaderButton}
            onClick={
              () =>
                // eslint-disable-next-line
                openCreateFolderModalHandler(setShowCreatingFolderModal)
              // eslint-disable-next-line
            }
            data-testid="study-documents-add-folder-button"
          >
            Add folder
          </Button>
        </div>
      )}
    </>
  );
};

export default StudyDocumentsHeader;
