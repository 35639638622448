/* eslint-disable camelcase */
/* eslint-disable no-unused-expressions */
import React, {
  useState,
  useEffect,
  useCallback,
} from 'react'
import { Nav } from 'react-bootstrap'
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid';
import ReactExport from '../../../react-export-excel' // eslint-disable-line import/no-unresolved
import { useAuth } from '../../../providers/auth'
import { useGraphQL } from '../../../providers/graphql'
import {
  formatTaskMeasurementSummaryGridOptions,
  formatTaskMeasurementIndividualGridOptions,
  formatTGISummaryGridOptions,
  formatMortalityObservationIndividualGridOptions,
  formatClinicalObservationIndividualGridOptions,
  formatDosingRecordIndividualGridOptions,
  formatBodyWeightChangeSummaryGridOptions,
  formatBodyWeightChangeIndividualGridOptions,
  getDataForLongFormat,
  getTvcBwcCoMoDataForLongFormat,
  getDoDataForLongFormat,
  // eslint-disable-next-line
} from '../../utils'
import LoadingSpinnerIcon from './loading.svg'

import DownloadIconM from './IconDownload.svg' /* now using a new icon as per design */
import DownloadIconDisabled from './icon-download-disabled.svg'

// eslint-disable-next-line import/no-named-as-default-member
const { ExcelFile } = ReactExport;
// eslint-disable-next-line import/no-named-as-default-member
const { ExcelSheet } = ReactExport.ExcelFile;

const dataSheet = (study, model, user, username) => {
  const timezone = ` ${new Date().toString().match(/\(([^)]+)\)$/)[1]}`
  const dataset = [
    {
      columns: [
        { value: '', widthPx: 400 },
        { value: '', widthPx: 400 },
      ],
      data: [
        [
          { value: 'CrownLink Data Report', style: { font: { sz: '24', bold: true } } },
          { value: '' },
        ],
        [
          { value: 'Crown Bioscience', style: { font: { sz: '22', bold: true } } },
          { value: '' },
        ],
        [
          { value: '', style: { font: { sz: '12', bold: true } } },
          { value: '' },
        ],
        [
          { value: 'Company', style: { font: { sz: '12', bold: true } } },
          { value: study.company },
        ],
        [
          { value: 'Project Code', style: { font: { sz: '12', bold: true } } },
          { value: study.project_code },
        ],
        [
          { value: 'Study Title', style: { font: { sz: '12', bold: true } } },
          { value: study.study_title },
        ],
        [
          { value: 'Project Sponsor', style: { font: { sz: '12', bold: true } } },
          { value: study.sponsor || 'N/A' },
        ],
        [
          { value: 'Study Director', style: { font: { sz: '12', bold: true } } },
          { value: study.director || 'N/A' },
        ],
        [
          { value: 'Business Development', style: { font: { sz: '12', bold: true } } },
          { value: study.business_director || 'N/A' },
        ],
        [
          { value: 'Project Manager', style: { font: { sz: '12', bold: true } } },
          { value: study.project_manager || 'N/A' },
        ],
        [
          { value: 'Project Models', style: { font: { sz: '12', bold: true } } },
          { value: study.models.map((m) => m.study_model).join(', ') },
        ],
        [
          { value: 'Models Included', style: { font: { sz: '12', bold: true } } },
          { value: model },
        ],
        [
          { value: 'Generated By', style: { font: { sz: '12', bold: true } } },
          { value: username == null ? user.email.split('@')[0] : username },
        ],
        [
          { value: 'Generated On', style: { font: { sz: '12', bold: true } } },
          {
            value: new Date().toLocaleString('sv').replace(/-/g, '/') + timezone,
          },
        ],
      ],
    },
  ]

  return dataset
}

const ExportDownload = function ExportDownload({
  study,
  model,
  dataExportAudit = () => { },
  updateExportedFiles = () => { },
  downloadZip,
}) {
  const { user } = useAuth()
  const {
    user: userDetails, getTaskMeasurementSummaryAPI, getTaskMeasurementIndividualAPI,
  } = useGraphQL();

  const [loadingIcon, setLoadingIcon] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [tvi, setTvi] = useState([])
  const [tvci, setTvci] = useState([])
  const [tvir, setTvir] = useState([])
  const [tvil, setTvil] = useState([])
  const [tvsr, setTvsr] = useState([])
  const [tvsl, setTvsl] = useState([])
  const [tvs, setTvs] = useState([])
  const [tvcs, setTvcs] = useState([])
  const [bwi, setBwi] = useState([])
  const [bws, setBws] = useState([])
  const [bwc, setBwc] = useState([])
  const [bwcs, setBwcs] = useState([])
  const [co, setCo] = useState([])
  const [doa, setDoa] = useState([])
  const [mo, setMo] = useState([])
  const [tfs, setTfs] = useState([])
  const [tfi, setTfi] = useState([])
  const [lf, setLF] = useState([])

  const [hasApprovedData, setHasApprovedData] = useState(false)

  const checkApprovedData = useCallback(async () => {
    if (model?.director?.state === 'RELEASED') {
      setHasApprovedData(true)
    } else {
      setHasApprovedData(false)
    }
  }, [model.director])

  useEffect(() => {
    checkApprovedData()
  }, [checkApprovedData])

  const getExcelSheetComponent = (dataSet, name, defaultReturnValue = null) => {
    let returnVal = defaultReturnValue;

    if (dataSet && dataSet?.length) {
      returnVal = (<ExcelSheet dataSet={dataSet} name={name} key={`excel-sheet-${name}`} />)
    }

    return returnVal
  }

  const getExcelFileJSXIfDatasetIsNotNull = () => {
    const dataSet = dataSheet(study, model.study_model, user, `${userDetails.first_name} ${userDetails.last_name}`)

    if (dataSet) {
      const validSheets = [
        getExcelSheetComponent(dataSet, 'General Information'),
        getExcelSheetComponent(tvsr, 'Right TV Summary'),
        getExcelSheetComponent(tvsl, 'Left TV Summary'),
        getExcelSheetComponent(tfs, 'TF Summary'),
        getExcelSheetComponent(tfi, 'TF Individual'),
        getExcelSheetComponent(tvs, 'TV Summary'),
        getExcelSheetComponent(tvil, 'Left TV Individual'),
        getExcelSheetComponent(tvir, 'Right TV Individual'),
        getExcelSheetComponent(tvi, 'TV Individual'),
        getExcelSheetComponent(tvcs, 'TVC Summary'),
        getExcelSheetComponent(tvci, 'TVC Individual'),
        getExcelSheetComponent(bws, 'BW Summary'),
        getExcelSheetComponent(bwi, 'BW Individual'),
        getExcelSheetComponent(bwcs, 'BWC Summary'),
        getExcelSheetComponent(bwc, 'BWC Individual'),
        getExcelSheetComponent(co, 'CO'),
        getExcelSheetComponent(doa, 'DR'),
        getExcelSheetComponent(mo, 'MO'),
        getExcelSheetComponent(lf, 'Long Format'),
      ].filter((x) => x);

      return (
        <ExcelFile downloadZip={downloadZip} onUpdateState={(newState) => updateExportedFiles(newState)} hideElement filename={`${study.project_code}--${model.study_model}--${model?.study_type ?? 'NA'}--${uuidv4()}`}>
          {/* <ExcelSheet dataSet={dataSet} name="General Information" /> */}
          {validSheets.map((x) => x)}

        </ExcelFile>

      )
    }

    return (<></>)
  }

  return (
    <>
      { /* eslint-disable-next-line react/jsx-indent */}
      { /* eslint-disable-next-line no-use-before-define */}
      {downloadIcon(model.study_model, model.study_id, loadingIcon, setLoadingIcon, loaded, setLoaded, setTvi, setTvci, setTvir, setTvil, setTvs, setTvcs, setTvsr, setTvsl, setTfs, setTfi, setBwi, setBws, setBwc, setBwcs, setCo, setDoa, setMo, setLF, getTaskMeasurementSummaryAPI, getTaskMeasurementIndividualAPI, dataExportAudit, hasApprovedData)}
      {loaded ? getExcelFileJSXIfDatasetIsNotNull() : ''}
    </>
  )
}

const fixDosingData = (dataset) => {
  // convert dosing_data values to checkmarks
  const newDoa = { columnDefs: dataset.columnDefs, rowData: [] }
  newDoa.rowData = dataset.rowData.map((row) => {
    const objs = Object.keys(row).map((key) => {
      if (key.startsWith('studyDay') && row[key].length > 1) {
        return { [key]: row[key].split('\n')[3] === '0' ? ' Holiday' : ' ✓' }
      }
      return { [key]: row[key] }

    })
    return objs.reduce(((r, c) => Object.assign(r, c)), {})
  })
  return newDoa
}

const convertToDataset = (dataset, type, model, legend, tgiData, datasetVentral, datasetDorsal, ventralTaskIndividual, dorsalTaskIndividual) => {
  const rows = dataset?.rowData
  const columns = dataset?.columnDefs
  const types = {
    TVSR: {
      name: 'Right Tumor Volume', units: 'mm\xB3', abbrev: 'TVSR', summary: true, legend: false,
    },
    TVSL: {
      name: 'Left Tumor Volume', units: 'mm\xB3', abbrev: 'TVSL', summary: true, legend: false,
    },
    TVS: {
      name: 'Tumor Volume Summary', units: 'mm\xB3', abbrev: 'TVS', summary: true, legend: false,
    },
    TVI: {
      name: 'Tumor Volume', units: 'mm\xB3', abbrev: 'TV', summary: false, legend: false,
    },
    TVCS: {
      name: 'Tumor Volume Change Summary', units: 'mm\xB3', abbrev: 'TVCS', summary: true, legend: false,
    },
    TVCI: {
      name: 'Tumor Volume Change', units: '%', abbrev: 'TVC', summary: false, legend: false,
    },
    TVIR: {
      name: 'Right Tumor Volume', units: 'mm\xB3', abbrev: 'TVR', summary: false, legend: false,
    },
    TVIL: {
      name: 'Left Tumor Volume', units: 'mm\xB3', abbrev: 'TVL', summary: false, legend: false,
    },
    BWS: {
      name: 'Body Weight', units: 'g', abbrev: 'BW', summary: true, legend: false,
    },
    BWCS: {
      name: 'Body Weight Change', units: 'g', abbrev: 'BWC', summary: true, legend: false,
    },
    BWI: {
      name: 'Body Weight', units: 'g', abbrev: 'BW', summary: false, legend: false,
    },
    BWC: {
      name: 'Body Weight Change', units: '%', abbrev: 'BWC', summary: false, legend: false,
    },
    CO: {
      name: 'Clinical Observations', units: '', abbrev: 'CO', summary: false, legend: true,
    },
    DR: {
      name: 'Dosing Record', units: '', abbrev: 'DR', summary: false, legend: false,
    },
    MO: {
      name: 'Mortality Observations', units: '', abbrev: 'MO', summary: false, legend: true,
    },
    TFS: {
      name: 'Total Flux Summary', units: '', abbrev: 'TFS', summary: true, legend: false,
    },
    TFI: {
      name: 'Total Flux Individual', units: '', abbrev: 'TFI', summary: false, legend: false,
    },
  }
  const swapWeekdayValues = (colsWeekDay, colsWeekDaysNumeric) => {
    // Define a function to check if a value is a weekday
    const isWeekday = (value) => {
      const weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
      return weekdays.includes(value);
    };

    // Swap values based on the condition for both lists
    const swappedColsWeekDay = colsWeekDay.map((colWeekDay, index) => {
      if (!isWeekday(colWeekDay.value)) {
        // If it's not a weekday, swap with colsWeekDaysNumeric
        return { value: colsWeekDaysNumeric[index].value, width: colWeekDay.width };
      }
      // If it's a weekday, keep the original value
      return colWeekDay;
    });

    const swappedColsWeekDaysNumeric = colsWeekDaysNumeric.map((colWeekDaysNumeric, index) => {
      if (!isWeekday(colWeekDaysNumeric.value) && !isWeekday(colsWeekDay[index].value)) {
        // If it's not a weekday, swap with colsWeekDay
        return { value: colsWeekDay[index].value, width: colWeekDaysNumeric.width };
      }
      // If it's a weekday, keep the original value
      return colWeekDaysNumeric;
    });

    return [swappedColsWeekDay, swappedColsWeekDaysNumeric];
  };

  const refineColsRow = (colsRow) => {
    const colsWeekDaysNumeric = colsRow.map((item) => {
      if (item.value.startsWith('Day ')) {
        return {
          value: item.value,
          width: item.width,
        };
      }
      return {
        value: '',
        width: item.width,
      };
    });

    return colsWeekDaysNumeric;
  };

  let colsRows = []
  let ventralTaskIndividualcolsRows = []
  let dorsalTaskIndividualcolsRows = []
  if (types[type].summary) {
    if (dataset !== undefined) {
      const colsRow = columns.map((col) => ({
        value: col?.headerName ?? '',
        width: { wch: 200 },
      }));
      const colsRowDate = columns.map((col) => ({
        value: col?.headerComponentParams?.studyDate ?? '',
        width: { wch: 200 },
      }));
      const colsWeekDay = columns.map((col) => ({
        value: col?.headerComponentParams?.weekDay ?? '',
        width: { wch: 200 },
      }));
      const colsWeekDaysNumeric = colsRow.map((item) => {
        if (item.value.startsWith('Day ')) {
          const dayValue = item.value.split(' ')[1];
          return {
            value: dayValue,
            width: item.width,
          };
        }
        return item;

      });
      colsRows = colsRows.concat([colsRowDate, colsWeekDay, refineColsRow(colsRow), colsWeekDaysNumeric])
    }
  } else {
    // For the individual tabs vertically stack the StudyDate/StudyDay/Weekday per CCP-1149
    const createColsRow = (columns) => {
      const colsRow = columns.map((col) => ({
        value: col.headerName ? col.headerName : col.headerComponentParams.weekDay,
        width: { wch: 200 },
      }));
      const colsRowDate = columns.map((col) => ({
        value: col.headerName ? '' : col.headerComponentParams.studyDate,
        width: { wch: 200 },
      }));
      const colsRowDay = columns.map((col) => ({
        value: col.headerName ? '' : col.headerComponentParams.studyDay,
        width: { wch: 200 },
      }));
      const colsWeekDaysNumeric = colsRowDay.map((item) => {
        if (item.value.startsWith('Day ')) {
          const dayValue = item.value.split(' ')[1];
          return {
            value: dayValue,
            width: item.width,
          };
        }
        return item;

      });
      const [swappedCols1, swappedCols2] = swapWeekdayValues(colsRow, colsWeekDaysNumeric);
      return [colsRowDate, swappedCols1, colsRowDay, swappedCols2];
    };
    if (dataset !== undefined) {
      colsRows = colsRows.concat(createColsRow(columns));
    }

    if (ventralTaskIndividual !== undefined) {
      const ventralTaskIndividualColumns = ventralTaskIndividual?.columnDefs;
      ventralTaskIndividualcolsRows = ventralTaskIndividualcolsRows.concat(createColsRow(ventralTaskIndividualColumns));
    }

    if (dorsalTaskIndividual !== undefined) {
      const dorsalTaskIndividualColumns = dorsalTaskIndividual?.columnDefs;
      dorsalTaskIndividualcolsRows = dorsalTaskIndividualcolsRows.concat(createColsRow(dorsalTaskIndividualColumns));
    }

  }
  const processRowData = (rowData, columns) => rowData.map((row) => columns.map((col) => {
    const val = row?.[col?.field];
    if (val === null || val === undefined || (typeof val === 'boolean' && !val)) {
      return { value: '' };
    } if (typeof val === 'object') {
      return { value: Object.values(val).join() };
    } if (typeof val === 'number') {
      return { value: (val).toString() };
    }
    return { value: val };

  }));
  let mainRows = null;
  if (dataset !== undefined) {
    mainRows = processRowData(rows, columns);
  }
  let datasetVentralMainRows = null;
  let datasetDorsalMainRows = null;
  let ventralTaskIndividualMainRows = null;
  let dorsalTaskIndividualMainRows = null;

  if (datasetVentral !== undefined) {
    datasetVentralMainRows = processRowData(datasetVentral.rowData, datasetVentral.columnDefs);
  }
  if (datasetDorsal !== undefined) {
    datasetDorsalMainRows = processRowData(datasetDorsal.rowData, datasetDorsal.columnDefs);
  }
  if (ventralTaskIndividual !== undefined) {
    ventralTaskIndividualMainRows = processRowData(ventralTaskIndividual.rowData, ventralTaskIndividual.columnDefs);
  }
  if (dorsalTaskIndividual !== undefined) {
    dorsalTaskIndividualMainRows = processRowData(dorsalTaskIndividual.rowData, dorsalTaskIndividual.columnDefs);
  }

  const unit = types[type].units !== '' ? ` (${types[type].units})` : ''
  const summaryIndividualheader = [
    [{ value: `${model}: ${types[type].name} (${types[type].abbrev}) ${unit}` }],
    [{ value: `${types[type].abbrev} ${types[type].summary ? 'Summary' : 'Individual'}` }],
    [{ value: '' }],
  ]

  // add legend to datasets that have them (CO, and MO currently)
  let legendRows = []
  if (types[type].legend) {
    legendRows = legend.map((l) => [{ value: `${l.key}: ${l.value}` }])
    legendRows.unshift([{ value: 'Legend' }])
    legendRows.unshift([{ value: '' }])
  }

  let data = []

  if (types[type].summary) {
    const processRows = (rows) => rows.map((row) => row.map((col, index) => {
      const { value } = row[index];
      return value && value.includes('±') ? { value: value.split('±')[0] } : { value };
    }));
    const processErrorRows = (rows) => rows.map((row) => row.map((col, index) => {
      const { value } = row[index];
      return value && value.includes('±') ? { value: value.split('±')[1] } : { value };
    }));

    let groupRows = null;
    let errorRows = null;
    if (dataset !== undefined) {
      groupRows = processRows(mainRows);
      errorRows = processErrorRows(mainRows);
    }
    let datasetVentralGroupRows = null;
    let datasetVentralErrorRows = null;
    if (datasetVentral !== undefined) {
      datasetVentralGroupRows = processRows(datasetVentralMainRows);
      datasetVentralErrorRows = processErrorRows(datasetVentralMainRows);
    }

    let datasetDorsalGroupRows = null;
    let datasetDorsalErrorRows = null;
    if (datasetDorsal !== undefined) {
      datasetDorsalGroupRows = processRows(datasetDorsalMainRows);
      datasetDorsalErrorRows = processErrorRows(datasetDorsalMainRows);
    }

    // Add TGI Summary Data table if TV Summary
    const TGIColsRows = []
    let TGIColsStudyDateRows = []
    let TGIColsWeekDayRows = []
    let TGIMainRows = []
    const TGIMeanColsRows = []
    let TGIMeanColsStudyDateRows = []
    let TGIMeanColsWeekDayRows = []
    let TGIMeanMainRows = []
    if (type === 'TVS' || type === 'TVSR' || type === 'TVSL') {

      let isAllDeltaInhibitionNull = true;
      let isAllMeanInhibitionNull = true;
      tgiData.groups.forEach((group) => {
        group.study_day_metrics.forEach((metric) => {
          if (metric.delta_inhibition !== null) {
            isAllDeltaInhibitionNull = false;
          }
          if (metric.mean_inhibition !== null) {
            isAllMeanInhibitionNull = false;
          }
        });
      });

      if (!isAllDeltaInhibitionNull) {
        const TGIData = formatTGISummaryGridOptions(tgiData.groups)
        const TGIRows = TGIData.rowData
        const TGIColumns = TGIData.columnDefs
        TGIColsStudyDateRows = TGIColumns.map((col) => ({
          value: col?.headerComponentParams?.studyDate ?? '',
          width: { wch: 200 },
        }));

        TGIColsWeekDayRows = TGIColumns.map((col) => ({
          value: col?.headerComponentParams?.weekDay ?? '',
          width: { wch: 200 },
        }));
        const TGIColsStudyDayRows = TGIColumns.map((col) => ({ value: col.headerName ? col.headerName : Object.values(col.headerComponentParams).join(' '), width: { wch: 200 } }))
        const colsWeekDaysNumeric = TGIColsStudyDayRows.map((item) => {
          if (item.value.startsWith('Day ')) {
            const dayValue = item.value.split(' ')[1];
            return {
              value: dayValue,
              width: item.width,
            };
          }
          return item;

        });
        TGIColsRows.push(TGIColsStudyDateRows);
        TGIColsRows.push((TGIColsWeekDayRows));
        TGIColsRows.push(refineColsRow(TGIColsStudyDayRows));
        TGIColsRows.push(colsWeekDaysNumeric);
        TGIMainRows = TGIRows.map((row) => TGIColumns.map((col) => {
          const val = row[col.field]
          if (!val) return { value: '' }
          if (typeof (val) === 'object') return { value: Object.values(val).join().length ? `${Object.values(val).join()}%` : '' }
          if (typeof (val) === 'number') return { value: val.toString() }
          return { value: val }
        }))

        TGIColsRows.unshift([{ value: 'Tumor Growth Inhibition (Mean % Δ Inhibition)' }])
        TGIColsRows.unshift([{ value: '' }])
        TGIColsRows.unshift([{ value: '' }])
        TGIMainRows.push([{ value: 'Mean % Δ Inhibition = ((mean(C)-mean(C0)) - (mean(T)-mean(T0))) / (mean(C)-mean(C0)) * 100%' }])
        TGIMainRows.push([{ value: 'T - treatment group value' }])
        TGIMainRows.push([{ value: 'T0 - treatment group initial value' }])
        TGIMainRows.push([{ value: 'C - control group value' }])
        TGIMainRows.push([{ value: 'C0 - control group initial value' }])
      }

      if (!isAllMeanInhibitionNull) {
        const TGIMeanData = formatTGISummaryGridOptions(tgiData.groups, undefined, 'mean_inhibition')
        const TGIMeanRows = TGIMeanData.rowData
        const TGIMeanColumns = TGIMeanData.columnDefs
        TGIMeanColsStudyDateRows = TGIMeanColumns.map((col) => ({
          value: col?.headerComponentParams?.studyDate ?? '',
          width: { wch: 200 },
        }));
        TGIMeanColsWeekDayRows = TGIMeanColumns.map((col) => ({
          value: col?.headerComponentParams?.weekDay ?? '',
          width: { wch: 200 },
        }));
        const TGIColsStudyDayRows = TGIMeanColumns.map((col) => ({ value: col.headerName ? col.headerName : Object.values(col.headerComponentParams).join(' '), width: { wch: 200 } }))
        const colsWeekDaysNumeric = TGIColsStudyDayRows.map((item) => {
          if (item.value.startsWith('Day ')) {
            const dayValue = item.value.split(' ')[1];
            return {
              value: dayValue,
              width: item.width,
            };
          }
          return item;

        });
        TGIMeanMainRows = TGIMeanRows.map((row) => TGIMeanColumns.map((col) => {
          const val = row[col.field]
          if (!val) return { value: '' }
          if (typeof (val) === 'object') return { value: Object.values(val).join().length ? `${Object.values(val).join()}%` : '' }
          if (typeof (val) === 'number') return { value: val.toString() }
          return { value: val }
        }))

        TGIMeanColsRows.push(TGIMeanColsStudyDateRows)
        TGIMeanColsRows.push(TGIMeanColsWeekDayRows)
        TGIMeanColsRows.push(refineColsRow(TGIColsStudyDayRows))
        TGIMeanColsRows.push(colsWeekDaysNumeric)

        TGIMeanColsRows.unshift([{ value: 'Tumor Growth Inhibition (Mean % Inhibition)' }])
        TGIMeanColsRows.unshift([{ value: '' }])
        TGIMeanColsRows.unshift([{ value: '' }])
        TGIMeanMainRows.push([{ value: 'Mean % Inhibition = (mean(C)-mean(T))/mean(C) * 100%' }])
        TGIMeanMainRows.push([{ value: 'T - current group value' }])
        TGIMeanMainRows.push([{ value: 'C - control group value' }])
      }
    }

    const meanErrorHeader = [[{ value: 'Group Mean ± Standard Error' }]]
    const groupMeanHeader = [[{ value: '' }], [{ value: 'Group Mean' }]]
    const standardErrorHeader = [[{ value: '' }], [{ value: 'Standard Error' }]]

    const ventralOnlyHeader = [[{ value: '' }], [{ value: 'Mean Total Flux (p/s) - Ventral Only' }], [{ value: '' }]]
    const dorsalOnlyHeader = [[{ value: '' }], [{ value: 'Mean Total Flux (p/s) - Dorsal Only' }], [{ value: '' }]]
    if (type === 'TFS') {
      data = [...summaryIndividualheader]
      if (dataset !== undefined) {
        data.push(...[...meanErrorHeader, ...colsRows, ...mainRows, ...groupMeanHeader,
          ...colsRows, ...groupRows, ...standardErrorHeader, ...colsRows, ...errorRows, ...TGIColsRows, ...TGIMainRows, ...TGIMeanColsRows, ...TGIMeanMainRows])
      }
      if (datasetVentral !== undefined) {
        data.push(...[...ventralOnlyHeader,
          ...meanErrorHeader, ...colsRows, ...datasetVentralMainRows, ...groupMeanHeader,
          ...colsRows, ...datasetVentralGroupRows, ...standardErrorHeader, ...colsRows,
          ...datasetVentralErrorRows]);
      }
      if (datasetDorsal !== undefined) {
        data.push(...[...dorsalOnlyHeader, ...meanErrorHeader, ...colsRows,
          ...datasetDorsalMainRows, ...groupMeanHeader, ...colsRows, ...datasetDorsalGroupRows,
          ...standardErrorHeader, ...colsRows, ...datasetDorsalErrorRows]);
      }
      data.push(...[...TGIColsRows, ...TGIMainRows, ...TGIMeanColsRows, ...TGIMeanMainRows])
    } else {
      data = [...summaryIndividualheader, ...meanErrorHeader, ...colsRows, ...mainRows, ...groupMeanHeader,
        ...colsRows, ...groupRows, ...standardErrorHeader, ...colsRows, ...errorRows, ...legendRows,
        ...TGIColsRows, ...TGIMainRows, ...TGIMeanColsRows, ...TGIMeanMainRows]
    }
  } else {
    // remove second header row for CO, DR, and MO
    if (types[type].units === '') summaryIndividualheader.splice(1, 1)
    data = [...summaryIndividualheader]
    if (dataset !== undefined) {
      data.push(...[...colsRows, ...mainRows])
    }
    if (ventralTaskIndividual !== undefined) {
      const header = [[{ value: '' }], [{ value: 'Individual Total Flux (p/s) - Ventral Only' }], [{ value: '' }]]
      data.push(...[...header, ...ventralTaskIndividualcolsRows, ...ventralTaskIndividualMainRows])
    }
    if (dorsalTaskIndividual !== undefined) {
      const header = [[{ value: '' }], [{ value: 'Individual Total Flux (p/s) - Dorsal Only' }], [{ value: '' }]]
      data.push(...[...header, ...dorsalTaskIndividualcolsRows, ...dorsalTaskIndividualMainRows])
    }
    data.push(...[...legendRows])
  }
  return [{ columns: [], data }]
}

const fixSummaryData = (dataset, type, model, tgiData) => {
  // Convert summary data to work with Excel export
  const rows = dataset.rowData.map((row) => {
    const objs = Object.keys(row).map((key) => {
      const val = row[key]

      // in case of string value, return back existing value
      if (typeof (val) === 'string') return { [key]: val }

      // If an object, combine the mean and sem values into one value with a plus/minus symbol
      if (typeof (val) === 'object') return { [key]: Object.values(val).join(' \xB1 ') }

      return '' // just have this to shut-up the compiler warning
    })
    return objs.reduce(((r, c) => Object.assign(r, c)), {})
  })
  return convertToDataset({ columnDefs: dataset.columnDefs, rowData: rows }, type, model, null, tgiData)
}

const fixSummaryDataTfs = (datasetmean, datasetventral, datasetdorsal, type, model, tgiData) => {
  // Function to convert an individual row of summary data
  const convertRow = (row) => {
    const objs = Object.keys(row).map((key) => {
      const val = row[key]

      // in case of string value, return back existing value
      if (typeof (val) === 'string') return { [key]: val }

      // If an object, combine the mean and sem values into one value with a plus/minus symbol
      if (typeof (val) === 'object') return { [key]: Object.values(val).join(' \xB1 ') }

      return '' // just have this to shut-up the compiler warning
    })
    return objs.reduce(((r, c) => Object.assign(r, c)), {})
  }

  // Convert summary data to work with Excel export
  const datasetmeanrows = datasetmean?.rowData?.map(convertRow)
  const datasetventralrows = datasetventral?.rowData?.map(convertRow)
  const datasetdorsalrows = datasetdorsal?.rowData?.map(convertRow)

  return convertToDataset(
    datasetmean ? { columnDefs: datasetmean?.columnDefs, rowData: datasetmeanrows } : undefined,
    type,
    model,
    null,
    tgiData,
    datasetventral ? { columnDefs: datasetventral?.columnDefs, rowData: datasetventralrows } : undefined,
    datasetdorsal ? { columnDefs: datasetdorsal?.columnDefs, rowData: datasetdorsalrows } : undefined,
  )
}

const downloadData = async (id, getTaskMeasurementSummaryAPI, getTaskMeasurementIndividualAPI) => {
  const tvs = new Promise((resolve) => { resolve(getTaskMeasurementSummaryAPI(id, 'TumorVolume', false)) })
  const tv = new Promise((resolve) => { resolve(getTaskMeasurementIndividualAPI(id, 'TumorVolume', false)) })
  const bw = new Promise((resolve) => { resolve(getTaskMeasurementIndividualAPI(id, 'BodyWeight', false)) })
  const bws = new Promise((resolve) => { resolve(getTaskMeasurementSummaryAPI(id, 'BodyWeight', false)) })
  const bwc = new Promise((resolve) => { resolve(getTaskMeasurementIndividualAPI(id, 'BodyWeightChange', false)) })
  const bwcs = new Promise((resolve) => { resolve(getTaskMeasurementSummaryAPI(id, 'BodyWeightChange', false)) })
  const co = new Promise((resolve) => { resolve(getTaskMeasurementIndividualAPI(id, 'Observation', false)) })
  const doa = new Promise((resolve) => { resolve(getTaskMeasurementIndividualAPI(id, 'Dosing', false)) })
  const mo = new Promise((resolve) => { resolve(getTaskMeasurementIndividualAPI(id, 'MortalityObservation', false)) })
  const tfs = new Promise((resolve) => { resolve(getTaskMeasurementSummaryAPI(id, 'TotalFlux', false)) })
  const tfi = new Promise((resolve) => { resolve(getTaskMeasurementIndividualAPI(id, 'TotalFlux', false)) })
  const tvcs = new Promise((resolve) => { resolve(getTaskMeasurementSummaryAPI(id, 'TumorVolumeChange', false)) })
  const tvci = new Promise((resolve) => { resolve(getTaskMeasurementIndividualAPI(id, 'TumorVolumeChange', false)) })
  return Promise.allSettled([tvs, tv, bw, bws, bwc, bwcs, co, doa, mo, tfs, tfi, tvcs, tvci])
}
const appendData = (rows, data) => {
  const keys = Object.keys(rows)
  const finalData = {}
  keys.forEach((key) => {
    finalData[key] = [...rows[key], data[key] ?? '']
  })
  return finalData
}
const appendDrData = (rows, data, articles) => {
  const keys = Object.keys(rows)
  const finalData = {}
  keys.forEach((key) => {
    const values = []
    Object.keys(articles || {}).forEach((article) => {
      values.push(data[key]?.[article] ? '✓' : '')
    })
    finalData[key] = [...rows[key], ...values]
  })
  return finalData
}
const createDataSetForLongFormat = (list, dynamiColumns, articles) => {
  const drArticles = Object.keys(articles)
  const drColumns = Array.from({ length: drArticles.length }, () => ({ value: 'Dosing Records' }))
  const data = [
    [{ value: 'Long Format' }],
    [{ value: '' }],
    [{ value: '' }],
    [
      { value: 'Group' },
      { value: 'Animal ID' },
      { value: 'Session Date' },
      { value: 'Session Day' },
      ...dynamiColumns, // dynamically add different columns for total flux and tumor volume
      { value: 'Body Weight' },
      { value: 'Body Weight Change' },
      { value: 'Clinical Observation' },
      ...drColumns,
      { value: 'Mortality Observation' },
    ],
    [
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      ...Array.from({ length: dynamiColumns.length }, () => ({ value: '' })), // dynamically add empty columns for total flux and tumor volume
      { value: '' },
      { value: '' },
      { value: '' },
      ...drArticles.map((article) => ({ value: article })),
      { value: '' },
    ],
  ]
  list.forEach((item) => {
    data.push(item.map((val, index) => {
      if (index === 3) {
        return { value: `Day ${val}` }
      }
      return { value: val }
    }))
  })
  return [{ columns: [], data }]
}
const getRowsDataForLongFormat = (result) => {
  const { fixedRows, values: bwValues } = getDataForLongFormat(result[2].value?.data?.getTaskMeasurementIndividual[0].groups)
  const bwcValues = getTvcBwcCoMoDataForLongFormat(result[4].value?.data?.getTaskMeasurementIndividual[0].groups, 'BWC')
  const coValues = getTvcBwcCoMoDataForLongFormat(result[6].value?.data?.getTaskMeasurementIndividual[0].groups, '')
  const { values: drValues, articles } = getDoDataForLongFormat(result[7].value?.data?.getTaskMeasurementIndividual[0].groups)
  const moValues = getTvcBwcCoMoDataForLongFormat(result[8].value?.data?.getTaskMeasurementIndividual[0].groups, '')
  let meanValues = {};
  let ventralValues = {};
  let dorsalValues = {};
  let data = fixedRows
  const dynamiColumns = []
  if (result[10]?.value?.data?.getTaskMeasurementIndividual) {
    result[10].value.data.getTaskMeasurementIndividual.forEach((taskIndividual) => {
      const { values } = getDataForLongFormat(taskIndividual.groups)
      if (taskIndividual?.task_name === 'Mean' || taskIndividual?.task_name === 'Volume') {
        meanValues = values;
        data = appendData(data, meanValues)
        dynamiColumns.push({ value: 'Total Flux (Mean)' })
      }
      if (taskIndividual?.task_name === 'Ventral') {
        ventralValues = values;
        data = appendData(data, ventralValues)
        dynamiColumns.push({ value: 'Total Flux (Ventral)' })
      }
      if (taskIndividual?.task_name === 'Dorsal') {
        dorsalValues = values;
        data = appendData(data, dorsalValues)
        dynamiColumns.push({ value: 'Total Flux (Dorsal)' })
      }
    });
  }
  if (result[1]?.value?.data?.getTaskMeasurementIndividual?.length === 1) {
    const { values: tvValues } = getDataForLongFormat(result[1].value?.data?.getTaskMeasurementIndividual[0].groups)
    data = appendData(data, tvValues)
    dynamiColumns.push({ value: 'Tumor Volume' })
    const tvcValues = getTvcBwcCoMoDataForLongFormat(result[12].value?.data?.getTaskMeasurementIndividual[0].groups, 'TVC')
    data = appendData(data, tvcValues)
    dynamiColumns.push({ value: 'Tumor Volume Change' })
  } else if (result[1]?.value?.data?.getTaskMeasurementIndividual?.length > 1) {
    const { values: tvrValues } = getDataForLongFormat(result[1].value?.data?.getTaskMeasurementIndividual[0].groups)
    data = appendData(data, tvrValues)
    dynamiColumns.push({ value: 'Right Tumor Volume' })
    const { values: tvlValues } = getDataForLongFormat(result[1].value?.data?.getTaskMeasurementIndividual[1].groups)
    data = appendData(data, tvlValues)
    dynamiColumns.push({ value: 'Left Tumor Volume' })
  }
  data = appendData(data, bwValues)
  data = appendData(data, bwcValues)
  data = appendData(data, coValues)
  data = appendDrData(data, drValues, articles)
  data = appendData(data, moValues)
  let list = _.orderBy(Object.values(data), (o) => +o[3]) // study day sort
  list = _.orderBy(list, [0, 1]); // group name and animalID sort
  return createDataSetForLongFormat(list, dynamiColumns, articles)
}
const downloadIcon = (study_model, study_id, loadingIcon, setLoadingIcon, loaded, setLoaded, setTvi, setTvci, setTvir, setTvil, setTvs, setTvcs, setTvsr, setTvsl, setTfs, setTfi, setBwi, setBws, setBwc, setBwcs, setCo, setDoa, setMo, setLF, getTaskMeasurementSummaryAPI, getTaskMeasurementIndividualAPI, dataExportAudit, hasApprovedData) => (
  <Nav.Item onClick={(e) => {
    if (!loaded && hasApprovedData) {
      e.stopPropagation()
      setLoadingIcon(true)
      new Promise((resolve) => resolve(downloadData(study_id, getTaskMeasurementSummaryAPI, getTaskMeasurementIndividualAPI))).then((result) => {
        dataExportAudit(study_model)
        if (result && result.length) {
          // if length of result[0].value.data.getTaskMeasurementSummary is exactly one than not one then dual flank
          if (result[0]?.value?.data?.getTaskMeasurementSummary?.length === 1) {
            result[0].value ? setTvs(fixSummaryData(formatTaskMeasurementSummaryGridOptions(result[0].value.data.getTaskMeasurementSummary[0].groups, false), 'TVS', study_model, result[0].value.data.getTaskMeasurementSummary[0])) : setTvs([])
            result[11].value ? setTvcs(fixSummaryData(formatBodyWeightChangeSummaryGridOptions(result[11].value.data.getTaskMeasurementSummary[0].groups, false), 'TVCS', study_model)) : setTvcs([])
          } else {
            // if length of result[0].value.data.getTaskMeasurementSummary is more than one then dual flank
            result[0].value ? setTvsr(fixSummaryData(formatTaskMeasurementSummaryGridOptions(result[0].value.data.getTaskMeasurementSummary[0].groups, false), 'TVSR', study_model, result[0].value.data.getTaskMeasurementSummary[0])) : setTvsr([])
            result[0].value ? setTvsl(fixSummaryData(formatTaskMeasurementSummaryGridOptions(result[0].value.data.getTaskMeasurementSummary[1].groups, false), 'TVSL', study_model, result[0].value.data.getTaskMeasurementSummary[1])) : setTvsl([])
          }
          // if length of result[0].value.data.getTaskMeasurementIndividual is exactly one than not one then dual flank
          if (result[1]?.value?.data?.getTaskMeasurementIndividual?.length === 1) {
            result[1].value ? setTvi(convertToDataset(formatTaskMeasurementIndividualGridOptions(result[1].value.data.getTaskMeasurementIndividual[0].groups, false), 'TVI', study_model)) : setTvi([])
            result[12].value ? setTvci(convertToDataset(formatBodyWeightChangeIndividualGridOptions(result[12].value.data.getTaskMeasurementIndividual[0].groups, false), 'TVCI', study_model)) : setTvci([])
          } else {
            // if length of result[0].value.data.getTaskMeasurementIndividual is more than one then dual flank
            result[1].value ? setTvir(convertToDataset(formatTaskMeasurementIndividualGridOptions(result[1].value.data.getTaskMeasurementIndividual[0].groups, false), 'TVIR', study_model)) : setTvir([])
            result[1].value ? setTvil(convertToDataset(formatTaskMeasurementIndividualGridOptions(result[1].value.data.getTaskMeasurementIndividual[1].groups, false), 'TVIL', study_model)) : setTvil([])
          }
          result[2].value ? setBwi(convertToDataset(formatTaskMeasurementIndividualGridOptions(result[2].value.data.getTaskMeasurementIndividual[0].groups, false), 'BWI', study_model)) : setBwi([])
          result[3].value ? setBws(fixSummaryData(formatTaskMeasurementSummaryGridOptions(result[3].value.data.getTaskMeasurementSummary[0].groups, false), 'BWS', study_model)) : setBws([])
          result[4].value ? setBwc(convertToDataset(formatBodyWeightChangeIndividualGridOptions(result[4].value.data.getTaskMeasurementIndividual[0].groups, false), 'BWC', study_model)) : setBwc([])
          result[5].value ? setBwcs(fixSummaryData(formatBodyWeightChangeSummaryGridOptions(result[5].value.data.getTaskMeasurementSummary[0].groups, false), 'BWCS', study_model)) : setBwcs([])
          result[6].value ? setCo(convertToDataset(formatClinicalObservationIndividualGridOptions(result[6].value.data.getTaskMeasurementIndividual[0].groups, false), 'CO', study_model, result[6].value.data.getTaskMeasurementIndividual[0].legend)) : setCo([])
          result[7].value ? setDoa(convertToDataset(fixDosingData(formatDosingRecordIndividualGridOptions(result[7].value.data.getTaskMeasurementIndividual[0].groups, false)), 'DR', study_model)) : setDoa([])
          result[8].value ? setMo(convertToDataset(formatMortalityObservationIndividualGridOptions(result[8].value.data.getTaskMeasurementIndividual[0].groups, false), 'MO', study_model, result[8].value.data.getTaskMeasurementIndividual[0].legend)) : setMo([])
          setLF(getRowsDataForLongFormat(result))
          if (result[9]?.value?.data?.getTaskMeasurementSummary) {
            let meanTaskSummary;
            let ventralTaskSummary;
            let dorsalTaskSummary;
            result[9].value.data.getTaskMeasurementSummary.forEach((taskSummary) => {
              if (taskSummary?.task_name === 'Mean' || taskSummary?.task_name === 'Volume') {
                meanTaskSummary = taskSummary;
              }
              if (taskSummary?.task_name === 'Ventral') {
                ventralTaskSummary = taskSummary;
              }
              if (taskSummary?.task_name === 'Dorsal') {
                dorsalTaskSummary = taskSummary;
              }
            });
            setTfs(fixSummaryDataTfs(
              meanTaskSummary ? formatTaskMeasurementSummaryGridOptions(meanTaskSummary.groups, false) : undefined,
              ventralTaskSummary ? formatTaskMeasurementSummaryGridOptions(ventralTaskSummary.groups, false) : undefined,
              dorsalTaskSummary ? formatTaskMeasurementSummaryGridOptions(dorsalTaskSummary.groups, false) : undefined,
              'TFS', study_model, result[9].value.data.getTaskMeasurementSummary,
            ))
          } else {
            setTfs([])
          }
          if (result[10]?.value?.data?.getTaskMeasurementIndividual) {
            let meanTaskIndividual;
            let ventralTaskIndividual;
            let dorsalTaskIndividual;
            result[10].value.data.getTaskMeasurementIndividual.forEach((taskIndividual) => {
              if (taskIndividual?.task_name === 'Mean' || taskIndividual?.task_name === 'Volume') {
                meanTaskIndividual = taskIndividual;
              }
              if (taskIndividual?.task_name === 'Ventral') {
                ventralTaskIndividual = taskIndividual;
              }
              if (taskIndividual?.task_name === 'Dorsal') {
                dorsalTaskIndividual = taskIndividual;
              }
            });
            setTfi(convertToDataset(
              meanTaskIndividual ? formatTaskMeasurementIndividualGridOptions(meanTaskIndividual.groups, false) : undefined,
              'TFI', study_model, null, null, undefined, undefined,
              ventralTaskIndividual ? formatTaskMeasurementIndividualGridOptions(ventralTaskIndividual.groups, false) : undefined,
              dorsalTaskIndividual ? formatTaskMeasurementIndividualGridOptions(dorsalTaskIndividual.groups, false) : undefined,
            ))
          } else {
            setTfi([])
          }
          setLoaded(true)
          setLoadingIcon(false)
        }
      })
    } else {
      // Allow for download of export multiple times
      setLoaded(false)
    }
  }}
  >
    {hasApprovedData ? (
      <Nav.Link className="model-tab-download-cell">
        <>
          {loadingIcon ? (<img className="svg-loader" src={LoadingSpinnerIcon} alt="loading-spinner" />) : (<img src={DownloadIconM} alt="download-icon" />)}
        </>
      </Nav.Link>
    ) : (
      <Nav.Link className="modelTab-downloadDisabledLink disabled-link">
        <>
          <img src={DownloadIconDisabled} alt="disabled-download-icon" />
        </>
      </Nav.Link>
    )}
  </Nav.Item>
)

export default ExportDownload;
