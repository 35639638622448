import React, { useState, useEffect } from 'react';
import { Tab } from 'react-bootstrap';

const ControlledTabsContainer = ({ defaultActiveKey, children }) => {
  const [key, setKey] = useState(defaultActiveKey);
  useEffect(() => {

  }, [key]);
  //  <Row>
  //     <Col sm={3}>
  //       <Nav variant="pills" className="flex-column">
  //         <Nav.Item>
  //           <Nav.Link eventKey="first">Tab 1</Nav.Link>
  //         </Nav.Item>
  //         <Nav.Item>
  //           <Nav.Link eventKey="second">Tab 2</Nav.Link>
  //         </Nav.Item>
  //       </Nav>
  //     </Col>
  //     <Col sm={9}>
  //       <Tab.Content>
  //         <Tab.Pane eventKey="first">
  //           first content
  //         </Tab.Pane>
  //         <Tab.Pane eventKey="second">
  //           second content
  //         </Tab.Pane>
  //       </Tab.Content>
  //     </Col>
  //   </Row>
  //   <Row>
  //     <Col sm={3}>
  //       <Nav variant="pills" className="flex-column">
  //         <Nav.Item>
  //           <Nav.Link eventKey="first">Tab 1</Nav.Link>
  //         </Nav.Item>
  //         <Nav.Item>
  //           <Nav.Link eventKey="second">Tab 2</Nav.Link>
  //         </Nav.Item>
  //       </Nav>
  //     </Col>
  //     <Col sm={9}>
  //       <Tab.Content>
  //         <Tab.Pane eventKey="first">
  //           first content
  //         </Tab.Pane>
  //         <Tab.Pane eventKey="second">
  //           second content
  //         </Tab.Pane>
  //       </Tab.Content>
  //     </Col>
  //   </Row>
  //   <Row>
  //     <Col sm={3}>
  //       <Nav variant="pills" className="flex-column">
  //         <Nav.Item>
  //           <Nav.Link eventKey="first">Tab 1</Nav.Link>
  //         </Nav.Item>
  //         <Nav.Item>
  //           <Nav.Link eventKey="second">Tab 2</Nav.Link>
  //         </Nav.Item>
  //       </Nav>
  //     </Col>
  //     <Col sm={9}>
  //       <Tab.Content>
  //         <Tab.Pane eventKey="first">
  //           first content
  //         </Tab.Pane>
  //         <Tab.Pane eventKey="second">
  //           second content
  //         </Tab.Pane>
  //       </Tab.Content>
  //     </Col>
  //   </Row>
  return (
    <Tab.Container transition={false} defaultActiveKey={defaultActiveKey} onSelect={(k) => setKey(k)}>
      {children}
    </Tab.Container>
  );
};

export default ControlledTabsContainer;
