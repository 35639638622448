import React from 'react';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import version from '../version.json'
import footerCrownLogo from './landing/New-Logo-Crown-Bioscience.png';

const NavFooter = function NavFooter() {
  return (
    <div className="footer broad-footer">
      <div className="footer-links footer-link-margin-top">
        <div className="box-outer-padding w-300">
          <div className="box-subhead AvenirNextLTProMedium">{/* Features */}</div>
          <div>
            <div>
              <Link className="footer-link AvenirNextLTProRegular" to="/termsofuse" target="_blank" rel="noreferrer" color="inherit" underline="hover">
                Terms of Use
              </Link>
            </div>
            <div>
              <Link className="footer-link AvenirNextLTProRegular" to="/privacypolicy" target="_blank" rel="noreferrer" color="inherit" data-testid="privacy-policy-test" underline="hover">
                Privacy Policy
              </Link>
            </div>
            <div>
              <Link className="footer-link AvenirNextLTProRegular" to="/acceptableusepolicy" target="_blank" rel="noreferrer" color="inherit" data-testid="acceptable-use-policy-test" underline="hover">
                Acceptable Use Policy
              </Link>
            </div>
            <div>
              <a className="footer-link AvenirNextLTProRegular" href="/" color="inherit">
                {/* Study Data */}
              </a>
            </div>
            <div>
              <a className="footer-link AvenirNextLTProRegular" href="/">
                {/* Study Documenatation */}
              </a>
            </div>
          </div>
        </div>
        <div className="box-outer-padding">
          <div className="box-subhead AvenirNextLTProMedium">{/* Resource */}</div>
          <div>
            <a className="footer-link AvenirNextLTProRegular" href="/" color="inherit">
              {/* About Saturn */}
            </a>
          </div>
          <div>
            <a className="footer-link AvenirNextLTProRegular" href="/" color="inherit">
              {/* Password Reset */}
            </a>
          </div>
          <div>
            <a className="footer-link AvenirNextLTProRegular" href="/" color="inherit">
              {/* Report an Issue */}
            </a>
          </div>
          <div>
            <a className="footer-link AvenirNextLTProRegular" href="/">
              {/* Request Access */}
            </a>
          </div>
        </div>
        <div className="box-outer-padding">
          <div className="box-subhead AvenirNextLTProMedium">{/* About */}</div>
          <div>
            <a className="footer-link AvenirNextLTProRegular" href="/" color="inherit">
              {/* Location */}
            </a>
          </div>
          <div>
            <a className="footer-link AvenirNextLTProRegular" href="/" color="inherit">
              {/* Dosing Schedule */}
            </a>
          </div>
          <div>
            <a className="footer-link AvenirNextLTProRegular" href="/" color="inherit">
              {/* Terms */}
            </a>
          </div>
        </div>

        <div>
          <Box className="copyrights-img">
            <span>
              <img src={footerCrownLogo} alt="help" />
            </span>
          </Box>
          <Box className="copyrights">
            <span className="AvenirNextLTProRegular">
              © 2021 Crown Bioscience Inc. All rights reserved.
            </span><br />
            <span className="version">
              {window.location.host.endsWith('cn') && (
                <a className="icp-china-url" href="https://beian.miit.gov.cn/" target="_blank" color="inherit" rel="noreferrer">
                  苏ICP备10097610号-6
                </a>
              )}  Version {version.version}
            </span>
          </Box>
        </div>

      </div>

    </div>
  );
}
export default NavFooter;
