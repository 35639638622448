import React, { useState, useEffect } from 'react'
import {
  Container, Card, Button as RBbtn, Form, Row, Col, Spinner,
} from 'react-bootstrap'
import _ from 'lodash'
import moment from 'moment';
import Typography from '@mui/material/Typography'
// imports for popup Element
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { Box } from '@mui/material';
import './study/popupStyleNew.css'
import IconStudyDataV1 from './study/IconStudyDataV1.svg';
import DataFiltersHeader from './DataFiltersHeader'
import DateFilterComponent from './DateFiltercomponent'
// imports for popup Element Ends
import { useAuth } from '../../providers/auth';

import { GROUPS } from '../../constants';
import { truncationOfLongStr } from '../utils';

const Filters = function ({
  filterTgiStudyDays = [], AllExcludedGroup = [], setPFilters, isTumorVolumeDataAvailable, resetFlowStates, model, releasing, filtersLocked, setFiltersLocked, disabledDateFilters, TgiControlGroups = [], referenceDays = [], filterStudyDays = [], filterAllStudyDays = [], releaseData, children, filtersLoading, cachedModelMetaData,
}) {

  const getDefaultDateRange = () => {
    let returnState = {
      range: '',
    }
    if (disabledDateFilters.dosingOption === false) {
      returnState.range = 'Dosing & Recovery Phase'
    }
    if (disabledDateFilters.allPhasesOption === false) {
      returnState = {
        range: 'Study Day Range',
        startDay: cachedModelMetaData?.filters?.dateRange?.startDay ?? getDay0or1IfExists(),
        endDay: cachedModelMetaData?.filters?.dateRange?.endDay ?? getLastDay(),
      }
    }
    return returnState
  }
  const { hasGroup } = useAuth()
  const isJsd = hasGroup('JuniorStudyDirector') && !hasGroup('StudyDirector');
  const isSD = hasGroup('StudyDirector');
  const sortedAllExcludedGroup = _.sortBy(AllExcludedGroup.filter((group) => group.group_name !== 'Unassigned' && group.animals.length > 0), 'group_name_to_show')
  const sortedExcludeGroups = _.sortBy(TgiControlGroups.filter((group) => group.group_name !== 'Unassigned' && group.animals.length > 0), 'group_name_to_show')
  const doesCurrentUserHasStudyOrJuniorStudyDirector = () => hasGroup(GROUPS.STUDY_DIRECTOR) || hasGroup(GROUPS.JUNIOR_STUDY_DIRECTOR)
  const parseDayToDigit = (day) => (day ? day.split(' ')[1] : '');
  const getDay0or1IfExists = () => {
    const day0or1 = filterAllStudyDays.filter((x) => x[1] === 'Day 0' || x[1] === 'Day 1')
    if (day0or1?.length > 0) {
      return parseDayToDigit(day0or1[0][1])
    }

    return -1;
  }
  const getLastDay = () => {
    if (filterAllStudyDays?.length > 0) return parseDayToDigit(filterAllStudyDays[filterAllStudyDays.length - 1][1])
    return -1
  }
  const resolveDateRange = () => {
    if (cachedModelMetaData?.filters?.dateRange?.startDay && doesCurrentUserHasStudyOrJuniorStudyDirector()) {

      return { ...cachedModelMetaData.filters.dateRange }
    }
    return getDefaultDateRange()
  }
  const [filters, setFilters] = useState(() => {
    const resolvedFilters = {
      dateRange: resolveDateRange(),
      unassignedMice: 'Exclude',
      survivalAnalysis: 'Include',
      dosingTimes: 'Exclude',
      excludeGroups: 'None',
      referenceDayForBWC: '0',
      referenceDayForTGI: '-1',
      controlGroup: `${sortedExcludeGroups[0]?.group_name}`,
    }
    return resolvedFilters
  })
  const [active, setActive] = useState('DefaultFilters');
  const [switchRole, setSwitchRole] = useState('SD');

  useEffect(() => {
    if (cachedModelMetaData?.filters) {
      setFilters((prevState) => {
        const TgiControlGroup = Object.keys(cachedModelMetaData.filters)?.length === 0 ? prevState?.controlGroup : 'No TGI';
        return {
          dateRange: cachedModelMetaData?.filters?.dateRange ?? filters.dateRange, // getSavedDateRangeFilters(cachedModelMetaData),
          excludeGroups: cachedModelMetaData?.filters?.excludeGroups?.length ? cachedModelMetaData.filters.excludeGroups : prevState?.excludeGroups,
          unassignedMice: cachedModelMetaData?.filters?.unassignedMice ? 'Include' : prevState?.unassignedMice,
          survivalAnalysis: cachedModelMetaData?.filters?.survivalAnalysis || prevState?.survivalAnalysis,
          dosingTimes: cachedModelMetaData?.filters?.dosingTimes || prevState?.dosingTimes,
          referenceDayForBWC: cachedModelMetaData?.filters?.referenceDayForBWC ? cachedModelMetaData.filters.referenceDayForBWC : prevState?.referenceDayForBWC,
          controlGroup: cachedModelMetaData?.filters?.controlGroup ? cachedModelMetaData.filters.controlGroup : TgiControlGroup,
          referenceDayForTGI: cachedModelMetaData?.filters?.referenceDayForTGI || cachedModelMetaData?.filters?.referenceDayForTGI === 0 ? cachedModelMetaData.filters.referenceDayForTGI : prevState?.referenceDayForTGI,
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cachedModelMetaData]);

  const handleOnChange = (event) => {
    event.stopPropagation();
    const { name } = event.target;
    if (name === 'dateRange') {
      let newDate = {
        range: event.target.value,
      }

      if (event.target.value === 'Study Day Range') {
        newDate = {
          range: event.target.value,
          startDay: getDefaultDate(filters),
          endDay: getDefaultDate(filters, false),
        }
      }
      setFilters({ ...filters, dateRange: newDate })
    } else {
      setFilters({ ...filters, [name]: event.target.value })
    }
  }

  const handleOnChangeDateRangeStart = (event) => {
    event.stopPropagation();
    const newDate = {
      ...filters.dateRange,
      startDay: event.target.value,
    }
    setFilters({ ...filters, dateRange: newDate })
  }

  const handleOnChangeDateRangeEnd = (event) => {
    event.stopPropagation();
    const newDate = {
      ...filters.dateRange,
      endDay: event.target.value,
    }
    setFilters({ ...filters, dateRange: newDate })
  }

  const handleOnChangeExcludeGroups = (event) => {
    event.stopPropagation();
    const selectedValues = Array.from(event.target.selectedOptions, (option) => option.value);
    setFilters({ ...filters, excludeGroups: selectedValues });
  }

  useEffect(() => {
    if (sortedExcludeGroups.length && filters.controlGroup === 'undefined') {
      const resolvedFilters = { ...filters, controlGroup: `${sortedExcludeGroups[0]?.group_name}` }
      setFilters(resolvedFilters);
    }
  }, [filters, filterAllStudyDays, sortedExcludeGroups]);

  const normalizeFilters = () => {
    // const { controlGroup, ...finalFilters } = { ...filters }
    // ToDo CCP-1163 - swap above with below once BE is ready to accept controlGroup
    // const finalFilters = { ...filters }
    const { controlGroup, ...finalFiltersNoTGI } = { ...filters }
    const finalFilters = filters.controlGroup === 'No TGI' ? finalFiltersNoTGI : { ...filters }
    finalFilters.unassignedMice = finalFilters.unassignedMice !== 'Exclude'
    finalFilters.referenceDayForBWC = parseInt(finalFilters.referenceDayForBWC)
    finalFilters.referenceDayForTGI = parseInt(finalFilters.referenceDayForTGI)
    if (finalFilters.referenceDayForTGI === -1 && allTgiDays.length && filters.controlGroup !== 'No TGI') {
      finalFilters.referenceDayForTGI = allTgiDays[0].study_day
      setFilters({ ...filters, referenceDayForTGI: allTgiDays[0].study_day })
    } else if (filters.controlGroup === 'No TGI') {
      finalFilters.referenceDayForTGI = -1
      setFilters({ ...filters, referenceDayForTGI: -1 })
    }
    /* eslint-disable-next-line no-nested-ternary */
    finalFilters.excludeGroups = finalFilters.excludeGroups !== 'None' ? (typeof finalFilters.excludeGroups === 'string' ? [finalFilters.excludeGroups] : finalFilters.excludeGroups) : []
    // no normalization needed for controlGroup
    return finalFilters
  }

  const handleOnSubmit = (event) => {
    event.preventDefault();
    setFiltersLocked(true);
    const finalFilters = normalizeFilters();
    setPFilters(finalFilters);
  }

  const handleOnCancel = () => {
    resetFlowStates();
  }

  const uniqueReferenceDays = _.uniqBy(
    _(referenceDays)
      .flatMap('animals')
      .flatMap('sessions')
      .map((session) => session)
      .value(),
    'study_day',
  )

  const uniqueReferenceDaysForTGI = _.uniqBy(
    _(filterTgiStudyDays)
      .flatMap('animals')
      .flatMap('sessions')
      .map((session) => session)
      .value(),
    'study_day',
  )

  const sortedUniqueReferenceDays = _.sortBy(uniqueReferenceDays, 'study_day')
  const allTgiDays = _.sortBy(uniqueReferenceDaysForTGI, 'study_day')

  const day0 = sortedUniqueReferenceDays.find((day) => day.study_day === 0)

  const day1 = sortedUniqueReferenceDays.find((day) => day.study_day === 1)

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const roleSwitch = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (switchRole === 'SD') {
      setSwitchRole('JSD');
    } else {
      setSwitchRole('SD');
    }
  };

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
  };

  const getDefaultDate = (filters, callingToGetStartDate = true) => {
    let startDay = null;
    let endDay = null;
    if (doesCurrentUserHasStudyOrJuniorStudyDirector()
      && !filters?.dateRange?.startDay
      && !cachedModelMetaData?.filters?.dateRange?.startDay
    ) {
      if (callingToGetStartDate) {
        const day0or1 = getDay0or1IfExists()
        if (day0or1 > -1) {
          startDay = day0or1
        } else {
          startDay = parseDayToDigit(filterAllStudyDays[0][1])
        }
        return startDay;
      }
      endDay = parseDayToDigit(filterAllStudyDays[filterAllStudyDays.length - 1][1])
      return endDay;
    }

    if (cachedModelMetaData?.filters?.dateRange?.startDay) {

      if (callingToGetStartDate) {
        return cachedModelMetaData?.filters?.dateRange?.startDay
      }
      return cachedModelMetaData?.filters?.dateRange?.endDay
    }

    return cachedModelMetaData?.filters?.dateRange

  }
  // Code for popup element Ends
  return (
    <Container fluid={false} className="study-details-data-filters">
      <Card>
        <Card.Header className="text-center text-md-left study-data-filters-header">
          <Typography className="card-data-filters">
            Data Filters:
          </Typography>
        </Card.Header>
        <Card.Body className="studyDetails-dataFilters-cardBody">
          {
            filtersLoading
              ? (
                <Row style={{ margin: '10px 0px 5px 32px' }} className="justify-content-center">
                  <RBbtn variant="primary" disabled>
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    Loading Filters...
                  </RBbtn>
                </Row>
              )
              : (
                <Form onSubmit={(event) => handleOnSubmit(event)}>

                  {/* show nested Study Day Range form */}
                  {
                    active === 'DefaultFilters'
                    && (
                      <Row>
                        {filters.dateRange?.range !== 'Dosing & Recovery Phase' && filters.dateRange?.range !== 'All Phases' && filters.dateRange?.range !== 'Study Date Range'
                          ? (
                            <Col style={{ paddingLeft: '0px' }} lg={2} md={3}>
                              <Form.Group
                                controlId="formBasicSelect"
                                className="studyDetails-dataFilters-formGroup"
                                style={{ width: '-webkit-fill-available' }}
                              >
                                <Form.Label className="studyDetails-dataFilters-formLabel">Date Range</Form.Label>
                                <Form.Control
                                  name="dateRange"
                                  style={{ height: '56px' }}
                                  as="select"
                                  // below is alternative method
                                  // value={filters.dateRange}
                                  // force uncontrolled default value to be Study Day Range for UX for forthcoming nested form value
                                  defaultValue={getDefaultDateRange().range}
                                  // value={'Study Day Range'}
                                  onChange={(event) => handleOnChange(event)}
                                  disabled={!filterAllStudyDays.length}
                                  data-testid="Select_Option"
                                >
                                  {/* allow parent form to reset nested form */}
                                  {/* below is alternative method */}
                                  {/* <option value="">Study Day Range</option> */}
                                  {/* uphold forced uncontrolled default value to be Study Day Range */}
                                  <option disabled={disabledDateFilters.allPhasesOption || !filterAllStudyDays.length} value="Study Day Range">Study Day Range</option>
                                  <option disabled={disabledDateFilters.dosingOption || !filterAllStudyDays.length} value="Dosing & Recovery Phase">Dosing & Recovery Phase</option>
                                  <option disabled={disabledDateFilters.allPhasesOption || !filterAllStudyDays.length} value="All Phases">All Phases</option>

                                </Form.Control>
                              </Form.Group>
                            </Col>
                          )
                          : (
                            <Col style={{ paddingLeft: '0px' }} lg={2} md={3}>
                              <Form.Group
                                controlId="formBasicSelect"
                                className="studyDetails-dataFilters-formGroup"
                              >
                                <Form.Label className="studyDetails-dataFilters-formLabel">Date Range</Form.Label>
                                <Form.Control
                                  name="dateRange"
                                  as="select"
                                  data-testid="Select_Option"
                                  value={filters.dateRange.range}
                                  onChange={(event) => handleOnChange(event)}
                                  style={{ height: '56px' }}
                                  disabled={filtersLocked || filterAllStudyDays.length === 0}
                                >
                                  {/* force value to be set as empty for forthcoming nested form value */}
                                  <option disabled={disabledDateFilters.allPhasesOption} value="Study Day Range" data-testid="study_Day_Range">Study Day Range</option>
                                  <option disabled={disabledDateFilters.dosingOption} value="Dosing & Recovery Phase">Dosing & Recovery Phase</option>
                                  <option disabled={disabledDateFilters.allPhasesOption} value="All Phases">All Phases</option>

                                </Form.Control>
                              </Form.Group>
                            </Col>
                          )}
                        {
                          (filters.dateRange?.range !== 'Dosing & Recovery Phase' && filters.dateRange?.range !== 'All Phases' && filters.dateRange?.range !== 'Study Date Range') && (
                            <Col style={{ paddingLeft: '0px' }} lg={2} md={3}>
                              <Row>
                                <DateFilterComponent
                                  label="Start Date"
                                  value={filters.dateRange?.startDay}
                                  onChange={handleOnChangeDateRangeStart}
                                  defaultValue={filters.dateRange?.startDay}
                                  filterAllStudyDays={filterAllStudyDays}
                                  filterStudyDays={filterStudyDays}
                                  handleOnChangeDateRange={handleOnChangeDateRangeStart}
                                  parseDayToDigit={parseDayToDigit}
                                  testIdPrefix="startDate"
                                />

                                <DateFilterComponent
                                  label="End Date"
                                  value={filters.dateRange?.endDay}
                                  onChange={handleOnChangeDateRangeEnd}
                                  defaultValue={filters.dateRange?.endDay}
                                  filterAllStudyDays={filterAllStudyDays}
                                  filterStudyDays={filterStudyDays}
                                  handleOnChangeDateRange={handleOnChangeDateRangeEnd}
                                  parseDayToDigit={parseDayToDigit}
                                  testIdPrefix="endDate"
                                />
                              </Row>
                            </Col>
                          )
                        }
                        {
                          filters.excludeGroups !== 'None'
                            ? (
                              <Col style={{ paddingLeft: '0px' }} lg={2} md={3}>
                                <Form.Group
                                  controlId="formBasicSelect"
                                  className="studyDetails-dataFilters-formGroup"
                                  style={{ width: '-webkit-fill-available' }}
                                >
                                  <Form.Label className="studyDetails-dataFilters-formLabel">Excluded Groups</Form.Label>
                                  <Form.Control
                                    name="excludeGroups"
                                    style={{ height: '56px' }}
                                    as="select"
                                    // below is alternative method
                                    // value={filters.excludedGroups}
                                    // force uncontrolled default value to be Group Selection(s) for UX for forthcoming nested form value
                                    defaultValue="Group Selection(s)"
                                    value="Group Selection(s)"
                                    onChange={(event) => handleOnChange(event)}
                                    disabled={filtersLocked}
                                  >
                                    {/* allow parent form to reset nested form */}
                                    {/* below is alternative method */}
                                    {/* <option value="">Group Selection(s)</option> */}
                                    <option value="None">None</option>
                                    {/* uphold forced uncontrolled default value to be Group Selection(s) */}
                                    <option value="Group Selection(s)">Group Selection(s)</option>
                                  </Form.Control>
                                </Form.Group>
                              </Col>
                            ) : (
                              <Col style={{ paddingLeft: '0px' }} lg={2} md={3}>
                                <Form.Group
                                  controlId="formBasicSelect"
                                  className="studyDetails-dataFilters-formGroup"
                                >
                                  <Form.Label className="studyDetails-dataFilters-formLabel">Excluded Groups</Form.Label>
                                  <Form.Control
                                    name="excludeGroups"
                                    as="select"
                                    data-testid="excludeGroups_Options"
                                    value={filters.excludeGroups}
                                    onChange={(event) => handleOnChange(event)}
                                    style={{ height: '56px' }}
                                    disabled={filtersLocked}
                                    required
                                  >
                                    <option value="None" data-testid="Excluded_Groups_option1">None</option>
                                    {/* force value to be set as empty for forthcoming nested form value */}
                                    <option value="" data-testid="Excluded_Groups_option2">Group Selection(s)</option>
                                  </Form.Control>
                                </Form.Group>
                              </Col>
                            )
                        }
                        {
                          filters.excludeGroups !== 'None'
                            && (
                              <Col style={{ paddingLeft: '0px' }} lg={2} md={3}>
                                <Form.Group
                                  controlId="formBasicSelect"
                                  className="studyDetails-dataFilters-formGroup"
                                  style={{ width: '-webkit-fill-available' }}
                                >
                                  <Form.Label className="studyDetails-dataFilters-formLabel">Groups</Form.Label>
                                  <Form.Control
                                    name="excludeGroups"
                                    as="select"
                                    onChange={(event) => handleOnChangeExcludeGroups(event)}
                                    // require nested form value
                                    value={filters.excludeGroups}
                                    required
                                    multiple
                                    disabled={filtersLocked}
                                  >
                                    {
                                      sortedAllExcludedGroup.map((group) => <option key={group.group_name} value={group.group_name}>{group?.group_name_to_show}</option>)
                                    }
                                  </Form.Control>
                                </Form.Group>
                              </Col>
                            )
                          }
                        <Col style={{ paddingLeft: '0px' }} lg={2} md={3}>
                          <Form.Group
                            controlId="formBasicSelect"
                            className="studyDetails-dataFilters-formGroup"
                          >
                            <Form.Label className="studyDetails-dataFilters-formLabel">Unassigned Mice</Form.Label>
                            <Form.Control
                              name="unassignedMice"
                              as="select"
                              data-testid="UnassignedMice_Option"
                              value={filters.unassignedMice}
                              onChange={(event) => handleOnChange(event)}
                              style={{ height: '56px' }}
                              disabled={filtersLocked}
                            >
                              <option value="Exclude">Exclude</option>
                              <option value="Include">Include</option>
                            </Form.Control>
                          </Form.Group>
                        </Col>
                        <Col style={{ paddingLeft: '0px' }} lg={2} md={3}>
                          <Form.Group
                            controlId="formBasicSelect"
                            className="studyDetails-dataFilters-formGroup"
                          >
                            <Form.Label className="studyDetails-dataFilters-formLabel">Reference Day (BWC)</Form.Label>
                            <Form.Control
                              name="referenceDayForBWC"
                              as="select"
                              data-testid="referenceDayForBWC_Option"
                              value={filters.referenceDayForBWC}
                              onChange={(event) => handleOnChange(event)}
                              style={{ height: '56px' }}
                              disabled={filtersLocked}
                            >
                              <option value="" disabled>Common Options</option>
                              {day0 !== undefined && <option value={0}>Day 0 ({moment(day0.study_date, 'MM/DD/YYYY').format('DD MMM YYYY')})</option>}
                              {day1 !== undefined && <option value={1}>Day 1 ({moment(day1.study_date, 'MM/DD/YYYY').format('DD MMM YYYY')})</option>}
                              {sortedUniqueReferenceDays.length && <option key={sortedUniqueReferenceDays[0].study_day} value={sortedUniqueReferenceDays[0].study_day}>Day {sortedUniqueReferenceDays[0].study_day} ({moment(sortedUniqueReferenceDays[0].study_date, 'MM/DD/YYYY').format('DD MMM YYYY')})</option>}
                              <option value="" disabled>All Options</option>
                              {
                                sortedUniqueReferenceDays.map((day) => <option key={day.study_day} value={day.study_day}>Day {day.study_day} ({moment(day.study_date, 'MM/DD/YYYY').format('DD MMM YYYY')})</option>)
                              }
                            </Form.Control>
                          </Form.Group>
                        </Col>
                        { isTumorVolumeDataAvailable && (
                        <>
                          <Col style={{ paddingLeft: '0px' }} lg={2} md={3}>
                            <Form.Group controlId="formBasicSelect" className="studyDetails-dataFilters-formGroup">
                              <Form.Label className="studyDetails-dataFilters-formLabel">TGI Control Group</Form.Label>
                              <Form.Control
                                name="controlGroup"
                                as="select"
                                data-testid="TGI_Control_Group_Options"
                                value={filters.controlGroup}
                                onChange={(event) => handleOnChange(event)}
                                style={{ height: '56px' }}
                                disabled={filtersLocked}
                              >
                                {sortedExcludeGroups.map((group) => <option key={group.group_name} value={group.group_name}>{group?.group_name_to_show}</option>)}
                                <option value="No TGI">No TGI</option>
                              </Form.Control>
                            </Form.Group>
                          </Col>
                          {filters.controlGroup !== 'No TGI' && (
                          <Col style={{ paddingLeft: '0px' }} lg={2} md={3}>
                            <Form.Group controlId="formBasicSelect" className="studyDetails-dataFilters-formGroupBottom">
                              <Form.Label className="studyDetails-dataFilters-formLabel">Reference Day (TVC & TGI)</Form.Label>
                              <Form.Control
                                name="referenceDayForTGI"
                                as="select"
                                data-testid="referenceDayForTGI_Option"
                                value={filters.referenceDayForTGI}
                                onChange={(event) => handleOnChange(event)}
                                style={{ height: '56px' }}
                                disabled={filtersLocked}
                              >
                                <option value="" disabled>Common Options</option>
                                {allTgiDays.length && (
                                  <>
                                    {allTgiDays.some((day) => day.study_day === 0) && (
                                    <option value={0}>Day 0 ({moment(allTgiDays.find((day) => day.study_day === 0).study_date, 'MM/DD/YYYY').format('DD MMM YYYY')})</option>
                                    )}
                                    {allTgiDays.some((day) => day.study_day === 1) && (
                                    <option value={1}>Day 1 ({moment(allTgiDays.find((day) => day.study_day === 1).study_date, 'MM/DD/YYYY').format('DD MMM YYYY')})</option>
                                    )}
                                    {!allTgiDays.some((day) => day.study_day === 0 || day.study_day === 1) && (
                                    <option value={allTgiDays[0].study_day}>Day {allTgiDays[0].study_day} ({moment(allTgiDays[0].study_date, 'MM/DD/YYYY').format('DD MMM YYYY')})</option>
                                    )}
                                    <option value="" disabled>All Options</option>
                                    {allTgiDays.map((day) => (
                                      <option key={day.study_day} value={day.study_day}>Day {day.study_day} ({moment(day.study_date, 'MM/DD/YYYY').format('DD MMM YYYY')})</option>
                                    ))}
                                  </>
                                )}
                              </Form.Control>
                            </Form.Group>
                          </Col>
                          )}
                        </>
                        )}
                        <Col style={{ paddingLeft: '0px' }} lg={2} md={3}>
                          <Form.Group
                            controlId="formBasicSelect"
                            className="studyDetails-dataFilters-formGroup"
                          >
                            <Form.Label className="studyDetails-dataFilters-formLabel">Include Survival Analysis?</Form.Label>
                            <Form.Control
                              name="survivalAnalysis"
                              as="select"
                              data-testid="SurvivalAnalysis_Option"
                              value={filters.survivalAnalysis}
                              onChange={(event) => handleOnChange(event)}
                              style={{ height: '56px' }}
                              disabled={filtersLocked}
                            >
                              <option value="Include">Include</option>
                              <option value="Exclude">Exclude</option>
                            </Form.Control>
                          </Form.Group>
                        </Col>
                        <Col style={{ paddingLeft: '0px' }} lg={2} md={3}>
                          <Form.Group
                            controlId="formBasicSelect"
                            className="studyDetails-dataFilters-formGroup"
                          >
                            <Form.Label className="studyDetails-dataFilters-formLabel">Include Dosing Times?</Form.Label>
                            <Form.Control
                              name="dosingTimes"
                              as="select"
                              data-testid="DosingTimes_Option"
                              value={filters.dosingTimes}
                              onChange={(event) => handleOnChange(event)}
                              style={{ height: '56px' }}
                              disabled={filtersLocked}
                            >
                              <option value="Exclude">Exclude</option>
                              <option value="Include">Include</option>
                            </Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                    )
                  }

                  {
                    active === 'FiltersTitleView' && (
                      <Row style={{ marginTop: '-2%', marginBottom: '1%' }}>
                        <Col><Row style={{ marginLeft: '0' }}><p style={{ fontFamily: 'AvenirNextLTPro-Medium' }}>Date Range:&nbsp;</p><p style={{ fontFamily: 'AvenirNextLTPro-Medium' }}>{filters.dateRange?.startDay ? `Day ${filters.dateRange.startDay}-Day ${filters.dateRange.endDay}` : filters.dateRange?.range}</p></Row></Col>
                        <Col><Row><p style={{ fontFamily: 'AvenirNextLTPro-Medium', marginBottom: '0' }}>Excluded Groups:&nbsp;</p><p style={{ fontFamily: 'AvenirNextLTPro-Medium' }}>{typeof filters.excludeGroups === 'object' ? filters.excludeGroups.map((groupName) => truncationOfLongStr(sortedAllExcludedGroup.filter((excludeGroup) => excludeGroup.group_name === groupName)[0]?.group_name_to_show)).join(', ') : filters.excludeGroups}</p></Row></Col>
                        <Col><Row><p style={{ fontFamily: 'AvenirNextLTPro-Medium', marginBottom: '0' }}>Excluded Groups:&nbsp;</p><p style={{ fontFamily: 'AvenirNextLTPro-Medium' }}>{typeof filters.excludeGroups === 'object' ? filters.excludeGroups.map((groupName) => truncationOfLongStr(sortedExcludeGroups.filter((excludeGroup) => excludeGroup.group_name === groupName)[0]?.group_name_to_show)).join(', ') : filters.excludeGroups}</p></Row></Col>
                        <Col><Row><p style={{ fontFamily: 'AvenirNextLTPro-Medium', marginBottom: '0' }}>Unassigned Mice:&nbsp;</p><p style={{ fontFamily: 'AvenirNextLTPro-Medium' }}>{filters.unassignedMice === 'Include' ? 'Include' : 'Exclude'}</p></Row></Col>
                        <Col><Row><p style={{ fontFamily: 'AvenirNextLTPro-Medium', marginBottom: '0' }}>Reference Day for (BWC):&nbsp;</p><p style={{ fontFamily: 'AvenirNextLTPro-Medium' }}>Day {filters.referenceDayForBWC}</p></Row></Col>
                        {isTumorVolumeDataAvailable
                        && (
                        <>
                          <Col>
                            <Row>
                              <p style={{ fontFamily: 'AvenirNextLTPro-Medium', marginBottom: '0' }}>
                                TGI Control Group:&nbsp;
                              </p>
                              <p style={{ fontFamily: 'AvenirNextLTPro-Medium' }}>
                                {
                                  filters.controlGroup !== 'undefined'
                                    ? truncationOfLongStr(sortedExcludeGroups.filter((excludeGroup) => excludeGroup.group_name === filters.controlGroup)[0]?.group_name_to_show) : 'No TGI'
                                }
                              </p>
                            </Row>
                          </Col>
                          <Col><Row><p style={{ fontFamily: 'AvenirNextLTPro-Medium', marginBottom: '0' }}>Reference Day (TVC & TGI):&nbsp;</p><p style={{ fontFamily: 'AvenirNextLTPro-Medium' }}>{[undefined, -1, '-1', null].includes(filters.referenceDayForTGI) ? 'None' : `Day ${filters.referenceDayForTGI}`}</p></Row></Col>
                        </>
                        ) }
                      </Row>
                    )
                  }

                  {/* eslint-disable-next-line no-nested-ternary */}
                  {/* Modal Popup */}
                  <div style={{ borderRadius: '0px 16px' }}>
                    <Box>
                      <RBbtn
                        type="submit"
                        disabled={filtersLocked}
                        className="studyDetails-dataFilters-formConfirmButton AvenirNextLTPro-Medium"
                        onClick={() => { handleClickOpen(); setActive('FiltersTitleView') }}
                      >
                        <b>Confirm Filters & Generate Preview</b>
                      </RBbtn>
                      <RBbtn
                        variant="danger"
                        onClick={() => { handleOnCancel(); handleClose(); }}
                        className="studyDetails-dataFilters-formCancelButton AvenirNextLTPro-Medium"
                      >
                        <b>Cancel</b>
                      </RBbtn>
                    </Box>
                    <Dialog
                      onClose={handleClose}
                      open={open}
                      // eslint-disable-next-line react/jsx-no-bind
                      style={{
                        transform: 'translate(0%, -49%)', height: '92vh', left: '0', top: '52%',
                      }}
                      disableEnforceFocus
                      disableEscapeKeyDown
                      hideBackdrop
                    >
                      <div id="popupHeader">
                        <DialogTitle>
                          Preview Data for {model.study_model}
                          {
                            releasing
                              ? (
                                <DialogActions style={{ padding: '0px' }}>
                                  <Button
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      handleClose();
                                      handleOnCancel();
                                    }}
                                    style={{ padding: '0px', minWidth: '0px' }}
                                  >
                                    <CancelOutlinedIcon sx={{ color: '#FFFFFFDE', opacity: '0.9' }} />
                                  </Button>
                                </DialogActions>
                              ) : null
                          }
                        </DialogTitle>
                      </div>
                      <DialogContent style={{ padding: '10px 24px' }}>
                        <Card style={{ border: '0' }}>
                          <Card.Header style={{ border: 'none', paddingTop: '15px', paddingBottom: '25px' }} className="text-center AvenirNextLTProDemi text-md-left d-flex"> {/* styling enhancements as per design */}
                            <img src={IconStudyDataV1} alt="logo" />
                            <Typography className="card-head-study AvenirNextLTProMedium">
                              Study Data
                            </Typography>
                          </Card.Header>
                          <Card.Body style={{ padding: '0' }}>
                            <Container fluid={false} className="study-details-data-filters" style={{ borderRadius: '0px 16px' }}>
                              <Card style={{ borderRadius: '0px 16px' }}>
                                <Card.Header style={{ backgroundColor: '#f3f3f3', borderRadius: '0px 16px 0px 0px' }} className="text-center text-md-left study-data-filters-header-popup">
                                  <Typography className="card-data-filters">
                                    Data Filters:
                                  </Typography>
                                </Card.Header>
                                <Card.Body style={{ backgroundColor: '#f3f3f3', borderRadius: '0px 0px 0px 16px' }} className="studyDetails-dataFilters-cardBody">
                                  {
                                    active === 'FiltersTitleView' && (
                                      <DataFiltersHeader
                                        isTumorVolumeDataAvailable={isTumorVolumeDataAvailable}
                                        filters={filters}
                                        sortedExcludeGroups={sortedExcludeGroups}
                                        sortedAllExcludedGroup={sortedAllExcludedGroup}

                                      />
                                    )
                                  }
                                  {/* eslint-disable-next-line no-nested-ternary */}
                                  {releasing
                                    ? (isJsd || (isSD && switchRole === 'JSD')
                                      ? (
                                        <>
                                          <RBbtn
                                            style={{ backgroundColor: 'green', marginRight: '5px' }}
                                            onClick={(e) => {
                                              e.preventDefault();
                                              e.stopPropagation();
                                              handleClose();
                                              const finalFilters = normalizeFilters()
                                              releaseData(finalFilters, model.study_id, 'APPROVED')
                                              resetFlowStates();
                                            }}
                                          >
                                            <b>Approve</b>
                                          </RBbtn>
                                          <RBbtn
                                            style={{ marginRight: '5px' }}
                                            variant="warning"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              e.stopPropagation();
                                              handleClose();
                                              const finalFilters = normalizeFilters()
                                              releaseData(finalFilters, model.study_id, 'ACTION_REQUIRED')
                                              resetFlowStates();
                                            }}
                                          >
                                            <b>Action Required</b>
                                          </RBbtn>
                                        </>
                                      ) : (
                                        <RBbtn
                                          type="submit"
                                          style={{ backgroundColor: 'green', marginRight: '1%' }}
                                          data-testid="Release_Data_Button"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            handleClose();
                                            const finalFilters = normalizeFilters()
                                            releaseData(finalFilters, model.study_id, 'RELEASED')
                                            resetFlowStates();
                                          }}
                                        >
                                          <b>Release New Data for {model.study_model}</b>
                                        </RBbtn>
                                      )
                                    ) : null}
                                  {
                                    releasing
                                      ? (
                                        <RBbtn
                                          variant="danger"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            handleOnCancel();
                                            handleClose();
                                          }}
                                          className="studyDetails-dataFilters-formCancelButton AvenirNextLTPro-Medium"
                                        >
                                          <b>Cancel</b>
                                        </RBbtn>
                                      ) : null
                                  }
                                  {
                                    releasing && isSD
                                      ? (
                                        <RBbtn
                                          className="btn btn-warning"
                                          onClick={roleSwitch}
                                          style={{ float: 'right', cursor: 'pointer' }}
                                        >
                                          {
                                            switchRole === 'SD'
                                              ? (
                                                <b>Switch Role to PSD/JSD</b>
                                              ) : (
                                                <b>Switch Role to SD</b>
                                              )
                                          }

                                        </RBbtn>
                                      ) : null
                                  }
                                </Card.Body>
                              </Card>
                            </Container>
                            {children}
                          </Card.Body>
                        </Card>
                      </DialogContent>
                    </Dialog>
                  </div>
                  {/* Modal Popup ENDS */}
                </Form>
              )
          }
        </Card.Body>
      </Card>
    </Container>
  )
}

export default Filters
