import { Button, Modal, Fade /* Alert */ } from 'react-bootstrap';
import React, { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

import Alert from '@mui/material/Alert';
import PropTypes from 'prop-types';
import './documents.css';

import _ from 'lodash';
import { useS3 } from '../../../providers/s3';
import { useFolder } from '../../../providers/folder';

const DeleteDocumentModal = function DeleteDocumentModal({ projectCode, onDeleteCompleted, deleteDocumentAudit = () => {} }) {
  const { deleteS3Object } = useS3();
  const { setDeleteFileName, deleteFileName } = useFolder();
  const [modalBody, setModalBody] = useState(<div>Are you sure you want to delete this file?</div>);
  const [loading, setLoading] = useState(false);

  const errorModalBody = (error) => (
    <Alert className="documents-AlertOnTop" sx={{ fontFamily: 'AvenirNextLTPro-Regular' }} severity="error">
      <span className="containerSubTitle">There was an error deleting your document</span>
      <hr />
      <span className="containerSubTitleFileName">{error.toString()}</span>
    </Alert>
  );

  const handleSubmit = async () => {
    if (deleteFileName) {
      setLoading(true)
      await deleteS3Object(deleteFileName, projectCode).catch((err) => {
        setModalBody(errorModalBody(err));
      });
      await deleteDocumentAudit()
      setLoading(false)
      onDeleteCompleted();
    } else {
      setModalBody(errorModalBody('This file has already been deleted'))
    }
  };

  return !_.isEmpty(deleteFileName) ? (
    <>
      <Fade in={!_.isEmpty(deleteFileName)}>
        <Modal show={!_.isEmpty(deleteFileName)} onHide={() => setDeleteFileName('')}>
          <Modal.Header className="documents-oldModal-header" closeButton={() => setDeleteFileName('')}>
            <Modal.Title>
              <span className="containerSubTitle">
                Delete File:{' '}
                <span className="containerSubTitleFileName">{deleteFileName}</span>
              </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="documents-oldModal-body">
            {modalBody}
          </Modal.Body>
          <Modal.Footer className="documents-oldModal-footer">
            <Button
              className="LoginFormSignInButton"
              variant="primary"
              onClick={() => handleSubmit()}
            >
              Confirm
              { loading && (
                <CircularProgress
                  size={20}
                  style={{
                    color: '#fff',
                    marginLeft: '3px',
                    marginTop: '1px',
                    position: 'absolute',
                  }}
                />
              )}
            </Button>
            <Button
              className="LoginFormOutlineButton"
              variant="light"
              onClick={() => setDeleteFileName('')}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </Fade>
    </>
  ) : <></>;
}

DeleteDocumentModal.propTypes = {
  projectCode: PropTypes.string.isRequired,
  onDeleteCompleted: PropTypes.func.isRequired,
  deleteDocumentAudit: PropTypes.func.isRequired,
}

export default DeleteDocumentModal;
