import React from 'react';
import { Select, MenuItem } from '@mui/material';
import { useHistory } from 'react-router';
import { v4 as uuidv4 } from 'uuid';

// Function to get unique dates from versionList
const getUniqueDates = (versionList) => {
  const uniqueDates = new Set();
  versionList.forEach(({ date }) => {
    uniqueDates.add(date);
  });
  return Array.from(uniqueDates);
};

// Render select list for docs version, versionList here must be sorted in descending order.
export default ({ versionList, defaultValue }) => {
  const history = useHistory();
  const uniqueDates = getUniqueDates(versionList);

  return (
    <Select
      id={`select-${defaultValue}`}
      key={`select-${uuidv4()}`}
      defaultValue={defaultValue}
      onChange={(_, { props: { value } }) => {
        history.push(`.${history.location.pathname}`, { date: value });
      }}
      style={{
        // position: 'absolute',
        // left: 'calc(67%)',
        top: 'calc(1%)',
        background: 'white',
        height: '30px',
        width: '190px',
        maxWidth: '190px',
      }}
      variant="outlined"
    >
      {uniqueDates.map((date) => (
        <MenuItem key={uuidv4()} name={date} value={date}>
          {date}
        </MenuItem>
      ))}
    </Select>
  );
};
