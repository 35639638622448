/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getRawStudies = /* GraphQL */ `
  query GetRawStudies {
    getRawStudies {
      study_id
      study_project_code
      study_project
      study_number
      study_title
      study_target
      __typename
    }
  }
`;
export const getProjectStudies = /* GraphQL */ `
  query GetProjectStudies($projectRecordCode: String) {
    getProjectStudies(projectRecordCode: $projectRecordCode) {
      study_id
      study_type
      study_site
      study_status
      study_model
      __typename
    }
  }
`;
export const getProjectStudiesWithDetails = /* GraphQL */ `
  query GetProjectStudiesWithDetails($projectRecordCode: String) {
    getProjectStudiesWithDetails(projectRecordCode: $projectRecordCode) {
      study_id
      study_type
      study_site
      study_status
      study_model
      director {
        filters
        name
        createdAt
        state
        __typename
      }
      juniorDirector {
        filters
        name
        createdAt
        state
        __typename
      }
      __typename
    }
  }
`;
export const isTaskSessionAvailable = /* GraphQL */ `
  query IsTaskSessionAvailable($studyId: String, $taskName: String) {
    isTaskSessionAvailable(studyId: $studyId, taskName: $taskName) {
      is_task_session_available
      __typename
    }
  }
`;
export const isTaskTypeSessionAvailable = /* GraphQL */ `
  query IsTaskTypeSessionAvailable(
    $studyId: String
    $filters: SessionAvailableFilters!
  ) {
    isTaskTypeSessionAvailable(studyId: $studyId, filters: $filters) {
      is_task_session_available
      __typename
    }
  }
`;
export const getProjectRecords = /* GraphQL */ `
  query GetProjectRecords($pageNumber: Int, $status: String) {
    getProjectRecords(pageNumber: $pageNumber, status: $status) {
      ProjectRecords {
        code
        latest_release_date
        companies
        statuses
        sites
        study_types
        model_ids
        sponsors
        milestones {
          name
          forecast
          actual
          __typename
        }
        study_directors
        project_managers
        business_directors
        study_title
        third_dimension
        __typename
      }
      Statuses
      Sites
      StudyTypes
      __typename
    }
  }
`;
export const getProjectRecord = /* GraphQL */ `
  query GetProjectRecord($projectCode: String) {
    getProjectRecord(projectCode: $projectCode) {
      code
      latest_release_date
      companies
      statuses
      sites
      study_types
      model_ids
      sponsors
      milestones {
        name
        forecast
        actual
        __typename
      }
      study_directors
      project_managers
      business_directors
      study_title
      third_dimension
      __typename
    }
  }
`;
export const getTumorVolumeSummary = /* GraphQL */ `
  query GetTumorVolumeSummary($studyId: String) {
    getTumorVolumeSummary(studyId: $studyId) {
      group_id
      group_name
      study_day_metrics {
        study_day
        study_date
        mean
        std
        sem
        __typename
      }
      __typename
    }
  }
`;
export const getTumorVolumeIndividual = /* GraphQL */ `
  query GetTumorVolumeIndividual($studyId: String) {
    getTumorVolumeIndividual(studyId: $studyId) {
      groups {
        group_id
        group_name
        animals {
          subject_id
          subject_type
          temp_id
          fate_code
          fate_date
          fate_day
          sessions {
            study_day
            study_date
            value
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const getTaskMeasurementSummary = /* GraphQL */ `
  query GetTaskMeasurementSummary(
    $studyId: String
    $taskType: String
    $dataFilters: DataFilters
    $taskStatus: String
  ) {
    getTaskMeasurementSummary(
      studyId: $studyId
      taskType: $taskType
      dataFilters: $dataFilters
      taskStatus: $taskStatus
    ) {
      groups {
        group_id
        group_name
        group_name_to_show
        study_day_metrics {
          study_day
          study_date
          mean
          std
          sem
          count
          delta_inhibition
          mean_inhibition
          __typename
        }
        __typename
      }
      task_name
      __typename
    }
  }
`;
export const getTaskMeasurementIndividual = /* GraphQL */ `
  query GetTaskMeasurementIndividual(
    $studyId: String
    $taskType: String
    $dataFilters: DataFilters
    $taskStatus: String
  ) {
    getTaskMeasurementIndividual(
      studyId: $studyId
      taskType: $taskType
      dataFilters: $dataFilters
      taskStatus: $taskStatus
    ) {
      groups {
        group_id
        group_name
        dose_volume
        article
        group_name_to_show
        animals {
          subject_id
          subject_type
          temp_id
          fate_code
          fate_date
          fate_day
          staging_date
          mortality_date
          sessions {
            study_day
            study_date
            study_time
            value
            __typename
          }
          __typename
        }
        __typename
      }
      legend {
        key
        value
        __typename
      }
      task_name
      __typename
    }
  }
`;
export const getStudyDataApprovalState = /* GraphQL */ `
  query GetStudyDataApprovalState($studyId: String) {
    getStudyDataApprovalState(studyId: $studyId) {
      state
      dosing_begins
      study_begins
      roll_back_timeStamp
      __typename
    }
  }
`;
export const getPreviewDataAvailability = /* GraphQL */ `
  query GetPreviewDataAvailability($studyId: String) {
    getPreviewDataAvailability(studyId: $studyId) {
      preview
      __typename
    }
  }
`;
export const getLastModifiedDate = /* GraphQL */ `
  query GetLastModifiedDate($studyId: String) {
    getLastModifiedDate(studyId: $studyId) {
      last_modified_date
      __typename
    }
  }
`;
export const rollBackReleasedData = /* GraphQL */ `
  query RollBackReleasedData($studyId: String) {
    rollBackReleasedData(studyId: $studyId) {
      rollBackReleasedData
      __typename
    }
  }
`;
export const setStudyQualityControl = /* GraphQL */ `
  query SetStudyQualityControl($QCDataParams: QCDataParams) {
    setStudyQualityControl(QCDataParams: $QCDataParams) {
      response
      rollBackTimeStamp
      __typename
    }
  }
`;
export const getQCMetadata = /* GraphQL */ `
  query GetQCMetadata($studyId: String) {
    getQCMetadata(studyId: $studyId) {
      studyId
      director {
        filters
        name
        createdAt
        state
        __typename
      }
      juniorDirector {
        filters
        name
        createdAt
        state
        __typename
      }
      __typename
    }
  }
`;
export const getS3Credentials = /* GraphQL */ `
  query GetS3Credentials($project_prefix: String, $is_china: Boolean) {
    getS3Credentials(project_prefix: $project_prefix, is_china: $is_china) {
      AccessKeyId
      SecretAccessKey
      SessionToken
      __typename
    }
  }
`;
export const createCompanyAccount = /* GraphQL */ `
  query CreateCompanyAccount($CompanyAccountData: CompanyAccountData) {
    createCompanyAccount(CompanyAccountData: $CompanyAccountData) {
      message
      error
      company_id
      __typename
    }
  }
`;
export const getCompanyNames = /* GraphQL */ `
  query GetCompanyNames(
    $pageNumber: Int
    $pageSize: Int
    $searchString: String
  ) {
    getCompanyNames(
      pageNumber: $pageNumber
      pageSize: $pageSize
      searchString: $searchString
    )
  }
`;
export const getStudyIdentifierForCompanyName = /* GraphQL */ `
  query GetStudyIdentifierForCompanyName($companyName: String) {
    getStudyIdentifierForCompanyName(companyName: $companyName)
  }
`;
export const getCompanyUsers = /* GraphQL */ `
  query GetCompanyUsers($companyAccountID: String) {
    getCompanyUsers(companyAccountID: $companyAccountID) {
      email
      name
      lastLogin
      status
      role
      groups
      __typename
    }
  }
`;
export const createUserForCompany = /* GraphQL */ `
  query CreateUserForCompany($users: [CompanyUserInput]) {
    createUserForCompany(users: $users) {
      message
      error
      requiresForceCreation
      __typename
    }
  }
`;
export const updateUserForCompany = /* GraphQL */ `
  query UpdateUserForCompany(
    $user: InputUser
    $add_groups: [String]
    $remove_groups: [String]
    $event: String
  ) {
    updateUserForCompany(
      user: $user
      add_groups: $add_groups
      remove_groups: $remove_groups
      event: $event
    ) {
      message
      error
      company_id
      __typename
    }
  }
`;
export const createSystemMessage = /* GraphQL */ `
  query CreateSystemMessage($message: SystemMessagesInput) {
    createSystemMessage(message: $message) {
      id
      sent_by
      status
      sent_timestamp
      recalled_timestamp
      message_content
      recipients
      user {
        id
        email
        favorites
        notifications
        first_name
        last_name
        phone
        companyAccountID
        lastLoginAt
        studyVisited
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listCognitoGroups = /* GraphQL */ `
  query ListCognitoGroups {
    listCognitoGroups
  }
`;
export const getUserSystemMessages = /* GraphQL */ `
  query GetUserSystemMessages {
    getUserSystemMessages {
      id
      userId
      messageId
      status
      message {
        id
        status
        recalledTimestamp
        sentBy
        recipients
        messageContent
        createdAt
        updatedAt
        user {
          id
          email
          favorites
          notifications
          first_name
          last_name
          phone
          companyAccountID
          lastLoginAt
          studyVisited
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      user {
        id
        email
        favorites
        notifications
        first_name
        last_name
        phone
        companyAccountID
        lastLoginAt
        studyVisited
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const companyWhitelistedGroups = /* GraphQL */ `
  query CompanyWhitelistedGroups($companyAccountID: String) {
    companyWhitelistedGroups(companyAccountID: $companyAccountID)
  }
`;
export const listSystemMessages = /* GraphQL */ `
  query ListSystemMessages {
    listSystemMessages {
      id
      status
      recalledTimestamp
      sentBy
      recipients
      messageContent
      createdAt
      updatedAt
      user {
        id
        email
        favorites
        notifications
        first_name
        last_name
        phone
        companyAccountID
        lastLoginAt
        studyVisited
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const recallSystemMessage = /* GraphQL */ `
  query RecallSystemMessage($messageId: ID!) {
    recallSystemMessage(messageId: $messageId) {
      id
      status
      recalledTimestamp
      sentBy
      recipients
      messageContent
      createdAt
      updatedAt
      user {
        id
        email
        favorites
        notifications
        first_name
        last_name
        phone
        companyAccountID
        lastLoginAt
        studyVisited
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const createModelDataFileRecord = /* GraphQL */ `
  query CreateModelDataFileRecord($ModelDataFileInput: ModelDataFileInput) {
    createModelDataFileRecord(ModelDataFileInput: $ModelDataFileInput) {
      data {
        id
        filename
        updated_at
        type
        subtype
        s3_file_id
        previous_file_id
        user_id
        hidden
        __typename
      }
      error
      __typename
    }
  }
`;
export const removeModelDataFileRecord = /* GraphQL */ `
  query RemoveModelDataFileRecord($Id: ID!) {
    removeModelDataFileRecord(Id: $Id) {
      data {
        id
        __typename
      }
      error
      __typename
    }
  }
`;
export const getCurrentModelDataFileRecordsByType = /* GraphQL */ `
  query GetCurrentModelDataFileRecordsByType(
    $ModelDataFilesByTypeInput: ModelDataFilesByTypeInput
  ) {
    getCurrentModelDataFileRecordsByType(
      ModelDataFilesByTypeInput: $ModelDataFilesByTypeInput
    ) {
      data {
        id
        filename
        updated_at
        type
        subtype
        s3_file_id
        previous_file_id
        user_id
        hidden
        __typename
      }
      error
      __typename
    }
  }
`;
export const getUserNotificationEventAction = /* GraphQL */ `
  query GetUserNotificationEventAction($id: ID!) {
    getUserNotificationEventAction(id: $id) {
      id
      eventId
      userId
      action
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUserNotificationEventActions = /* GraphQL */ `
  query ListUserNotificationEventActions(
    $filter: ModelUserNotificationEventActionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserNotificationEventActions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventId
        userId
        action
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSystemMessages = /* GraphQL */ `
  query GetSystemMessages($id: ID!) {
    getSystemMessages(id: $id) {
      id
      status
      recalledTimestamp
      sentBy
      recipients
      messageContent
      createdAt
      updatedAt
      user {
        id
        email
        favorites
        notifications
        first_name
        last_name
        phone
        companyAccountID
        lastLoginAt
        studyVisited
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listSystemMessagess = /* GraphQL */ `
  query ListSystemMessagess(
    $filter: ModelSystemMessagesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSystemMessagess(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        status
        recalledTimestamp
        sentBy
        recipients
        messageContent
        createdAt
        updatedAt
        user {
          id
          email
          favorites
          notifications
          first_name
          last_name
          phone
          companyAccountID
          lastLoginAt
          studyVisited
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSystemMessagesReception = /* GraphQL */ `
  query GetSystemMessagesReception($id: ID!) {
    getSystemMessagesReception(id: $id) {
      id
      userId
      messageId
      status
      message {
        id
        status
        recalledTimestamp
        sentBy
        recipients
        messageContent
        createdAt
        updatedAt
        user {
          id
          email
          favorites
          notifications
          first_name
          last_name
          phone
          companyAccountID
          lastLoginAt
          studyVisited
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      user {
        id
        email
        favorites
        notifications
        first_name
        last_name
        phone
        companyAccountID
        lastLoginAt
        studyVisited
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listSystemMessagesReceptions = /* GraphQL */ `
  query ListSystemMessagesReceptions(
    $filter: ModelSystemMessagesReceptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSystemMessagesReceptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        messageId
        status
        message {
          id
          status
          recalledTimestamp
          sentBy
          recipients
          messageContent
          createdAt
          updatedAt
          user {
            id
            email
            favorites
            notifications
            first_name
            last_name
            phone
            companyAccountID
            lastLoginAt
            studyVisited
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        createdAt
        updatedAt
        user {
          id
          email
          favorites
          notifications
          first_name
          last_name
          phone
          companyAccountID
          lastLoginAt
          studyVisited
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCompanyGroups = /* GraphQL */ `
  query GetCompanyGroups($id: ID!) {
    getCompanyGroups(id: $id) {
      id
      name
      companyAccountId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCompanyGroupss = /* GraphQL */ `
  query ListCompanyGroupss(
    $filter: ModelCompanyGroupsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanyGroupss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        companyAccountId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getHelp = /* GraphQL */ `
  query GetHelp($id: ID!) {
    getHelp(id: $id) {
      id
      helpText
      helpHtml
      addedBy
      createdAt
      updatedAt
      user {
        id
        email
        favorites
        notifications
        first_name
        last_name
        phone
        companyAccountID
        lastLoginAt
        studyVisited
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listHelps = /* GraphQL */ `
  query ListHelps(
    $filter: ModelhelpFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHelps(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        helpText
        helpHtml
        addedBy
        createdAt
        updatedAt
        user {
          id
          email
          favorites
          notifications
          first_name
          last_name
          phone
          companyAccountID
          lastLoginAt
          studyVisited
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getColorScheme = /* GraphQL */ `
  query GetColorScheme($id: ID!) {
    getColorScheme(id: $id) {
      id
      userId
      studyId
      modelId
      schemeType
      value
      graphName
      taskType
      colorMapping {
        group
        color
        __typename
      }
      createdAt
      updatedAt
      user {
        id
        email
        favorites
        notifications
        first_name
        last_name
        phone
        companyAccountID
        lastLoginAt
        studyVisited
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listColorSchemes = /* GraphQL */ `
  query ListColorSchemes(
    $filter: ModelColorSchemeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listColorSchemes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        studyId
        modelId
        schemeType
        value
        graphName
        taskType
        colorMapping {
          group
          color
          __typename
        }
        createdAt
        updatedAt
        user {
          id
          email
          favorites
          notifications
          first_name
          last_name
          phone
          companyAccountID
          lastLoginAt
          studyVisited
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getGraphAnnotations = /* GraphQL */ `
  query GetGraphAnnotations($id: ID!) {
    getGraphAnnotations(id: $id) {
      id
      studyId
      modelId
      graphName
      value
      taskType
      isActive
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listGraphAnnotationss = /* GraphQL */ `
  query ListGraphAnnotationss(
    $filter: ModelGraphAnnotationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGraphAnnotationss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        studyId
        modelId
        graphName
        value
        taskType
        isActive
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const notificationEventActionPerUser = /* GraphQL */ `
  query NotificationEventActionPerUser(
    $userId: String
    $createdAt: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserNotificationEventActionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notificationEventActionPerUser(
      userId: $userId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventId
        userId
        action
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const messagesByUserIdAndStatus = /* GraphQL */ `
  query MessagesByUserIdAndStatus(
    $userId: ID
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSystemMessagesReceptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByUserIdAndStatus(
      userId: $userId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        messageId
        status
        message {
          id
          status
          recalledTimestamp
          sentBy
          recipients
          messageContent
          createdAt
          updatedAt
          user {
            id
            email
            favorites
            notifications
            first_name
            last_name
            phone
            companyAccountID
            lastLoginAt
            studyVisited
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        createdAt
        updatedAt
        user {
          id
          email
          favorites
          notifications
          first_name
          last_name
          phone
          companyAccountID
          lastLoginAt
          studyVisited
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const companyGroupsByCompanyAccountId = /* GraphQL */ `
  query CompanyGroupsByCompanyAccountId(
    $companyAccountId: String
    $sortDirection: ModelSortDirection
    $filter: ModelCompanyGroupsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    companyGroupsByCompanyAccountId(
      companyAccountId: $companyAccountId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        companyAccountId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      email
      favorites
      notifications
      first_name
      last_name
      phone
      companyAccountID
      lastLoginAt
      studyVisited
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        favorites
        notifications
        first_name
        last_name
        phone
        companyAccountID
        lastLoginAt
        studyVisited
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserByEmail = /* GraphQL */ `
  query GetUserByEmail(
    $email: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        favorites
        notifications
        first_name
        last_name
        phone
        companyAccountID
        lastLoginAt
        studyVisited
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserByCompany = /* GraphQL */ `
  query GetUserByCompany(
    $companyAccountID: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserByCompany(
      companyAccountID: $companyAccountID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        favorites
        notifications
        first_name
        last_name
        phone
        companyAccountID
        lastLoginAt
        studyVisited
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getNotificationEvent = /* GraphQL */ `
  query GetNotificationEvent($id: ID!) {
    getNotificationEvent(id: $id) {
      id
      type
      createdBy
      companyName
      projectCode
      createdAt
      description
      userId
      attributes {
        key
        value
        __typename
      }
      updatedAt
      __typename
    }
  }
`;
export const listNotificationEvents = /* GraphQL */ `
  query ListNotificationEvents(
    $filter: ModelNotificationEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotificationEvents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        createdBy
        companyName
        projectCode
        createdAt
        description
        userId
        attributes {
          key
          value
          __typename
        }
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const notificationEventPerProjectCode = /* GraphQL */ `
  query NotificationEventPerProjectCode(
    $projectCode: String
    $createdAt: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notificationEventPerProjectCode(
      projectCode: $projectCode
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        createdBy
        companyName
        projectCode
        createdAt
        description
        userId
        attributes {
          key
          value
          __typename
        }
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const notificationsForProjectByType = /* GraphQL */ `
  query NotificationsForProjectByType(
    $projectCode: String
    $type: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notificationsForProjectByType(
      projectCode: $projectCode
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        createdBy
        companyName
        projectCode
        createdAt
        description
        userId
        attributes {
          key
          value
          __typename
        }
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserPreferences = /* GraphQL */ `
  query GetUserPreferences($id: ID!) {
    getUserPreferences(id: $id) {
      id
      userId
      notificationOptIn
      emailFrequencyPreferences
      userRegion
      userTimeZone
      lastEmailSentAt
      lastUpdatedPasswordAt
      deleteAccountAt
      sharePersonalData
      terms {
        ipAddress
        timestamp
        termsVersion
        privacyVersion
        __typename
      }
      isImmediate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUserPreferencess = /* GraphQL */ `
  query ListUserPreferencess(
    $filter: ModelUserPreferencesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserPreferencess(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        notificationOptIn
        emailFrequencyPreferences
        userRegion
        userTimeZone
        lastEmailSentAt
        lastUpdatedPasswordAt
        deleteAccountAt
        sharePersonalData
        terms {
          ipAddress
          timestamp
          termsVersion
          privacyVersion
          __typename
        }
        isImmediate
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const preferencesForUser = /* GraphQL */ `
  query PreferencesForUser(
    $userId: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserPreferencesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    preferencesForUser(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        notificationOptIn
        emailFrequencyPreferences
        userRegion
        userTimeZone
        lastEmailSentAt
        lastUpdatedPasswordAt
        deleteAccountAt
        sharePersonalData
        terms {
          ipAddress
          timestamp
          termsVersion
          privacyVersion
          __typename
        }
        isImmediate
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const preferencesByUserRegion = /* GraphQL */ `
  query PreferencesByUserRegion(
    $userRegion: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserPreferencesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    preferencesByUserRegion(
      userRegion: $userRegion
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        notificationOptIn
        emailFrequencyPreferences
        userRegion
        userTimeZone
        lastEmailSentAt
        lastUpdatedPasswordAt
        deleteAccountAt
        sharePersonalData
        terms {
          ipAddress
          timestamp
          termsVersion
          privacyVersion
          __typename
        }
        isImmediate
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const preferencesByImmediateNotification = /* GraphQL */ `
  query PreferencesByImmediateNotification(
    $isImmediate: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserPreferencesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    preferencesByImmediateNotification(
      isImmediate: $isImmediate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        notificationOptIn
        emailFrequencyPreferences
        userRegion
        userTimeZone
        lastEmailSentAt
        lastUpdatedPasswordAt
        deleteAccountAt
        sharePersonalData
        terms {
          ipAddress
          timestamp
          termsVersion
          privacyVersion
          __typename
        }
        isImmediate
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserNotificationSubscriptionCheckpoint = /* GraphQL */ `
  query GetUserNotificationSubscriptionCheckpoint($id: ID!) {
    getUserNotificationSubscriptionCheckpoint(id: $id) {
      id
      userId
      projectCode
      createdAt
      type
      updatedAt
      __typename
    }
  }
`;
export const listUserNotificationSubscriptionCheckpoints = /* GraphQL */ `
  query ListUserNotificationSubscriptionCheckpoints(
    $filter: ModelUserNotificationSubscriptionCheckpointFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserNotificationSubscriptionCheckpoints(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        projectCode
        createdAt
        type
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSubUnsubCheckpoints = /* GraphQL */ `
  query GetSubUnsubCheckpoints(
    $userId: String
    $createdAt: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserNotificationSubscriptionCheckpointFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSubUnsubCheckpoints(
      userId: $userId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        projectCode
        createdAt
        type
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getComment = /* GraphQL */ `
  query GetComment($id: ID!) {
    getComment(id: $id) {
      id
      modelID
      projectID
      commentedBy
      deletedAt
      note
      commentType
      parentID
      createdAt
      updatedAt
      user {
        id
        email
        favorites
        notifications
        first_name
        last_name
        phone
        companyAccountID
        lastLoginAt
        studyVisited
        createdAt
        updatedAt
        __typename
      }
      parentComment {
        id
        modelID
        projectID
        commentedBy
        deletedAt
        note
        commentType
        parentID
        createdAt
        updatedAt
        user {
          id
          email
          favorites
          notifications
          first_name
          last_name
          phone
          companyAccountID
          lastLoginAt
          studyVisited
          createdAt
          updatedAt
          __typename
        }
        parentComment {
          id
          modelID
          projectID
          commentedBy
          deletedAt
          note
          commentType
          parentID
          createdAt
          updatedAt
          user {
            id
            email
            favorites
            notifications
            first_name
            last_name
            phone
            companyAccountID
            lastLoginAt
            studyVisited
            createdAt
            updatedAt
            __typename
          }
          parentComment {
            id
            modelID
            projectID
            commentedBy
            deletedAt
            note
            commentType
            parentID
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const listComments = /* GraphQL */ `
  query ListComments(
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        modelID
        projectID
        commentedBy
        deletedAt
        note
        commentType
        parentID
        createdAt
        updatedAt
        user {
          id
          email
          favorites
          notifications
          first_name
          last_name
          phone
          companyAccountID
          lastLoginAt
          studyVisited
          createdAt
          updatedAt
          __typename
        }
        parentComment {
          id
          modelID
          projectID
          commentedBy
          deletedAt
          note
          commentType
          parentID
          createdAt
          updatedAt
          user {
            id
            email
            favorites
            notifications
            first_name
            last_name
            phone
            companyAccountID
            lastLoginAt
            studyVisited
            createdAt
            updatedAt
            __typename
          }
          parentComment {
            id
            modelID
            projectID
            commentedBy
            deletedAt
            note
            commentType
            parentID
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const commentsByProjectAndType = /* GraphQL */ `
  query CommentsByProjectAndType(
    $projectID: String
    $commentType: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    commentsByProjectAndType(
      projectID: $projectID
      commentType: $commentType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        modelID
        projectID
        commentedBy
        deletedAt
        note
        commentType
        parentID
        createdAt
        updatedAt
        user {
          id
          email
          favorites
          notifications
          first_name
          last_name
          phone
          companyAccountID
          lastLoginAt
          studyVisited
          createdAt
          updatedAt
          __typename
        }
        parentComment {
          id
          modelID
          projectID
          commentedBy
          deletedAt
          note
          commentType
          parentID
          createdAt
          updatedAt
          user {
            id
            email
            favorites
            notifications
            first_name
            last_name
            phone
            companyAccountID
            lastLoginAt
            studyVisited
            createdAt
            updatedAt
            __typename
          }
          parentComment {
            id
            modelID
            projectID
            commentedBy
            deletedAt
            note
            commentType
            parentID
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const commentsBymodelIDAndType = /* GraphQL */ `
  query CommentsBymodelIDAndType(
    $modelID: String
    $commentType: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    commentsBymodelIDAndType(
      modelID: $modelID
      commentType: $commentType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        modelID
        projectID
        commentedBy
        deletedAt
        note
        commentType
        parentID
        createdAt
        updatedAt
        user {
          id
          email
          favorites
          notifications
          first_name
          last_name
          phone
          companyAccountID
          lastLoginAt
          studyVisited
          createdAt
          updatedAt
          __typename
        }
        parentComment {
          id
          modelID
          projectID
          commentedBy
          deletedAt
          note
          commentType
          parentID
          createdAt
          updatedAt
          user {
            id
            email
            favorites
            notifications
            first_name
            last_name
            phone
            companyAccountID
            lastLoginAt
            studyVisited
            createdAt
            updatedAt
            __typename
          }
          parentComment {
            id
            modelID
            projectID
            commentedBy
            deletedAt
            note
            commentType
            parentID
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCompanyAccount = /* GraphQL */ `
  query GetCompanyAccount($id: ID!) {
    getCompanyAccount(id: $id) {
      id
      name
      studyIdentifiers
      whitelistedDomains
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCompanyAccounts = /* GraphQL */ `
  query ListCompanyAccounts(
    $filter: ModelCompanyAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanyAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        studyIdentifiers
        whitelistedDomains
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
