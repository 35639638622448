import React, { useState, useEffect } from 'react';
import { Button, Row, Spinner } from 'react-bootstrap';
import Modal from '@mui/material/Modal';
import { TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import { useGraphQL } from '../../providers/graphql';
import { CUSTOM_BOX_STYLE } from '../../constants';

function ModifyGroupsModal({
  togglemodifyGroupsModal,
  modifyGroupsModal,
  currentUser,
  companyUsers,
  reloadUsers,
  changeState,
  companyInfo,
  setCompanyUsers,
}) {
  const [userEmail, setUserEmail] = useState(false);
  const [userName, setUserName] = useState('');
  const [userGroups, setUserGroups] = useState([]);
  const [userWhiteListedGroups, setUserWhiteListedGroups] = useState([]);
  const [groupsLoading, setGroupsLoading] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [showErrorText, setShowErrorText] = useState(false);
  let errorCombination = [];
  const { companyWhitelistedGroups, updateUserForCompany } = useGraphQL();

  const handleChange = async (_event, value) => {
    setGroupsLoading(true);
    const groupsToBeAdded = value.filter((x) => !userGroups.includes(x));
    const groupsToBeRemoved = userGroups.filter((x) => !value.includes(x));
    const invalidGroups = {
      AccountAdmin: ['AccountAdmin', 'SystemAdmin'],
      SystemAdmin: ['SystemAdmin', 'AccountAdmin', 'External'],
      IndividualProjects: ['IndividualProjects', 'CompanyProjects', 'AllProjects', 'DemoData', 'C:Exxxx'],
      AllProjects: ['AllProjects', 'CompanyProjects', 'DemoData', 'IndividualProjects', 'C:Exxxx'],
      'C:Exxxx': ['IndividualProjects', 'AllProjects', 'C:Exxxx'],
      External: ['External', 'SystemAdmin', 'StudyDirector', 'JuniorStudyDirector', 'Biomarker', 'BusinessDevelopment', 'CrownGeneral'],
      StudyDirector: ['StudyDirector', 'External', 'JuniorStudyDirector', 'Biomarker', 'BusinessDevelopment', 'CrownGeneral'],
      JuniorStudyDirector: ['JuniorStudyDirector', 'StudyDirector', 'External', 'Biomarker', 'BusinessDevelopment', 'CrownGeneral'],
      Biomarker: ['Biomarker', 'StudyDirector', 'JuniorStudyDirector', 'External', 'BusinessDevelopment', 'CrownGeneral'],
      BusinessDevelopment: ['BusinessDevelopment', 'StudyDirector', 'JuniorStudyDirector', 'Biomarker', 'External', 'CrownGeneral'],
      CrownGeneral: ['CrownGeneral', 'StudyDirector', 'JuniorStudyDirector', 'Biomarker', 'BusinessDevelopment', 'External'],
      CompanyProjects: ['CompanyProjects', 'IndividualProjects', 'AllProjects', 'DemoData'],
      DemoData: ['DemoData', 'IndividualProjects', 'CompanyProjects', 'AllProjects'],
    };
    if (groupsToBeAdded.length > 0 || groupsToBeRemoved.length > 0) {
      let errorFound = false;
      if (groupsToBeAdded.length > 0) {
        let elementSelected = groupsToBeAdded[0];
        if (elementSelected.startsWith('C:E')) {
          elementSelected = 'C:Exxxx'
        }
        invalidGroups[elementSelected].forEach((_key, index) => {
          const item = invalidGroups[elementSelected][index];
          if (item.startsWith('C:E')) {
            const found = userGroups.find((element) => element.startsWith('C:E'))
            if (found) {
              setErrorText('Invalid combination of groups; C:Exxxx allowed only one.')
              errorCombination = ['C:Exxxx']
              setShowErrorText(true);
              errorFound = true;
              setUserGroups(value.filter((itemVal) => itemVal !== elementSelected));
            }
          } else if (elementSelected.startsWith('C:E')) {
            if (userGroups.includes(item)) {
              setErrorText(`Invalid combination of groups [${item}, ${elementSelected}]`)
              errorCombination = [item, elementSelected]
              setShowErrorText(true);
              errorFound = true;
              setUserGroups(value.filter((itemVal) => itemVal !== elementSelected));
            }
          } else if (userGroups.includes(item)) {
            setErrorText(`Invalid combination of groups [${item}, ${elementSelected}]`)
            errorCombination = [item, elementSelected]
            setShowErrorText(true);
            errorFound = true;
            setUserGroups(value.filter((itemVal) => itemVal !== elementSelected));
          }
        })
      }
      if (groupsToBeRemoved.length > 0 && showErrorText) {
        let elementSelected = groupsToBeRemoved[0];
        if (elementSelected.startsWith('C:E')) {
          elementSelected = 'C:Exxxx'
        }
        if (errorCombination.includes(elementSelected)) {
          setShowErrorText(false);
          setErrorText('')
          errorCombination = []
        }
      }
      if (errorFound) { setGroupsLoading(false); return; }
      const userData = { email: userEmail, name: userName };
      const response = await updateUserForCompany(userData, groupsToBeAdded, groupsToBeRemoved);
      const res = response.data.updateUserForCompany;
      if (res && res.message && res.message === 'User Updated Successfully') {
        const updatedCompanyUsers = companyUsers.map((user) => {
          if (user.email === userEmail) {
            return { ...user, groups: value };
          }
          return user;
        });
        setCompanyUsers(updatedCompanyUsers);
        changeState('success', 'User group(s) modified successfully. ', true);
      } else {
        const updatedCompanyUsers = companyUsers.map((user) => {
          if (user.email === userEmail) {
            return { ...user, groups: userGroups };
          }
          return user;
        });
        setCompanyUsers(updatedCompanyUsers);
        changeState('error', 'Error while modifying user group(s).', true);
      }
    }
    setErrorText('')
    setShowErrorText(false);
    setGroupsLoading(false);
  }
  useEffect(() => {
    const result = companyUsers.filter((oneUser) => oneUser.email === currentUser.email);
    const oneUser = result.length > 0 ? result[0] : null;
    if (oneUser) {
      setUserEmail(oneUser.email);
      setUserGroups(oneUser.groups);
      setUserName(oneUser.name);
      setGroupsLoading(true);
      companyWhitelistedGroups(companyInfo.id).then((response) => {
        const res = response.data.companyWhitelistedGroups;
        if (res) {
          setUserWhiteListedGroups(res);
          setGroupsLoading(false);
        }
      });
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyUsers, currentUser, userGroups])
  const onCancel = () => {
    togglemodifyGroupsModal();
    (async () => { await reloadUsers(true) })()
  };

  return (
    <Modal
      open={modifyGroupsModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{ ...CUSTOM_BOX_STYLE }}>
        <div style={{ textAlign: 'center' }}>
          <span className="containerTitle" data-testid="container_Title">Modify Group(s)</span>
        </div>
        <p
          style={{
            color: 'white',
            fontSize: '16px',
          }}
          data-testid="user-Name"
        >
          {userName}
        </p>
        <p
          style={{
            color: 'white',
            fontSize: '16px',
          }}
          data-testid="user-Email"
        >
          {userEmail}
        </p>
        {
      groupsLoading
        ? (
          <Row style={{ margin: '10px 0px 5px 32px' }} className="justify-content-center">
            <Button variant="primary" disabled>
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              Loading Groups...
            </Button>
          </Row>
        ) : (
          <div>
            {
      showErrorText
        ? (
          <p
            style={{
              color: 'red',
            }}
            data-testid="error-text"
          >{errorText}
          </p>
        )
        : null
}
            <div style={{
              border: '1px solid #FFFFFFF',
              borderRadius: '3px',
            }}
            >
              <Autocomplete
                data-testid="multi-selector"
                multiple
                limitTags={4}
                id="multiple-limit-tags"
                disableClearable
                options={userWhiteListedGroups}
                getOptionLabel={(option) => option}
                defaultValue={userGroups}
                onChange={handleChange}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Groups" />
                )}
                sx={{ width: '100%', backgroundColor: 'white !important' }}
              />
            </div>
          </div>
        )
            }
        <Button
          type="button"
          variant="light"
          data-testid="container_Cancel"
          className="LoginFormOutlineButton"
          onClick={onCancel}
        >
          Close
        </Button>
      </Box>
    </Modal>
  );
}
export default ModifyGroupsModal;
