import React from 'react';
import { Link as LinkIcon } from '@styled-icons/material/Link';

import { LinkToolbarButton } from '@udecode/plate';
import tooltip from '../tooltip/tooltip';

const HyperLinkButtons = () => (
  <>
    <LinkToolbarButton
      tooltip={tooltip('Hyper link')}
      value="hyperlink"
      icon={<LinkIcon />}
    />
  </>
);

export default HyperLinkButtons;
