import React from 'react';
import { Button } from 'react-bootstrap';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { useGraphQL } from '../../providers/graphql';
import { useSnackBar } from '../../providers/snackbar';
import { CUSTOM_BOX_STYLE } from '../../constants';

function DeleteAdminModal({
  toggleUserDeleteAccountModal,
  showModal,
  currentUser,
  companyUsers,
  setCompanyUsers,
  reloadUsers,
}) {
  const { updateUserForCompany } = useGraphQL();
  const { changeState } = useSnackBar();

  const onCancel = () => {
    toggleUserDeleteAccountModal();
  };
  const onDelete = () => {
    updateUserForCompany(currentUser, [], [], 'removeUser').then((response) => {
      const res = response.data.updateUserForCompany;
      if (res) {
        if (res.message) {
          if (res.message === 'User Updated Successfully') {
            (async () => { await reloadUsers() })();
            changeState(
              'success',
              `${currentUser.email} User Removed from Company`,
              true,
            );
            // eslint-disable-next-line
            const updatedUsers = companyUsers.filter((oneUser) => oneUser.email === currentUser.email ? null : oneUser)
            setCompanyUsers(updatedUsers)
          } else {
            changeState('error', 'Error while Removing Company User', true);
          }
        } else {
          changeState('error', 'Error  while Removing Company User', true);
        }
      } else {
        // eslint-disable-next-line
        changeState('error', 'Error  while Removing Company User', true);
      }
    });
    toggleUserDeleteAccountModal();
  };
  return (
    <Modal
      open={showModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{ ...CUSTOM_BOX_STYLE }}>
        <p
          style={{
            color: 'white',
            fontSize: '16px',
          }}
          data-testid="second-Confirmation-Title"
        >
          Do you wish to delete this user from your account? They will lose all
          account-level access.
        </p>
        <Button
          type="submit"
          variant="primary"
          className="LoginFormSignInButtonChangePassword"
          onClick={onDelete}
        >
          Delete
        </Button>
        <Button
          type="button"
          variant="light"
          className="LoginFormOutlineButton"
          onClick={onCancel}
        >
          Cancel
        </Button>
      </Box>
    </Modal>
  );
}
export default DeleteAdminModal;
