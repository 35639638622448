// Main component for modal on adding system message.

import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
import React, { useState } from 'react';

import { BRAND_COLORS, CUSTOM_BOX_STYLE, FONT_FAMILY } from '../../../../constants';
import RichTextEditor from '../../ReusableComponents/RichTextEditor/RichTextEditor';
import { parseJSONOrStringValueToRTE } from '../../../utils';

const noOp = () => { }

// --------------------

const PreviewSystemMessageModal = ({ props }) => {
  const {
    showModal, // Whether to show modal or not.
    toggleModal = noOp, // helper function to toggle showModal state.
    messageContent, // content of the message to display
    resetMessageContent = noOp, // called to reset message state.
  } = props
  const [isLoading, setIsLoading] = useState(false);

  const onHide = () => {
    setIsLoading(false)
    resetMessageContent()
    toggleModal()
  };

  const renderFormOrLoader = () => {
    if (isLoading) {
      return (
        <>
          <div style={{ textAlign: 'center' }}>
            <Typography className={`${FONT_FAMILY}-Regular containerTitle`}>
              Loading...
            </Typography>
            <CircularProgress size="3rem" style={{ color: BRAND_COLORS.orange }} />

          </div>
        </>
      )
    }
    return (
      <Box>
        <div
          style={{
            height: '300px',
            minHeight: '300px',
            background: 'white',
            padding: '8px',
            overflow: 'scroll',
          }}
          data-testid="rte-preview"
        >
          <RichTextEditor
            name="messageContent"
            showToolbar={false}
            showBalloonToolbar={false}
            initialValue={parseJSONOrStringValueToRTE(messageContent)}
            readOnly
          />
        </div>
        <div>
          <Button
            data-testid="test-close-preview-btn"
            className="LoginFormOutlineButton"
            type="button"
            variant="light"
            onClick={onHide}
            disabled={isLoading}
          >
            Close
          </Button>
        </div>
      </Box>
    )
  }

  return (
    <Modal data-testid="test-preview-system-message-modal" open={showModal}>
      <Box sx={CUSTOM_BOX_STYLE}>
        <div style={{ textAlign: 'center' }}>
          <span className="containerTitle" data-testid="container_Title">System Message Preview</span>
        </div>
        {renderFormOrLoader()}
      </Box>
    </Modal>
  )
}

export default PreviewSystemMessageModal;
