import React, { useState, useEffect } from 'react';
import {
  Card,
  Button,
  Row,
  Spinner,
} from 'react-bootstrap';
import Gantt from '../gantt/Gantt';
import formatGanttData from './landingUtils';
import { useAuth } from '../../../providers/auth';
import { useGraphQL } from '../../../providers/graphql';
import { GROUPS } from '../../../constants';

const StudiesTable = function StudiesTable({ studies, studiesData }) {
  const {
    loading,
    getOrCreateUser,
    setFavorites,
    userPreferences,
    loadOrCreateUserPreferences,
  } = useGraphQL();
  const { user, hasGroup } = useAuth();
  const [studiesTableData, setStudiesTableData] = useState({});
  const [loadedFavorites, setLoadedFavorites] = useState([]);
  const [loadedNotifications, setLoadedNotifications] = useState([]);

  const canSubscribeNotifications = hasGroup([GROUPS.EXTERNAL, GROUPS.STUDY_DIRECTOR, GROUPS.JUNIOR_STUDY_DIRECTOR, GROUPS.BUSINESS_DEVELOPMENT, GROUPS.BIOMARKER, GROUPS.GENERAL_ACCESS]);

  useEffect(() => {
    if (user) {
      loadOrCreateUserPreferences(user.id)
      getOrCreateUser(user)
        .then((response) => {
          if (response.favorites) {
            setLoadedFavorites(response.favorites)
            setFavorites(response.favorites)
          }
        })
        .catch(() => {});
    }
    // eslint-disable-next-line
  }, [user])

  useEffect(() => {
    const { notificationOptIn } = userPreferences
    if (notificationOptIn) {
      setLoadedNotifications(notificationOptIn)
    }
  }, [userPreferences])

  useEffect(() => {
    if (studies) {
      const formattedData = formatGanttData(studies, loadedFavorites, loadedNotifications, canSubscribeNotifications, studiesData);
      setStudiesTableData(formattedData);
    }
    // eslint-disable-next-line
  }, [studies, loadedFavorites]);

  return (
    <Card.Body style={{ padding: !loading ? 0 : '1.25rem' }}>
      {/* <Gantt /> */}
      {!loading ? (
        <Gantt ganttConfig={studiesTableData} />
      ) : (
        <Row className="justify-content-center">
          <Button variant="primary" disabled>
            <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            Loading Studies...
          </Button>
        </Row>
      )}
    </Card.Body>
  );
}

export default StudiesTable;
