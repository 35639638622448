/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState, useRef, useEffect } from 'react';
import {
  Button,
} from 'react-bootstrap';
/* import { FileRichtext } from "react-bootstrap-icons"; */ /* icons removed as new icons are being used as per design */
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import Typography from '@mui/material/Typography';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/styles';
import { useHistory } from 'react-router-dom';
import {
  Amplify,
  Auth,
  API,
  graphqlOperation,
} from 'aws-amplify';
import { GetObjectCommand } from '@aws-sdk/client-s3';
import { LambdaClient, InvokeCommand } from '@aws-sdk/client-lambda';
import { STSClient, GetCallerIdentityCommand } from '@aws-sdk/client-sts';
import { SecretsManagerClient, GetSecretValueCommand } from '@aws-sdk/client-secrets-manager';
import { v4 as uuidv4 } from 'uuid';
import { isSafari } from 'react-device-detect'
import {
  ROTATED_HEADER_TEMPLATE,
  ROTATED_HEADER_TEMPLATE_WORD_WRAP,
  DEFAULT_GRIDOPTS,
  HEADER_WITHOUT_PADDING,
  CHINA_CONFIG,
  EXCLUDED_MO_FOR_SURVIVAL_ANALYSIS_CHART,
  CUSTOM_DEFAULT_FOLDERS,
} from '../constants';
import DownloadIconPM from './components/study/IconDownload.svg'; /* now using a new icon as per design */
import DeleteIconM from './components/study/icon-delete.svg'; /* now using a new icon as per design */
import { useFolder } from '../providers/folder';
import awsExports from '../aws-exports'; // eslint-disable-line
import { useMortalityObservationLegendContext } from '../providers/mortalityObservationLegend';
import { useClinicalObservationLegendContext } from '../providers/clinicalObservationLegend';
// eslint-disable-next-line import/no-cycle
import { useGraphQL } from '../providers/graphql';
// eslint-disable-next-line import/no-cycle
import { useS3 } from '../providers/s3';
import LoadingSpinnerIcon from './loading.svg';
import * as queries from '../graphql/queries';
import { useAuth } from '../providers/auth';

const amplifyConfig = Amplify.configure(awsExports);

/**
 * A no operation function.
 */
export const noop = () => { };

// eslint-disable-next-line
export const LightTooltip = styled(({
  className, offVt, offHz, ...props
}) => (
  <Tooltip
    disableInteractive
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    PopperProps={{
      popperOptions: {
        modifiers: [
          {
            name: 'offset',
            options: { offset: [offVt, offHz] },
          },
        ],
      },
    }}
    classes={{
      popper: className,
    }}
  />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#FFFBEC',
    color: '#222222',
    border: '1px solid #FEE7D5',
    borderRadius: '2px',
    fontSize: '12px',
    top: '20px',
  },
}));

export const getSecrets = async () => {
  const creds = await Auth.currentCredentials();

  const secretsManager = new SecretsManagerClient({
    endpoint: `https://secretsmanager.${amplifyConfig.aws_project_region}.amazonaws.com`,
    region: amplifyConfig.aws_project_region,
    credentials: creds,
  });

  const data = await secretsManager.send(new GetSecretValueCommand({ SecretId: CHINA_CONFIG.SECRET_ARN }));

  const secret = data.SecretString;
  const jsonParsed = JSON.parse(secret);

  return jsonParsed;
};

export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const LinkComponent = function ({ params }) {
  const history = useHistory();
  return (
    // eslint-disable-next-line react/button-has-type
    <button
      onClick={() => history.push(`/details/${params.value}`)}
      className="btn-link stretched-link AvenirNextLTProRegular"
    >
      {params.value}
    </button>
  );
}
const getParsedDate = (dateToParse) => {
  let isValid = false;
  let date;
  try {
    date = new Date(dateToParse);
    isValid = true;
  } catch (error) {
    isValid = false;
  }
  return { isValid, date };
}
const getGreydBGColorEmptyDiv = (params, session) => {
  let isMortalityDatePassed = false;
  const studyDate = getParsedDate(session?.study_date)
  const mortalityDate = getParsedDate(params.data.mortalityDate)
  if (studyDate.isValid && mortalityDate.isValid && studyDate.date > mortalityDate.date) {
    isMortalityDatePassed = true;
    params.eGridCell.classList.add('bgLightgray')
  }
  return { isMortalityDatePassed }
}
const getValueOrGreyedBGColoredCell = (params, session) => {
  const { isMortalityDatePassed } = getGreydBGColorEmptyDiv(params, session);
  if (isMortalityDatePassed) {
    return '';
  }

  return params?.value ?? ''
}

export const DownloadAllFilesRenderer = ({ files, auditDownload }) => {
  const [loadingIcon, setLoadingIcon] = useState(false);
  const { getAuthenticatedS3Client, bucketName, getProjectPrefix } = useS3();
  const { doTask } = useAuth();

  const handleDownloadAll = () => doTask(async () => {
    setLoadingIcon(true);
    await Promise.all(
      files.map(async (file) => {
        const client = await getAuthenticatedS3Client(
          getProjectPrefix(file.s3Path),
        );
        const res = await client.send(new GetObjectCommand({
          Bucket: bucketName,
          Key: file.s3Path,
        }));
        await downloadFile(res.Body, file.name);

        if (auditDownload) {
          auditDownload();
        }
      }),
    );
    setLoadingIcon(false);
  });

  return (
    <div>
      {loadingIcon ? (
        <img className="svg-loader" src={LoadingSpinnerIcon} alt="loading-spinner" />
      ) : (
        <LightTooltip title="Download All Files">
          <Button
            style={{
              color: '#0645AD', textDecoration: 'none', border: 'none', background: 'none', cursor: 'pointer', marginTop: '2%',
            }}
            type="button"
            onClick={handleDownloadAll}
          > Download <br /> All
          </Button>
        </LightTooltip>
      )}
    </div>
  );
};

export const FileNameDownloadSelectCellRenderer = ({ params }) => {
  const [loadingIcon, setLoadingIcon] = useState(false);
  const { getAuthenticatedS3Client, bucketName, getProjectPrefix } = useS3();
  const { doTask } = useAuth();

  return (
    <div
      className={_.isEmpty(params.data.path) ? 'disabled' : ''}
      onClick={() => doTask(async () => {
        if (params.data.path) {
          setLoadingIcon(true);
          const client = await getAuthenticatedS3Client(getProjectPrefix(params.data.path));
          const res = await client.send(new GetObjectCommand({
            Bucket: bucketName,
            Key: params.data.path,
          }));
          await downloadFile(res.Body, params.data.name)

          if (params.auditDownload) {
            params.auditDownload();
          }
          setLoadingIcon(false)
        }
      })}
    >
      {
        loadingIcon
          ? (<img className="svg-loader" src={LoadingSpinnerIcon} alt="loading-spinner" />)
          : <img src={DownloadIconPM} alt="download-icon on AG-Grid" />
      }
    </div>
  );
}

export function FileNameDeleteSelectCellRenderer({ params }) {
  const { setDeleteFileName } = useFolder();
  if (_.isEmpty(params.data.path)) {
    return <></>;
  }
  return (
    <button className="buttonWithTransparentBackground" type="button" onClick={() => setDeleteFileName(params.data.path)}>
      <img src={DeleteIconM} alt="delete-icon" />{' '}
      {/* delete icon as per revised design */}
    </button>
  );
}

export function FolderNameDeleteSelectCellRenderer({ params }) {
  return (
    <button
      className="buttonWithTransparentBackground"
      type="button"
      onClick={() => params.deleteFolderHandler(params.data.name)}
    >
      <img src={DeleteIconM} alt="delete-icon" />
    </button>
  );
}

export const parseEventActionsForUser = (eventActions) => {
  const eventActionsObj = {};
  if (eventActions) {
    eventActions.map((obj) => {
      if (obj.eventId) {
        if (
          eventActionsObj[obj.eventId]
          && eventActionsObj[obj.eventId].length
        ) {
          eventActionsObj[obj.eventId] = [
            ...eventActionsObj[obj.eventId],
            { ...obj },
          ];
        } else {
          eventActionsObj[obj.eventId] = [{ ...obj }];
        }
      } else {
        // need define somewhere in the constants
        /* eslint-disable-next-line no-lonely-if */
        if (!eventActionsObj.MARK_ALL_READ) {
          eventActionsObj.MARK_ALL_READ = { ...obj };
        }
      }
      return null;
    });
  }

  return eventActionsObj;
};

export const getCurrentTimeInSeconds = () => parseInt(Date.now() / 1000);

const GenericColumnCellRenderer = function ({ params }) {
  return (
    <LightTooltip
      title={(
        <Typography className="AvenirNextLTProRegular">
          {params.value}
        </Typography>
      )}
      placement="bottom"
    >
      <div className="ellipse-onwrap font-size-14">
        <span>{params.value}</span>
      </div>
    </LightTooltip>
  );
}

const MeanBodyWeightSummaryColumnCellRenderer = function ({ params }) {
  return (
    <LightTooltip
      title={(
        <Typography className="AvenirNextLTProRegular">
          {params.value}
        </Typography>
      )}
      placement="bottom"
    >
      <div className="ellipse-onwrap font-size-14 transform-0-15">
        <span>{params.value}</span>
      </div>
    </LightTooltip>
  );
}

export const StudyDocsDescColumnCellRenderer = function ({ params }) {
  return (
    <LightTooltip
      title={(
        <Typography className="AvenirNextLTProRegular">
          {params.value}
        </Typography>
      )}
      placement="right"
      offVt={26}
      offHz={-70}
    >
      <div className="ellipse-onwrap font-size-14">
        <span>{params.value}</span>
      </div>
    </LightTooltip>
  );
}

const FavoriteCellRenderer = function ({ params }) {
  const { addFavorite, removeFavorite } = useGraphQL();

  if (params.value) {
    return (
      <span
        style={{ width: '100%', height: '100%' }}
        onClick={() => removeFavorite(params.data.project_code)}
      >
        <StarIcon style={{ fontSize: 16 }} />
      </span>
    );
  }
  return (
    <span
      style={{ width: '100%', height: '100%' }}
      onClick={() => addFavorite(params.data.project_code)}
    >
      <StarBorderIcon style={{ fontSize: 16 }} />
    </span>
  );
}

const NotificationCellRenderer = function ({ params }) {
  const { unsubscribeFromNotification, subscribeForNotification } = useGraphQL();

  if (params.value) {
    return (
      <span
        style={{ width: '100%', height: '100%' }}
        onClick={() => unsubscribeFromNotification(params.data.project_code)}
      >
        <NotificationsIcon style={{ fontSize: 16 }} />
      </span>
    );
  }
  return (
    <span
      style={{ width: '100%', height: '100%' }}
      onClick={() => subscribeForNotification(params.data.project_code)}
    >
      <NotificationsOutlinedIcon style={{ fontSize: 16 }} />
    </span>
  );
}

export const FileNameColumnCellRenderer = function ({ params }) {
  return (
    <LightTooltip
      title={(
        <Typography className="AvenirNextLTProRegular">
          {params.value}
        </Typography>
      )}
      placement="right"
      offVt={26}
      offHz={-60}
    >
      <div className="ellipse-onwrap font-size-14">
        <span>{` ${params.value}`}</span>
      </div>
    </LightTooltip>
  );
}

export const FolderNameColumnCellRenderer = function ({ params }) {
  return (
    <button
      type="button"
      onClick={() => params.enterFolderHandler(params.data.name)}
      className="stretched-link btn-link buttonWithTransparentBackground"
    >
      {params.data.name}
    </button>
  );
};

const LastModifiedCellRenderer = function ({ params }) {
  return (
    <div
      className={`font-size-1x ${params.value === '-' ? 'padding-left-60' : '-'} `}
    >
      <span className="AvenirNextLTProRegular">
        {/* {` ${params.value}`} */}
        {params.value !== '-' && params.value !== undefined
          ? moment(params.value).format('DD MMM YYYY')
          : '-'}
      </span>
    </div>
  );
}

export const formatStudyDay = (study_day, time) => {
  let studyDay = `studyDay${study_day}${time || ''}`;
  if (study_day < 0) {
    studyDay = `studyDayNeg${Math.abs(study_day)}${time || ''}`;
  }
  return studyDay;
};

export const formatStudyDayWithDate = (study_day, date, time, tabName = '') => {
  let studyDay = `Day ${study_day} (${date}, ${time})`;
  if (study_day < 0) {
    studyDay = `Day ${Math.abs(study_day)} (${date}, ${time})`;
  }
  if (tabName) {
    studyDay = `${studyDay} ${tabName}`
  }
  return studyDay;
};
export const formatStudyDayWithDateAndTime = (study_day, date, time) => {
  let studyDay = `Day ${study_day} (${date} ${time})`;
  if (study_day < 0) {
    studyDay = `Day ${Math.abs(study_day)} (${date} ${time})`;
  }
  return studyDay;
};

const valueGetter = (params) => {
  const value = params.data[params.column.colId];
  if (
    value
    && value.mean !== undefined
    && value.sem !== undefined
  ) {
    return `${value.mean} ± ${value.sem}`
  }
  return '';
};

// evaluate nullish value (null or undefined) whilst guarding errors from reference invalidation across the entire chain
const TGIValueGetter = (params) => {
  const value = params.data[params.column.colId];
  return ((value?.deltaInhibition) ? `${value.deltaInhibition}%` : '-');
};
export const getDummyColumn = (header, subHeader) => ({
  field: 'NA',
  minWidth: header === 'Dosing Records' ? 150 : 120,
  headerClass: ['padding-0', 'padding-t-b-4'],
  cellClass: [
    'text-align-center',
    'justify-content-center',
    'padding-lr-0',
    'text-align-center',
    'padding-b-10',
  ],
  headerComponent: header === 'Dosing Records' ? 'dosingRecordWithOutTime' : 'studyDayHeader',
  headerComponentParams: {
    header,
    subHeader,
  },
})
getDummyColumn.propTypes = {
  header: PropTypes.string.isRequired,
  subHeader: PropTypes.string.isRequired,
};
const FateCodeCellRenderer = function ({ params, legends }) {
  const { selectedFateCode } = useMortalityObservationLegendContext();
  const { setSelectedFateCode } = useMortalityObservationLegendContext();
  const color = selectedFateCode.length > 0
    && !selectedFateCode.includes(params.value)
    && 'lightgray';
  let tooltipText = ''
  if (params.value) {
    tooltipText = legends.find((leg) => leg.key === params.value).value
  }
  return (
    <LightTooltip
      title={(
        <Typography className="AvenirNextLTProRegular">
          {tooltipText}
        </Typography>
      )}
      placement="bottom"
      data-testid={params.value}
    >
      <div
        style={{ color, cursor: 'pointer' }}
        onClick={() => (!selectedFateCode.includes(params.value)
          ? setSelectedFateCode([...selectedFateCode, params.value])
          : setSelectedFateCode(
            selectedFateCode.filter((el) => el !== params.value),
          ))}
      >
        {params.value}
      </div>
    </LightTooltip>
  );
}

const ClinicalObservationCodesCellRenderer = function ({ value, legend }) {
  const { selectedCoCodes } = useClinicalObservationLegendContext();
  const { setSelectedCoCodes } = useClinicalObservationLegendContext();
  const tempCoCodesArray = value !== undefined && value.split(', ');
  const tooltipValue = (code) => legend.find((el) => el.key === code)
    && legend.find((el) => el.key === code).value;

  return value !== undefined ? (
    tempCoCodesArray.map((code, index) => (
      <LightTooltip
        title={(
          <Typography className="AvenirNextLTProRegular">
            {tooltipValue(code)}
          </Typography>
        )}
        placement="bottom"
        key={code}
      >
        <div
          style={{
            color:
              selectedCoCodes.length > 0
              && !selectedCoCodes.includes(code)
              && 'lightgray',
            cursor: 'pointer',
            lineHeight: '100%',
            // need maxWidth set in the columnDefs if using the below four properties; it only works (wraps lines appropriately) if entire table loads *then* CO tab is selected
            hyphens: 'none',
            display: 'inline-block',
            wordBreak: 'break-all',
            minWidth: 'fit-content',
            paddingTop: '6px',
            paddingBottom: '2px',
          }}
          onClick={() => (!selectedCoCodes.includes(code)
            ? setSelectedCoCodes([...selectedCoCodes, code])
            : setSelectedCoCodes(selectedCoCodes.filter((el) => el !== code)))}
          data-toggle="tooltip"
          data-html="true"
        >
          {code}
          {tempCoCodesArray.length - 1 !== index && ', '}&nbsp;
        </div>
      </LightTooltip>
    ))
  ) : (
    // Below is required to prevent AG Grid's "React Component '...' not created within 1000ms" error
    <span />
  );
}

const DosingRecordCellRenderer = function ({ params }) {
  return params.value ? (
    <LightTooltip
      title={(
        <Typography className="AvenirNextLTProRegular">
          {params.value.split('\n')[0]}
          <br />
          {params.value.split('\n')[1]}
          <br />
          {params.value.split('\n')[2]}
        </Typography>
      )}
      placement="bottom"
    >
      <span data-toggle="tooltip" data-html="true">
        {params.value.split('\n')[3] === '0' ? 'Holiday' : '✓'}
      </span>
    </LightTooltip>
  ) : (
    ''
  );
}

const siteCellFormatter = (params) => `${_.isArray(params.value) ? params.value[0]?.substr(0, 4) : params.value.substr(0, 4)}...`;

// keep below as we will likely re-include post-MVP
// function fateDay(animal) {
//   return animal.fate_code !== '' || !animal.fate_code.includes(',') ? animal.fate_day : '-'
// }

function fateCode(animal) {
  if (animal.fate_code.includes(',')) {
    return 'N/A'
  } if (animal.fate_code !== '') {
    return animal.fate_code
  }
  return 'A'

}

function stagingDate(animal) {
  return animal.staging_date !== ('' && null) ? animal.staging_date : '-'
}

function mortalityDate(animal) {
  return animal.mortality_date !== ('' && null) ? animal.mortality_date : '-'
}

export const formatStudiesTableGridOpts = (
  userId,
  studies,
  favorites,
  notifications,
) => {
  const gridOptions = { ...DEFAULT_GRIDOPTS };

  gridOptions.pagination = true;
  // gridOptions.defaultColDef.sortable = true
  gridOptions.defaultColDef.resizable = false;

  // gridOptions.enableColResize = true
  gridOptions.onFirstDataRendered = function onFirstDataRendered(params) {
    const columns = params.columnApi.getAllColumns();
    columns.forEach((c) => {
      c.addEventListener('filterActiveChanged', (event) => {
        const elem = document.getElementById(
          event.column.colDef.headerComponentParams.headerName,
        );
        if (elem && elem.classList.contains('visibility-hidden')) {
          elem.classList.remove('visibility-hidden');
        } else if (elem) {
          elem.classList.add('visibility-hidden');
        }
      });
    });
    params.api.sizeColumnsToFit();
  };
  gridOptions.columnDefs = [
    {
      headerClass: ['font-size-10', 'padding-0'],
      headerComponent: 'notificationIconCellRenderer',
      field: 'notification',
      cellClass: [
        'padding-lr-0',
        'text-align-center',
        'justify-content-center',
        'font-size-10',
      ],
      sort: false,
      // unSortIcon: true,
      maxWidth: 100,
      cellRenderer: (params) => (
        <NotificationCellRenderer params={params} />
      ),
    },
    {
      headerClass: ['font-size-10', 'padding-0'],
      headerComponent: 'favoriteIconCellRenderer',
      field: 'favorite',
      cellClass: [
        'padding-lr-0',
        'text-align-center',
        'justify-content-center',
        'font-size-10',
      ],
      sort: false,
      // unSortIcon: true,
      maxWidth: 100,
      cellRenderer: (params) => (
        <FavoriteCellRenderer params={params} />
      ),
    },
    {
      headerClass: ['font-size-10', 'padding-0'],
      headerComponent: 'studyListHeaderRenderer',
      headerComponentParams: {
        headerName: 'Company',
      },
      field: 'company',
      cellClass: [
        'padding-lr-0',
        'text-align-center',
        'justify-content-center',
        'font-size-10',
      ],
      unSortIcon: true,
      tooltipField: 'company',
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      filterParams: { buttons: ['reset'] },
    },
    {
      headerClass: ['font-size-10', 'padding-0'],
      headerComponent: 'studyListHeaderRenderer',
      headerComponentParams: {
        headerName: 'Project Code',
      },
      field: 'project_code',
      cellClass: [
        'padding-lr-0',
        'text-align-center',
        'justify-content-center',
        'font-size-10',
      ],
      cellRenderer: (params) => <LinkComponent params={params} />,
      //  cellRenderer: (params) => (<LinkComponent {params} />),
      unSortIcon: true,
      tooltipField: 'project_code',
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      filterParams: { buttons: ['reset'] },
    },
    {
      headerClass: ['font-size-10', 'padding-0'],
      headerComponent: 'studyListHeaderRenderer',
      headerComponentParams: {
        headerName: 'Status',
      },
      field: 'status',
      cellClass: [
        'padding-lr-0',
        'text-align-center',
        'justify-content-center',
        'font-size-10',
      ],
      unSortIcon: true,
      tooltipField: 'status',
      filter: 'agSetColumnFilter',
      floatingFilter: true,
      filterParams: { buttons: ['reset'] },
      valueGetter: (params) => params.data.status?.split(',').map((status) => status.trim()),
    },
    {
      headerClass: ['font-size-10', 'padding-0'],
      headerComponent: 'studyListHeaderRenderer',
      headerComponentParams: {
        headerName: 'Site',
      },
      field: 'site',
      valueFormatter: siteCellFormatter,
      cellClass: [
        'padding-lr-0',
        'text-align-center',
        'justify-content-center',
        'font-size-10',
      ],
      unSortIcon: true,
      tooltipField: 'site',
      filter: 'agSetColumnFilter',
      floatingFilter: true,
      filterParams: { buttons: ['reset'] },
      valueGetter: (params) => params.data.site?.split(',').map((site) => site.trim()),
    },
    {
      headerClass: ['font-size-10', 'padding-0'],
      headerComponent: 'studyListHeaderRenderer',
      headerComponentParams: {
        headerName: 'Study Type',
      },
      field: 'study_type',
      cellClass: [
        'padding-lr-0',
        // "padding-r-0",
        // "padding-l-10",
        'text-align-center',
        'justify-content-center',
        'font-size-10',
      ],
      unSortIcon: true,
      tooltipField: 'study_type',
      filter: 'agSetColumnFilter',
      floatingFilter: true,
      filterParams: { buttons: ['reset'] },
      valueGetter: (params) => params.data.study_type
        ?.split(',')
        .map((studyType) => studyType.trim()),
    },
    {
      headerClass: ['font-size-10', 'padding-0'],
      headerComponent: 'studyListHeaderRenderer',
      headerComponentParams: {
        headerName: 'Model ID',
      },
      field: 'model_id',
      cellClass: [
        'padding-lr-0',
        'text-align-center',
        'justify-content-center',
        'font-size-10',
      ],
      unSortIcon: true,
      tooltipField: 'model_id',
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      filterParams: { buttons: ['reset'] },
    },
    {
      headerClass: ['font-size-10', 'padding-0'],
      headerComponent: 'studyListHeaderRenderer',
      headerComponentParams: {
        headerName: 'Project Sponsor',
      },
      field: 'sponsor',
      cellClass: [
        'padding-lr-0',
        'text-align-center',
        'justify-content-center',
        'font-size-10',
      ],
      unSortIcon: true,
      tooltipField: 'sponsor',
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      filterParams: { buttons: ['reset'] },
    },
    {
      headerClass: ['font-size-10', 'padding-0'],
      headerComponent: 'studyListHeaderRenderer',
      headerComponentParams: {
        headerName: 'Study Director',
      },
      field: 'director',
      cellClass: [
        'padding-lr-0',
        'text-align-center',
        'justify-content-center',
        'font-size-10',
      ],
      unSortIcon: true,
      tooltipField: 'director',
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      filterParams: { buttons: ['reset'] },
    },
  ];
  const rows = [];
  studies.forEach((study) => {
    rows.push({
      company: study.company,
      project_code: study.project_code,
      status: study.status,
      site: study.site,
      study_type: study.study_type,
      model_id: study.model_id,
      sponsor: study.sponsor,
      director: study.director,
      favorite: favorites.includes(study.project_code),
      notification: notifications.includes(study.project_code),
    });
  });
  gridOptions.rowData = rows;
  return gridOptions;
};

export const formatFoldersTableGridOpts = (folders, setGridApi) => {
  const gridOptions = { ...DEFAULT_GRIDOPTS };

  if (setGridApi) {
    gridOptions.onGridReady = (event) => setGridApi(event.api);
  }

  gridOptions.onFirstDataRendered = function onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  };

  gridOptions.columnDefs = [
    {
      suppressMenu: true,
      unSortIcon: true,
      sortable: true,
      sort: 'asc',
      sortingOrder: ['asc', 'desc'],
      headerName: 'Name',
      field: 'name',
      cellClass: 'font-size-14',
      headerClass: ['font-size-14', 'ellipse-onwrap'],
      cellRenderer: 'folderNameSelectCellRenderer',
    },
    {
      suppressMenu: true,
      unSortIcon: true,
      sortable: true,
      sortingOrder: ['asc', 'desc'],
      headerName: 'Last Modified',
      field: 'lastModified',
      cellRenderer: (params) => (
        <LastModifiedCellRenderer params={params} />
      ),
      headerClass: ['font-size-14', 'ellipse-onwrap'],
    },
  ];
  const rows = [];
  Object.values(folders).forEach((folder) => {
    rows.push({
      name: folder.name,
      lastModified: folder.lastModified,
    });
  });
  gridOptions.rowData = rows;
  gridOptions.rowHeight = 61;

  return gridOptions;
};

export const getTopLevelGridOpts = (
  folders,
  enterFolderHandler = () => {},
  deleteFolderHandler = () => {},
  isCustomFoldersEnabled,
  hideEmptyFolders,
) => {
  const gridOptions = { ...DEFAULT_GRIDOPTS };

  gridOptions.onFirstDataRendered = function onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  };

  // Custom comparator function for case-insensitive sorting
  const nameComparator = (valueA, valueB) => {
    const stringA = valueA ? valueA.toString().toLowerCase() : '';
    const stringB = valueB ? valueB.toString().toLowerCase() : '';

    if (stringA === stringB) {
      return 0;
    }

    return stringA < stringB ? -1 : 1;
  };

  gridOptions.columnDefs = [
    {
      suppressMenu: true,
      unSortIcon: true,
      sortable: true,
      sort: 'asc',
      sortingOrder: ['asc', 'desc'],
      headerName: 'Name',
      field: 'name',
      cellClass: 'font-size-14',
      headerClass: ['font-size-14', 'ellipse-onwrap'],
      minWidth: 300,
      cellRenderer: (params) => {
        const p = { ...params, enterFolderHandler };
        return <FolderNameColumnCellRenderer params={p} />;
      },
      comparator: nameComparator,
    },
    {
      suppressMenu: true,
      unSortIcon: true,
      sortable: true,
      sortingOrder: ['asc', 'desc'],
      headerName: 'Last Modified',
      field: 'lastModified',
      cellRenderer: (params) => <LastModifiedCellRenderer params={params} />,
      headerClass: ['font-size-14', 'ellipse-onwrap'],
    },
    hideEmptyFolders ? null
      : {
        field: 'delete',
        headerName: 'Delete',
        suppressMenu: true,
        cellClass: [
          'font-size-14',
          'text-align-center',
          'justify-content-center',
        ],
        headerClass: ['font-size-14'],
        maxWidth: 100,
        headerComponentParams: {
          template: HEADER_WITHOUT_PADDING,
        },
        cellRenderer: (params) => {
          const p = { ...params, deleteFolderHandler };
          const { isCustomFoldersEnabled } = params.data;
          return isCustomFoldersEnabled ? (
            <FolderNameDeleteSelectCellRenderer params={p} />
          ) : (
            ''
          );
        },
      },
  ].filter((column) => column !== null);
  const rows = [];
  Object.values(folders).forEach((folder) => {
    const isCustomFolder = !CUSTOM_DEFAULT_FOLDERS[`${folder.name}/`];
    if (!hideEmptyFolders) {
      rows.push({
        name: folder.name,
        lastModified: folder.lastModified,
        isCustomFolder,
        isCustomFoldersEnabled,
      });
    } else if (hideEmptyFolders && (folder.files.length !== 0 || folder.folders)) {
      rows.push({
        name: folder.name,
        lastModified: folder.lastModified,
        isCustomFolder,
        isCustomFoldersEnabled,
      });
    }
  });
  gridOptions.rowData = rows;
  gridOptions.rowHeight = 61;

  return gridOptions;
};

export const formatMixedFolderTableGridOpts = async (
  files,
  folders,
  isDownloadAuthorized,
  isCustomFoldersEnabled,
  // eslint-disable-next-line
  downloadAllHeaderComponent = (<a href="#" />),
  enterFolderHandler = () => { },
  deleteFolderHandler = () => { },
) => {
  const formatFilesTableGridOpts = { ...DEFAULT_GRIDOPTS };

  formatFilesTableGridOpts.onFirstDataRendered = function onFirstDataRendered(
    params,
  ) {
    params.api.sizeColumnsToFit();
  };
  const columnDefs = [
    {
      suppressMenu: true,
      unSortIcon: true,
      sortable: true,
      sort: 'asc',
      sortingOrder: ['asc', 'desc'],
      headerName: 'Name',
      field: 'name',
      cellClass: 'font-size-14',
      headerClass: ['font-size-14', 'ellipse-onwrap'],
      minWidth: 260,
      comparator: naturalSort,
      cellRenderer: (params) => {
        const p = { ...params, enterFolderHandler };
        return params.data.isFolder ? (
          <FolderNameColumnCellRenderer params={p} />
        ) : (
          <FileNameColumnCellRenderer params={params} />
        );
      },
    },
    {
      suppressMenu: true,
      unSortIcon: true,
      sortable: true,
      sortingOrder: ['asc', 'desc'],
      headerName: 'Last Modified',
      field: 'lastModified',
      cellClass: 'font-size-14',
      headerClass: ['font-size-14'],
      headerComponentParams: {
        template: HEADER_WITHOUT_PADDING,
      },
    },
    {
      field: 'download',
      headerName: '',
      headerClass: ['font-size-10'],
      maxWidth: 120,
      // eslint-disable-next-line
      cellClass: (params) =>
        // eslint-disable-next-line
        !params.data.isFolder
          ? [
            'agGrid-downloadIconColumn',
            'text-align-center',
            'justify-content-center',
            'padding-l0',
          ]
          : null,
      headerComponent: () => downloadAllHeaderComponent,
      cellRenderer: (params) => {
        const p = { ...params, enterFolderHandler };
        return !params.data.isFolder ? (
          <FileNameDownloadSelectCellRenderer params={p} />
        ) : null;
      },
    },
    {
      field: 'delete',
      headerName: 'Delete',
      suppressMenu: true,
      cellClass: [
        'font-size-14',
        'text-align-center',
        'justify-content-center',
      ],
      headerClass: ['font-size-14'],
      headerComponentParams: {
        template: HEADER_WITHOUT_PADDING,
      },
      maxWidth: 100,
      cellRenderer: (params) => {
        const p = { ...params, deleteFolderHandler };
        const renderFolderDelete = isCustomFoldersEnabled ? <FolderNameDeleteSelectCellRenderer params={p} /> : '';
        return !params.data.isFolder ? (
          <FileNameDeleteSelectCellRenderer params={params} />
        ) : renderFolderDelete;
      },
    },
  ];
  if (!isDownloadAuthorized) {
    // It will remove download column
    columnDefs.pop()
  }

  // Mixing files and folders
  const rowData = [];
  files.forEach((file) => {
    rowData.push({
      name: file.name,
      description: file.description,
      lastModified:
        file.lastModified !== '-'
          ? moment(file.lastModified).format('DD MMM YYYY')
          : '-',
      path: file.s3Path,
      ifFolder: false,
    });
  });
  Object.values(folders).forEach((folderObj) => {
    if (folderObj.name) {
      rowData.push({
        name: folderObj.name,
        description: '',
        lastModified: folderObj.lastModified && folderObj.lastModified !== '-'
          ? moment(folderObj.lastModified).format('DD MMM YYYY')
          : '-',
        path: '',
        isFolder: true,
      });
    }
  });

  formatFilesTableGridOpts.rowData = rowData;
  formatFilesTableGridOpts.columnDefs = columnDefs;
  return formatFilesTableGridOpts;
};

const naturalSort = (value_a, value_b) => {
  const lowerA = value_a.toLowerCase();
  const lowerB = value_b.toLowerCase();
  return (
    (lowerB.localeCompare('a') >= 0) - (lowerA.localeCompare('a') >= 0)
    || lowerA.localeCompare(lowerB)
  );
};
export const formatFilesTableGridOpts = async (
  files,
  isDownloadAuthorized,
  setGridApi,
  auditDownload = () => { },
  // eslint-disable-next-line
  downloadAllHeaderComponent = (<a href="#" />),
) => {
  const formatFilesTableGridOpts = { ...DEFAULT_GRIDOPTS };
  // no need from v26.0
  // formatFilesTableGridOpts.applyColumnDefOrder = true;

  if (setGridApi) {
    formatFilesTableGridOpts.onGridReady = (event) => setGridApi(event.api);
  }

  formatFilesTableGridOpts.onFirstDataRendered = function onFirstDataRendered(
    params,
  ) {
    params.api.sizeColumnsToFit();
  };
  const columnDefs = [
    {
      suppressMenu: true,
      unSortIcon: true,
      sortable: true,
      sort: 'asc',
      sortingOrder: ['asc', 'desc'],
      headerName: 'Name',
      field: 'name',
      cellClass: 'font-size-14',
      headerClass: ['font-size-14', 'ellipse-onwrap'],
      // tooltipField: 'name',
      // maxWidth: 120,
      comparator: naturalSort,
      cellRenderer: (params) => (
        <FileNameColumnCellRenderer params={params} />
      ),
    },

    {
      suppressMenu: true,
      unSortIcon: true,
      sortable: true,
      sortingOrder: ['asc', 'desc'],
      headerName: 'Description',
      field: 'description',
      cellClass: 'font-size-14',
      headerClass: ['font-size-14', 'ellipse-onwrap'],
      // tooltipField: "description",
      cellRenderer: (params) => (
        <StudyDocsDescColumnCellRenderer params={params} />
      ),
    },
    {
      suppressMenu: true,
      unSortIcon: true,
      sortable: true,
      sortingOrder: ['asc', 'desc'],
      headerName: 'Last Modified',
      field: 'lastModified',
      cellClass: 'font-size-14',
      headerClass: ['font-size-14'],
      headerComponentParams: {
        template: HEADER_WITHOUT_PADDING,
      },
      // maxWidth: 120,
    },
    {
      field: 'download',
      headerName: '',
      headerClass: ['font-size-10'],
      cellClass: [
        'agGrid-downloadIconColumn',
        'text-align-center',
        'justify-content-center',
        'padding-l0',
      ],
      headerComponent: () => downloadAllHeaderComponent,
      cellRenderer: (params) => {
        const p = { ...params, auditDownload };
        return <FileNameDownloadSelectCellRenderer params={p} />
      },
    },
    {
      field: 'delete',
      headerName: '',
      maxWidth: 40,
      cellClass: [
        'agGrid-trashIconColumn',
        'text-align-center',
        'justify-content-center',
        'padding-l0',
      ],
      cellRenderer: (params) => (
        <FileNameDeleteSelectCellRenderer params={params} />
      ),
    },
  ];
  if (!isDownloadAuthorized) {
    // It will remove download column
    columnDefs.pop()
  }

  const rowData = files.map((file) => ({
    name: file.name,
    description: file.description,
    lastModified:
      file.lastModified !== '-'
        ? moment(file.lastModified).format('DD MMM YYYY')
        : '-' /* file.lastModified, */,
    path: file.s3Path,
  }));
  formatFilesTableGridOpts.rowData = rowData;
  formatFilesTableGridOpts.columnDefs = columnDefs;
  return formatFilesTableGridOpts;
};

export const formatTaskMeasurementSummaryData = (groups, acronym, colors, meanHoverText) => {
  const alphabeticalGroups = _.sortBy(groups, 'group_name');
  const tumorVolumeData = [];
  const graphMode = acronym === 'TV' ? 'lines+markers' : 'lines';

  alphabeticalGroups.forEach((group, index) => {
    const data = {
      x: [],
      y: [],
      name: '',
      type: 'scatter',
      text: [],
      hoverinfo: 'text',
      error_y: {
        type: 'data',
        array: [],
        visible: true,
      },
      mode: graphMode,
      line: {
        color: (colors && colors.length && colors[index]) || 'red',
      },
    };
    const groupName = truncationOfLongStr(group.group_name_to_show);
    data.name = groupName;
    group.study_day_metrics.forEach((study_day_metric) => {
      const x = study_day_metric.study_day;
      const y = study_day_metric.mean;
      const n = study_day_metric.count && study_day_metric.count
      const standardError = study_day_metric.sem;
      const upperBar = _.round(y + standardError, 2).toFixed(2);
      const lowerBar = _.round(y - standardError, 2).toFixed(2);
      const intervalLength = _.round(upperBar - lowerBar, 2).toFixed(2);
      /* eslint-disable-next-line no-nested-ternary */
      const measurement = acronym === 'TV'
        ? '(mm<sup>3</sup>)'
        : acronym === 'BW'
          ? '(g)'
          : '(%)';
      const hoverTemplateString = `<b>Study Day</b>: ${x}<br >`
        + `<b>Group</b>: ${groupName}<br>`
        + `<b>Number (n): ${n}<br >`
        + `<b>${meanHoverText || `Mean ${acronym} ${measurement}`}</b>: ${_.round(y, 2).toFixed(
          2,
        )}<br>`
        + `<b>SEM Upper Bar</b>: ${upperBar}<br>`
        + `<b>SEM Lower Bar</b>: ${lowerBar}<br>`
        + `<b>SEM Interval Length</b>: ${intervalLength}<br>`;

      data.text.push(hoverTemplateString);
      data.x.push(x);
      data.y.push(y);
      data.error_y.array.push(study_day_metric.sem);
    });
    tumorVolumeData.push(data);
  });

  return tumorVolumeData;
};

/**
 * This function checks if all animals assigned to a group are dead
 * @param {object} group - The group you want to check for the condition above
 * @return {object} returns an object with 2 properties `areAllAnimalsDead` and `highestDateWithinGroup`
 * if all animals are dead and a mortality date is assigned to all animals with in the passed group then
 * `areAllAnimalsDead` would be true, false otherwise.
 */
const checkIfAllAnimalsAreDeadWithinGroup = (group) => {
  const { animals } = group;
  const areAllAnimalsDead = animals?.every(({ mortality_date }) => Boolean(mortality_date));
  const parseDateUsingMoment = (datestr) => moment(datestr, 'MM/DD/YYYY')
  let highestDateWithinGroup;
  if (areAllAnimalsDead) {
    highestDateWithinGroup = parseDateUsingMoment(animals.sort((a, b) => parseDateUsingMoment(b) - parseDateUsingMoment(a))[0].mortality_date);
  }
  return { areAllAnimalsDead, highestDateWithinGroup }
}
/**
 * This function is responsible for rendering either data or grey colored cell in summary grid.
 * for further details CCP-3402
 * @param {object} animalsStatus - Contains 2 properties, `areAllAnimalsDead` and `highestDateWithinGroup`.
 * if all animals within a specific group are assigned with mortality date than `areAllAnimalsDead` would be true
 * @param {date} date - The data for which the cell is being rendered
 * @param {Object} gridApi - gridApi is object, ag grid passes to its renderer function as first parameter
 * @return {string} returns either value of the cell or greyed background colored cell with empty contents
 */
const summaryGridsCellRenderer = (date, gridApi) => {
  const { animalsStatus } = gridApi.data;
  if (animalsStatus.areAllAnimalsDead && animalsStatus.highestDateWithinGroup < date) {
    gridApi.eGridCell.classList.add('bgLightgray')
    return '';
  }

  return gridApi.value;
}

export const formatTaskMeasurementSummaryGridOptions = (groups, setGridApi, scientificFormat = false) => {
  const gridOptions = { ...DEFAULT_GRIDOPTS };
  gridOptions.headerHeight = 60;
  gridOptions.rowHeight = 50

  if (setGridApi) {
    gridOptions.onGridReady = (event) => setGridApi(event.api);
  }

  const filteredGroups = _.filter(
    groups,
    (o) => o.study_day_metrics.length > 0,
  );

  const alphabeticalGroups = _.sortBy(filteredGroups, 'group_name');

  const longestAlphabeticalGroup = _.maxBy(filteredGroups, 'study_day_metrics.length')

  const columnDefs = [
    {
      minWidth: 70,
      maxWidth: 150,
      // tooltipField: "group",
      headerName: 'Group',
      field: 'group',
      pinned: 'left',
      cellRenderer: (params) => (
        <GenericColumnCellRenderer params={params} />
      ),
    },
  ];

  _.map(longestAlphabeticalGroup?.study_day_metrics, (group) => {
    const headerName = `Day ${group.study_day}`;

    const date = moment(group.study_date, 'MM/DD/YYYY');
    const formattedDate = date.format('DD MMM YYYY');
    const weekDay = date.format('dddd');
    columnDefs.push({
      minWidth: scientificFormat ? 180 : 145,
      headerName,
      headerComponent: 'dateStudyDayDayOfTheWeekHeader',
      headerComponentParams: {
        studyDate: formattedDate,
        studyDay: headerName,
        weekDay,
      },
      field: formatStudyDay(group.study_day),
      valueGetter,
      headerClass: ['text-align-center', 'justify-content-center'],
      cellClass: ['text-align-center', 'justify-content-center'],
      cellRenderer: (params) => summaryGridsCellRenderer(date, params),
    });
  });
  const rows = [];
  alphabeticalGroups.forEach((group) => {
    const newRow = {};
    newRow.animalsStatus = checkIfAllAnimalsAreDeadWithinGroup(group);
    newRow.group = group.group_name_to_show;
    group.study_day_metrics.forEach((studyDay) => {
      newRow[formatStudyDay(studyDay.study_day)] = {
        mean: scientificFormat ? convertToScientificFormat(_.round(studyDay.mean, 2).toFixed(2)) : _.round(studyDay.mean, 2).toFixed(2),
        sem: scientificFormat ? convertToScientificFormat(_.round(studyDay.sem, 2).toFixed(2)) : _.round(studyDay.sem, 2).toFixed(2),
      };
    });
    rows.push(newRow);
  });

  gridOptions.columnDefs = columnDefs;
  gridOptions.rowData = rows;
  return gridOptions;
};
/**
 * Used to format grids for tumor growth mean tables
 * @param {string} dataType - if value is 'delta_inhibition' then the data is being formatted for 'Tumor Growth Inhibition Delta' grid
 */
export const formatTGISummaryGridOptions = (groups, setGridApi, dataType = 'delta_inhibition') => {
  const gridOptions = { ...DEFAULT_GRIDOPTS }
  gridOptions.headerHeight = 60
  gridOptions.rowHeight = 50

  if (setGridApi) {
    gridOptions.onGridReady = (event) => setGridApi(event.api)
  }

  const filteredGroups = _.filter(
    groups,
    (o) => o.study_day_metrics.length > 0,
  )

  const alphabeticalGroups = _.sortBy(filteredGroups, 'group_name')

  const longestAlphabeticalGroup = _.maxBy(filteredGroups, 'study_day_metrics.length')

  const columnDefs = [
    {
      minWidth: 70,
      maxWidth: 150,
      // tooltipField: "group",
      headerName: 'Group',
      field: 'group',
      pinned: 'left',
      cellRenderer: (params) => (
        <GenericColumnCellRenderer params={params} />
      ),
    },
  ]
  _.map(longestAlphabeticalGroup?.study_day_metrics, (group) => {
    const headerName = `Day ${group.study_day}`

    const date = moment(group.study_date, 'MM/DD/YYYY')
    const formattedDate = date.format('DD MMM YYYY')
    const weekDay = date.format('dddd')
    columnDefs.push({
      minWidth: 145,
      headerName,
      headerComponent: 'dateStudyDayDayOfTheWeekHeader',
      headerComponentParams: {
        studyDate: formattedDate,
        studyDay: headerName,
        weekDay,
      },
      field: formatStudyDay(group.study_day),
      // cellRenderer,
      // ToDo CCP-1161 - swap above with below once BE incorporates TGI data for FE
      valueGetter: TGIValueGetter,
      headerClass: ['text-align-center', 'justify-content-center'],
      cellClass: ['text-align-center', 'justify-content-center'],
      cellRenderer: (params) => summaryGridsCellRenderer(date, params),
    })
  })
  const rows = []

  // ToDo CCP-1165 - remove below example 'math' logic from FE with arbitrary/stubbed data to BE with actual/filtered data
  // Mean % Δ Inhibition = ((mean(C) - mean(C0)) - (mean(T) - mean(T0))) / (mean(C) - mean(C0)) * 100%

  // // set arbitrary Control Group to first Group
  // const controlGroup = alphabeticalGroups[0]
  // // set arbitrary initial value (BWC reference day) to first study day
  // const controlGroupInitialValue = controlGroup.study_day_metrics[0].mean
  // // include optional chaining to protect against undefined results
  // const controlGroupCurrentValue = (rollingStudyDay) => controlGroup.study_day_metrics.filter(day => day.study_day === rollingStudyDay)[0]?.mean

  alphabeticalGroups.forEach((group) => {
    const newRow = {}
    newRow.group = group.group_name_to_show
    newRow.animalsStatus = checkIfAllAnimalsAreDeadWithinGroup(group)
    group.study_day_metrics.forEach((studyDay) => {
      newRow[formatStudyDay(studyDay.study_day)] = {
        // ensure value (even if nullish) for params is always sent to TGICellRenderer whilst guarding errors from reference invalidation across the entire chain
        deltaInhibition: studyDay && studyDay[dataType]?.toFixed(2),
      }
    })
    rows.push(newRow)
  })

  gridOptions.columnDefs = columnDefs
  gridOptions.rowData = rows
  return gridOptions
}

export const formatTaskMeasurementIndividualData = (
  individualGroups,
  acronym,
  getColors,
) => {
  const filteredGroups = _.filter(
    individualGroups,
    (o) => o.animals.length > 0,
  );

  const alphabeticalGroups = _.sortBy(filteredGroups, 'group_name');

  const individualTVGroups = {
    chartValues: {},
    xaxisRange: [],
    yaxisRange: [],
  };

  const graphMode = acronym === 'TV' ? 'lines+markers' : 'lines';

  if (!alphabeticalGroups.length) {
    return {};
  }

  alphabeticalGroups.forEach((group) => {
    let colors = [];
    if (getColors) {
      colors = getColors(acronym, `${acronym}-individual-${group.group_name}`);
    }
    individualTVGroups.chartValues[group.group_name_to_show] = [];
    _.sortBy(group.animals, 'subject_id').forEach((animal, index) => {
      const groupData = {
        cliponaxis: false,
        x: [],
        y: [],
        name: '',
        type: 'scatter',
        text: [],
        hoverinfo: 'text',
        mode: graphMode,
        line: {
          color: (colors && colors.length && colors[index]) || 'red',
        },
      };
      groupData.name = animal.subject_id;

      animal.sessions.forEach((session) => {
        const measurement = acronym === 'TV' ? '(mm<sup>3</sup>)' : '(g)';
        const bottomLine = acronym === 'BWC'
          ? `<b>Individual ${acronym}</b>: ${_.multiply(
            session.value,
            100,
          ).toFixed(2)}%<br>`
          : `<b>Individual ${acronym} ${measurement}</b>: ${_.round(
            session.value,
            2,
          ).toFixed(2)}<br>`;
        const hoverTemplateString = `<b>Study Day</b>: ${session.study_day}<br >`
          + `<b>Animal ID</b>: ${animal.subject_id}<br>`
          + `${bottomLine}`;
        groupData.x.push(session.study_day);
        groupData.y.push(_.round(session.value, 2).toFixed(2));
        groupData.text.push(hoverTemplateString);
      });
      individualTVGroups.chartValues[group.group_name_to_show].push(groupData);
    });
  });

  const sessionValues = _(alphabeticalGroups)
    .flatMap('animals')
    .flatMap('sessions')
    .map((session) => Number.parseFloat(session.value))
    .value();
  const sessionStudyDays = _(alphabeticalGroups)
    .flatMap('animals')
    .flatMap('sessions')
    .map((session) => session.study_day)
    .value();

  const minX = _.min(sessionStudyDays);
  const maxX = _.max(sessionStudyDays);
  const lengthX = Math.floor(Math.abs(maxX)).toString().length - 2
  const padX = Number([1, ...Array.from({ length: lengthX }, () => 0)].join('')) / 2
  individualTVGroups.xaxisRange = [minX - padX, maxX + padX];

  const minY = _.min(sessionValues);
  const maxY = _.max(sessionValues);
  const lengthY = Math.floor(Math.abs(maxY)).toString().length - 2
  const padY = Number([1, ...Array.from({ length: lengthY }, () => 0)].join(''))
  individualTVGroups.yaxisRange = [minY - (acronym === 'BWC' ? padY / 100 : padY), maxY + (acronym === 'BWC' ? padY / 100 : padY)];
  return individualTVGroups;
};

const getSortedStudyDays = (groups) => {

  const alphabeticalGroups = _.sortBy(groups, 'group_name');

  const uniqueStudyDays = _.uniqBy(
    _(alphabeticalGroups)
      .flatMap('animals')
      .flatMap('sessions')
      .map((session) => session)
      .value(),
    'study_day',
  );

  const sortedUniqueStudyDays = _.sortBy(uniqueStudyDays, 'study_day');
  return {
    alphabeticalGroups,
    sortedUniqueStudyDays,
  }
}
export const formatTaskMeasurementIndividualGridOptions = (groups, setGridApi, scientificFormat = false) => {
  const gridOptions = { ...DEFAULT_GRIDOPTS }
  gridOptions.headerHeight = 100
  gridOptions.rowHeight = 50
  if (setGridApi) {
    gridOptions.onGridReady = (event) => setGridApi(event.api);
  }
  const rows = [];
  const columnDefs = [
    {
      headerName: 'Group',
      field: 'group',
      minWidth: 120,
      maxWidth: 270,
      resizable: false,
      headerClass: ['inline-block', 'padding-l-24'/* , 'margin-top-30', 'margin-left-20' */],
      // tooltipField: 'group',
      pinned: 'left',
      cellClass: ['padding-l2', 'padding-r0', 'text-align-center'],
      cellRenderer: (params) => (
        <GenericColumnCellRenderer params={params} />
      ),
    },
    {
      headerName: 'Animal ID',
      field: 'subjectId',
      minWidth: 50,
      maxWidth: 60,
      resizable: false,
      headerComponentParams: {
        template: ROTATED_HEADER_TEMPLATE,
      },
      headerClass: ['inline-block', 'padding-0'],
      cellClass: ['padding-lr-0', 'text-align-center'],
      pinned: 'left',
    },
    {
      headerName: 'Temp ID',
      field: 'tempId',
      minWidth: 50,
      maxWidth: 60,
      resizable: false,
      headerComponentParams: {
        template: ROTATED_HEADER_TEMPLATE,
      },
      headerClass: ['inline-block', 'padding-0'],
      cellClass: ['padding-lr-0', 'text-align-center'],
      pinned: 'left',
    },
    {
      headerName: 'Sex',
      field: 'subjectType',
      minWidth: 40,
      maxWidth: 50,
      resizable: false,
      headerComponentParams: {
        template: ROTATED_HEADER_TEMPLATE,
      },
      headerClass: ['inline-block', 'padding-0'],
      cellClass: ['padding-lr-0', 'text-align-center'],
      pinned: 'left',
    },
    {
      headerName: 'Fate Code',
      field: 'fateCode',
      minWidth: 40,
      maxWidth: 50,
      resizable: false,
      headerComponentParams: {
        template: ROTATED_HEADER_TEMPLATE,
      },
      headerClass: ['inline-block', 'padding-0'],
      cellClass: ['padding-lr-0', 'text-align-center'],
      pinned: 'left',
    },
    // keep below as we will likely re-include post-MVP
    // {
    //   headerName: "Fate Day",
    //   field: "fateDay",
    //   minWidth: 40,
    //   maxWidth: 50,
    //   resizable: false,
    //   headerComponentParams: {
    //     template: ROTATED_HEADER_TEMPLATE,
    //   },
    //   headerClass: ["inline-block", "padding-0"],
    //   cellClass: ["padding-lr-0", "text-align-center"],
    //   pinned: "left",
    // },
    {
      headerName: 'Staging Date',
      field: 'stagingDate',
      minWidth: 96,
      maxWidth: 116,
      resizable: false,
      headerComponentParams: {
        template: ROTATED_HEADER_TEMPLATE_WORD_WRAP,
      },
      // headerClass: ["inline-block", "padding-0"],
      cellClass: ['padding-lr-0', 'text-align-center'],
      pinned: 'left',
    },
    {
      headerName: 'Mortality Date',
      field: 'mortalityDate',
      minWidth: 96,
      maxWidth: 116,
      resizable: false,
      headerComponentParams: {
        template: ROTATED_HEADER_TEMPLATE_WORD_WRAP,
      },
      // headerClass: ["inline-block", "padding-0"],
      cellClass: ['padding-lr-0', 'text-align-center'],
      pinned: 'left',
    },
  ];

  const alphabeticalGroups = _.sortBy(groups, 'group_name');

  const uniqueStudyDays = _.uniqBy(
    _(alphabeticalGroups)
      .flatMap('animals')
      .flatMap('sessions')
      .map((session) => session)
      .value(),
    'study_day',
  );

  // Sort study day headers in chronological order by study_day
  const sortedUniqueStudyDays = _.sortBy(uniqueStudyDays, 'study_day');

  sortedUniqueStudyDays.forEach((session) => {
    const headerName = `Day ${session.study_day}`;
    const date = moment(session.study_date, 'MM/DD/YYYY');
    const formattedDate = date.format('DD MMM YYYY');
    const weekDay = date.format('dddd');
    columnDefs.push({
      field: formatStudyDay(session.study_day, session.study_time),
      minWidth: 100,
      headerClass: ['inline-block', 'padding-0', 'padding-t-b-4'/* , "margin-top-30" */],
      cellClass: [
        'text-align-center',
        'justify-content-center',
        'padding-lr-0',
        'text-align-center',
        'padding-b-10',
      ],
      headerComponent: 'dateStudyDayDayOfTheWeekHeader',
      headerComponentParams: {
        studyDate: formattedDate,
        studyDay: headerName,
        weekDay,
      },
      cellRenderer: (params) => getValueOrGreyedBGColoredCell(params, session),
    });
  });

  _.map(alphabeticalGroups, (group) => {
    let newRow = {
      resizable: false,
      group: group.group_name_to_show,
      cellClass: ['padding-lr-0'],
    };

    _.sortBy(group.animals, 'subject_id').forEach((animal, index) => {
      if (index !== 0) {
        newRow = {
          group: '',
        };
      }

      // keep below as we will likely re-include post-MVP
      // newRow.fateDay = fateDay(animal)
      newRow.stagingDate = stagingDate(animal)
      newRow.mortalityDate = mortalityDate(animal)
      newRow.fateCode = fateCode(animal);
      newRow.subjectType = animal.subject_type;
      newRow.subjectId = animal.subject_id;
      newRow.tempId = animal.temp_id;
      animal.sessions.forEach((session) => {
        newRow[formatStudyDay(session.study_day, session.study_time)] = scientificFormat ? convertToScientificFormat(_.round(
          session.value,
          2,
        ).toFixed(2)) : _.round(
          session.value,
          2,
        ).toFixed(2);
      });
      rows.push(newRow);
    });
  });

  gridOptions.columnDefs = columnDefs;
  gridOptions.rowData = rows;

  return gridOptions;
};
export const getDataForLongFormat = (groups, scientificFormat = false) => {
  const fixedRows = {};
  const values = {};

  _.map(groups, (group) => {
    (group.animals || []).forEach((animal) => {
      (animal.sessions || []).forEach((session) => {
        const value = scientificFormat ? convertToScientificFormat(_.round(
          session.value,
          2,
        ).toFixed(2)) : _.round(
          session.value,
          2,
        ).toFixed(2);

        const date = moment(session.study_date, 'MM/DD/YYYY');
        const formattedDate = date.format('DD MMM YYYY');
        const formatDayDateAndTime = formatStudyDayWithDateAndTime(session.study_day, formattedDate, session.study_time)
        const key = `${group.group_id} ${animal.subject_id} ${formatDayDateAndTime}`
        fixedRows[key] = [group.group_name_to_show, animal.subject_id, formattedDate, session.study_day]
        values[key] = value
      });
    });
  });
  return { fixedRows, values };
};
export const getTvcBwcCoMoDataForLongFormat = (groups, lable = '') => {

  const values = {}
  _.map(groups, (group) => {
    (group.animals || []).forEach((animal) => {
      (animal.sessions || []).forEach((session) => {
        const date = moment(session.study_date, 'MM/DD/YYYY');
        const formattedDate = date.format('DD MMM YYYY')
        const formatDayDateAndTime = formatStudyDayWithDateAndTime(session.study_day, formattedDate, session.study_time)
        const key = `${group.group_id} ${animal.subject_id} ${formatDayDateAndTime}`
        values[key] = lable === 'BWC' || lable === 'TVC' ? `${_.multiply(session.value, 100).toFixed(2)}%` : session.value
      })
    })
  })

  return values
}
export const getDoDataForLongFormat = (groups) => {

  const values = {}
  const articles = {}
  _.map(groups, (group) => {
    (group.animals || []).forEach((animal) => {
      (animal.sessions || []).forEach((session) => {
        const date = moment(session.study_date, 'MM/DD/YYYY');
        const formattedDate = date.format('DD MMM YYYY')
        const formatDayDateAndTime = formatStudyDayWithDateAndTime(session.study_day, formattedDate, session.study_time)
        const key = `${group.group_id} ${animal.subject_id} ${formatDayDateAndTime}`
        articles[group.article] = 1;
        if (!values[key]) {
          values[key] = {}
        }
        values[key][group.article] = `Group: ${group.group_name} \nAnimal ID: ${animal.subject_id} \nArticle: ${group.article} \n${session.value}`;
      })
    })
  })

  return { values, articles }
}
export const formatMortalityObservationIndividualGridOptions = (groups, setGridApi, legends) => {
  const gridOptions = { ...DEFAULT_GRIDOPTS }
  gridOptions.headerHeight = 100
  gridOptions.rowHeight = 50

  if (setGridApi) {
    gridOptions.onGridReady = (event) => setGridApi(event.api);
  }

  const rows = [];
  const columnDefs = [
    {
      headerName: 'Group',
      field: 'group',
      minWidth: 120,
      maxWidth: 270,
      resizable: false,
      headerClass: ['inline-block', 'padding-l-24'/* , 'margin-top-30', 'margin-left-20' */],
      // tooltipField: 'group',
      pinned: 'left',
      cellClass: ['padding-l2', 'padding-r0', 'text-align-center'],
      cellRenderer: (params) => (
        <GenericColumnCellRenderer params={params} />
      ),
    },
    {
      headerName: 'Animal ID',
      field: 'subjectId',
      minWidth: 50,
      maxWidth: 60,
      resizable: false,
      headerComponentParams: {
        template: ROTATED_HEADER_TEMPLATE,
      },
      headerClass: ['inline-block', 'padding-0'],
      cellClass: ['padding-lr-0', 'text-align-center'],
      pinned: 'left',
    },
    {
      headerName: 'Temp ID',
      field: 'tempId',
      minWidth: 50,
      maxWidth: 60,
      resizable: false,
      headerComponentParams: {
        template: ROTATED_HEADER_TEMPLATE,
      },
      headerClass: ['inline-block', 'padding-0'],
      cellClass: ['padding-lr-0', 'text-align-center'],
      pinned: 'left',
    },
    {
      headerName: 'Sex',
      field: 'subjectType',
      minWidth: 40,
      maxWidth: 50,
      resizable: false,
      headerComponentParams: {
        template: ROTATED_HEADER_TEMPLATE,
      },
      headerClass: ['inline-block', 'padding-0'],
      cellClass: ['padding-lr-0', 'text-align-center'],
      pinned: 'left',
    },
  ];

  // filtering groups without empty animals array, however for MO Table the AG Grid does automatically not show such
  const filteredGroups = _.filter(groups, (o) => o.animals.length > 0);

  const alphabeticalGroups = _.sortBy(filteredGroups, 'group_name');

  const uniqueStudyDays = _.uniqBy(
    _(alphabeticalGroups)
      .flatMap('animals')
      .flatMap('sessions')
      .map((session) => session)
      .value(),
    'study_day',
  );

  // Sort study day headers in chronological order by study_day
  const sortedUniqueStudyDays = _.sortBy(uniqueStudyDays, 'study_day');

  sortedUniqueStudyDays.forEach((session) => {
    const headerName = `Day ${session.study_day}`;
    const date = moment(session.study_date, 'MM/DD/YYYY');
    const formattedDate = date.format('DD MMM YYYY');
    const weekDay = date.format('dddd');
    columnDefs.push({
      field: formatStudyDay(session.study_day, session.study_time),
      minWidth: 100,
      headerClass: ['inline-block', 'padding-0'/* , "margin-top-30" */],
      cellClass: [
        'text-align-center',
        'justify-content-center',
        'padding-lr-0',
        'text-align-center',
        // session.value === SelectedFateCode() && 'text-red'
      ],
      cellRenderer: (params) => {
        const { isMortalityDatePassed } = getGreydBGColorEmptyDiv(params, session);
        if (isMortalityDatePassed) {
          params.eGridCell.classList.add('bgLightgray')
          return '';
        }
        return (<FateCodeCellRenderer params={params} legends={legends} />)
      },
      headerComponent: 'dateStudyDayDayOfTheWeekHeader',
      headerComponentParams: {
        studyDate: formattedDate,
        studyDay: headerName,
        weekDay,
      },
    });
  });

  _.map(alphabeticalGroups, (group) => {
    let newRow = {
      resizable: false,
      group: group.group_name_to_show,
      cellClass: ['padding-lr-0'],
    };

    _.sortBy(group.animals, 'subject_id').forEach((animal, index) => {
      if (index !== 0) {
        newRow = {
          group: '',
        };
      }

      newRow.subjectType = animal.subject_type;
      newRow.subjectId = animal.subject_id;
      newRow.tempId = animal.temp_id;
      newRow.mortalityDate = animal.mortality_date
      animal.sessions.forEach((session) => {
        newRow[formatStudyDay(session.study_day, session.study_time)] = session.value;
      });
      rows.push(newRow);
    });
  });

  gridOptions.columnDefs = columnDefs;
  gridOptions.rowData = rows;

  return gridOptions;
};

export const formatDosingRecordIndividualGridOptions = (groups, setGridApi, excludeDosingTimes) => {
  const gridOptions = { ...DEFAULT_GRIDOPTS }
  gridOptions.headerHeight = 100
  gridOptions.rowHeight = 50

  if (setGridApi) {
    gridOptions.onGridReady = (event) => setGridApi(event.api);
  }
  const rows = [];
  const columnDefs = [
    {
      headerName: 'Group',
      field: 'group',
      minWidth: 120,
      maxWidth: 270,
      resizable: false,
      headerClass: ['inline-block', 'padding-l-24'/* , 'margin-top-30', 'margin-left-20' */],
      // tooltipField: 'group',
      pinned: 'left',
      cellClass: ['padding-l2', 'padding-r0', 'text-align-center'],
      cellRenderer: (params) => (
        <GenericColumnCellRenderer params={params} />
      ),
    },
    {
      headerName: 'Article',
      field: 'article',
      minWidth: 80,
      maxWidth: 100,
      resizable: false,
      headerClass: ['inline-block', 'padding-l3', 'padding-0'],
      // tooltipField: 'article',
      pinned: 'left',
      cellClass: ['padding-lr-0', 'text-align-center'],
      cellRenderer: (params) => (
        <GenericColumnCellRenderer params={params} />
      ),
    },
    {
      headerName: 'Dose Volume',
      field: 'doseVolume',
      // tooltipField: "doseVolume",
      minWidth: 40,
      maxWidth: 80,
      resizable: false,
      headerComponentParams: {
        template: ROTATED_HEADER_TEMPLATE_WORD_WRAP,
      },
      // headerClass: ["inline-block", 'padding-tp-24'],
      cellClass: ['padding-l4', 'padding-r0', 'text-align-center'],
      pinned: 'left',
      cellRenderer: (params) => (
        <GenericColumnCellRenderer params={params} />
      ),
    },
    {
      headerName: 'Animal ID',
      field: 'subjectId',
      minWidth: 50,
      maxWidth: 60,
      resizable: false,
      headerComponentParams: {
        template: ROTATED_HEADER_TEMPLATE,
      },
      headerClass: ['inline-block', 'padding-0', 'padding-tp-24'],
      cellClass: ['padding-lr-0', 'text-align-center'],
      pinned: 'left',
    },
    {
      headerName: 'Temp ID',
      field: 'tempId',
      minWidth: 50,
      maxWidth: 60,
      resizable: false,
      headerComponentParams: {
        template: ROTATED_HEADER_TEMPLATE,
      },
      headerClass: ['inline-block', 'padding-0', 'padding-tp-24'],
      cellClass: ['padding-lr-0', 'text-align-center'],
      pinned: 'left',
    },
    {
      headerName: 'Sex',
      field: 'subjectType',
      minWidth: 40,
      maxWidth: 50,
      resizable: false,
      headerComponentParams: {
        template: ROTATED_HEADER_TEMPLATE,
      },
      headerClass: ['inline-block', 'padding-0', 'padding-tp-24'],
      cellClass: ['padding-lr-0', 'text-align-center'],
      pinned: 'left',
    },
    {
      headerName: 'Fate Code',
      field: 'fateCode',
      minWidth: 40,
      maxWidth: 50,
      resizable: false,
      headerComponentParams: {
        template: ROTATED_HEADER_TEMPLATE,
      },
      headerClass: ['inline-block', 'padding-0', 'padding-tp-24'],
      cellClass: ['padding-lr-0', 'text-align-center'],
      pinned: 'left',
    },
    // keep below as we will likely re-include post-MVP
    // {
    //   headerName: "Fate Day",
    //   field: "fateDay",
    //   minWidth: 40,
    //   maxWidth: 50,
    //   resizable: false,
    //   headerComponentParams: {
    //     template: ROTATED_HEADER_TEMPLATE,
    //   },
    //   headerClass: ["inline-block", "padding-0",'padding-tp-24'],
    //   cellClass: ["padding-lr-0", "text-align-center"],
    //   pinned: "left",
    // },
    {
      headerName: 'Staging Date',
      field: 'stagingDate',
      minWidth: 96,
      maxWidth: 116,
      resizable: false,
      headerComponentParams: {
        template: ROTATED_HEADER_TEMPLATE_WORD_WRAP,
      },
      // headerClass: ["inline-block", "padding-0"],
      cellClass: ['padding-lr-0', 'text-align-center'],
      pinned: 'left',
    },
    {
      headerName: 'Mortality Date',
      field: 'mortalityDate',
      minWidth: 96,
      maxWidth: 116,
      resizable: false,
      headerComponentParams: {
        template: ROTATED_HEADER_TEMPLATE_WORD_WRAP,
      },
      // headerClass: ["inline-block", "padding-0"],
      cellClass: ['padding-lr-0', 'text-align-center'],
      pinned: 'left',
    },
  ];

  const alphabeticalGroups = _.sortBy(groups, 'group_name');

  const uniqueStudyDays = _.uniqWith(
    _(alphabeticalGroups)
      .flatMap('animals')
      .flatMap('sessions')
      .map((session) => session)
      .value(),
    (studyA, studyB) => studyA.study_day === studyB.study_day
      && studyA.study_time === studyB.study_time,
  );

  // Sort study day headers in chronological order by study_day and time
  const sortedUniqueStudyDays = _.orderBy(
    uniqueStudyDays,
    [
      (study) => study.study_day,
      (study) => {
        const epoch_time = new Date(
          `${study.study_date} ${study.study_time}`,
        ).getTime();
        return epoch_time;
      },
    ],
    ['asc', 'asc'],
  );

  sortedUniqueStudyDays.forEach((session) => {
    const headerName = `Day ${session.study_day}`;
    const date = moment(session.study_date, 'MM/DD/YYYY');
    const formattedDate = date.format('DD MMM YYYY');
    const weekDay = date.format('dddd');
    const time = session.study_time;
    columnDefs.push({
      field: formatStudyDay(session.study_day, time),
      minWidth: 100,
      headerClass: ['inline-block', 'padding-0'/* , "margin-top-30" */],
      // tooltipField: formatStudyDay(session.study_day),
      cellClass: [
        'text-align-center',
        'justify-content-center',
        'padding-lr-0',
        'text-align-center',
      ],
      cellRenderer: (params) => {
        const { isMortalityDatePassed } = getGreydBGColorEmptyDiv(params, session);
        if (isMortalityDatePassed) {
          params.eGridCell.classList.add('bgLightgray')
          return '';
        }
        return (<DosingRecordCellRenderer params={params} />)
      },
      headerComponent: excludeDosingTimes ? 'dateStudyDayDayOfTheWeekTimeHeaderWithOutTime' : 'dateStudyDayDayOfTheWeekTimeHeaderWithTime',
      headerComponentParams: {
        studyDate: formattedDate,
        studyDay: headerName,
        weekDay,
        time,
      },
    });
  });

  _.map(alphabeticalGroups, (group) => {
    let newRow = {
      resizable: false,
      group: group.group_name_to_show,
      cellClass: ['padding-lr-0'],
    };

    _.sortBy(group.animals, 'subject_id').forEach((animal, index) => {
      if (index !== 0) {
        newRow = {
          group: '',
        };
      }

      // keep below as we will likely re-include post-MVP
      // newRow.fateDay = fateDay(animal)
      newRow.stagingDate = stagingDate(animal)
      newRow.mortalityDate = mortalityDate(animal)
      newRow.fateCode = fateCode(animal);
      newRow.subjectType = animal.subject_type;
      newRow.subjectId = animal.subject_id;
      newRow.tempId = animal.temp_id;
      newRow.article = group.article;
      newRow.doseVolume = group.dose_volume;
      animal.sessions.forEach((session) => {
        // ? May still need the below at some point
        // newRow[formatStudyDay(session.study_day)] = _.round(
        //   session.value,
        //   2,
        // ).toFixed(2)
        newRow[
          formatStudyDay(session.study_day, session.study_time)
        ] = `Group: ${group.group_name} \nAnimal ID: ${animal.subject_id} \nArticle: ${group.article} \n${session.value}`;
      });
      rows.push(newRow);
    });
  });

  gridOptions.columnDefs = columnDefs;
  gridOptions.rowData = rows;

  return gridOptions;
};

export const formatModelCharacteristicsOptions = () => [
  {
    headerName: 'Model ID',
    field: 'model-id',
  },
  {
    headerName: 'Cancer Type',
    field: 'cancer-type',
  },
  {
    headerName: 'Subtype',
    field: 'subtype',
  },
  {
    headerName: 'Origin',
    field: 'origin',
    styles: { color: '#056a7b' },
  },
  {
    headerName: 'TMA',
    field: 'tma',
    styles: { color: '#056a7b' },
  },
  {
    headerName: 'Special Feature',
    field: 'special-feature',
    styles: { color: '#cc2327' },
  },
].map((model) => ({
  ...model,
  content: 'N/A',
}));

export const formatModelMoreCharacteristicsOptions = () => [
  {
    headerName: 'Gender',
    field: 'gender',
  },
  {
    headerName: 'Age',
    field: 'age',
  },
  {
    headerName: 'Stage',
    field: 'stage',
  },
  {
    headerName: 'Grade',
    field: 'grade',
  },
  {
    headerName: 'Pathology Diagnosis',
    field: 'pathology-diagnosis',
  },
  {
    headerName: 'Biopsy Site',
    field: 'biopsy-site',
  },
  {
    headerName: 'Treatment History',
    field: 'treatment-history',
  },
  {
    headerName: 'Pathology QC',
    field: 'pathology-qc',
  },
  {
    headerName: 'HuPrime2.0',
    field: 'huprime2.0',
  },
  {
    headerName: 'HuPrime 2.0 Comment',
    field: 'hp',
  },
  {
    headerName: 'Affy U219 Data',
    field: 'afd',
  },
  {
    headerName: 'Affy SNP6.0 Data',
    field: 'afdt',
  },
  {
    headerName: 'RNA Seq',
    field: 'rna',
  },
  {
    headerName: 'Exome Seq',
    field: 'es',
  },
  {
    headerName: 'WGS',
    field: 'wsg',
  },
  {
    headerName: 'PrimePanel Cell Line',
    field: 'pcl',
  },
  {
    headerName: 'Mouse Strain',
    field: 'ms',
  },
  {
    headerName: 'Available In',
    field: 'ai',
  },
  {
    headerName: 'Viral Status in PDX',
    field: 'vsp',
  },
  {
    headerName: 'HLA',
    field: 'hla',
  },
  {
    headerName: 'Genomic Feature',
    field: 'gf',
  },
].map((mod) => ({
  ...mod,
  content: 'N/A',
}));

export const formatTaskMeasurementIndividualGridOptionsForSessionSummaryTable = (groups, header = '', subHeader = '', lable = '', scientificFormat = false) => {

  const { alphabeticalGroups, sortedUniqueStudyDays } = getSortedStudyDays(groups)
  const taskMeasurementStudyDayColumns = {}
  const sessionDaysTaskMeasurement = []
  sortedUniqueStudyDays.forEach((session) => {
    const date = moment(session.study_date, 'MM/DD/YYYY');
    const formattedDate = date.format('DD MMM YYYY');
    sessionDaysTaskMeasurement.push({
      day: session.study_day, time: session.study_time, date: formattedDate,
    })
    taskMeasurementStudyDayColumns[formatStudyDayWithDate(session.study_day, formattedDate, session.study_time)] = {
      field: formatStudyDayWithDate(session.study_day, formattedDate, session.study_time, lable),
      minWidth: 150,
      headerClass: ['padding-0', 'padding-t-b-4'],
      cellClass: [
        'text-align-center',
        'justify-content-center',
        'padding-lr-0',
        'text-align-center',
        'padding-b-10',
      ],
      cellRenderer: (params) => getValueOrGreyedBGColoredCell(params, session),
      headerComponent: 'studyDayHeader',
      headerComponentParams: {
        header,
        subHeader,
      },
    };
  });

  const taskMeasurementRows = {}
  _.map(alphabeticalGroups, (group) => {
    taskMeasurementRows[group.group_id] = {}
    _.sortBy(group.animals, 'subject_id').forEach((animal) => {
      taskMeasurementRows[group.group_id][animal.subject_id] = {}
      animal.sessions.forEach((session) => {
        const date = moment(session.study_date, 'MM/DD/YYYY');
        const formattedDate = date.format('DD MMM YYYY')
        taskMeasurementRows[group.group_id][animal.subject_id][formatStudyDayWithDate(session.study_day, formattedDate, session.study_time)] = scientificFormat ? convertToScientificFormat(_.round(
          session.value,
          2,
        ).toFixed(2)) : _.round(
          session.value,
          2,
        ).toFixed(2);
      });
    });
  });
  return {
    taskMeasurementRows,
    taskMeasurementStudyDayColumns,
    sessionDaysTaskMeasurement,
  };
};

export const formatBodyWeightIndividualGridOptionsForSessionSummaryTable = (groups, setGridApi, scientificFormat = false) => {
  const gridOptions = { ...DEFAULT_GRIDOPTS }
  gridOptions.headerHeight = 100
  gridOptions.rowHeight = 50

  if (setGridApi) {
    gridOptions.onGridReady = (event) => setGridApi(event.api);
  }
  const rows = [];
  const columnDefs = [
    {
      headerName: 'Group',
      field: 'group',
      minWidth: 120,
      maxWidth: 270,
      resizable: false,
      headerClass: ['inline-block', 'padding-l-24'],
      pinned: 'left',
      cellClass: ['padding-l2', 'padding-r0', 'text-align-center'],
      cellRenderer: (params) => (
        <GenericColumnCellRenderer params={params} />
      ),
    },
    {
      headerName: 'Animal ID',
      field: 'subjectId',
      minWidth: 50,
      maxWidth: 60,
      resizable: false,
      headerComponentParams: {
        template: ROTATED_HEADER_TEMPLATE,
      },
      headerClass: ['inline-block', 'padding-0', 'padding-tp-24'],
      cellClass: ['padding-lr-0', 'text-align-center'],
      pinned: 'left',
    },
    {
      headerName: 'Temp ID',
      field: 'tempId',
      minWidth: 50,
      maxWidth: 60,
      resizable: false,
      headerComponentParams: {
        template: ROTATED_HEADER_TEMPLATE,
      },
      headerClass: ['inline-block', 'padding-0', 'padding-tp-24'],
      cellClass: ['padding-lr-0', 'text-align-center'],
      pinned: 'left',
    },
    {
      headerName: 'Sex',
      field: 'subjectType',
      minWidth: 40,
      maxWidth: 50,
      resizable: false,
      headerComponentParams: {
        template: ROTATED_HEADER_TEMPLATE,
      },
      headerClass: ['inline-block', 'padding-0', 'padding-tp-24'],
      cellClass: ['padding-lr-0', 'text-align-center'],
      pinned: 'left',
    },
    {
      headerName: 'Fate Code',
      field: 'fateCode',
      minWidth: 40,
      maxWidth: 50,
      resizable: false,
      headerComponentParams: {
        template: ROTATED_HEADER_TEMPLATE,
      },
      headerClass: ['inline-block', 'padding-0', 'padding-tp-24'],
      cellClass: ['padding-lr-0', 'text-align-center'],
      pinned: 'left',
    },
    {
      headerName: 'Staging Date',
      field: 'stagingDate',
      minWidth: 96,
      maxWidth: 116,
      resizable: false,
      headerComponentParams: {
        template: ROTATED_HEADER_TEMPLATE_WORD_WRAP,
      },
      cellClass: ['padding-lr-0', 'text-align-center'],
      pinned: 'left',
    },
    {
      headerName: 'Mortality Date',
      field: 'mortalityDate',
      minWidth: 96,
      maxWidth: 116,
      resizable: false,
      headerComponentParams: {
        template: ROTATED_HEADER_TEMPLATE_WORD_WRAP,
      },
      cellClass: ['padding-lr-0', 'text-align-center'],
      pinned: 'left',
    },
  ];

  const { alphabeticalGroups, sortedUniqueStudyDays } = getSortedStudyDays(groups)

  const bodyWeightStudyDayColumns = {}
  const sessionDaysBodyWeight = []
  sortedUniqueStudyDays.forEach((session) => {
    const date = moment(session.study_date, 'MM/DD/YYYY');
    const formattedDate = date.format('DD MMM YYYY');
    sessionDaysBodyWeight.push({
      day: session.study_day, time: session.study_time, date: formattedDate,
    })
    bodyWeightStudyDayColumns[formatStudyDayWithDate(session.study_day, formattedDate, session.study_time)] = {
      field: formatStudyDayWithDate(session.study_day, formattedDate, session.study_time, 'BW'),
      minWidth: 100,
      headerClass: ['padding-0', 'padding-t-b-4'],
      cellClass: [
        'text-align-center',
        'justify-content-center',
        'padding-lr-0',
        'text-align-center',
        'padding-b-10',
      ],
      cellRenderer: (params) => getValueOrGreyedBGColoredCell(params, session),
      headerComponent: 'studyDayHeader',
      headerComponentParams: {
        header: 'Body',
        subHeader: 'Weight',
      },
    };
  });
  const bodyWeightRows = {}
  _.map(alphabeticalGroups, (group) => {
    let newRow = {
      resizable: false,
      group: group.group_name_to_show,
      cellClass: ['padding-lr-0'],
    };
    bodyWeightRows[group.group_id] = {}
    _.sortBy(group.animals, 'subject_id').forEach((animal, index) => {
      if (index !== 0) {
        newRow = {
          group: '',
        };
      }

      newRow.stagingDate = stagingDate(animal)
      newRow.mortalityDate = mortalityDate(animal)
      newRow.fateCode = fateCode(animal);
      newRow.subjectType = animal.subject_type;
      newRow.subjectId = animal.subject_id;
      newRow.tempId = animal.temp_id;
      newRow.article = group.article;
      newRow.doseVolume = group.dose_volume;
      newRow.groupID = group.group_id
      newRow.NA = '-'
      bodyWeightRows[group.group_id][animal.subject_id] = {}
      animal.sessions.forEach((session) => {
        const date = moment(session.study_date, 'MM/DD/YYYY');
        const formattedDate = date.format('DD MMM YYYY')
        bodyWeightRows[group.group_id][animal.subject_id][formatStudyDayWithDate(session.study_day, formattedDate, session.study_time)] = scientificFormat ? convertToScientificFormat(_.round(
          session.value,
          2,
        ).toFixed(2)) : _.round(
          session.value,
          2,
        ).toFixed(2);
      });
      rows.push(newRow);
    });
  });
  gridOptions.columnDefs = columnDefs;
  gridOptions.rowData = rows;
  return {
    gridOptions,
    bodyWeightStudyDayColumns,
    bodyWeightRows,
    sessionDaysBodyWeight,
  };
};

export const getDataForSessionSummaryTable = (groups, legend, header = '', subHeader = '', lable = '') => {

  const { alphabeticalGroups, sortedUniqueStudyDays } = getSortedStudyDays(groups)

  const columns = {}
  const sessionDays = []
  sortedUniqueStudyDays.forEach((session) => {
    const date = moment(session.study_date, 'MM/DD/YYYY')
    const formattedDate = date.format('DD MMM YYYY')
    sessionDays.push({
      day: session.study_day, time: session.study_time, date: formattedDate,
    })
    columns[formatStudyDayWithDate(session.study_day, formattedDate, session.study_time)] = {
      field: formatStudyDayWithDate(session.study_day, formattedDate, session.study_time, lable),
      minWidth: lable === 'CO' ? 175 : 120,
      autoHeight: true,
      headerClass: ['padding-0'],
      cellStyle: lable === 'CO' ? { 'white-space': 'normal' } : {},
      cellClass: [
        lable === 'CO' ? 'l-height-10-2' : 'display-flex',
        lable === 'CO' ? 'text-align-left' : 'text-align-center',
        'justify-content-center',
        'padding-lr-1',
      ],
      cellRenderer: (params) => {
        if (lable === 'BWC' || lable === 'TVC') {
          return getValueOrGreyedBGColoredCell(params, session)
        }
        const { isMortalityDatePassed } = getGreydBGColorEmptyDiv(params, session);
        if (isMortalityDatePassed) {
          params.eGridCell.classList.add('bgLightgray')
          return ''
        }
        if (lable === 'CO') {
          return (<ClinicalObservationCodesCellRenderer value={params.value} legend={legend} />)
        }
        if (lable === 'MO') {
          return (<FateCodeCellRenderer params={params} legends={legend} />)
        }
        return '';
      },
      headerComponent: 'studyDayHeader',
      headerComponentParams: {
        header,
        subHeader,
      },
    }
  })

  const rows = {}
  _.map(alphabeticalGroups, (group) => {
    rows[group.group_id] = {}
    _.sortBy(group.animals, 'subject_id').forEach((animal) => {
      rows[group.group_id][animal.subject_id] = {}
      animal.sessions.forEach((session) => {
        const date = moment(session.study_date, 'MM/DD/YYYY');
        const formattedDate = date.format('DD MMM YYYY')
        rows[group.group_id][animal.subject_id][formatStudyDayWithDate(session.study_day, formattedDate, session.study_time)] = lable === 'BWC' || lable === 'TVC' ? `${_.multiply(session.value, 100).toFixed(2)}%` : session.value
      })
    })
  })

  return {
    columns,
    rows,
    sessionDays,
  }
}

export const formatDosingRecordIndividualGridOptionsForSessionSummaryTable = (groups, excludeDosingTimes) => {

  const alphabeticalGroups = _.sortBy(groups, 'group_name');

  const uniqueStudyDays = _.uniqWith(
    _(alphabeticalGroups)
      .flatMap('animals')
      .flatMap('sessions')
      .map((session) => session)
      .value(),
    (studyA, studyB) => studyA.study_day === studyB.study_day
      && studyA.study_time === studyB.study_time,
  );

  // Sort study day headers in chronological order by study_day and time
  const sortedUniqueStudyDays = _.orderBy(
    uniqueStudyDays,
    [
      (study) => study.study_day,
      (study) => {
        const epoch_time = new Date(
          `${study.study_date} ${study.study_time}`,
        ).getTime();
        return epoch_time;
      },
    ],
    ['asc', 'asc'],
  );
  const dosingStudyDayColumns = {}
  const sessionDaysDosingRecords = []
  sortedUniqueStudyDays.forEach((session) => {
    const date = moment(session.study_date, 'MM/DD/YYYY');
    const formattedDate = date.format('DD MMM YYYY');
    const time = session.study_time;
    sessionDaysDosingRecords.push({
      day: session.study_day, time, date: formattedDate,
    })
  });
  const dosingRecordRows = {}
  const allArticles = {}
  _.map(alphabeticalGroups, (group) => {
    dosingRecordRows[`${group.group_id} ${group.article}`] = {}
    _.sortBy(group.animals, 'subject_id').forEach((animal) => {
      dosingRecordRows[`${group.group_id} ${group.article}`][animal.subject_id] = {}
      animal.sessions.forEach((session) => {
        const date = moment(session.study_date, 'MM/DD/YYYY');
        const formattedDate = date.format('DD MMM YYYY')
        dosingRecordRows[`${group.group_id} ${group.article}`][animal.subject_id][
          formatStudyDayWithDate(session.study_day, formattedDate, session.study_time)
        ] = `Group: ${group.group_name} \nAnimal ID: ${animal.subject_id} \nArticle: ${group.article} \n${session.value}`;
        const keyWithOutArticle = formatStudyDayWithDate(session.study_day, formattedDate, session.study_time)
        initializeDosingStudyDayColumns(dosingStudyDayColumns, group.group_id)
        initializeDosingStudyDayColumns(dosingStudyDayColumns[group.group_id], group.article)
        if (!isKeyExist(dosingStudyDayColumns[group.group_id][group.article], keyWithOutArticle)) {
          dosingStudyDayColumns[group.group_id][group.article][keyWithOutArticle] = {
            field: group.article,
            minWidth: 150,
            headerClass: ['padding-0'],
            cellClass: [
              'text-align-center',
              'justify-content-center',
              'padding-lr-0',
              'text-align-center',
            ],
            cellRenderer: (params) => <DosingRecordCellRenderer params={params} />,
            headerComponent: excludeDosingTimes ? 'dosingRecordWithOutTime' : 'dosingRecordWithTime',
            headerComponentParams: {
              header: 'Dosing Records',
              subHeader: group.article,
              time: session.study_time,
            },
          };
          allArticles[group.article] = 1;
        }
      });
    });
  });

  return {
    sessionDaysDosingRecords,
    dosingStudyDayColumns,
    dosingRecordRows,
    allArticles,
  };
};
const initializeDosingStudyDayColumns = (dosingStudyDayColumns, key) => {
  if (!isKeyExist(dosingStudyDayColumns, key)) {
    dosingStudyDayColumns[key] = {};
  }
};
export const isKeyExist = (obj, key) => !!obj[key]
export const isExist = (obj) => !!obj
const processDataRow = (row, studyDay, columnPrefix, rowsData, columnData) => {
  const newRow = { ...row };
  if (rowsData?.[row.groupID]?.[row.subjectId]?.[studyDay] && columnData) {
    newRow[`${studyDay} ${columnPrefix}`] = rowsData[row.groupID][row.subjectId][studyDay];
  }
  return newRow;
};
const processClinicalObservation = (row, studyDay, clinicalObservationRows) => {
  const newRow = { ...row };
  const clinicalObservationLegends = []
  if (clinicalObservationRows?.[row.groupID]?.[row.subjectId]?.[studyDay]) {
    newRow[`${studyDay} CO`] = clinicalObservationRows[row.groupID][row.subjectId][studyDay];
    clinicalObservationLegends.push(...(clinicalObservationRows[row.groupID][row.subjectId][studyDay].split(', ') || []));
  }
  return {
    coRow: newRow,
    coLegends: clinicalObservationLegends,
  };
};
const processDosingData = (row, index, studyDay, article, rowsData) => {
  const newRow = { ...row };
  if (rowsData?.[`${row.groupID} ${article}`]?.[row.subjectId]?.[studyDay]) {
    newRow[`${index} DR`] = rowsData[`${row.groupID} ${article}`][row.subjectId][studyDay]
  } else {
    newRow[`${index} DR`] = ''
  }
  return newRow;
};
export const getRowsAndColumn = ({
  studyDay,

  bliVolumeRows,
  bliVolumeStudyDayColumns,

  bliMeanRows,
  bliMeanStudyDayColumns,

  bliVentralRows,
  bliVentralStudyDayColumns,

  bliDorsalRows,
  bliDorsalStudyDayColumns,

  tumorVolumeRows,
  tumorVolumeStudyDayColumns,

  tumorVolumeChangeRows,
  tumorVolumeChangeStudyDayColumns,

  tumorVolumeRightRows,
  tumorVolumeRightStudyDayColumns,

  tumorVolumeLeftRows,
  tumorVolumeLeftStudyDayColumns,

  bodyWeightRows,
  bodyWeightStudyDayColumns,

  bodyWeightChangeRows,
  bodyWeightChangeStudyDayColumns,

  clinicalObservationRows,
  clinicalObservationStudyDayColumns,

  mortalityObservationRows,
  mortalityObservationStudyDayColumns,

  dosingRecordRows,
  dosingStudyDayColumns,

  fixedRowData,
  allArticles,
}) => {
  const bliVolumeColumn = bliVolumeStudyDayColumns[studyDay]
  const bliMeanColumn = bliMeanStudyDayColumns[studyDay]
  const bliVentralColumn = bliVentralStudyDayColumns[studyDay]
  const bliDorsalColumn = bliDorsalStudyDayColumns[studyDay]
  const tumorVolumeColumn = tumorVolumeStudyDayColumns[studyDay]
  const tumorVolumeChangeColumn = tumorVolumeChangeStudyDayColumns[studyDay]
  const tumorVolumeRightColumn = tumorVolumeRightStudyDayColumns[studyDay]
  const tumorVolumeLeftColumn = tumorVolumeLeftStudyDayColumns[studyDay]
  const bodyWeightColumn = bodyWeightStudyDayColumns[studyDay]
  const bodyWeightChangeColumn = bodyWeightChangeStudyDayColumns[studyDay]
  const clinicalObservationColumn = clinicalObservationStudyDayColumns[studyDay]
  const mortalityObservationColumn = mortalityObservationStudyDayColumns[studyDay]
  const columns = [
    bliVolumeColumn,
    bliMeanColumn,
    bliVentralColumn,
    bliDorsalColumn,
    tumorVolumeColumn,
    tumorVolumeChangeColumn,
    tumorVolumeRightColumn,
    tumorVolumeLeftColumn,
    bodyWeightColumn || getDummyColumn('Body', 'Weight'),
    bodyWeightChangeColumn || getDummyColumn('Body Weight', 'Change'),
    clinicalObservationColumn || getDummyColumn('Clinical', 'Observation'),
  ].filter(Boolean)
  const dosingColumnsByArticle = {}
  const articles = Object.keys(allArticles || [])
  const newArticles = { ...allArticles }
  const clinicalObservationLegends = []
  const rows = fixedRowData.map((row) => {
    let newRow = { ...row }
    newRow = processDataRow(newRow, studyDay, 'BLIVO', bliVolumeRows, bliVolumeColumn);
    newRow = processDataRow(newRow, studyDay, 'BLIM', bliMeanRows, bliMeanColumn);
    newRow = processDataRow(newRow, studyDay, 'BLIV', bliVentralRows, bliVentralColumn);
    newRow = processDataRow(newRow, studyDay, 'BLID', bliDorsalRows, bliDorsalColumn);
    newRow = processDataRow(newRow, studyDay, 'TV', tumorVolumeRows, tumorVolumeColumn);
    newRow = processDataRow(newRow, studyDay, 'TVC', tumorVolumeChangeRows, tumorVolumeChangeColumn);
    newRow = processDataRow(newRow, studyDay, 'TVR', tumorVolumeRightRows, tumorVolumeRightColumn);
    newRow = processDataRow(newRow, studyDay, 'TVL', tumorVolumeLeftRows, tumorVolumeLeftColumn);
    newRow = processDataRow(newRow, studyDay, 'BW', bodyWeightRows, true);
    newRow = processDataRow(newRow, studyDay, 'BWC', bodyWeightChangeRows, true);
    const { coRow, coLegends } = processClinicalObservation(newRow, studyDay, clinicalObservationRows)
    newRow = coRow
    clinicalObservationLegends.push(...coLegends)
    newRow = processDataRow(newRow, studyDay, 'MO', mortalityObservationRows, true);
    const group = dosingStudyDayColumns[row.groupID]
    articles.forEach((article, index) => {
      if (group?.[article]?.[studyDay]) {
        dosingColumnsByArticle[article] = 1
        newArticles[article] = group?.[article]?.[studyDay]
      }
      newRow = processDosingData(newRow, index, studyDay, article, dosingRecordRows)
    })
    return newRow
  })
  articles.forEach((article, index) => {
    if (!dosingColumnsByArticle[article]) {
      newArticles[article] = getDummyColumn('Dosing Records', article)
    } else {
      newArticles[article].field = `${index} DR`
    }
  })
  columns.push(...Object.values(newArticles)) // pushed all dosing column with individual article
  columns.push(mortalityObservationColumn || getDummyColumn('Mortality', 'Observation'))
  return {
    rows,
    columns,
    clinicalObservationLegends,
  }
}
export const formatClinicalObservationIndividualGridOptions = (groups, setGridApi, legend) => {
  const gridOptions = { ...DEFAULT_GRIDOPTS }
  gridOptions.headerHeight = 100

  if (setGridApi) {
    gridOptions.onGridReady = (event) => setGridApi(event.api)
  }

  const rows = []
  const columnDefs = [
    {
      headerName: 'Group',
      field: 'group',
      minWidth: 120,
      maxWidth: 130,
      resizable: false,
      headerClass: ['inline-block', 'padding-l-24'],
      // tooltipField: 'group',
      pinned: 'left',
      cellClass: ['padding-l2', 'padding-r0', 'text-align-center', 'l-height-10-lf'],
      cellRenderer: (params) => (
        <GenericColumnCellRenderer params={params} />
      ),
    },
    {
      headerName: 'Animal ID',
      field: 'subjectId',
      minWidth: 50,
      maxWidth: 60,
      resizable: false,
      headerComponentParams: {
        template: ROTATED_HEADER_TEMPLATE,
      },
      headerClass: ['inline-block', 'padding-0'],
      cellClass: ['padding-lr-0', 'text-align-center', 'l-height-10'],
      pinned: 'left',
    },
    {
      headerName: 'Temp ID',
      field: 'tempId',
      minWidth: 50,
      maxWidth: 60,
      resizable: false,
      headerComponentParams: {
        template: ROTATED_HEADER_TEMPLATE,
      },
      headerClass: ['inline-block', 'padding-0'],
      cellClass: ['padding-lr-0', 'text-align-center', 'l-height-10'],
      pinned: 'left',
    },
    {
      headerName: 'Sex',
      field: 'subjectType',
      minWidth: 40,
      maxWidth: 50,
      resizable: false,
      headerComponentParams: {
        template: ROTATED_HEADER_TEMPLATE,
      },
      headerClass: ['inline-block', 'padding-0'],
      cellClass: ['padding-lr-0', 'text-align-center', 'l-height-10'],
      pinned: 'left',
    },
  ]

  const alphabeticalGroups = _.sortBy(groups, 'group_name')

  const uniqueStudyDays = _.uniqBy(
    _(alphabeticalGroups)
      .flatMap('animals')
      .flatMap('sessions')
      .map((session) => session)
      .value(),
    'study_day',
  )

  // Sort study day headers in chronological order by study_day
  const sortedUniqueStudyDays = _.sortBy(uniqueStudyDays, 'study_day')

  sortedUniqueStudyDays.forEach((session) => {
    const headerName = `Day ${session.study_day}`
    const date = moment(session.study_date, 'MM/DD/YYYY')
    const formattedDate = date.format('DD MMM YYYY')
    const weekDay = date.format('dddd')
    columnDefs.push({
      field: formatStudyDay(session.study_day),
      minWidth: 175,
      // need the below maxWidth set if using the hyphens, display, wordBreak and minWidth properties on the cellRenderer
      maxWidth: 175,
      // need the below autoHeight and textWrap for multiple co codes to show
      autoHeight: true,
      wrapText: true,
      headerClass: ['inline-block', 'padding-0'],
      cellStyle: { 'white-space': 'normal' },
      cellClass: [
        'l-height-10-2',
        'text-align-left',
        'justify-content-center',
        'padding-lr-1',
        // session.value === SelectedFateCode() && 'text-red'
      ],
      cellRenderer: (params) => {

        const { isMortalityDatePassed } = getGreydBGColorEmptyDiv(params, session);
        if (isMortalityDatePassed) {
          params.eGridCell.classList.add('bgLightgray')
          return ''
        }
        return (<ClinicalObservationCodesCellRenderer value={params.value} legend={legend} />)
      },
      headerComponent: 'dateStudyDayDayOfTheWeekHeader',
      headerComponentParams: {
        studyDate: formattedDate,
        studyDay: headerName,
        weekDay,
      },
    })
  })

  _.map(alphabeticalGroups, (group) => {
    let newRow = {
      resizable: false,
      group: group.group_name_to_show,
      cellClass: ['padding-lr-0'],
    }

    _.sortBy(group.animals, 'subject_id').forEach((animal, index) => {
      if (index !== 0) {
        newRow = {
          group: '',
        }
      }

      newRow.subjectType = animal.subject_type
      newRow.subjectId = animal.subject_id
      newRow.tempId = animal.temp_id
      newRow.mortalityDate = animal.mortality_date
      animal.sessions.forEach((session) => {
        newRow[formatStudyDay(session.study_day)] = session.value
      })
      rows.push(newRow)
    })
  })

  gridOptions.columnDefs = columnDefs
  gridOptions.rowData = rows
  gridOptions.rowHeight = 40

  return gridOptions
}

export const getFirstOrLastSessionDay = (allGroups, lookingForFirstDay = true) => {
  const uniqueDatesAccrossTheGroups = [
    ...new Set(allGroups
      .map((grp) => grp.animals)
      .flat()
      .map((anm) => anm.sessions.map((sess) => sess.study_day))
      .flat()) ?? [],
  ].sort((a, b) => parseInt(a) - parseInt(b))

  const dayIndex = lookingForFirstDay ? 0 : uniqueDatesAccrossTheGroups.length - 1;

  return uniqueDatesAccrossTheGroups[dayIndex] ?? -1
}

/**
 * Calculates animal survival percentage based on parameters
 * @param {number} animalsDeadSoFar - The number of animals dead within a group so far
 * @param {number} totalAnimalsWithinAGroup - The number of total animals assigned to a group
 */
const calculateAnimalSurvivalPercentage = (animalsDeadSoFar, totalAnimalsWithinAGroup) => Math.ceil((animalsDeadSoFar / totalAnimalsWithinAGroup) * 100)

/**
 * Return hover tooltip
 * @param {string} groupName - The name of the group
 * @param {number} deadAnimalCountForGroup - The number of animals dead within a group so far
 * @param {number} totalAnimalsAssignedToThisgroup - The number of total animals assigned to a group
 */
const getHoverTemplateForSurvivalAnalysis = (groupName, deadAnimalCountForGroup, totalAnimalsAssignedToThisgroup) => (`<b>Group Name</b>: ${groupName}<br>`
  + `<b>Number</b>: ${deadAnimalCountForGroup} out of ${totalAnimalsAssignedToThisgroup} (${calculateAnimalSurvivalPercentage(deadAnimalCountForGroup, totalAnimalsAssignedToThisgroup)}%)<br>`)

/**
 * Formats the data required by plotlyjs to plot the chart
 * @param {Array} groups - The array of objects containing information about the group
 */
export const formatSurvivalAnalysisDataForChart = (mortalityGroups, allgroups, colors, startDay = 0) => {
  const alphabeticalGroups = _.sortBy(allgroups, 'group_name');

  const survivalAnalysisDataForChart = [];
  const graphMode = 'lines';

  alphabeticalGroups.forEach((grp, index) => {
    let group = mortalityGroups.find((mGrp) => mGrp.group_id === grp.group_id) || grp
    if (group.animals.every((x) => x.sessions.length <= 0)) {
      if (grp.animals.every((x) => x.sessions.length <= 0)) {
        return;
      }
      group = grp
    }

    const data = {
      x: [],
      y: [],
      name: '',
      type: 'scatter',
      text: [],
      hoverinfo: 'text',
      mode: graphMode,
      line: {
        color: (colors && colors.length && colors[index]) || 'red',
      },
    };
    const groupName = truncationOfLongStr(group.group_name_to_show);
    data.name = groupName;

    data.y.push(100)
    data.x.push(startDay ? +startDay : 0) // should be first selected date range

    let allSessionsDays = [];

    group.animals.forEach((animal) => {
      animal.sessions.forEach((session) => {
        if (!allSessionsDays.includes(session.study_day)) {
          allSessionsDays.push(session.study_day)
        }
      });
    });

    allSessionsDays = allSessionsDays.sort((a, b) => a - b);

    let deadAnimalCountForGroup = group.animals.length;
    const totalAnimalsAssignedToThisgroup = group.animals.length;
    data.text.push(getHoverTemplateForSurvivalAnalysis(groupName, deadAnimalCountForGroup, totalAnimalsAssignedToThisgroup));

    allSessionsDays.forEach((day) => {
      data.x.push(day);
      data.y.push(calculateAnimalSurvivalPercentage(deadAnimalCountForGroup, totalAnimalsAssignedToThisgroup));
      data.text.push(getHoverTemplateForSurvivalAnalysis(groupName, deadAnimalCountForGroup, totalAnimalsAssignedToThisgroup));
      group.animals.forEach((animal) => {
        if (animal.fate_day === day && animal.fate_date !== '' && !EXCLUDED_MO_FOR_SURVIVAL_ANALYSIS_CHART.includes(animal.fate_code)) {
          deadAnimalCountForGroup -= 1;
        }
      });
      data.x.push(day);
      data.y.push(calculateAnimalSurvivalPercentage(deadAnimalCountForGroup, totalAnimalsAssignedToThisgroup));
      data.text.push(getHoverTemplateForSurvivalAnalysis(groupName, deadAnimalCountForGroup, totalAnimalsAssignedToThisgroup));
    });
    data.text.push(getHoverTemplateForSurvivalAnalysis(groupName, deadAnimalCountForGroup, totalAnimalsAssignedToThisgroup));
    survivalAnalysisDataForChart.push(data);

  });

  return survivalAnalysisDataForChart;
};

export const formatBodyWeightChangeSummaryData = (groups, acronym, colors) => {
  const alphabeticalGroups = _.sortBy(groups, 'group_name');

  const tumorVolumeData = [];
  const graphMode = 'lines';

  alphabeticalGroups.forEach((group, index) => {
    const data = {
      x: [],
      y: [],
      name: '',
      type: 'scatter',
      text: [],
      hoverinfo: 'text',
      error_y: {
        type: 'data',
        array: [],
        visible: true,
      },
      mode: graphMode,
      line: {
        color: (colors && colors.length && colors[index]) || 'red',
      },
    };
    const groupName = truncationOfLongStr(group.group_name_to_show);
    data.name = groupName;
    group.study_day_metrics.forEach((study_day_metric) => {
      const x = study_day_metric.study_day;
      const y = study_day_metric.mean;
      const n = study_day_metric.count && study_day_metric.count;
      const standardError = study_day_metric.sem;
      const upperBar = _.multiply(y + standardError, 100).toFixed(2);
      const lowerBar = _.multiply(y - standardError, 100).toFixed(2);
      const intervalLength = _.round(upperBar - lowerBar, 2).toFixed(2);
      const hoverTemplateString = `<b>Study Day</b>: ${x}<br >`
        + `<b>Group</b>: ${groupName}<br>`
        + `<b>Number (n): ${n}<br >`
        + `<b>Mean ${acronym}</b>: ${_.multiply(y, 100).toFixed(2)}%<br>`
        + `<b>SEM Upper Bar</b>: ${upperBar}%<br>`
        + `<b>SEM Lower Bar</b>: ${lowerBar}%<br>`
        + `<b>SEM Interval Length</b>: ${intervalLength}%<br>`;

      data.text.push(hoverTemplateString);
      data.x.push(x);
      data.y.push(y);
      data.error_y.array.push(study_day_metric.sem);
    });
    tumorVolumeData.push(data);
  });

  return tumorVolumeData;
};

export const getDataForBarGraph = (groups, dataKey, getColors, isMaximumCutoffCheckboxChecked, maximumCutoffValue) => {
  let indexTrack = null
  let newGroups = groups.map((group, index) => {
    if (group.group) {
      indexTrack = index
    }
    const grp = {
      ...group,
      group: groups[indexTrack].group,
    }
    if (group[dataKey]) {
      const tvcValue = +(group[dataKey].replace('%', ''))
      grp[dataKey] = tvcValue
      if (isMaximumCutoffCheckboxChecked && maximumCutoffValue && maximumCutoffValue > 0) {
        grp[dataKey] = tvcValue > maximumCutoffValue ? maximumCutoffValue : tvcValue
      }
    }
    return grp
  })
  newGroups = _.orderBy(newGroups, ['group', dataKey], ['asc', 'desc']);
  const graphData = []
  newGroups.forEach((grp, index) => {
    if (index === 0 || (newGroups[index - 1] && grp.group !== newGroups[index - 1].group)) {
      graphData.push({
        type: 'bar',
        hoverinfo: 'text',
        x: [],
        y: [],
        text: [],
        name: grp.group,
        marker: {
          color: [],
        },
        hoverlabel: { font: { color: 'black' }, bgcolor: 'white' },
      })
    }
    const acronym = 'TVC'
    const colors = getColors(acronym, `${acronym}-summary`);
    if (grp[dataKey]) {
      graphData[graphData.length - 1].y.push(grp[dataKey])
      graphData[graphData.length - 1].x.push(index)
      graphData[graphData.length - 1].marker.color.push(colors[graphData.length - 1])
      graphData[graphData.length - 1].text.push(
        `<b>Group</b>: ${grp.group}<br>`
        + `<b>Animal ID: ${grp.subjectId}<br >`
        + `<b>TVC</b>: ${grp[dataKey]}%<br>`,
      )
    }
  })
  return graphData
}
export const formatBodyWeightChangeSummaryGridOptions = (
  groups,
  setGridApi,
) => {
  const gridOptions = { ...DEFAULT_GRIDOPTS };
  gridOptions.headerHeight = 60;

  if (setGridApi) {
    gridOptions.onGridReady = (event) => setGridApi(event.api);
  }

  const filteredGroups = _.filter(
    groups,
    (o) => o.study_day_metrics.length > 0,
  );

  const alphabeticalGroups = _.sortBy(filteredGroups, 'group_name');

  const longestAlphabeticalGroup = _.maxBy(filteredGroups, 'study_day_metrics.length')

  const columnDefs = [
    {
      minWidth: 70,
      maxWidth: 150,
      // tooltipField: "group",
      headerName: 'Group',
      field: 'group',
      pinned: 'left',
      cellRenderer: (params) => (
        <MeanBodyWeightSummaryColumnCellRenderer params={params} />
      ),
    },
  ];
  _.map(longestAlphabeticalGroup?.study_day_metrics, (group) => {
    const headerName = `Day ${group.study_day}`;

    const date = moment(group.study_date, 'MM/DD/YYYY');
    const formattedDate = date.format('DD MMM YYYY');
    const weekDay = date.format('dddd');
    columnDefs.push({
      minWidth: 150,
      headerName,
      headerComponent: 'dateStudyDayDayOfTheWeekHeader',
      headerComponentParams: {
        studyDate: formattedDate,
        studyDay: headerName,
        weekDay,
      },
      field: formatStudyDay(group.study_day),
      valueGetter,
      // headerClass: ["text-align-center", "justify-content-center"],
      // cellClass: ["text-align-center", "justify-content-center"],
      headerClass: 'center-grid-cell-data',
      cellClass: 'center-grid-cell-data',
      cellRenderer: (params) => summaryGridsCellRenderer(date, params),
    });
  });
  const rows = [];
  alphabeticalGroups.forEach((group) => {
    const newRow = {};
    newRow.group = group.group_name_to_show;
    newRow.animalsStatus = checkIfAllAnimalsAreDeadWithinGroup(group)
    group.study_day_metrics.forEach((studyDay) => {
      newRow[formatStudyDay(studyDay.study_day)] = {
        mean: `${_.multiply(studyDay.mean, 100).toFixed(2)}%`,
        sem: `${_.multiply(studyDay.sem, 100).toFixed(2)}%`,
      };
    });
    rows.push(newRow);
  });

  gridOptions.columnDefs = columnDefs;
  gridOptions.rowData = rows;
  return gridOptions;
};

export const formatBodyWeightChangeIndividualGridOptions = (
  groups,
  setGridApi,
) => {
  const gridOptions = { ...DEFAULT_GRIDOPTS };
  gridOptions.headerHeight = 100;

  if (setGridApi) {
    gridOptions.onGridReady = (event) => setGridApi(event.api)
    gridOptions.rowHeight = 50
  }
  const rows = [];
  const columnDefs = [
    {
      headerName: 'Group',
      field: 'group',
      minWidth: 120,
      maxWidth: 270,
      resizable: false,
      headerClass: ['inline-block', 'padding-l-24'/* , 'margin-top-30', 'margin-left-20' */],
      // tooltipField: 'group',
      pinned: 'left',
      cellClass: ['padding-l2', 'padding-r0', 'text-align-center'],
      cellRenderer: (params) => (
        <GenericColumnCellRenderer params={params} />
      ),
    },
    {
      headerName: 'Animal ID',
      field: 'subjectId',
      minWidth: 50,
      maxWidth: 60,
      resizable: false,
      headerComponentParams: {
        template: ROTATED_HEADER_TEMPLATE,
      },
      headerClass: ['inline-block', 'padding-0'],
      cellClass: ['padding-lr-0', 'text-align-center'],
      pinned: 'left',
    },
    {
      headerName: 'Temp ID',
      field: 'tempId',
      minWidth: 50,
      maxWidth: 60,
      resizable: false,
      headerComponentParams: {
        template: ROTATED_HEADER_TEMPLATE,
      },
      headerClass: ['inline-block', 'padding-0'],
      cellClass: ['padding-lr-0', 'text-align-center'],
      pinned: 'left',
    },
    {
      headerName: 'Sex',
      field: 'subjectType',
      minWidth: 40,
      maxWidth: 50,
      resizable: false,
      headerComponentParams: {
        template: ROTATED_HEADER_TEMPLATE,
      },
      headerClass: ['inline-block', 'padding-0'],
      cellClass: ['padding-lr-0', 'text-align-center'],
      pinned: 'left',
    },
    {
      headerName: 'Fate Code',
      field: 'fateCode',
      minWidth: 40,
      maxWidth: 50,
      resizable: false,
      headerComponentParams: {
        template: ROTATED_HEADER_TEMPLATE,
      },
      headerClass: ['inline-block', 'padding-0'],
      cellClass: ['padding-lr-0', 'text-align-center'],
      pinned: 'left',
    },
    // keep below as we will likely re-include post-MVP
    // {
    //   headerName: "Fate Day",
    //   field: "fateDay",
    //   minWidth: 40,
    //   maxWidth: 50,
    //   resizable: false,
    //   headerComponentParams: {
    //     template: ROTATED_HEADER_TEMPLATE,
    //   },
    //   headerClass: ["inline-block", "padding-0"],
    //   cellClass: ["padding-lr-0", "text-align-center"],
    //   pinned: "left",
    // },
    {
      headerName: 'Staging Date',
      field: 'stagingDate',
      minWidth: 96,
      maxWidth: 116,
      resizable: false,
      headerComponentParams: {
        template: ROTATED_HEADER_TEMPLATE_WORD_WRAP,
      },
      // headerClass: ["inline-block", "padding-0"],
      cellClass: ['padding-lr-0', 'text-align-center'],
      pinned: 'left',
    },
    {
      headerName: 'Mortality Date',
      field: 'mortalityDate',
      minWidth: 96,
      maxWidth: 116,
      resizable: false,
      headerComponentParams: {
        template: ROTATED_HEADER_TEMPLATE_WORD_WRAP,
      },
      // headerClass: ["inline-block", "padding-0"],
      cellClass: ['padding-lr-0', 'text-align-center'],
      pinned: 'left',
    },
  ];

  const alphabeticalGroups = _.sortBy(groups, 'group_name');

  const uniqueStudyDays = _.uniqBy(
    _(alphabeticalGroups)
      .flatMap('animals')
      .flatMap('sessions')
      .map((session) => session)
      .value(),
    'study_day',
  );

  // Sort study day headers in chronological order by study_day
  const sortedUniqueStudyDays = _.sortBy(uniqueStudyDays, 'study_day');

  sortedUniqueStudyDays.forEach((session) => {
    const headerName = `Day ${session.study_day}`;
    const date = moment(session.study_date, 'MM/DD/YYYY');
    const formattedDate = date.format('DD MMM YYYY');
    const weekDay = date.format('dddd');
    columnDefs.push({
      field: formatStudyDay(session.study_day, session.study_time),
      minWidth: 100,
      headerClass: ['inline-block', 'padding-0'/* , "margin-top-30" */],
      cellClass: [
        'text-align-center',
        'justify-content-center',
        'padding-lr-0',
        'text-align-center',
      ],
      headerComponent: 'dateStudyDayDayOfTheWeekHeader',
      headerComponentParams: {
        studyDate: formattedDate,
        studyDay: headerName,
        weekDay,
      },
      cellRenderer: (params) => getValueOrGreyedBGColoredCell(params, session),
    });
  });

  _.map(alphabeticalGroups, (group) => {
    let newRow = {
      resizable: false,
      group: group.group_name_to_show,
      cellClass: ['padding-lr-0'],
    };

    _.sortBy(group.animals, 'subject_id').forEach((animal, index) => {
      if (index !== 0) {
        newRow = {
          group: '',
        };
      }

      // keep below as we will likely re-include post-MVP
      // newRow.fateDay = fateDay(animal)
      newRow.stagingDate = stagingDate(animal)
      newRow.mortalityDate = mortalityDate(animal)
      newRow.fateCode = fateCode(animal);
      newRow.subjectType = animal.subject_type;
      newRow.subjectId = animal.subject_id;
      newRow.tempId = animal.temp_id;
      animal.sessions.forEach((session) => {
        newRow[formatStudyDay(session.study_day, session.study_time)] = `${_.multiply(session.value, 100).toFixed(2)}%`;
      });
      rows.push(newRow);
    });
  });

  gridOptions.columnDefs = columnDefs;
  gridOptions.rowData = rows;

  return gridOptions;
};

/**
 * Invoke lambda function asynchornously without waiting for response
 * @param {String} functionName
 * @param {Object} jsonPayload
 * @returns {Promise} Returns a promise that resolves when the function is executed successfully
 */
export const invokeLambdaFunction = async (functionName, jsonPayload) => {
  const credentials = await Auth.currentCredentials();
  const lambda = new LambdaClient({
    credentials,
  });
  const params = {
    FunctionName: functionName,
    InvocationType: 'Event', // Available options: Event | RequestResponse | DryRun,
    LogType: 'Tail',
    Payload: JSON.stringify(jsonPayload),
  };
  return lambda.send(new InvokeCommand(params));

}

/**
 * Returns AWS account ID used in ARNs as a string
 * @returns {Number} AccountId
 */
export const getAWSAccountId = async () => {

  const creds = await Auth.currentCredentials();
  const sts = new STSClient({ region: amplifyConfig.aws_project_region, credentials: creds });
  try {
    const response = await sts.send(new GetCallerIdentityCommand({}));
    return response.Account
  } catch (e) {
    return e
  }
}

export const getProjectPathPrefix = (code = '') => code.split('/')[0]

const downloadFile = async (body, name) => {
  const reader = body.getReader();
  const chunks = [];

  // eslint-disable-next-line no-constant-condition
  while (true) {
    // eslint-disable-next-line no-await-in-loop
    const { done, value } = await reader.read();

    if (done) {
      break;
    }

    chunks.push(value);
  }

  const blob = new Blob(chunks);
  const url = window.URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.href = url;
  a.download = name;
  document.body.appendChild(a);
  a.style.display = 'none';
  a.click();
  a.remove();

  window.URL.revokeObjectURL(url);
}

export const getStudies = (setCompanyHeading, setStudies, setLoader, codes = [], companyNames = [], pageNumber = 1) => {
  if (pageNumber > 10) {
    setCompanyHeading(companyNames)
    setStudies(codes)
    setLoader(false)
    return
  }
  API.graphql(graphqlOperation(queries.getProjectRecords, { pageNumber })).then(
    (response) => {
      response.data.getProjectRecords.forEach((item) => {
        const code = item.code && item.code.split('-')[0]
        if (code && !codes.includes(code)) {
          codes.push(code)
        }
        const companyName = item.companies ? item.companies.filter((item) => item !== null && item !== ' ') : null
        companyName.forEach((item) => {
          if (item && !companyNames.includes(item)) {
            companyNames.push(item)
          }
        })
      })
      if (response.data.getProjectRecords.length === 2500) {
        /* eslint-disable-next-line */
        getStudies(setCompanyHeading, setLoader, setStudies, codes, companyNames, ++pageNumber)
        setLoader(true)
      } else {
        setLoader(false)
        setStudies(codes.join(', '))
        setCompanyHeading(companyNames.join(', '))
      }
    },
  ).catch(() => {
    setStudies(codes)
    setCompanyHeading(companyNames)
    setLoader(false)
  })

}

export const truncationOfLongStr = (longString, maxLength = 20) => {

  if (longString?.length > maxLength) {
    const truncatedString = `${longString.slice(0, maxLength - 3)}...`;
    return truncatedString;
  }
  return longString
}

const addTooltip = (event, text) => {
  if (text !== '') {
    let tooltipDiv = document.getElementById('custom-tooltip');
    if (!tooltipDiv) {
      tooltipDiv = document.createElement('div');
      tooltipDiv.id = 'custom-tooltip';
      tooltipDiv.className = 'custom-tooltip';
      tooltipDiv.innerText = text;
      document.body.appendChild(tooltipDiv);
    }
    return tooltipDiv;
  }
  return null;
};

const onMouseEnterChart = (index, groups) => (event) => {
  const alphabeticalGroups = _.sortBy(groups, 'group_name');
  let text = '';
  if (alphabeticalGroups.length) {
    text = alphabeticalGroups[index] ? alphabeticalGroups[index].group_name_to_show : '';
  }
  let tooltipDiv = document.getElementById('custom-tooltip');
  if (!tooltipDiv) {
    tooltipDiv = addTooltip(event, text);
  } else {
    tooltipDiv.innerText = text;
  }
  if (tooltipDiv) {
    const { clientX, clientY } = event;
    const tooltipRect = tooltipDiv.getBoundingClientRect();
    const { innerWidth, innerHeight } = window;
    let x = clientX + 10;
    let y = clientY + 10;
    if (x + tooltipRect.width > innerWidth) {
      x = innerWidth - tooltipRect.width - 10;
    }
    if (y + tooltipRect.height > innerHeight) {
      y = innerHeight - tooltipRect.height - 10;
    }
    tooltipDiv.style.top = `${y}px`;
    tooltipDiv.style.left = `${x}px`;
  }
};

const onMouseLeaveChart = () => {
  const prevTooltip = document.getElementById('custom-tooltip');
  if (prevTooltip) {
    prevTooltip.remove();
    prevTooltip.style.display = 'none';
  }
};

const eventListeners = [];

export const modeBarButtons = (
  {
    ColorButton, chartMenu, handleClickOpen, studyId, modelId, graphName, acronym, data = [],
  },
) => {
  const newColorButton = new ColorButton();
  const colorButton = newColorButton.getColorButton();
  const groups = data.map((item) => item.name);
  colorButton.click = () => {
    handleClickOpen(
      studyId,
      modelId,
      graphName,
      acronym,
      groups,
    );
  }
  let modeBarButtons = [chartMenu[0]];
  modeBarButtons.push(colorButton);
  modeBarButtons = [...modeBarButtons, ...chartMenu.slice(1)];
  return modeBarButtons;
};

export const displayTooltip = (groups, plotDiv = null) => (figure, graphDiv) => {
  let nodes = [];
  let groupNode = null;
  if (plotDiv) {
    nodes = plotDiv.querySelectorAll('.traces');
    groupNode = plotDiv.querySelector('.legend');
  } else if (graphDiv) {
    nodes = graphDiv.querySelectorAll('.traces');
    groupNode = graphDiv.querySelector('.legend');
  }
  if (groupNode) {
    groupNode.removeEventListener('mouseleave', onMouseLeaveChart);
    groupNode.addEventListener('mouseleave', onMouseLeaveChart);
  }
  if (nodes.length) {
    nodes.forEach((node, index) => {
      if (eventListeners.length && eventListeners[index]) {
        node.removeEventListener('mouseenter', eventListeners[index]);
      }
      if (groups.length) {
        const eventListener = onMouseEnterChart(index, groups);
        eventListeners[index] = eventListener;
        node.addEventListener('mouseenter', eventListener);
      }
    });
  }
};

export const convertToScientificFormat = (standardFormatNumber) => {
  const exponent = Math.floor(Math.log10(Math.abs(standardFormatNumber)));
  const mantissa = standardFormatNumber / (10 ** exponent);
  let exponentStr = exponent < 0 ? '-' : '+';
  exponentStr += Math.abs(exponent) < 10 ? `0${Math.abs(exponent)}` : Math.abs(exponent);
  return `${mantissa.toFixed(2)}E${exponentStr}`;

}
export const getPDFFileNameFromConfigEntry = (configEntry) => {
  const { path, fileName } = configEntry;
  return `${path}${fileName}`
}

export const getPDFEmbededObj = (title, entry) => {
  const fileName = getPDFFileNameFromConfigEntry(entry);
  const embedObjectForOtherBrowser = (
    <embed
      width="100%"
      height="1500px"
      frameBorder="0"
      title={title}
      type="application/pdf"
      src={`${fileName}`}
      key={uuidv4()}
      id={uuidv4()}
      name={uuidv4()}
    />
  )

  const iframeForSafari = (
    <iframe
      style={{
        WebkitOverflowScrolling: 'touch', width: '99.44vw', minHeight: '92.5vh', height: '92.5vh',
      }}
      key={uuidv4()}
      title={fileName}
      src={`${fileName}#top `}
    // onLoad={(iframe) => {
    //   const iframeDoc = iframe.currentTarget.contentDocument || iframe.currentTarget.contentWindow.document;
    //   iframeDoc.documentElement.scrollTop = 0;
    //   iframeDoc.body.scrollTop = 0;
    // }}
    />
  )

  return isSafari ? iframeForSafari : embedObjectForOtherBrowser
}

export const getEmptyOrInitializedMessageContents = (messageContents) => ([{
  type: 'p',
  children: [
    {
      text: messageContents ?? '',
    },
  ],
}]);

export const parseJSONOrStringValueToRTE = (stringOrJSONValue) => {
  let valueToReturn = '';
  try {
    if (Array.isArray(stringOrJSONValue)) {
      valueToReturn = stringOrJSONValue
    } else if (isJson(stringOrJSONValue)) {
      valueToReturn = JSON.parse(stringOrJSONValue);
    }
  } catch (error) {
    valueToReturn = getEmptyOrInitializedMessageContents(stringOrJSONValue);
  }
  return valueToReturn;
}

export const isJson = (item) => {
  let value = typeof item !== 'string' ? JSON.stringify(item) : item;
  try {
    value = JSON.parse(value);
  } catch (e) {
    return false;
  }

  return typeof value === 'object' && value !== null;
}
