import React from 'react';
import { AUDIT_TRAIL_EVENT_TYPES } from '../../../../constants';
import {
  DownloadAllFilesRenderer,
} from '../../../utils';
import { createMessageForSQS } from '../../../utils.createMessage';
import { getFormattedMixedFolderTableGridOpts } from '../../../../utils/utils';

// STUDY DOCUMENTS FUNCTIONS
export const getCustomS3FoldersHandler = async (
  setIsLoadingTable,
  getCustomS3Folders,
  setCustomS3Folders,
  projectCode,
) => {
  setIsLoadingTable(true);
  const customS3FoldersData = await getCustomS3Folders(projectCode);
  setCustomS3Folders(customS3FoldersData);
  setIsLoadingTable(false);
};

export const selectedFolderGridOptsHandler = async (
  files,
  folders = [],
  isCustomFoldersEnabled,
  enterFolderHandler,
  deleteFolderHandler,
  setAgGridOptions,
  isExternalUser,
) => {
  const selectedFolderGridOpts = await getFormattedMixedFolderTableGridOpts(
    files,
    folders,
    true,
    isCustomFoldersEnabled,
    <DownloadAllFilesRenderer
      files={files}
      downloadDocumentAudit={downloadDocumentAudit}
    />,
    enterFolderHandler,
    deleteFolderHandler,
    isExternalUser,
  );
  setAgGridOptions(selectedFolderGridOpts);
};

export const searchFolderData = (folderName, currentFolder) => {
  if (currentFolder[folderName]) {
    return currentFolder[folderName];
  }
  // eslint-disable-next-line
  for (const key in currentFolder) {
    if (
      typeof currentFolder[key] === 'object'
        && !Array.isArray(currentFolder[key])
    ) {
      const result = searchFolderData(folderName, currentFolder[key]);
      if (result) {
        return result;
      }
    }
  }
  return null;
};

export const goToTopLevelHandler = (
  setFoldersNavigation,
  setSelectedFolder,
) => {
  setFoldersNavigation([]);
  setSelectedFolder({});
};

export const createFolderHandler = async (
  newFolderNameRef,
  setIsCreatingFolder,
  createFolder,
  foldersNavigation,
  projectCode,
  setShowCreatingFolderModal,
  setIsLoadingTable,
  getCustomS3Folders,
  setCustomS3Folders,
) => {
  const newFolderName = newFolderNameRef.current.value;
  setIsCreatingFolder(true);
  await createFolder(foldersNavigation, newFolderName, projectCode);
  setIsCreatingFolder(false);
  setShowCreatingFolderModal(false);
  await getCustomS3FoldersHandler(
    setIsLoadingTable,
    getCustomS3Folders,
    setCustomS3Folders,
    projectCode,
  );
};

export const openCreateFolderModalHandler = (setShowCreatingFolderModal) => {
  setShowCreatingFolderModal(true);
};

export const uploadCompletedHandler = async (
  setIsLoadingTable,
  getCustomS3Folders,
  setCustomS3Folders,
  projectCode,
) => {
  await getCustomS3FoldersHandler(
    setIsLoadingTable,
    getCustomS3Folders,
    setCustomS3Folders,
    projectCode,
  );
};

export const deleteDocumentAudit = async (
  user,
  projectCode,
  study,
  sendMessageToQueueForAudits,
) => {
  if (user?.id) {
    const messageBody = await createMessageForSQS({
      eventType: AUDIT_TRAIL_EVENT_TYPES.DOCUMENT_DELETED,
      userId: user.id,
      projectId: projectCode,
      companyName: study.summary,
    });
    sendMessageToQueueForAudits(messageBody);
  }
};

export const downloadDocumentAudit = async (
  user,
  projectCode,
  study,
  sendMessageToQueueForAudits,
) => {
  if (user?.id) {
    const messageBody = await createMessageForSQS({
      eventType: AUDIT_TRAIL_EVENT_TYPES.DOCUMENT_DOWNLOAD,
      userId: user.id,
      projectId: projectCode,
      companyName: study.company,
    });
    sendMessageToQueueForAudits(messageBody);
  }
};
