/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Button, Row, Spinner } from 'react-bootstrap'
import { AgGridReact } from 'ag-grid-react'
import _ from 'lodash'
import BodyWeightChangeChart from './BodyWeightChangeChart'
import BodyWeightChangeTable from '../../BodyWeightChangeTable';
import RadioList from '../RadioList';
import { truncationOfLongStr } from '../../utils';

const TumorVolumeChangePanel = function TumorVolumeChangePanel({
  acronym, studyId, model, dataViewChangeAudit, chartExportAudit, ...props
}) {

  const convertDecimalToPercent = (data) => {
    // deep copy the data into local variable otherwise react will keep re-rendering as data in props will be changed
    const copiedData = JSON.parse(JSON.stringify(data ?? []));
    if (copiedData?.length > 0) {
      copiedData?.forEach((currObj) => {
        currObj.y = currObj.y?.map((yAxis) => parseFloat(yAxis).toFixed(2) * 100)
        if (currObj?.error_y?.array) {
          // toFixed(5) to get more precise value
          currObj.error_y.array = currObj.error_y.array?.map((yAxis) => parseFloat(yAxis).toFixed(5) * 100) ?? [];
        }
        return currObj;
      })
    }
    return copiedData;
  }

  const multipleOf100 = (axises) => [Math.floor(axises[0] * 100), Math.ceil(axises[1] * 100)]

  const formatYAxisLabelForMBWCG = () => {
    if (!props?.tumorVolumeChangeSummaryData || props?.tumorVolumeChangeSummaryData?.length <= 0) return [];

    return convertDecimalToPercent(props?.tumorVolumeChangeSummaryData)
  }

  const formatYAxisLabelForIBWC = (individualBodyWeightChangeGraphData) => {
    if (individualBodyWeightChangeGraphData?.length <= 0) return [];

    return convertDecimalToPercent(individualBodyWeightChangeGraphData)
  }

  const spinner = (
    <Row className="justify-content-center">
      <Button variant="primary" disabled>
        <Spinner
          as="span"
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
        />
        Loading Tumor Volume Change Metrics...
      </Button>
    </Row>
  )

  return (
    <RadioList
      auditCallback={dataViewChangeAudit}
      items={[
        {
          id: 'summary',
          label: 'Summary',
          content: (
            <div>
              <hr className="mlr-10" />
              <div style={props.tumorVolumeChangeSummaryFetching ? { display: 'none' } : {}}>
                <div style={props.showTable && props.tumorVolumeChangeSummaryData?.length ? {} : { display: 'none' }}>
                  <Row
                    style={{
                      width: '100%',
                      height: '450px',
                      margin: 'auto',
                    }}
                    className="summary-chart"
                  >
                    <BodyWeightChangeChart
                      chartExportAudit={chartExportAudit('summary')}
                      margin={{ r: 0, t: 30, pad: 0 }}
                      yaxisTitle="<b>Mean Tumor Volume Change (%)</b>"
                      title="Mean Tumor Volume Change Graph (%)"
                      xaxisTitle="<b>Study Day</b>"
                      yAxisTickSuffix="%"
                      data={formatYAxisLabelForMBWCG()}
                      divId="tumor-volume-mean-summary"
                      groups={props.tumorVolumeChangeSummaryGroups}
                      studyId={studyId}
                      modelId={model.study_id}
                      graphName={`${acronym}-summary`}
                      acronym={acronym}
                    />
                  </Row>
                  <hr className="mlr-10" />
                  <Row
                    style={{
                      width: '100%',
                      height: '350px',
                      margin: 'auto',
                    }}
                  >
                    <h4>Mean Tumor Volume Change Table (%)</h4>
                    {!_.isEmpty(props.tumorVolumeChangeSummaryGridOptions) && (
                      <BodyWeightChangeTable
                        divStyles={{
                          height: '100%',
                          width: '100%',
                        }}
                        gridOptions={props.tumorVolumeChangeSummaryGridOptions}
                      />
                    )}
                  </Row>
                  <Row
                    style={{
                      width: '100%',
                      height: 'auto',
                      margin: 'auto',
                    }}
                  >
                    <div>
                      <br />
                      <br />
                      {/* potentially modify the M1 text to be lower-cased */}
                      Tumor Volume Change = ( T - T0 ) / T0<br />
                      T0 - treatment group initial value<br />
                      T - treatment group value<br />
                    </div>
                  </Row>
                </div>
                {/* error handle */}
                {(!props.showTable || !props.tumorVolumeChangeSummaryData?.length) && <div>No Data Available</div>}
              </div>
              {props.tumorVolumeChangeSummaryFetching && spinner}
              <hr className="mlr-10" />
            </div>
          ),
        },
        {
          id: 'individual',
          label: 'Individual',
          content: (
            <div>
              <hr className="mlr-10" />
              <h5 className="text-center text-md-left">
                Absolute Tumor Volume Change (%): Individual Measurements
              </h5>
              <div style={props.tumorVolumeChangeIndividualFetching ? { display: 'none' } : {}}>
                <div style={!props.showTable || _.isEmpty(props.tumorVolumeChangeIndividualData) ? { display: 'none' } : {}}>
                  <div
                    className="ag-theme-alpine"
                    style={{
                      width: '100%',
                      height: '450px',
                      margin: 'auto',
                    }}
                  >
                    {props.tumorVolumeChangeIndividualGridOptions?.onGridReady
                      ? (
                        <AgGridReact
                          gridOptions={props.tumorVolumeChangeIndividualGridOptions}
                        />
                      )
                      : (
                        <Row className="justify-content-center">
                          <Button variant="primary" disabled>
                            <Spinner
                              as="span"
                              animation="grow"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                            Loading Tumor Volume Change Metrics...
                          </Button>
                        </Row>
                      )}
                  </div>

                  {!_.isEmpty(props.tumorVolumeChangeIndividualData.chartValues)
                    && Object.entries(props.tumorVolumeChangeIndividualData.chartValues).map(
                      (groupChartValues) => (
                        <Row
                          key={groupChartValues[0]}
                          style={{
                            height: '450px',
                            marginTop: '40px',
                          }}
                        >
                          <BodyWeightChangeChart
                            chartExportAudit={chartExportAudit('individual')}
                            margin={{
                              r: 20,
                              t: 40,
                              pad: 0,
                            }}
                            yaxisTitle="<b>Tumor Volume Change (%) </b>"
                            title={
                              `Individual Tumor Volume Change (%): ${truncationOfLongStr(groupChartValues[0], 80)}`
                            }
                            xaxisTitle="<b>Study Day</b>"
                            yAxisTickSuffix="%"
                            data={formatYAxisLabelForIBWC(groupChartValues[1])} // this
                            xaxisRange={props.tumorVolumeChangeIndividualData.xaxisRange}
                            yaxisRange={multipleOf100(props.tumorVolumeChangeIndividualData.yaxisRange)}
                            divId="individual-tumor-volumes"
                            studyId={studyId}
                            modelId={model.study_id}
                            graphName={`${acronym}-individual-${groupChartValues[0]}`}
                            acronym={acronym}
                          />
                        </Row>
                      ),
                    )}
                </div>
                {(!props.showTable || _.isEmpty(props.tumorVolumeChangeIndividualData)) && <div>No Data Available</div>}
              </div>
              {props.tumorVolumeChangeIndividualFetching && spinner}
              <hr className="mlr-10" />
            </div>
          ),
        },
      ]}
    />
  );
}

TumorVolumeChangePanel.propTypes = {
  tumorVolumeChangeIndividualData: PropTypes.object,
  tumorVolumeChangeIndividualGridOptions: PropTypes.object,
  tumorVolumeChangeSummaryData: PropTypes.arrayOf(PropTypes.object),
  tumorVolumeChangeSummaryGroups: PropTypes.arrayOf(PropTypes.object),
  tumorVolumeChangeSummaryGridOptions: PropTypes.object,
  tumorVolumeChangeIndividualFetching: PropTypes.bool,
  tumorVolumeChangeSummaryFetching: PropTypes.bool,
  showTable: PropTypes.bool,
  dataViewChangeAudit: PropTypes.func,
  chartExportAudit: PropTypes.func,
};

export default TumorVolumeChangePanel;
