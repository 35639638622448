import React from 'react';
import { createRoot } from 'react-dom/client';
import { Amplify } from 'aws-amplify';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import { awsmobile } from './config';
import { GraphQLProvider } from './providers/graphql';
import { S3Provider } from './providers/s3';
import { SQSProvider } from './providers/sqs'
import { SNSProvider } from './providers/sns'
import { FolderProvider } from './providers/folder';
import { Segment } from './providers/analytics/segment';
import { AuthProvider } from './providers/auth'
import { MortalityObservationLegendProvider } from './providers/mortalityObservationLegend'
import { ClinicalObservationLegendProvider } from './providers/clinicalObservationLegend';
import { SnackBarProvider } from './providers/snackbar';

Amplify.configure(awsmobile);
const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <SQSProvider>
      <GraphQLProvider>
        <FolderProvider>
          <S3Provider>
            <MortalityObservationLegendProvider>
              <ClinicalObservationLegendProvider>
                <SNSProvider>
                  <BrowserRouter
                    getUserConfirmation={() => {
                      /* Empty callback to block the default browser prompt */
                    }}
                  >
                    <Segment>
                      <AuthProvider>
                        <SnackBarProvider>
                          <App />
                        </SnackBarProvider>
                      </AuthProvider>
                    </Segment>
                  </BrowserRouter>
                </SNSProvider>
              </ClinicalObservationLegendProvider>
            </MortalityObservationLegendProvider>
          </S3Provider>
        </FolderProvider>
      </GraphQLProvider>
    </SQSProvider>
  </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
