import { Button, Modal, /* Form, */ Fade /* Alert */ } from 'react-bootstrap';

import React, { useState } from 'react';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import { makeStyles } from '@mui/styles';
import './documents.css';
import _ from 'lodash';
import {
  MODAL_INIT_STATE,
} from '../../../constants';

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    fontFamily: 'AvenirNextLTPro-Regular',
    width: '100%',
    '&.Mui-focused': {
      backgroundColor: '#fff',
      fontFamily: 'AvenirNextLTPro-Regular',
    },
  },
  focused: {},
  notchedOutline: {},

  label: {
    color: '#FFFFFFDE',
  },
}));

const DocumentUploadModal = function DocumentUploadModal({
  description,
  s3ObjectPath,
  file,
  existing,
  setModalProps,
  uploadingSame,
  alreadyInQue,
  pushToQueue,
  fileTypeAllowed,
}) {

  const [newFileDescription, setNewFileDescription] = useState(description);

  const classes = useStyles();

  const initModalBody = () => (
    <>
      {existing && !uploadingSame && !alreadyInQue && (
        <Alert className="documents-AlertOnTop" sx={{ fontFamily: 'AvenirNextLTPro-Regular' }} severity="warning">
          <AlertTitle sx={{ fontFamily: 'AvenirNextLTPro-Regular' }}>Warning</AlertTitle>
          {s3ObjectPath}<br />
          This file has already been uploaded.<br />
          Click <strong>Yes</strong> to overwrite the file and description.<br />
          Click <strong>No</strong> to cancel upload.
        </Alert>
      )}
      {uploadingSame && (
        <Alert className="documents-AlertOnTop" sx={{ fontFamily: 'AvenirNextLTPro-Regular' }} severity="warning">
          <AlertTitle sx={{ fontFamily: 'AvenirNextLTPro-Regular' }}>Warning</AlertTitle>
          {s3ObjectPath}<br />
          This file has already been uploading.<br />
        </Alert>
      )}
      {alreadyInQue && (
        <Alert className="documents-AlertOnTop" sx={{ fontFamily: 'AvenirNextLTPro-Regular' }} severity="warning">
          <AlertTitle sx={{ fontFamily: 'AvenirNextLTPro-Regular' }}>Warning</AlertTitle>
          {s3ObjectPath}<br />
          This file is already in the uploading queue.<br />
        </Alert>
      )}
      {fileTypeAllowed !== true && (
        <Alert className="documents-AlertOnTop" sx={{ fontFamily: 'AvenirNextLTPro-Regular' }} severity="error">
          <AlertTitle sx={{ fontFamily: 'AvenirNextLTPro-Regular' }}>Error</AlertTitle>
          {s3ObjectPath}<br />
          This file type is not allowed for upload.
        </Alert>
      )}
      {(!uploadingSame && !alreadyInQue) && (
        <form className="form">
          <InputLabel className="inputLabel" htmlFor="newFileDescription">
            File Description
          </InputLabel>
          <TextField
            id="newFileDescription"
            variant="outlined"
            multiline
            placeholder={
                _.isEmpty(newFileDescription)
                  ? 'file description'
                  : newFileDescription
              }
            name="newFileDescription"
            InputProps={{
              classes: {
                root: classes.root,
                focused: classes.focused,
              },
            }}
            sx={{
              width: '100%',
            }}
            onChange={(e) => setNewFileDescription(e.currentTarget.value)}
            minRows={3}
          />
        </form>
      )}
    </>
  );
  // eslint-disable-next-line
  const [modalBody, setModalBody] = useState(initModalBody);

  const handleUploadSubmit = async () => {
    setModalProps(MODAL_INIT_STATE);
    setTimeout(() => {
      pushToQueue({
        s3ObjectPath,
        file,
        newFileDescription,
      })
    }, 500);
  };
  return (
    <>
      <Fade in={!_.isEmpty(file)}>
        <Modal
          show={!_.isEmpty(file)}
          onHide={() => setModalProps(MODAL_INIT_STATE)}
        >
          <Modal.Header className="documents-oldModal-header" closeButton={() => setModalProps(MODAL_INIT_STATE)}>
            <Modal.Title>
              <span className="containerSubTitle">
                File Upload:{' '}
                <span className="containerSubTitleFileName">{s3ObjectPath}</span>
              </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="documents-oldModal-body">{modalBody}</Modal.Body>

          {(!uploadingSame && !alreadyInQue) && (
            <Modal.Footer className="documents-oldModal-footer">
              <Button
                className="LoginFormSignInButton"
                type="submit"
                variant="primary"
                disabled={fileTypeAllowed !== true || uploadingSame || alreadyInQue}
                onClick={() => handleUploadSubmit()}
              >
                Yes
              </Button>
              <Button
                className="LoginFormOutlineButton"
                type="button"
                variant="light"
                onClick={() => setModalProps(MODAL_INIT_STATE)}
              >
                No
              </Button>
            </Modal.Footer>
          )}
        </Modal>
      </Fade>
    </>
  );
}
export default DocumentUploadModal;
