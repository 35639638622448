import React, { useCallback, useMemo, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Button } from 'react-bootstrap';
import { TextField } from '@mui/material';
import { Auth } from 'aws-amplify';
import InputLabel from '@mui/material/InputLabel';
import { createMessageForSQS } from '../../utils.createMessage';
import { useSQS } from '../../../providers/sqs';
import { AUDIT_TRAIL_EVENT_TYPES } from '../../../constants';
import isValidPassword from './utils';
import './auth.css'

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    fontFamily: 'AvenirNextLTPro-Regular',
    width: '400px',
    '&.Mui-focused': {
      backgroundColor: '#fff',
      fontFamily: 'AvenirNextLTPro-Regular',
    },
  },
  focused: {},
  notchedOutline: {},
}));

const NewPassword = function NewPasswordComponent({
  user, cancel, handleLogin, setUserPassword, setErrorMessage,
}) {
  const [password, setPassword] = useState({
    newPassword: '',
    confirmPassword: '',
  });
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [loading, setLoading] = useState(false);
  const { sendMessageToQueueForAudits } = useSQS();
  const classes = useStyles();

  const onChange = useCallback((e) => {
    setPassword({
      ...password,
      [e.target.name]: e.target.value,
    });
  }, [password]);

  const doesPasswordMatch = useMemo(() => {
    const { newPassword, confirmPassword } = password;
    if (newPassword.length) {
      setIsPasswordValid(!!isValidPassword(newPassword));
    }
    if (!newPassword.length || !confirmPassword.length) return true;
    return newPassword === confirmPassword;
  }, [password])

  const submitNewPassword = (e) => {
    e.preventDefault();
    setLoading(true)
    user.completeNewPasswordChallenge(password.newPassword, {}, {
      onSuccess() {
        createMessageForSQS({ eventType: AUDIT_TRAIL_EVENT_TYPES.ACCOUNT_CREATION, userId: user.username }).then((messageBody) => {
          sendMessageToQueueForAudits(messageBody)
        })
        Auth.configure({
          Auth: {
            authenticationFlowType: 'USER_SRP_AUTH',
          },
        });
        handleLogin(undefined, password.newPassword)
        setUserPassword(password.newPassword)
      },
      onFailure(err) {
        setLoading(false)
        setErrorMessage(err.message)
        if (err.code === 'NotAuthorizedException') {
          cancel();
        }
      },
    });
  }

  const isSubmitDisabled = useMemo(() => {
    const { newPassword, confirmPassword } = password;
    return (!isPasswordValid || !doesPasswordMatch || !newPassword.length || !confirmPassword.length);
  }, [password, doesPasswordMatch, isPasswordValid]);

  return (
    <div>
      <form className="form" onSubmit={submitNewPassword} noValidate>
        <div className="inputField">
          <InputLabel className="inputLabel" htmlFor="newPassword">
            New Password
          </InputLabel>
          <TextField
            name="newPassword"
            variant="outlined"
            placeholder="Enter new password"
            type="password"
            value={password.newPassword}
            id="newPassword"
            autoFocus
            InputProps={{
              classes: {
                root: classes.root,
                focused: classes.focused,
              },
            }}
            sx={{
              width: '100%',
            }}
            onChange={onChange}
          />
        </div>
        <div className="inputField">
          <InputLabel className="inputLabel" htmlFor="newPassword">
            Confirm Password
          </InputLabel>
          <TextField
            name="confirmPassword"
            variant="outlined"
            placeholder="Enter confirm password"
            type="password"
            value={password.confirmPassword}
            id="confirmPassword"
            InputProps={{
              classes: {
                root: classes.root,
                focused: classes.focused,
              },
            }}
            sx={{
              width: '100%',
            }}
            onChange={onChange}
          />
        </div>

        <p
          style={{
            color: '#E2AAA0',
            display: !isPasswordValid || !doesPasswordMatch ? 'block' : 'none',
          }}
        >
          {
            !isPasswordValid ? 'Password must have at least 15 characters, 1 lowercase character, 1 uppercase character, 1 number, 1 special character'
              : "Password doesn't match"
          }
        </p>
        <Button
          className="LoginFormSignInButton"
          type="submit"
          variant="primary"
          disabled={isSubmitDisabled || loading}
        >
          {loading ? 'Loading...' : 'Reset Password'}
        </Button>

        <Button
          className="LoginFormOutlineButton"
          type="button"
          variant="light"
          onClick={() => cancel()}
        >
          Sign Out
        </Button>

      </form>
    </div>
  )
}

export default NewPassword;
