// Main Component for Listing and handling System Message Generation.
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { styled } from '@mui/styles';

import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';

import CircularProgress from '@mui/material/CircularProgress';
import SystemMessagesGrid from './SystemMessagesGrid';
import AddSystemMessageModal from './AddSystemMessage';
import PreviewSystemMessageModal from './PreviewSystemMessage';
import { parseJSONOrStringValueToRTE } from '../../../utils';
import { BRAND_COLORS } from '../../../../constants';

const ColorButton = styled(Button)(() => ({
  color: '#222222',
  backgroundColor: '#FFFFFF',
  border: `1px solid ${BRAND_COLORS.orange}`,
  borderRadius: '4px',
  marginRight: '12px',
  fontFamily: 'AvenirNextLTPro-Medium',
  marginBottom: '25px',
  '&:hover': {
    backgroundColor: '#F9A868',
    borderRadius: '4px',
    border: '1px solid transparent',
    fontFamily: 'AvenirNextLTPro-Medium',
  },
}));

const SystemMessages = () => {

  const breadcrumbs = [
    <Link underline="hover" className="study-details-breadcrumb-homelink" key="system_message_breadcrumb_1" to="/landing">
      <span><em>Study </em></span>List
    </Link>,

    <Typography className="bc-study-det" key="system_message_breadcrumb_2">
      System Messages
    </Typography>,
  ];

  const renderNavigation = () => (
    <Breadcrumbs
      underline="none"
      className="bc-margin-bl"
      sx={{ fontSize: '14px', marginTop: '16px', padding: '0px' }}
      separator={<img src="./landing/arrow_right.svg" alt="breadcrumb separator" />}
      aria-label="breadcrumb"
    >
      {breadcrumbs}
    </Breadcrumbs>
  )
  // eslint-disable-next-line no-unused-vars
  const [loader, setLoader] = useState(false)
  const [showSystemMessageModal, setAddSystemMessageModal] = useState(false);
  const [showPreviewMessageModal, setShowPreviewMessageModal] = useState(false);
  const [previewMessageContent, setPreviewMessageContent] = useState(null);
  const [sentMessage, setSentMessage] = useState(null); // used to point grid if new message was added.

  useEffect(() => {
    if (previewMessageContent) {
      setShowPreviewMessageModal(true)
    }
  }, [previewMessageContent])

  const toggleAddSystemMessageModal = () => {
    setAddSystemMessageModal(!showSystemMessageModal);
  };

  const togglePreviewMessageModal = () => {
    setShowPreviewMessageModal(!showPreviewMessageModal);
  };

  const resetMessageContent = () => {
    setPreviewMessageContent(null)
  }

  const getSentMessage = (response) => {
    // receives graphql response on message addition.
    const data = response.data.createSystemMessage
    setSentMessage({
      id: data.id,
      recipients: data.recipients,
      date: data.sent_timestamp,
      recalled_timestamp: data.recalled_timestamp,
      sentBy: data.sent_by,
      content: parseJSONOrStringValueToRTE(data.message_content),
      status: data.status,
      sender: {
        email: data.user?.email,
        first_name: data.user?.first_name,
        last_name: data.user?.last_name,
      },
    })
  }
  // --------- FINAL RETURN -------
  return (
    <div className="landing-inner-container-mphs">
      {renderNavigation()}
      <div className="d-flex" data-testid="system-admin-title">
        <span className="icon-txt AvenirNextLTProRegular">System Messages</span>
      </div>

      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <ColorButton disabled={loader} data-testid="test-system-message-add" variant="outlined" onClick={toggleAddSystemMessageModal}>{loader ? 'Creating Message' : 'Add Message'}</ColorButton>
        {loader && <CircularProgress />}
      </div>

      {/* <Snackbar
        style={{ position: 'unset' }}
        open={showResponseMessage}
        autoHideDuration={2500}
        onClose={() => setShowResponseMessage(false)}
        message={showUpdatePreferencesResult(messageType)}
      /> */}

      <div className="ag-theme-alpine" style={{ height: '500px', width: '100%', marginBottom: '32px' }} data-testid="system-admin-grid">
        <SystemMessagesGrid props={{
          setPreviewMessageContent,
          newMessageSent: sentMessage,
        }}
        />
      </div>
      <AddSystemMessageModal props={{
        showModal: showSystemMessageModal,
        toggleModal: toggleAddSystemMessageModal,
        sendData: getSentMessage,
      }}
      />
      <PreviewSystemMessageModal props={{
        showModal: showPreviewMessageModal,
        toggleModal: togglePreviewMessageModal,
        resetMessageContent,
        messageContent: previewMessageContent,
      }}
      />

    </div>
  );
}

export default SystemMessages;
