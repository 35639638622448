import React, {
  createContext, useState, useEffect, useRef,
} from 'react';
import _ from 'lodash';
import {
  S3Client, ListObjectsCommand, ListObjectsV2Command, DeleteObjectCommand, AbortMultipartUploadCommand, PutObjectCommand, GetObjectTaggingCommand,
} from '@aws-sdk/client-s3';
import { Upload } from '@aws-sdk/lib-storage';
import {
  DEFAULT_FOLDERS,
  CUSTOM_DEFAULT_FOLDERS,
  formatLastModified,
  CHINA_CONFIG,
  US_CONFIG,
} from '../constants';
import { useFolder } from './folder';
import { useGraphQL } from './graphql';
import {
  findOrCreatePath, getFileData, getPathFromKey, adjustModifyDates, encodeFileDescription, getFileDescriptions, updateDescription,
} from './s3.utils';

export const S3Context = createContext();
export const S3Provider = ({ children }) => {
  const ContentLength = 1024 * 1024 * 2 * 5;
  const { setDeleteFileName } = useFolder();
  const [s3Folders, setS3Folders] = useState({ ...DEFAULT_FOLDERS });
  const [s3FoldersUpdating, setS3FoldersUpdating] = useState(false);
  const [flushUploadFile, setFlushUploadFile] = useState(false);
  const [errorModal, setErrorModal] = useState({});
  const [isChina, setIsChina] = useState(true);
  const [abortUpload, setAbortUpload] = useState(false);
  const [smallFileLoader, setSmallFileLoader] = useState(false);
  const [cancellingFileUpload, setCancellingFileUpload] = useState(false);
  const [uploadProgress, setUploadProgress] = useState({});
  const [currentUploadId, setCurrentUploadId] = useState(undefined);
  const { getS3Credentials } = useGraphQL();
  const [bucketName, setBucketName] = useState(CHINA_CONFIG.BUCKET);
  const [region, setRegion] = useState(CHINA_CONFIG.REGION);
  const abortRef = useRef();
  const partsProgress = useRef({});
  const progressKey = useRef();
  const randomFileKey = useRef();
  const currentUploadIdRef = useRef({});
  abortRef.current = abortUpload;
  currentUploadIdRef.current = currentUploadId

  useEffect(() => {
    if (!window.location.host.endsWith('cn')) {
      setIsChina(false);
      setBucketName(US_CONFIG.BUCKET);
      setRegion(US_CONFIG.REGION);
    }
  }, []);

  const refreshFolders = async (projectCode) => {
    setS3Folders(Object.assign(s3Folders, DEFAULT_FOLDERS));
    updateS3Folders(`${projectCode}/`);
  };

  const getAuthenticatedS3Client = async (projectPrefix) => {
    const response = await getS3Credentials(projectPrefix, isChina).catch(() => { })
    const config = response?.data?.getS3Credentials ? {
      credentials: {
        accessKeyId: response.data.getS3Credentials.AccessKeyId,
        secretAccessKey: response.data.getS3Credentials.SecretAccessKey,
        sessionToken: response.data.getS3Credentials.SessionToken,
      },
      region,
    } : {}
    const client = new S3Client(config);
    return client;
  };

  const isAccessible = async (projectPrefix) => {
    const projectCode = getProjectPrefix(projectPrefix);
    const client = await getAuthenticatedS3Client(projectCode);
    try {
      const params = {
        Bucket: bucketName,
        Prefix: projectPrefix,
      };
      await client.send(new ListObjectsV2Command(params));
      return true;
    } catch (e) {
      return false;
    }
  };

  const cancelFileUpload = async (projectCode, key) => {
    try {
      if (projectCode && key) {
        setCancellingFileUpload(true)
        const id = currentUploadId || currentUploadIdRef.current
        setCurrentUploadId(undefined)
        partsProgress.current[randomFileKey.current] = {};
        progressKey.current = null;
        randomFileKey.current = null;
        const s3Client = await getAuthenticatedS3Client(getProjectPrefix(projectCode));
        const input = {
          Bucket: bucketName,
          Key: key,
          UploadId: id,
        }
        const response = await s3Client.send(new AbortMultipartUploadCommand(input));
        setCancellingFileUpload(false)
        return response;
      }
      return null;
    } catch (error) {
      return error
    }
  };

  const uploadMultipart = async (client, Key, Body, Bucket, description) => {
    const parallelUploads3 = new Upload({
      client,
      params: {
        Bucket,
        Key,
        Body,
        Tagging: `description=${encodeFileDescription(description)}`,
      },
      queueSize: 4,
      partSize: 1024 * 1024 * 5,
    });

    parallelUploads3.on('httpUploadProgress', (progress) => {
      setUploadProgress({
        loaded: progress.loaded,
        total: progress.total,
      });
    });
    const res = await parallelUploads3.done();
    return res;
  };

  /**
   * @param {AWS.S3} client
   * @param {string} key
   * @param {any} body
   * @param {string} bucket
   * @param {string} description
   */
  const uploadSinglePartFile = async (client, key, body, bucket, description) => {
    const params = {
      Body: body,
      Bucket: bucket,
      Key: key,
      Tagging: `description=${encodeFileDescription(description)}`,
    };
    const res = await client.send(new PutObjectCommand(params))
    return res;
  }

  const uploadDocument = async (
    s3ObjectPath,
    file,
    description,
    projectCode,
  ) => {
    const s3Client = await getAuthenticatedS3Client(getProjectPrefix(projectCode));
    let uploadResponse = {}
    const check = Math.floor(file.size / ContentLength) === 0
    if (check) {
      setSmallFileLoader(true);
      uploadResponse = await uploadSinglePartFile(
        s3Client,
        s3ObjectPath,
        file,
        bucketName,
        description,
      );
      setSmallFileLoader(false);
    } else {
      uploadResponse = await uploadMultipart(
        s3Client,
        s3ObjectPath,
        file,
        bucketName,
        description,
      );
    }
    setCurrentUploadId(undefined)
    setUploadProgress({});
    refreshFolders(projectCode);
    return uploadResponse
  };

  const formatFolderLastModified = () => {
    Object.entries(s3Folders).forEach(([key, value]) => {
      if (
        value.files.length !== 0
        && value.files.every((x) => typeof x === 'object')
      ) {
        const latestFile = _.last(
          _.sortBy(s3Folders[key].files, (file) => new Date(file.lastModified)),
        );

        if (!_.isEmpty(latestFile)) {
          setS3Folders(
            Object.assign(s3Folders, {
              [key]: {
                ...s3Folders[key],
                lastModified: formatLastModified(
                  latestFile.lastModified.toString(),
                ),
              },
            }),
          );
        }

        const formattedFiles = _.map(s3Folders[key].files, (file) => ({
          ...file,
          lastModified:
            file.lastModified !== ''
              ? formatLastModified(file.lastModified.toString())
              : '',
        }));

        setS3Folders(
          Object.assign(s3Folders, {
            [key]: {
              ...s3Folders[key],
              files: [...formattedFiles],
            },
          }),
        );
      }
    });
  };

  const getS3ObjectTagsPromise = async (s3Client, s3Path) => s3Client.send(new GetObjectTaggingCommand({
    Key: s3Path,
    Bucket: !isChina ? US_CONFIG.BUCKET : CHINA_CONFIG.BUCKET,
  }))
    .then((data) => ({
      ...data,
      $response: {
        request: {
          params: {
            Key: s3Path,
          },
        },
      },
    }))

  const resolveS3ObjectTagsPromises = async (s3ObjectTagsPromises) => {
    const s3ObjectTagsResponse = await Promise.all(s3ObjectTagsPromises)
      .then((data) => data)
      .catch(() => { });

    if (Array.isArray(s3ObjectTagsResponse)) {
      s3ObjectTagsResponse.forEach((resolved) => {
        const s3ObjectPath = resolved.$response.request.params.Key;
        const folderKey = `${s3ObjectPath.split('/')[1]}/`;
        const newDescription = getDescriptionTagValue(resolved.TagSet);
        const addedDescriptions = _.map(s3Folders[folderKey].files, (file) => {
          if (file.s3Path === s3ObjectPath) {
            return { ...file, description: newDescription };
          }
          return file;
        });
        setS3Folders(
          Object.assign(s3Folders, {
            [folderKey]: {
              ...s3Folders[folderKey],
              files: [...addedDescriptions],
            },
          }),
        );
      });
    }
  };

  const getDescriptionTagValue = (tags) => {
    // eslint-disable-next-line consistent-return
    const descriptionTag = _.find(tags, (tag) => {
      if (tag.Key === 'description') {
        return tag;
      }
    });
    if (!_.isEmpty(descriptionTag)) {
      return descriptionTag.Value;
    }
    return '';
  };

  const getProjectPrefix = (code = '') => code.split('-')[0]

  const updateS3Folders = async (studyProjectCode) => {
    try {
      const objectTagsPromises = [];
      let folderRes = [];
      const s3Client = await getAuthenticatedS3Client(getProjectPrefix(studyProjectCode));
      const s3Key = 'Key';
      const s3LastModified = 'LastModified';

      const params = {
        Bucket: bucketName,
        Prefix: studyProjectCode,
      };

      folderRes = await s3Client.send(new ListObjectsCommand(params))
        .then((data) => data.Contents)
        .catch((e) => e);

      setS3FoldersUpdating(true);

      Object.values(folderRes).forEach((obj) => {
        if (obj[s3Key] !== studyProjectCode) {
          if (!_.endsWith(obj[s3Key], '/')) {
            objectTagsPromises.push(getS3ObjectTagsPromise(s3Client, obj[s3Key]));
            const splitPath = obj[s3Key].split('/');
            const folderPath = `${splitPath[1]}/`;
            const fileName = splitPath[2];

            setS3Folders(
              Object.assign(s3Folders, {
                [folderPath]: {
                  ...s3Folders[folderPath],
                  files: [
                    ...s3Folders[folderPath]?.files,
                    {
                      lastModified: obj[s3LastModified],
                      name: fileName,
                      s3Path: obj[s3Key],
                      description: '',
                    },
                  ],
                },
              }),
            );
          } else {
            const splitPath = obj[s3Key].split('/');
            const folderPath = `${splitPath[1]}/`;
            setS3Folders(
              Object.assign(s3Folders, {
                [folderPath]: {
                  ...s3Folders[folderPath],
                  name: splitPath[1],
                  s3Path: obj[s3Key],
                  files: [],
                  lastModified: formatLastModified(
                    obj[s3LastModified].toString(),
                  ),
                },
              }),
            );
          }
        }
      });
      if (!_.isEqual(s3Folders, DEFAULT_FOLDERS)) {
        await resolveS3ObjectTagsPromises(objectTagsPromises);
        formatFolderLastModified();
      } else {
        setS3Folders({ ...DEFAULT_FOLDERS });
      }
      setS3FoldersUpdating(false);
      // eslint-disable-next-line no-empty
    } catch (e) { }

  };

  const deleteS3ObjectChina = async (s3ObjectKey, projectCode) => {
    const s3Client = await getAuthenticatedS3Client(getProjectPrefix(projectCode));
    try {
      const params = {
        Bucket: bucketName,
        Key: s3ObjectKey,
      };
      await s3Client.send(new DeleteObjectCommand(params));
      // eslint-disable-next-line no-useless-return
      return;
    } catch (e) {
      // eslint-disable-next-line consistent-return
      return e;
    }
  };

  const deleteS3Object = async (filePath, projectCode) => {
    try {
      await deleteS3ObjectChina(filePath, projectCode);
      setDeleteFileName('');
      if (projectCode) {
        refreshFolders(projectCode)
      }
      // eslint-disable-next-line no-useless-return
      return;
    } catch (e) {
      // eslint-disable-next-line consistent-return
      return e;
    }
  };

  const createFolder = async (foldersNavigation, newFolderName, projectCode) => {
    const s3Client = await getAuthenticatedS3Client(getProjectPrefix(projectCode));
    let folderKey = `${projectCode}/${newFolderName}/hidden.crown`;

    // If there are nested folders, add them to the folderKey
    if (foldersNavigation.length > 0) {
      const foldersNavigationString = foldersNavigation.map((folderName) => folderName).join('/');
      folderKey = `${projectCode}/${foldersNavigationString}/${newFolderName}/hidden.crown`;
    }
    try {
      let uploadResponse = {};

      uploadResponse = await uploadSinglePartFile(
        s3Client,
        folderKey,
        'hidden.crown',
        bucketName,
        '',
      );

      setCurrentUploadId(undefined);
      setUploadProgress({});
      refreshFolders(projectCode);
      return uploadResponse;
    } catch (e) {
      return e;
    }
  };

  // GET DEFAULT AND CUSTOM S3 FOLDERS AND MERGED THEM TOGETHER
  const getCustomS3Folders = async (studyProjectCode) => {
    try {
      let folderRes = [];
      const s3Client = await getAuthenticatedS3Client(
        getProjectPrefix(studyProjectCode),
      );
      const s3Key = 'Key';
      const s3LastModified = 'LastModified';
      const params = {
        Bucket: bucketName,
        Prefix: studyProjectCode,
      };
      folderRes = await s3Client.send(new ListObjectsCommand(params))
        .then((data) => data.Contents)
        .catch((e) => e);
      const objectTagsPromises = [];

      // Mapping files to new corresponding folders
      const customS3Folders = JSON.parse(JSON.stringify(CUSTOM_DEFAULT_FOLDERS));
      Object.values(folderRes).forEach((folderResObj) => {
        if (!folderResObj.Key.endsWith('/')) {
          objectTagsPromises.push(
            getS3ObjectTagsPromise(s3Client, folderResObj[s3Key]),
          );
          const filePath = getPathFromKey(folderResObj.Key);
          const fileData = getFileData(folderResObj, s3Key, s3LastModified);
          findOrCreatePath(customS3Folders, filePath, fileData);
        }
      });

      // Mapping descriptions to files
      const fileDescriptions = await getFileDescriptions(objectTagsPromises);
      fileDescriptions.forEach((file) => updateDescription(customS3Folders, file.s3ObjectPath, file.description));

      adjustModifyDates(customS3Folders);
      return customS3Folders;
    } catch (e) {
      return e;
    }
  };

  // DELETE CUSTOM FOLDER
  const deleteFolder = async (projectCode, folderPath) => {
    const s3Client = await getAuthenticatedS3Client(
      getProjectPrefix(projectCode),
    );
    try {
      const params = {
        Bucket: bucketName,
        Prefix: folderPath,
      };
      // Delete all objects in the folder
      const objects = await s3Client.send(new ListObjectsV2Command(params));

      const deleteObjectPromises = objects.Contents.map((object) => s3Client.send(new DeleteObjectCommand({ Bucket: bucketName, Key: object.Key })));
      await Promise.all(deleteObjectPromises);
      // Delete the folder itself
      await s3Client.send(new DeleteObjectCommand({ Bucket: bucketName, Key: folderPath }));
      refreshFolders(projectCode);
      // eslint-disable-next-line no-useless-return
      return;
    } catch (e) {
      // eslint-disable-next-line consistent-return
      return e;
    }
  };

  return (
    <S3Context.Provider
      value={{
        s3Folders,
        updateS3Folders,
        s3FoldersUpdating,
        uploadDocument,
        deleteS3Object,
        cancelFileUpload,
        errorModal,
        setErrorModal,
        setS3Folders,
        refreshFolders,
        abortUpload,
        setAbortUpload,
        uploadProgress,
        setUploadProgress,
        setCurrentUploadId,
        currentUploadId,
        cancellingFileUpload,
        bucketName,
        smallFileLoader,
        getAuthenticatedS3Client,
        isAccessible,
        flushUploadFile,
        setFlushUploadFile,
        getProjectPrefix,
        createFolder,
        getCustomS3Folders,
        deleteFolder,
      }}
    >
      {children}
    </S3Context.Provider>
  );
}

export const useS3 = () => {
  const context = React.useContext(S3Context);

  if (context === undefined) {
    throw new Error(
      '`useS3` hook must be used within a `S3Provider` component',
    );
  }
  return context;
};
