import {
  createBoldPlugin,
  createParagraphPlugin,
  createItalicPlugin,
  createPlugins,
  createStrikethroughPlugin,
  createFontColorPlugin,
  createFontBackgroundColorPlugin,
  createAlignPlugin,
  createTablePlugin,
  createUnderlinePlugin,
  createDeserializeDocxPlugin,
  createHighlightPlugin,
} from '@udecode/plate';
import { createJuicePlugin } from '@udecode/plate-juice';

import plateUI from './common/plateUI';

/**
 * Below is the list of default plugins being used in rich text editor,
 * these do not have any concern with any user's role
 */
export const defaultPlugins = [
  createParagraphPlugin(),
  createAlignPlugin(),
  createBoldPlugin(),
  createItalicPlugin(),
  createStrikethroughPlugin(),
  createFontColorPlugin(),
  createFontBackgroundColorPlugin(),
  createTablePlugin(),
  createUnderlinePlugin(),
  // new
  createDeserializeDocxPlugin(),
  createJuicePlugin(),
  createHighlightPlugin(),
];

/**
 * Creates plugin and returns
 * @param {object} resolvedPlugins - if not config is passed, default will be used
 */
const createResolvedPlugins = (resolvedPlugins = defaultPlugins) => createPlugins(
  resolvedPlugins,
  {
    components: plateUI,
  },
);

export default createResolvedPlugins;
