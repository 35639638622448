import React from 'react';
import { Button } from '@mui/material';
import styles from '../StudyDocuments.module.scss';

const StudyDocumentsBreadcrumbs = (props) => {
  const {
    foldersNavigation,
    goToTopLevelHandler,
    enterBreadbrumbFolderHandler,
    setFoldersNavigation,
    setSelectedFolder,
  } = props;

  const isCurrentFolder = (idx) => {
    if (idx === foldersNavigation.length - 1) {
      return true;
    }
    return false;
  }

  return (
    <div className={styles.breadcrumbs}>
      <Button
        className={styles.breadcrumbsItem}
        onClick={
          () =>
            // eslint-disable-next-line
            goToTopLevelHandler(setFoldersNavigation, setSelectedFolder)
          // eslint-disable-next-line
        }
      >
        Study Documents
        {foldersNavigation.length > 0 && <img src="../../landing/arrow_right.svg" alt="logo" />}
      </Button>
      {foldersNavigation.length > 0
        && foldersNavigation.map((folder, idx) => (
          <Button
            key={folder}
            className={styles.breadcrumbsItem}
            onClick={() => enterBreadbrumbFolderHandler(folder)}
            disabled={isCurrentFolder(idx)}
            classes={{ disabled: styles.disabled }}
          >
            {folder}
            {!isCurrentFolder(idx) && (
              <img src="../../landing/arrow_right.svg" alt="logo" />
            )}
          </Button>
        ))}
    </div>
  );
};

export default StudyDocumentsBreadcrumbs;
