import Button from '@mui/material/Button';
import { styled } from '@mui/styles';
import { BRAND_COLORS } from '../../../constants';

const ColorButton = styled(Button)(() => ({
  color: '#222222',
  backgroundColor: '#FFFFFF',
  border: `1px solid ${BRAND_COLORS.orange}`,
  borderRadius: '4px',
  marginRight: '12px',
  fontFamily: 'AvenirNextLTPro-Medium',
  marginBottom: '25px',
  '&:hover': {
    backgroundColor: '#F9A868',
    borderRadius: '4px',
    border: '1px solid transparent',
    fontFamily: 'AvenirNextLTPro-Medium',
  },
}));

export default ColorButton;
