import React, { useState, useEffect } from 'react';
import {
  Button,
  Modal,
} from 'react-bootstrap';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import CircularProgress from '@mui/material/CircularProgress';
import { useS3 } from '../../../providers/s3';
import { useAuth } from '../../../providers/auth';

const WarningPrompt = (props) => {
  const {
    show,
    noText,
    noFunc,
    yesFunc,
    yesText,
    description,
  } = props;
  const { cancellingFileUpload } = useS3();

  const { signOut } = useAuth();
  const [loader, setLoader] = useState(false)

  useEffect(() => {
    if (!cancellingFileUpload && loader) {
      setLoader(false)
      noFunc()
      signOut()
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [cancellingFileUpload]);

  const onYes = () => {
    setLoader(true)
    yesFunc()
  }
  return (
    <Modal
      show={show}
      onHide={() => noFunc()}
    >
      <Modal.Header className="documents-oldModal-header" closeButton={() => noFunc()}>
        <Modal.Title style={{ color: '#fff', marginBottom: '2px' }}>
          File upload in progress
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="documents-oldModal-body">
        <Alert className="documents-AlertOnTop" sx={{ fontFamily: 'AvenirNextLTPro-Regular' }} severity="warning">
          <AlertTitle sx={{ fontFamily: 'AvenirNextLTPro-Regular' }}>Warning</AlertTitle>
          { description }
        </Alert>
      </Modal.Body>
      <Modal.Footer className="documents-oldModal-footer">
        <Button
          type="submit"
          variant="primary"
          className="LoginFormSignInButton"
          onClick={() => onYes()}
        >
          { yesText }
          { loader && (
            <CircularProgress
              size={20}
              style={{
                color: '#fff',
                marginLeft: '3px',
                marginTop: '1px',
                position: 'absolute',
              }}
            />
          )}
        </Button>
        <Button
          className="LoginFormOutlineButton"
          type="button"
          variant="light"
          onClick={() => noFunc()}
        >
          { noText }
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default WarningPrompt;
