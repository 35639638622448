import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import {
  FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, FormHelperText, Button, CircularProgress,
} from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { useAuth } from '../providers/auth'
import { BRAND_COLORS, WEEKDAYS } from '../constants';
import { useGraphQL } from '../providers/graphql';
import NotificationsIcon from './components/dropdown/manageNotifications.svg';

const ManageNotifications = function ManageNotifications() {

  const { user } = useAuth()
  const { userPreferences, updateUserPreferences, setUserPreferences } = useGraphQL()
  const [notificationFrequency, setNotificationFrequency] = useState('DAILY')
  const [weekdaysSelection, setWeekdaysSelection] = useState({})
  const [showSpinner, setShowSpinner] = useState(false)
  const [displayMessage, setDisplayMessage] = useState('doNothing');
  const [isImmediate, setIsImmediate] = useState('');

  useEffect(() => {
    setShowSpinner(false)
    if (userPreferences?.emailFrequencyPreferences?.length > 0 && userPreferences?.emailFrequencyPreferences[0] === 'DAILY'
    ) {
      setNotificationFrequency('DAILY')
      setIsImmediate('NOT_IMMEDIATE')
    } else if (userPreferences?.emailFrequencyPreferences?.length > 0 && userPreferences?.emailFrequencyPreferences[0] === 'IMMEDIATE') {
      setNotificationFrequency('IMMEDIATE')
      setIsImmediate('IMMEDIATE')
    } else {
      setNotificationFrequency('CUSTOM')
      setIsImmediate('NOT_IMMEDIATE')
      const days = {}
      if (userPreferences?.emailFrequencyPreferences) {
        userPreferences.emailFrequencyPreferences.map((val) => {
          days[val] = true
          return true
        })
      }
      setWeekdaysSelection(days)
    }
  }, [userPreferences])

  const changeUserPreferences = async () => {
    const frequency = []
    if (notificationFrequency === 'DAILY') {
      frequency.push('DAILY')
    } else if (notificationFrequency === 'IMMEDIATE') {
      frequency.push('IMMEDIATE')
    } else {
      Object.keys(weekdaysSelection).forEach((day) => {
        if (weekdaysSelection[day]) {
          frequency.push(day);
        }
      });
    }
    const {
      id,
      notificationOptIn,
      emailNotificationTime,
      lastUpdatedPasswordAt,
      deleteAccountAt,
    } = userPreferences
    setShowSpinner(true)
    try {
      const updatedPreferences = await updateUserPreferences({
        id,
        userId: user.id,
        notificationOptIn,
        frequency,
        notificationLimit: 'LAST_24H',
        notificationTime: emailNotificationTime,
        region: new Date().getTimezoneOffset(),
        lastUpdatedPasswordAt,
        deleteAccountAt,
        isImmediate,
      });
      setUserPreferences(updatedPreferences.data.updateUserPreferences);
      setDisplayMessage('success');
    } catch (error) {
      setShowSpinner(false);
      setDisplayMessage('error');
    }
  }

  const renderButtonSave = (content, onClick1, onClick2) => <Button key={content} style={{ font: 'normal normal normal 16px/22px AvenirNextLTPro-Medium' }} className="manage-notification-save-button" onClick={() => { onClick1(); onClick2(); }}>{content}</Button>

  const renderButtonWeek = (checked, content, onClick, customCSS = {}) => (
    <Button
      key={content}
      className="manage-notification-weekday-button"
      style={{
        backgroundColor: checked ? '#F9A868' : '#FFFFFF', color: '#222222', font: 'normal normal normal 16px/22px AvenirNextLTPro-Medium', padding: '0.5rem 1.5rem', border: `1px solid ${BRAND_COLORS.orange}`, borderRadius: '4px', letterSpacing: '0.64px', opacity: 1, height: '38px', width: '66px', ...customCSS,
      }}
      onClick={onClick}
    >{content}
    </Button>
  )

  const selectWeekday = (value) => () => {
    if (!weekdaysSelection[value]) {
      setWeekdaysSelection({ ...weekdaysSelection, [value]: true })
    } else {
      setWeekdaysSelection({ ...weekdaysSelection, [value]: false })
    }
  }

  const breadcrumbs = [
    <Link className="study-details-breadcrumb-homelink" key="manage_notification_breadcrumb_1" to="/landing" underline="hover">
      <span><em>Study </em></span>List
    </Link>,

    <Typography className="bc-study-det" key="manage_notification_breadcrumb_2">
      Manage Notifications
    </Typography>,
  ];

  const renderNavigation = () => (
    <Breadcrumbs
      underline="none"
      className="bc-margin-bl"
      separator={<img src="./landing/arrow_right.svg" alt="breadcrumb separator" />}
      aria-label="breadcrumb"
    >
      {breadcrumbs}
    </Breadcrumbs>
  )
  const showUpdatePreferencesResult = (errorAlert) => {
    const message = {
      success: (
        <div className="LoginForm-SuccessMessage successText" style={{ padding: '2px 5px', width: '250%' }}>
          <div className="LoginForm-SuccessMessageText Mng-not-msg-txt">Successfully saved notification settings</div>
        </div>
      ),
      error: (
        <div className="LoginForm-ErrorMessage errorText" style={{ padding: '2px 5px', width: '250%' }}>
          <div className="LoginForm-ErrorMessageText Mng-not-msg-txt">Please try again later</div>
        </div>
      ),
      doNothing: null,
    }
    return (
      <Stack sx={{ width: '100%' }} spacing={2}> {message[errorAlert]} </Stack>
    )
  }

  const renderManageNotificationsForm = () => (
    <FormControl component="fieldset" variant="standard">
      <FormLabel style={{
        font: 'normal normal normal 20px/24px AvenirNextLTPro-Medium', textAlign: 'left', letterSpacing: '0px', color: '#222222DE', opacity: 1, marginBottom: '29px',
      }}
      >How frequently do you want to receive email notifications?
      </FormLabel>
      <RadioGroup
        name="radio-buttons-group"
        value={notificationFrequency}
        onChange={(e) => {
          setNotificationFrequency(e.target.value)
          setIsImmediate('NOT_IMMEDIATE')
        }}
      >
        <FormControlLabel
          key="manage-notification-option-daily"
          sx={{
            '& .MuiTypography-root': {
              fontFamily: 'AvenirNextLTPro-Medium', textAlign: 'left', color: '#222222', letterSpacing: '0px', opacity: 1,
            },
          }}
          value="DAILY"
          control={<Radio style={{ color: BRAND_COLORS.orange }} />}
          label="Daily"
        />
        <FormControlLabel
          key="manage-notification-option-custom"
          sx={{
            '& .MuiTypography-root': {
              fontFamily: 'AvenirNextLTPro-Medium', textAlign: 'left', color: '#222222', letterSpacing: '0px', opacity: 1,
            },
          }}
          value="CUSTOM"
          control={<Radio style={{ color: BRAND_COLORS.orange }} />}
          label="On Selected Days"
        />
      </RadioGroup>
      {notificationFrequency === 'CUSTOM' && (
        <>
          <FormHelperText style={{
            marginLeft: '0px', textAlign: 'left', font: 'normal normal normal 14px/24px AvenirNextLTPro-Regular', letterSpacing: '0px', opacity: 1, color: '#555555',
          }}
          >Please select the days on which you want to receive aggregated email notifications
          </FormHelperText>
          <div style={{
            display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', fontStyle: 'italic', marginTop: '16px',
          }}
          >
            {WEEKDAYS.map(({ value, label }) => renderButtonWeek(weekdaysSelection[value] || false, label, selectWeekday(value), { marginRight: '16px' }))}
          </div>
        </>
      )}
      <RadioGroup
        name="radio-buttons-group"
        value={notificationFrequency}
        onChange={(e) => {
          setNotificationFrequency(e.target.value)
          setIsImmediate(e.target.value)
        }}
      >
        <FormControlLabel
          key="manage-notification-option-custom"
          sx={{
            '& .MuiTypography-root': {
              fontFamily: 'AvenirNextLTPro-Medium', textAlign: 'left', color: '#222222', letterSpacing: '0px', opacity: 1,
            },
          }}
          value="IMMEDIATE"
          control={<Radio style={{ color: BRAND_COLORS.orange }} />}
          label="Immediate"
          data-testid="immediate-notifications-radio-button"
        />
      </RadioGroup>
    </FormControl>
  )

  // Handling the snackbar
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  // Handling the snackbar ENDS

  return (
    <div className="manage-notification">
      {renderNavigation()}
      <div className="manage-notification-svg">
        <img src={NotificationsIcon} alt="Manage Notifications" />
        <span
          className="AvenirNextLTProRegular"
          style={{
            marginLeft: '12px', textAlign: 'left', letterSpacing: '0px', color: '#056A7B', opacity: 1,
          }}
          data-testid="manage-notifications-title"
        >
          Manage Notifications
        </span>
      </div>
      <div className="manage-notification-inner">
        {
          !showSpinner
          && (
          <Snackbar
            style={{ position: 'unset' }}
            open={open}
            autoHideDuration={2500}
            onClose={handleClose}
            message={showUpdatePreferencesResult(displayMessage)}
          />
          )
        }
        {renderManageNotificationsForm()}
        <div className="manage-notification-save-settings">
          {renderButtonSave('Save Settings', changeUserPreferences, handleClick)}
          {showSpinner && <CircularProgress color="inherit" display="inline" />}
        </div>
      </div>
    </div>
  )
}

export default ManageNotifications
