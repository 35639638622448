import {
  Button,
  Modal,
  CloseButton,
  Fade,
} from 'react-bootstrap';

import React, { useState, useEffect } from 'react';

import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import InputLabel from '@mui/material/InputLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { makeStyles } from '@mui/styles';
import './documents.css';
import _ from 'lodash';

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    fontFamily: 'AvenirNextLTPro-Regular',
    width: '100%',
    '&.Mui-focused': {
      backgroundColor: '#fff',
      fontFamily: 'AvenirNextLTPro-Regular',
    },
  },
  focused: {},
  notchedOutline: {},

  label: {
    color: '#FFFFFFDE',
  },
}));

const MultiDocumentUploadModal = ({
  bulkFiles,
  setBulkFiles,
  setFiles,
  setQueueLength,
}) => {

  const [newFileDescription, setNewFileDescription] = useState('');
  const [skipDescription, setSkipDescription] = useState('no');
  const [disableYesBtn, setDisableYesBtn] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    if (bulkFiles.length) {
      let disabled = false
      bulkFiles.forEach((file) => {
        disabled = disabled || file.uploadingSame || file.alreadyInQue || !file.fileTypeAllowed
      })
      setDisableYesBtn(disabled)
    }
  }, [bulkFiles]);

  const handleChangeSkipDescription = (e) => {
    setSkipDescription(e.currentTarget.value)
  }

  const handleUploadSubmit = async () => {
    const files = bulkFiles.map((file) => ({
      file: file.file,
      newFileDescription,
      s3ObjectPath: file.s3ObjectPath,
    }))
    setBulkFiles([])
    setTimeout(() => {
      setFiles((prevFiles) => [...prevFiles, ...files])
      setQueueLength((prev) => prev + files.length)
    }, 500)
  };
  const removeFromFileList = (s3ObjectPath) => {
    const files = bulkFiles.filter((file) => file.s3ObjectPath !== s3ObjectPath)
    setBulkFiles(files)
  };
  const showAlert = (msg1, showMsg2, showX) => (
    <Alert className="file-upload-alert" sx={{ fontFamily: 'AvenirNextLTPro-Regular' }} severity={!showMsg2 && !showX ? 'error' : 'warning'}>
      {msg1}<br />
      { showMsg2 && <span>Click <strong>Yes</strong> to overwrite the file and description.<br /></span> }
      { showX && <span>Click <strong style={{ fontSize: '20px' }}>x</strong> to remove this file.</span> }
    </Alert>
  )
  function fileItem(file) {
    return (
      <div key={file.s3ObjectPath}>
        <div className="file-name">
          <span className="mt-1 file-s3-obj-path">{file.s3ObjectPath}</span>
          {
            bulkFiles.length > 1 && (
              <CloseButton
                size="sm"
                className="mr-1"
                style={{ color: '#FFC455' }}
                onClick={() => removeFromFileList(file.s3ObjectPath)}
              />
            )
          }
        </div>
        {file.existing && !file.uploadingSame && !file.alreadyInQue && showAlert('This file has already been uploaded.', true, true)}
        {file.uploadingSame && showAlert('This file has already been uploading.', false, true)}
        {file.alreadyInQue && showAlert('This file is already in the uploading queue.', false, true)}
        {file.fileTypeAllowed !== true && showAlert('This file type is not allowed for upload.', false, false)}
      </div>
    );
  }
  return (
    <>
      <Fade in={!_.isEmpty(bulkFiles)}>
        <Modal
          show={!_.isEmpty(bulkFiles)}
          onHide={() => setBulkFiles([])}
        >
          <Modal.Header className="documents-oldModal-header" closeButton={() => setBulkFiles([])}>
            <Modal.Title>
              <span className="containerSubTitle">
                Multiple File Upload
              </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="documents-oldModal-body">
            <div className="file-list-grp">
              {
                bulkFiles.map((file) => fileItem(file))
              }
            </div>
            <FormControl>
              <RadioGroup value={skipDescription} onChange={handleChangeSkipDescription}>
                <FormControlLabel
                  style={{ color: '#FFC455' }}
                  control={<Radio style={{ color: '#FFC455' }} />}
                  label="Skip All"
                  value="yes"
                />
                <FormControlLabel
                  style={{ color: '#FFC455' }}
                  control={<Radio style={{ color: '#FFC455' }} />}
                  label="Apply Description to All Files"
                  value="no"
                />
              </RadioGroup>
            </FormControl>
            {skipDescription === 'no' && (
              <form className="form">
                <InputLabel className="inputLabel mt-0" htmlFor="newFileDescription">
                  File Description
                </InputLabel>
                <TextField
                  id="newFileDescription"
                  variant="outlined"
                  multiline
                  placeholder={
                      _.isEmpty(newFileDescription)
                        ? 'file description'
                        : newFileDescription
                    }
                  name="newFileDescription"
                  InputProps={{
                    classes: {
                      root: classes.root,
                      focused: classes.focused,
                    },
                  }}
                  sx={{
                    width: '100%',
                  }}
                  onChange={(e) => setNewFileDescription(e.currentTarget.value)}
                  minRows={3}
                />
              </form>
            )}
          </Modal.Body>

          <Modal.Footer className="documents-oldModal-footer">
            <Button
              type="submit"
              variant="primary"
              disabled={disableYesBtn}
              className="LoginFormSignInButton"
              onClick={() => handleUploadSubmit()}
            >
              Yes
            </Button>
            <Button
              className="LoginFormOutlineButton"
              type="button"
              variant="light"
              onClick={() => setBulkFiles([])}
            >
              No
            </Button>
          </Modal.Footer>
        </Modal>
      </Fade>
    </>
  );
}
export default MultiDocumentUploadModal;
