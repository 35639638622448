import React, { createContext, useState } from 'react'

export const MortalityObservationLegendContext = createContext()

export const MortalityObservationLegendProvider = function ({ children }) {
  const [selectedFateCode, setSelectedFateCode] = useState([])

  return (
    <MortalityObservationLegendContext.Provider
      value={{
        selectedFateCode, setSelectedFateCode,
      }}
    >
      {children}
    </MortalityObservationLegendContext.Provider>
  )
}

export const useMortalityObservationLegendContext = () => {
  const context = React.useContext(MortalityObservationLegendContext)

  if (context === undefined) {
    throw new Error(
      '`useMortalityObservationLegendContext` hook must be used within a `MortalityObservationLegendContextProvider` component',
    )
  }
  return context
}
