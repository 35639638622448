/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import PropTypes from 'prop-types';
import { AgGridReact } from 'ag-grid-react'
import _ from 'lodash'
import {
  Button, Row, Col, Spinner, Card,
} from 'react-bootstrap'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/styles';
import Typography from '@mui/material/Typography';
import {
  FormControl, InputLabel, Select, MenuItem,
} from '@mui/material';
import { useClinicalObservationLegendContext } from '../../../providers/clinicalObservationLegend'
import { formatStudyDayWithDate, getRowsAndColumn } from '../../utils';
import TVCChartSessionSummary from '../TVCChartSessionSummary'
import styles from './StudySummary/StudySummary.module.scss';
import './SessionSummaryPanel.css';

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip
    disableInteractive
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    PopperProps={{
      popperOptions: {
        modifiers: [
          {
            name: 'offset',
            options: { offset: [50, -10] },
          },
        ],
      },
    }}
    classes={{
      popper: className,
    }}
  />
  // eslint-disable-next-line no-unused-vars
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#FFFBEC',
    color: '#222222',
    border: '1px solid #FEE7D5',
    borderRadius: '2px',
    fontSize: '12px',
    top: '20px',
  },
}));
const DisplayLegend = function (props) {
  const { clinicalObservationIndividualGroupsLegend, legends } = props
  let alphabeticalLegend = _.sortBy(clinicalObservationIndividualGroupsLegend, 'key')

  const { selectedCoCodes, setSelectedCoCodes } = useClinicalObservationLegendContext()
  alphabeticalLegend = alphabeticalLegend.filter((item) => legends.includes(item.key))
  return (
    <>
      {
        selectedCoCodes.length > 0 && selectedCoCodes.length < clinicalObservationIndividualGroupsLegend.length
        && (
        <div
          className="blackHyperlink"
          onClick={() => setSelectedCoCodes([])}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              setSelectedCoCodes([]);
            }
          }}
          style={{ cursor: 'pointer' }}
        >
          Restore Default Highlighting
          <hr />
        </div>
        )
      }
      {
        alphabeticalLegend.map((value, index) => {
          const tempIndx = index
          return (
            <LightTooltip
              title={(
                <Typography className="AvenirNextLTProRegular">
                  {value.value}
                </Typography>
              )}
              placement="bottom"
              key={value + tempIndx}
            >
              {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
              <p
                className={selectedCoCodes.length > 0 && !selectedCoCodes.includes(value.key) ? 'lightGrayHyperlink' : 'blackHyperlink'}
                onClick={() => (!selectedCoCodes.includes(value.key) ? setSelectedCoCodes([...selectedCoCodes, value.key]) : setSelectedCoCodes(selectedCoCodes.filter((el) => el !== value.key)))}
                style={{ cursor: 'pointer' }}
                data-toggle="tooltip"
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                  }
                }}
              >
                {value.key}: {value.value.length > 20 ? `${value.value.substr(0, 20)}...` : value.value}
              </p>
            </LightTooltip>
          )
        })
      }
    </>
  )
}
DisplayLegend.propTypes = {
  legends: PropTypes.arrayOf(PropTypes.string),
  clinicalObservationIndividualGroupsLegend: PropTypes.arrayOf(PropTypes.object),
};
const SessionSummaryPanel = function (props) {
  const {
    selectedTab,
    sessionSummaryFetching,
    excludeDosingTimes,
    sessionSummaryGridOptionsForPinnedColumns: gridOptions,

    bliVolumeRows,
    sessionDaysBliVolume,
    bliVolumeStudyDayColumns,

    bliMeanRows,
    sessionDaysBliMean,
    bliMeanStudyDayColumns,

    bliVentralRows,
    sessionDaysBliVentral,
    bliVentralStudyDayColumns,

    bliDorsalRows,
    sessionDaysBliDorsal,
    bliDorsalStudyDayColumns,

    tumorVolumeRows,
    sessionDaysTumorVolume,
    tumorVolumeStudyDayColumns,

    tumorVolumeChangeRows,
    sessionDaysTumorVolumeChange,
    tumorVolumeChangeStudyDayColumns,

    tumorVolumeRightRows,
    sessionDaysTumorVolumeRight,
    tumorVolumeRightStudyDayColumns,

    tumorVolumeLeftRows,
    sessionDaysTumorVolumeLeft,
    tumorVolumeLeftStudyDayColumns,

    bodyWeightRows,
    sessionDaysBodyWeight,
    bodyWeightStudyDayColumns,

    bodyWeightChangeRows,
    sessionDaysBodyWeightChange,
    bodyWeightChangeStudyDayColumns,

    clinicalObservationRows,
    sessionDaysClinicalObservation,
    clinicalObservationStudyDayColumns,

    mortalityObservationRows,
    sessionDaysMortalityObservation,
    mortalityObservationStudyDayColumns,

    allArticles,
    dosingRecordRows,
    dosingStudyDayColumns,
    sessionDaysDosingRecords,

    clinicalObservationIndividualGroupsLegend,
    chartExportAudit,
    tumorVolumeChangeGraphdata,
    studyId,
    modelId,
    createOrUpdateGraphAnnotation,
    listGraphAnnotations,
  } = props

  const showSummaryComponents = useMemo(() => gridOptions && gridOptions.rowData?.length > 0, [gridOptions]);
  const sessionDays = useMemo(() => {
    let sessionDays = [
      ...sessionDaysBliVolume,
      ...sessionDaysBliMean,
      ...sessionDaysBliVentral,
      ...sessionDaysBliDorsal,
      ...sessionDaysTumorVolume,
      ...sessionDaysTumorVolumeChange,
      ...sessionDaysTumorVolumeRight,
      ...sessionDaysTumorVolumeLeft,
      ...sessionDaysBodyWeight,
      ...sessionDaysBodyWeightChange,
      ...sessionDaysClinicalObservation,
      ...sessionDaysMortalityObservation,
      ...sessionDaysDosingRecords,
    ]
    sessionDays = _.uniqWith(sessionDays, _.isEqual)
    sessionDays = _.sortBy(sessionDays, 'day');
    if (excludeDosingTimes) {
      const sessionDayRepeat = {}
      sessionDays = sessionDays.map((sessionDay) => {
        if (!sessionDayRepeat[sessionDay.day]) {
          sessionDayRepeat[sessionDay.day] = []
        }
        sessionDayRepeat[sessionDay.day] = [...sessionDayRepeat[sessionDay.day], sessionDay]
        const val = formatStudyDayWithDate(sessionDay.day, sessionDay.date, sessionDay.time)
        return {
          key: val,
          value: val,
          label: `Day ${sessionDay.day} (${sessionDay.date}) - Session ${sessionDayRepeat[sessionDay.day].length}`,
        }
      })
      return sessionDays
    }
    sessionDays = sessionDays.map((sessionDay) => {
      const val = formatStudyDayWithDate(sessionDay.day, sessionDay.date, sessionDay.time)
      return {
        key: val,
        value: val,
        label: val,
      }
    })
    return sessionDays
  }, [
    excludeDosingTimes,
    sessionDaysBliVolume,
    sessionDaysBliMean,
    sessionDaysBliVentral,
    sessionDaysBliDorsal,
    sessionDaysTumorVolume,
    sessionDaysTumorVolumeChange,
    sessionDaysTumorVolumeRight,
    sessionDaysTumorVolumeLeft,
    sessionDaysBodyWeight,
    sessionDaysBodyWeightChange,
    sessionDaysClinicalObservation,
    sessionDaysMortalityObservation,
    sessionDaysDosingRecords,
  ])
  const pinnedColumns = gridOptions.columnDefs
  const fixedRowData = gridOptions.rowData
  const gridRef = useRef();
  const spinner = (
    <Row className="justify-content-center">
      <Button variant="primary" disabled>
        <Spinner
          as="span"
          animation="grow"
          size="sm"
          aria-hidden="true"
        />
        Loading Session Summary Metrics...
      </Button>
    </Row>
  )
  const [sessionDay, setSessionDay] = useState('');
  const [legends, setLegends] = useState([]);
  const [referenceLineValue, setReferenceLineValue] = useState('');
  const [maximumCutoffValue, setMaximumCutoffValue] = useState(100);
  const [isReferenceLineCheckboxChecked, setReferenceLineCheckboxChecked] = useState(false);
  const [isMaximumCutoffCheckboxChecked, setIsMaximumCutoffCheckboxChecked] = useState(true);
  const [isReferenceLineCheckboxCheckedToPass, setReferenceLineCheckboxCheckedToPass] = useState(false);
  const [isMaximumCutoffCheckboxCheckedToPass, setIsMaximumCutoffCheckboxCheckedToPass] = useState(true);
  const [referenceLineValueToPass, setReferenceLineValueToPass] = useState('');
  const [maximumCutoffValueToPass, setMaximumCutoffValueToPass] = useState(100);
  const [graphDataLoader, setGraphDataLoader] = useState(false);
  const handleReferenceLineInputChange = (e) => {
    setReferenceLineValue(e.target.value.replace('%', ''));
  };
  const handleMaximumCutoffInputChange = (e) => {
    setMaximumCutoffValue(e.target.value.replace('%', ''));
  };
  const handleCheckboxChangeRefrenceLine = () => {
    setReferenceLineCheckboxChecked(!isReferenceLineCheckboxChecked);
  };
  const handleCheckboxChangeMaximumCutoff = () => {
    setIsMaximumCutoffCheckboxChecked(!isMaximumCutoffCheckboxChecked);
  };
  const handleApplyButtonClick = () => {
    setGraphDataLoader(true);

    // Reference Line input object
    const inputObjectReferenceLine = {
      studyId,
      modelId,
      graphName: 'TVC-SessionSummaryWaterfall',
      taskType: 'reference-line',
      value: referenceLineValue,
      isActive: isReferenceLineCheckboxChecked,
    };

    // Set Reference Line value to pass if checkbox is checked
    if (isReferenceLineCheckboxChecked) {
      setReferenceLineValueToPass(referenceLineValue);
      setReferenceLineCheckboxCheckedToPass(true);
    } else {
      setReferenceLineValueToPass('');
      setReferenceLineCheckboxCheckedToPass(false);
    }

    // Create or update Graph Annotation for Reference Line
    createOrUpdateGraphAnnotation(inputObjectReferenceLine)
      .then(() => {

        // Maximum Cutoff input object
        const inputObjectMaximumCutoff = {
          studyId,
          modelId,
          graphName: 'TVC-SessionSummaryWaterfall',
          taskType: 'max-cutoff',
          value: maximumCutoffValue,
          isActive: isMaximumCutoffCheckboxChecked,
        };

        // Set Maximum Cutoff value to pass if checkbox is checked
        if (isMaximumCutoffCheckboxChecked) {
          setMaximumCutoffValueToPass(maximumCutoffValue);
          setIsMaximumCutoffCheckboxCheckedToPass(true);
        } else {
          setMaximumCutoffValueToPass(100);
          setIsMaximumCutoffCheckboxCheckedToPass(false);
        }

        // Create or update Graph Annotation for Maximum Cutoff
        createOrUpdateGraphAnnotation(inputObjectMaximumCutoff)
          .then(() => {
            setGraphDataLoader(false);
          })
          .catch((error) => {
            // Handle the error if needed
            console.error(error);
            setGraphDataLoader(false);
          });
      })
      .catch((error) => {
        // Handle the error if needed
        console.error(error);
        setGraphDataLoader(false);
      });
  };

  const handleChange = useCallback((event) => {
    const studyDay = event.target.value
    if (gridRef?.current?.api) {
      const { rows, columns, clinicalObservationLegends } = getRowsAndColumn({
        studyDay,

        bliVolumeRows,
        bliVolumeStudyDayColumns,

        bliMeanRows,
        bliMeanStudyDayColumns,

        bliVentralRows,
        bliVentralStudyDayColumns,

        bliDorsalRows,
        bliDorsalStudyDayColumns,

        tumorVolumeRows,
        tumorVolumeStudyDayColumns,

        tumorVolumeChangeRows,
        tumorVolumeChangeStudyDayColumns,

        tumorVolumeRightRows,
        tumorVolumeRightStudyDayColumns,

        tumorVolumeLeftRows,
        tumorVolumeLeftStudyDayColumns,

        bodyWeightRows,
        bodyWeightStudyDayColumns,

        bodyWeightChangeRows,
        bodyWeightChangeStudyDayColumns,

        clinicalObservationRows,
        clinicalObservationStudyDayColumns,

        mortalityObservationRows,
        mortalityObservationStudyDayColumns,

        dosingRecordRows,
        dosingStudyDayColumns,

        fixedRowData,
        allArticles,
      })
      gridRef.current.api.setRowData(rows)
      gridRef.current.api.setColumnDefs([...pinnedColumns, ...columns])
      setLegends(clinicalObservationLegends)
    }
    setSessionDay(studyDay);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [
    pinnedColumns,

    bliVolumeRows,
    bliVolumeStudyDayColumns,

    bliMeanRows,
    bliMeanStudyDayColumns,

    bliVentralRows,
    bliVentralStudyDayColumns,

    bliDorsalRows,
    bliDorsalStudyDayColumns,

    tumorVolumeRows,
    tumorVolumeStudyDayColumns,

    tumorVolumeChangeRows,
    tumorVolumeChangeStudyDayColumns,

    tumorVolumeRightRows,
    tumorVolumeRightStudyDayColumns,

    tumorVolumeLeftRows,
    tumorVolumeLeftStudyDayColumns,

    bodyWeightRows,
    bodyWeightStudyDayColumns,

    bodyWeightChangeRows,
    bodyWeightChangeStudyDayColumns,

    clinicalObservationRows,
    clinicalObservationStudyDayColumns,

    mortalityObservationRows,
    mortalityObservationStudyDayColumns,

    dosingRecordRows,
    dosingStudyDayColumns,

    fixedRowData,
    allArticles,
  ]);

  useEffect(() => {
    const fetchGraphAnnotations = async () => {
      try {
        const response = await listGraphAnnotations(
          studyId,
          modelId,
          'TVC-SessionSummaryWaterfall',
          '',
        );
        handleApiResponse(response);
      } catch (error) {
        console.error('API Error:', error);
      }
    };

    const handleApiResponse = (response) => {
      if (response?.data?.listGraphAnnotationss?.items?.length > 0) {
        response.data.listGraphAnnotationss.items.forEach(handleAnnotationItem);
      }
    };

    const handleAnnotationItem = (item) => {
      console.log('item.value', typeof (item.value));
      if (item.taskType === 'reference-line') {
        setReferenceLineValueToPass(item.isActive ? item.value : 0);
        setReferenceLineValue(item.value);
        setReferenceLineCheckboxCheckedToPass(item.isActive);
        setReferenceLineCheckboxChecked(item.isActive);
      } else if (item.taskType === 'max-cutoff') {
        setMaximumCutoffValueToPass(item.value);
        setMaximumCutoffValue(item.value);
        setIsMaximumCutoffCheckboxChecked(item.isActive);
        setIsMaximumCutoffCheckboxCheckedToPass(item.isActive);
      }
    };

    const handleTimeout = () => {
      if (
        !sessionDay
        && sessionDays.length
        && gridOptions.columnDefs.length
        && gridOptions.rowData.length
        && selectedTab === 'session-summary'
        && gridRef?.current?.api
      ) {
        handleChange({ target: { value: sessionDays[sessionDays.length - 1].value } });
      }
    };

    fetchGraphAnnotations();

    const timeoutId = setTimeout(handleTimeout, 200);

    return () => clearTimeout(timeoutId);
  }, [sessionDays, handleChange, gridOptions.columnDefs, gridOptions.rowData, selectedTab, listGraphAnnotations, studyId, modelId, sessionDay]);

  return (
    <>
      {
      showSummaryComponents && (
        <>
          <hr className="mlr-10" />
          <Row style={sessionSummaryFetching ? { display: 'none' } : {}}>
            <Col md="10">
              <h4 className="text-center text-md-left" style={{ display: 'flex', alignItems: 'center' }}>
                Session Summary
                <FormControl style={{ marginLeft: 15 }} size="small">
                  <InputLabel id="demo-simple-select-label">Session Days</InputLabel>
                  <Select
                    labelId="sessionDay-select-label"
                    id="sessionDay-select"
                    value={sessionDay}
                    label="Session Days"
                    onChange={handleChange}
                    style={{
                      width: '300px',
                    }}
                  >
                    {
                      sessionDays.map((item) => (
                        <MenuItem key={item.key} value={item.value}>{item.label}</MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </h4>
              <div
                className="ag-theme-alpine"
                style={{
                  width: '100%',
                  height: '450px',
                  margin: 'auto',
                }}
              >
                <AgGridReact
                  ref={gridRef}
                  gridOptions={gridOptions}
                />
              </div>
            </Col>
            <Col md="2">
              <h4 style={{ marginBottom: 20 }}>&nbsp;</h4>
              <div
                className="ag-theme-alpine"
                style={{
                  width: '100%',
                  height: '450px',
                  margin: 'auto',
                }}
              >
                <Card className="legend-list-card">
                  <Card.Header>
                    <h5 className="studyDetails-legendsCardHeader text-center text-md-left" id="mo_legend">Legend</h5>
                  </Card.Header>
                  <Card.Body className="legend-list">
                    <DisplayLegend clinicalObservationIndividualGroupsLegend={clinicalObservationIndividualGroupsLegend} legends={legends} />
                  </Card.Body>
                </Card>
              </div>
            </Col>
          </Row>
          {
            tumorVolumeChangeStudyDayColumns[sessionDay] && sessionDaysTumorVolumeChange.length && !graphDataLoader ? (
              <Row
                style={{
                  width: '100%',
                  height: '550px',
                  margin: 'auto',
                  marginTop: '3%',
                  marginBottom: '3%',
                }}
                className="summary-chart"
              >
                <Col md="12" style={{ marginTop: 0 }}>
                  <TVCChartSessionSummary
                    studyId={studyId}
                    modelId={modelId}
                    margin={{ r: 0, t: 30, pad: 0 }}
                    divId="tumor-volume-change-session-summary"
                    sessionDay={sessionDay}
                    chartExportAudit={chartExportAudit}
                    tumorVolumeChangeGraphdata={tumorVolumeChangeGraphdata}
                    sessionDaysTumorVolumeChange={sessionDaysTumorVolumeChange}
                    tumorVolumeChangeStudyDayColumns={tumorVolumeChangeStudyDayColumns}
                    referenceLinePercentage={referenceLineValueToPass}
                    isReferenceLineCheckboxChecked={isReferenceLineCheckboxCheckedToPass}
                    isMaximumCutoffCheckboxChecked={isMaximumCutoffCheckboxCheckedToPass}
                    maximumCutoffValue={maximumCutoffValueToPass}
                    setMaximumCutoffValue={setMaximumCutoffValue}
                    setMaximumCutoffValueToPass={setMaximumCutoffValueToPass}
                  />
                  {/* Reference Line Section */}
                  <div className="reference-line-container">
                    <div className="maxcut-line-label">Maximum Cutoff (%):</div>
                    <div className="reference-line-label">Reference Line (%):</div>
                    <div className="input-container">
                      <div className="label">Value</div>
                      <input
                        type="text"
                        className={`reference-line-input ${isMaximumCutoffCheckboxChecked ? '' : 'disabled-input'}`}
                        value={`${maximumCutoffValue}%`}
                        onChange={handleMaximumCutoffInputChange}
                        disabled={!isMaximumCutoffCheckboxChecked}
                      />
                      <input
                        type="text"
                        className={`maxcut-line-input ${isReferenceLineCheckboxChecked ? '' : 'disabled-input'}`}
                        value={`${referenceLineValue}%`}
                        onChange={handleReferenceLineInputChange}
                        disabled={!isReferenceLineCheckboxChecked}
                      />

                    </div>
                    <div className="checkbox-apply-container">
                      <div className="on-off-label">On / Off</div>
                      <div className="checkbox-container">
                        <input type="checkbox" checked={isMaximumCutoffCheckboxChecked} onChange={handleCheckboxChangeMaximumCutoff} />

                      </div>
                      <div className="maxcut-checkbox-container">
                        <input type="checkbox" checked={isReferenceLineCheckboxChecked} onChange={handleCheckboxChangeRefrenceLine} />
                      </div>
                    </div>
                  </div>
                  <div
                    className="apply-button"
                    onClick={handleApplyButtonClick}
                    role="button"
                    tabIndex={0}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === 'Space') {
                        handleApplyButtonClick();
                      }
                    }}
                  >
                    Apply
                  </div>

                </Col>
              </Row>
            ) : null
          }
          {
            graphDataLoader ? (
              <center>
                <Button variant="primary" disabled>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    aria-hidden="true"
                  />
                  Loading Graph Data...
                </Button>
              </center>
            ) : null
          }
        </>
      )
      }
      {!showSummaryComponents && !sessionSummaryFetching && (
        <div className={styles.noDataTextLeft}>
          No Data Available
        </div>
      )}
      {sessionSummaryFetching && spinner}
    </>
  )
}
SessionSummaryPanel.propTypes = {
  sessionSummaryFetching: PropTypes.bool,
  selectedTab: PropTypes.string,
  excludeDosingTimes: PropTypes.bool,
  sessionSummaryGridOptionsForPinnedColumns: PropTypes.object,

  bliVolumeRows: PropTypes.object,
  sessionDaysBliVolume: PropTypes.arrayOf(PropTypes.object),
  bliVolumeStudyDayColumns: PropTypes.object,

  bliMeanRows: PropTypes.object,
  sessionDaysBliMean: PropTypes.arrayOf(PropTypes.object),
  bliMeanStudyDayColumns: PropTypes.object,

  bliVentralRows: PropTypes.object,
  sessionDaysBliVentral: PropTypes.arrayOf(PropTypes.object),
  bliVentralStudyDayColumns: PropTypes.object,

  bliDorsalRows: PropTypes.object,
  sessionDaysBliDorsal: PropTypes.arrayOf(PropTypes.object),
  bliDorsalStudyDayColumns: PropTypes.object,

  tumorVolumeRows: PropTypes.object,
  sessionDaysTumorVolume: PropTypes.arrayOf(PropTypes.object),
  tumorVolumeStudyDayColumns: PropTypes.object,

  tumorVolumeChangeRows: PropTypes.object,
  sessionDaysTumorVolumeChange: PropTypes.arrayOf(PropTypes.object),
  tumorVolumeChangeStudyDayColumns: PropTypes.object,

  tumorVolumeRightRows: PropTypes.object,
  sessionDaysTumorVolumeRight: PropTypes.arrayOf(PropTypes.object),
  tumorVolumeRightStudyDayColumns: PropTypes.object,

  tumorVolumeLeftRows: PropTypes.object,
  sessionDaysTumorVolumeLeft: PropTypes.arrayOf(PropTypes.object),
  tumorVolumeLeftStudyDayColumns: PropTypes.object,

  bodyWeightRows: PropTypes.object,
  sessionDaysBodyWeight: PropTypes.arrayOf(PropTypes.object),
  bodyWeightStudyDayColumns: PropTypes.object,

  bodyWeightChangeRows: PropTypes.object,
  sessionDaysBodyWeightChange: PropTypes.arrayOf(PropTypes.object),
  bodyWeightChangeStudyDayColumns: PropTypes.object,

  clinicalObservationRows: PropTypes.object,
  sessionDaysClinicalObservation: PropTypes.arrayOf(PropTypes.object),
  clinicalObservationStudyDayColumns: PropTypes.object,

  mortalityObservationRows: PropTypes.object,
  sessionDaysMortalityObservation: PropTypes.arrayOf(PropTypes.object),
  mortalityObservationStudyDayColumns: PropTypes.object,

  allArticles: PropTypes.object,
  dosingRecordRows: PropTypes.object,
  dosingStudyDayColumns: PropTypes.object,
  sessionDaysDosingRecords: PropTypes.arrayOf(PropTypes.object),

  clinicalObservationIndividualGroupsLegend: PropTypes.arrayOf(PropTypes.object),
  createOrUpdateGraphAnnotation: PropTypes.object,
  listGraphAnnotations: PropTypes.object,
};
export default SessionSummaryPanel
