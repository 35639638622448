import React, { useEffect, useState } from 'react';
import { Select, MenuItem } from '@mui/material';
import { useHistory } from 'react-router';
import { v4 as uuidv4 } from 'uuid';

// Function to get unique languages from versionList
const getUniqueLanguages = (versionList) => {
  const uniqueLangs = new Map();

  versionList.forEach((obj) => {
    const { lang, disable } = obj;
    const existingObj = uniqueLangs.get(lang);

    // If the language is not already encountered or the current object has "disable": false,
    // update the map with the current object for that language.
    if (!existingObj || !disable) {
      uniqueLangs.set(lang, obj);
    }
  });

  // Return the values of the map (i.e., the objects with unique languages and "disable": false).
  return Array.from(uniqueLangs.values());
};

// Render select list for docs version, versionList here must be sorted in descending order.
export default ({ versionList, defaultValue }) => {
  const history = useHistory();
  const [uniqueLanguages, setUniqueLanguages] = useState(getUniqueLanguages(versionList));

  const handleChange = (_, { props: { value } }) => {
    history.push(`.${history.location.pathname}`, { lang: value });
  };

  useEffect(() => {
    // This will recompute the unique languages and update the select list whenever the versionList changes.
    setUniqueLanguages(getUniqueLanguages(versionList));
  }, [versionList]);

  return (
    <Select
      id={`select-${defaultValue}`}
      key={`select-${uuidv4()}`}
      defaultValue={defaultValue}
      onChange={handleChange}
      style={{
        // position: 'absolute',
        // left: 'calc(67%)',
        top: 'calc(1%)',
        background: 'white',
        height: '30px',
        width: '190px',
        maxWidth: '190px',
      }}
      variant="outlined"
    >
      {uniqueLanguages.map(({ lang, disable }) => (
        <MenuItem key={uuidv4()} name={lang} value={lang} disabled={disable}>
          {lang}
        </MenuItem>
      ))}
    </Select>
  );
};
