import { ThemeProvider, createTheme, StyledEngineProvider } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { LicenseManager } from 'ag-grid-enterprise';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { AuthState } from '@aws-amplify/ui-components';
import { Container, Row, Col } from 'react-bootstrap';
import { useLocation } from 'react-router-dom'
import _ from 'lodash';
import { useAuth } from './providers/auth';
import Routing from './resources/Routing';
import NavHeader from './resources/NavHeader';
import NavFooter from './resources/NavFooter';
import { useGraphQL } from './providers/graphql';
import FeedbackModal from './resources/Feedback/Feedback';
import ChangePassword from './resources/components/auth/ChangePassword';

const theme = createTheme();

LicenseManager.setLicenseKey('Using_this_{AG_Grid}_Enterprise_key_{AG-054105}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{JSR_(Sunnyvale,_CA)}_is_granted_a_{Multiple_Applications}_Developer_License_for_{5}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_{AG_Grid}_Enterprise___This_key_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{31_March_2025}____[v3]_[01]_MTc0MzM3NTYwMDAwMA==7f3b1c516a871956637bfe8a0f32fd32');

const App = function App() {
  const { user, authState } = useAuth();
  const { setStudies } = useGraphQL();
  const [showHelpText, setHelpText] = useState(false);
  const [showFeedbackForm, setFeedbackForm] = useState(false);
  const [showChangePassword, setChangePassword] = useState(false);
  const location = useLocation()

  useEffect(() => {
    if (authState === AuthState.SignedOut) {
      setStudies([]);
    }
  }, [authState, setStudies]);

  const toggleSideBar = () => {
    setHelpText(!showHelpText);
  };

  const toggleFeedbackForm = () => {
    setFeedbackForm(!showFeedbackForm);
  };

  const toggleChangePassword = () => {
    setChangePassword(!showChangePassword);
  };
  const hideNavBarAndFooter = () => !['/termsofuse', '/privacypolicy', '/acceptableusepolicy'].includes(location.pathname)

  return (
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <Container
          fluid
          className="App"
          style={{ min_height: '100%', position: 'relative' }}
        >
          {
            authState === AuthState.SignedIn && !_.isEmpty(user) && hideNavBarAndFooter()
              ? (
                <NavHeader
                  toggleSideBar={toggleSideBar}
                  toggleFeedbackForm={toggleFeedbackForm}
                  toggleChangePassword={toggleChangePassword}
                />
              ) : null
          }
          <Row>
            <Col id="page-content-wrapper">
              <Routing toggleChangePassword={toggleChangePassword} />
            </Col>
            <FeedbackModal showFeedbackForm={showFeedbackForm} toggleFeedbackForm={toggleFeedbackForm} />
            <ChangePassword showChangePassword={showChangePassword} toggleChangePassword={toggleChangePassword} />
          </Row>
          {/* {authState === AuthState.SignedIn && !_.isEmpty(user) && <NavFooter />} */}
        </Container>

        {hideNavBarAndFooter() && <NavFooter />}
      </StyledEngineProvider>
    </ThemeProvider>
  );
};

export default App;
