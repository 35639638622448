import React, { useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

const StudyDocumentsTable = function (props) {
  const { agGridOptions } = props;
  const [rowsCount, setRowsCount] = useState(0);
  const [tableHeight, setTableHeight] = useState('300px');

  useEffect(() => {
    if (agGridOptions.rowData && agGridOptions.rowData.length > 0) {
      setRowsCount(agGridOptions.rowData.length);
    }
  }, [agGridOptions]);

  useEffect(() => {
    switch (true) {
      case rowsCount === 0:
        setTableHeight('300px');
        break;
      case rowsCount === 1:
        setTableHeight('115px');
        break;
      case rowsCount === 2:
        setTableHeight('175px');
        break;
      case rowsCount === 3:
        setTableHeight('238px');
        break;
      case rowsCount === 4:
        setTableHeight('300px');
        break;
      case rowsCount === 5:
        setTableHeight('360px');
        break;
      case rowsCount === 6:
        setTableHeight('425px');
        break;
      case rowsCount >= 7:
        setTableHeight('485px');
        break;
      default:
        setTableHeight('300px');
    }
  }, [rowsCount]);

  return (
    <div
      className="ag-theme-alpine"
      style={{ height: tableHeight, width: '100%', margin: 'auto' }}
      data-testid="study-documents-table"
    >
      <AgGridReact
        headerHeight="50"
        rowHeight="50"
        rowSelection="multiple"
        suppressRowClickSelection
        {...agGridOptions}
      />
    </div>
  );
};

export default StudyDocumentsTable;
