import React, { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { TextField } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import { makeStyles } from '@mui/styles';

import { useAuth } from '../../../providers/auth';
import { useSnackBar } from '../../../providers/snackbar';

import isValidPassword from './utils';
import { CUSTOM_BOX_STYLE } from '../../../constants';

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    fontFamily: 'AvenirNextLTPro-Regular',
    width: '400px',
    '&.Mui-focused': {
      backgroundColor: '#fff',
      fontFamily: 'AvenirNextLTPro-Regular',
    },
  },
  focused: {},
  notchedOutline: {},

  label: {
    color: '#FFFFFFDE',
  },
}));

const ChangePasswordModal = function ChangePassword(props) {

  const { showChangePassword, toggleChangePassword } = props;

  const { changeUserPassword } = useAuth();
  const { changeState, closeAlert, openAlert } = useSnackBar();

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = React.useState(false);
  const classes = useStyles();

  const map = {
    currentPassword: setCurrentPassword,
    newPassword: setNewPassword,
    confirmPassword: setConfirmPassword,
  };

  const toggleChangePasswordForm = () => {
    toggleChangePassword();
  };

  const handleOnChange = (e) => {
    map[e.target.name](e.target.value);
  };

  const onHide = () => {
    closeAlert();
    setCurrentPassword('');
    setNewPassword('');
    setConfirmPassword('');
    toggleChangePasswordForm();
  }

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      setLoading(true);
      /* setShowAlert(false); */
      await changeUserPassword(currentPassword, newPassword);
      /* onHide(); */
      changeState('success', 'Password successfully changed', true);
    } catch (error) {
      if (error.code === 'NotAuthorizedException') {
        changeState('error', 'Incorrect password', false);
      } else {
        changeState('error', error.message, false);
      }
      openAlert();
    } finally {
      setLoading(false);
    }
  };

  const isPasswordMatch = () => {
    if (newPassword.length && confirmPassword.length && (newPassword === confirmPassword)) {
      return true;
    }
    return false;
  };

  const isSubmitDisabled = () => {
    if (!currentPassword.length || !newPassword.length || !confirmPassword.length) {
      return true;
    }
    return !isPasswordMatch() || !isValidPassword(newPassword);
  };

  const displayConfirmError = () => {
    if (!newPassword.length || !confirmPassword.length) {
      return false;
    }
    return !isPasswordMatch();
  };

  return (
    <Modal open={showChangePassword} onClose={onHide} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box sx={CUSTOM_BOX_STYLE}>
        <form className="form" onSubmit={handleOnSubmit}>
          <span className="containerTitle">Change Password</span>
          <div className="inputField">
            <InputLabel className="inputLabel" htmlFor="email">
              Current Password
            </InputLabel>
            <TextField
              id="currentPassword"
              variant="outlined"
              placeholder="Enter your current password"
              name="currentPassword"
              type="password"
              value={currentPassword}
              required
              InputProps={{
                classes: {
                  root: classes.root,
                  focused: classes.focused,
                },
              }}
              sx={{
                width: '100%',
              }}
              onChange={(event) => handleOnChange(event)}
            />
          </div>
          <div className="inputField">
            <InputLabel className="inputLabel" htmlFor="email">
              New Password
            </InputLabel>
            <TextField
              id="newPassword"
              variant="outlined"
              placeholder="Enter your new password"
              name="newPassword"
              type="password"
              value={newPassword}
              required
              InputProps={{
                classes: {
                  root: classes.root,
                  focused: classes.focused,
                },
              }}
              sx={{
                width: '100%',
              }}
              onChange={(event) => handleOnChange(event)}
            />
          </div>
          <p
            style={{
              color: '#E2AAA0',
              display: !isValidPassword(newPassword) ? 'block' : 'none',
            }}
          >
            Password must have at least 15 characters, 1 lowercase character, 1 uppercase character, 1 number, 1 special character
          </p>
          <div className="inputField">
            <InputLabel className="inputLabel" htmlFor="email">
              Confirm Password
            </InputLabel>
            <TextField
              id="confirmPassword"
              variant="outlined"
              placeholder="Re-enter your new password"
              name="confirmPassword"
              type="password"
              value={confirmPassword}
              required
              InputProps={{
                classes: {
                  root: classes.root,
                  focused: classes.focused,
                },
              }}
              sx={{
                width: '100%',
              }}
              onChange={(event) => handleOnChange(event)}
            />
          </div>
          <p
            style={{
              color: '#E2AAA0',
              display: displayConfirmError() ? 'block' : 'none',
            }}
          >
            The new passwords do not match
          </p>
          <Button
            className="LoginFormSignInButtonChangePassword"
            type="submit"
            variant="primary"
            onClick={handleOnSubmit}
            disabled={isSubmitDisabled()}
            data-test-id="authSignInBtn"
          >
            {loading && <CircularProgress size={20} />} Change Password
          </Button>

          <Button
            className="LoginFormOutlineButton"
            type="button"
            variant="light"
            onClick={onHide}
          >
            Close
          </Button>
        </form>
      </Box>
    </Modal>
  );
};

export default ChangePasswordModal;
