const TermsContent = {
  terms: {
    title: 'Terms and Conditions',
    version: '1.0.2',
    content: `<html>
    <head>
      <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
      <style type="text/css">
        ol.lst-kix_list_7-0 {
          list-style-type: none;
        }
        .lst-kix_list_2-1 > li {
          counter-increment: lst-ctn-kix_list_2-1;
        }
        ol.lst-kix_list_38-6 {
          list-style-type: none;
        }
        ol.lst-kix_list_38-5 {
          list-style-type: none;
        }
        ol.lst-kix_list_38-8 {
          list-style-type: none;
        }
        .lst-kix_list_21-8 > li {
          counter-increment: lst-ctn-kix_list_21-8;
        }
        ol.lst-kix_list_9-0.start {
          counter-reset: lst-ctn-kix_list_9-0 0;
        }
        ol.lst-kix_list_38-7 {
          list-style-type: none;
        }
        ol.lst-kix_list_38-2 {
          list-style-type: none;
        }
        ol.lst-kix_list_38-1 {
          list-style-type: none;
        }
        ol.lst-kix_list_38-4 {
          list-style-type: none;
        }
        ol.lst-kix_list_38-3 {
          list-style-type: none;
        }
        ol.lst-kix_list_38-0 {
          list-style-type: none;
        }
        ol.lst-kix_list_13-4.start {
          counter-reset: lst-ctn-kix_list_13-4 0;
        }
        ol.lst-kix_list_40-3.start {
          counter-reset: lst-ctn-kix_list_40-3 0;
        }
        .lst-kix_list_13-0 > li {
          counter-increment: lst-ctn-kix_list_13-0;
        }
        ol.lst-kix_list_20-2.start {
          counter-reset: lst-ctn-kix_list_20-2 0;
        }
        .lst-kix_list_5-0 > li {
          counter-increment: lst-ctn-kix_list_5-0;
        }
        ol.lst-kix_list_2-3.start {
          counter-reset: lst-ctn-kix_list_2-3 0;
        }
        ol.lst-kix_list_37-2.start {
          counter-reset: lst-ctn-kix_list_37-2 0;
        }
        ol.lst-kix_list_7-5 {
          list-style-type: none;
        }
        ol.lst-kix_list_7-6 {
          list-style-type: none;
        }
        ol.lst-kix_list_7-7 {
          list-style-type: none;
        }
        ol.lst-kix_list_7-8 {
          list-style-type: none;
        }
        ol.lst-kix_list_7-1 {
          list-style-type: none;
        }
        ol.lst-kix_list_7-2 {
          list-style-type: none;
        }
        ol.lst-kix_list_7-3 {
          list-style-type: none;
        }
        ol.lst-kix_list_7-4 {
          list-style-type: none;
        }
        ol.lst-kix_list_23-2.start {
          counter-reset: lst-ctn-kix_list_23-2 0;
        }
        .lst-kix_list_40-1 > li {
          counter-increment: lst-ctn-kix_list_40-1;
        }
        ol.lst-kix_list_5-3.start {
          counter-reset: lst-ctn-kix_list_5-3 0;
        }
        .lst-kix_list_38-6 > li {
          counter-increment: lst-ctn-kix_list_38-6;
        }
        .lst-kix_list_4-3 > li {
          counter-increment: lst-ctn-kix_list_4-3;
        }
        ol.lst-kix_list_17-1.start {
          counter-reset: lst-ctn-kix_list_17-1 0;
        }
        .lst-kix_list_24-7 > li {
          counter-increment: lst-ctn-kix_list_24-7;
        }
        .lst-kix_list_18-8 > li {
          counter-increment: lst-ctn-kix_list_18-8;
        }
        ol.lst-kix_list_8-8.start {
          counter-reset: lst-ctn-kix_list_8-8 0;
        }
        ol.lst-kix_list_26-7.start {
          counter-reset: lst-ctn-kix_list_26-7 0;
        }
        .lst-kix_list_7-2 > li {
          counter-increment: lst-ctn-kix_list_7-2;
        }
        ol.lst-kix_list_10-4.start {
          counter-reset: lst-ctn-kix_list_10-4 0;
        }
        .lst-kix_list_24-7 > li:before {
          content: "" counter(lst-ctn-kix_list_24-0, decimal) "."
            counter(lst-ctn-kix_list_24-1, decimal) "."
            counter(lst-ctn-kix_list_24-2, decimal) "."
            counter(lst-ctn-kix_list_24-3, decimal) "."
            counter(lst-ctn-kix_list_24-4, decimal) "."
            counter(lst-ctn-kix_list_24-5, decimal) "."
            counter(lst-ctn-kix_list_24-6, decimal) "."
            counter(lst-ctn-kix_list_24-7, decimal) ". ";
        }
        ol.lst-kix_list_16-5 {
          list-style-type: none;
        }
        .lst-kix_list_1-4 > li {
          counter-increment: lst-ctn-kix_list_1-4;
        }
        ul.lst-kix_list_27-0 {
          list-style-type: none;
        }
        ol.lst-kix_list_16-6 {
          list-style-type: none;
        }
        ul.lst-kix_list_27-1 {
          list-style-type: none;
        }
        .lst-kix_list_24-8 > li:before {
          content: "" counter(lst-ctn-kix_list_24-0, decimal) "."
            counter(lst-ctn-kix_list_24-1, decimal) "."
            counter(lst-ctn-kix_list_24-2, decimal) "."
            counter(lst-ctn-kix_list_24-3, decimal) "."
            counter(lst-ctn-kix_list_24-4, decimal) "."
            counter(lst-ctn-kix_list_24-5, decimal) "."
            counter(lst-ctn-kix_list_24-6, decimal) "."
            counter(lst-ctn-kix_list_24-7, decimal) "."
            counter(lst-ctn-kix_list_24-8, decimal) ". ";
        }
        ol.lst-kix_list_16-7 {
          list-style-type: none;
        }
        ol.lst-kix_list_1-6.start {
          counter-reset: lst-ctn-kix_list_1-6 0;
        }
        ul.lst-kix_list_27-2 {
          list-style-type: none;
        }
        ol.lst-kix_list_16-8 {
          list-style-type: none;
        }
        ol.lst-kix_list_9-5.start {
          counter-reset: lst-ctn-kix_list_9-5 0;
        }
        ol.lst-kix_list_16-1 {
          list-style-type: none;
        }
        ol.lst-kix_list_40-8.start {
          counter-reset: lst-ctn-kix_list_40-8 0;
        }
        ol.lst-kix_list_16-2 {
          list-style-type: none;
        }
        ol.lst-kix_list_16-3 {
          list-style-type: none;
        }
        ol.lst-kix_list_16-4 {
          list-style-type: none;
        }
        .lst-kix_list_24-2 > li:before {
          content: "" counter(lst-ctn-kix_list_24-0, decimal) "."
            counter(lst-ctn-kix_list_24-1, decimal) "."
            counter(lst-ctn-kix_list_24-2, decimal) ". ";
        }
        ol.lst-kix_list_16-0 {
          list-style-type: none;
        }
        ol.lst-kix_list_20-7.start {
          counter-reset: lst-ctn-kix_list_20-7 0;
        }
        .lst-kix_list_24-3 > li:before {
          content: "" counter(lst-ctn-kix_list_24-0, decimal) "."
            counter(lst-ctn-kix_list_24-1, decimal) "."
            counter(lst-ctn-kix_list_24-2, decimal) "."
            counter(lst-ctn-kix_list_24-3, decimal) ". ";
        }
        .lst-kix_list_24-4 > li:before {
          content: "" counter(lst-ctn-kix_list_24-0, decimal) "."
            counter(lst-ctn-kix_list_24-1, decimal) "."
            counter(lst-ctn-kix_list_24-2, decimal) "."
            counter(lst-ctn-kix_list_24-3, decimal) "."
            counter(lst-ctn-kix_list_24-4, decimal) ". ";
        }
        .lst-kix_list_9-4 > li {
          counter-increment: lst-ctn-kix_list_9-4;
        }
        ul.lst-kix_list_27-3 {
          list-style-type: none;
        }
        .lst-kix_list_24-5 > li:before {
          content: "" counter(lst-ctn-kix_list_24-0, decimal) "."
            counter(lst-ctn-kix_list_24-1, decimal) "."
            counter(lst-ctn-kix_list_24-2, decimal) "."
            counter(lst-ctn-kix_list_24-3, decimal) "."
            counter(lst-ctn-kix_list_24-4, decimal) "."
            counter(lst-ctn-kix_list_24-5, decimal) ". ";
        }
        ul.lst-kix_list_27-4 {
          list-style-type: none;
        }
        ul.lst-kix_list_27-5 {
          list-style-type: none;
        }
        ul.lst-kix_list_27-6 {
          list-style-type: none;
        }
        ul.lst-kix_list_27-7 {
          list-style-type: none;
        }
        ul.lst-kix_list_27-8 {
          list-style-type: none;
        }
        .lst-kix_list_24-6 > li:before {
          content: "" counter(lst-ctn-kix_list_24-0, decimal) "."
            counter(lst-ctn-kix_list_24-1, decimal) "."
            counter(lst-ctn-kix_list_24-2, decimal) "."
            counter(lst-ctn-kix_list_24-3, decimal) "."
            counter(lst-ctn-kix_list_24-4, decimal) "."
            counter(lst-ctn-kix_list_24-5, decimal) "."
            counter(lst-ctn-kix_list_24-6, decimal) ". ";
        }
        ol.lst-kix_list_16-4.start {
          counter-reset: lst-ctn-kix_list_16-4 0;
        }
        ol.lst-kix_list_14-1.start {
          counter-reset: lst-ctn-kix_list_14-1 0;
        }
        .lst-kix_list_23-6 > li:before {
          content: "" counter(lst-ctn-kix_list_23-6, lower-roman) ") ";
        }
        .lst-kix_list_6-5 > li {
          counter-increment: lst-ctn-kix_list_6-5;
        }
        .lst-kix_list_23-3 > li:before {
          content: "(" counter(lst-ctn-kix_list_23-3, lower-roman) ") ";
        }
        .lst-kix_list_23-7 > li:before {
          content: "" counter(lst-ctn-kix_list_23-7, lower-latin) ". ";
        }
        .lst-kix_list_23-2 > li:before {
          content: "(" counter(lst-ctn-kix_list_23-2, lower-latin) ") ";
        }
        ol.lst-kix_list_23-7.start {
          counter-reset: lst-ctn-kix_list_23-7 0;
        }
        .lst-kix_list_23-0 > li:before {
          content: "Article " counter(lst-ctn-kix_list_23-0, upper-roman) ". ";
        }
        .lst-kix_list_23-8 > li:before {
          content: "" counter(lst-ctn-kix_list_23-8, lower-roman) ". ";
        }
        .lst-kix_list_3-6 > li {
          counter-increment: lst-ctn-kix_list_3-6;
        }
        .lst-kix_list_23-1 > li:before {
          content: "Section " counter(lst-ctn-kix_list_23-0, upper-roman) "."
            counter(lst-ctn-kix_list_23-1, decimal-leading-zero) " ";
        }
        .lst-kix_list_24-1 > li:before {
          content: "" counter(lst-ctn-kix_list_24-0, decimal) "."
            counter(lst-ctn-kix_list_24-1, decimal) ". ";
        }
        .lst-kix_list_2-8 > li {
          counter-increment: lst-ctn-kix_list_2-8;
        }
        .lst-kix_list_24-0 > li:before {
          content: "" counter(lst-ctn-kix_list_24-0, decimal) ". ";
        }
        ol.lst-kix_list_26-2.start {
          counter-reset: lst-ctn-kix_list_26-2 0;
        }
        .lst-kix_list_23-4 > li:before {
          content: "" counter(lst-ctn-kix_list_23-4, decimal) ") ";
        }
        .lst-kix_list_23-5 > li:before {
          content: "" counter(lst-ctn-kix_list_23-5, lower-latin) ") ";
        }
        .lst-kix_list_22-2 > li:before {
          content: "" counter(lst-ctn-kix_list_22-2, lower-roman) ") ";
        }
        .lst-kix_list_22-6 > li:before {
          content: "" counter(lst-ctn-kix_list_22-6, decimal) ". ";
        }
        ol.lst-kix_list_36-0.start {
          counter-reset: lst-ctn-kix_list_36-0 0;
        }
        .lst-kix_list_8-6 > li {
          counter-increment: lst-ctn-kix_list_8-6;
        }
        .lst-kix_list_22-0 > li:before {
          content: "" counter(lst-ctn-kix_list_22-0, decimal) ") ";
        }
        .lst-kix_list_22-8 > li:before {
          content: "" counter(lst-ctn-kix_list_22-8, lower-roman) ". ";
        }
        ol.lst-kix_list_4-6.start {
          counter-reset: lst-ctn-kix_list_4-6 0;
        }
        ol.lst-kix_list_9-7 {
          list-style-type: none;
        }
        ol.lst-kix_list_9-8 {
          list-style-type: none;
        }
        ol.lst-kix_list_22-5.start {
          counter-reset: lst-ctn-kix_list_22-5 0;
        }
        ol.lst-kix_list_39-5.start {
          counter-reset: lst-ctn-kix_list_39-5 0;
        }
        ol.lst-kix_list_3-0.start {
          counter-reset: lst-ctn-kix_list_3-0 0;
        }
        ol.lst-kix_list_9-3 {
          list-style-type: none;
        }
        ol.lst-kix_list_9-4 {
          list-style-type: none;
        }
        .lst-kix_list_5-7 > li {
          counter-increment: lst-ctn-kix_list_5-7;
        }
        ol.lst-kix_list_9-5 {
          list-style-type: none;
        }
        ol.lst-kix_list_9-6 {
          list-style-type: none;
        }
        ol.lst-kix_list_9-0 {
          list-style-type: none;
        }
        ol.lst-kix_list_9-1 {
          list-style-type: none;
        }
        ol.lst-kix_list_9-2 {
          list-style-type: none;
        }
        .lst-kix_list_22-4 > li:before {
          content: "(" counter(lst-ctn-kix_list_22-4, lower-latin) ") ";
        }
        ol.lst-kix_list_25-5.start {
          counter-reset: lst-ctn-kix_list_25-5 0;
        }
        .lst-kix_list_25-5 > li:before {
          content: "" counter(lst-ctn-kix_list_25-0, decimal) "."
            counter(lst-ctn-kix_list_25-1, decimal) "."
            counter(lst-ctn-kix_list_25-2, decimal) "."
            counter(lst-ctn-kix_list_25-3, decimal) "."
            counter(lst-ctn-kix_list_25-4, decimal) "."
            counter(lst-ctn-kix_list_25-5, decimal) ". ";
        }
        .lst-kix_list_25-7 > li:before {
          content: "" counter(lst-ctn-kix_list_25-0, decimal) "."
            counter(lst-ctn-kix_list_25-1, decimal) "."
            counter(lst-ctn-kix_list_25-2, decimal) "."
            counter(lst-ctn-kix_list_25-3, decimal) "."
            counter(lst-ctn-kix_list_25-4, decimal) "."
            counter(lst-ctn-kix_list_25-5, decimal) "."
            counter(lst-ctn-kix_list_25-6, decimal) "."
            counter(lst-ctn-kix_list_25-7, decimal) ". ";
        }
        .lst-kix_list_3-5 > li {
          counter-increment: lst-ctn-kix_list_3-5;
        }
        ol.lst-kix_list_1-1.start {
          counter-reset: lst-ctn-kix_list_1-1 0;
        }
        ol.lst-kix_list_18-3.start {
          counter-reset: lst-ctn-kix_list_18-3 -1;
        }
        ol.lst-kix_list_24-4.start {
          counter-reset: lst-ctn-kix_list_24-4 0;
        }
        ol.lst-kix_list_18-7 {
          list-style-type: none;
        }
        ol.lst-kix_list_18-8 {
          list-style-type: none;
        }
        ol.lst-kix_list_33-0.start {
          counter-reset: lst-ctn-kix_list_33-0 0;
        }
        ol.lst-kix_list_18-3 {
          list-style-type: none;
        }
        ol.lst-kix_list_18-4 {
          list-style-type: none;
        }
        .lst-kix_list_6-4 > li {
          counter-increment: lst-ctn-kix_list_6-4;
        }
        ol.lst-kix_list_18-5 {
          list-style-type: none;
        }
        ul.lst-kix_list_29-0 {
          list-style-type: none;
        }
        ol.lst-kix_list_18-6 {
          list-style-type: none;
        }
        .lst-kix_list_40-7 > li:before {
          content: "" counter(lst-ctn-kix_list_40-0, decimal) "."
            counter(lst-ctn-kix_list_40-1, decimal) "."
            counter(lst-ctn-kix_list_40-2, decimal) "."
            counter(lst-ctn-kix_list_40-3, decimal) "."
            counter(lst-ctn-kix_list_40-4, decimal) "."
            counter(lst-ctn-kix_list_40-5, decimal) "."
            counter(lst-ctn-kix_list_40-6, decimal) "."
            counter(lst-ctn-kix_list_40-7, decimal) ". ";
        }
        .lst-kix_list_9-3 > li {
          counter-increment: lst-ctn-kix_list_9-3;
        }
        ol.lst-kix_list_18-2 {
          list-style-type: none;
        }
        .lst-kix_list_40-5 > li:before {
          content: "" counter(lst-ctn-kix_list_40-0, decimal) "."
            counter(lst-ctn-kix_list_40-1, decimal) "."
            counter(lst-ctn-kix_list_40-2, decimal) "."
            counter(lst-ctn-kix_list_40-3, decimal) "."
            counter(lst-ctn-kix_list_40-4, decimal) "."
            counter(lst-ctn-kix_list_40-5, decimal) ". ";
        }
        ol.lst-kix_list_15-2.start {
          counter-reset: lst-ctn-kix_list_15-2 0;
        }
        ol.lst-kix_list_37-7.start {
          counter-reset: lst-ctn-kix_list_37-7 0;
        }
        ol.lst-kix_list_2-8.start {
          counter-reset: lst-ctn-kix_list_2-8 0;
        }
        ol.lst-kix_list_7-6.start {
          counter-reset: lst-ctn-kix_list_7-6 0;
        }
        ul.lst-kix_list_29-1 {
          list-style-type: none;
        }
        ul.lst-kix_list_29-2 {
          list-style-type: none;
        }
        ul.lst-kix_list_29-3 {
          list-style-type: none;
        }
        ul.lst-kix_list_29-4 {
          list-style-type: none;
        }
        ul.lst-kix_list_29-5 {
          list-style-type: none;
        }
        ul.lst-kix_list_29-6 {
          list-style-type: none;
        }
        ul.lst-kix_list_29-7 {
          list-style-type: none;
        }
        ul.lst-kix_list_29-8 {
          list-style-type: none;
        }
        ol.lst-kix_list_15-3.start {
          counter-reset: lst-ctn-kix_list_15-3 0;
        }
        ul.lst-kix_list_18-0 {
          list-style-type: none;
        }
        ol.lst-kix_list_5-8.start {
          counter-reset: lst-ctn-kix_list_5-8 0;
        }
        .lst-kix_list_1-3 > li {
          counter-increment: lst-ctn-kix_list_1-3;
        }
        .lst-kix_list_40-3 > li:before {
          content: "" counter(lst-ctn-kix_list_40-0, decimal) "."
            counter(lst-ctn-kix_list_40-1, decimal) "."
            counter(lst-ctn-kix_list_40-2, decimal) "."
            counter(lst-ctn-kix_list_40-3, decimal) ". ";
        }
        ol.lst-kix_list_12-2.start {
          counter-reset: lst-ctn-kix_list_12-2 0;
        }
        ol.lst-kix_list_6-0.start {
          counter-reset: lst-ctn-kix_list_6-0 0;
        }
        ul.lst-kix_list_18-1 {
          list-style-type: none;
        }
        .lst-kix_list_40-1 > li:before {
          content: "" counter(lst-ctn-kix_list_40-0, decimal) "."
            counter(lst-ctn-kix_list_40-1, decimal) ". ";
        }
        .lst-kix_list_4-2 > li {
          counter-increment: lst-ctn-kix_list_4-2;
        }
        ol.lst-kix_list_3-1 {
          list-style-type: none;
        }
        ol.lst-kix_list_38-3.start {
          counter-reset: lst-ctn-kix_list_38-3 0;
        }
        ol.lst-kix_list_3-2 {
          list-style-type: none;
        }
        .lst-kix_list_24-8 > li {
          counter-increment: lst-ctn-kix_list_24-8;
        }
        ol.lst-kix_list_3-3 {
          list-style-type: none;
        }
        ol.lst-kix_list_3-4.start {
          counter-reset: lst-ctn-kix_list_3-4 0;
        }
        .lst-kix_list_5-1 > li {
          counter-increment: lst-ctn-kix_list_5-1;
        }
        ol.lst-kix_list_3-4 {
          list-style-type: none;
        }
        ol.lst-kix_list_19-0.start {
          counter-reset: lst-ctn-kix_list_19-0 0;
        }
        ol.lst-kix_list_21-3.start {
          counter-reset: lst-ctn-kix_list_21-3 0;
        }
        ol.lst-kix_list_3-0 {
          list-style-type: none;
        }
        .lst-kix_list_7-1 > li {
          counter-increment: lst-ctn-kix_list_7-1;
        }
        .lst-kix_list_38-5 > li {
          counter-increment: lst-ctn-kix_list_38-5;
        }
        ol.lst-kix_list_25-6.start {
          counter-reset: lst-ctn-kix_list_25-6 0;
        }
        ol.lst-kix_list_34-0 {
          list-style-type: none;
        }
        .lst-kix_list_21-8 > li:before {
          content: "" counter(lst-ctn-kix_list_21-0, decimal) "."
            counter(lst-ctn-kix_list_21-1, decimal) "."
            counter(lst-ctn-kix_list_21-2, decimal) "."
            counter(lst-ctn-kix_list_21-3, decimal) "."
            counter(lst-ctn-kix_list_21-4, decimal) "."
            counter(lst-ctn-kix_list_21-5, decimal) "."
            counter(lst-ctn-kix_list_21-6, decimal) "."
            counter(lst-ctn-kix_list_21-7, decimal) "."
            counter(lst-ctn-kix_list_21-8, decimal) ". ";
        }
        .lst-kix_list_26-5 > li:before {
          content: "" counter(lst-ctn-kix_list_26-0, decimal) "."
            counter(lst-ctn-kix_list_26-1, decimal) "."
            counter(lst-ctn-kix_list_26-2, decimal) "."
            counter(lst-ctn-kix_list_26-3, decimal) "."
            counter(lst-ctn-kix_list_26-4, decimal) "."
            counter(lst-ctn-kix_list_26-5, decimal) ". ";
        }
        .lst-kix_list_16-0 > li {
          counter-increment: lst-ctn-kix_list_16-0;
        }
        .lst-kix_list_8-0 > li {
          counter-increment: lst-ctn-kix_list_8-0;
        }
        .lst-kix_list_10-0 > li {
          counter-increment: lst-ctn-kix_list_10-0;
        }
        ol.lst-kix_list_18-2.start {
          counter-reset: lst-ctn-kix_list_18-2 -1;
        }
        ol.lst-kix_list_3-5 {
          list-style-type: none;
        }
        ol.lst-kix_list_3-6 {
          list-style-type: none;
        }
        .lst-kix_list_26-8 > li:before {
          content: "" counter(lst-ctn-kix_list_26-0, decimal) "."
            counter(lst-ctn-kix_list_26-1, decimal) "."
            counter(lst-ctn-kix_list_26-2, decimal) "."
            counter(lst-ctn-kix_list_26-3, decimal) "."
            counter(lst-ctn-kix_list_26-4, decimal) "."
            counter(lst-ctn-kix_list_26-5, decimal) "."
            counter(lst-ctn-kix_list_26-6, decimal) "."
            counter(lst-ctn-kix_list_26-7, decimal) "."
            counter(lst-ctn-kix_list_26-8, decimal) ". ";
        }
        ol.lst-kix_list_3-7 {
          list-style-type: none;
        }
        ol.lst-kix_list_3-8 {
          list-style-type: none;
        }
        .lst-kix_list_21-0 > li:before {
          content: "" counter(lst-ctn-kix_list_21-0, decimal) ". ";
        }
        ul.lst-kix_list_36-1 {
          list-style-type: none;
        }
        ul.lst-kix_list_36-2 {
          list-style-type: none;
        }
        .lst-kix_list_26-1 > li:before {
          content: "" counter(lst-ctn-kix_list_26-0, decimal) "."
            counter(lst-ctn-kix_list_26-1, decimal) ". ";
        }
        ul.lst-kix_list_36-3 {
          list-style-type: none;
        }
        .lst-kix_list_13-1 > li {
          counter-increment: lst-ctn-kix_list_13-1;
        }
        ul.lst-kix_list_36-4 {
          list-style-type: none;
        }
        .lst-kix_list_21-1 > li:before {
          content: "" counter(lst-ctn-kix_list_21-0, decimal) "."
            counter(lst-ctn-kix_list_21-1, decimal) ". ";
        }
        ol.lst-kix_list_15-8.start {
          counter-reset: lst-ctn-kix_list_15-8 0;
        }
        .lst-kix_list_26-4 > li:before {
          content: "" counter(lst-ctn-kix_list_26-0, decimal) "."
            counter(lst-ctn-kix_list_26-1, decimal) "."
            counter(lst-ctn-kix_list_26-2, decimal) "."
            counter(lst-ctn-kix_list_26-3, decimal) "."
            counter(lst-ctn-kix_list_26-4, decimal) ". ";
        }
        .lst-kix_list_10-2 > li {
          counter-increment: lst-ctn-kix_list_10-2;
        }
        .lst-kix_list_21-5 > li:before {
          content: "" counter(lst-ctn-kix_list_21-0, decimal) "."
            counter(lst-ctn-kix_list_21-1, decimal) "."
            counter(lst-ctn-kix_list_21-2, decimal) "."
            counter(lst-ctn-kix_list_21-3, decimal) "."
            counter(lst-ctn-kix_list_21-4, decimal) "."
            counter(lst-ctn-kix_list_21-5, decimal) ". ";
        }
        ul.lst-kix_list_36-5 {
          list-style-type: none;
        }
        ul.lst-kix_list_36-6 {
          list-style-type: none;
        }
        ul.lst-kix_list_36-7 {
          list-style-type: none;
        }
        ul.lst-kix_list_36-8 {
          list-style-type: none;
        }
        .lst-kix_list_21-4 > li:before {
          content: "" counter(lst-ctn-kix_list_21-0, decimal) "."
            counter(lst-ctn-kix_list_21-1, decimal) "."
            counter(lst-ctn-kix_list_21-2, decimal) "."
            counter(lst-ctn-kix_list_21-3, decimal) "."
            counter(lst-ctn-kix_list_21-4, decimal) ". ";
        }
        .lst-kix_list_26-0 > li:before {
          content: "" counter(lst-ctn-kix_list_26-0, decimal) ". ";
        }
        ol.lst-kix_list_7-2.start {
          counter-reset: lst-ctn-kix_list_7-2 0;
        }
        ol.lst-kix_list_38-8.start {
          counter-reset: lst-ctn-kix_list_38-8 0;
        }
        ol.lst-kix_list_19-5.start {
          counter-reset: lst-ctn-kix_list_19-5 0;
        }
        ol.lst-kix_list_12-5 {
          list-style-type: none;
        }
        ol.lst-kix_list_12-6 {
          list-style-type: none;
        }
        ol.lst-kix_list_12-7 {
          list-style-type: none;
        }
        ol.lst-kix_list_12-8 {
          list-style-type: none;
        }
        ol.lst-kix_list_12-1 {
          list-style-type: none;
        }
        ol.lst-kix_list_12-2 {
          list-style-type: none;
        }
        ol.lst-kix_list_12-3 {
          list-style-type: none;
        }
        ol.lst-kix_list_39-6.start {
          counter-reset: lst-ctn-kix_list_39-6 0;
        }
        ol.lst-kix_list_12-4 {
          list-style-type: none;
        }
        ol.lst-kix_list_26-3.start {
          counter-reset: lst-ctn-kix_list_26-3 0;
        }
        ol.lst-kix_list_12-0 {
          list-style-type: none;
        }
        .lst-kix_list_21-0 > li {
          counter-increment: lst-ctn-kix_list_21-0;
        }
        .lst-kix_list_25-1 > li:before {
          content: "" counter(lst-ctn-kix_list_25-0, decimal) "."
            counter(lst-ctn-kix_list_25-1, decimal) ". ";
        }
        .lst-kix_list_25-0 > li:before {
          content: "" counter(lst-ctn-kix_list_25-0, decimal) ". ";
        }
        ol.lst-kix_list_10-8.start {
          counter-reset: lst-ctn-kix_list_10-8 0;
        }
        ol.lst-kix_list_7-1.start {
          counter-reset: lst-ctn-kix_list_7-1 0;
        }
        .lst-kix_list_39-0 > li:before {
          content: "" counter(lst-ctn-kix_list_39-0, decimal) ") ";
        }
        ol.lst-kix_list_23-6 {
          list-style-type: none;
        }
        ol.lst-kix_list_23-5 {
          list-style-type: none;
        }
        ol.lst-kix_list_23-8 {
          list-style-type: none;
        }
        .lst-kix_list_39-1 > li:before {
          content: "" counter(lst-ctn-kix_list_39-1, lower-latin) ") ";
        }
        ol.lst-kix_list_23-7 {
          list-style-type: none;
        }
        ol.lst-kix_list_40-7.start {
          counter-reset: lst-ctn-kix_list_40-7 0;
        }
        .lst-kix_list_37-0 > li {
          counter-increment: lst-ctn-kix_list_37-0;
        }
        ol.lst-kix_list_23-2 {
          list-style-type: none;
        }
        .lst-kix_list_16-7 > li {
          counter-increment: lst-ctn-kix_list_16-7;
        }
        ol.lst-kix_list_21-4.start {
          counter-reset: lst-ctn-kix_list_21-4 0;
        }
        ol.lst-kix_list_23-1 {
          list-style-type: none;
        }
        ol.lst-kix_list_23-4 {
          list-style-type: none;
        }
        ol.lst-kix_list_23-3 {
          list-style-type: none;
        }
        ol.lst-kix_list_23-0 {
          list-style-type: none;
        }
        ol.lst-kix_list_20-6.start {
          counter-reset: lst-ctn-kix_list_20-6 0;
        }
        .lst-kix_list_38-7 > li {
          counter-increment: lst-ctn-kix_list_38-7;
        }
        .lst-kix_list_13-8 > li {
          counter-increment: lst-ctn-kix_list_13-8;
        }
        .lst-kix_list_2-2 > li {
          counter-increment: lst-ctn-kix_list_2-2;
        }
        ol.lst-kix_list_4-7.start {
          counter-reset: lst-ctn-kix_list_4-7 0;
        }
        .lst-kix_list_16-5 > li {
          counter-increment: lst-ctn-kix_list_16-5;
        }
        ol.lst-kix_list_5-0 {
          list-style-type: none;
        }
        .lst-kix_list_3-7 > li {
          counter-increment: lst-ctn-kix_list_3-7;
        }
        ol.lst-kix_list_5-1 {
          list-style-type: none;
        }
        .lst-kix_list_26-2 > li {
          counter-increment: lst-ctn-kix_list_26-2;
        }
        ol.lst-kix_list_5-2 {
          list-style-type: none;
        }
        .lst-kix_list_40-8 > li {
          counter-increment: lst-ctn-kix_list_40-8;
        }
        .lst-kix_list_22-5 > li:before {
          content: "(" counter(lst-ctn-kix_list_22-5, lower-roman) ") ";
        }
        .lst-kix_list_21-2 > li {
          counter-increment: lst-ctn-kix_list_21-2;
        }
        .lst-kix_list_22-1 > li:before {
          content: "" counter(lst-ctn-kix_list_22-1, lower-latin) ") ";
        }
        ol.lst-kix_list_36-0 {
          list-style-type: none;
        }
        .lst-kix_list_27-4 > li:before {
          content: " ";
        }
        .lst-kix_list_20-2 > li {
          counter-increment: lst-ctn-kix_list_20-2;
        }
        .lst-kix_list_6-6 > li {
          counter-increment: lst-ctn-kix_list_6-6;
        }
        ol.lst-kix_list_15-7.start {
          counter-reset: lst-ctn-kix_list_15-7 0;
        }
        .lst-kix_list_23-3 > li {
          counter-increment: lst-ctn-kix_list_23-3;
        }
        .lst-kix_list_13-6 > li {
          counter-increment: lst-ctn-kix_list_13-6;
        }
        ol.lst-kix_list_14-6.start {
          counter-reset: lst-ctn-kix_list_14-6 0;
        }
        .lst-kix_list_39-4 > li:before {
          content: "(" counter(lst-ctn-kix_list_39-4, lower-latin) ") ";
        }
        .lst-kix_list_39-8 > li:before {
          content: "" counter(lst-ctn-kix_list_39-8, lower-roman) ". ";
        }
        ol.lst-kix_list_5-7 {
          list-style-type: none;
        }
        ol.lst-kix_list_5-8 {
          list-style-type: none;
        }
        ol.lst-kix_list_26-8.start {
          counter-reset: lst-ctn-kix_list_26-8 0;
        }
        ol.lst-kix_list_5-3 {
          list-style-type: none;
        }
        .lst-kix_list_8-7 > li {
          counter-increment: lst-ctn-kix_list_8-7;
        }
        .lst-kix_list_19-6 > li {
          counter-increment: lst-ctn-kix_list_19-6;
        }
        ol.lst-kix_list_5-4 {
          list-style-type: none;
        }
        .lst-kix_list_27-0 > li:before {
          content: "\\0025cf  ";
        }
        ol.lst-kix_list_5-5 {
          list-style-type: none;
        }
        ol.lst-kix_list_5-6 {
          list-style-type: none;
        }
        .lst-kix_list_20-4 > li {
          counter-increment: lst-ctn-kix_list_20-4;
        }
        .lst-kix_list_9-5 > li {
          counter-increment: lst-ctn-kix_list_9-5;
        }
        .lst-kix_list_5-8 > li {
          counter-increment: lst-ctn-kix_list_5-8;
        }
        ol.lst-kix_list_19-4.start {
          counter-reset: lst-ctn-kix_list_19-4 0;
        }
        .lst-kix_list_38-0 > li {
          counter-increment: lst-ctn-kix_list_38-0;
        }
        .lst-kix_list_32-0 > li {
          counter-increment: lst-ctn-kix_list_32-0;
        }
        ol.lst-kix_list_2-2.start {
          counter-reset: lst-ctn-kix_list_2-2 0;
        }
        ol.lst-kix_list_20-1.start {
          counter-reset: lst-ctn-kix_list_20-1 0;
        }
        .lst-kix_list_25-4 > li:before {
          content: "" counter(lst-ctn-kix_list_25-0, decimal) "."
            counter(lst-ctn-kix_list_25-1, decimal) "."
            counter(lst-ctn-kix_list_25-2, decimal) "."
            counter(lst-ctn-kix_list_25-3, decimal) "."
            counter(lst-ctn-kix_list_25-4, decimal) ". ";
        }
        .lst-kix_list_19-4 > li {
          counter-increment: lst-ctn-kix_list_19-4;
        }
        .lst-kix_list_24-1 > li {
          counter-increment: lst-ctn-kix_list_24-1;
        }
        .lst-kix_list_25-8 > li:before {
          content: "" counter(lst-ctn-kix_list_25-0, decimal) "."
            counter(lst-ctn-kix_list_25-1, decimal) "."
            counter(lst-ctn-kix_list_25-2, decimal) "."
            counter(lst-ctn-kix_list_25-3, decimal) "."
            counter(lst-ctn-kix_list_25-4, decimal) "."
            counter(lst-ctn-kix_list_25-5, decimal) "."
            counter(lst-ctn-kix_list_25-6, decimal) "."
            counter(lst-ctn-kix_list_25-7, decimal) "."
            counter(lst-ctn-kix_list_25-8, decimal) ". ";
        }
        ol.lst-kix_list_38-4.start {
          counter-reset: lst-ctn-kix_list_38-4 0;
        }
        ol.lst-kix_list_25-1.start {
          counter-reset: lst-ctn-kix_list_25-1 0;
        }
        ol.lst-kix_list_14-7 {
          list-style-type: none;
        }
        ol.lst-kix_list_14-8 {
          list-style-type: none;
        }
        ol.lst-kix_list_26-6.start {
          counter-reset: lst-ctn-kix_list_26-6 0;
        }
        .lst-kix_list_37-2 > li {
          counter-increment: lst-ctn-kix_list_37-2;
        }
        ol.lst-kix_list_14-3 {
          list-style-type: none;
        }
        ol.lst-kix_list_21-8.start {
          counter-reset: lst-ctn-kix_list_21-8 0;
        }
        ol.lst-kix_list_14-4 {
          list-style-type: none;
        }
        ol.lst-kix_list_14-5 {
          list-style-type: none;
        }
        ol.lst-kix_list_14-6 {
          list-style-type: none;
        }
        .lst-kix_list_15-2 > li {
          counter-increment: lst-ctn-kix_list_15-2;
        }
        ol.lst-kix_list_14-0 {
          list-style-type: none;
        }
        .lst-kix_list_22-5 > li {
          counter-increment: lst-ctn-kix_list_22-5;
        }
        .lst-kix_list_40-6 > li:before {
          content: "" counter(lst-ctn-kix_list_40-0, decimal) "."
            counter(lst-ctn-kix_list_40-1, decimal) "."
            counter(lst-ctn-kix_list_40-2, decimal) "."
            counter(lst-ctn-kix_list_40-3, decimal) "."
            counter(lst-ctn-kix_list_40-4, decimal) "."
            counter(lst-ctn-kix_list_40-5, decimal) "."
            counter(lst-ctn-kix_list_40-6, decimal) ". ";
        }
        ol.lst-kix_list_14-1 {
          list-style-type: none;
        }
        ol.lst-kix_list_14-2 {
          list-style-type: none;
        }
        .lst-kix_list_20-5 > li:before {
          content: "" counter(lst-ctn-kix_list_20-5, lower-latin) ") ";
        }
        .lst-kix_list_28-8 > li:before {
          content: " ";
        }
        .lst-kix_list_20-1 > li:before {
          content: "Section " counter(lst-ctn-kix_list_20-0, upper-roman) "."
            counter(lst-ctn-kix_list_20-1, decimal-leading-zero) " ";
        }
        .lst-kix_list_12-3 > li {
          counter-increment: lst-ctn-kix_list_12-3;
        }
        .lst-kix_list_24-6 > li {
          counter-increment: lst-ctn-kix_list_24-6;
        }
        .lst-kix_list_17-3 > li {
          counter-increment: lst-ctn-kix_list_17-3;
        }
        ol.lst-kix_list_8-4.start {
          counter-reset: lst-ctn-kix_list_8-4 0;
        }
        .lst-kix_list_28-4 > li:before {
          content: " ";
        }
        .lst-kix_list_39-3 > li {
          counter-increment: lst-ctn-kix_list_39-3;
        }
        ol.lst-kix_list_3-5.start {
          counter-reset: lst-ctn-kix_list_3-5 0;
        }
        .lst-kix_list_21-7 > li {
          counter-increment: lst-ctn-kix_list_21-7;
        }
        ol.lst-kix_list_25-0.start {
          counter-reset: lst-ctn-kix_list_25-0 0;
        }
        ol.lst-kix_list_25-8 {
          list-style-type: none;
        }
        ol.lst-kix_list_25-7 {
          list-style-type: none;
        }
        ol.lst-kix_list_25-4 {
          list-style-type: none;
        }
        ol.lst-kix_list_25-3 {
          list-style-type: none;
        }
        ol.lst-kix_list_13-0.start {
          counter-reset: lst-ctn-kix_list_13-0 0;
        }
        .lst-kix_list_14-4 > li {
          counter-increment: lst-ctn-kix_list_14-4;
        }
        ol.lst-kix_list_25-6 {
          list-style-type: none;
        }
        ol.lst-kix_list_25-5 {
          list-style-type: none;
        }
        ol.lst-kix_list_25-0 {
          list-style-type: none;
        }
        ol.lst-kix_list_25-2 {
          list-style-type: none;
        }
        .lst-kix_list_10-7 > li {
          counter-increment: lst-ctn-kix_list_10-7;
        }
        ol.lst-kix_list_25-1 {
          list-style-type: none;
        }
        .lst-kix_list_25-4 > li {
          counter-increment: lst-ctn-kix_list_25-4;
        }
        ol.lst-kix_list_8-3.start {
          counter-reset: lst-ctn-kix_list_8-3 0;
        }
        .lst-kix_list_11-5 > li {
          counter-increment: lst-ctn-kix_list_11-5;
        }
        .lst-kix_list_28-0 > li:before {
          content: "\\0025cf  ";
        }
        ol.lst-kix_list_14-5.start {
          counter-reset: lst-ctn-kix_list_14-5 0;
        }
        .lst-kix_list_27-8 > li:before {
          content: " ";
        }
        .lst-kix_list_40-2 > li:before {
          content: "" counter(lst-ctn-kix_list_40-0, decimal) "."
            counter(lst-ctn-kix_list_40-1, decimal) "."
            counter(lst-ctn-kix_list_40-2, decimal) ". ";
        }
        ol.lst-kix_list_37-1.start {
          counter-reset: lst-ctn-kix_list_37-1 0;
        }
        ol.lst-kix_list_20-3.start {
          counter-reset: lst-ctn-kix_list_20-3 0;
        }
        .lst-kix_list_4-1 > li {
          counter-increment: lst-ctn-kix_list_4-1;
        }
        .lst-kix_list_19-1 > li:before {
          content: "Section " counter(lst-ctn-kix_list_19-0, upper-roman) "."
            counter(lst-ctn-kix_list_19-1, decimal-leading-zero) " ";
        }
        .lst-kix_list_19-4 > li:before {
          content: "" counter(lst-ctn-kix_list_19-4, decimal) ") ";
        }
        ol.lst-kix_list_37-8.start {
          counter-reset: lst-ctn-kix_list_37-8 0;
        }
        .lst-kix_list_19-3 > li:before {
          content: "(" counter(lst-ctn-kix_list_19-3, lower-roman) ") ";
        }
        ol.lst-kix_list_38-0.start {
          counter-reset: lst-ctn-kix_list_38-0 0;
        }
        .lst-kix_list_15-0 > li {
          counter-increment: lst-ctn-kix_list_15-0;
        }
        ol.lst-kix_list_24-5.start {
          counter-reset: lst-ctn-kix_list_24-5 0;
        }
        ol.lst-kix_list_6-6.start {
          counter-reset: lst-ctn-kix_list_6-6 0;
        }
        .lst-kix_list_39-5 > li {
          counter-increment: lst-ctn-kix_list_39-5;
        }
        .lst-kix_list_11-0 > li {
          counter-increment: lst-ctn-kix_list_11-0;
        }
        ol.lst-kix_list_1-5.start {
          counter-reset: lst-ctn-kix_list_1-5 0;
        }
        ul.lst-kix_list_32-1 {
          list-style-type: none;
        }
        ul.lst-kix_list_32-2 {
          list-style-type: none;
        }
        ul.lst-kix_list_32-3 {
          list-style-type: none;
        }
        ul.lst-kix_list_32-4 {
          list-style-type: none;
        }
        ol.lst-kix_list_9-6.start {
          counter-reset: lst-ctn-kix_list_9-6 0;
        }
        ul.lst-kix_list_32-5 {
          list-style-type: none;
        }
        ol.lst-kix_list_16-3.start {
          counter-reset: lst-ctn-kix_list_16-3 0;
        }
        ul.lst-kix_list_32-6 {
          list-style-type: none;
        }
        ul.lst-kix_list_32-7 {
          list-style-type: none;
        }
        ul.lst-kix_list_32-8 {
          list-style-type: none;
        }
        ol.lst-kix_list_22-4.start {
          counter-reset: lst-ctn-kix_list_22-4 0;
        }
        .lst-kix_list_22-7 > li {
          counter-increment: lst-ctn-kix_list_22-7;
        }
        ol.lst-kix_list_4-5.start {
          counter-reset: lst-ctn-kix_list_4-5 0;
        }
        .lst-kix_list_37-0 > li:before {
          content: "" counter(lst-ctn-kix_list_37-0, decimal) ". ";
        }
        .lst-kix_list_26-7 > li {
          counter-increment: lst-ctn-kix_list_26-7;
        }
        ol.lst-kix_list_11-2.start {
          counter-reset: lst-ctn-kix_list_11-2 0;
        }
        .lst-kix_list_5-2 > li {
          counter-increment: lst-ctn-kix_list_5-2;
        }
        .lst-kix_list_37-7 > li {
          counter-increment: lst-ctn-kix_list_37-7;
        }
        .lst-kix_list_19-6 > li:before {
          content: "" counter(lst-ctn-kix_list_19-6, lower-roman) ") ";
        }
        .lst-kix_list_37-8 > li:before {
          content: "" counter(lst-ctn-kix_list_37-0, decimal) "."
            counter(lst-ctn-kix_list_37-1, decimal) "."
            counter(lst-ctn-kix_list_37-2, decimal) "."
            counter(lst-ctn-kix_list_37-3, decimal) "."
            counter(lst-ctn-kix_list_37-4, decimal) "."
            counter(lst-ctn-kix_list_37-5, decimal) "."
            counter(lst-ctn-kix_list_37-6, decimal) "."
            counter(lst-ctn-kix_list_37-7, decimal) "."
            counter(lst-ctn-kix_list_37-8, decimal) ". ";
        }
        ol.lst-kix_list_8-7.start {
          counter-reset: lst-ctn-kix_list_8-7 0;
        }
        .lst-kix_list_17-2 > li {
          counter-increment: lst-ctn-kix_list_17-2;
        }
        .lst-kix_list_37-6 > li:before {
          content: "" counter(lst-ctn-kix_list_37-0, decimal) "."
            counter(lst-ctn-kix_list_37-1, decimal) "."
            counter(lst-ctn-kix_list_37-2, decimal) "."
            counter(lst-ctn-kix_list_37-3, decimal) "."
            counter(lst-ctn-kix_list_37-4, decimal) "."
            counter(lst-ctn-kix_list_37-5, decimal) "."
            counter(lst-ctn-kix_list_37-6, decimal) ". ";
        }
        .lst-kix_list_20-5 > li {
          counter-increment: lst-ctn-kix_list_20-5;
        }
        ol.lst-kix_list_35-0.start {
          counter-reset: lst-ctn-kix_list_35-0 0;
        }
        ol.lst-kix_list_37-3.start {
          counter-reset: lst-ctn-kix_list_37-3 0;
        }
        .lst-kix_list_37-1 > li:before {
          content: "" counter(lst-ctn-kix_list_37-0, decimal) "."
            counter(lst-ctn-kix_list_37-1, decimal) ". ";
        }
        .lst-kix_list_22-0 > li {
          counter-increment: lst-ctn-kix_list_22-0;
        }
        ol.lst-kix_list_24-0.start {
          counter-reset: lst-ctn-kix_list_24-0 0;
        }
        .lst-kix_list_18-3 > li {
          counter-increment: lst-ctn-kix_list_18-3;
        }
        .lst-kix_list_37-3 > li:before {
          content: "" counter(lst-ctn-kix_list_37-0, decimal) "."
            counter(lst-ctn-kix_list_37-1, decimal) "."
            counter(lst-ctn-kix_list_37-2, decimal) "."
            counter(lst-ctn-kix_list_37-3, decimal) ". ";
        }
        .lst-kix_list_21-6 > li {
          counter-increment: lst-ctn-kix_list_21-6;
        }
        .lst-kix_list_10-3 > li {
          counter-increment: lst-ctn-kix_list_10-3;
        }
        ol.lst-kix_list_1-0.start {
          counter-reset: lst-ctn-kix_list_1-0 0;
        }
        .lst-kix_list_37-3 > li {
          counter-increment: lst-ctn-kix_list_37-3;
        }
        .lst-kix_list_18-0 > li:before {
          content: "\\0025cf  ";
        }
        ol.lst-kix_list_13-3.start {
          counter-reset: lst-ctn-kix_list_13-3 0;
        }
        .lst-kix_list_38-7 > li:before {
          content: "" counter(lst-ctn-kix_list_38-0, decimal) "."
            counter(lst-ctn-kix_list_38-1, decimal) "."
            counter(lst-ctn-kix_list_38-2, decimal) "."
            counter(lst-ctn-kix_list_38-3, decimal) "."
            counter(lst-ctn-kix_list_38-4, decimal) "."
            counter(lst-ctn-kix_list_38-5, decimal) "."
            counter(lst-ctn-kix_list_38-6, decimal) "."
            counter(lst-ctn-kix_list_38-7, decimal) ". ";
        }
        .lst-kix_list_3-0 > li {
          counter-increment: lst-ctn-kix_list_3-0;
        }
        .lst-kix_list_18-2 > li:before {
          content: " ";
        }
        .lst-kix_list_26-0 > li {
          counter-increment: lst-ctn-kix_list_26-0;
        }
        ol.lst-kix_list_4-0.start {
          counter-reset: lst-ctn-kix_list_4-0 0;
        }
        .lst-kix_list_38-2 > li:before {
          content: "" counter(lst-ctn-kix_list_38-0, decimal) "."
            counter(lst-ctn-kix_list_38-1, decimal) "."
            counter(lst-ctn-kix_list_38-2, decimal) ". ";
        }
        .lst-kix_list_38-4 > li:before {
          content: "" counter(lst-ctn-kix_list_38-0, decimal) "."
            counter(lst-ctn-kix_list_38-1, decimal) "."
            counter(lst-ctn-kix_list_38-2, decimal) "."
            counter(lst-ctn-kix_list_38-3, decimal) "."
            counter(lst-ctn-kix_list_38-4, decimal) ". ";
        }
        ol.lst-kix_list_11-7.start {
          counter-reset: lst-ctn-kix_list_11-7 0;
        }
        ol.lst-kix_list_14-2.start {
          counter-reset: lst-ctn-kix_list_14-2 0;
        }
        .lst-kix_list_16-1 > li {
          counter-increment: lst-ctn-kix_list_16-1;
        }
        .lst-kix_list_38-4 > li {
          counter-increment: lst-ctn-kix_list_38-4;
        }
        .lst-kix_list_38-5 > li:before {
          content: "" counter(lst-ctn-kix_list_38-0, decimal) "."
            counter(lst-ctn-kix_list_38-1, decimal) "."
            counter(lst-ctn-kix_list_38-2, decimal) "."
            counter(lst-ctn-kix_list_38-3, decimal) "."
            counter(lst-ctn-kix_list_38-4, decimal) "."
            counter(lst-ctn-kix_list_38-5, decimal) ". ";
        }
        .lst-kix_list_25-2 > li {
          counter-increment: lst-ctn-kix_list_25-2;
        }
        .lst-kix_list_27-1 > li:before {
          content: " ";
        }
        .lst-kix_list_27-3 > li:before {
          content: " ";
        }
        .lst-kix_list_17-5 > li {
          counter-increment: lst-ctn-kix_list_17-5;
        }
        ol.lst-kix_list_9-4.start {
          counter-reset: lst-ctn-kix_list_9-4 0;
        }
        .lst-kix_list_21-3 > li {
          counter-increment: lst-ctn-kix_list_21-3;
        }
        .lst-kix_list_18-8 > li:before {
          content: " ";
        }
        .lst-kix_list_17-6 > li {
          counter-increment: lst-ctn-kix_list_17-6;
        }
        ol.lst-kix_list_4-3.start {
          counter-reset: lst-ctn-kix_list_4-3 0;
        }
        .lst-kix_list_7-8 > li {
          counter-increment: lst-ctn-kix_list_7-8;
        }
        .lst-kix_list_10-7 > li:before {
          content: "" counter(lst-ctn-kix_list_10-7, lower-latin) ". ";
        }
        .lst-kix_list_20-1 > li {
          counter-increment: lst-ctn-kix_list_20-1;
        }
        ul.lst-kix_list_34-1 {
          list-style-type: none;
        }
        .lst-kix_list_36-4 > li:before {
          content: " ";
        }
        ul.lst-kix_list_34-2 {
          list-style-type: none;
        }
        ul.lst-kix_list_34-3 {
          list-style-type: none;
        }
        .lst-kix_list_10-5 > li:before {
          content: "" counter(lst-ctn-kix_list_10-5, lower-roman) ". ";
        }
        ol.lst-kix_list_13-5.start {
          counter-reset: lst-ctn-kix_list_13-5 0;
        }
        ul.lst-kix_list_34-4 {
          list-style-type: none;
        }
        ol.lst-kix_list_18-6.start {
          counter-reset: lst-ctn-kix_list_18-6 -1;
        }
        ul.lst-kix_list_34-5 {
          list-style-type: none;
        }
        ul.lst-kix_list_34-6 {
          list-style-type: none;
        }
        ol.lst-kix_list_20-8 {
          list-style-type: none;
        }
        ol.lst-kix_list_13-8.start {
          counter-reset: lst-ctn-kix_list_13-8 0;
        }
        ol.lst-kix_list_20-5 {
          list-style-type: none;
        }
        ol.lst-kix_list_20-4 {
          list-style-type: none;
        }
        ol.lst-kix_list_20-7 {
          list-style-type: none;
        }
        .lst-kix_list_11-7 > li {
          counter-increment: lst-ctn-kix_list_11-7;
        }
        ol.lst-kix_list_20-6 {
          list-style-type: none;
        }
        .lst-kix_list_9-2 > li:before {
          content: "" counter(lst-ctn-kix_list_9-2, lower-roman) ". ";
        }
        ol.lst-kix_list_20-1 {
          list-style-type: none;
        }
        ol.lst-kix_list_20-0 {
          list-style-type: none;
        }
        ol.lst-kix_list_20-3 {
          list-style-type: none;
        }
        ol.lst-kix_list_20-2 {
          list-style-type: none;
        }
        ol.lst-kix_list_37-6.start {
          counter-reset: lst-ctn-kix_list_37-6 0;
        }
        ol.lst-kix_list_14-0.start {
          counter-reset: lst-ctn-kix_list_14-0 0;
        }
        .lst-kix_list_12-5 > li {
          counter-increment: lst-ctn-kix_list_12-5;
        }
        ul.lst-kix_list_34-7 {
          list-style-type: none;
        }
        ul.lst-kix_list_34-8 {
          list-style-type: none;
        }
        .lst-kix_list_5-5 > li {
          counter-increment: lst-ctn-kix_list_5-5;
        }
        .lst-kix_list_36-2 > li:before {
          content: " ";
        }
        .lst-kix_list_9-0 > li:before {
          content: "" counter(lst-ctn-kix_list_9-0, lower-latin) ". ";
        }
        .lst-kix_list_16-8 > li {
          counter-increment: lst-ctn-kix_list_16-8;
        }
        ol.lst-kix_list_24-3.start {
          counter-reset: lst-ctn-kix_list_24-3 0;
        }
        .lst-kix_list_23-5 > li {
          counter-increment: lst-ctn-kix_list_23-5;
        }
        .lst-kix_list_11-3 > li:before {
          content: "" counter(lst-ctn-kix_list_11-3, decimal) ". ";
        }
        .lst-kix_list_38-8 > li {
          counter-increment: lst-ctn-kix_list_38-8;
        }
        .lst-kix_list_6-3 > li {
          counter-increment: lst-ctn-kix_list_6-3;
        }
        ol.lst-kix_list_18-4.start {
          counter-reset: lst-ctn-kix_list_18-4 -1;
        }
        ol.lst-kix_list_1-3.start {
          counter-reset: lst-ctn-kix_list_1-3 0;
        }
        ol.lst-kix_list_33-0 {
          list-style-type: none;
        }
        ol.lst-kix_list_1-2.start {
          counter-reset: lst-ctn-kix_list_1-2 0;
        }
        .lst-kix_list_29-1 > li:before {
          content: " ";
        }
        .lst-kix_list_20-4 > li:before {
          content: "" counter(lst-ctn-kix_list_20-4, decimal) ") ";
        }
        ol.lst-kix_list_22-7.start {
          counter-reset: lst-ctn-kix_list_22-7 0;
        }
        ol.lst-kix_list_6-1.start {
          counter-reset: lst-ctn-kix_list_6-1 0;
        }
        .lst-kix_list_29-3 > li:before {
          content: " ";
        }
        .lst-kix_list_20-2 > li:before {
          content: "(" counter(lst-ctn-kix_list_20-2, lower-latin) ") ";
        }
        .lst-kix_list_9-8 > li:before {
          content: "" counter(lst-ctn-kix_list_9-8, lower-roman) ". ";
        }
        ol.lst-kix_list_16-8.start {
          counter-reset: lst-ctn-kix_list_16-8 0;
        }
        .lst-kix_list_28-7 > li:before {
          content: " ";
        }
        .lst-kix_list_4-8 > li {
          counter-increment: lst-ctn-kix_list_4-8;
        }
        .lst-kix_list_1-7 > li:before {
          content: " ";
        }
        .lst-kix_list_1-5 > li:before {
          content: " ";
        }
        .lst-kix_list_28-5 > li:before {
          content: " ";
        }
        ol.lst-kix_list_9-1.start {
          counter-reset: lst-ctn-kix_list_9-1 0;
        }
        ol.lst-kix_list_24-2.start {
          counter-reset: lst-ctn-kix_list_24-2 0;
        }
        .lst-kix_list_5-6 > li {
          counter-increment: lst-ctn-kix_list_5-6;
        }
        ol.lst-kix_list_22-6.start {
          counter-reset: lst-ctn-kix_list_22-6 0;
        }
        .lst-kix_list_2-1 > li:before {
          content: "" counter(lst-ctn-kix_list_2-1, lower-latin) ". ";
        }
        .lst-kix_list_19-8 > li {
          counter-increment: lst-ctn-kix_list_19-8;
        }
        .lst-kix_list_2-3 > li:before {
          content: "" counter(lst-ctn-kix_list_2-3, decimal) ". ";
        }
        .lst-kix_list_11-8 > li {
          counter-increment: lst-ctn-kix_list_11-8;
        }
        .lst-kix_list_35-3 > li:before {
          content: " ";
        }
        ol.lst-kix_list_24-8.start {
          counter-reset: lst-ctn-kix_list_24-8 0;
        }
        .lst-kix_list_30-4 > li:before {
          content: " ";
        }
        .lst-kix_list_20-8 > li {
          counter-increment: lst-ctn-kix_list_20-8;
        }
        .lst-kix_list_35-6 > li:before {
          content: " ";
        }
        .lst-kix_list_9-1 > li {
          counter-increment: lst-ctn-kix_list_9-1;
        }
        .lst-kix_list_3-2 > li:before {
          content: "(" counter(lst-ctn-kix_list_3-2, lower-latin) ") ";
        }
        .lst-kix_list_26-7 > li:before {
          content: "" counter(lst-ctn-kix_list_26-0, decimal) "."
            counter(lst-ctn-kix_list_26-1, decimal) "."
            counter(lst-ctn-kix_list_26-2, decimal) "."
            counter(lst-ctn-kix_list_26-3, decimal) "."
            counter(lst-ctn-kix_list_26-4, decimal) "."
            counter(lst-ctn-kix_list_26-5, decimal) "."
            counter(lst-ctn-kix_list_26-6, decimal) "."
            counter(lst-ctn-kix_list_26-7, decimal) ". ";
        }
        .lst-kix_list_8-1 > li:before {
          content: "[" counter(lst-ctn-kix_list_8-0, decimal) "."
            counter(lst-ctn-kix_list_8-1, decimal) " ";
        }
        ol.lst-kix_list_1-8.start {
          counter-reset: lst-ctn-kix_list_1-8 0;
        }
        .lst-kix_list_6-0 > li {
          counter-increment: lst-ctn-kix_list_6-0;
        }
        .lst-kix_list_3-5 > li:before {
          content: " ";
        }
        .lst-kix_list_30-7 > li:before {
          content: " ";
        }
        .lst-kix_list_40-2 > li {
          counter-increment: lst-ctn-kix_list_40-2;
        }
        ol.lst-kix_list_11-5.start {
          counter-reset: lst-ctn-kix_list_11-5 0;
        }
        .lst-kix_list_8-6 > li:before {
          content: " ";
        }
        .lst-kix_list_11-1 > li {
          counter-increment: lst-ctn-kix_list_11-1;
        }
        .lst-kix_list_26-2 > li:before {
          content: "" counter(lst-ctn-kix_list_26-0, decimal) "."
            counter(lst-ctn-kix_list_26-1, decimal) "."
            counter(lst-ctn-kix_list_26-2, decimal) ". ";
        }
        .lst-kix_list_21-6 > li:before {
          content: "" counter(lst-ctn-kix_list_21-0, decimal) "."
            counter(lst-ctn-kix_list_21-1, decimal) "."
            counter(lst-ctn-kix_list_21-2, decimal) "."
            counter(lst-ctn-kix_list_21-3, decimal) "."
            counter(lst-ctn-kix_list_21-4, decimal) "."
            counter(lst-ctn-kix_list_21-5, decimal) "."
            counter(lst-ctn-kix_list_21-6, decimal) ". ";
        }
        ol.lst-kix_list_16-6.start {
          counter-reset: lst-ctn-kix_list_16-6 0;
        }
        ol.lst-kix_list_22-1.start {
          counter-reset: lst-ctn-kix_list_22-1 0;
        }
        ol.lst-kix_list_4-2.start {
          counter-reset: lst-ctn-kix_list_4-2 0;
        }
        ol.lst-kix_list_16-0.start {
          counter-reset: lst-ctn-kix_list_16-0 0;
        }
        .lst-kix_list_21-3 > li:before {
          content: "" counter(lst-ctn-kix_list_21-0, decimal) "."
            counter(lst-ctn-kix_list_21-1, decimal) "."
            counter(lst-ctn-kix_list_21-2, decimal) "."
            counter(lst-ctn-kix_list_21-3, decimal) ". ";
        }
        ol.lst-kix_list_18-7.start {
          counter-reset: lst-ctn-kix_list_18-7 -1;
        }
        .lst-kix_list_25-5 > li {
          counter-increment: lst-ctn-kix_list_25-5;
        }
        ol.lst-kix_list_11-6.start {
          counter-reset: lst-ctn-kix_list_11-6 0;
        }
        .lst-kix_list_4-4 > li {
          counter-increment: lst-ctn-kix_list_4-4;
        }
        ol.lst-kix_list_6-4.start {
          counter-reset: lst-ctn-kix_list_6-4 0;
        }
        .lst-kix_list_17-1 > li:before {
          content: "" counter(lst-ctn-kix_list_17-1, lower-latin) ". ";
        }
        .lst-kix_list_25-3 > li:before {
          content: "" counter(lst-ctn-kix_list_25-0, decimal) "."
            counter(lst-ctn-kix_list_25-1, decimal) "."
            counter(lst-ctn-kix_list_25-2, decimal) "."
            counter(lst-ctn-kix_list_25-3, decimal) ". ";
        }
        ol.lst-kix_list_4-1.start {
          counter-reset: lst-ctn-kix_list_4-1 0;
        }
        .lst-kix_list_39-2 > li {
          counter-increment: lst-ctn-kix_list_39-2;
        }
        .lst-kix_list_16-2 > li:before {
          content: "" counter(lst-ctn-kix_list_16-2, lower-roman) ". ";
        }
        .lst-kix_list_26-6 > li {
          counter-increment: lst-ctn-kix_list_26-6;
        }
        .lst-kix_list_16-5 > li:before {
          content: "" counter(lst-ctn-kix_list_16-5, lower-roman) ". ";
        }
        .lst-kix_list_15-3 > li {
          counter-increment: lst-ctn-kix_list_15-3;
        }
        ol.lst-kix_list_22-2.start {
          counter-reset: lst-ctn-kix_list_22-2 0;
        }
        .lst-kix_list_37-6 > li {
          counter-increment: lst-ctn-kix_list_37-6;
        }
        ol.lst-kix_list_11-0.start {
          counter-reset: lst-ctn-kix_list_11-0 0;
        }
        ol.lst-kix_list_18-8.start {
          counter-reset: lst-ctn-kix_list_18-8 -1;
        }
        .lst-kix_list_3-3 > li {
          counter-increment: lst-ctn-kix_list_3-3;
        }
        .lst-kix_list_16-4 > li {
          counter-increment: lst-ctn-kix_list_16-4;
        }
        .lst-kix_list_39-3 > li:before {
          content: "(" counter(lst-ctn-kix_list_39-3, decimal) ") ";
        }
        ol.lst-kix_list_6-3.start {
          counter-reset: lst-ctn-kix_list_6-3 0;
        }
        .lst-kix_list_38-1 > li {
          counter-increment: lst-ctn-kix_list_38-1;
        }
        ol.lst-kix_list_16-5.start {
          counter-reset: lst-ctn-kix_list_16-5 0;
        }
        .lst-kix_list_17-6 > li:before {
          content: "" counter(lst-ctn-kix_list_17-6, decimal) ". ";
        }
        .lst-kix_list_2-6 > li:before {
          content: "" counter(lst-ctn-kix_list_2-6, decimal) ". ";
        }
        ol.lst-kix_list_16-2.start {
          counter-reset: lst-ctn-kix_list_16-2 0;
        }
        .lst-kix_list_14-5 > li {
          counter-increment: lst-ctn-kix_list_14-5;
        }
        .lst-kix_list_7-5 > li:before {
          content: " ";
        }
        .lst-kix_list_27-6 > li:before {
          content: " ";
        }
        .lst-kix_list_19-5 > li {
          counter-increment: lst-ctn-kix_list_19-5;
        }
        .lst-kix_list_22-7 > li:before {
          content: "" counter(lst-ctn-kix_list_22-7, lower-latin) ". ";
        }
        .lst-kix_list_23-2 > li {
          counter-increment: lst-ctn-kix_list_23-2;
        }
        ol.lst-kix_list_11-1.start {
          counter-reset: lst-ctn-kix_list_11-1 0;
        }
        .lst-kix_list_34-7 > li:before {
          content: " ";
        }
        .lst-kix_list_18-5 > li:before {
          content: " ";
        }
        .lst-kix_list_13-6 > li:before {
          content: "" counter(lst-ctn-kix_list_13-6, decimal) ". ";
        }
        .lst-kix_list_6-7 > li {
          counter-increment: lst-ctn-kix_list_6-7;
        }
        .lst-kix_list_26-3 > li {
          counter-increment: lst-ctn-kix_list_26-3;
        }
        .lst-kix_list_10-6 > li {
          counter-increment: lst-ctn-kix_list_10-6;
        }
        .lst-kix_list_1-7 > li {
          counter-increment: lst-ctn-kix_list_1-7;
        }
        .lst-kix_list_39-6 > li:before {
          content: "" counter(lst-ctn-kix_list_39-6, decimal) ". ";
        }
        ul.lst-kix_list_30-3 {
          list-style-type: none;
        }
        ul.lst-kix_list_30-4 {
          list-style-type: none;
        }
        ul.lst-kix_list_30-5 {
          list-style-type: none;
        }
        .lst-kix_list_7-5 > li {
          counter-increment: lst-ctn-kix_list_7-5;
        }
        .lst-kix_list_15-6 > li:before {
          content: " ";
        }
        ul.lst-kix_list_30-6 {
          list-style-type: none;
        }
        .lst-kix_list_11-4 > li {
          counter-increment: lst-ctn-kix_list_11-4;
        }
        ol.lst-kix_list_24-7.start {
          counter-reset: lst-ctn-kix_list_24-7 0;
        }
        ul.lst-kix_list_30-7 {
          list-style-type: none;
        }
        ul.lst-kix_list_30-8 {
          list-style-type: none;
        }
        .lst-kix_list_22-4 > li {
          counter-increment: lst-ctn-kix_list_22-4;
        }
        .lst-kix_list_31-3 > li:before {
          content: " ";
        }
        .lst-kix_list_36-7 > li:before {
          content: " ";
        }
        ul.lst-kix_list_30-0 {
          list-style-type: none;
        }
        ol.lst-kix_list_6-8.start {
          counter-reset: lst-ctn-kix_list_6-8 0;
        }
        .lst-kix_list_10-2 > li:before {
          content: "" counter(lst-ctn-kix_list_10-2, lower-roman) ". ";
        }
        ul.lst-kix_list_30-1 {
          list-style-type: none;
        }
        ul.lst-kix_list_30-2 {
          list-style-type: none;
        }
        ol.lst-kix_list_1-7.start {
          counter-reset: lst-ctn-kix_list_1-7 0;
        }
        .lst-kix_list_13-7 > li {
          counter-increment: lst-ctn-kix_list_13-7;
        }
        .lst-kix_list_20-7 > li:before {
          content: "" counter(lst-ctn-kix_list_20-7, lower-latin) ". ";
        }
        ol.lst-kix_list_6-5.start {
          counter-reset: lst-ctn-kix_list_6-5 0;
        }
        .lst-kix_list_4-6 > li:before {
          content: "" counter(lst-ctn-kix_list_4-6, decimal) " ";
        }
        .lst-kix_list_25-6 > li:before {
          content: "" counter(lst-ctn-kix_list_25-0, decimal) "."
            counter(lst-ctn-kix_list_25-1, decimal) "."
            counter(lst-ctn-kix_list_25-2, decimal) "."
            counter(lst-ctn-kix_list_25-3, decimal) "."
            counter(lst-ctn-kix_list_25-4, decimal) "."
            counter(lst-ctn-kix_list_25-5, decimal) "."
            counter(lst-ctn-kix_list_25-6, decimal) ". ";
        }
        ol.lst-kix_list_6-7.start {
          counter-reset: lst-ctn-kix_list_6-7 0;
        }
        .lst-kix_list_12-2 > li {
          counter-increment: lst-ctn-kix_list_12-2;
        }
        .lst-kix_list_9-5 > li:before {
          content: "" counter(lst-ctn-kix_list_9-5, lower-roman) ". ";
        }
        .lst-kix_list_29-6 > li:before {
          content: " ";
        }
        ol.lst-kix_list_22-0.start {
          counter-reset: lst-ctn-kix_list_22-0 0;
        }
        .lst-kix_list_24-0 > li {
          counter-increment: lst-ctn-kix_list_24-0;
        }
        .lst-kix_list_33-3 > li:before {
          content: " ";
        }
        .lst-kix_list_12-2 > li:before {
          content: "" counter(lst-ctn-kix_list_12-2, lower-roman) ". ";
        }
        .lst-kix_list_11-6 > li:before {
          content: "" counter(lst-ctn-kix_list_11-6, decimal) ". ";
        }
        .lst-kix_list_32-7 > li:before {
          content: " ";
        }
        .lst-kix_list_1-2 > li:before {
          content: "(" counter(lst-ctn-kix_list_1-2, lower-roman) ") ";
        }
        ol.lst-kix_list_11-3.start {
          counter-reset: lst-ctn-kix_list_11-3 0;
        }
        .lst-kix_list_1-0 > li {
          counter-increment: lst-ctn-kix_list_1-0;
        }
        ol.lst-kix_list_16-1.start {
          counter-reset: lst-ctn-kix_list_16-1 0;
        }
        ol.lst-kix_list_40-8 {
          list-style-type: none;
        }
        li.li-bullet-0:before {
          margin-left: -18pt;
          white-space: nowrap;
          display: inline-block;
          min-width: 18pt;
        }
        ol.lst-kix_list_40-6 {
          list-style-type: none;
        }
        .lst-kix_list_35-0 > li {
          counter-increment: lst-ctn-kix_list_35-0;
        }
        ol.lst-kix_list_40-7 {
          list-style-type: none;
        }
        ol.lst-kix_list_40-4 {
          list-style-type: none;
        }
        .lst-kix_list_18-7 > li {
          counter-increment: lst-ctn-kix_list_18-7;
        }
        ol.lst-kix_list_40-5 {
          list-style-type: none;
        }
        ol.lst-kix_list_40-2 {
          list-style-type: none;
        }
        ol.lst-kix_list_40-3 {
          list-style-type: none;
        }
        ol.lst-kix_list_40-0 {
          list-style-type: none;
        }
        ol.lst-kix_list_40-1 {
          list-style-type: none;
        }
        .lst-kix_list_40-4 > li:before {
          content: "" counter(lst-ctn-kix_list_40-0, decimal) "."
            counter(lst-ctn-kix_list_40-1, decimal) "."
            counter(lst-ctn-kix_list_40-2, decimal) "."
            counter(lst-ctn-kix_list_40-3, decimal) "."
            counter(lst-ctn-kix_list_40-4, decimal) ". ";
        }
        ol.lst-kix_list_11-4.start {
          counter-reset: lst-ctn-kix_list_11-4 0;
        }
        .lst-kix_list_28-2 > li:before {
          content: " ";
        }
        .lst-kix_list_14-1 > li:before {
          content: "" counter(lst-ctn-kix_list_14-1, lower-latin) ". ";
        }
        .lst-kix_list_14-3 > li:before {
          content: "" counter(lst-ctn-kix_list_14-3, decimal) ". ";
        }
        ol.lst-kix_list_15-6 {
          list-style-type: none;
        }
        .lst-kix_list_25-8 > li {
          counter-increment: lst-ctn-kix_list_25-8;
        }
        ol.lst-kix_list_15-7 {
          list-style-type: none;
        }
        ul.lst-kix_list_28-0 {
          list-style-type: none;
        }
        ol.lst-kix_list_15-8 {
          list-style-type: none;
        }
        ul.lst-kix_list_28-1 {
          list-style-type: none;
        }
        .lst-kix_list_14-0 > li:before {
          content: "" counter(lst-ctn-kix_list_14-0, upper-latin) ". ";
        }
        .lst-kix_list_14-4 > li:before {
          content: "" counter(lst-ctn-kix_list_14-4, lower-latin) ". ";
        }
        ol.lst-kix_list_15-2 {
          list-style-type: none;
        }
        ol.lst-kix_list_15-3 {
          list-style-type: none;
        }
        ol.lst-kix_list_15-4 {
          list-style-type: none;
        }
        ol.lst-kix_list_18-5.start {
          counter-reset: lst-ctn-kix_list_18-5 -1;
        }
        .lst-kix_list_6-1 > li {
          counter-increment: lst-ctn-kix_list_6-1;
        }
        .lst-kix_list_37-5 > li {
          counter-increment: lst-ctn-kix_list_37-5;
        }
        .lst-kix_list_14-5 > li:before {
          content: "" counter(lst-ctn-kix_list_14-5, lower-roman) ". ";
        }
        .lst-kix_list_14-7 > li:before {
          content: "" counter(lst-ctn-kix_list_14-7, lower-latin) ". ";
        }
        ol.lst-kix_list_15-5 {
          list-style-type: none;
        }
        ol.lst-kix_list_15-0 {
          list-style-type: none;
        }
        .lst-kix_list_14-6 > li:before {
          content: "" counter(lst-ctn-kix_list_14-6, decimal) ". ";
        }
        ol.lst-kix_list_15-1 {
          list-style-type: none;
        }
        ol.lst-kix_list_7-4.start {
          counter-reset: lst-ctn-kix_list_7-4 0;
        }
        .lst-kix_list_17-0 > li {
          counter-increment: lst-ctn-kix_list_17-0;
        }
        .lst-kix_list_9-0 > li {
          counter-increment: lst-ctn-kix_list_9-0;
        }
        ul.lst-kix_list_28-2 {
          list-style-type: none;
        }
        ul.lst-kix_list_28-3 {
          list-style-type: none;
        }
        ul.lst-kix_list_28-4 {
          list-style-type: none;
        }
        ul.lst-kix_list_28-5 {
          list-style-type: none;
        }
        ol.lst-kix_list_25-3.start {
          counter-reset: lst-ctn-kix_list_25-3 0;
        }
        ul.lst-kix_list_28-6 {
          list-style-type: none;
        }
        ul.lst-kix_list_28-7 {
          list-style-type: none;
        }
        ol.lst-kix_list_11-8.start {
          counter-reset: lst-ctn-kix_list_11-8 0;
        }
        ul.lst-kix_list_28-8 {
          list-style-type: none;
        }
        .lst-kix_list_14-2 > li:before {
          content: "" counter(lst-ctn-kix_list_14-2, lower-roman) ". ";
        }
        .lst-kix_list_20-7 > li {
          counter-increment: lst-ctn-kix_list_20-7;
        }
        ol.lst-kix_list_26-7 {
          list-style-type: none;
        }
        ol.lst-kix_list_26-6 {
          list-style-type: none;
        }
        ol.lst-kix_list_26-8 {
          list-style-type: none;
        }
        ol.lst-kix_list_26-3 {
          list-style-type: none;
        }
        ol.lst-kix_list_26-2 {
          list-style-type: none;
        }
        ol.lst-kix_list_12-0.start {
          counter-reset: lst-ctn-kix_list_12-0 0;
        }
        ol.lst-kix_list_26-5 {
          list-style-type: none;
        }
        ol.lst-kix_list_26-4 {
          list-style-type: none;
        }
        ol.lst-kix_list_26-1 {
          list-style-type: none;
        }
        ol.lst-kix_list_26-0 {
          list-style-type: none;
        }
        .lst-kix_list_32-2 > li:before {
          content: " ";
        }
        .lst-kix_list_32-1 > li:before {
          content: " ";
        }
        .lst-kix_list_32-3 > li:before {
          content: " ";
        }
        ol.lst-kix_list_21-6.start {
          counter-reset: lst-ctn-kix_list_21-6 0;
        }
        ol.lst-kix_list_3-7.start {
          counter-reset: lst-ctn-kix_list_3-7 0;
        }
        .lst-kix_list_14-8 > li:before {
          content: "" counter(lst-ctn-kix_list_14-8, lower-roman) ". ";
        }
        .lst-kix_list_39-7 > li {
          counter-increment: lst-ctn-kix_list_39-7;
        }
        .lst-kix_list_32-0 > li:before {
          content: "" counter(lst-ctn-kix_list_32-0, decimal) ". ";
        }
        .lst-kix_list_3-2 > li {
          counter-increment: lst-ctn-kix_list_3-2;
        }
        ol.lst-kix_list_15-5.start {
          counter-reset: lst-ctn-kix_list_15-5 0;
        }
        ol.lst-kix_list_39-8 {
          list-style-type: none;
        }
        .lst-kix_list_5-0 > li:before {
          content: "[ARTICLE " counter(lst-ctn-kix_list_5-0, upper-roman) " ";
        }
        ol.lst-kix_list_6-0 {
          list-style-type: none;
        }
        ol.lst-kix_list_6-1 {
          list-style-type: none;
        }
        .lst-kix_list_14-8 > li {
          counter-increment: lst-ctn-kix_list_14-8;
        }
        ol.lst-kix_list_22-3.start {
          counter-reset: lst-ctn-kix_list_22-3 0;
        }
        .lst-kix_list_5-4 > li {
          counter-increment: lst-ctn-kix_list_5-4;
        }
        ol.lst-kix_list_39-5 {
          list-style-type: none;
        }
        ol.lst-kix_list_39-4 {
          list-style-type: none;
        }
        ol.lst-kix_list_39-7 {
          list-style-type: none;
        }
        ol.lst-kix_list_39-6 {
          list-style-type: none;
        }
        .lst-kix_list_5-3 > li:before {
          content: "[(" counter(lst-ctn-kix_list_5-3, lower-roman) ") ";
        }
        ol.lst-kix_list_39-1 {
          list-style-type: none;
        }
        ol.lst-kix_list_24-6.start {
          counter-reset: lst-ctn-kix_list_24-6 0;
        }
        ol.lst-kix_list_39-0 {
          list-style-type: none;
        }
        .lst-kix_list_5-2 > li:before {
          content: "[(" counter(lst-ctn-kix_list_5-2, lower-latin) ") ";
        }
        ol.lst-kix_list_39-3 {
          list-style-type: none;
        }
        .lst-kix_list_8-3 > li {
          counter-increment: lst-ctn-kix_list_8-3;
        }
        ol.lst-kix_list_39-2 {
          list-style-type: none;
        }
        .lst-kix_list_5-1 > li:before {
          content: "[Section " counter(lst-ctn-kix_list_5-0, upper-roman) "."
            counter(lst-ctn-kix_list_5-1, decimal-leading-zero) " ";
        }
        .lst-kix_list_5-7 > li:before {
          content: " ";
        }
        .lst-kix_list_5-6 > li:before {
          content: " ";
        }
        .lst-kix_list_5-8 > li:before {
          content: " ";
        }
        ol.lst-kix_list_6-6 {
          list-style-type: none;
        }
        ol.lst-kix_list_6-7 {
          list-style-type: none;
        }
        .lst-kix_list_5-4 > li:before {
          content: "[(" counter(lst-ctn-kix_list_5-4, upper-latin) ") ";
        }
        ol.lst-kix_list_6-8 {
          list-style-type: none;
        }
        .lst-kix_list_5-5 > li:before {
          content: " ";
        }
        ol.lst-kix_list_6-2 {
          list-style-type: none;
        }
        ol.lst-kix_list_6-3 {
          list-style-type: none;
        }
        ol.lst-kix_list_6-4 {
          list-style-type: none;
        }
        ol.lst-kix_list_6-5 {
          list-style-type: none;
        }
        ol.lst-kix_list_12-5.start {
          counter-reset: lst-ctn-kix_list_12-5 0;
        }
        .lst-kix_list_6-1 > li:before {
          content: "" counter(lst-ctn-kix_list_6-1, lower-latin) ". ";
        }
        .lst-kix_list_6-3 > li:before {
          content: "" counter(lst-ctn-kix_list_6-3, decimal) ". ";
        }
        .lst-kix_list_6-8 > li {
          counter-increment: lst-ctn-kix_list_6-8;
        }
        .lst-kix_list_6-0 > li:before {
          content: "[" counter(lst-ctn-kix_list_6-0, decimal) ".   ";
        }
        .lst-kix_list_6-4 > li:before {
          content: "" counter(lst-ctn-kix_list_6-4, lower-latin) ". ";
        }
        ol.lst-kix_list_14-8.start {
          counter-reset: lst-ctn-kix_list_14-8 0;
        }
        .lst-kix_list_6-2 > li:before {
          content: "" counter(lst-ctn-kix_list_6-2, lower-roman) ". ";
        }
        ol.lst-kix_list_15-0.start {
          counter-reset: lst-ctn-kix_list_15-0 0;
        }
        .lst-kix_list_2-5 > li {
          counter-increment: lst-ctn-kix_list_2-5;
        }
        ol.lst-kix_list_3-2.start {
          counter-reset: lst-ctn-kix_list_3-2 0;
        }
        .lst-kix_list_6-8 > li:before {
          content: "" counter(lst-ctn-kix_list_6-8, lower-roman) ". ";
        }
        .lst-kix_list_6-5 > li:before {
          content: "" counter(lst-ctn-kix_list_6-5, lower-roman) ". ";
        }
        .lst-kix_list_6-7 > li:before {
          content: "" counter(lst-ctn-kix_list_6-7, lower-latin) ". ";
        }
        .lst-kix_list_6-6 > li:before {
          content: "" counter(lst-ctn-kix_list_6-6, decimal) ". ";
        }
        ol.lst-kix_list_17-8 {
          list-style-type: none;
        }
        ol.lst-kix_list_10-6.start {
          counter-reset: lst-ctn-kix_list_10-6 0;
        }
        .lst-kix_list_7-4 > li:before {
          content: " ";
        }
        .lst-kix_list_7-6 > li:before {
          content: "" counter(lst-ctn-kix_list_7-6, decimal) " ";
        }
        ol.lst-kix_list_17-4 {
          list-style-type: none;
        }
        .lst-kix_list_18-5 > li {
          counter-increment: lst-ctn-kix_list_18-5;
        }
        ol.lst-kix_list_19-7.start {
          counter-reset: lst-ctn-kix_list_19-7 0;
        }
        ol.lst-kix_list_17-5 {
          list-style-type: none;
        }
        ol.lst-kix_list_6-2.start {
          counter-reset: lst-ctn-kix_list_6-2 0;
        }
        .lst-kix_list_15-5 > li {
          counter-increment: lst-ctn-kix_list_15-5;
        }
        ol.lst-kix_list_17-6 {
          list-style-type: none;
        }
        ol.lst-kix_list_24-1.start {
          counter-reset: lst-ctn-kix_list_24-1 0;
        }
        ol.lst-kix_list_17-7 {
          list-style-type: none;
        }
        ol.lst-kix_list_17-0 {
          list-style-type: none;
        }
        .lst-kix_list_22-2 > li {
          counter-increment: lst-ctn-kix_list_22-2;
        }
        ol.lst-kix_list_17-1 {
          list-style-type: none;
        }
        ol.lst-kix_list_17-2 {
          list-style-type: none;
        }
        .lst-kix_list_7-2 > li:before {
          content: "[(" counter(lst-ctn-kix_list_7-2, lower-roman) ") ";
        }
        ol.lst-kix_list_17-3 {
          list-style-type: none;
        }
        .lst-kix_list_7-6 > li {
          counter-increment: lst-ctn-kix_list_7-6;
        }
        ol.lst-kix_list_22-8.start {
          counter-reset: lst-ctn-kix_list_22-8 0;
        }
        .lst-kix_list_34-8 > li:before {
          content: " ";
        }
        .lst-kix_list_31-0 > li:before {
          content: "\\0025cf  ";
        }
        .lst-kix_list_12-6 > li {
          counter-increment: lst-ctn-kix_list_12-6;
        }
        .lst-kix_list_24-3 > li {
          counter-increment: lst-ctn-kix_list_24-3;
        }
        .lst-kix_list_13-7 > li:before {
          content: "" counter(lst-ctn-kix_list_13-7, lower-latin) ". ";
        }
        .lst-kix_list_7-8 > li:before {
          content: " ";
        }
        ol.lst-kix_list_23-0.start {
          counter-reset: lst-ctn-kix_list_23-0 0;
        }
        .lst-kix_list_15-6 > li {
          counter-increment: lst-ctn-kix_list_15-6;
        }
        .lst-kix_list_4-7 > li {
          counter-increment: lst-ctn-kix_list_4-7;
        }
        ol.lst-kix_list_2-5.start {
          counter-reset: lst-ctn-kix_list_2-5 0;
        }
        .lst-kix_list_15-5 > li:before {
          content: " ";
        }
        .lst-kix_list_31-6 > li:before {
          content: " ";
        }
        .lst-kix_list_31-8 > li:before {
          content: " ";
        }
        ol.lst-kix_list_26-0.start {
          counter-reset: lst-ctn-kix_list_26-0 0;
        }
        .lst-kix_list_9-8 > li {
          counter-increment: lst-ctn-kix_list_9-8;
        }
        .lst-kix_list_13-4 > li {
          counter-increment: lst-ctn-kix_list_13-4;
        }
        .lst-kix_list_4-1 > li:before {
          content: "" counter(lst-ctn-kix_list_4-0, decimal) "."
            counter(lst-ctn-kix_list_4-1, decimal) " ";
        }
        .lst-kix_list_31-2 > li:before {
          content: " ";
        }
        .lst-kix_list_31-4 > li:before {
          content: " ";
        }
        .lst-kix_list_15-7 > li:before {
          content: " ";
        }
        .lst-kix_list_36-0 > li {
          counter-increment: lst-ctn-kix_list_36-0;
        }
        .lst-kix_list_17-7 > li {
          counter-increment: lst-ctn-kix_list_17-7;
        }
        .lst-kix_list_4-3 > li:before {
          content: "(" counter(lst-ctn-kix_list_4-3, lower-roman) ") ";
        }
        .lst-kix_list_4-5 > li:before {
          content: " ";
        }
        .lst-kix_list_1-8 > li {
          counter-increment: lst-ctn-kix_list_1-8;
        }
        ol.lst-kix_list_38-1.start {
          counter-reset: lst-ctn-kix_list_38-1 0;
        }
        .lst-kix_list_10-5 > li {
          counter-increment: lst-ctn-kix_list_10-5;
        }
        .lst-kix_list_15-1 > li:before {
          content: "" counter(lst-ctn-kix_list_15-0, decimal) "("
            counter(lst-ctn-kix_list_15-1, lower-latin) ") ";
        }
        ol.lst-kix_list_1-4.start {
          counter-reset: lst-ctn-kix_list_1-4 0;
        }
        .lst-kix_list_24-4 > li {
          counter-increment: lst-ctn-kix_list_24-4;
        }
        .lst-kix_list_15-3 > li:before {
          content: "(" counter(lst-ctn-kix_list_15-3, upper-latin) ") ";
        }
        .lst-kix_list_22-1 > li {
          counter-increment: lst-ctn-kix_list_22-1;
        }
        ol.lst-kix_list_4-4.start {
          counter-reset: lst-ctn-kix_list_4-4 0;
        }
        .lst-kix_list_16-2 > li {
          counter-increment: lst-ctn-kix_list_16-2;
        }
        ol.lst-kix_list_39-3.start {
          counter-reset: lst-ctn-kix_list_39-3 0;
        }
        .lst-kix_list_40-5 > li {
          counter-increment: lst-ctn-kix_list_40-5;
        }
        ol.lst-kix_list_9-2.start {
          counter-reset: lst-ctn-kix_list_9-2 0;
        }
        .lst-kix_list_20-0 > li {
          counter-increment: lst-ctn-kix_list_20-0;
        }
        ol.lst-kix_list_16-7.start {
          counter-reset: lst-ctn-kix_list_16-7 0;
        }
        .lst-kix_list_32-4 > li:before {
          content: " ";
        }
        .lst-kix_list_11-2 > li {
          counter-increment: lst-ctn-kix_list_11-2;
        }
        .lst-kix_list_26-5 > li {
          counter-increment: lst-ctn-kix_list_26-5;
        }
        .lst-kix_list_19-2 > li {
          counter-increment: lst-ctn-kix_list_19-2;
        }
        .lst-kix_list_33-4 > li:before {
          content: " ";
        }
        ol.lst-kix_list_8-8 {
          list-style-type: none;
        }
        .lst-kix_list_12-3 > li:before {
          content: "" counter(lst-ctn-kix_list_12-3, decimal) ". ";
        }
        .lst-kix_list_38-2 > li {
          counter-increment: lst-ctn-kix_list_38-2;
        }
        .lst-kix_list_32-6 > li:before {
          content: " ";
        }
        ol.lst-kix_list_8-4 {
          list-style-type: none;
        }
        .lst-kix_list_12-1 > li:before {
          content: "" counter(lst-ctn-kix_list_12-1, lower-latin) ". ";
        }
        ol.lst-kix_list_8-5 {
          list-style-type: none;
        }
        .lst-kix_list_33-0 > li:before {
          content: "" counter(lst-ctn-kix_list_33-0, decimal) ". ";
        }
        .lst-kix_list_33-2 > li:before {
          content: " ";
        }
        ol.lst-kix_list_8-6 {
          list-style-type: none;
        }
        ol.lst-kix_list_8-7 {
          list-style-type: none;
        }
        .lst-kix_list_32-8 > li:before {
          content: " ";
        }
        ol.lst-kix_list_8-0 {
          list-style-type: none;
        }
        .lst-kix_list_16-3 > li {
          counter-increment: lst-ctn-kix_list_16-3;
        }
        ol.lst-kix_list_8-1 {
          list-style-type: none;
        }
        .lst-kix_list_23-6 > li {
          counter-increment: lst-ctn-kix_list_23-6;
        }
        ol.lst-kix_list_8-2 {
          list-style-type: none;
        }
        .lst-kix_list_13-3 > li {
          counter-increment: lst-ctn-kix_list_13-3;
        }
        ol.lst-kix_list_13-6.start {
          counter-reset: lst-ctn-kix_list_13-6 0;
        }
        ol.lst-kix_list_8-3 {
          list-style-type: none;
        }
        ol.lst-kix_list_40-5.start {
          counter-reset: lst-ctn-kix_list_40-5 0;
        }
        ol.lst-kix_list_25-8.start {
          counter-reset: lst-ctn-kix_list_25-8 0;
        }
        .lst-kix_list_39-0 > li {
          counter-increment: lst-ctn-kix_list_39-0;
        }
        .lst-kix_list_34-0 > li:before {
          content: "" counter(lst-ctn-kix_list_34-0, decimal) ". ";
        }
        .lst-kix_list_10-4 > li {
          counter-increment: lst-ctn-kix_list_10-4;
        }
        .lst-kix_list_14-1 > li {
          counter-increment: lst-ctn-kix_list_14-1;
        }
        .lst-kix_list_21-4 > li {
          counter-increment: lst-ctn-kix_list_21-4;
        }
        .lst-kix_list_39-6 > li {
          counter-increment: lst-ctn-kix_list_39-6;
        }
        .lst-kix_list_13-3 > li:before {
          content: "" counter(lst-ctn-kix_list_13-3, decimal) ". ";
        }
        .lst-kix_list_34-4 > li:before {
          content: " ";
        }
        .lst-kix_list_34-6 > li:before {
          content: " ";
        }
        .lst-kix_list_13-5 > li:before {
          content: "" counter(lst-ctn-kix_list_13-5, lower-roman) ". ";
        }
        .lst-kix_list_12-5 > li:before {
          content: "" counter(lst-ctn-kix_list_12-5, lower-roman) ". ";
        }
        ol.lst-kix_list_13-7.start {
          counter-reset: lst-ctn-kix_list_13-7 0;
        }
        .lst-kix_list_18-4 > li {
          counter-increment: lst-ctn-kix_list_18-4;
        }
        .lst-kix_list_12-7 > li:before {
          content: "" counter(lst-ctn-kix_list_12-7, lower-latin) ". ";
        }
        .lst-kix_list_33-6 > li:before {
          content: " ";
        }
        ol.lst-kix_list_21-1.start {
          counter-reset: lst-ctn-kix_list_21-1 0;
        }
        .lst-kix_list_33-8 > li:before {
          content: " ";
        }
        .lst-kix_list_34-2 > li:before {
          content: " ";
        }
        .lst-kix_list_25-1 > li {
          counter-increment: lst-ctn-kix_list_25-1;
        }
        .lst-kix_list_13-1 > li:before {
          content: "" counter(lst-ctn-kix_list_13-1, lower-latin) ". ";
        }
        .lst-kix_list_30-5 > li:before {
          content: " ";
        }
        .lst-kix_list_22-8 > li {
          counter-increment: lst-ctn-kix_list_22-8;
        }
        .lst-kix_list_35-0 > li:before {
          content: "" counter(lst-ctn-kix_list_35-0, decimal) ". ";
        }
        .lst-kix_list_35-1 > li:before {
          content: " ";
        }
        .lst-kix_list_35-4 > li:before {
          content: " ";
        }
        .lst-kix_list_35-5 > li:before {
          content: " ";
        }
        ol.lst-kix_list_40-6.start {
          counter-reset: lst-ctn-kix_list_40-6 0;
        }
        ol.lst-kix_list_11-6 {
          list-style-type: none;
        }
        ol.lst-kix_list_11-7 {
          list-style-type: none;
        }
        .lst-kix_list_1-1 > li {
          counter-increment: lst-ctn-kix_list_1-1;
        }
        ol.lst-kix_list_11-8 {
          list-style-type: none;
        }
        .lst-kix_list_30-1 > li:before {
          content: " ";
        }
        ol.lst-kix_list_11-2 {
          list-style-type: none;
        }
        ol.lst-kix_list_2-6.start {
          counter-reset: lst-ctn-kix_list_2-6 0;
        }
        .lst-kix_list_3-0 > li:before {
          content: "ARTICLE " counter(lst-ctn-kix_list_3-0, upper-roman) " ";
        }
        ol.lst-kix_list_11-3 {
          list-style-type: none;
        }
        ol.lst-kix_list_11-4 {
          list-style-type: none;
        }
        .lst-kix_list_30-2 > li:before {
          content: " ";
        }
        ol.lst-kix_list_11-5 {
          list-style-type: none;
        }
        ol.lst-kix_list_20-5.start {
          counter-reset: lst-ctn-kix_list_20-5 0;
        }
        ol.lst-kix_list_13-1.start {
          counter-reset: lst-ctn-kix_list_13-1 0;
        }
        ol.lst-kix_list_11-0 {
          list-style-type: none;
        }
        ol.lst-kix_list_11-1 {
          list-style-type: none;
        }
        .lst-kix_list_4-0 > li {
          counter-increment: lst-ctn-kix_list_4-0;
        }
        ol.lst-kix_list_37-5.start {
          counter-reset: lst-ctn-kix_list_37-5 0;
        }
        .lst-kix_list_3-4 > li:before {
          content: "(" counter(lst-ctn-kix_list_3-4, upper-latin) ") ";
        }
        .lst-kix_list_3-3 > li:before {
          content: "(" counter(lst-ctn-kix_list_3-3, lower-roman) ") ";
        }
        .lst-kix_list_8-0 > li:before {
          content: "[" counter(lst-ctn-kix_list_8-0, decimal) ". ";
        }
        .lst-kix_list_30-6 > li:before {
          content: " ";
        }
        .lst-kix_list_8-7 > li:before {
          content: " ";
        }
        .lst-kix_list_38-3 > li {
          counter-increment: lst-ctn-kix_list_38-3;
        }
        .lst-kix_list_3-8 > li:before {
          content: " ";
        }
        ol.lst-kix_list_10-7.start {
          counter-reset: lst-ctn-kix_list_10-7 0;
        }
        .lst-kix_list_8-3 > li:before {
          content: "[(" counter(lst-ctn-kix_list_8-3, lower-roman) ") ";
        }
        ol.lst-kix_list_22-7 {
          list-style-type: none;
        }
        ol.lst-kix_list_22-6 {
          list-style-type: none;
        }
        ol.lst-kix_list_22-8 {
          list-style-type: none;
        }
        .lst-kix_list_3-7 > li:before {
          content: " ";
        }
        ol.lst-kix_list_22-3 {
          list-style-type: none;
        }
        ol.lst-kix_list_22-2 {
          list-style-type: none;
        }
        .lst-kix_list_8-4 > li:before {
          content: "[(" counter(lst-ctn-kix_list_8-4, upper-latin) ") ";
        }
        ol.lst-kix_list_22-5 {
          list-style-type: none;
        }
        ol.lst-kix_list_22-4 {
          list-style-type: none;
        }
        .lst-kix_list_19-1 > li {
          counter-increment: lst-ctn-kix_list_19-1;
        }
        ol.lst-kix_list_22-1 {
          list-style-type: none;
        }
        ol.lst-kix_list_22-0 {
          list-style-type: none;
        }
        ol.lst-kix_list_8-5.start {
          counter-reset: lst-ctn-kix_list_8-5 0;
        }
        ol.lst-kix_list_26-4.start {
          counter-reset: lst-ctn-kix_list_26-4 0;
        }
        .lst-kix_list_35-8 > li:before {
          content: " ";
        }
        .lst-kix_list_17-1 > li {
          counter-increment: lst-ctn-kix_list_17-1;
        }
        .lst-kix_list_11-1 > li:before {
          content: "" counter(lst-ctn-kix_list_11-1, lower-latin) ". ";
        }
        .lst-kix_list_11-0 > li:before {
          content: "" counter(lst-ctn-kix_list_11-0, lower-roman) ". ";
        }
        ol.lst-kix_list_9-3.start {
          counter-reset: lst-ctn-kix_list_9-3 0;
        }
        .lst-kix_list_8-8 > li:before {
          content: " ";
        }
        .lst-kix_list_37-4 > li {
          counter-increment: lst-ctn-kix_list_37-4;
        }
        ol.lst-kix_list_2-2 {
          list-style-type: none;
        }
        .lst-kix_list_16-8 > li:before {
          content: "" counter(lst-ctn-kix_list_16-8, lower-roman) ". ";
        }
        ol.lst-kix_list_2-3 {
          list-style-type: none;
        }
        ol.lst-kix_list_2-4 {
          list-style-type: none;
        }
        .lst-kix_list_16-7 > li:before {
          content: "" counter(lst-ctn-kix_list_16-7, lower-latin) ". ";
        }
        ol.lst-kix_list_2-5 {
          list-style-type: none;
        }
        .lst-kix_list_17-8 > li {
          counter-increment: lst-ctn-kix_list_17-8;
        }
        ol.lst-kix_list_2-0 {
          list-style-type: none;
        }
        ol.lst-kix_list_2-1 {
          list-style-type: none;
        }
        .lst-kix_list_4-8 > li:before {
          content: " ";
        }
        .lst-kix_list_21-5 > li {
          counter-increment: lst-ctn-kix_list_21-5;
        }
        .lst-kix_list_4-7 > li:before {
          content: "" counter(lst-ctn-kix_list_4-7, decimal) " ";
        }
        .lst-kix_list_14-2 > li {
          counter-increment: lst-ctn-kix_list_14-2;
        }
        ol.lst-kix_list_20-0.start {
          counter-reset: lst-ctn-kix_list_20-0 0;
        }
        .lst-kix_list_17-0 > li:before {
          content: "" counter(lst-ctn-kix_list_17-0, decimal) ".   ";
        }
        ol.lst-kix_list_35-0 {
          list-style-type: none;
        }
        .lst-kix_list_39-8 > li {
          counter-increment: lst-ctn-kix_list_39-8;
        }
        .lst-kix_list_16-0 > li:before {
          content: "" counter(lst-ctn-kix_list_16-0, decimal) ".   ";
        }
        .lst-kix_list_40-6 > li {
          counter-increment: lst-ctn-kix_list_40-6;
        }
        ol.lst-kix_list_4-8.start {
          counter-reset: lst-ctn-kix_list_4-8 0;
        }
        .lst-kix_list_8-4 > li {
          counter-increment: lst-ctn-kix_list_8-4;
        }
        .lst-kix_list_16-4 > li:before {
          content: "" counter(lst-ctn-kix_list_16-4, lower-latin) ". ";
        }
        ol.lst-kix_list_3-3.start {
          counter-reset: lst-ctn-kix_list_3-3 0;
        }
        .lst-kix_list_16-3 > li:before {
          content: "" counter(lst-ctn-kix_list_16-3, decimal) ". ";
        }
        ol.lst-kix_list_2-6 {
          list-style-type: none;
        }
        ol.lst-kix_list_2-7 {
          list-style-type: none;
        }
        ol.lst-kix_list_2-8 {
          list-style-type: none;
        }
        .lst-kix_list_11-3 > li {
          counter-increment: lst-ctn-kix_list_11-3;
        }
        ul.lst-kix_list_35-1 {
          list-style-type: none;
        }
        ul.lst-kix_list_35-2 {
          list-style-type: none;
        }
        ul.lst-kix_list_35-3 {
          list-style-type: none;
        }
        ul.lst-kix_list_35-4 {
          list-style-type: none;
        }
        ol.lst-kix_list_37-4.start {
          counter-reset: lst-ctn-kix_list_37-4 0;
        }
        ul.lst-kix_list_35-5 {
          list-style-type: none;
        }
        ol.lst-kix_list_39-7.start {
          counter-reset: lst-ctn-kix_list_39-7 0;
        }
        ol.lst-kix_list_8-6.start {
          counter-reset: lst-ctn-kix_list_8-6 0;
        }
        .lst-kix_list_17-7 > li:before {
          content: "" counter(lst-ctn-kix_list_17-7, lower-latin) ". ";
        }
        ol.lst-kix_list_38-2.start {
          counter-reset: lst-ctn-kix_list_38-2 0;
        }
        .lst-kix_list_17-8 > li:before {
          content: "" counter(lst-ctn-kix_list_17-8, lower-roman) ". ";
        }
        .lst-kix_list_33-0 > li {
          counter-increment: lst-ctn-kix_list_33-0;
        }
        .lst-kix_list_17-3 > li:before {
          content: "" counter(lst-ctn-kix_list_17-3, decimal) ". ";
        }
        .lst-kix_list_17-4 > li:before {
          content: "" counter(lst-ctn-kix_list_17-4, lower-latin) ". ";
        }
        ul.lst-kix_list_35-6 {
          list-style-type: none;
        }
        ul.lst-kix_list_35-7 {
          list-style-type: none;
        }
        ol.lst-kix_list_8-0.start {
          counter-reset: lst-ctn-kix_list_8-0 0;
        }
        ul.lst-kix_list_35-8 {
          list-style-type: none;
        }
        .lst-kix_list_7-0 > li:before {
          content: "[" counter(lst-ctn-kix_list_7-0, decimal) ". ";
        }
        ol.lst-kix_list_19-6.start {
          counter-reset: lst-ctn-kix_list_19-6 0;
        }
        ol.lst-kix_list_9-7.start {
          counter-reset: lst-ctn-kix_list_9-7 0;
        }
        ol.lst-kix_list_13-8 {
          list-style-type: none;
        }
        .lst-kix_list_2-4 > li:before {
          content: "" counter(lst-ctn-kix_list_2-4, lower-latin) ". ";
        }
        .lst-kix_list_2-8 > li:before {
          content: "" counter(lst-ctn-kix_list_2-8, lower-roman) ". ";
        }
        ol.lst-kix_list_13-4 {
          list-style-type: none;
        }
        ol.lst-kix_list_13-5 {
          list-style-type: none;
        }
        ol.lst-kix_list_13-6 {
          list-style-type: none;
        }
        ol.lst-kix_list_13-7 {
          list-style-type: none;
        }
        ol.lst-kix_list_13-0 {
          list-style-type: none;
        }
        ol.lst-kix_list_13-1 {
          list-style-type: none;
        }
        ol.lst-kix_list_13-2 {
          list-style-type: none;
        }
        .lst-kix_list_7-3 > li:before {
          content: "[(" counter(lst-ctn-kix_list_7-3, upper-latin) ") ";
        }
        ol.lst-kix_list_13-3 {
          list-style-type: none;
        }
        .lst-kix_list_10-0 > li:before {
          content: "" counter(lst-ctn-kix_list_10-0, lower-latin) ". ";
        }
        .lst-kix_list_9-7 > li {
          counter-increment: lst-ctn-kix_list_9-7;
        }
        ol.lst-kix_list_21-7.start {
          counter-reset: lst-ctn-kix_list_21-7 0;
        }
        .lst-kix_list_13-8 > li:before {
          content: "" counter(lst-ctn-kix_list_13-8, lower-roman) ". ";
        }
        .lst-kix_list_31-1 > li:before {
          content: " ";
        }
        .lst-kix_list_18-3 > li:before {
          content: " ";
        }
        .lst-kix_list_18-7 > li:before {
          content: " ";
        }
        .lst-kix_list_18-6 > li {
          counter-increment: lst-ctn-kix_list_18-6;
        }
        ol.lst-kix_list_3-8.start {
          counter-reset: lst-ctn-kix_list_3-8 0;
        }
        ol.lst-kix_list_39-8.start {
          counter-reset: lst-ctn-kix_list_39-8 0;
        }
        .lst-kix_list_7-7 > li:before {
          content: "" counter(lst-ctn-kix_list_7-7, decimal) " ";
        }
        .lst-kix_list_36-5 > li:before {
          content: " ";
        }
        ol.lst-kix_list_8-1.start {
          counter-reset: lst-ctn-kix_list_8-1 0;
        }
        .lst-kix_list_15-4 > li:before {
          content: " ";
        }
        .lst-kix_list_31-5 > li:before {
          content: " ";
        }
        ol.lst-kix_list_19-1.start {
          counter-reset: lst-ctn-kix_list_19-1 0;
        }
        ol.lst-kix_list_24-8 {
          list-style-type: none;
        }
        .lst-kix_list_10-4 > li:before {
          content: "" counter(lst-ctn-kix_list_10-4, lower-latin) ". ";
        }
        .lst-kix_list_10-8 > li:before {
          content: "" counter(lst-ctn-kix_list_10-8, lower-roman) ". ";
        }
        ol.lst-kix_list_20-4.start {
          counter-reset: lst-ctn-kix_list_20-4 0;
        }
        .lst-kix_list_4-0 > li:before {
          content: "" counter(lst-ctn-kix_list_4-0, decimal) ". ";
        }
        .lst-kix_list_36-1 > li:before {
          content: " ";
        }
        ol.lst-kix_list_24-5 {
          list-style-type: none;
        }
        ol.lst-kix_list_24-4 {
          list-style-type: none;
        }
        ol.lst-kix_list_24-7 {
          list-style-type: none;
        }
        ol.lst-kix_list_25-2.start {
          counter-reset: lst-ctn-kix_list_25-2 0;
        }
        .lst-kix_list_15-0 > li:before {
          content: "" counter(lst-ctn-kix_list_15-0, decimal) " ";
        }
        .lst-kix_list_15-8 > li:before {
          content: " ";
        }
        ol.lst-kix_list_24-6 {
          list-style-type: none;
        }
        ol.lst-kix_list_24-1 {
          list-style-type: none;
        }
        ol.lst-kix_list_38-7.start {
          counter-reset: lst-ctn-kix_list_38-7 0;
        }
        ol.lst-kix_list_14-3.start {
          counter-reset: lst-ctn-kix_list_14-3 0;
        }
        ol.lst-kix_list_24-0 {
          list-style-type: none;
        }
        ol.lst-kix_list_24-3 {
          list-style-type: none;
        }
        .lst-kix_list_15-7 > li {
          counter-increment: lst-ctn-kix_list_15-7;
        }
        ol.lst-kix_list_24-2 {
          list-style-type: none;
        }
        .lst-kix_list_4-4 > li:before {
          content: "(" counter(lst-ctn-kix_list_4-4, upper-latin) ") ";
        }
        .lst-kix_list_9-3 > li:before {
          content: "" counter(lst-ctn-kix_list_9-3, decimal) ". ";
        }
        ol.lst-kix_list_7-0.start {
          counter-reset: lst-ctn-kix_list_7-0 0;
        }
        .lst-kix_list_12-8 > li {
          counter-increment: lst-ctn-kix_list_12-8;
        }
        ol.lst-kix_list_13-2.start {
          counter-reset: lst-ctn-kix_list_13-2 0;
        }
        ol.lst-kix_list_4-0 {
          list-style-type: none;
        }
        ol.lst-kix_list_4-1 {
          list-style-type: none;
        }
        ol.lst-kix_list_4-2 {
          list-style-type: none;
        }
        ol.lst-kix_list_4-3 {
          list-style-type: none;
        }
        ol.lst-kix_list_37-7 {
          list-style-type: none;
        }
        ol.lst-kix_list_37-6 {
          list-style-type: none;
        }
        ol.lst-kix_list_14-4.start {
          counter-reset: lst-ctn-kix_list_14-4 0;
        }
        .lst-kix_list_9-7 > li:before {
          content: "" counter(lst-ctn-kix_list_9-7, lower-latin) ". ";
        }
        .lst-kix_list_2-4 > li {
          counter-increment: lst-ctn-kix_list_2-4;
        }
        .lst-kix_list_29-4 > li:before {
          content: " ";
        }
        .lst-kix_list_29-8 > li:before {
          content: " ";
        }
        ol.lst-kix_list_37-8 {
          list-style-type: none;
        }
        ol.lst-kix_list_3-6.start {
          counter-reset: lst-ctn-kix_list_3-6 0;
        }
        ol.lst-kix_list_37-3 {
          list-style-type: none;
        }
        ol.lst-kix_list_37-2 {
          list-style-type: none;
        }
        .lst-kix_list_32-5 > li:before {
          content: " ";
        }
        ol.lst-kix_list_37-5 {
          list-style-type: none;
        }
        ol.lst-kix_list_37-4 {
          list-style-type: none;
        }
        .lst-kix_list_11-4 > li:before {
          content: "" counter(lst-ctn-kix_list_11-4, lower-latin) ". ";
        }
        ol.lst-kix_list_37-1 {
          list-style-type: none;
        }
        .lst-kix_list_12-4 > li:before {
          content: "" counter(lst-ctn-kix_list_12-4, lower-latin) ". ";
        }
        ol.lst-kix_list_37-0 {
          list-style-type: none;
        }
        .lst-kix_list_5-3 > li {
          counter-increment: lst-ctn-kix_list_5-3;
        }
        ol.lst-kix_list_37-0.start {
          counter-reset: lst-ctn-kix_list_37-0 0;
        }
        .lst-kix_list_29-0 > li:before {
          content: "\\0025cf  ";
        }
        ol.lst-kix_list_4-8 {
          list-style-type: none;
        }
        .lst-kix_list_7-4 > li {
          counter-increment: lst-ctn-kix_list_7-4;
        }
        .lst-kix_list_33-1 > li:before {
          content: " ";
        }
        .lst-kix_list_1-0 > li:before {
          content: "" counter(lst-ctn-kix_list_1-0, decimal) ". ";
        }
        ol.lst-kix_list_19-2.start {
          counter-reset: lst-ctn-kix_list_19-2 0;
        }
        ol.lst-kix_list_38-5.start {
          counter-reset: lst-ctn-kix_list_38-5 0;
        }
        ol.lst-kix_list_4-4 {
          list-style-type: none;
        }
        .lst-kix_list_11-8 > li:before {
          content: "" counter(lst-ctn-kix_list_11-8, lower-roman) ". ";
        }
        ol.lst-kix_list_4-5 {
          list-style-type: none;
        }
        ol.lst-kix_list_2-0.start {
          counter-reset: lst-ctn-kix_list_2-0 0;
        }
        ol.lst-kix_list_4-6 {
          list-style-type: none;
        }
        .lst-kix_list_12-0 > li:before {
          content: "" counter(lst-ctn-kix_list_12-0, lower-roman) ". ";
        }
        ol.lst-kix_list_4-7 {
          list-style-type: none;
        }
        .lst-kix_list_1-4 > li:before {
          content: " ";
        }
        .lst-kix_list_13-0 > li:before {
          content: "" counter(lst-ctn-kix_list_13-0, upper-latin) ". ";
        }
        .lst-kix_list_1-6 > li {
          counter-increment: lst-ctn-kix_list_1-6;
        }
        .lst-kix_list_13-4 > li:before {
          content: "" counter(lst-ctn-kix_list_13-4, lower-latin) ". ";
        }
        .lst-kix_list_34-5 > li:before {
          content: " ";
        }
        ol.lst-kix_list_26-5.start {
          counter-reset: lst-ctn-kix_list_26-5 0;
        }
        ol.lst-kix_list_38-6.start {
          counter-reset: lst-ctn-kix_list_38-6 0;
        }
        .lst-kix_list_33-5 > li:before {
          content: " ";
        }
        ol.lst-kix_list_19-3.start {
          counter-reset: lst-ctn-kix_list_19-3 0;
        }
        .lst-kix_list_2-0 > li:before {
          content: "" counter(lst-ctn-kix_list_2-0, decimal) ".   ";
        }
        ol.lst-kix_list_2-1.start {
          counter-reset: lst-ctn-kix_list_2-1 0;
        }
        .lst-kix_list_4-5 > li {
          counter-increment: lst-ctn-kix_list_4-5;
        }
        ol.lst-kix_list_9-8.start {
          counter-reset: lst-ctn-kix_list_9-8 0;
        }
        .lst-kix_list_1-8 > li:before {
          content: " ";
        }
        .lst-kix_list_34-1 > li:before {
          content: " ";
        }
        .lst-kix_list_12-8 > li:before {
          content: "" counter(lst-ctn-kix_list_12-8, lower-roman) ". ";
        }
        .lst-kix_list_8-2 > li {
          counter-increment: lst-ctn-kix_list_8-2;
        }
        .lst-kix_list_19-0 > li:before {
          content: "Article " counter(lst-ctn-kix_list_19-0, upper-roman) ". ";
        }
        ol.lst-kix_list_17-7.start {
          counter-reset: lst-ctn-kix_list_17-7 0;
        }
        ol.lst-kix_list_12-6.start {
          counter-reset: lst-ctn-kix_list_12-6 0;
        }
        .lst-kix_list_23-8 > li {
          counter-increment: lst-ctn-kix_list_23-8;
        }
        .lst-kix_list_8-1 > li {
          counter-increment: lst-ctn-kix_list_8-1;
        }
        ol.lst-kix_list_8-2.start {
          counter-reset: lst-ctn-kix_list_8-2 0;
        }
        ol.lst-kix_list_26-1.start {
          counter-reset: lst-ctn-kix_list_26-1 0;
        }
        .lst-kix_list_19-2 > li:before {
          content: "(" counter(lst-ctn-kix_list_19-2, lower-latin) ") ";
        }
        ol.lst-kix_list_3-1.start {
          counter-reset: lst-ctn-kix_list_3-1 0;
        }
        ol.lst-kix_list_21-0.start {
          counter-reset: lst-ctn-kix_list_21-0 0;
        }
        .lst-kix_list_7-0 > li {
          counter-increment: lst-ctn-kix_list_7-0;
        }
        .lst-kix_list_19-0 > li {
          counter-increment: lst-ctn-kix_list_19-0;
        }
        .lst-kix_list_2-3 > li {
          counter-increment: lst-ctn-kix_list_2-3;
        }
        ol.lst-kix_list_19-8.start {
          counter-reset: lst-ctn-kix_list_19-8 0;
        }
        .lst-kix_list_1-2 > li {
          counter-increment: lst-ctn-kix_list_1-2;
        }
        .lst-kix_list_19-8 > li:before {
          content: "" counter(lst-ctn-kix_list_19-8, lower-roman) ". ";
        }
        ol.lst-kix_list_14-7.start {
          counter-reset: lst-ctn-kix_list_14-7 0;
        }
        ol.lst-kix_list_20-8.start {
          counter-reset: lst-ctn-kix_list_20-8 0;
        }
        .lst-kix_list_19-5 > li:before {
          content: "" counter(lst-ctn-kix_list_19-5, lower-latin) ") ";
        }
        .lst-kix_list_19-7 > li:before {
          content: "" counter(lst-ctn-kix_list_19-7, lower-latin) ". ";
        }
        .lst-kix_list_9-2 > li {
          counter-increment: lst-ctn-kix_list_9-2;
        }
        ol.lst-kix_list_23-8.start {
          counter-reset: lst-ctn-kix_list_23-8 0;
        }
        .lst-kix_list_24-5 > li {
          counter-increment: lst-ctn-kix_list_24-5;
        }
        .lst-kix_list_37-7 > li:before {
          content: "" counter(lst-ctn-kix_list_37-0, decimal) "."
            counter(lst-ctn-kix_list_37-1, decimal) "."
            counter(lst-ctn-kix_list_37-2, decimal) "."
            counter(lst-ctn-kix_list_37-3, decimal) "."
            counter(lst-ctn-kix_list_37-4, decimal) "."
            counter(lst-ctn-kix_list_37-5, decimal) "."
            counter(lst-ctn-kix_list_37-6, decimal) "."
            counter(lst-ctn-kix_list_37-7, decimal) ". ";
        }
        ol.lst-kix_list_17-2.start {
          counter-reset: lst-ctn-kix_list_17-2 0;
        }
        .lst-kix_list_13-2 > li {
          counter-increment: lst-ctn-kix_list_13-2;
        }
        ol.lst-kix_list_21-5.start {
          counter-reset: lst-ctn-kix_list_21-5 0;
        }
        .lst-kix_list_38-0 > li:before {
          content: "" counter(lst-ctn-kix_list_38-0, decimal) ". ";
        }
        .lst-kix_list_19-7 > li {
          counter-increment: lst-ctn-kix_list_19-7;
        }
        .lst-kix_list_38-1 > li:before {
          content: "" counter(lst-ctn-kix_list_38-0, decimal) "."
            counter(lst-ctn-kix_list_38-1, decimal) ". ";
        }
        .lst-kix_list_14-3 > li {
          counter-increment: lst-ctn-kix_list_14-3;
        }
        ol.lst-kix_list_15-6.start {
          counter-reset: lst-ctn-kix_list_15-6 0;
        }
        .lst-kix_list_37-2 > li:before {
          content: "" counter(lst-ctn-kix_list_37-0, decimal) "."
            counter(lst-ctn-kix_list_37-1, decimal) "."
            counter(lst-ctn-kix_list_37-2, decimal) ". ";
        }
        .lst-kix_list_37-4 > li:before {
          content: "" counter(lst-ctn-kix_list_37-0, decimal) "."
            counter(lst-ctn-kix_list_37-1, decimal) "."
            counter(lst-ctn-kix_list_37-2, decimal) "."
            counter(lst-ctn-kix_list_37-3, decimal) "."
            counter(lst-ctn-kix_list_37-4, decimal) ". ";
        }
        .lst-kix_list_25-6 > li {
          counter-increment: lst-ctn-kix_list_25-6;
        }
        .lst-kix_list_37-5 > li:before {
          content: "" counter(lst-ctn-kix_list_37-0, decimal) "."
            counter(lst-ctn-kix_list_37-1, decimal) "."
            counter(lst-ctn-kix_list_37-2, decimal) "."
            counter(lst-ctn-kix_list_37-3, decimal) "."
            counter(lst-ctn-kix_list_37-4, decimal) "."
            counter(lst-ctn-kix_list_37-5, decimal) ". ";
        }
        ul.lst-kix_list_31-2 {
          list-style-type: none;
        }
        .lst-kix_list_12-1 > li {
          counter-increment: lst-ctn-kix_list_12-1;
        }
        ul.lst-kix_list_31-3 {
          list-style-type: none;
        }
        ul.lst-kix_list_31-4 {
          list-style-type: none;
        }
        ul.lst-kix_list_31-5 {
          list-style-type: none;
        }
        ul.lst-kix_list_31-6 {
          list-style-type: none;
        }
        ul.lst-kix_list_31-7 {
          list-style-type: none;
        }
        ul.lst-kix_list_31-8 {
          list-style-type: none;
        }
        .lst-kix_list_18-1 > li:before {
          content: "\\0025cf  ";
        }
        .lst-kix_list_38-8 > li:before {
          content: "" counter(lst-ctn-kix_list_38-0, decimal) "."
            counter(lst-ctn-kix_list_38-1, decimal) "."
            counter(lst-ctn-kix_list_38-2, decimal) "."
            counter(lst-ctn-kix_list_38-3, decimal) "."
            counter(lst-ctn-kix_list_38-4, decimal) "."
            counter(lst-ctn-kix_list_38-5, decimal) "."
            counter(lst-ctn-kix_list_38-6, decimal) "."
            counter(lst-ctn-kix_list_38-7, decimal) "."
            counter(lst-ctn-kix_list_38-8, decimal) ". ";
        }
        ul.lst-kix_list_31-0 {
          list-style-type: none;
        }
        .lst-kix_list_40-3 > li {
          counter-increment: lst-ctn-kix_list_40-3;
        }
        ul.lst-kix_list_31-1 {
          list-style-type: none;
        }
        ol.lst-kix_list_25-4.start {
          counter-reset: lst-ctn-kix_list_25-4 0;
        }
        .lst-kix_list_38-3 > li:before {
          content: "" counter(lst-ctn-kix_list_38-0, decimal) "."
            counter(lst-ctn-kix_list_38-1, decimal) "."
            counter(lst-ctn-kix_list_38-2, decimal) "."
            counter(lst-ctn-kix_list_38-3, decimal) ". ";
        }
        .lst-kix_list_23-4 > li {
          counter-increment: lst-ctn-kix_list_23-4;
        }
        ol.lst-kix_list_23-1.start {
          counter-reset: lst-ctn-kix_list_23-1 0;
        }
        .lst-kix_list_38-6 > li:before {
          content: "" counter(lst-ctn-kix_list_38-0, decimal) "."
            counter(lst-ctn-kix_list_38-1, decimal) "."
            counter(lst-ctn-kix_list_38-2, decimal) "."
            counter(lst-ctn-kix_list_38-3, decimal) "."
            counter(lst-ctn-kix_list_38-4, decimal) "."
            counter(lst-ctn-kix_list_38-5, decimal) "."
            counter(lst-ctn-kix_list_38-6, decimal) ". ";
        }
        ol.lst-kix_list_32-0.start {
          counter-reset: lst-ctn-kix_list_32-0 0;
        }
        ol.lst-kix_list_2-4.start {
          counter-reset: lst-ctn-kix_list_2-4 0;
        }
        .lst-kix_list_23-1 > li {
          counter-increment: lst-ctn-kix_list_23-1;
        }
        ol.lst-kix_list_1-3 {
          list-style-type: none;
        }
        ol.lst-kix_list_1-4 {
          list-style-type: none;
        }
        .lst-kix_list_2-7 > li:before {
          content: "" counter(lst-ctn-kix_list_2-7, lower-latin) ". ";
        }
        .lst-kix_list_2-7 > li {
          counter-increment: lst-ctn-kix_list_2-7;
        }
        ol.lst-kix_list_1-5 {
          list-style-type: none;
        }
        .lst-kix_list_24-2 > li {
          counter-increment: lst-ctn-kix_list_24-2;
        }
        ol.lst-kix_list_1-6 {
          list-style-type: none;
        }
        ol.lst-kix_list_1-0 {
          list-style-type: none;
        }
        .lst-kix_list_2-5 > li:before {
          content: "" counter(lst-ctn-kix_list_2-5, lower-roman) ". ";
        }
        ol.lst-kix_list_1-1 {
          list-style-type: none;
        }
        ol.lst-kix_list_1-2 {
          list-style-type: none;
        }
        ol.lst-kix_list_17-0.start {
          counter-reset: lst-ctn-kix_list_17-0 0;
        }
        .lst-kix_list_27-5 > li:before {
          content: " ";
        }
        ol.lst-kix_list_32-0 {
          list-style-type: none;
        }
        ol.lst-kix_list_10-3.start {
          counter-reset: lst-ctn-kix_list_10-3 0;
        }
        .lst-kix_list_22-3 > li {
          counter-increment: lst-ctn-kix_list_22-3;
        }
        .lst-kix_list_18-6 > li:before {
          content: " ";
        }
        .lst-kix_list_39-5 > li:before {
          content: "(" counter(lst-ctn-kix_list_39-5, lower-roman) ") ";
        }
        .lst-kix_list_39-7 > li:before {
          content: "" counter(lst-ctn-kix_list_39-7, lower-latin) ". ";
        }
        .lst-kix_list_14-6 > li {
          counter-increment: lst-ctn-kix_list_14-6;
        }
        .lst-kix_list_10-1 > li:before {
          content: "" counter(lst-ctn-kix_list_10-1, lower-latin) ". ";
        }
        .lst-kix_list_18-4 > li:before {
          content: " ";
        }
        .lst-kix_list_7-7 > li {
          counter-increment: lst-ctn-kix_list_7-7;
        }
        ol.lst-kix_list_15-1.start {
          counter-reset: lst-ctn-kix_list_15-1 0;
        }
        ol.lst-kix_list_15-4.start {
          counter-reset: lst-ctn-kix_list_15-4 0;
        }
        ol.lst-kix_list_1-7 {
          list-style-type: none;
        }
        ol.lst-kix_list_1-8 {
          list-style-type: none;
        }
        .lst-kix_list_36-6 > li:before {
          content: " ";
        }
        ol.lst-kix_list_39-2.start {
          counter-reset: lst-ctn-kix_list_39-2 0;
        }
        .lst-kix_list_10-3 > li:before {
          content: "" counter(lst-ctn-kix_list_10-3, decimal) ". ";
        }
        .lst-kix_list_15-4 > li {
          counter-increment: lst-ctn-kix_list_15-4;
        }
        .lst-kix_list_2-6 > li {
          counter-increment: lst-ctn-kix_list_2-6;
        }
        ol.lst-kix_list_7-3.start {
          counter-reset: lst-ctn-kix_list_7-3 0;
        }
        .lst-kix_list_36-0 > li:before {
          content: "" counter(lst-ctn-kix_list_36-0, decimal) ". ";
        }
        .lst-kix_list_36-8 > li:before {
          content: " ";
        }
        ol.lst-kix_list_40-2.start {
          counter-reset: lst-ctn-kix_list_40-2 0;
        }
        .lst-kix_list_34-0 > li {
          counter-increment: lst-ctn-kix_list_34-0;
        }
        .lst-kix_list_26-4 > li {
          counter-increment: lst-ctn-kix_list_26-4;
        }
        ol.lst-kix_list_5-7.start {
          counter-reset: lst-ctn-kix_list_5-7 0;
        }
        .lst-kix_list_20-8 > li:before {
          content: "" counter(lst-ctn-kix_list_20-8, lower-roman) ". ";
        }
        .lst-kix_list_39-1 > li {
          counter-increment: lst-ctn-kix_list_39-1;
        }
        .lst-kix_list_3-4 > li {
          counter-increment: lst-ctn-kix_list_3-4;
        }
        .lst-kix_list_29-7 > li:before {
          content: " ";
        }
        .lst-kix_list_29-5 > li:before {
          content: " ";
        }
        .lst-kix_list_20-0 > li:before {
          content: "Article " counter(lst-ctn-kix_list_20-0, upper-roman) ". ";
        }
        .lst-kix_list_9-6 > li:before {
          content: "" counter(lst-ctn-kix_list_9-6, decimal) ". ";
        }
        ol.lst-kix_list_10-7 {
          list-style-type: none;
        }
        ol.lst-kix_list_10-8 {
          list-style-type: none;
        }
        .lst-kix_list_9-4 > li:before {
          content: "" counter(lst-ctn-kix_list_9-4, lower-latin) ". ";
        }
        ol.lst-kix_list_10-3 {
          list-style-type: none;
        }
        ol.lst-kix_list_10-4 {
          list-style-type: none;
        }
        ol.lst-kix_list_10-5 {
          list-style-type: none;
        }
        ol.lst-kix_list_10-6 {
          list-style-type: none;
        }
        .lst-kix_list_20-6 > li:before {
          content: "" counter(lst-ctn-kix_list_20-6, lower-roman) ") ";
        }
        .lst-kix_list_23-0 > li {
          counter-increment: lst-ctn-kix_list_23-0;
        }
        ol.lst-kix_list_10-0 {
          list-style-type: none;
        }
        ol.lst-kix_list_10-1 {
          list-style-type: none;
        }
        ol.lst-kix_list_10-2 {
          list-style-type: none;
        }
        ol.lst-kix_list_12-1.start {
          counter-reset: lst-ctn-kix_list_12-1 0;
        }
        .lst-kix_list_11-5 > li:before {
          content: "" counter(lst-ctn-kix_list_11-5, lower-roman) ". ";
        }
        ol.lst-kix_list_21-2.start {
          counter-reset: lst-ctn-kix_list_21-2 0;
        }
        .lst-kix_list_20-6 > li {
          counter-increment: lst-ctn-kix_list_20-6;
        }
        .lst-kix_list_1-1 > li:before {
          content: "(" counter(lst-ctn-kix_list_1-1, lower-latin) ") ";
        }
        .lst-kix_list_11-7 > li:before {
          content: "" counter(lst-ctn-kix_list_11-7, lower-latin) ". ";
        }
        .lst-kix_list_8-5 > li {
          counter-increment: lst-ctn-kix_list_8-5;
        }
        ol.lst-kix_list_25-7.start {
          counter-reset: lst-ctn-kix_list_25-7 0;
        }
        ul.lst-kix_list_33-1 {
          list-style-type: none;
        }
        .lst-kix_list_1-3 > li:before {
          content: "(" counter(lst-ctn-kix_list_1-3, upper-latin) ") ";
        }
        ul.lst-kix_list_33-2 {
          list-style-type: none;
        }
        ul.lst-kix_list_33-3 {
          list-style-type: none;
        }
        ul.lst-kix_list_33-4 {
          list-style-type: none;
        }
        ul.lst-kix_list_33-5 {
          list-style-type: none;
        }
        ul.lst-kix_list_33-6 {
          list-style-type: none;
        }
        .lst-kix_list_28-3 > li:before {
          content: " ";
        }
        ol.lst-kix_list_10-5.start {
          counter-reset: lst-ctn-kix_list_10-5 0;
        }
        ul.lst-kix_list_33-7 {
          list-style-type: none;
        }
        ol.lst-kix_list_21-8 {
          list-style-type: none;
        }
        ol.lst-kix_list_21-7 {
          list-style-type: none;
        }
        ol.lst-kix_list_2-7.start {
          counter-reset: lst-ctn-kix_list_2-7 0;
        }
        ol.lst-kix_list_21-4 {
          list-style-type: none;
        }
        ol.lst-kix_list_21-3 {
          list-style-type: none;
        }
        ol.lst-kix_list_21-6 {
          list-style-type: none;
        }
        .lst-kix_list_14-7 > li {
          counter-increment: lst-ctn-kix_list_14-7;
        }
        ol.lst-kix_list_21-5 {
          list-style-type: none;
        }
        ol.lst-kix_list_21-0 {
          list-style-type: none;
        }
        ol.lst-kix_list_7-5.start {
          counter-reset: lst-ctn-kix_list_7-5 0;
        }
        ol.lst-kix_list_21-2 {
          list-style-type: none;
        }
        ol.lst-kix_list_21-1 {
          list-style-type: none;
        }
        ol.lst-kix_list_40-4.start {
          counter-reset: lst-ctn-kix_list_40-4 0;
        }
        .lst-kix_list_27-7 > li:before {
          content: " ";
        }
        .lst-kix_list_25-7 > li {
          counter-increment: lst-ctn-kix_list_25-7;
        }
        ul.lst-kix_list_33-8 {
          list-style-type: none;
        }
        ol.lst-kix_list_39-4.start {
          counter-reset: lst-ctn-kix_list_39-4 0;
        }
        .lst-kix_list_28-1 > li:before {
          content: " ";
        }
        ol.lst-kix_list_19-6 {
          list-style-type: none;
        }
        .lst-kix_list_35-2 > li:before {
          content: " ";
        }
        ol.lst-kix_list_19-7 {
          list-style-type: none;
        }
        .lst-kix_list_3-1 > li {
          counter-increment: lst-ctn-kix_list_3-1;
        }
        ol.lst-kix_list_19-8 {
          list-style-type: none;
        }
        .lst-kix_list_30-3 > li:before {
          content: " ";
        }
        ol.lst-kix_list_19-2 {
          list-style-type: none;
        }
        ol.lst-kix_list_34-0.start {
          counter-reset: lst-ctn-kix_list_34-0 0;
        }
        .lst-kix_list_26-8 > li {
          counter-increment: lst-ctn-kix_list_26-8;
        }
        ol.lst-kix_list_19-3 {
          list-style-type: none;
        }
        ol.lst-kix_list_19-4 {
          list-style-type: none;
        }
        .lst-kix_list_30-8 > li:before {
          content: " ";
        }
        ol.lst-kix_list_19-5 {
          list-style-type: none;
        }
        .lst-kix_list_35-7 > li:before {
          content: " ";
        }
        ol.lst-kix_list_19-0 {
          list-style-type: none;
        }
        ol.lst-kix_list_39-1.start {
          counter-reset: lst-ctn-kix_list_39-1 0;
        }
        ol.lst-kix_list_19-1 {
          list-style-type: none;
        }
        ol.lst-kix_list_7-7.start {
          counter-reset: lst-ctn-kix_list_7-7 0;
        }
        .lst-kix_list_3-1 > li:before {
          content: "Section " counter(lst-ctn-kix_list_3-0, upper-roman) "."
            counter(lst-ctn-kix_list_3-1, decimal-leading-zero) " ";
        }
        .lst-kix_list_26-6 > li:before {
          content: "" counter(lst-ctn-kix_list_26-0, decimal) "."
            counter(lst-ctn-kix_list_26-1, decimal) "."
            counter(lst-ctn-kix_list_26-2, decimal) "."
            counter(lst-ctn-kix_list_26-3, decimal) "."
            counter(lst-ctn-kix_list_26-4, decimal) "."
            counter(lst-ctn-kix_list_26-5, decimal) "."
            counter(lst-ctn-kix_list_26-6, decimal) ". ";
        }
        .lst-kix_list_14-0 > li {
          counter-increment: lst-ctn-kix_list_14-0;
        }
        ol.lst-kix_list_17-4.start {
          counter-reset: lst-ctn-kix_list_17-4 0;
        }
        .lst-kix_list_8-2 > li:before {
          content: "[(" counter(lst-ctn-kix_list_8-2, lower-latin) ") ";
        }
        .lst-kix_list_12-0 > li {
          counter-increment: lst-ctn-kix_list_12-0;
        }
        ol.lst-kix_list_12-3.start {
          counter-reset: lst-ctn-kix_list_12-3 0;
        }
        ol.lst-kix_list_40-0.start {
          counter-reset: lst-ctn-kix_list_40-0 0;
        }
        .lst-kix_list_21-2 > li:before {
          content: "" counter(lst-ctn-kix_list_21-0, decimal) "."
            counter(lst-ctn-kix_list_21-1, decimal) "."
            counter(lst-ctn-kix_list_21-2, decimal) ". ";
        }
        .lst-kix_list_8-5 > li:before {
          content: " ";
        }
        .lst-kix_list_2-0 > li {
          counter-increment: lst-ctn-kix_list_2-0;
        }
        .lst-kix_list_15-1 > li {
          counter-increment: lst-ctn-kix_list_15-1;
        }
        .lst-kix_list_26-3 > li:before {
          content: "" counter(lst-ctn-kix_list_26-0, decimal) "."
            counter(lst-ctn-kix_list_26-1, decimal) "."
            counter(lst-ctn-kix_list_26-2, decimal) "."
            counter(lst-ctn-kix_list_26-3, decimal) ". ";
        }
        .lst-kix_list_3-6 > li:before {
          content: " ";
        }
        .lst-kix_list_21-7 > li:before {
          content: "" counter(lst-ctn-kix_list_21-0, decimal) "."
            counter(lst-ctn-kix_list_21-1, decimal) "."
            counter(lst-ctn-kix_list_21-2, decimal) "."
            counter(lst-ctn-kix_list_21-3, decimal) "."
            counter(lst-ctn-kix_list_21-4, decimal) "."
            counter(lst-ctn-kix_list_21-5, decimal) "."
            counter(lst-ctn-kix_list_21-6, decimal) "."
            counter(lst-ctn-kix_list_21-7, decimal) ". ";
        }
        ol.lst-kix_list_5-0.start {
          counter-reset: lst-ctn-kix_list_5-0 0;
        }
        .lst-kix_list_11-2 > li:before {
          content: "" counter(lst-ctn-kix_list_11-2, lower-roman) ". ";
        }
        .lst-kix_list_39-4 > li {
          counter-increment: lst-ctn-kix_list_39-4;
        }
        .lst-kix_list_40-4 > li {
          counter-increment: lst-ctn-kix_list_40-4;
        }
        ol.lst-kix_list_12-4.start {
          counter-reset: lst-ctn-kix_list_12-4 0;
        }
        .lst-kix_list_16-6 > li:before {
          content: "" counter(lst-ctn-kix_list_16-6, decimal) ". ";
        }
        ol.lst-kix_list_10-1.start {
          counter-reset: lst-ctn-kix_list_10-1 0;
        }
        .lst-kix_list_25-2 > li:before {
          content: "" counter(lst-ctn-kix_list_25-0, decimal) "."
            counter(lst-ctn-kix_list_25-1, decimal) "."
            counter(lst-ctn-kix_list_25-2, decimal) ". ";
        }
        .lst-kix_list_18-2 > li {
          counter-increment: lst-ctn-kix_list_18-2;
        }
        ol.lst-kix_list_5-6.start {
          counter-reset: lst-ctn-kix_list_5-6 0;
        }
        .lst-kix_list_22-6 > li {
          counter-increment: lst-ctn-kix_list_22-6;
        }
        .lst-kix_list_16-1 > li:before {
          content: "" counter(lst-ctn-kix_list_16-1, lower-latin) ". ";
        }
        .lst-kix_list_7-3 > li {
          counter-increment: lst-ctn-kix_list_7-3;
        }
        ol.lst-kix_list_39-0.start {
          counter-reset: lst-ctn-kix_list_39-0 0;
        }
        .lst-kix_list_25-0 > li {
          counter-increment: lst-ctn-kix_list_25-0;
        }
        .lst-kix_list_19-3 > li {
          counter-increment: lst-ctn-kix_list_19-3;
        }
        ol.lst-kix_list_7-8.start {
          counter-reset: lst-ctn-kix_list_7-8 0;
        }
        .lst-kix_list_12-4 > li {
          counter-increment: lst-ctn-kix_list_12-4;
        }
        .lst-kix_list_23-7 > li {
          counter-increment: lst-ctn-kix_list_23-7;
        }
        ol.lst-kix_list_10-2.start {
          counter-reset: lst-ctn-kix_list_10-2 0;
        }
        .lst-kix_list_39-2 > li:before {
          content: "" counter(lst-ctn-kix_list_39-2, lower-roman) ") ";
        }
        .lst-kix_list_12-7 > li {
          counter-increment: lst-ctn-kix_list_12-7;
        }
        .lst-kix_list_40-0 > li {
          counter-increment: lst-ctn-kix_list_40-0;
        }
        .lst-kix_list_17-2 > li:before {
          content: "" counter(lst-ctn-kix_list_17-2, lower-roman) ". ";
        }
        ol.lst-kix_list_5-5.start {
          counter-reset: lst-ctn-kix_list_5-5 0;
        }
        .lst-kix_list_30-0 > li:before {
          content: "\\0025cf  ";
        }
        ol.lst-kix_list_17-3.start {
          counter-reset: lst-ctn-kix_list_17-3 0;
        }
        ol.lst-kix_list_40-1.start {
          counter-reset: lst-ctn-kix_list_40-1 0;
        }
        .lst-kix_list_17-5 > li:before {
          content: "" counter(lst-ctn-kix_list_17-5, lower-roman) ". ";
        }
        .lst-kix_list_6-2 > li {
          counter-increment: lst-ctn-kix_list_6-2;
        }
        .lst-kix_list_27-2 > li:before {
          content: " ";
        }
        .lst-kix_list_22-3 > li:before {
          content: "(" counter(lst-ctn-kix_list_22-3, decimal) ") ";
        }
        .lst-kix_list_7-1 > li:before {
          content: "[(" counter(lst-ctn-kix_list_7-1, lower-latin) ") ";
        }
        .lst-kix_list_13-5 > li {
          counter-increment: lst-ctn-kix_list_13-5;
        }
        .lst-kix_list_9-6 > li {
          counter-increment: lst-ctn-kix_list_9-6;
        }
        ol.lst-kix_list_23-3.start {
          counter-reset: lst-ctn-kix_list_23-3 0;
        }
        ol.lst-kix_list_5-4.start {
          counter-reset: lst-ctn-kix_list_5-4 0;
        }
        ol.lst-kix_list_5-1.start {
          counter-reset: lst-ctn-kix_list_5-1 0;
        }
        .lst-kix_list_20-3 > li {
          counter-increment: lst-ctn-kix_list_20-3;
        }
        .lst-kix_list_25-3 > li {
          counter-increment: lst-ctn-kix_list_25-3;
        }
        .lst-kix_list_16-6 > li {
          counter-increment: lst-ctn-kix_list_16-6;
        }
        .lst-kix_list_11-6 > li {
          counter-increment: lst-ctn-kix_list_11-6;
        }
        .lst-kix_list_31-7 > li:before {
          content: " ";
        }
        ol.lst-kix_list_10-0.start {
          counter-reset: lst-ctn-kix_list_10-0 0;
        }
        ol.lst-kix_list_17-8.start {
          counter-reset: lst-ctn-kix_list_17-8 0;
        }
        .lst-kix_list_3-8 > li {
          counter-increment: lst-ctn-kix_list_3-8;
        }
        .lst-kix_list_4-6 > li {
          counter-increment: lst-ctn-kix_list_4-6;
        }
        .lst-kix_list_1-5 > li {
          counter-increment: lst-ctn-kix_list_1-5;
        }
        .lst-kix_list_37-1 > li {
          counter-increment: lst-ctn-kix_list_37-1;
        }
        ol.lst-kix_list_17-5.start {
          counter-reset: lst-ctn-kix_list_17-5 0;
        }
        .lst-kix_list_4-2 > li:before {
          content: "(" counter(lst-ctn-kix_list_4-2, lower-latin) ") ";
        }
        ol.lst-kix_list_23-6.start {
          counter-reset: lst-ctn-kix_list_23-6 0;
        }
        .lst-kix_list_17-4 > li {
          counter-increment: lst-ctn-kix_list_17-4;
        }
        .lst-kix_list_15-2 > li:before {
          content: "(" counter(lst-ctn-kix_list_15-2, lower-roman) ") ";
        }
        .lst-kix_list_36-3 > li:before {
          content: " ";
        }
        .lst-kix_list_26-1 > li {
          counter-increment: lst-ctn-kix_list_26-1;
        }
        .lst-kix_list_10-8 > li {
          counter-increment: lst-ctn-kix_list_10-8;
        }
        .lst-kix_list_9-1 > li:before {
          content: "" counter(lst-ctn-kix_list_9-1, lower-latin) ". ";
        }
        .lst-kix_list_10-6 > li:before {
          content: "" counter(lst-ctn-kix_list_10-6, decimal) ". ";
        }
        .lst-kix_list_40-7 > li {
          counter-increment: lst-ctn-kix_list_40-7;
        }
        ol.lst-kix_list_12-7.start {
          counter-reset: lst-ctn-kix_list_12-7 0;
        }
        .lst-kix_list_15-8 > li {
          counter-increment: lst-ctn-kix_list_15-8;
        }
        .lst-kix_list_40-8 > li:before {
          content: "" counter(lst-ctn-kix_list_40-0, decimal) "."
            counter(lst-ctn-kix_list_40-1, decimal) "."
            counter(lst-ctn-kix_list_40-2, decimal) "."
            counter(lst-ctn-kix_list_40-3, decimal) "."
            counter(lst-ctn-kix_list_40-4, decimal) "."
            counter(lst-ctn-kix_list_40-5, decimal) "."
            counter(lst-ctn-kix_list_40-6, decimal) "."
            counter(lst-ctn-kix_list_40-7, decimal) "."
            counter(lst-ctn-kix_list_40-8, decimal) ". ";
        }
        .lst-kix_list_37-8 > li {
          counter-increment: lst-ctn-kix_list_37-8;
        }
        ol.lst-kix_list_12-8.start {
          counter-reset: lst-ctn-kix_list_12-8 0;
        }
        .lst-kix_list_20-3 > li:before {
          content: "(" counter(lst-ctn-kix_list_20-3, lower-roman) ") ";
        }
        .lst-kix_list_29-2 > li:before {
          content: " ";
        }
        ol.lst-kix_list_23-5.start {
          counter-reset: lst-ctn-kix_list_23-5 0;
        }
        .lst-kix_list_21-1 > li {
          counter-increment: lst-ctn-kix_list_21-1;
        }
        .lst-kix_list_10-1 > li {
          counter-increment: lst-ctn-kix_list_10-1;
        }
        .lst-kix_list_8-8 > li {
          counter-increment: lst-ctn-kix_list_8-8;
        }
        ol.lst-kix_list_17-6.start {
          counter-reset: lst-ctn-kix_list_17-6 0;
        }
        .lst-kix_list_28-6 > li:before {
          content: " ";
        }
        .lst-kix_list_1-6 > li:before {
          content: " ";
        }
        .lst-kix_list_33-7 > li:before {
          content: " ";
        }
        .lst-kix_list_40-0 > li:before {
          content: "" counter(lst-ctn-kix_list_40-0, decimal) ". ";
        }
        .lst-kix_list_12-6 > li:before {
          content: "" counter(lst-ctn-kix_list_12-6, decimal) ". ";
        }
        ol.lst-kix_list_23-4.start {
          counter-reset: lst-ctn-kix_list_23-4 0;
        }
        .lst-kix_list_34-3 > li:before {
          content: " ";
        }
        .lst-kix_list_2-2 > li:before {
          content: "" counter(lst-ctn-kix_list_2-2, lower-roman) ". ";
        }
        .lst-kix_list_13-2 > li:before {
          content: "" counter(lst-ctn-kix_list_13-2, lower-roman) ". ";
        }
        ol.lst-kix_list_5-2.start {
          counter-reset: lst-ctn-kix_list_5-2 0;
        }
        ol {
          margin: 0;
          padding: 0;
        }
        table td,
        table th {
          padding: 0;
        }
        .c9 {
          -webkit-text-decoration-skip: none;
          color: #000000;
          font-weight: 400;
          text-decoration: underline;
          vertical-align: baseline;
          text-decoration-skip-ink: none;
          font-size: 12pt;
          font-family: "Times New Roman";
          font-style: normal;
        }
        .c2 {
          -webkit-text-decoration-skip: none;
          color: #000000;
          font-weight: 700;
          text-decoration: underline;
          vertical-align: baseline;
          text-decoration-skip-ink: none;
          font-size: 12pt;
          font-family: "Times New Roman";
          font-style: normal;
        }
        .c0 {
          margin-left: 36pt;
          padding-top: 6pt;
          padding-left: 0pt;
          padding-bottom: 6pt;
          line-height: 1;
          orphans: 2;
          widows: 2;
          text-align: justify;
        }
        .c1 {
          color: #000000;
          font-weight: 400;
          text-decoration: none;
          vertical-align: baseline;
          font-size: 12pt;
          font-family: "Times New Roman";
          font-style: normal;
        }
        .c11 {
          color: #000000;
          font-weight: 400;
          text-decoration: none;
          vertical-align: baseline;
          font-size: 12pt;
          font-family: "Arial";
          font-style: normal;
        }
        .c15 {
          color: #000000;
          font-weight: 700;
          text-decoration: none;
          vertical-align: baseline;
          font-size: 16pt;
          font-family: "Times New Roman";
          font-style: normal;
        }
        .c14 {
          color: #000000;
          font-weight: 400;
          text-decoration: none;
          vertical-align: baseline;
          font-size: 4pt;
          font-family: "Times New Roman";
          font-style: normal;
        }
        .c7 {
          color: #000000;
          font-weight: 700;
          text-decoration: none;
          vertical-align: baseline;
          font-size: 12pt;
          font-family: "Times New Roman";
          font-style: normal;
        }
        .c8 {
          padding-top: 6pt;
          padding-bottom: 0pt;
          line-height: 1;
          orphans: 2;
          widows: 2;
          text-align: center;
        }
        .c3 {
          padding-top: 6pt;
          padding-bottom: 0pt;
          line-height: 1;
          orphans: 2;
          widows: 2;
          text-align: justify;
        }
        .c10 {
          padding-top: 6pt;
          padding-bottom: 12pt;
          line-height: 1;
          orphans: 2;
          widows: 2;
          text-align: center;
        }
        .c12 {
          padding-top: 6pt;
          padding-bottom: 0pt;
          line-height: 1;
          orphans: 2;
          widows: 2;
          text-align: left;
        }
        .c6 {
          background-color: #ffffff;
          max-width: 468pt;
          padding: 72pt 72pt 72pt 72pt;
        }
        .c4 {
          color: inherit;
          text-decoration: inherit;
        }
        .c5 {
          padding: 0;
          margin: 0;
        }
        .c13 {
          height: 12pt;
        }
        .title {
          padding-top: 6pt;
          color: #000000;
          font-size: 12pt;
          padding-bottom: 0pt;
          font-family: "Times New Roman";
          line-height: 1;
          orphans: 2;
          widows: 2;
          text-align: left;
        }
        .subtitle {
          padding-top: 18pt;
          color: #666666;
          font-size: 24pt;
          padding-bottom: 4pt;
          font-family: "Georgia";
          line-height: 1;
          page-break-after: avoid;
          font-style: italic;
          orphans: 2;
          widows: 2;
          text-align: left;
        }
        li {
          color: #000000;
          font-size: 12pt;
          font-family: "Arial";
        }
        p {
          margin: 0;
          color: #000000;
          font-size: 12pt;
          font-family: "Arial";
        }
        h1 {
          padding-top: 12pt;
          color: #000000;
          border-bottom-color: #000000;
          font-size: 16pt;
          padding-bottom: 1pt;
          line-height: 1;
          page-break-after: avoid;
          border-bottom-width: 0.5pt;
          font-family: "Arial";
          border-bottom-style: solid;
          orphans: 2;
          widows: 2;
          text-align: left;
        }
        h2 {
          padding-top: 18pt;
          color: #000000;
          font-weight: 700;
          font-size: 18pt;
          padding-bottom: 4pt;
          font-family: "Arial";
          line-height: 1;
          page-break-after: avoid;
          orphans: 2;
          widows: 2;
          text-align: left;
        }
        h3 {
          padding-top: 14pt;
          color: #000000;
          font-weight: 700;
          font-size: 14pt;
          padding-bottom: 4pt;
          font-family: "Arial";
          line-height: 1;
          page-break-after: avoid;
          orphans: 2;
          widows: 2;
          text-align: left;
        }
        h4 {
          padding-top: 12pt;
          color: #000000;
          font-weight: 700;
          font-size: 12pt;
          padding-bottom: 2pt;
          font-family: "Arial";
          line-height: 1;
          page-break-after: avoid;
          orphans: 2;
          widows: 2;
          text-align: left;
        }
        h5 {
          padding-top: 11pt;
          color: #000000;
          font-weight: 700;
          font-size: 11pt;
          padding-bottom: 2pt;
          font-family: "Arial";
          line-height: 1;
          page-break-after: avoid;
          orphans: 2;
          widows: 2;
          text-align: left;
        }
        h6 {
          padding-top: 10pt;
          color: #000000;
          font-weight: 700;
          font-size: 10pt;
          padding-bottom: 2pt;
          font-family: "Arial";
          line-height: 1;
          page-break-after: avoid;
          orphans: 2;
          widows: 2;
          text-align: left;
        }
      </style>
    </head>
    <body class="c6 doc-content">
      <a id="id.gjdgxs"></a>
      <p class="c10" id="h.30j0zll">
        <span class="c15">Acceptable Use Policy</span>
      </p>
      <p class="c8"><span class="c1">Updated 10.11.2022</span></p>
      <a id="id.1fob9te"></a>
      <p class="c3"><span class="c2">Introduction</span></p>
      <a id="id.3znysh7"></a>
      <p class="c3">
        <span class="c1">This Acceptable Use Policy (this &quot;</span
        ><span class="c7">AUP</span
        ><span class="c1"
          >&quot;) governs your access to and use of the website at &nbsp;</span
        ><span class="c9"
          ><a
            class="c4"
            href="https://www.google.com/url?q=https://crownlink.crownbio.com&amp;sa=D&amp;source=editors&amp;ust=1667239251165078&amp;usg=AOvVaw2ruCYdGY93JCjiSE5jL_-c"
            >https://crownlink.crownbio.com</a
          ></span
        ><span class="c1">&nbsp;(the &ldquo;</span><span class="c7">Portal</span
        ><span class="c1">&rdquo;) offered by Crown Bioscience, Inc. (&quot;</span
        ><span class="c7">Provider</span><span class="c1">,&quot; &quot;</span
        ><span class="c7">we</span><span class="c1">,&quot; or &quot;</span
        ><span class="c7">us</span
        ><span class="c1"
          >&quot;). Provider reserves the right to amend, alter, or modify your
          conduct requirements as set forth in this AUP at any time. By
          registering to use the Protal, you accept and agree to be bound and
          abide by this AUP. If you do not want to agree to this AUP, you must not
          access or use the Portal.</span
        >
      </p>
      <a id="id.2et92p0"></a>
      <p class="c3">
        <span class="c14">&nbsp;</span><span class="c2">Prohibited Uses</span>
      </p>
      <p class="c3">
        <span class="c1"
          >You may use the Portal only for lawful purposes and in accordance with
          this AUP. You agree </span
        ><span class="c9">not</span
        ><span class="c1">&nbsp;to use the Portal:</span>
      </p>
      <ul class="c5 lst-kix_list_18-0 start">
        <li class="c0 li-bullet-0">
          <span class="c1"
            >In any way that violates any applicable federal, state, local, or
            international law or regulation (including, without limitation, any
            laws regarding the export of data or software to and from the U.S. or
            other countries).
          </span>
        </li>
        <li class="c0 li-bullet-0">
          <span class="c1"
            >For the purpose of exploiting, harming, or attempting to exploit or
            harm, minors in any way by exposing them to inappropriate content,
            asking for personally identifiable information, or otherwise.</span
          >
        </li>
        <li class="c0 li-bullet-0">
          <span class="c1"
            >In any way that violates the rights of any individual or entity
            established in any jurisdiction.</span
          >
        </li>
        <li class="c0 li-bullet-0">
          <span class="c1"
            >To transmit, or procure the sending of, any advertising or
            promotional material, including any &quot;junk mail,&quot; &quot;chain
            letter,&quot; &quot;spam,&quot; or any other similar
            solicitation.</span
          >
        </li>
        <li class="c0 li-bullet-0">
          <span class="c1"
            >To impersonate or attempt to impersonate Provider, a Provider
            employee, another user, or any other person or entity (including,
            without limitation, by using e-mail addresses or screen names
            associated with any of the foregoing).</span
          >
        </li>
        <li class="c0 li-bullet-0">
          <span class="c1"
            >To sell, share or disclose personal information, including the email
            addresses of any person without such person&rsquo;s knowing and
            continued consent to such disclosure.</span
          >
        </li>
        <li class="c0 li-bullet-0">
          <span class="c1"
            >To engage in any other conduct that restricts or inhibits
            anyone&#39;s use or enjoyment of the Portal, or which, as determined
            by us, may harm Provider or users of the Portal or expose them to
            liability.</span
          >
        </li>
      </ul>
      <a id="id.tyjcwt"></a>
      <p class="c3">
        <span class="c1">Additionally, you agree </span><span class="c9">not</span
        ><span class="c1">&nbsp;to:</span>
      </p>
      <ul class="c5 lst-kix_list_18-0">
        <li class="c0 li-bullet-0">
          <span class="c1"
            >Use the Portal in any manner that could disable, overburden, damage,
            or impair the Portal or interfere with any other party&#39;s use of
            the Portal, including their ability to engage in real time activities
            through the Portal.</span
          >
        </li>
        <li class="c0 li-bullet-0">
          <span class="c1"
            >Use any robot, spider, or other automatic or manual device, process,
            or means to access the Portal for any purpose, including monitoring
            any Portal traffic or collecting information about other users.</span
          >
        </li>
        <li class="c0 li-bullet-0">
          <span class="c1"
            >Use any device, software, or routine that interferes with the proper
            working of the Portal.</span
          >
        </li>
        <li class="c0 li-bullet-0">
          <span class="c1"
            >Introduce any viruses, trojan horses, worms, logic bombs, or other
            software or material which is malicious or technologically
            harmful.</span
          >
        </li>
        <li class="c0 li-bullet-0">
          <span class="c1"
            >Attempt to gain unauthorized access to, interfere with, damage, or
            disrupt any parts of the Portal or any server, computer, database, or
            other resource or element connected to the Portal.
          </span>
        </li>
        <li class="c0 li-bullet-0">
          <span class="c1"
            >Violate, attempt to violate, or knowingly facilitate the violation of
            the security or integrity of the Portal.</span
          >
        </li>
        <li class="c0 li-bullet-0">
          <span class="c1"
            >Remove any copyright, trademark, or other proprietary rights notices
            containd in or on the Portal.
          </span>
        </li>
        <li class="c0 li-bullet-0">
          <span class="c1"
            >Otherwise attempt to interfere with the proper working of the
            Portal.</span
          >
        </li>
      </ul>
      <a id="id.3dy6vkm"></a>
      <p class="c3"><span class="c2">Content Standards</span></p>
      <p class="c3">
        <span class="c1">You agree </span><span class="c9">not</span
        ><span class="c1"
          >&nbsp;to use the Portal to send, knowingly receive, upload, download,
          use, or re-use any material which:</span
        >
      </p>
      <ul class="c5 lst-kix_list_18-0">
        <li class="c0 li-bullet-0">
          <span class="c1"
            >Contains any material that is defamatory, obscene, indecent, abusive,
            offensive, harassing, violent, hateful, inflammatory, or otherwise
            objectionable.</span
          >
        </li>
        <li class="c0 li-bullet-0">
          <span class="c1"
            >Promotes sexually explicit or pornographic material, violence, or
            discrimination based on race, sex, religion, nationality, disability,
            sexual orientation, or age.</span
          >
        </li>
        <li class="c0 li-bullet-0">
          <span class="c1"
            >Infringes any patent, trademark, trade secret, copyright, or other
            intellectual property or other rights of any other person.</span
          >
        </li>
        <li class="c0 li-bullet-0">
          <span class="c1"
            >Violates the legal rights (including the rights of publicity and
            privacy) of others or contains any material that could give rise to
            any civil or criminal liability under applicable laws or
            regulations.</span
          >
        </li>
        <li class="c0 li-bullet-0">
          <span class="c1">Is likely to deceive any person.</span>
        </li>
        <li class="c0 li-bullet-0">
          <span class="c1"
            >Promotes any illegal activity, or advocates, promotes, or assists any
            unlawful act.</span
          >
        </li>
        <li class="c0 li-bullet-0">
          <span class="c1"
            >Causes annoyance, inconvenience, or needless anxiety or is likely to
            upset, embarrass, alarm, or annoy any other person.</span
          >
        </li>
        <li class="c0 li-bullet-0">
          <span class="c1"
            >Impersonates any person, or misrepresents your identity or
            affiliation with any person or organization.
          </span>
        </li>
        <li class="c0 li-bullet-0">
          <span class="c1"
            >Involves unsolicited commercial activities or sales, such as
            contests, sweepstakes, and other sales promotions, barter, or
            advertising.</span
          >
        </li>
        <li class="c0 li-bullet-0">
          <span class="c1"
            >Gives the impression that they emanate from or are endorsed by us or
            any other person or entity, if this is not the case.</span
          >
        </li>
      </ul>
      <a id="id.1t3h5sf"></a>
      <p class="c3"><span class="c2">Monitoring and Enforcement</span></p>
      <p class="c3">
        <span class="c1"
          >Provider, in its sole discretion, will determine whether your conduct
          is in compliance with this AUP. We have the right to:</span
        >
      </p>
      <ul class="c5 lst-kix_list_18-0">
        <li class="c0 li-bullet-0">
          <span class="c1"
            >Monitor your use of the Portal for any purpose in our sole discretion
            and as we see fit.</span
          >
        </li>
        <li class="c0 li-bullet-0">
          <span class="c1"
            >Take any action we deem necessary or appropriate in our sole
            discretion if we believe a user&#39;s conduct violates this AUP,
            infringes any intellectual property right or other right of any person
            or entity, threatens the personal safety of users of the Portal or the
            public, or could create liability for Provider.</span
          >
        </li>
        <li class="c0 li-bullet-0">
          <span class="c1"
            >Disclose your identity or other information about you to any third
            party who claims that material posted by you violates their rights,
            including their intellectual property rights or their right to
            privacy.</span
          >
        </li>
        <li class="c0 li-bullet-0">
          <span class="c1"
            >Take appropriate legal action, including without limitation, referral
            to law enforcement, for any illegal or unauthorized use of the Portal.
          </span>
        </li>
        <li class="c0 li-bullet-0">
          <span class="c1"
            >Terminate or suspend your access to all or part of the Portal for any
            or no reason, including without limitation, any violation of this
            AUP.</span
          >
        </li>
      </ul>
      <p class="c3">
        <span class="c1"
          >Without limiting the foregoing, we have the right to fully cooperate
          with any law enforcement authorities or court order requesting or
          directing us to disclose the identity or other information of anyone who
          accesses or uses the Portal. YOU WAIVE AND HOLD HARMLESS PROVIDER AND
          ITS AFFILIATES, LICENSORS, VENDORS </span
        ><span class="c1">AND CONTRACTORS</span
        ><span class="c1"
          >&nbsp;FROM ANY LOSS, CLAIM OR LIABILITY RESULTING FROM ANY ACTION TAKEN
          BY PROVIDER OR ANY OF THE FOREGOING PARTIES DURING, OR TAKEN AS A
          CONSEQUENCE OF, INVESTIGATIONS BY EITHER PROVIDER OR SUCH PARTIES OR LAW
          ENFORCEMENT AUTHORITIES.</span
        >
      </p>
      <div>
        <p class="c8 c13"><span class="c11"></span></p>
        <p class="c12">
          <span class="c11"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
          >
        </p>
      </div>
    </body>
  </html>`,
  },
  privacy: {
    title: 'Privacy Notice',
    version: '1.0.1',
    content: `<html>
    <head>
      <meta content="text/html; charset=UTF-8" http-equiv="content-type">
      <style type="text/css">
        @import url('https://themes.googleusercontent.com/fonts/css?kit=fpjTOVmNbO4Lz34iLyptLUXza5VhXqVC6o75Eld_V98');
    
        * {
          font-family: "AvenirNextLTPro-Regular" !important;
        }
        .lst-kix_list_14-1>li:before {
          content: "o  "
        }
    
        .lst-kix_list_14-3>li:before {
          content: "\\0025aa  "
        }
    
        ul.lst-kix_list_1-0 {
          list-style-type: none
        }
    
        .lst-kix_list_14-0>li:before {
          content: "\\0025cf  "
        }
    
        .lst-kix_list_14-4>li:before {
          content: "\\0025aa  "
        }
    
        .lst-kix_list_14-5>li:before {
          content: "\\0025aa  "
        }
    
        .lst-kix_list_14-7>li:before {
          content: "\\0025aa  "
        }
    
        .lst-kix_list_14-6>li:before {
          content: "\\0025aa  "
        }
    
        ul.lst-kix_list_9-3 {
          list-style-type: none
        }
    
        ul.lst-kix_list_9-4 {
          list-style-type: none
        }
    
        ul.lst-kix_list_9-1 {
          list-style-type: none
        }
    
        ul.lst-kix_list_9-2 {
          list-style-type: none
        }
    
        ul.lst-kix_list_9-7 {
          list-style-type: none
        }
    
        ol.lst-kix_list_7-4.start {
          counter-reset: lst-ctn-kix_list_7-4 0
        }
    
        ul.lst-kix_list_9-8 {
          list-style-type: none
        }
    
        ul.lst-kix_list_9-5 {
          list-style-type: none
        }
    
        ul.lst-kix_list_9-6 {
          list-style-type: none
        }
    
        ol.lst-kix_list_7-5 {
          list-style-type: none
        }
    
        ul.lst-kix_list_1-3 {
          list-style-type: none
        }
    
        ol.lst-kix_list_7-6 {
          list-style-type: none
        }
    
        ul.lst-kix_list_1-4 {
          list-style-type: none
        }
    
        ol.lst-kix_list_7-7 {
          list-style-type: none
        }
    
        ul.lst-kix_list_1-1 {
          list-style-type: none
        }
    
        ol.lst-kix_list_7-8 {
          list-style-type: none
        }
    
        ul.lst-kix_list_1-2 {
          list-style-type: none
        }
    
        ol.lst-kix_list_7-1 {
          list-style-type: none
        }
    
        ul.lst-kix_list_1-7 {
          list-style-type: none
        }
    
        ol.lst-kix_list_7-2 {
          list-style-type: none
        }
    
        ul.lst-kix_list_9-0 {
          list-style-type: none
        }
    
        ul.lst-kix_list_1-8 {
          list-style-type: none
        }
    
        ol.lst-kix_list_7-3 {
          list-style-type: none
        }
    
        ul.lst-kix_list_1-5 {
          list-style-type: none
        }
    
        ol.lst-kix_list_7-4 {
          list-style-type: none
        }
    
        .lst-kix_list_14-2>li:before {
          content: "\\0025aa  "
        }
    
        ul.lst-kix_list_1-6 {
          list-style-type: none
        }
    
        .lst-kix_list_14-8>li:before {
          content: "\\0025aa  "
        }
    
        .lst-kix_list_7-2>li {
          counter-increment: lst-ctn-kix_list_7-2
        }
    
        .lst-kix_list_5-0>li:before {
          content: "\\0025cf  "
        }
    
        .lst-kix_list_5-3>li:before {
          content: "\\0025aa  "
        }
    
        .lst-kix_list_5-2>li:before {
          content: "\\0025aa  "
        }
    
        .lst-kix_list_5-1>li:before {
          content: "o  "
        }
    
        .lst-kix_list_5-7>li:before {
          content: "\\0025aa  "
        }
    
        ul.lst-kix_list_8-4 {
          list-style-type: none
        }
    
        ul.lst-kix_list_8-5 {
          list-style-type: none
        }
    
        .lst-kix_list_5-6>li:before {
          content: "\\0025aa  "
        }
    
        .lst-kix_list_5-8>li:before {
          content: "\\0025aa  "
        }
    
        ul.lst-kix_list_8-2 {
          list-style-type: none
        }
    
        ul.lst-kix_list_8-3 {
          list-style-type: none
        }
    
        ul.lst-kix_list_8-8 {
          list-style-type: none
        }
    
        ul.lst-kix_list_8-6 {
          list-style-type: none
        }
    
        ul.lst-kix_list_8-7 {
          list-style-type: none
        }
    
        .lst-kix_list_5-4>li:before {
          content: "\\0025aa  "
        }
    
        .lst-kix_list_5-5>li:before {
          content: "\\0025aa  "
        }
    
        ul.lst-kix_list_8-0 {
          list-style-type: none
        }
    
        ul.lst-kix_list_8-1 {
          list-style-type: none
        }
    
        .lst-kix_list_6-1>li:before {
          content: "o  "
        }
    
        .lst-kix_list_6-3>li:before {
          content: "\\0025aa  "
        }
    
        .lst-kix_list_6-0>li:before {
          content: "\\0025cf  "
        }
    
        .lst-kix_list_6-4>li:before {
          content: "\\0025aa  "
        }
    
        .lst-kix_list_6-2>li:before {
          content: "\\0025aa  "
        }
    
        .lst-kix_list_6-8>li:before {
          content: "\\0025aa  "
        }
    
        .lst-kix_list_6-5>li:before {
          content: "\\0025aa  "
        }
    
        .lst-kix_list_6-7>li:before {
          content: "\\0025aa  "
        }
    
        .lst-kix_list_6-6>li:before {
          content: "\\0025aa  "
        }
    
        .lst-kix_list_2-7>li:before {
          content: "\\0025aa  "
        }
    
        .lst-kix_list_7-4>li:before {
          content: "" counter(lst-ctn-kix_list_7-4, lower-latin) ". "
        }
    
        .lst-kix_list_7-6>li:before {
          content: "" counter(lst-ctn-kix_list_7-6, decimal) ". "
        }
    
        .lst-kix_list_2-5>li:before {
          content: "\\0025aa  "
        }
    
        .lst-kix_list_7-2>li:before {
          content: "" counter(lst-ctn-kix_list_7-2, lower-roman) ". "
        }
    
        .lst-kix_list_7-6>li {
          counter-increment: lst-ctn-kix_list_7-6
        }
    
        ul.lst-kix_list_3-7 {
          list-style-type: none
        }
    
        ul.lst-kix_list_3-8 {
          list-style-type: none
        }
    
        .lst-kix_list_10-1>li:before {
          content: "\\0025cb  "
        }
    
        .lst-kix_list_13-7>li:before {
          content: "\\0025aa  "
        }
    
        ul.lst-kix_list_3-1 {
          list-style-type: none
        }
    
        ul.lst-kix_list_3-2 {
          list-style-type: none
        }
    
        .lst-kix_list_7-7>li {
          counter-increment: lst-ctn-kix_list_7-7
        }
    
        .lst-kix_list_7-8>li:before {
          content: "" counter(lst-ctn-kix_list_7-8, lower-roman) ". "
        }
    
        ul.lst-kix_list_3-0 {
          list-style-type: none
        }
    
        ul.lst-kix_list_3-5 {
          list-style-type: none
        }
    
        ul.lst-kix_list_3-6 {
          list-style-type: none
        }
    
        ul.lst-kix_list_3-3 {
          list-style-type: none
        }
    
        ul.lst-kix_list_3-4 {
          list-style-type: none
        }
    
        .lst-kix_list_10-7>li:before {
          content: "\\0025cb  "
        }
    
        .lst-kix_list_7-8>li {
          counter-increment: lst-ctn-kix_list_7-8
        }
    
        .lst-kix_list_15-5>li:before {
          content: "\\0025aa  "
        }
    
        .lst-kix_list_10-5>li:before {
          content: "\\0025a0  "
        }
    
        .lst-kix_list_10-3>li:before {
          content: "\\0025cf  "
        }
    
        ul.lst-kix_list_11-7 {
          list-style-type: none
        }
    
        ul.lst-kix_list_11-6 {
          list-style-type: none
        }
    
        .lst-kix_list_4-1>li:before {
          content: "o  "
        }
    
        ol.lst-kix_list_7-3.start {
          counter-reset: lst-ctn-kix_list_7-3 0
        }
    
        ul.lst-kix_list_11-5 {
          list-style-type: none
        }
    
        ul.lst-kix_list_11-4 {
          list-style-type: none
        }
    
        ul.lst-kix_list_11-3 {
          list-style-type: none
        }
    
        .lst-kix_list_15-7>li:before {
          content: "o  "
        }
    
        ul.lst-kix_list_11-2 {
          list-style-type: none
        }
    
        ul.lst-kix_list_11-1 {
          list-style-type: none
        }
    
        ul.lst-kix_list_11-0 {
          list-style-type: none
        }
    
        .lst-kix_list_9-2>li:before {
          content: "\\0025a0  "
        }
    
        .lst-kix_list_4-3>li:before {
          content: "\\0025aa  "
        }
    
        .lst-kix_list_4-5>li:before {
          content: "\\0025aa  "
        }
    
        ul.lst-kix_list_11-8 {
          list-style-type: none
        }
    
        .lst-kix_list_15-1>li:before {
          content: "o  "
        }
    
        .lst-kix_list_9-0>li:before {
          content: "\\0025cf  "
        }
    
        .lst-kix_list_15-3>li:before {
          content: "\\0025cf  "
        }
    
        .lst-kix_list_9-6>li:before {
          content: "\\0025cf  "
        }
    
        .lst-kix_list_9-4>li:before {
          content: "\\0025cb  "
        }
    
        .lst-kix_list_11-3>li:before {
          content: "\\0025aa  "
        }
    
        ul.lst-kix_list_2-8 {
          list-style-type: none
        }
    
        .lst-kix_list_12-3>li:before {
          content: "\\0025aa  "
        }
    
        ol.lst-kix_list_7-6.start {
          counter-reset: lst-ctn-kix_list_7-6 0
        }
    
        .lst-kix_list_11-5>li:before {
          content: "\\0025aa  "
        }
    
        .lst-kix_list_12-1>li:before {
          content: "o  "
        }
    
        ul.lst-kix_list_2-2 {
          list-style-type: none
        }
    
        ul.lst-kix_list_2-3 {
          list-style-type: none
        }
    
        ul.lst-kix_list_2-0 {
          list-style-type: none
        }
    
        ul.lst-kix_list_2-1 {
          list-style-type: none
        }
    
        .lst-kix_list_9-8>li:before {
          content: "\\0025a0  "
        }
    
        ul.lst-kix_list_2-6 {
          list-style-type: none
        }
    
        .lst-kix_list_1-1>li:before {
          content: "o  "
        }
    
        ul.lst-kix_list_2-7 {
          list-style-type: none
        }
    
        .lst-kix_list_11-7>li:before {
          content: "\\0025aa  "
        }
    
        ul.lst-kix_list_2-4 {
          list-style-type: none
        }
    
        ul.lst-kix_list_2-5 {
          list-style-type: none
        }
    
        ul.lst-kix_list_10-0 {
          list-style-type: none
        }
    
        .lst-kix_list_1-3>li:before {
          content: "\\0025aa  "
        }
    
        .lst-kix_list_13-3>li:before {
          content: "\\0025aa  "
        }
    
        ul.lst-kix_list_10-8 {
          list-style-type: none
        }
    
        ul.lst-kix_list_10-7 {
          list-style-type: none
        }
    
        .lst-kix_list_1-7>li:before {
          content: "\\0025aa  "
        }
    
        ul.lst-kix_list_10-6 {
          list-style-type: none
        }
    
        ul.lst-kix_list_10-5 {
          list-style-type: none
        }
    
        ul.lst-kix_list_10-4 {
          list-style-type: none
        }
    
        ul.lst-kix_list_10-3 {
          list-style-type: none
        }
    
        .lst-kix_list_1-5>li:before {
          content: "\\0025aa  "
        }
    
        ul.lst-kix_list_10-2 {
          list-style-type: none
        }
    
        ul.lst-kix_list_10-1 {
          list-style-type: none
        }
    
        .lst-kix_list_13-5>li:before {
          content: "\\0025aa  "
        }
    
        .lst-kix_list_12-5>li:before {
          content: "\\0025aa  "
        }
    
        ol.lst-kix_list_7-5.start {
          counter-reset: lst-ctn-kix_list_7-5 0
        }
    
        .lst-kix_list_12-7>li:before {
          content: "\\0025aa  "
        }
    
        .lst-kix_list_2-1>li:before {
          content: "o  "
        }
    
        .lst-kix_list_2-3>li:before {
          content: "\\0025aa  "
        }
    
        .lst-kix_list_13-1>li:before {
          content: "o  "
        }
    
        .lst-kix_list_7-1>li {
          counter-increment: lst-ctn-kix_list_7-1
        }
    
        .lst-kix_list_3-0>li:before {
          content: "\\0025cf  "
        }
    
        ol.lst-kix_list_7-7.start {
          counter-reset: lst-ctn-kix_list_7-7 0
        }
    
        ul.lst-kix_list_5-7 {
          list-style-type: none
        }
    
        ul.lst-kix_list_5-8 {
          list-style-type: none
        }
    
        .lst-kix_list_3-1>li:before {
          content: "o  "
        }
    
        .lst-kix_list_3-2>li:before {
          content: "\\0025aa  "
        }
    
        ul.lst-kix_list_5-5 {
          list-style-type: none
        }
    
        ul.lst-kix_list_5-6 {
          list-style-type: none
        }
    
        .lst-kix_list_8-1>li:before {
          content: "o  "
        }
    
        .lst-kix_list_8-2>li:before {
          content: "\\0025aa  "
        }
    
        .lst-kix_list_3-5>li:before {
          content: "\\0025aa  "
        }
    
        ul.lst-kix_list_5-0 {
          list-style-type: none
        }
    
        .lst-kix_list_3-4>li:before {
          content: "\\0025aa  "
        }
    
        ul.lst-kix_list_5-3 {
          list-style-type: none
        }
    
        .lst-kix_list_3-3>li:before {
          content: "\\0025aa  "
        }
    
        ul.lst-kix_list_5-4 {
          list-style-type: none
        }
    
        ul.lst-kix_list_5-1 {
          list-style-type: none
        }
    
        .lst-kix_list_8-0>li:before {
          content: "\\0025cf  "
        }
    
        ul.lst-kix_list_5-2 {
          list-style-type: none
        }
    
        .lst-kix_list_8-7>li:before {
          content: "\\0025aa  "
        }
    
        .lst-kix_list_3-8>li:before {
          content: "\\0025aa  "
        }
    
        .lst-kix_list_8-5>li:before {
          content: "\\0025aa  "
        }
    
        .lst-kix_list_8-6>li:before {
          content: "\\0025aa  "
        }
    
        .lst-kix_list_8-3>li:before {
          content: "\\0025aa  "
        }
    
        ul.lst-kix_list_13-5 {
          list-style-type: none
        }
    
        ul.lst-kix_list_13-4 {
          list-style-type: none
        }
    
        ul.lst-kix_list_13-3 {
          list-style-type: none
        }
    
        .lst-kix_list_3-6>li:before {
          content: "\\0025aa  "
        }
    
        ul.lst-kix_list_13-2 {
          list-style-type: none
        }
    
        ul.lst-kix_list_13-1 {
          list-style-type: none
        }
    
        .lst-kix_list_3-7>li:before {
          content: "\\0025aa  "
        }
    
        ul.lst-kix_list_13-0 {
          list-style-type: none
        }
    
        .lst-kix_list_8-4>li:before {
          content: "\\0025aa  "
        }
    
        .lst-kix_list_11-2>li:before {
          content: "\\0025aa  "
        }
    
        ul.lst-kix_list_13-8 {
          list-style-type: none
        }
    
        .lst-kix_list_11-1>li:before {
          content: "o  "
        }
    
        ul.lst-kix_list_13-7 {
          list-style-type: none
        }
    
        ul.lst-kix_list_13-6 {
          list-style-type: none
        }
    
        .lst-kix_list_11-0>li:before {
          content: "\\0025cf  "
        }
    
        .lst-kix_list_8-8>li:before {
          content: "\\0025aa  "
        }
    
        ol.lst-kix_list_7-2.start {
          counter-reset: lst-ctn-kix_list_7-2 0
        }
    
        .lst-kix_list_4-8>li:before {
          content: "\\0025aa  "
        }
    
        .lst-kix_list_4-7>li:before {
          content: "\\0025aa  "
        }
    
        ul.lst-kix_list_4-8 {
          list-style-type: none
        }
    
        ul.lst-kix_list_4-6 {
          list-style-type: none
        }
    
        ul.lst-kix_list_4-7 {
          list-style-type: none
        }
    
        .lst-kix_list_7-3>li {
          counter-increment: lst-ctn-kix_list_7-3
        }
    
        ul.lst-kix_list_4-0 {
          list-style-type: none
        }
    
        ul.lst-kix_list_4-1 {
          list-style-type: none
        }
    
        ul.lst-kix_list_4-4 {
          list-style-type: none
        }
    
        ul.lst-kix_list_4-5 {
          list-style-type: none
        }
    
        ul.lst-kix_list_4-2 {
          list-style-type: none
        }
    
        ul.lst-kix_list_4-3 {
          list-style-type: none
        }
    
        ol.lst-kix_list_7-8.start {
          counter-reset: lst-ctn-kix_list_7-8 0
        }
    
        ol.lst-kix_list_7-1.start {
          counter-reset: lst-ctn-kix_list_7-1 0
        }
    
        ul.lst-kix_list_12-6 {
          list-style-type: none
        }
    
        ul.lst-kix_list_12-5 {
          list-style-type: none
        }
    
        ul.lst-kix_list_12-4 {
          list-style-type: none
        }
    
        ul.lst-kix_list_12-3 {
          list-style-type: none
        }
    
        ul.lst-kix_list_12-2 {
          list-style-type: none
        }
    
        ul.lst-kix_list_12-1 {
          list-style-type: none
        }
    
        ul.lst-kix_list_12-0 {
          list-style-type: none
        }
    
        ul.lst-kix_list_12-8 {
          list-style-type: none
        }
    
        ul.lst-kix_list_12-7 {
          list-style-type: none
        }
    
        .lst-kix_list_7-0>li:before {
          content: "\\0025cf  "
        }
    
        .lst-kix_list_2-6>li:before {
          content: "\\0025aa  "
        }
    
        .lst-kix_list_2-4>li:before {
          content: "\\0025aa  "
        }
    
        .lst-kix_list_2-8>li:before {
          content: "\\0025aa  "
        }
    
        .lst-kix_list_7-1>li:before {
          content: "" counter(lst-ctn-kix_list_7-1, lower-latin) ". "
        }
    
        .lst-kix_list_7-5>li:before {
          content: "" counter(lst-ctn-kix_list_7-5, lower-roman) ". "
        }
    
        .lst-kix_list_7-3>li:before {
          content: "" counter(lst-ctn-kix_list_7-3, decimal) ". "
        }
    
        .lst-kix_list_10-0>li:before {
          content: "\\0025cf  "
        }
    
        .lst-kix_list_13-6>li:before {
          content: "\\0025aa  "
        }
    
        .lst-kix_list_13-8>li:before {
          content: "\\0025aa  "
        }
    
        ul.lst-kix_list_7-0 {
          list-style-type: none
        }
    
        .lst-kix_list_7-7>li:before {
          content: "" counter(lst-ctn-kix_list_7-7, lower-latin) ". "
        }
    
        .lst-kix_list_7-5>li {
          counter-increment: lst-ctn-kix_list_7-5
        }
    
        .lst-kix_list_15-4>li:before {
          content: "o  "
        }
    
        .lst-kix_list_15-6>li:before {
          content: "\\0025cf  "
        }
    
        .lst-kix_list_10-4>li:before {
          content: "\\0025cb  "
        }
    
        .lst-kix_list_10-8>li:before {
          content: "\\0025a0  "
        }
    
        .lst-kix_list_4-0>li:before {
          content: "\\0025cf  "
        }
    
        ul.lst-kix_list_15-3 {
          list-style-type: none
        }
    
        ul.lst-kix_list_15-2 {
          list-style-type: none
        }
    
        .lst-kix_list_15-0>li:before {
          content: "-  "
        }
    
        ul.lst-kix_list_15-1 {
          list-style-type: none
        }
    
        .lst-kix_list_15-8>li:before {
          content: "\\0025aa  "
        }
    
        ul.lst-kix_list_15-0 {
          list-style-type: none
        }
    
        .lst-kix_list_10-2>li:before {
          content: "\\0025a0  "
        }
    
        .lst-kix_list_4-4>li:before {
          content: "\\0025aa  "
        }
    
        ul.lst-kix_list_15-8 {
          list-style-type: none
        }
    
        .lst-kix_list_4-2>li:before {
          content: "\\0025aa  "
        }
    
        .lst-kix_list_4-6>li:before {
          content: "\\0025aa  "
        }
    
        ul.lst-kix_list_15-7 {
          list-style-type: none
        }
    
        ul.lst-kix_list_15-6 {
          list-style-type: none
        }
    
        .lst-kix_list_9-3>li:before {
          content: "\\0025cf  "
        }
    
        ul.lst-kix_list_15-5 {
          list-style-type: none
        }
    
        ul.lst-kix_list_15-4 {
          list-style-type: none
        }
    
        .lst-kix_list_15-2>li:before {
          content: "\\0025aa  "
        }
    
        .lst-kix_list_10-6>li:before {
          content: "\\0025cf  "
        }
    
        .lst-kix_list_9-1>li:before {
          content: "\\0025cb  "
        }
    
        .lst-kix_list_9-7>li:before {
          content: "\\0025cb  "
        }
    
        .lst-kix_list_11-4>li:before {
          content: "\\0025aa  "
        }
    
        .lst-kix_list_12-4>li:before {
          content: "\\0025aa  "
        }
    
        .lst-kix_list_9-5>li:before {
          content: "\\0025a0  "
        }
    
        ul.lst-kix_list_6-6 {
          list-style-type: none
        }
    
        ul.lst-kix_list_6-7 {
          list-style-type: none
        }
    
        ul.lst-kix_list_6-4 {
          list-style-type: none
        }
    
        ul.lst-kix_list_6-5 {
          list-style-type: none
        }
    
        ul.lst-kix_list_6-8 {
          list-style-type: none
        }
    
        .lst-kix_list_12-2>li:before {
          content: "\\0025aa  "
        }
    
        .lst-kix_list_7-4>li {
          counter-increment: lst-ctn-kix_list_7-4
        }
    
        .lst-kix_list_11-6>li:before {
          content: "\\0025aa  "
        }
    
        .lst-kix_list_1-0>li:before {
          content: "\\0025cf  "
        }
    
        ul.lst-kix_list_6-2 {
          list-style-type: none
        }
    
        .lst-kix_list_11-8>li:before {
          content: "\\0025aa  "
        }
    
        ul.lst-kix_list_6-3 {
          list-style-type: none
        }
    
        .lst-kix_list_1-2>li:before {
          content: "\\0025aa  "
        }
    
        ul.lst-kix_list_6-0 {
          list-style-type: none
        }
    
        .lst-kix_list_12-0>li:before {
          content: "\\0025cf  "
        }
    
        ul.lst-kix_list_6-1 {
          list-style-type: none
        }
    
        .lst-kix_list_1-4>li:before {
          content: "\\0025aa  "
        }
    
        .lst-kix_list_13-0>li:before {
          content: "\\0025cf  "
        }
    
        ul.lst-kix_list_14-4 {
          list-style-type: none
        }
    
        ul.lst-kix_list_14-3 {
          list-style-type: none
        }
    
        ul.lst-kix_list_14-2 {
          list-style-type: none
        }
    
        .lst-kix_list_13-4>li:before {
          content: "\\0025aa  "
        }
    
        ul.lst-kix_list_14-1 {
          list-style-type: none
        }
    
        ul.lst-kix_list_14-0 {
          list-style-type: none
        }
    
        .lst-kix_list_1-6>li:before {
          content: "\\0025aa  "
        }
    
        li.li-bullet-0:before {
          margin-left: -18pt;
          white-space: nowrap;
          display: inline-block;
          min-width: 18pt
        }
    
        ul.lst-kix_list_14-8 {
          list-style-type: none
        }
    
        ul.lst-kix_list_14-7 {
          list-style-type: none
        }
    
        .lst-kix_list_2-0>li:before {
          content: "\\0025cf  "
        }
    
        .lst-kix_list_12-6>li:before {
          content: "\\0025aa  "
        }
    
        ul.lst-kix_list_14-6 {
          list-style-type: none
        }
    
        ul.lst-kix_list_14-5 {
          list-style-type: none
        }
    
        .lst-kix_list_1-8>li:before {
          content: "\\0025aa  "
        }
    
        .lst-kix_list_2-2>li:before {
          content: "\\0025aa  "
        }
    
        .lst-kix_list_13-2>li:before {
          content: "\\0025aa  "
        }
    
        .lst-kix_list_12-8>li:before {
          content: "\\0025aa  "
        }
    
        ol {
          margin: 0;
          padding: 0
        }
    
        table td,
        table th {
          padding: 0
        }
    
        .c19 {
          -webkit-text-decoration-skip: none;
          color: #234178;
          font-weight: 700;
          text-decoration: underline;
          vertical-align: baseline;
          text-decoration-skip-ink: none;
          font-size: 13.5pt;
          font-family: "Arial";
          font-style: normal
        }
    
        .c0 {
          background-color: #ffffff;
          margin-left: 20.9pt;
          padding-top: 0pt;
          padding-left: 0pt;
          padding-bottom: 12pt;
          line-height: 1.0;
          orphans: 2;
          widows: 2;
          text-align: left
        }
    
        .c1 {
          margin-left: 36pt;
          padding-top: 0pt;
          padding-left: 0pt;
          padding-bottom: 8pt;
          line-height: 1.0791666666666666;
          orphans: 2;
          widows: 2;
          text-align: left
        }
    
        .c31 {
          background-color: #ffffff;
          padding-top: 0pt;
          padding-bottom: 12pt;
          line-height: 1.0;
          orphans: 2;
          widows: 2;
          text-align: center
        }
    
        .c4 {
          background-color: #ffffff;
          padding-top: 0pt;
          padding-bottom: 0pt;
          line-height: 1.0;
          orphans: 2;
          widows: 2;
          text-align: left
        }
    
        .c15 {
          color: #ed7d31;
          font-weight: 700;
          text-decoration: none;
          vertical-align: baseline;
          font-size: 10.5pt;
          font-family: "Arial";
          font-style: normal
        }
    
        .c23 {
          -webkit-text-decoration-skip: none;
          color: #0645ad;
          font-weight: 400;
          text-decoration: underline;
          text-decoration-skip-ink: none;
          font-size: 10.5pt;
          font-family: "Arial"
        }
    
        .c3 {
          color: #000000;
          font-weight: 400;
          text-decoration: none;
          vertical-align: baseline;
          font-size: 10.5pt;
          font-family: "Arial";
          font-style: normal
        }
    
        .c26 {
          background-color: #ffffff;
          padding-top: 0pt;
          padding-bottom: 8pt;
          line-height: 1.0;
          orphans: 2;
          widows: 2;
          text-align: left
        }
    
        .c6 {
          -webkit-text-decoration-skip: none;
          color: #0000ff;
          font-weight: 400;
          text-decoration: underline;
          text-decoration-skip-ink: none;
          font-size: 10.5pt;
          font-family: "Arial"
        }
    
        .c8 {
          color: #234178;
          font-weight: 700;
          text-decoration: none;
          vertical-align: baseline;
          font-size: 13.5pt;
          font-family: "Arial";
          font-style: normal
        }
    
        .c16 {
          color: #000000;
          font-weight: 400;
          text-decoration: none;
          vertical-align: baseline;
          font-size: 11pt;
          font-family: "Calibri";
          font-style: normal
        }
    
        .c2 {
          color: #000000;
          font-weight: 700;
          text-decoration: none;
          vertical-align: baseline;
          font-size: 10.5pt;
          font-family: "Arial";
          font-style: normal
        }
    
        .c20 {
          color: #0f1111;
          font-weight: 400;
          text-decoration: none;
          vertical-align: baseline;
          font-size: 10.5pt;
          font-family: "Arial";
          font-style: normal
        }
    
        .c5 {
          background-color: #ffffff;
          padding-top: 0pt;
          padding-bottom: 12pt;
          line-height: 1.0;
          orphans: 2;
          widows: 2;
          text-align: left
        }
    
        .c28 {
          padding-top: 0pt;
          padding-bottom: 12pt;
          line-height: 1.0791666666666666;
          orphans: 2;
          widows: 2;
          text-align: left
        }
    
        .c30 {
          background-color: #ffffff;
          color: #0f1111;
          font-weight: 700;
          text-decoration: none;
          font-size: 10.5pt;
          font-family: "Arial"
        }
    
        .c7 {
          padding-top: 0pt;
          padding-bottom: 12pt;
          line-height: 1.0;
          orphans: 2;
          widows: 2;
          text-align: left
        }
    
        .c35 {
          padding-top: 14pt;
          padding-bottom: 0pt;
          line-height: 1.0;
          orphans: 2;
          widows: 2;
          text-align: left
        }
    
        .c32 {
          color: #434343;
          font-weight: 400;
          text-decoration: none;
          font-size: 11pt;
          font-family: "Arial"
        }
    
        .c18 {
          padding-top: 0pt;
          padding-bottom: 0pt;
          line-height: 1.0;
          text-align: justify;
          height: 11pt
        }
    
        .c10 {
          font-size: 10.5pt;
          font-family: "Arial";
          color: #000000;
          font-weight: 400
        }
    
        .c21 {
          font-size: 10.5pt;
          font-family: "Arial";
          color: #ed7d31;
          font-weight: 700
        }
    
        .c17 {
          padding-top: 0pt;
          padding-bottom: 0pt;
          line-height: 1.0;
          text-align: left
        }
    
        .c22 {
          font-size: 10.5pt;
          font-family: "Arial";
          color: #0f1111;
          font-weight: 400
        }
    
        .c34 {
          font-size: 10.5pt;
          font-family: "Arial";
          color: #0645ad;
          font-weight: 400
        }
    
        .c27 {
          font-size: 13.5pt;
          font-family: "Arial";
          color: #234178;
          font-weight: 700
        }
    
        .c39 {
          font-family: "Arial";
          color: #141412;
          font-weight: 400
        }
    
        .c40 {
          font-size: 10.5pt;
          font-family: "Arial";
          font-weight: 400
        }
    
        .c24 {
          vertical-align: baseline;
          font-style: normal
        }
    
        .c11 {
          margin-left: 21pt;
          text-indent: -21pt
        }
    
        .c36 {
          max-width: 451.3pt;
          padding: 72pt 72pt 72pt 72pt
        }
    
        .c12 {
          padding: 0;
          margin: 0
        }
    
        .c14 {
          color: inherit;
          text-decoration: inherit
        }
    
        .c13 {
          margin-left: 36pt;
          padding-left: 0pt
        }
    
        .c29 {
          margin-left: 36pt;
          height: 11pt
        }
    
        .c9 {
          margin-left: 21pt;
          padding-left: 0pt
        }
    
        .c38 {
          orphans: 2;
          widows: 2
        }
    
        .c25 {
          page-break-after: avoid
        }
    
        .c33 {
          background-color: #ffffff
        }
    
        .c37 {
          height: 11pt
        }
    
        .title {
          padding-top: 24pt;
          color: #000000;
          font-weight: 700;
          font-size: 36pt;
          padding-bottom: 6pt;
          font-family: "Calibri";
          line-height: 1.0791666666666666;
          page-break-after: avoid;
          orphans: 2;
          widows: 2;
          text-align: left
        }
    
        .subtitle {
          padding-top: 18pt;
          color: #666666;
          font-size: 24pt;
          padding-bottom: 4pt;
          font-family: "Georgia";
          line-height: 1.0791666666666666;
          page-break-after: avoid;
          font-style: italic;
          orphans: 2;
          widows: 2;
          text-align: left
        }
    
        li {
          color: #000000;
          font-size: 11pt;
          font-family: "Calibri"
        }
    
        p {
          margin: 0;
          color: #000000;
          font-size: 11pt;
          font-family: "Calibri"
        }
    
        h1 {
          padding-top: 12pt;
          color: #2f5496;
          font-size: 16pt;
          padding-bottom: 0pt;
          font-family: "Calibri";
          line-height: 1.0791666666666666;
          page-break-after: avoid;
          orphans: 2;
          widows: 2;
          text-align: left
        }
    
        h2 {
          padding-top: 2pt;
          color: #2f5496;
          font-size: 13pt;
          padding-bottom: 0pt;
          font-family: "Calibri";
          line-height: 1.0791666666666666;
          page-break-after: avoid;
          orphans: 2;
          widows: 2;
          text-align: left
        }
    
        h3 {
          padding-top: 2pt;
          color: #1f3863;
          font-size: 12pt;
          padding-bottom: 0pt;
          font-family: "Calibri";
          line-height: 1.0791666666666666;
          page-break-after: avoid;
          orphans: 2;
          widows: 2;
          text-align: left
        }
    
        h4 {
          padding-top: 0pt;
          color: #000000;
          font-weight: 700;
          font-size: 12pt;
          padding-bottom: 8pt;
          font-family: "Times New Roman";
          line-height: 1.0;
          orphans: 2;
          widows: 2;
          text-align: left
        }
    
        h5 {
          padding-top: 11pt;
          color: #000000;
          font-weight: 700;
          font-size: 11pt;
          padding-bottom: 2pt;
          font-family: "Calibri";
          line-height: 1.0791666666666666;
          page-break-after: avoid;
          orphans: 2;
          widows: 2;
          text-align: left
        }
    
        h6 {
          padding-top: 10pt;
          color: #000000;
          font-weight: 700;
          font-size: 10pt;
          padding-bottom: 2pt;
          font-family: "Calibri";
          line-height: 1.0791666666666666;
          page-break-after: avoid;
          orphans: 2;
          widows: 2;
          text-align: left
        }
      </style>
    </head>
    
    <body class="c33 c36 doc-content">
      <div>
        <p class="c17 c37 c38"><span class="c16"></span></p>
      </div>
      <p class="c31"><span class="c19">Portal Privacy Policy </span></p>
      <p class="c31"><span class="c3">Last Updated July 2022</span></p>
      <p class="c5"><span class="c10">Crown Bioscience, Inc. and its affiliates (collectively &quot;Crown Bioscience&quot;,
          &ldquo;we&rdquo;, &ldquo;us&rdquo;, or &ldquo;our&rdquo;) are committed to protecting your privacy and making it
          easier and more efficient for you to interact with us. We recognize that it is critical for you to be confident
          that your privacy is protected when you use our Portal (</span><span class="c6"><a class="c14"
            href="https://www.google.com/url?q=https://crownlink.crownbio.com&amp;sa=D&amp;source=editors&amp;ust=1659411762478410&amp;usg=AOvVaw3Rfzi7Z35aWTW_hOPkgnt2">https://crownlink.crownbio.com</a></span><span
          class="c10">). This Privacy Policy describes how we collect, use, disclose, and safeguard your personal
          information through our Portal</span><span class="c22 c33">.&nbsp;</span><span class="c24 c30">By using our
          Portal, you are consenting to the practices described in this Privacy Policy.</span>
      </p>
      <p class="c5"><span class="c8">Controller of Personal Information</span></p>
      <p class="c5"><span class="c10">Any personal information provided to or gathered by Crown Bioscience is controlled
        </span><span class="c20 c33">primarily by Crown Bioscience, Inc.,16550 West Bernardo Drive, Building 5, Suite 525,
          San Diego, CA 92127.</span>
      </p>
      <p class="c5"><span class="c8">What Personal Information We Collect</span></p>
      <p class="c5"><span class="c3">Crown Bioscience does not collect any personal information about you unless you provide
          that information voluntarily by filling out a form, sending an email, placing an order, or accessing your account.
          You are under no obligation to provide us with personal information of any kind; however, your refusal to do so
          may prevent you from using our Portal. </span>
      </p>
      <p class="c5"><span class="c3">The personal information we may collect includes:</span></p>
      <p class="c5"><span class="c21">Personal Data</span></p>
      <ul class="c12 lst-kix_list_4-0 start">
        <li class="c4 c9 li-bullet-0"><span class="c20">Personal identifying information such as your name, address, email
            and phone number;</span>
        </li>
        <li class="c4 c9 li-bullet-0"><span class="c20">Your employer and job role;</span></li>
        <li class="c4 c9 li-bullet-0"><span class="c20">Your location information;</span></li>
        <li class="c4 c9 li-bullet-0"><span class="c20">Your areas of interest relating to our products, services or
            businesses; and</span>
        </li>
        <li class="c0 li-bullet-0"><span class="c22">Content of your reviews, emails and online chat or messages with
            us.</span><span class="c10">&nbsp;The information collected is not limited to text characters and may include
            audio, video, and graphic information formats included in the message</span><span class="c20">.</span></li>
      </ul>
      <p class="c4"><span class="c21">Derivative Information (Log Data)</span></p>
      <p class="c4 c37"><span class="c2"></span></p>
      <p class="c4"><span class="c10">Our servers may automatically collect i</span><span class="c22 c33">nformation about
          your use of our Portal</span><span class="c3">, such as:</span></p>
      <p class="c4"><span class="c3">&nbsp;</span></p>
      <ul class="c12 lst-kix_list_4-0">
        <li class="c4 c9 li-bullet-0"><span class="c22">IP address used to connect your computer to the internet;</span>
        </li>
        <li class="c4 c9 li-bullet-0"><span class="c22">Login information, email address, and password;</span></li>
        <li class="c4 c9 li-bullet-0"><span class="c20">Location of your device or computer and your access time;</span>
        </li>
        <li class="c4 c9 li-bullet-0"><span class="c20">Your browser and operating system;</span></li>
        <li class="c4 c9 li-bullet-0"><span class="c22">Webpage visit and content use history, including full URL
            clickstream to, through, and from our Portal, as well as date and time, products and content you viewed or
            searched for, content downloads, streams, and playback details, download errors, length of visits to certain
            pages, and page interaction information; and</span>
        </li>
        <li class="c0 li-bullet-0"><span class="c22">Portal metrics (e.g., the occurrences of technical errors, your
            interactions with Portal features and content, your settings preferences and backup information, information
            about uploaded files such as the file name, dates, times and location).</span>
        </li>
      </ul>
      <p class="c5 c11"><span class="c21">No Collection of Children&#39;s Personal Information</span></p>
      <p class="c4"><span class="c10">Crown Bioscience does not knowingly collect personal information from children under
          the age of 13 or create profiles of children under the age of 13. Users are cautioned, however, that the
          collection of personal information submitted in an email will be treated as though it was submitted by an adult.
          If you become aware of any data we have collected from children under age 13, please contact us at </span><span
          class="c23"><a class="c14" href="mailto:privacy@crownBio.com">privacy@CrownBio.com</a></span><span
          class="c3">.</span></p>
      <p class="c4 c37"><span class="c3"></span></p>
      <p class="c5"><span class="c27">How We Use Your Personal Information</span></p>
      <p class="c5"><span class="c3">Having accurate information about you allows us to deliver, develop, and improve Crown
          Bioscience&rsquo;s services and provide you with a smooth, efficient, and customized experience. Specifically, we
          may use information collected about you to:</span>
      </p>
      <ul class="c12 lst-kix_list_6-0 start">
        <li class="c5 c9 li-bullet-0"><span class="c3">Communicate with you via different channels (e.g., by phone, email,
            or live chat) to address your questions and concerns; (Legal basis for processing your personal data: consent,
            performance of contract and/or to take steps at your request prior to entering into a contract)</span>
        </li>
        <li class="c5 c9 li-bullet-0"><span class="c3">Ensure smooth connection with and proper function of our Portal and
            optimize user experience of our Portal. The log files enable the evaluation of system security and stability;
            (Legal basis for processing your personal data: legitimate interest for running our business, provision of
            administration and IT support, and developing our products/services)</span>
        </li>
        <li class="c5 c9 li-bullet-0"><span class="c3">Verify and/or authenticate your identity, access rights, privileges,
            etc; (Legal basis for processing your personal data: performance of contract, legitimate interest for network
            security and to prevent fraud)</span>
        </li>
        <li class="c5 c9 li-bullet-0"><span class="c3">Deliver, fulfil and manage Crown Bioscience&rsquo;s services and
            other transactions; (Legal basis for processing your personal data: performance of contract)</span>
        </li>
        <li class="c5 c9 li-bullet-0"><span class="c3">Improve Crown Bioscience&rsquo;s services, troubleshoot and solve
            problems, and analyse usability and effectiveness; (Legal basis for processing your personal data: legitimate
            interest for running our business, provision of administration and IT support, and developing our
            products/services)</span>
        </li>
        <li class="c5 c9 li-bullet-0"><span class="c3">Understand your needs and identify your preferences so as to
            recommend features, products, and services that might be of interest to you; (Legal basis for processing your
            personal data: legitimate interest to study how customers use our products/services, to develop them and grow
            our business)</span>
        </li>
        <li class="c5 c9 li-bullet-0"><span class="c3">Deliver targeted advertising, newsletters, marketing, events and
            other information that might be of interest to you; (Legal basis for processing your personal data: legitimate
            interest to study how customers use our products/services, to develop them and grow our business)</span>
        </li>
        <li class="c5 c9 li-bullet-0"><span class="c3">Comply with legal obligations, such as assisting law enforcement and
            responding to subpoena;&nbsp;(Legal basis for processing your personal data: legal obligation)</span>
        </li>
        <li class="c5 c9 li-bullet-0"><span class="c3">Prevent misuse and misbehaviour, monitor against theft, and protect
            against criminal activity; (Legal basis for processing your personal data: legal obligation, legitimate interest
            for network security and to prevent fraud)</span>
        </li>
        <li class="c0 li-bullet-0"><span class="c3">Use depersonalised and aggregated information to develop reports and
            analysis for research and for other legitimate business purposes. We combine your information, in aggregated
            form, with those of other users to generate summarised reports that help us to optimise our services and
            products. (Legal basis for processing your personal data: legitimate interest&nbsp;to develop our business and
            to inform our marketing strategy)</span>
        </li>
      </ul>
      <p class="c5"><span class="c8">Ways We Collect and Use Personal Information, and Your Options</span></p>
      <p class="c5"><span class="c21">Contact Form(s) / Contact by Email / Live Chat</span></p>
      <p class="c5"><span class="c3">If you send us inquiries or requests via our contact forms, email or live chat, or
          register for a webinar or event, your details, including the contact information you have provided there, will be
          stored for the purpose of processing the inquiry and fulfilling the request and for follow-up actions. </span>
      </p>
      <p class="c5"><span class="c21">Access to Portal and Online Account</span></p>
      <h3 class="c25 c28"><span class="c3">To access the Portal, you will need to create an account and provide us
          with&nbsp;personal information&nbsp;that includes your name, employer, email, phone number and a password. Your
          personal information will be used to verify and/or authenticate your identity, access rights, privileges, etc.
          Your refusal to provide personal information will prevent you from using the Portal.</span>
      </h3>
      <p class="c5"><span class="c21">Cookies </span></p>
      <p class="c5"><span class="c3">We may use cookies created by our Portal to help customize and improve your experience.
          A cookie is a unique text file stored on your computer by your web browser. These text files are used as a means
          of distinguishing among users of a website. A cookie will not include personal information, unless you have
          volunteered that information. One of the primary purposes of cookies is to provide a convenience feature to save
          you time. For example, if you personalize pages on our website or register for a service, a cookie helps us to
          recall your specific information during subsequent visits. When you return to the website, the information you
          previously provided can be retrieved from the cookie file, allowing you to easily use customized features. You
          have the ability to accept or decline cookies. Most web browsers automatically accept cookies, but you can modify
          your browser setting to decline cookies if you prefer (or delete cookies already present). If you choose to
          decline cookies, you may not be able to fully experience certain features of our Portal.</span>
      </p>
      <p class="c5"><span class="c8">Third Parties Who May Have a Copy of Your Personal Information</span></p>
      <p class="c5"><span class="c10">By accessing our Portal, you consent to the collection and use of your information by
          our affiliates, third-party service providers or other trusted business (&ldquo;Third Party Service
          Providers&rdquo;) to process it in</span><span class="c33 c40">&nbsp;compliance with appropriate confidentiality
          and security measures</span><span class="c3">.</span></p>
      <p class="c5"><span class="c10">Among those Third Party Service Providers are:</span></p>
      <p class="c5"><span class="c15">Amazon Web Services (AWS)</span></p>
      <p class="c5"><span class="c10">Our Portal uses AWS to build its structure and all information collected and stored on
          the Portal, including user information, is collected and stored on AWS.</span>
      </p>
      <p class="c5"><span class="c15">HubSpot and Google Analytics</span></p>
      <p class="c5"><span class="c3">We may also partner with selected third-party vendors, such as HubSpot and Google
          Analytics, to allow tracking technologies and remarketing services on our Portal through the use of first-party
          cookies and third-party cookies. </span>
      </p>
      <p class="c5"><span class="c3">Our Portal uses HubSpot, a marketing automation tool that allows us to accept forms and
          respond to our users with the content they request. HubSpot uses cookies to help gauge the interest of our users,
          make repetitive tasks easier, serve personalized content, and facilitate our marketing. You consent to
          HubSpot&rsquo;s use of cookies when you use our Portal. To reject cookies from HubSpot, you can turn them off in
          your web browser. </span>
      </p>
      <p class="c5"><span class="c10">Our Portal uses Google Analytics, a web analysis service of Google Inc.
          (&quot;Google&quot;). Google Analytics uses cookies. The information generated by the cookies about your use of
          our Portal is usually transferred to a Google server in the United States and stored there. We use Google
          Analytics only with IP anonymization enabled. This means that Google will truncate the IP address of users within
          Member States of the European Union or in other signatory states to the Agreement on the European Economic Area to
          exclude any personal relationship. You can prevent Google from collecting the data generated by the cookies
          relating to your use of our Portal (including your IP address) and from processing this data by Google by
          downloading and installing the browser plug-in available at&nbsp;</span><span class="c23"><a class="c14"
            href="https://www.google.com/url?q=http://tools.google.com/dlpage/gaoptout&amp;sa=D&amp;source=editors&amp;ust=1659411762483760&amp;usg=AOvVaw2WyTVq0_F0OM7Wa5Rpys7w">http://tools.google.com/dlpage/gaoptout</a></span><span
          class="c3">.</span></p>
      <p class="c5"><span class="c8">Confidentiality and Security of Personal Information Collected </span></p>
      <p class="c5"><span class="c3">Crown Bioscience is strongly committed to protecting personal information against
          unauthorized access, use or disclosure. Consequently, Crown Bioscience limits employee access to personal
          information to only those employees who need access to the information in the performance of their official
          duties. Employees who have access to such information follow appropriate protocols in connection with the use and
          disclosure of personal information. Additionally, Crown Bioscience has implemented measures to safeguard the
          personal information and to improve the security of its information technology, including, but not limited to,
          authentication, authorization, monitoring, auditing, and encryption. These security measures have been integrated
          into the design, implementation, and day-to-day operations of our Portal as part of our continuing commitment to
          the security of electronic content as well as the electronic transmission of information. For website security
          purposes and to maintain the availability of our Portal for all users, Crown Bioscience employs software to
          monitor traffic to identify unauthorized attempts to upload or change information or otherwise damage our
          Portal.</span>
      </p>
      <p class="c5"><span class="c2">While we use administrative, technical, and physical security measures to help protect
          your personal information and have taken reasonable steps to secure the personal information you provide to us,
          please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data
          transmission can be guaranteed against any interception or other type of misuse. &nbsp;Any information disclosed
          online is vulnerable to interception and misuse by unauthorized parties. Therefore, we cannot guarantee complete
          security if you provide personal information.</span>
      </p>
      <p class="c5"><span class="c27">Disclosure of Your Personal Information</span></p>
      <p class="c7"><span class="c3">We will share information collected about you with third parties in certain situations:
        </span></p>
      <ul class="c12 lst-kix_list_6-0">
        <li class="c1 li-bullet-0"><span class="c3">If we believe the release of information about you is necessary to
            respond to legal process, to investigate or remedy potential violations of our policies, &nbsp;to detect,
            prevent, or otherwise address fraud, security, or technical issues, or to protect the rights, property, and
            safety of others, we will share your information as permitted or required by any applicable law, rule, or
            regulation. &nbsp;</span>
        </li>
      </ul>
      <p class="c4"><span class="c3">We may share your information:</span></p>
      <ul class="c12 lst-kix_list_6-0">
        <li class="c5 c13 li-bullet-0"><span class="c3">with third parties that perform services for us or on our behalf,
            including data analysis, email delivery, hosting service, customer service, and marketing assistance.
            &nbsp;</span>
        </li>
        <li class="c5 c13 li-bullet-0"><span class="c3">with our affiliates who honor this Privacy Policy. Affiliates
            include our parent company and any subsidiaries, joint venture partners or other companies that we control or
            that are under common control with us.</span>
        </li>
        <li class="c5 c13 li-bullet-0"><span class="c3">with our trusted business partners to offer you certain products,
            services or promotions.</span>
        </li>
        <li class="c5 c13 li-bullet-0"><span class="c3">Some Crown Bioscience products and/or services may be co-branded and
            offered in conjunction with another company. If you register for or use such products and/or services, both
            Crown Bioscience and the other company may receive information collected in connection with the co-branded
            product/services.</span>
        </li>
        <li class="c5 c13 li-bullet-0"><span class="c3">If we reorganize or sell all or a portion of our assets, undergo a
            merger, or are acquired by another entity, we may transfer your information to the successor entity. &nbsp;If we
            go out of business or enter into bankruptcy, your information would be an asset transferred or acquired by a
            third party. You acknowledge that such transfers may occur and that the transferee may decline to honor
            commitments we made in this Privacy Policy.</span>
        </li>
      </ul>
      <p class="c5"><span class="c10">Your voluntary provision of personal information to Crown Bioscience constitutes your
          consent to the collection and disclosure of the information by Crown Bioscience for the above
          purposes.</span><span class="c22 c33">&nbsp;In other situations, we will notify you when personal information
          about you might be shared with third parties, and you will have an opportunity to choose not to share the
          information.</span>
      </p>
      <p class="c5"><span class="c2">Furthermore, please note that the content in your Portal account (other than your
          personal profile) will be viewable by all users who are authorized by your employer to access the Portal.
          &nbsp;When you post comments, contributions or other content to your account, your posts may be viewed by other
          users and may be publicly distributed outside our Portal permanently. We are not responsible for the actions of
          other users authorized by your employer who may view your personal or sensitive information, and we have no
          authority to manage or control other users authorized by your employer. &nbsp;</span>
      </p>
      <p class="c5"><span class="c8">International Data Transfer</span></p>
      <p class="c26"><span class="c3">We may transfer to, and store the data we collect about you in, countries other than
          the country in which the data was originally collected, including the United States or other destinations outside
          the European Economic Area (&ldquo;EEA&rdquo;) and the United Kingdom. Those countries may not have the same data
          protection laws as the country in which you provided the data. When we transfer your data to other countries, we
          will protect the data as described in this Privacy Policy and comply with applicable legal requirements providing
          adequate protection for the transfer of data to countries outside the EEA and outside the United Kingdom.</span>
      </p>
      <p class="c26"><span class="c3">If you are located in the EEA or the United Kingdom, we will only transfer your
          personal data if:</span>
      </p>
      <ul class="c12 lst-kix_list_13-0 start">
        <li class="c13 c33 c35 li-bullet-0"><span class="c3">the country to which the personal data will be transferred has
            been granted a European Commission adequacy decision; or</span>
        </li>
        <li class="c4 c13 li-bullet-0" id="h.gjdgxs"><span class="c3">we have put in place appropriate safeguards in respect
            of the transfer, for example we have entered into European Union standard contractual clauses and required
            additional safeguards with the recipient, or the recipient is a party to binding corporate rules approved by a
            European Union or United Kingdom supervisory authority.</span>
        </li>
      </ul>
      <p class="c26"><span class="c3">You may request more information about the safeguards that we have put in place in
          respect of transfers of personal data by contacting us at </span><span class="c23 c24"><a class="c14"
            href="mailto:privacy@crownBio.com">privacy@CrownBio.com</a></span><span class="c3">.</span></p>
      <p class="c5"><span class="c8">Access to and Change of Your Information and Privacy Preference</span></p>
      <h2 class="c7 c25"><span class="c10">You may submit a request to Crown Bioscience to inquire whether personal
          information pertaining to you has been collected. Any such request shall be made in writing and must be
          accompanied by reasonable proof of identity of the user. Reasonable proof of identity may include verification of
          a signature, inclusion of an identifier generally known only to you, or similar appropriate identification. Please
          submit your request to:&nbsp;</span><span class="c23"><a class="c14"
            href="mailto:privacy@crownBio.com">privacy@CrownBio.com</a></span><span class="c34">.</span><span
          class="c10">&nbsp;Crown Bioscience will undertake reasonable steps to permit you to correct, amend, or delete your
          information demonstrated to be inaccurate or incomplete.</span>
      </h2>
      <h2 class="c7 c25"><span class="c15">Account Information</span></h2>
      <p class="c7"><span class="c3">You may at any time review or change the information in your account or terminate your
          account by:</span>
      </p>
      <ul class="c12 lst-kix_list_10-0 start">
        <li class="c17 c13 li-bullet-0"><span class="c3">Logging into your account settings and updating your account; or
          </span></li>
        <li class="c13 c17 li-bullet-0"><span class="c10">Contacting us at </span><span class="c23"><a class="c14"
              href="mailto:privacy@crownBio.com">privacy@CrownBio.com</a></span><span class="c34">.</span></li>
      </ul>
      <p class="c17 c29"><span class="c3"></span></p>
      <p class="c7"><span class="c3">Upon your request to terminate your account, we will deactivate or delete your account
          and information from our active databases. However, some information may be retained in our files to prevent
          fraud, troubleshoot problems, assist with any investigations, enforce our Terms of Use and/or comply with legal
          requirements.</span>
      </p>
      <h2 class="c7 c25" id="h.30j0zll"><span class="c15">Cookies</span></h2>
      <p class="c7"><span class="c3">If you want to browse our Portal without being tracked the browsing history, you may do
          so by blocking cookies on your browser.</span>
      </p>
      <h2 class="c7 c25"><span class="c15">Emails and Communications</span></h2>
      <p class="c7"><span class="c3">If you no longer wish to receive correspondence, emails, or other communications from
          us, you may opt-out by:</span>
      </p>
      <ul class="c12 lst-kix_list_9-0 start">
        <li class="c17 c13 li-bullet-0"><span class="c3">Noting your preferences at the time you register your account;
          </span></li>
        <li class="c17 c13 li-bullet-0"><span class="c3">Logging into your account settings and updating your preferences;
            or</span>
        </li>
        <li class="c17 c13 li-bullet-0"><span class="c10">Contacting us at </span><span class="c23"><a class="c14"
              href="mailto:privacy@crownBio.com">privacy@CrownBio.com</a></span><span class="c3">.</span></li>
      </ul>
      <p class="c17 c29"><span class="c3"></span></p>
      <p class="c5"><span class="c3">If you no longer wish to receive correspondence, emails, or other communications from
          third parties, you are responsible for contacting the third party directly.</span>
      </p>
      <p class="c5"><span class="c8">Retention of Information Collected </span></p>
      <p class="c5"><span class="c10">The information collected by Crown Bioscience is retained by Crown Bioscience as
          determined by relevant legal retention periods. </span><span class="c33 c39">&nbsp;</span><span
          class="c3">Personal information in your account will be retained until you choose to remove it or until the
          account is closed. We retain your personal information for as long as is necessary to fulfil the purpose for which
          it was collected. We may retain your personal information for longer period due to reasons such as security and
          fraud prevention, tax or accounting record keeping, or complying with legal or regulatory requirements.</span>
      </p>
      <p class="c5"><span class="c8">Links to Third-Party Websites</span></p>
      <p class="c5"><span class="c3">Our Portal may provide links to third parties as a convenience in locating relevant
          information and services. We do not take any responsibility for the content, the accuracy of the information
          and/or quality of products or services provided by or advertised on these third-party websites. We are not
          responsible for the content or privacy and security practices and policies of any third parties, including other
          sites, services or applications that may be linked to or from our Portal.</span>
      </p>
      <p class="c5"><span class="c8">Your Data Protection Rights</span></p>
      <p class="c26"><span class="c3">If you are a &ldquo;data subject&rdquo; under applicable data protection law in the
          EEA or United Kingdom, you will have the following rights in relation to personal data that we hold about
          you:</span>
      </p>
      <ul class="c12 lst-kix_list_12-0 start">
        <li class="c5 c13 li-bullet-0"><span class="c3">Right to Access - to request confirmation of whether we process
            personal data relating to you, and if so, to request a copy of that personal data;</span>
        </li>
        <li class="c5 c13 li-bullet-0"><span class="c3">Right to Rectification - to request that we rectify or update any
            personal data that is inaccurate, incomplete or outdated;</span>
        </li>
        <li class="c5 c13 li-bullet-0"><span class="c3">Right to Erasure - to request that we erase your personal data in
            certain circumstances, such as where we collected personal data on the basis of your consent and you withdraw
            your consent;</span>
        </li>
        <li class="c5 c13 li-bullet-0"><span class="c3">Right to Restriction of Processing - to request that we restrict the
            use of your personal data in certain circumstances, such as while we consider another request that you have
            submitted, for example a request that we update your personal data;</span>
        </li>
        <li class="c5 c13 li-bullet-0"><span class="c3">Right to Withdraw Consent - where you have given us consent to
            process your personal data, to withdraw your consent; </span>
        </li>
        <li class="c5 c13 li-bullet-0"><span class="c3">Right to Data Portability - to request that we provide a copy of
            your personal data to you in a structured, commonly used and machine-readable format in certain
            circumstances;</span>
        </li>
        <li class="c5 c13 li-bullet-0"><span class="c3">Right to Object - to object to the processing of your information
            for certain purposes; and</span>
        </li>
        <li class="c5 c13 li-bullet-0"><span class="c3">Right to Object to Automated Decision Making - We use automated
            decision-making and profiling on our Portal. You have the right to object to our use of automated
            decision-making and profiling. You can do so by opting-out of cookies and similar technologies in accordance
            with the methods described in the relevant section above.</span>
        </li>
      </ul>
      <p class="c5"><span class="c10">To exercise your rights as set out above or to make a complaint or submit an inquiry
          about our privacy practices, please contact us at </span><span class="c23"><a class="c14"
            href="mailto:privacy@crownBio.com">privacy@CrownBio.com</a></span><span class="c3">.</span></p>
      <p class="c5"><span class="c8">User&#39;s Responsibility</span></p>
      <p class="c5"><span class="c3">While we are committed to protecting your privacy, Crown Bioscience cannot and does not
          guarantee absolute privacy. You are&nbsp;responsible for safeguarding your passwords for access to your account
          and are responsible for all actions made using your password. You will not share your passwords or provide access
          to another individual using your password.</span>
      </p>
      <p class="c5"><span class="c8">Changes to This Privacy Policy</span></p>
      <p class="c5"><span class="c3">Crown Bioscience will occasionally update this Privacy Policy to reflect changes in our
          practices. When changes are made to this Privacy Policy, we will revise the &quot;last updated&quot; date at the
          top of this page. We encourage you to periodically review this policy to be informed of how Crown Bioscience is
          protecting your information.</span>
      </p>
      <p class="c5"><span class="c8">Disclaimer</span></p>
      <p class="c5"><span class="c3">The information provided in this Privacy Policy should not be construed as giving
          business, legal, or other advice, or warranting, as fail proof, the security of information provided through our
          Portal.</span>
      </p>
      <p class="c5"><span class="c8">Contact Us</span></p>
      <p class="c5"><span class="c3">If you have questions or comments about this Privacy Policy, please contact us at:
        </span></p>
      <p class="c4"><span class="c3">Crown Bioscience, Inc.,</span></p>
      <p class="c4"><span class="c3">16550 West Bernardo Drive, </span></p>
      <p class="c4"><span class="c3">Building 5, Suite 525, </span></p>
      <p class="c4"><span class="c3">San Diego, CA 92127</span></p>
      <p class="c4"><span class="c3">Attention: Legal Department</span></p>
      <p class="c4"><span class="c23"><a class="c14" href="mailto:privacy@crownBio.com">privacy@CrownBio.com</a></span></p>
      <p class="c4"><span class="c3">&nbsp; &nbsp;</span></p>
      <div>
        <p class="c18"><span class="c24 c32"></span></p>
      </div>
    </body>
    </html>`,
  },
  not_now: { title: 'Accept Terms and Conditions', content: 'To proceed accept terms and conditions and privacy notice' },
};

export default TermsContent;
