import React from 'react';
import { FormatBold } from '@styled-icons/material/FormatBold';
import { FormatItalic } from '@styled-icons/material/FormatItalic';
import { FormatUnderlined } from '@styled-icons/material/FormatUnderlined';

import { FormatAlignCenter } from '@styled-icons/material/FormatAlignCenter';
import { FormatAlignJustify } from '@styled-icons/material/FormatAlignJustify';
import { FormatAlignLeft } from '@styled-icons/material/FormatAlignLeft';
import { FormatAlignRight } from '@styled-icons/material/FormatAlignRight';

import { BorderAll } from '@styled-icons/material/BorderAll';
import { BorderClear } from '@styled-icons/material/BorderClear';
import { BorderBottom } from '@styled-icons/material/BorderBottom';
import { BorderTop } from '@styled-icons/material/BorderTop';
import { BorderLeft } from '@styled-icons/material/BorderLeft';
import { BorderRight } from '@styled-icons/material/BorderRight';

import { Highlight } from '@styled-icons/boxicons-regular/Highlight'

import { Check } from '@styled-icons/material/Check'

import './balloonToolbar.css';

import {
  BalloonToolbar,
  getPluginType,
  MARK_BOLD,
  MARK_ITALIC,
  MARK_UNDERLINE,
  MarkToolbarButton,
  usePlateEditorRef,

  AlignToolbarButton,

  MARK_HIGHLIGHT,

  deleteColumn,
  deleteRow,
  deleteTable,
  insertTable,
  insertTableColumn,
  insertTableRow,
  TableToolbarButton,
} from '@udecode/plate';

const BalloonToolbarRTE = (
  props,
) => {
  const { children } = props;

  const editor = usePlateEditorRef();

  return (
    <BalloonToolbar classNames="customBalloonToolbar" styles={{ zIndex: '1500' }}>
      <MarkToolbarButton
        type={getPluginType(editor, MARK_BOLD)}
        icon={<FormatBold />}
        actionHandler="onMouseDown"
      />
      <MarkToolbarButton
        type={getPluginType(editor, MARK_ITALIC)}
        icon={<FormatItalic />}
        actionHandler="onMouseDown"
      />
      <MarkToolbarButton
        type={getPluginType(editor, MARK_UNDERLINE)}
        icon={<FormatUnderlined />}
        actionHandler="onMouseDown"
      />

      <AlignToolbarButton
        value="left"
        icon={<FormatAlignLeft />}
        actionHandler="onMouseDown"
      />
      <AlignToolbarButton
        value="center"
        icon={<FormatAlignCenter />}
        actionHandler="onMouseDown"
      />
      <AlignToolbarButton
        value="right"
        icon={<FormatAlignRight />}
        actionHandler="onMouseDown"

      />
      <AlignToolbarButton
        value="justify"
        icon={<FormatAlignJustify />}
        actionHandler="onMouseDown"
      />

      <MarkToolbarButton
        type={getPluginType(editor, MARK_HIGHLIGHT)}
        icon={<Highlight />}
        actionHandler="onMouseDown"
        selectedIcon={<Check />}
        className="balloonToolbarColor"
      />

      <TableToolbarButton
        icon={<BorderAll />}
        actionHandler="onMouseDown"
        transform={insertTable}
      />
      <TableToolbarButton
        icon={<BorderClear />}
        actionHandler="onMouseDown"
        transform={deleteTable}
      />
      <TableToolbarButton
        icon={<BorderBottom />}
        actionHandler="onMouseDown"
        transform={insertTableRow}
      />
      <TableToolbarButton
        icon={<BorderTop />}
        actionHandler="onMouseDown"
        transform={deleteRow}
      />
      <TableToolbarButton
        icon={<BorderLeft />}
        actionHandler="onMouseDown"
        transform={insertTableColumn}
      />
      <TableToolbarButton
        icon={<BorderRight />}
        actionHandler="onMouseDown"
        transform={deleteColumn}
      />

      {children}
    </BalloonToolbar>
  );
};

export default BalloonToolbarRTE
