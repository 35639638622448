import moment from 'moment';
import React from 'react';
import {
  DEFAULT_GRIDOPTS,
  HEADER_WITHOUT_PADDING,
} from '../constants';
import {
  FileNameColumnCellRenderer, FileNameDeleteSelectCellRenderer, FileNameDownloadSelectCellRenderer, FolderNameColumnCellRenderer, FolderNameDeleteSelectCellRenderer, StudyDocsDescColumnCellRenderer,
} from '../resources/utils';

export const naturalSort = (valueA, valueB) => {
  const lowerA = valueA.toLowerCase();
  const lowerB = valueB.toLowerCase();
  return (
    (lowerB.localeCompare('a') >= 0) - (lowerA.localeCompare('a') >= 0)
    || lowerA.localeCompare(lowerB)
  );
};

export const getFormattedMixedFolderTableGridOpts = async (
  filesData,
  foldersData,
  isDownloadAuthorized,
  isCustomFoldersEnabled,
  // eslint-disable-next-line
  downloadAllHeaderComponent = (<a href="#" />),
  enterFolderHandler = () => { },
  deleteFolderHandler = () => { },
  isExternalUser,
) => {
  const tableColumnName = {
    suppressMenu: true,
    unSortIcon: true,
    sortable: true,
    sort: 'asc',
    sortingOrder: ['asc', 'desc'],
    headerName: 'Name',
    field: 'name',
    cellClass: 'font-size-14',
    headerClass: ['font-size-14', 'ellipse-onwrap'],
    minWidth: 100,
    comparator: naturalSort,
    cellRenderer: (params) => {
      const p = { ...params, enterFolderHandler };
      return params.data.isFolder ? (
        <FolderNameColumnCellRenderer params={p} />
      ) : (
        <FileNameColumnCellRenderer params={params} />
      );
    },
  };
  const tableColumnDescription = {
    suppressMenu: true,
    unSortIcon: true,
    sortable: true,
    sortingOrder: ['asc', 'desc'],
    headerName: 'Description',
    field: 'description',
    cellClass: 'font-size-14',
    headerClass: ['font-size-14', 'ellipse-onwrap'],
    cellRenderer: (params) => (
      <StudyDocsDescColumnCellRenderer params={params} />
    ),
  };
  const tableColumnLastModified = {
    suppressMenu: true,
    unSortIcon: true,
    sortable: true,
    sortingOrder: ['asc', 'desc'],
    headerName: 'Last Modified',
    field: 'lastModified',
    cellClass: 'font-size-14',
    headerClass: ['font-size-14'],
    headerComponentParams: {
      template: HEADER_WITHOUT_PADDING,
    },
  };
  const tableColumnDownload = {
    field: 'download',
    headerName: '',
    headerClass: ['font-size-10'],
    maxWidth: 120,
    // eslint-disable-next-line
    cellClass: (params) =>
      // eslint-disable-next-line
      !params.data.isFolder
        ? [
          'agGrid-downloadIconColumn',
          'text-align-center',
          'justify-content-center',
          'padding-l0',
        ]
        : null,
    headerComponent: () => downloadAllHeaderComponent,
    cellRenderer: (params) => {
      const p = { ...params, enterFolderHandler };
      return !params.data.isFolder ? (
        <FileNameDownloadSelectCellRenderer params={p} />
      ) : null;
    },
  };
  const tableColumnDelete = {
    headerName: 'Delete',
    field: 'delete',
    suppressMenu: true,
    cellClass: [
      'font-size-14',
      'text-align-center',
      'justify-content-center',
    ],
    maxWidth: 100,
    headerComponentParams: {
      template: HEADER_WITHOUT_PADDING,
    },
    headerClass: ['font-size-14'],
    cellRenderer: (params) => {
      const p = { ...params, deleteFolderHandler };
      const renderFolderDelete = isCustomFoldersEnabled ? <FolderNameDeleteSelectCellRenderer params={p} /> : '';
      const renderFileDelete = !isExternalUser ? <FileNameDeleteSelectCellRenderer params={p} /> : '';
      return !params.data.isFolder ? (
        renderFileDelete
      ) : renderFolderDelete;
    },
  };
  const formatFilesTableGridOpts = { ...DEFAULT_GRIDOPTS };
  formatFilesTableGridOpts.onFirstDataRendered = function onFirstDataRendered(
    params,
  ) {
    params.api.sizeColumnsToFit();
  };
  const columnDefs = [
    tableColumnName,
    tableColumnDescription,
    tableColumnLastModified,
    tableColumnDownload,
    !isExternalUser ? tableColumnDelete : null,
  ].filter((column) => column !== null);

  if (!isDownloadAuthorized) {
    // It will remove download column
    columnDefs.pop()
  }
  // Mixing files and folders
  const rowData = [];
  filesData.forEach((file) => {
    rowData.push({
      name: file.name,
      description: file.description,
      lastModified:
        file.lastModified !== '-'
          ? moment(file.lastModified).format('DD MMM YYYY')
          : '-',
      path: file.s3Path,
      ifFolder: false,
    });
  });
  Object.values(foldersData).forEach((folder) => {
    if (folder.name) {
      rowData.push({
        name: folder.name,
        description: '',
        lastModified: folder.lastModified && folder.lastModified !== '-'
          ? moment(folder.lastModified).format('DD MMM YYYY')
          : '-',
        path: '',
        isFolder: true,
      });
    }
  });
  formatFilesTableGridOpts.rowData = rowData;
  formatFilesTableGridOpts.columnDefs = columnDefs;
  return formatFilesTableGridOpts;
};
