import React, {
  useState,
  useMemo,
  useEffect,
  useRef,
} from 'react';
import {
  Nav,
  Tab,
} from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import ExperimentsTable from './components/landing/StudiesTable';
import PasswordExpire from './components/auth/PasswordExpire';
import { useAuth } from '../providers/auth';
import { GROUPS } from '../constants';
import { useGraphQL } from '../providers/graphql';
import { useAnalytics } from '../providers/analytics';
import IconUserLandingV3 from './IconUserLandingV3.png';
import './UserLanding.css';

const UserLanding = function UserLanding({ toggleChangePassword }) {
  const { user, signOut } = useAuth();
  const {
    setLoading,
    loading,
    studies,
    studiesData,
    setCategory,
    category,
    updateStudiesV2,
    setStudies,
    getUserPreferences,
  } = useGraphQL();
  const [authCheckStudyList, setAuthCheckStudyList] = useState(false);
  const [showPasswordExpire, setShowPasswordExpire] = useState(false);
  const [passwordExpiryDiff, setPasswordExpiryDiff] = useState(0);
  const [activeFilterEvent, setActiveFilterEvent] = useState(false);

  const [analyticsLoadTick, setAnalyticsLoadTick] = useState(false);
  const analyticsSent = useRef(false);
  const analytics = useAnalytics();
  const analyticsTrack = useMemo(() => {
    const lsItem = localStorage.getItem('loginStartTime');
    return analytics?.startTrack(
      lsItem !== null ? 'login-to-study-list-load' : 'study-list-load',
      lsItem ?? Date.now(),
    )
  }, [analytics]);
  useEffect(() => {
    if (!analyticsLoadTick || analyticsSent.current) return;
    if (analyticsLoadTick && loading === false) {
      localStorage.removeItem('loginStartTime');
      analyticsTrack?.endTrack(user.email, user.groups);
      analyticsSent.current = true;
    }
  }, [analyticsLoadTick, analyticsTrack, loading, user.email, user.groups]);

  useEffect(() => {
    setLoading(true)
  }, [setLoading])
  useEffect(() => {
    setStudies([]);
    if (user.groups) {
      setAuthCheckStudyList(
        user.groups.includes(GROUPS.COMPANY_PROJECTS)
        || user.groups.includes(GROUPS.ALL_PROJECTS)
        || user.groups.includes(GROUPS.INDIVIDUAL_PROJECTS)
        || user.groups.includes(GROUPS.DEMO_DATA),
      );
    }
    // eslint-disable-next-line
  }, [user]);
  useEffect(() => {
    if (authCheckStudyList) {
      setStudies([]);
      updateStudiesV2().then(() => {
        setAnalyticsLoadTick(true);
      })
    }
    // eslint-disable-next-line
  }, [authCheckStudyList, user, category]);
  const location = useLocation();

  useEffect(() => {
    setCategory('active');
  }, [location, setCategory]);

  useEffect(() => {
    if (category === 'active' && !studies?.length && !loading && !activeFilterEvent) {
      setCategory('all');
    }
  }, [category, loading, studies, setCategory, activeFilterEvent]);

  const togglePasswordExpire = () => {
    setShowPasswordExpire(!showPasswordExpire);
  };

  useEffect(() => {
    async function checkPasswordExpiry() {
      const response = await getUserPreferences(user.id);
      const updatedAt = response.data.preferencesForUser.items[0].lastUpdatedPasswordAt;
      if (!updatedAt) return;
      const passwordExpiry = moment.unix(moment().unix()).diff(moment.unix(updatedAt), 'days');
      const hideModal = sessionStorage.getItem('passwordExpiryWarning')
      // 90 - 13 = 77 is for less than 14 day away from password expiry
      if (passwordExpiry >= 77 && passwordExpiry <= 90 && !hideModal) {
        togglePasswordExpire()
        const daysLeft = 90 - passwordExpiry
        setPasswordExpiryDiff(daysLeft)
      }
      if (passwordExpiry > 90) {
        signOut()
      }
    }
    if (user) {
      checkPasswordExpiry()
    }
    // eslint-disable-next-line
  }, [user]);

  const handleFilterClick = (c) => {
    setActiveFilterEvent(true)
    setCategory(c)
  }

  return (
    <div className="landing-inner-container-mphs">
      {authCheckStudyList
        && (
          <>
            <div className="data-grid">
              <div className="d-flex text_container" style={{ marginLeft: '-0.5%' }}>
                <img className="landing-icon" src={IconUserLandingV3} alt="" height="50" width="50" />
                <span className="icon-txt AvenirNextLTProRegular">Study List</span>
              </div>
              {!loading ? (
                <Tab.Content className="tab-content-study-list">
                  <Nav className="card-header-pills-2 mt-2 study-list-nav-pills-band study-list" variant="pills">
                    {[{ label: 'All', status: 'all' },
                      { label: 'Active', status: 'active' },
                      { label: 'Completed', status: 'completed' },
                      { label: 'Other', status: 'other' },
                    ].map(({ status, label }, index) => (
                      <Nav.Item
                        /* eslint-disable-next-line react/no-array-index-key */
                        key={`nav-item-${index}`}
                      >
                        <Nav.Link
                          className={`badge nav-link study-list-pill ${status === category ? 'active' : ''}`}
                          eventKey={`tab-${index}`}
                          onClick={() => handleFilterClick(status)}
                        >
                          {label}
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                </Tab.Content>
              ) : ''}
              <ExperimentsTable studies={studies} studiesData={studiesData} />
              <PasswordExpire
                showPasswordExpire={showPasswordExpire}
                passwordExpiryDiff={passwordExpiryDiff}
                togglePasswordExpire={togglePasswordExpire}
                toggleChangePassword={toggleChangePassword}
              />
            </div>
          </>
        )}
    </div>
  );
}
export default UserLanding;
