import React, { useState, useEffect } from 'react';
import docsConfig from '../DocsVersionConfig.json';
import DocsVersionSelect from './DocsVersionSelectComponent';
import DocsMuiltiLangSelectComponent from './DocsMuiltiLangSelectComponent';
import { getPDFEmbededObj } from '../utils';

const { privacyPolicy } = docsConfig;

export default ({ location: { state } }) => {
  // Initialize state variables to hold defaultDate and defaultLang
  const [defaultDate, setDefaultDate] = useState(privacyPolicy[0].date);
  const [defaultLang, setDefaultLang] = useState(privacyPolicy[0].lang);
  const [filteredPrivacyPolicy, setFilteredPrivacyPolicy] = useState(privacyPolicy);

  // useEffect to update the state variables when state changes
  useEffect(() => {
    if (state?.date) {
      const fileEntry = privacyPolicy.find(
        (x) => x.date === state.date && x.lang === defaultLang,
      );
      if (fileEntry) {
        // Check if the object with the new values exists
        setDefaultDate(state.date);
      }
    }
    if (state?.lang) {
      const fileEntry = privacyPolicy.find(
        (x) => x.date === defaultDate && x.lang === state.lang,
      );
      if (fileEntry) {
        // Check if the object with the new values exists
        setDefaultLang(state.lang);
      }
    }

    // Filtering the objects and adding the 'disable' key
    setFilteredPrivacyPolicy(privacyPolicy.map((item) => ({
      ...item,
      disable: item.date !== defaultDate,
    })))
  }, [state, defaultLang, defaultDate]);
  // Filter the file entry based on the defaultDate and defaultLang
  const fileEntry = privacyPolicy.find(
    (x) => x.date === defaultDate && x.lang === defaultLang,
  );
  return (
    <div>
      <DocsVersionSelect
        versionList={privacyPolicy}
        defaultValue={defaultDate}
      />
      <DocsMuiltiLangSelectComponent
        versionList={filteredPrivacyPolicy}
        defaultValue={defaultLang}
      />
      {getPDFEmbededObj('PrivacyPolicy', fileEntry)}
    </div>
  );
};
