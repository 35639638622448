// Main component for modal on showing system messages.

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import React, { useEffect, useState } from 'react';
import { CUSTOM_BOX_STYLE } from '../../../../constants';

import { useGraphQL } from '../../../../providers/graphql';
import RichTextEditor from '../../ReusableComponents/RichTextEditor/RichTextEditor';
import { parseJSONOrStringValueToRTE } from '../../../utils';

// const emptyMessage = {
//   id: '',
//   userId: '',
//   status: 'RECEIVED',
//   messageContent: 'PLACEHOLDER',
//   createdAt: '2023-01-01T00:00:00.000000+00:00',
// }
const emptyMessageList = []
// --------------------

const GetSystemMessagesModal = ({ props }) => {
  const {
    id = 0, // base identification
    showModal, // Whether to show modal or not.
    toggleModal, // helper function to toggle showModal state.
    messageContents = emptyMessageList, // message content list to show.
    rteid = 'rte',
  } = props
  // GRAPHQL Imports
  const { updateSystemMessagesReceptionStatus } = useGraphQL();

  const [message, setMessage] = useState(null)
  const [messageList, setMessageList] = useState(emptyMessageList)
  const [currentIdx, setCurrentIdx] = useState(null)
  // TODO: change to handle multiple messages.

  useEffect(() => {
    setMessageList(messageContents)
  }, [messageContents])

  useEffect(() => {
    if (messageList) { setCurrentIdx(0) }
  }, [messageList])

  useEffect(() => {
    if (messageList && (currentIdx !== null)) { setMessage(messageList[currentIdx]) }
  }, [currentIdx, messageList])

  const setAllMessagesToSeen = () => {
    messageList.forEach((msg) => {
      setMessageToSeen(msg)
    })
  }

  const setMessageToSeen = (msg) => {
    if (msg && msg.id) {
      updateSystemMessagesReceptionStatus(msg.id, 'READ')
    }
  }
  const onHide = () => {
    // reset state
    setMessage(null)
    setMessageList(emptyMessageList)
    setCurrentIdx(null)
    toggleModal()
  };

  const renderButtons = (idx) => {
    let buttons = <></>
    const nextButton = (
      <Button
        data-testid="next_message_button"
        className="LoginFormOutlineButton"
        type="button"
        variant="light"
        onClick={() => { setCurrentIdx(idx + 1) }}
      >
        Next
      </Button>
    )
    const closeButton = (
      <Button
        data-testid="cancel_message_button"
        className="LoginFormSignInButtonChangePassword"
        type="button"
        variant="primary"
        onClick={() => { setAllMessagesToSeen(); onHide() }}
      >
        Close
      </Button>
    )
    const previousButton = (
      <Button
        data-testid="previous_message_button"
        className="LoginFormOutlineButton"
        type="button"
        variant="light"
        onClick={() => { setCurrentIdx(idx - 1) }}
      >
        Previous
      </Button>
    )

    if (idx === messageList.length - 1) {
      if (messageList.length === 1) {
        buttons = (
          <>{closeButton}</>
        )
      } else {
        buttons = (
          <div>
            {previousButton}
            {closeButton}
          </div>
        )
      }
    } else if (idx === 0) {
      buttons = (
        <>{nextButton}</>
      )
    } else if (idx >= 1 && idx < messageList.length - 1) {
      buttons = (
        <div>
          {previousButton}
          {nextButton}
        </div>
      )
    }
    return buttons
  }

  const renderMessages = (idx) => {
    if (message == null) { return <></> }
    return (
      <>
        <Box>
          <span style={{ color: 'white' }}>{new Date(message.createdAt).toLocaleString()}</span>
          <div
            style={{
              height: '300px',
              minHeight: '300px',
              background: 'white',
              padding: '8px',
              overflow: 'scroll',
            }}
            name="messageContentRTE"
            id="messageContentRTE"
            data-testid={rteid}
          >
            <RichTextEditor
              showToolbar={false}
              showBalloonToolbar={false}
              initialValue={parseJSONOrStringValueToRTE(message.messageContent)}
              readOnly
            />
          </div>
          <div>
            {renderButtons(idx)}
          </div>
        </Box>
      </>
    )
  }

  return (
    <Modal open={showModal} data-id={id} data-testid={`test-${id}`}>
      <Box sx={CUSTOM_BOX_STYLE}>
        <div style={{ textAlign: 'center' }}>
          <span className="containerTitle" data-testid="container_Title">System Messages</span>
          <span data-testid="test-counter" style={{ color: 'white', float: 'right' }}>({currentIdx == null ? 0 : currentIdx + 1}/{messageList.length})</span>
        </div>
        {renderMessages(currentIdx)}
      </Box>
    </Modal>
  )
}

export default GetSystemMessagesModal;
