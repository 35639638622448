import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthState } from '@aws-amplify/ui-components';
import _ from 'lodash';
import { useAuth } from '../providers/auth';
import LoadingSession from './components/auth/LoadingSession';

const ProtectedRoute = function ({
  component: Component, accessibleBy = [], componentProps, ...restOfProps
}) {
  const { user, authState } = useAuth();
  const isAuthenticated = authState === AuthState.SignedIn && !_.isEmpty(user);
  const isAccessible = accessibleBy.length ? accessibleBy.findIndex((target) => (user?.groups || []).includes(target)) > -1 : true;

  return (
    <Route
      {...restOfProps}
      render={(props) => (isAuthenticated
        ? (
          <>
            {isAccessible
              ? <Component {...componentProps} {...props} /> : <Redirect to="/landing" />}
          </>
        )
        : <LoadingSession />
      )}
    />
  );
}
export default ProtectedRoute;
