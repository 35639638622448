import React from 'react';
import { FormatBold } from '@styled-icons/material/FormatBold';
import { FormatItalic } from '@styled-icons/material/FormatItalic';
import { FormatUnderlined } from '@styled-icons/material/FormatUnderlined';
import { FormatStrikethrough } from '@styled-icons/material/FormatStrikethrough';

import {
  MarkToolbarButton,
  MARK_BOLD, MARK_ITALIC,
  MARK_UNDERLINE, MARK_STRIKETHROUGH,
} from '@udecode/plate';

import tooltip from '../tooltip/tooltip';

// eslint-disable-next-line arrow-body-style
const FontPropertiesButtons = () => {
  return (
    <>
      <MarkToolbarButton
        type={MARK_BOLD}
        icon={<FormatBold />}
        tooltip={tooltip('Bold')}
      />
      <MarkToolbarButton
        type={MARK_ITALIC}
        icon={<FormatItalic />}
        tooltip={tooltip('Italic')}
      />
      <MarkToolbarButton
        type={MARK_UNDERLINE}
        icon={<FormatUnderlined />}
        tooltip={tooltip('Underline')}
      />
      <MarkToolbarButton
        type={MARK_STRIKETHROUGH}
        icon={<FormatStrikethrough />}
        tooltip={tooltip('Strike Through')}
      />
    </>
  );
};

export default FontPropertiesButtons;
