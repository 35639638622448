import {
  createLinkPlugin,
  PlateFloatingLink,
} from '@udecode/plate';
import defaultPluginsConfig from '../defaultPluginsConfig';

/**
 * if onValidationURL is passed by consumer of rich text editor, then it will be used
 */
const getHyperLinkOptions = (onValidateURL = defaultPluginsConfig.hyperLinkConfig.onValidateURL) => {
  let hyperlinkOptions = {}

  if (onValidateURL) {
    hyperlinkOptions = Object.assign(hyperlinkOptions, {
      isUrl: onValidateURL,
    })
  }
  return hyperlinkOptions;
}

/**
 * Creates plugin for hyper link
 * @param {function} validate - returns true or false, you can write business logic here to allow or disallow a url
 */
const createHyperLinkPlugin = (onValidateURL, shouldShowFloatingLink = false) => {
  const linkPlugin = createLinkPlugin({
    renderAfterEditable: shouldShowFloatingLink ? PlateFloatingLink : undefined,
    options: { ...getHyperLinkOptions(onValidateURL) },
  })
  return linkPlugin
}

export default createHyperLinkPlugin;
