import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Form, Row, Col } from 'react-bootstrap';
import { CUSTOM_BOX_STYLE } from '../../../constants';

function AddAccountAdminsModal({
  showAddAccountAdminModal,
  toggleAddAccountAdminModal,
  whiteListedDomains,
  createdCompany,
  setMessageType,
  setShowResponseMessage,
  getCompanyInfoById,
  createUserForCompany,
  setShowLoader,
}) {
  const [whitelistedDomainsError, setwhitelistedDomainsError] = useState('');
  const [createButton, setcreateButton] = useState(true);
  const [emails, setEmails] = useState([]);

  const onHide = () => {
    toggleAddAccountAdminModal();
  };

  const onAddAccountAdmins = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setShowLoader(true)
    getCompanyInfoById(createdCompany).then((res) => {
      setShowLoader(false)
      if (res?.data?.getCompanyAccount) {
        const companyAccount = res.data.getCompanyAccount;
        const companyGroup = companyAccount.studyIdentifiers.length
          ? companyAccount.studyIdentifiers[0]
          : '';
        const companyId = companyAccount.id;
        const requestData = emails.map((e) => ({
          email: e.trim(),
          company_id: companyId,
          group: companyGroup,
          other_groups: ['AccountAdmin'],
          force_creation: false,
        }));
        setShowLoader(true)
        createUserForCompany(requestData).then((res) => {
          setShowLoader(false)
          const response = res.data.createUserForCompany;
          if (response.message) {
            setMessageType('invited');
          } else if (response.error) {
            setMessageType('error');
          }
        });
      } else {
        setMessageType('error');
      }
      setShowResponseMessage(true);

    });
    onHide();
  };

  const whitelistedDomainsOnChange = (e) => {
    let noValidationError = true;
    const whiteListedDomainsList = whiteListedDomains[0].split(',');
    if (e.target.value === '') {
      setwhitelistedDomainsError('');
      setcreateButton(true);
      noValidationError = false;
    } else {
      const emails = e.target.value.split(',');
      setEmails(emails);
      emails.map((val) => {
        const domainForEach = val.split('@');
        if (val.length === 0 || domainForEach.length !== 2) {
          setwhitelistedDomainsError(
            'Improper format. Please enter the email in proper format. Example: abc@whitelisteddomains.com',
          );
          setcreateButton(true);
          noValidationError = false;
        } else if (!whiteListedDomainsList.includes(`@${domainForEach[1]}`)) {
          setwhitelistedDomainsError(
            'Error: User does not meet the whitelisted email domain requirements.',
          );
          setcreateButton(true);
          noValidationError = false;
        }
        return null;
      });
    }
    if (noValidationError) {
      setwhitelistedDomainsError('');
      setcreateButton(false);
    }
  };

  return (
    <Modal
      open={showAddAccountAdminModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={CUSTOM_BOX_STYLE}>
        <div style={{ textAlign: 'center' }}>
          <span className="containerTitle" data-testid="container_Title">
            Add Account Admin(s)
          </span>
        </div>
        <Form onSubmit={onAddAccountAdmins}>
          <Row>
            <Col>
              <Row>
                <div style={{ width: '100%' }}>
                  <Form.Group
                    // controlId="formBasicSelect"
                    className="studyDetails-dataFilters-formGroup"
                    style={{ marginLeft: '10px' }}
                  >
                    <Form.Label
                      className="addAccount-formLabel"
                      htmlFor="domainWhiteListing"
                      data-testid="whitelisted_email_domains_list"
                    >
                      Whitelisted Email Domain(s):
                    </Form.Label>

                    <p style={{ color: '#FFFFFF' }}>{' '}{whiteListedDomains}</p>

                    <Form.Label
                      className="addAccount-formLabel"
                      htmlFor="domainWhiteListing"
                      data-testid="whitelisted_email_domain"
                    >
                      Email(s)
                    </Form.Label>
                    <span style={{ content: ' *', color: 'red' }}>
                      <sup>*</sup>
                    </span>
                    <Form.Control
                      id="domainWhiteListing"
                      data-testid="domainWhiteListing"
                      style={{ height: '45px', marginBottom: '-20px' }}
                      as="input"
                      defaultValue=""
                      placeholder="something@1stdomain.com,something@2nddomain.com,something@3rddomain.com"
                      required
                      onChange={(e) => whitelistedDomainsOnChange(e)}
                    />
                    <p
                      style={{ color: '#E2AAA0', marginTop: '20px' }}
                      data-testid="email_error"
                    >
                      {' '}
                      {whitelistedDomainsError}
                    </p>
                  </Form.Group>
                </div>
              </Row>
            </Col>
          </Row>
          <Button
            className="LoginFormSignInButtonChangePassword"
            type="submit"
            variant="primary"
            disabled={createButton}
            data-testid="add_adminbutton"
          >
            Add Admin(s)
          </Button>

          <Button
            className="LoginFormOutlineButton"
            type="button"
            variant="light"
            onClick={onHide}
            data-testid="skip_button"
          >
            Skip
          </Button>
        </Form>
      </Box>
    </Modal>
  );
}

export default AddAccountAdminsModal;
