import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import InputLabel from '@mui/material/InputLabel';
import { Button } from 'react-bootstrap';
import { TextField } from '@mui/material';
import './auth.css'
import { Auth } from 'aws-amplify';
import moment from 'moment';
import isValidPassword from './utils';
import { useGraphQL } from '../../../providers/graphql';

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    fontFamily: 'AvenirNextLTPro-Regular',
    width: '400px',
    '&.Mui-focused': {
      backgroundColor: '#fff',
      fontFamily: 'AvenirNextLTPro-Regular',
    },
  },
  focused: {},
  notchedOutline: {},
}));

const ForgotPassword = function NewPasswordComponent({
  email = '',
  setForgotPasswordFlow,
  setErrorMessage,
  setSuccessMsg,
  /* renderBackToLogin = () => { }, */
}) {
  const [userEmail, setUserEmail] = useState(email)
  const [newPassword, setNewPassword] = useState('');
  const [passcode, setPasscode] = useState('');
  const [loading, setLoading] = useState(false);
  const [resettingPassword, setResettingPassword] = useState(false);
  const classes = useStyles();
  const { updateUserPreferences, loadOrCreateUserPreferences } = useGraphQL();

  const onHide = () => {
    // userEmail = '';
    setErrorMessage('');
    setSuccessMsg('');
    setForgotPasswordFlow();
  }

  const submitNewPassword = (e) => {
    e.preventDefault();
    setLoading(true)
    Auth.forgotPasswordSubmit(userEmail, passcode, newPassword)
      .then(() => {
        Auth.configure({ authenticationFlowType: 'USER_SRP_AUTH' }); // disable MFA
        Auth.signIn(userEmail, newPassword)
          .then(async (user) => {
            const userPreferences = await loadOrCreateUserPreferences(user.username);
            updateUserPreferences({
              ...userPreferences,
              lastUpdatedPasswordAt: moment().unix(),
            });
            Auth.signOut()
            setSuccessMsg('Successfully reset your password')
            setErrorMessage('')
            setForgotPasswordFlow(false);
          })
      })
      .catch((err) => {
        setLoading(false)
        setErrorMessage(err.message)
      });
  }

  const initForgotPassword = () => {
    setLoading(true);
    Auth.forgotPassword(userEmail)
      .then(() => {
        setResettingPassword(true)
        setErrorMessage('')
      })
      .catch((err) => {
        // if user does not have a verified email, this will error and prevent this from being possible
        setErrorMessage(err.message)
      })
      .finally(() => setLoading(false));
  }

  // // do we want this feature? skips entering email if the user has already entered it
  // useEffect(()=> {
  //     if(email) {
  //         initForgotPassword();
  //     }
  // }, [email])

  // eslint-disable-next-line no-unused-vars
  const isSubmitDisabled = () => {
    if (loading || !newPassword.length) {
      return true;
    }
    return !isValidPassword(newPassword);
  };

  return (
    <div>
      {!resettingPassword
        ? (
          <div>
            <form
              className="form"
              noValidate
              onSubmit={(e) => {
                e.preventDefault()
                initForgotPassword()
              }}
            >
              <div className="inputField">
                <InputLabel className="inputLabel" htmlFor="email">
                  Email Address
                </InputLabel>
                <TextField
                  id="email"
                  variant="outlined"
                  placeholder="Enter your email"
                  name="email"
                  autoComplete="email"
                  type="email"
                  value={userEmail}
                  required
                  InputProps={{
                    classes: {
                      root: classes.root,
                      focused: classes.focused,
                    },
                  }}
                  sx={{
                    width: '100%',
                  }}
                  onChange={(e) => setUserEmail(e.target.value)}
                />
                {/* {renderBackToLogin()} */}
              </div>
              <Button
                className="LoginFormSignInButton"
                type="submit"
                variant="primary"
                disabled={loading}
              >
                {loading ? 'Loading...' : 'Reset Password'}
              </Button>
              <Button
                className="LoginFormOutlineButton"
                type="button"
                variant="light"
                onClick={onHide}
              >
                Back
              </Button>
            </form>
          </div>
        )
        : (
          <div>
            <form className="form" onSubmit={submitNewPassword} noValidate>
              <div className="inputField">
                <InputLabel className="inputLabel" htmlFor="passcode">
                  Passcode
                </InputLabel>
                <TextField
                  variant="outlined"
                  autoComplete="false"
                  required
                  name="passcode"
                  type="text"
                  value={passcode}
                  autoFocus
                  InputProps={{
                    classes: {
                      root: classes.root,
                      focused: classes.focused,
                    },
                  }}
                  sx={{
                    width: '100%',
                  }}
                  onChange={(e) => setPasscode(e.target.value)}
                />
              </div>

              <div className="inputField">
                <InputLabel className="inputLabel" htmlFor="newPassword">
                  Password
                </InputLabel>
                <TextField
                  variant="outlined"
                  name="newPassword"
                  placeholder="Enter new password"
                  type="password"
                  value={newPassword}
                  id="newPassword"
                  autoFocus
                  InputProps={{
                    classes: {
                      root: classes.root,
                      focused: classes.focused,
                    },
                  }}
                  sx={{
                    width: '100%',
                  }}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
                {/* {renderBackToLogin()} */}
              </div>
              <p
                style={{
                  color: '#E2AAA0',
                  display: !isValidPassword(newPassword) ? 'block' : 'none',
                }}
              >
                Password must have at least 15 characters, 1 lowercase character, 1 uppercase character, 1 number, 1 special character
              </p>
              <Button
                className="LoginFormSignInButton"
                type="submit"
                variant="primary"
                disabled={loading}
              >
                {loading ? 'Loading...' : 'Set New Password'}
              </Button>
              <Button
                className="LoginFormOutlineButton"
                type="button"
                variant="light"
                onClick={onHide}
              >
                Back
              </Button>
            </form>
          </div>
        )}
    </div>
  )
}

export default ForgotPassword;
