import React from 'react';
import { Row, Col } from 'react-bootstrap'
import { styled } from '@mui/styles';
import Typography from '@mui/material/Typography'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { truncationOfLongStr } from '../utils';

const LightTooltip = styled(({ className, ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Tooltip {...props} classes={{ popper: className }} disableInteractive />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#FFFBEC',
    color: '#222222',
    top: '20px',
    border: '1px solid #FEE7D5',
    borderRadius: '2px',
    fontSize: '12px',
  },
}));

const DataFiltersHeader = ({
  filters, sortedExcludeGroups, isTumorVolumeDataAvailable, sortedAllExcludedGroup,
}) => (
  <Row style={{ marginBottom: '1%' }} data-testid="showDataFiltersBox">
    <Col>
      <Row style={{ marginLeft: '0' }}><p style={{ fontFamily: 'AvenirNextLTPro-Medium' }}>Date Range:&nbsp;</p><p style={{ fontFamily: 'AvenirNextLTPro-Medium' }}>{filters.dateRange?.startDay || filters.dateRange?.startDay === 0 ? `Day ${filters.dateRange.startDay} - Day ${filters.dateRange.endDay}` : filters.dateRange?.range}</p></Row>
      <Row style={{ marginLeft: '0' }}><p style={{ fontFamily: 'AvenirNextLTPro-Medium', marginBottom: '0' }}>Survival Analysis:&nbsp;</p><p style={{ fontFamily: 'AvenirNextLTPro-Medium' }}>{filters.survivalAnalysis === 'Include' ? 'Include' : 'Exclude'}</p></Row>
    </Col>
    <Col>
      <Row><p style={{ fontFamily: 'AvenirNextLTPro-Medium', marginBottom: '0' }}>Unassigned Mice:&nbsp;</p><p style={{ fontFamily: 'AvenirNextLTPro-Medium' }}>{filters.unassignedMice === 'Include' ? 'Include' : 'Exclude'}</p></Row>
      <Row><p style={{ fontFamily: 'AvenirNextLTPro-Medium', marginBottom: '0' }}>Dosing Times:&nbsp;</p><p style={{ fontFamily: 'AvenirNextLTPro-Medium' }}>{filters.dosingTimes === 'Include' ? 'Include' : 'Exclude'}</p></Row>
    </Col>
    <Col>
      <Row>
        <p style={{ fontFamily: 'AvenirNextLTPro-Medium', marginBottom: '0' }}>
          Excluded Groups:&nbsp;
        </p>
        <div style={{ fontFamily: 'AvenirNextLTPro-Medium' }}>
          {
            typeof filters.excludeGroups === 'object'
              ? filters.excludeGroups.map((groupName) => (
                <LightTooltip
                  className="navHeader-GetFeedback-toolTips AvenirNextLTProMedium"
                  title={(
                    <>
                      <Typography className="AvenirNextLTProRegular">
                        {sortedAllExcludedGroup.filter((excludeGroup) => excludeGroup.group_name === groupName)[0]?.group_name_to_show}
                      </Typography>
                    </>
                  )}
                  placement="left"
                >
                  <div>
                    {truncationOfLongStr(sortedAllExcludedGroup.filter((excludeGroup) => excludeGroup.group_name === groupName)[0]?.group_name_to_show)}
                  </div>
                </LightTooltip>
              ))
              : filters.excludeGroups
          }
        </div>
      </Row>
    </Col>
    <Col><Row><p style={{ fontFamily: 'AvenirNextLTPro-Medium', marginBottom: '0' }}>Reference Day (BWC):&nbsp;</p><p style={{ fontFamily: 'AvenirNextLTPro-Medium' }}>Day {filters.referenceDayForBWC}</p></Row></Col>
    {isTumorVolumeDataAvailable && (
    <>
      <Col>
        <Row>
          <p style={{ fontFamily: 'AvenirNextLTPro-Medium', marginBottom: '0' }}>
            TGI Control Group:&nbsp;
          </p>
          <LightTooltip
            className="navHeader-GetFeedback-toolTips AvenirNextLTProMedium"
            title={(
              <>
                <Typography className="AvenirNextLTProRegular">
                  {
                  filters.controlGroup !== 'undefined'
                    ? sortedExcludeGroups.filter((excludeGroup) => excludeGroup.group_name === filters.controlGroup)[0]?.group_name_to_show : 'No TGI'
                }
                </Typography>
              </>
          )}
            placement="bottom"
          >
            <p style={{ fontFamily: 'AvenirNextLTPro-Medium' }}>
              {
              filters.controlGroup !== 'undefined'
                ? truncationOfLongStr(sortedExcludeGroups.filter((excludeGroup) => excludeGroup.group_name === filters.controlGroup)[0]?.group_name_to_show) : 'No TGI'
            }
            </p>
          </LightTooltip>
        </Row>
      </Col>
      <Col>
        <Row>
          <p style={{ fontFamily: 'AvenirNextLTPro-Medium', marginBottom: '0' }}>Reference Day (TVC & TGI):&nbsp;</p>
          <p style={{ fontFamily: 'AvenirNextLTPro-Medium' }}>
            {[undefined, -1, '-1', null].includes(filters.referenceDayForTGI) ? 'None' : `Day ${filters.referenceDayForTGI}`}
          </p>
        </Row>
      </Col>
    </>
    )}

  </Row>
)

export default DataFiltersHeader
