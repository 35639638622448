import _ from 'lodash';
import moment from 'moment';
import { NOTIFICATION_EVENT_TYPE } from '../constants';

const sortObjectArrayByParam = (array, param) => array.sort((a, b) => {
  if (a[param] > b[param]) {
    return -1;
  }
  if (b[param] > a[param]) {
    return 1;
  }
  return 0;
});

export const filterGanttNotifications = (createdAt) => {
  const offset = new Date().getTimezoneOffset();
  const sixOClock = moment.unix(createdAt).add(offset, 'minutes').set({ hour: 0, minute: 0, second: 0 }).add(6, 'hours')
  const creationTimeUTC = moment.unix(createdAt).add(offset, 'minutes')
  if (creationTimeUTC.format('HH:mm:ss') !== '00:00:00') {
    const creationTime = moment.unix(createdAt)
    if (creationTime.hours() >= 6) {
      return { skip: moment().unix() < creationTime.unix(), time: creationTime.unix() }
    }
  }
  return { skip: moment().unix() < sixOClock.unix(), time: sixOClock.unix() }
}

export const createNotificationTimelinesForProjects = (
  notifications,
  checkpoints,
  startTime,
  optOutCodes,
  user,
) => {
  const timelines = {};
  const queryObjs = [];
  const clearCheckpoints = [];
  const currentTime = moment().add(1, 'days').unix()
  notifications.map((projectCode) => {
    timelines[projectCode] = [];
    return null;
  });
  checkpoints.map((obj) => {
    if (timelines[obj.projectCode]) {
      timelines[obj.projectCode] = [...timelines[obj.projectCode], obj];
    } else if (!optOutCodes.includes(obj.projectCode)) {
      const tempArray = [obj.projectCode]
      const { projectCodes: accepted } = projectCodesForOptOut(user, tempArray)
      if (accepted.includes(obj.projectCode)) {
        timelines[obj.projectCode] = [obj];
      } else {
        clearCheckpoints.push(obj)
      }
    } else {
      clearCheckpoints.push(obj)
    }
    return null;
  });
  Object.keys(timelines).forEach((projectCode) => {
    const objs = timelines[projectCode];
    if (!objs.length) {
      queryObjs.push({ projectCode, createdAtCondition: { between: [startTime, currentTime] } });
      return null;
    }
    while (objs.length) {
      const queryObj = { projectCode, createdAtCondition: { between: [startTime, currentTime] } };
      const head = objs.pop();
      if (head.type === 'SUB') {
        queryObj.createdAtCondition.between[0] = head.createdAt;
        const tail = objs.pop();
        if (tail) {
          queryObj.createdAtCondition = {
            between: [head.createdAt, tail.createdAt],
          };
        }
      }
      if (head.type === 'UNSUB') {
        queryObj.createdAtCondition = { between: [startTime, head.createdAt] };
      }
      queryObjs.push(queryObj);
    }
    return null;
  });
  return { queryObjs, clearCheckpoints };
};

const ganttNotificationsFilter = [NOTIFICATION_EVENT_TYPE.REDIRECT_TO_DASHBOARD.DOSING_BEGINS, NOTIFICATION_EVENT_TYPE.REDIRECT_TO_DASHBOARD.DOSING_ENDS, NOTIFICATION_EVENT_TYPE.REDIRECT_TO_DASHBOARD.ANIMAL_TERMINATION, NOTIFICATION_EVENT_TYPE.REDIRECT_TO_DASHBOARD.DOSING_BEGINS_FORECAST, NOTIFICATION_EVENT_TYPE.REDIRECT_TO_DASHBOARD.DOSING_ENDS_FORECAST, NOTIFICATION_EVENT_TYPE.REDIRECT_TO_DASHBOARD.ANIMAL_TERMINATION_FORECAST]

export const parseNotificationsDataForProjects = (
  notificationEvents, user,
) => {
  let notificationsArray = [];
  notificationEvents.map((obj) => {
    notificationsArray = [
      ...notificationsArray,
      ...(obj.data.notificationEventPerProjectCode || obj.data.listNotificationEvents).items.filter((item) => {
        if (user.groups.includes('DemoData')) {
          return true;
        }
        // document upload failed notification should be visible only to the file uploader
        if (item.type === NOTIFICATION_EVENT_TYPE.REDIRECT_TO_DASHBOARD.DOCUMENT_UPLOAD_FAILED && item.userId === user.id) {
          return true;
        }
        // document upload failed notification should not be visible to other users
        return item.userId !== user.id && item.type !== NOTIFICATION_EVENT_TYPE.REDIRECT_TO_DASHBOARD.DOCUMENT_UPLOAD_FAILED;
      }),
    ];
    return null;
  });
  const notificationsArrayFiltered = []
  notificationsArray.forEach((obj) => {
    if (ganttNotificationsFilter.includes(obj.type)) {
      const { skip, time } = filterGanttNotifications(obj.createdAt)
      if (skip) {
        return null
      }
      obj.createdAt = time
    }
    notificationsArrayFiltered.push(obj)
    return null
  })
  let compiledNotificationsArraySorted = sortObjectArrayByParam(
    notificationsArrayFiltered,
    'createdAt',
  );
  compiledNotificationsArraySorted = _.uniqBy(compiledNotificationsArraySorted, 'id')
  return compiledNotificationsArraySorted;
};

const demoCode = 'E12345'

export const projectCodesForOptOut = (user, projectCodes) => {
  const codes = []
  projectCodes.map((projectCode) => {
    let isIncluded = false
    const companyCode = projectCode.split('-')[0]
    for (let i = 0; i < user.groups.length; i += 1) {
      if (user.groups[i] === 'DemoData' && companyCode === demoCode) {
        isIncluded = true;
        break
      }
      if (user.groups[i] === 'AllProjects' && companyCode !== demoCode) {
        isIncluded = true;
        break
      }
      if (user.groups[i].slice(0, 2) === 'C:') {
        if (companyCode === user.groups[i].slice(2)) {
          isIncluded = true
          break
        }
      }
      if (user.groups[i].slice(0, 2) === 'P:') {
        if (projectCode === user.groups[i].slice(2)) {
          isIncluded = true
          break
        }
      }
    }
    if (!isIncluded) {
      codes.push(projectCode)
    }
    return null;
  })
  const remainingCodes = projectCodes.filter((x) => !codes.includes(x));
  return { projectCodes: remainingCodes, optOutCodes: codes }
}
