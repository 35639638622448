import xlsxPopulate from 'xlsx-populate';
import Papa from 'papaparse';
import iconv from 'iconv-lite';

/**
Reads the content of a File using FileReader.
@param {File} file - The File object from which to read the content.
@returns {Promise<ArrayBuffer>} - A promise that resolves to the content of the file.
*/
export const readFileContent = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.onload = (event) => {
    resolve(event.target.result);
  };
  reader.onerror = (error) => {
    reject(error);
  };
  reader.readAsArrayBuffer(file);
});

export const getCurrentFileLabel = (counter) => {
  switch (counter) {
    case 1:
      return 'PDX Clinical Information';
    case 2:
      return 'PDX Genomic Feature';
    case 3:
      return 'PDX HLA (RNAseq)';
    case 4:
      return 'PDX HLA (WES)';
    default:
      return '';
  }
};

/**
 Convert file content to a decoded string.

 @param {ArrayBuffer} fileContent - The content of the file.
 @param {string} fileExtension - The file extension (e.g., '.xlsx', '.xls', '.csv', '.list').
 @returns {Promise<string>} A promise that resolves to the decoded file content as a string.
 @throws {Error} Thrown if the file format is unsupported.
 */
export const convertFileContentToString = async (fileContent, fileExtension) => {
  let fileContentAsString;

  if (fileExtension === '.xlsx' || fileExtension === '.xls') {
    const workbook = await xlsxPopulate.fromDataAsync(fileContent);
    const sheetData = workbook.sheet(0).usedRange().value();
    const headers = sheetData[0];
    const formattedData = sheetData.slice(1).map((data) => headers.reduce((acc, cur, idx) => {
      acc[cur] = data[idx];
      return acc;
    }, {}));
    fileContentAsString = JSON.stringify(formattedData);
  } else if (fileExtension === '.csv' || fileExtension === '.list') {
    const fileContentString = new TextDecoder().decode(fileContent);
    const results = Papa.parse(fileContentString, { header: true });
    fileContentAsString = JSON.stringify(results.data);
  } else {
    throw new Error('Unsupported file format');
  }

  return iconv.decode(iconv.encode(fileContentAsString, 'utf-8', { strict: true }), 'utf-8');
};
