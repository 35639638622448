import React, { useMemo } from 'react';
import { NavDropdown } from 'react-bootstrap';
import { styled } from '@mui/styles';
import moment from 'moment';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { parseEventActionsForUser } from '../../utils';
import { BRAND_COLORS, NOTIFICATION_EVENT_TYPE, NOTIFICATION_MESSAGES } from '../../../constants';
import NotificationsIcon from './notifications.svg';
import NotificationsManageIcon from './IconManageNotification.svg';
import NotificationsAllReadIcon from './IconMarkAllRead.svg';
import NotificationsManageIconHover from './IconManageNotificationHover.svg';
import NotificationsAllReadIconHover from './IconMarkAllReadHover.svg';

const studyDirectorRestrictions = [NOTIFICATION_EVENT_TYPE.REDIRECT_TO_DASHBOARD.DATA_APPROVAL, NOTIFICATION_EVENT_TYPE.REDIRECT_TO_DASHBOARD.ACTION_REQUIRED];
const commentsRestrictions = [NOTIFICATION_EVENT_TYPE.REDIRECT_TO_DASHBOARD.NEW_COMMENT];

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip
    disableInteractive
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    PopperProps={{
      popperOptions: {
        modifiers: [
          {
            name: 'offset',
            options: { offset: [-50, -25] },
          },
        ],
      },
    }}
    classes={{
      popper: className,
    }}
  />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#FFFBEC',
    color: '#222222',
    border: '1px solid #FEE7D5',
    borderRadius: '2px',
    fontSize: '12px',
    top: '20px',
  },
}));

const NavbarDropdown = function NavbarDropdown({
  onClickManageNotifications, showNotificationForStudyDirector, showInternalComments, title, showCompanyName, items, eventActions, customRender, noItemText = 'No notifications to show', idValue = '', show, onClick = () => { }, onClickItem = () => { }, onClickMarkAllAsRead = () => { }, auditNotificationInteraction = () => { },
}) {

  const { notifications, unreadNotifications } = useMemo(() => {
    moment.relativeTimeThreshold('d', 7);
    moment.relativeTimeThreshold('w', 4);
    // format event actions here
    const parsedEventActions = parseEventActionsForUser(eventActions)
    const latestMarkedAllRead = parsedEventActions.MARK_ALL_READ ? parsedEventActions.MARK_ALL_READ.createdAt : 0;
    if (items && items.length) {
      let unreadNotifications = 0
      const notifications = items.map(({
        id, createdAt, projectCode, type, companyName, createdBy, attributes,
      }) => {
        const renderNotificationText = NOTIFICATION_MESSAGES[type];
        if (!renderNotificationText) {
          return null
        }
        if (studyDirectorRestrictions.includes(type) && !showNotificationForStudyDirector) {
          return null
        }
        if (commentsRestrictions.includes(type) && !showInternalComments && attributes.length && attributes[0].value === 'true') {
          return null;
        }
        const isRead = parsedEventActions[id] ? true : (latestMarkedAllRead > createdAt)
        unreadNotifications += isRead ? 0 : 1
        return (
          <NavDropdown.Item
            data-testid={`dropdown-item-${id}`}
            key={id}
            className="notifications-eachItem"
            onClick={() => {
              auditNotificationInteraction(projectCode, companyName, id)
              onClickItem(projectCode, id, type, isRead)
            }}
          >
            <div style={{
              lineHeight: 1.5,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignContent: 'center',
              width: '400px',
            }}
            >
              {isRead
                ? (
                  <UploadFileIcon
                    style={{
                      color: '#888888',
                      width: '16px',
                      height: '16px',
                      margin: '0px 4px 0px 0px',
                    }}
                  />
                )
                : (
                  <UploadFileIcon
                    style={{
                      color: BRAND_COLORS.orange,
                      width: '16px',
                      height: '16px',
                      margin: '0px 4px 0px 0px',
                    }}
                  />
                )}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  marginLeft: '5px',
                }}
              >
                <div
                  data-testid={`dropdown-item-${id}-content`}
                  style={{
                    fontSize: 14,
                    whiteSpace: 'initial',
                    color: isRead ? '#888888' : '#222222',
                  }}
                >
                  <p
                    data-testid={`dropdown-item-${id}-project-code`}
                    className="notifications-eachItem-topLine AvenirNextLTProMedium"
                  >
                    {projectCode} {showCompanyName && (companyName || '')}
                  </p>

                  {renderNotificationText({ createdBy, attributes })}
                </div>
                <div
                  data-testid={`dropdown-item-${id}-creationTime`}
                  className="notification-eachItem-timeString AvenirNextLTProRegular"
                  style={{ color: isRead ? '#666666' : '#666666' }}
                >
                  {moment(createdAt * 1000).fromNow()}
                </div>
              </div>
            </div>
          </NavDropdown.Item>
        )
      })
      return { notifications, unreadNotifications }
    }
    return { notifications: <NavDropdown.Item data-testid="dropdown-no-item" style={{ width: '400px', textAlign: 'center' }}>{noItemText}</NavDropdown.Item>, unreadNotifications: 0 }
  }, [items, noItemText, eventActions, onClickItem, showCompanyName, auditNotificationInteraction, showNotificationForStudyDirector, showInternalComments])

  // const titleForNotifications = <div><Bell style={{ fontSize: 24, color: "rgba(0,0,0,0.9)" }} /> <div data-testid="unread-notification-count" style={{ position: "absolute", color: "rgba(0,0,0,0.9)", background: "rgba(245, 215, 110, 1)", top: "-8px", right: "4px", minWidth: "1.5em", minHeight: "1.5em", textAlign: "center", borderRadius: "50%", fontSize: 10, padding: "2px" }}>{unreadNotifications > 10 ? "10+" : unreadNotifications}</div> </div>
  const titleForNotifications = (
    <div className="landing-navHeader-bellContainer" style={{ fontWeight: 'bold' }}>
      <img
        src={NotificationsIcon}
        alt="notifications icon on the header bar"
        className="landing-navHeader-bellIcon"
      />
      {/* <div
        className="notifications-icon-overlay-counter"
        data-testid="unread-notification-count"
      >
        {unreadNotifications > 9 ? '9+' : unreadNotifications}
      </div> */}

      {/* added separate CSS classes for notifications counts as they go beyond taking up a single digit, character */}
      {unreadNotifications > 9
        // eslint-disable-next-line react/jsx-wrap-multilines
        ? (
          <div
            className="notifications-icon-overlay-counterLarge"
            data-testid="unread-notification-count"
          >
            9+
          </div>
        )
        : (
          <div
            className="notifications-icon-overlay-counter"
            data-testid="unread-notification-count"
          >
            {unreadNotifications}
          </div>
        )}
    </div>
  )

  return (
    <NavDropdown
      data-testid="navbar-dropdown"
      onClick={onClick}
      show={show}
      id={idValue}
      title={title || titleForNotifications}
      alignRight
    >
      <NavDropdown.Header
        className="navHeader-notifications-dropDownHeader"
      >
        <span
          className="landing-navHeader-title"
        >
          Notifications
        </span>

        <LightTooltip
          className="navHeader-notifications-toolTips AvenirNextLTProMedium"
          title={(
            <>
              <Typography className="AvenirNextLTProRegular">
                Mark All Read
              </Typography>
            </>
                      )}
          placement="top-start"
        >
          <div
            data-testid="navbar-dropdown-mark-all-read"
            onClick={onClickMarkAllAsRead}
            className="landing-navHeader-markAllReadIcon"
            role="button"
            tabIndex={0}
            aria-hidden="true"
          >
            <img
              src={NotificationsAllReadIcon}
              alt="mark all read notifications icon on the header title bar"
            />
            <img
              className="landing-navHeader-markAllReadIconHover"
              src={NotificationsAllReadIconHover}
              alt="mark all read notifications icon on the header title bar"
            />
          </div>
        </LightTooltip>

        <LightTooltip
          className="navHeader-notifications-toolTips AvenirNextLTProMedium"
          title={(
            <>
              <Typography className="AvenirNextLTProRegular">
                Manage Notifications
              </Typography>
            </>
                      )}
          placement="top-start"
        >
          <div
            onClick={onClickManageNotifications}
            className="landing-navHeader-manageNotificationsIcon"
            role="button"
            tabIndex={0}
            aria-hidden="true"
          >
            <img
              src={NotificationsManageIcon}
              alt="manage notifications icon on the header title bar"
            />
            <img
              className="landing-navHeader-manageNotificationsIconHover"
              src={NotificationsManageIconHover}
              alt="mark all read notifications icon on the header title bar"
            />
          </div>
        </LightTooltip>

      </NavDropdown.Header>
      <div
        className="notifications-body"
      >
        {customRender ? customRender() : notifications}
      </div>
    </NavDropdown>
  )
}

export default NavbarDropdown
