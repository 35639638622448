import React, { useCallback, useMemo, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Button } from 'react-bootstrap';
import { TextField } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import isValidPassword from './utils';
import './auth.css';

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    fontFamily: 'AvenirNextLTPro-Regular',
    width: '400px',
    '&.Mui-focused': {
      backgroundColor: '#fff',
      fontFamily: 'AvenirNextLTPro-Regular',
    },
  },
  focused: {},
  notchedOutline: {},
}));
const ResetPassword = function ResetPassword({
  handleResetPassword, cancel, error, loading,
}) {
  const classes = useStyles();
  const [passwords, setPasswords] = useState({
    oldPassword: '',
    newPassword: '',
  });

  const handleChange = useCallback((e) => {
    const { target: { name, value } } = e;
    setPasswords({ ...passwords, [name]: value });
  }, [passwords, setPasswords]);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    handleResetPassword({ ...passwords });
  }, [passwords, handleResetPassword]);

  const isSubmitDisabled = useMemo(() => {
    const { oldPassword, newPassword } = passwords;
    if (!oldPassword.length || !newPassword.length) {
      return true;
    }
    if (!isValidPassword(newPassword)) {
      return true;
    }
    return false;
  }, [passwords]);

  return (
    <div>
      <form className="form" onSubmit={handleSubmit} noValidate>
        <div className="inputField">
          <InputLabel className="inputLabel" htmlFor="oldPassword">
            Old Password
          </InputLabel>
          <TextField
            name="oldPassword"
            variant="outlined"
            placeholder="Enter old password"
            type="password"
            value={passwords.oldPassword}
            id="oldPassword"
            autoFocus
            InputProps={{
              classes: {
                root: classes.root,
                focused: classes.focused,
              },
            }}
            sx={{
              width: '100%',
            }}
            onChange={handleChange}
          />
        </div>
        <div className="inputField">
          <InputLabel className="inputLabel" htmlFor="newPassword">
            New Password
          </InputLabel>
          <TextField
            name="newPassword"
            variant="outlined"
            placeholder="Enter new password"
            type="password"
            value={passwords.newPassword}
            id="newPassword"
            InputProps={{
              classes: {
                root: classes.root,
                focused: classes.focused,
              },
            }}
            sx={{
              width: '100%',
            }}
            onChange={handleChange}
          />
        </div>

        <p
          style={{
            color: '#E2AAA0',
            display: isSubmitDisabled ? 'block' : 'none',
          }}
        >
          Password must have at least 15 characters, 1 lowercase character, 1 uppercase character, 1 number, 1 special character
        </p>
        <p
          style={{
            color: '#E2AAA0',
            display: error ? 'block' : 'none',
          }}
        >
          {error}
        </p>
        <Button
          className="LoginFormSignInButton"
          type="submit"
          variant="primary"
          disabled={isSubmitDisabled || loading}
        >
          { loading ? 'Loading...' : 'Reset Password' }
        </Button>

        <Button
          className="LoginFormOutlineButton"
          type="button"
          variant="light"
          onClick={() => cancel()}
        >
          Sign Out
        </Button>

      </form>
    </div>
  )
}
export default ResetPassword;
