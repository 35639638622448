import React from 'react';

export const Radio = React.memo(({ item, checked, onChange }) => (
  <span className="form-check form-check-inline">
    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
    <label className={`form-check-label label-${item.id}`}>
      <input
        id={`radio-${item.id}`}
        type="radio"
        value={item.id}
        checked={checked}
        onChange={onChange}
        className="form-check-input"
      />
      {/* <label className={"form-check-label"}>{item.label}</label> */}
      {/* Code added by mphasis start.
      Commented on 1 dec 2021  */}
      <span data-testid={`radio-list-item-${item.label}`}>{item.label}</span>
      <span className={`switch-selection switch-${item.id}`} />
    </label>
    {/* Code added by mphasis end. */}
  </span>
));
const RadioList = function RadioList({ items, auditCallback = () => {} }) {
  const [value, setValue] = React.useState(items[0].id);
  const onChange = React.useCallback((e) => {
    setValue(e.target.value)
    auditCallback(e.target.value)
  }, [auditCallback]);

  return (
    <div className="mt-4">
      <div className="button-wrapper"> {/* // mphasis code  */}
        {items.map((item) => (
          <Radio
            item={item}
            key={item.id}
            checked={item.id === value}
            onChange={onChange}
          />
        ))}
      </div> {/* // mphasis code  */}

      {items.map((item) => item.id === value && <div key={item.id}>{item.content}</div>)}
    </div>
  );
};
export default RadioList;
