import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Nav, Card } from 'react-bootstrap';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import './ModelTab.css';
import Typography from '@mui/material/Typography';
import ExportDownload from './Export';
import IconModelSummaryV1 from './IconModelSummaryV1.svg'
import styles from './StudySummary/StudySummary.module.scss';
import { LightTooltip } from '../../utils';

const ModelTab = function ModelTab({
  study,
  setModel,
  modelTabsDisabled,
  disableModelLink,
  dataExportAudit = () => {},
}) {
  const [exportedFiles, setExportedFiles] = useState([]);
  const [numberOfFiles, setNumberOfFiles] = useState(0);

  useEffect(() => {
    if ((numberOfFiles && numberOfFiles === exportedFiles?.length) || (!numberOfFiles && exportedFiles?.length === 1)) {
      handleDownload(exportedFiles, numberOfFiles, study)
    }
  }, [exportedFiles, numberOfFiles, study])

  const handleDownload = async (files, numberOfFilesForDownload, currentStudy) => {
    /*
    Removes `--${uuid}` from all the file name for all files.
    @param {Array<Array>} filesToBeUpdated - The input array of file data where each element is an array [blob, filename].
    @returns {Array<Array>} - The transformed array without --${uuid}`.
    */
    const removeUUIDFromArray = (filesToBeUpdated) => {
      const uuidRegex = /[a-f\d]{8}-[a-f\d]{4}-[a-f\d]{4}-[a-f\d]{4}-[a-f\d]{12}/i;
      return filesToBeUpdated.map(([_, fileName]) => {
        const match = fileName.match(uuidRegex);
        if (match) {
          const uuid = match[0];
          const withoutUUID = fileName.replace(`--${uuid}`, '').trim();
          return [_, withoutUUID.trim()];
        }
        return [_, fileName];
      });
    }
    const filesToDownload = removeUUIDFromArray(files);

    try {
      if (filesToDownload?.length === 1 && !numberOfFilesForDownload) {
        saveAs(
          filesToDownload[0][0],
          filesToDownload[0][1],
        );
      } else if (filesToDownload?.length && numberOfFilesForDownload) {
        /**
        * Appends numbers to duplicate filenames and transforms the fileArray.
        *
        * @param {Array<Array>} fileArray - The input array of file data where each element is an array [blob, filename].
        * @returns {Array<Array>} - The transformed array with appended numbers for duplicate filenames.
        */
        const appendNumberToDuplicates = (fileArray) => {
          // Group files by filename
          const duplicates = fileArray.reduce((acc, file) => {
            if (acc[file[1]]) {
              acc[file[1]].push(file[0]);
            } else {
              acc[file[1]] = [file[0]];
            }

            return acc;
          }, {});

          // Create a new array with appended numbers for duplicate filenames
          const resultArray = Object.entries(duplicates).map((duplicate) => {
            const result = [];
            if (duplicate[1]?.length > 1) {
              duplicate[1].forEach((blob, index) => {
                const [name, extension] = duplicate[0].split('.');
                const suffix = `--${index + 1}`;
                const newFileName = `${name}${suffix}.${extension}`;
                result.push([blob, newFileName]);
              });
            } else {
              duplicate[1].forEach((blob) => {
                result.push([blob, `${duplicate[0]}`]);
              });
            }

            return result;
          });

          // Flatten the array of arrays and return the result
          return Object.values(resultArray).flat();
        }
        const zip = new JSZip();
        appendNumberToDuplicates(filesToDownload).forEach(([blob, fileName]) => {
          zip.file(fileName, blob, { binary: true });
        });
        const zipBlob = await zip.generateAsync({ type: 'blob' });
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');

        const formattedDate = `${year}${month}${day}`;
        saveAs(zipBlob, `${currentStudy?.project_code}_In Vivo Data_${formattedDate}.zip`);
      }
    } catch (e) {
      //
    } finally {
      setExportedFiles([]);
      setNumberOfFiles(0);
    }
  };

  const onNavClick = (index, model) => () => {
    if (!modelTabsDisabled && !disableModelLink(index, model)) {
      setModel(model)
    }
  };

  return (
    <div className={styles.studySummaryContainer}>
      <Card.Header className={styles.cardHeader}>
        <div className={styles.cardHeaderIconTitle}>
          <Typography className={styles.cardHeaderTitle}>
            <img src={IconModelSummaryV1} alt="Study Summary Icon" />
            Model Summary
          </Typography>
        </div>
      </Card.Header>
      <Card.Body className={styles.cardBody}>
        <div className="tab-bord">
          <div className="model headerBackground">
            <div data-testid="model-summary-col-model" className="modelPill">Model</div>
            <div data-testid="model-summary-col-study-type" className="modelPill">Study Type</div>
            <div data-testid="model-summary-col-site" className="modelPill">Site</div>
            <div data-testid="model-summary-col-status" className="modelPill">Status</div>
            {study.models.length <= 0 ? (
              <div data-testid="model-summary-col-status" className="modelPill">
                Raw Data
              </div>
            ) : (
              <div className="modelPill last-col downloadAll">
                <Nav.Item
                  onClick={() => {
                    dataExportAudit();
                    setExportedFiles([]);
                    setNumberOfFiles(0);
                    study.models.map((model) => {
                      if (model?.director?.state === 'RELEASED') {
                        setNumberOfFiles((prev) => prev + 1);
                      }
                      document
                        .getElementById(`export-${model.study_model}-${model.study_id}`)
                        .children[0].children[0].click()
                      return model;
                    });
                  }}
                >
                  <Typography
                    data-testid="model-summary-col-raw-data"
                    className="studyDetails-modelTab-dlColHeader-tab"
                  >
                    Raw Data
                  </Typography>
                  <Typography
                    data-testid="model-summary-col-download-all"
                    className="studyDetails-modelTab-dlColHeader"
                  >
                    Download All
                  </Typography>
                </Nav.Item>
              </div>
            )}

          </div>
          {study.models.length <= 0 ? (
            <div className={styles.noDataText}>
              No in vivo models are available at this time.
            </div>
          ) : (
            <Nav data-testid="NavItemTest" className="model content-scroll" variant="pills">
              {study.models.map((model, index) => (
                <div
                  className="modelRow"
                  data-testid="model-summary-table-row"
                // eslint-disable-next-line react/no-array-index-key
                  key={`nav-item-${index}`}
                >
                  {disableModelLink(index, model)
                    ? (
                      <LightTooltip
                        title={(
                          <>
                            <Typography className="AvenirNextLTProRegular disabled-tooltip">
                              No study data available
                            </Typography>
                          </>
                      )}
                        placement="bottom"
                      >
                        <Nav.Item
                          className="studyTypeHeader disable-nav-item"
                          onClick={onNavClick(index, model)}
                        >
                          <Nav.Link className="badge nav-link study-data-pill" eventKey={`tab-${index}`} disabled={modelTabsDisabled || disableModelLink(index, model)}>
                            {model.study_model}
                          </Nav.Link>
                        </Nav.Item>
                      </LightTooltip>
                    ) : (
                      <Nav.Item
                        className="studyTypeHeader"
                        onClick={onNavClick(index, model)}
                      >
                        <Nav.Link className="badge nav-link study-data-pill" eventKey={`tab-${index}`} disabled={modelTabsDisabled || disableModelLink(index, model)}>
                          {model.study_model}
                        </Nav.Link>
                      </Nav.Item>
                    )}
                  <LightTooltip
                    title={(
                      <>
                        <Typography className="AvenirNextLTProRegular">
                          {model.study_type ? model.study_type : 'N/A'}
                        </Typography>
                      </>
                              )}
                    placement="right"
                    offVt={24}
                    offHz={-60}
                  >
                    <div className="studyType truncate">{model.study_type ? model.study_type : 'N/A'}</div>
                  </LightTooltip>

                  <LightTooltip
                    title={(
                      <>
                        <Typography className="AvenirNextLTProRegular">
                          {model.study_site ? model.study_site : 'N/A'}
                        </Typography>
                      </>
                              )}
                    placement="right"
                    offVt={24}
                    offHz={-60}
                  >
                    <div className="studyType truncate">{model.study_site ? model.study_site : 'N/A'}</div>
                  </LightTooltip>

                  <LightTooltip
                    title={(
                      <>
                        <Typography className="AvenirNextLTProRegular">
                          {model.study_status ? model.study_status : 'N/A'}
                        </Typography>
                      </>
                              )}
                    placement="right"
                    offVt={26}
                    offHz={-40}
                  >
                    <div className="studyType truncate">{model.study_status ? model.study_status : 'N/A'}</div>
                  </LightTooltip>
                  <LightTooltip
                    title={(
                      <>
                        <Typography className="AvenirNextLTProRegular">
                          Download
                        </Typography>
                      </>
                              )}
                    placement="right"
                    offVt={24}
                    offHz={-40}
                  >
                    <div className="studyType last-col model-tab-download-col" id={`export-${model.study_model}-${model.study_id}`}> {/* added custom class for styling purposes */}
                      <ExportDownload
                        updateExportedFiles={(file) => {
                          setExportedFiles((prev) => {
                            if (!prev.some((existingFile) => file?.[1] && existingFile?.[1] === file?.[1])) {
                              return [...prev, file];
                            }
                            return prev;
                          });
                        }}
                        study={study}
                        model={model}
                        dataExportAudit={dataExportAudit}
                      />
                    </div>
                  </LightTooltip>
                </div>
              ))}
            </Nav>
          )}
        </div>
      </Card.Body>
    </div>
  )
}

// Prop validations
ModelTab.propTypes = {
  study: PropTypes.shape({
    models: PropTypes.arrayOf(
      PropTypes.shape({
        study_model: PropTypes.string.isRequired,
        study_type: PropTypes.string,
        study_site: PropTypes.string,
        study_status: PropTypes.string,
        // Add other properties as needed
      }),
    ),
  }),
  setModel: PropTypes.func.isRequired,
  modelTabsDisabled: PropTypes.bool.isRequired,
  disableModelLink: PropTypes.func.isRequired,
  dataExportAudit: PropTypes.func,
};
// Default props
ModelTab.defaultProps = {
  study: {
    models: [],
    // Add any other default properties if needed
  },
  dataExportAudit: undefined,
};

export default ModelTab;
