import { Auth } from 'aws-amplify';
import React, {
  createContext, useEffect, useRef, useState, useCallback,
} from 'react';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';
import { AuthState } from '@aws-amplify/ui-components';
import { Fade } from 'react-bootstrap';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { v4 as uuidv4 } from 'uuid';
import { createMessageForSQS } from '../resources/utils.createMessage';
import {
  INTERNAL_DOMAINS_FOR_USER_BEHAVIOR_ANALYTICS,
  AUDIT_TRAIL_EVENT_TYPES,
  DATA_COLLECTION_EVENT_TYPES,
  GROUPS,
} from '../constants';
import { useSQS } from './sqs';
import { useGraphQL } from './graphql';
import { useAnalytics } from './analytics/analytics-context';

const style = {
  position: 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 464,
  bgcolor: '#002334',
  border: '1px solid #002334',
  borderRadius: '0px 24px',
  p: 4,
};

/**
 * @typedef {{
 *   authState: AuthState,
 *   setAuthState: (state: any) => void,
 *   user: {},
 *   setUserInfo: (user: any) => void,
 *   doLogin: ,(user: any) => void
 *   signOut: () => Promise<void>,
 *   hasGroup: (groups: any) => any,
 *   changeUserPassword: (oldPassword: any, newPassword: any) => Promise<any>,
 *   setLoginRedirect: React.Dispatch<React.SetStateAction<string>>,
 *   loginRedirect: string,
 *   passwordExpiredFlow: boolean,
 *   setPasswordExpiredFlow: React.Dispatch<React.SetStateAction<boolean>>,
 *   isPasswordExpired: ({ userId }: {
 *       userId: any;
 *     }) => Promise<boolean>,
 *   getSystemMessagesNotifications: boolean,
 *   setGetSystemMessagesNotifications: React.Dispatch<React.SetStateAction<boolean>>,
 *   doTask: (doFunc: () => Promise<void>) => Promise<void>,
 * }} AuthContextValue
 *
 * @typedef {{
 *   active: boolean,
 *   tasks: string[],
 *   lastEndActiveEpoch: number;
 * }} LSActivity
 *
 * @typedef {LSActivity & { inactiveTimeout: boolean }} Activity
 */

/**
 * Items with these keys will persist upon user sign out.
 * While not defined here, the user's username is also a persistable key.
 * */
const PERSISTABLE_LOCAL_STORAGE_KEYS = [
  // The following three patterns represent the string `CognitoIdentityServiceProvider.<userPoolClientId>.<userPoolUserId>.[randomPasswordKey|deviceGroupKey|deviceKey]`
  /^CognitoIdentityServiceProvider\.[a-zA-Z0-9]+\.[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}\.randomPasswordKey$/,
  /^CognitoIdentityServiceProvider\.[a-zA-Z0-9]+\.[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}\.deviceGroupKey$/,
  /^CognitoIdentityServiceProvider\.[a-zA-Z0-9]+\.[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}\.deviceKey$/,
];
/** Items with these keys will persist upon user sign out */
const PERSISTABLE_SESSION_STORAGE_KEYS = [];

/**
 * @param {{ additionalPersistableLocalStorageKeys: (RegExp | string)[]; additionalPersistableSessionStorageKeys: (RegExp | string)[]; }} additionalKeys Additional keys to persist upon user sign out
 * @returns a promise that resolves when all non-persistable keys are removed from local and session storage
 */
export const removeNonPersistableKeys = ({
  additionalPersistableLocalStorageKeys = [],
  additionalPersistableSessionStorageKeys = [],
} = {}) => {
  /**
   * @param {RegExp | string} pattern The pattern to test against
   * @param {string} k The key to test
   */
  const testKey = (pattern, k) => (typeof pattern === 'string' ? pattern === k : pattern.test(k));

  const lsPatterns = PERSISTABLE_LOCAL_STORAGE_KEYS.concat(additionalPersistableLocalStorageKeys);
  const ssPatterns = PERSISTABLE_SESSION_STORAGE_KEYS.concat(additionalPersistableSessionStorageKeys);

  /**
   * @type {[RegExp | string, Storage][]}
   */
  const ops = [
    [lsPatterns, localStorage],
    [ssPatterns, sessionStorage],
  ];

  const promises = ops.map(([patterns, storage]) => Promise.resolve(
    Object.keys(storage).forEach((k) => {
      if (!patterns.some((pattern) => Boolean(pattern) && testKey(pattern, k))) {
        storage.removeItem(k);
      }
    }),
  ));

  return Promise.allSettled(promises);
};

export const isValidNumber = (value) => typeof value === 'number'
  && !Number.isNaN(value)
  && Number.isFinite(value);

export const isPlainObject = (obj) => {
  if (typeof obj !== 'object' || obj === null) return false;
  return obj.constructor === Object && Object.prototype.toString.call(obj) === '[object Object]';
}

export const getEpochSeconds = () => new Date().getTime() / 1000;

export const expireLSKey = 'cognitoTokenExpireTimes';
// How long before a token expires to refresh it
const refreshThresholdSeconds = 5 * 60; // 5 minutes

export const activityLSKey = 'lastActivity';
/** How long to consider a user inactive */
const inactivityTimeoutSeconds = 1 * 60 * 60; // 1 hour
const internalInactivityTimeoutSeconds = 12 * 60 * 60; // 12 hours
/** How often to check for inactivity */
const timeoutCheckFidelitySeconds = 5; // 5 seconds
/** How long before `inactivityTimeoutSeconds` to show the warning modal
This value should be less than `inactivityTimeoutSeconds` */
const modalInactivityTimeoutSeconds = 2 * 60; // 2 minutes

/**
 * @returns {Record<string, number> | null}
 */
export const getLocalTokenExpireTimes = () => {
  try {
    const tokenExpireTimes = localStorage.getItem(expireLSKey);
    if (!tokenExpireTimes) {
      return null;
    }
    const parsed = JSON.parse(tokenExpireTimes);
    if (parsed && Object.values(parsed).every(isValidNumber)) {
      return parsed;
    }
  } catch (e) {
    // JSON.parse failed. Remove it because it's malformed.
    localStorage.removeItem(expireLSKey);
  }
  return null;
}

/**
 * @returns {Promise<number>} The epoch seconds of the next token expire event.
 */
export const updateLocalTokenExpireTimes = async () => {
  const session = await Auth.currentSession();
  const accessTokenExpires = session.getAccessToken().getExpiration();
  const idTokenExpires = session.getIdToken().getExpiration();
  const expires = {
    accessTokenExpires,
    idTokenExpires,
  };
  localStorage.setItem(expireLSKey, JSON.stringify(expires));

  return Object.values(expires).reduce((min, expire) => Math.min(min, expire), Number.MAX_SAFE_INTEGER);
}

/**
 * @param {string} [activityJSONString=null] The JSON string of the activity object to parse. If undefined, the activity is retrieved from localStorage.
 * @returns {LSActivity | null}
 */
export const getLocalActivity = (activityJSONString = null) => {
  try {
    const activity = activityJSONString !== null ? activityJSONString : localStorage.getItem(activityLSKey);
    if (!activity) {
      return null;
    }
    const parsed = JSON.parse(activity);
    if (parsed && isPlainObject(parsed)) {
      return parsed;
    }
  } catch (e) {
    if (activityJSONString === null) {
      // JSON.parse failed. Remove it because it's malformed.
      localStorage.removeItem(activityLSKey);
    }
  }
  return null;
}

/**
 * @param {AuthState} authState
 * @param {() => void} signOut
 * @returns {Activity | null}
 */
export const checkLocalActivity = (authState, signOut, offset = inactivityTimeoutSeconds) => {
  let toSet = null;
  const nowEpochSeconds = getEpochSeconds();
  const currentActivity = getLocalActivity();
  if (currentActivity && authState === AuthState.SignedIn) {
    if (!currentActivity.active && nowEpochSeconds - currentActivity.lastEndActiveEpoch > offset) {
      // The user has been inactive for too long and should be signed out.
      signOut();
      return null;
    }
    toSet = {
      ...currentActivity,
      inactiveTimeout:
        // The user has been inactive for too long and the warning modal should be shown
        nowEpochSeconds - currentActivity.lastEndActiveEpoch > offset - modalInactivityTimeoutSeconds,
    };
  }

  return toSet;
};

/**
 * @param {boolean} [active=false] Whether the user is currently active.
 * @param {string} [taskId=undefined] The ID of the task being performed.
 * @returns {LSActivity} The LSActivity written to localStorage
 */
export const updateLocalActivity = (active = false, taskId = undefined) => {
  const currentActivity = getLocalActivity();

  let tasks = [];
  if (currentActivity?.tasks) {
    tasks = [...currentActivity.tasks];
  }
  if (taskId) {
    const taskIndex = tasks.indexOf(taskId);
    if (active && taskIndex === -1) {
      // Add the task
      tasks.push(taskId);
    } else if (!active && taskIndex !== -1) {
      // Remove the task
      tasks.splice(taskIndex, 1);
    }
  }

  /** @type {LSActivity} */
  const activity = {
    active,
    tasks,
    lastEndActiveEpoch: currentActivity && active ? currentActivity.lastEndActiveEpoch : Math.trunc(getEpochSeconds()),
  };
  localStorage.setItem(activityLSKey, JSON.stringify(activity));

  return activity;
}

export const formatSecondsToMMSS = (seconds) => {
  const truncSeconds = Math.trunc(seconds);
  const minutes = Math.floor(truncSeconds / 60);
  const remainingSeconds = truncSeconds % 60;
  return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
}

/** @type {React.Context<AuthContextValue>} */
export const AuthContext = createContext();

export const AuthProvider = function AuthProvider({ children }) {
  const [authState, _setAuthState] = useState(AuthState.SignIn);
  const authStateRef = useRef(authState)
  const activityChecked = useRef(false);
  const lsActivity = useRef(null);
  /** @type {React.MutableRefObject<Activity | null>} */
  const activityRef = useRef(lsActivity.current);
  /** @type {[number | null, React.Dispatch<React.SetStateAction<number | null>>]} */
  const [inactiveTime, setInactiveTime] = useState(null);
  const [timeoutSecondsRemaining, setTimeoutSecondsRemaining] = useState(formatSecondsToMMSS(0));
  const [user, setUser] = useState({});
  /** @type {React.MutableRefObject<NodeJS.Timer | null>} */
  const refreshTimerId = useRef(null);
  const [passwordExpiredFlow, setPasswordExpiredFlow] = useState(false);
  const { sendMessageToQueueForAudits, sendMessageToQueueForAnalytics } = useSQS()
  const [loginRedirect, setLoginRedirect] = useState('')
  const history = useHistory()
  const location = useLocation()
  const [userPreferences, setUserPreferences] = useState();
  const { updateUserPreferences, loadOrCreateUserPreferences } = useGraphQL();
  // getSystemMessagesNotifications: whether to do a fetch call for system messages or not.
  const [getSystemMessagesNotifications, setGetSystemMessagesNotifications] = useState(true)
  const analytics = useAnalytics();

  // takes single string group or array of groups, array of groups checks if user is in at least one of the groups
  const hasGroup = useCallback((groups) => {
    let result = false;
    if (groups.map) {
      /* eslint-disable-next-line no-restricted-syntax */
      for (const role in groups) {
        if (user && user.groups && user.groups.includes(groups[role])) {
          result = true;
          break;
        }
      }
    } else {
      return user && user.groups && user.groups.includes(groups)
    }
    return result;
  }, [user])

  const getTimeoutOffset = useCallback(
    () => (
      Object.keys(user).length !== 0
      && !(
        hasGroup([GROUPS.COMPANY_PROJECTS, GROUPS.EXTERNAL])
        || hasGroup([GROUPS.INDIVIDUAL_PROJECTS, GROUPS.EXTERNAL])
      )
        ? internalInactivityTimeoutSeconds
        : inactivityTimeoutSeconds),
    [hasGroup, user],
  );

  /**
   * @param {boolean} [active=false] Whether the user is currently active.
   * @param {string} [taskId=undefined] The ID of the task being performed.
   * @returns {number | null} The epoch seconds of the time to the next inactivy modal event.
   */
  const updateActivity = useCallback((active = false, taskId = undefined) => {
    const activity = updateLocalActivity(active, taskId)
    lsActivity.current = activity;
    return !active ? getTimeoutOffset() : null;
  }, [getTimeoutOffset]);

  // these keep ref aligned with state, refs are required to access this value within a window listener (for capturing user activity)
  const setAuthState = (state) => {
    _setAuthState(state);
    authStateRef.current = state;
  }

  const signOut = useCallback(async () => {
    localStorage.removeItem(expireLSKey);
    clearTimeout(refreshTimerId.current);
    refreshTimerId.current = null;

    localStorage.removeItem(activityLSKey);
    lsActivity.current = null;
    activityRef.current = null;
    setInactiveTime(null);
    setTimeoutSecondsRemaining(0);

    const [, , inUser] = await Promise.allSettled([
      createMessageForSQS({
        eventType: AUDIT_TRAIL_EVENT_TYPES.USER_LOGOUT,
        userId: user.id,
      }).then((messageBody) => sendMessageToQueueForAudits(messageBody)),
      createMessageForSQS({
        eventType: DATA_COLLECTION_EVENT_TYPES.USER_LOGOUT,
        userId: user.username,
      }).then((messageForSqs) => sendMessageToQueueForAnalytics(messageForSqs)),
      Auth.currentAuthenticatedUser(),
    ]);

    Auth.signOut()
      .then(() => removeNonPersistableKeys({
        additionalPersistableLocalStorageKeys:
          inUser.status === 'fulfilled' ? [inUser?.attributes.email] : [],
      }))
      .then(() => {
        setUser({});
        setAuthState(AuthState.SignIn);
        setGetSystemMessagesNotifications(true);
        history.push('/login');
      })
      .catch(() => {});
  }, [history, sendMessageToQueueForAnalytics, sendMessageToQueueForAudits, user.id, user.username]);

  const setInactive = useCallback((/** @type {boolean} */ inactive) => {
    const currEpochSeconds = getEpochSeconds();
    const remainingSeconds = modalInactivityTimeoutSeconds - (currEpochSeconds - (lsActivity.current.lastEndActiveEpoch + getTimeoutOffset()))
    if (inactive && remainingSeconds <= 0) {
      signOut();
      return;
    }
    setInactiveTime(inactive ? currEpochSeconds : null);
    setTimeoutSecondsRemaining(formatSecondsToMMSS(inactive ? remainingSeconds : 0));
  }, [getTimeoutOffset, signOut]);

  /* eslint-disable-next-line no-shadow */
  const setUserInfo = (user) => {
    setUser({
      id: user.username,
      name: user.name,
      email: user.attributes.email,
      groups:
        user.signInUserSession.accessToken.payload['cognito:groups'] || [],
      studies: [],
    });
  }

  /* eslint-disable-next-line no-shadow */
  const doLogin = (user) => {
    createMessageForSQS({ eventType: AUDIT_TRAIL_EVENT_TYPES.USER_LOGIN, userId: user.username }).then((messageBody) => {
      sendMessageToQueueForAudits(messageBody);
    });
    setUserInfo(user)
    setAuthState(AuthState.SignedIn)
    updateLocalTokenExpireTimes().then((nextExpireTime) => {
      const nowEpochSeconds = getEpochSeconds();
      if (refreshTimerId.current) {
        clearTimeout(refreshTimerId.current);
      }
      refreshTimerId.current = setTimeout(() => {
        doRefresh();
      }, (nextExpireTime - refreshThresholdSeconds - nowEpochSeconds) * 1000);
    });
    if (activityChecked.current) {
      updateActivity();
    }

    // For User Behavior tracking analytics
    if (analytics && user?.attributes?.email) {
      if (INTERNAL_DOMAINS_FOR_USER_BEHAVIOR_ANALYTICS.includes(user.attributes.email.split('@')[1])) {
        analytics.identify({
          source: 'Internal',
        });
      } else {
        analytics.identify({
          source: 'External',
        });
      }
    }
  }

  const changeUserPassword = async (oldPassword, newPassword) => {
    const cogUser = await Auth.currentAuthenticatedUser();
    return new Promise((resolve, reject) => {
      Auth.changePassword(cogUser, oldPassword, newPassword)
        .then((response) => {
          updateUserPreferences({
            ...userPreferences,
            lastUpdatedPasswordAt: moment().unix(),
          });
          resolve(response);
        })
        .catch(reject)
    })
  };

  const isPasswordExpired = async ({ userId }) => {
    const response = await loadOrCreateUserPreferences(userId);
    setUserPreferences(response);
    const { lastUpdatedPasswordAt } = response;
    if (!lastUpdatedPasswordAt) return false;
    return moment.unix(moment().unix())
      .diff(moment.unix(lastUpdatedPasswordAt), 'days') >= 90;
  }

  const doRefresh = useCallback(async () => {
    if (!activityRef.current || activityRef.current.tasks.length === 0) {
      // No tasks in progress
      const tokenExpireTimes = getLocalTokenExpireTimes();
      const nowEpochSeconds = getEpochSeconds();
      const expireThresholdEpochTime = nowEpochSeconds + refreshThresholdSeconds;
      const isActive = activityRef.current && !activityRef.current.inactiveTimeout;
      // Refresh tokens if any are about to expire (within a minute or less of the threshold)
      // Do not refresh if there is a task in progress
      if (isActive && tokenExpireTimes && Object.values(tokenExpireTimes).some((expireTime) => expireTime - 60 < expireThresholdEpochTime)) {
        /** @type {import('@aws-amplify/auth').CognitoUser} */
        const user = await Auth.currentAuthenticatedUser();
        const refreshToken = user.getSignInUserSession()?.getRefreshToken();
        if (!refreshToken) {
          // User isn't signed in anymore
          return;
        }

        // eslint-disable-next-line no-unused-vars
        user.refreshSession(refreshToken, (err, data) => {
          if (!err) {
            updateLocalTokenExpireTimes().then((nextExpireTime) => {
              if (refreshTimerId.current) {
                clearTimeout(refreshTimerId.current);
              }
              refreshTimerId.current = setTimeout(() => {
                doRefresh();
              }, (nextExpireTime - refreshThresholdSeconds - nowEpochSeconds) * 1000);
            });
          }
        })
      }
    } else {
      // Try again in 15 seconds if there are tasks in progress
      if (refreshTimerId.current) {
        clearTimeout(refreshTimerId.current);
      }
      refreshTimerId.current = setTimeout(() => {
        doRefresh();
      }, 15 * 1000);
    }
  }, []);

  /**
   * Performs a task asynchronously while keeping the user active.
   *
   * @param {() => Promise<void>} doFunc The function to be executed.
   * @returns A void promise that resolves when the task is completed.
   */
  const doTask = useCallback(async (/** @type {() => Promise<void>} */ doFunc) => {
    const taskId = uuidv4();
    updateActivity(true, taskId);
    return doFunc().finally(() => { updateActivity(undefined, taskId) });
  }, [updateActivity]);

  // Token and activity refreshing
  useEffect(() => {
    doRefresh();

    // lsActivity is set here as the timeout modal countdown was causing it to be reloaded every second
    lsActivity.current = getLocalActivity();

    // Sync localStorage state with changes including other tabs
    const updateLocalActivityOnTabChange = (/** @type {StorageEvent} */ ev) => {
      if (ev.key === activityLSKey) {
        lsActivity.current = getLocalActivity(ev.newValue);
      }
    };
    window.addEventListener('storage', updateLocalActivityOnTabChange, false);

    // Events that trigger activity
    const handleClick = () => {
      if (activityChecked.current && authStateRef.current === AuthState.SignedIn) {
        // If the user is already inactive, do not update the activity
        const currActivity = checkLocalActivity(authStateRef.current, signOut, getTimeoutOffset());
        if ((!currActivity || currActivity.inactiveTimeout) && currActivity?.tasks.length === 0) {
          // currActivity == null: User signed out from inactivity.
          // currActivity.isInactive == true: User reached inactivity timeout. Warning modal showing.
          return;
        }
        // Stay active if there are tasks in progress
        const keepActive = currActivity?.active || currActivity?.tasks.length > 0;
        updateActivity(keepActive);
      }
    }
    window.addEventListener('click', handleClick);

    return () => {
      if (refreshTimerId.current) {
        clearTimeout(refreshTimerId.current);
      }
      window.removeEventListener('click', handleClick);
      window.removeEventListener('storage', updateLocalActivityOnTabChange, false);
    };
  }, [doRefresh, getTimeoutOffset, signOut, updateActivity]);

  // Inactive timeout check interval
  useEffect(() => {
    let inactiveCheckInterval;
    if (!inactiveTime && authState === AuthState.SignedIn) {
      inactiveCheckInterval = setInterval(() => {
        const toSet = checkLocalActivity(authStateRef.current, signOut, getTimeoutOffset());
        activityRef.current = toSet;
        if (toSet?.inactiveTimeout) {
          setInactive(true);
        }
      }, timeoutCheckFidelitySeconds * 1000);
    }
    return () => {
      if (inactiveCheckInterval) {
        clearInterval(inactiveCheckInterval);
      }
    };
  }, [authState, getTimeoutOffset, inactiveTime, setInactive, signOut]);

  // Inactive timeout modal countdown
  useEffect(() => {
    let inactiveTimeoutInterval;
    if (inactiveTime) {
      inactiveTimeoutInterval = setInterval(() => {
        const nowEpochSeconds = getEpochSeconds();
        const secondsRemaining = modalInactivityTimeoutSeconds - (nowEpochSeconds - inactiveTime);
        if (secondsRemaining <= 0) {
          signOut();
        } else {
          setTimeoutSecondsRemaining(formatSecondsToMMSS(secondsRemaining));
        }
      }, 1000);
    }
    return () => {
      if (inactiveTimeoutInterval) {
        clearInterval(inactiveTimeoutInterval);
      }
    };
  // eslint disable is due to use of signOut and it is not readily convertable to a useCallback
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inactiveTime]);

  // onload of the page/refresh check user
  useEffect(() => {
    const activity = getLocalActivity();
    Auth.currentAuthenticatedUser()
      /* eslint-disable-next-line no-shadow */
      .then((user) => {
        setGetSystemMessagesNotifications(false)
        isPasswordExpired({ userId: user.username })
          .then((passwordExpired) => {
            // if user is not active or the password is expired
            if (!activity || activity.lastEndActiveEpoch + getTimeoutOffset() < getEpochSeconds() || passwordExpired) {
              signOut();
            } else {
              doLogin(user)
              createMessageForSQS({ eventType: DATA_COLLECTION_EVENT_TYPES.RESUME_SESSION, userId: user.username }).then((messageBody) => {
                sendMessageToQueueForAnalytics(messageBody);
              });
            }
          });
      }).catch(() => {
        // no session to continue
        setGetSystemMessagesNotifications(true)
        // redirect to login
        if (location.pathname !== '/login' && location.pathname !== '/privacypolicy' && location.pathname !== '/termsofuse' && location.pathname !== '/acceptableusepolicy') {
          // set this to go to your desired location after login
          setLoginRedirect(location.pathname);
          history.push('/login')
        }
      }).finally(() => {
        activityChecked.current = true
      });
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [])

  return (
    <AuthContext.Provider
      value={{
        authState,
        setAuthState,
        user,
        setUserInfo,
        doLogin,
        signOut,
        hasGroup,
        changeUserPassword,
        setLoginRedirect,
        loginRedirect,
        passwordExpiredFlow,
        setPasswordExpiredFlow,
        isPasswordExpired,
        getSystemMessagesNotifications,
        setGetSystemMessagesNotifications,
        doTask,
      }}
    >
      {Boolean(inactiveTime) && (
        <Fade in>
          <Modal
            open={Boolean(inactiveTime)}
            aria-labelledby="session-timeout-title"
            aria-describedby="session-timeout-description"
          >
            <Box sx={style}>
              <span className="containerTitle">Session Almost Expired</span><br />
              <span className="containerSubTitleTimeout">Your session is about to expire due to inactivity.  Please click 'Continue Session' to stay logged-in.</span>
              <div className="containerSubTitleTimeoutTimer" id="logoutTimer">{timeoutSecondsRemaining}</div>
              <Button
                className="LoginFormSignInButtonSessionTimeout"
                type="submit"
                variant="primary"
                onClick={() => {
                  updateActivity();
                  setInactive(false);
                }}
              >
                Continue Session
              </Button>

              <Button
                className="LoginFormOutlineButton"
                type="button"
                variant="light"
                onClick={(ev) => {
                  // stopPropagation here since this click does not count as user activity
                  // Mostly for timing purposes since the signOut will clear the LS entry, but the button click will add it back.
                  ev.stopPropagation();
                  signOut();
                }}
              >
                Sign Out
              </Button>
            </Box>
          </Modal>
        </Fade>
      )}
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = React.useContext(AuthContext);

  if (context === undefined) {
    throw new Error(
      '`useAuth` hook must be used within a `AuthContextProvider` component',
    );
  }
  return context;
};
