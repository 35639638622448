export class Tracker {
  constructor(analytics, event, startTime = Date.now()) {
    this.analytics = analytics;
    this.event = event;
    this.startTime = startTime;
  }

  endTrack(email, groups) {
    const totalTimeMs = Date.now() - this.startTime;
    this.analytics.track(this.event, {
      email,
      groups,
      currentUrl: window.location.href,
      totalTimeMs,
    });
  }
}

export default Tracker;
