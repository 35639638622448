import defaultPluginsConfig from './defaultPluginsConfig';
import createHyperLinkPlugin from './hyperLink/createHyperLinkPlugin';
import createResolvedPlugins, { defaultPlugins } from './defaultPlugins';
/**
 * Returns array of plugins that RTE is going to utilise
 */
const resolvePlugins = (config = defaultPluginsConfig) => {
  if (config?.hyperLinkConfig?.enableHyperLinkPlugin) {
    defaultPlugins.push(
      createHyperLinkPlugin(config?.hyperLinkConfig?.onValidateURL, config?.hyperLinkConfig?.showHyperLinkIcon),
    )
  }
  return createResolvedPlugins(defaultPlugins);
}

export default resolvePlugins;
