/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
import {
  Col,
  Row,
  Card,
  Nav,
  Tab,
  Button,
  Spinner,
  Container,
} from 'react-bootstrap';
import React, {
  useState, useEffect, useRef, useCallback, useMemo,
} from 'react';
import { useParams, Link } from 'react-router-dom';
import _ from 'lodash';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/styles';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
/* import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'; */
import ReplyIcon from '@mui/icons-material/Reply';
import { IconButton } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import Chip from '@mui/material/Chip';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import moment from 'moment';
import styles from './StudyDetails.module.scss';
import LatestUpdateTooltip from './LatestUpdateTooltip';
import ControlledTabsContainer from '../ControlledTabsContainer';
import { useGraphQL } from '../../../providers/graphql';
import { useAuth } from '../../../providers/auth';
import { useSQS } from '../../../providers/sqs';
import { ColorPanelProvider } from '../../../providers/colorPanel/colorPanel';
import {
  formatTaskMeasurementIndividualGridOptions,
  formatMortalityObservationIndividualGridOptions,
  formatClinicalObservationIndividualGridOptions,
  formatDosingRecordIndividualGridOptions,
  formatBodyWeightChangeIndividualGridOptions,
  truncationOfLongStr,
} from '../../utils';
import { createMessageForSQS } from '../../utils.createMessage';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import StudySummary from './StudySummary';
import ConditionalModelTab from './ConditionalModelTab';
import {
  GROUPS,
  AUDIT_TRAIL_EVENT_TYPES,
  DATA_COLLECTION_EVENT_TYPES,
} from '../../../constants';
import './StudyDetails.css';
import Filters from '../Filters';
/* import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined'; */
import Comments from './Comments';
import IconStudyDetailsV2 from './IconStudyDetailsV2.svg';
import IconStudyDataV1 from './IconStudyDataV1.svg';
import { useS3 } from '../../../providers/s3';
import { useAnalytics } from '../../../providers/analytics';
import StudyCharts from './studyCharts';
import ConfirmRollBackModal from './ConfirmRollBackModal';
import SubscribeStudy from './SubscribeStudy';
import newStyles from './StudySummary/StudySummary.module.scss';
import StudySummaryModal from './StudySummary/StudySummaryModal/StudySummaryModal';
import StudyDocuments from './StudyDocuments';
import ModelTab from './ModelTab';

const LightTooltip = styled(({ className, ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Tooltip {...props} classes={{ popper: className }} disableInteractive />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#FFFBEC',
    color: '#222222',
    top: '20px',
    border: '1px solid #FEE7D5',
    borderRadius: '2px',
    fontSize: '12px',
  },
}));

const StudyDetails = function ({ history }) {
  // const eventTabs = {
  //   tv: 'tumor-volume', bw: 'body-weight', bwc: 'body-weight-change', co: 'clinical-observations', dr: 'dosing-record', mo: 'mortality-observations',
  // }
  const isMounted = useRef(false)
  const {
    studies,
    qualityControl,
    getUser,
    rollBackReleasedData,
    getStudyDetailsProjectsData,
    getStudyDetailsDataAPI,
    userPreferences,
    setModelMetaData,
  } = useGraphQL();
  const { project_code } = useParams();
  const { user, hasGroup } = useAuth();
  const { isAccessible } = useS3();
  // const canPreview = true; //for testing backend enforcement
  const { sendMessageToQueueForAudits, sendMessageToQueueForAnalytics } = useSQS();
  const canPreview = hasGroup([GROUPS.STUDY_DIRECTOR, GROUPS.JUNIOR_STUDY_DIRECTOR]);
  const isJsd = hasGroup(GROUPS.JUNIOR_STUDY_DIRECTOR)
  const canViewComments = hasGroup([GROUPS.EXTERNAL, GROUPS.STUDY_DIRECTOR, GROUPS.JUNIOR_STUDY_DIRECTOR, GROUPS.BUSINESS_DEVELOPMENT, GROUPS.BIOMARKER, GROUPS.GENERAL_ACCESS]);
  const canViewStudyDocuments = hasGroup([GROUPS.EXTERNAL, GROUPS.STUDY_DIRECTOR, GROUPS.JUNIOR_STUDY_DIRECTOR, GROUPS.BUSINESS_DEVELOPMENT, GROUPS.BIOMARKER, GROUPS.GENERAL_ACCESS]);
  const canDisableModels = hasGroup([GROUPS.EXTERNAL, GROUPS.BUSINESS_DEVELOPMENT, GROUPS.BIOMARKER, GROUPS.GENERAL_ACCESS]);
  const hasLoadedData = useRef(false)
  const [loadingPreviewButton, setLoadingPreviewButton] = useState(true);
  const [lastModifiedDate, setLastModifiedDate] = useState('');
  const [hasPreviewData, setHasPreviewData] = useState(false);
  const [bodyWeightIndividualGroups, setBodyWeightIndividualGroups] = useState([])
  const [filtersLoading, setFiltersLoading] = useState(false);
  const [bodyWeightChangeIndividualGroups, setBodyWeightChangeIndividualGroups] = useState([])
  const [mortalityObservationIndividualGroups, setMortalityObservationIndividualGroups] = useState([])
  const [clinicalObservationIndividualGroups, setClinicalObservationIndividualGroups] = useState([])
  const [clinicalObservationIndividualGroupsLegend, setClinicalObservationIndividualGroupsLegend] = useState([])
  const [dosingRecordIndividualGroups, setDosingRecordIndividualGroups] = useState([])

  const [tvStudyDays, setTVStudyDays] = useState([])
  const [tvrStudyDays, setTVRStudyDays] = useState([])
  const [tvlStudyDays, setTVLStudyDays] = useState([])
  const [bliStudyDays, setBLIStudyDays] = useState([])
  const [bwStudyDays, setBWStudyDays] = useState([])
  const [bwcStudyDays, setBWCStudyDays] = useState([])
  const [moStudyDays, setMOStudyDays] = useState([])
  const [coStudyDays, setCOStudyDays] = useState([])
  const [doStudyDays, setDOStudyDays] = useState([])
  const [excludeUnassigned] = useState(false)
  const [study, setStudy] = useState({ models: [] })
  const [model, setModel] = useState({})
  const [modelTabsDisabled, setModelTabsDisabled] = useState(false)
  const [previewing, setPreviewing] = useState(false)
  const [filtering, setFiltering] = useState(false)
  const [filtersLocked, setFiltersLocked] = useState(false);
  const [releasing, setReleasing] = useState(false)
  const [released, setReleased] = useState(false);
  const [modelHasData, setModelHasData] = useState(false)
  const [disabledDateFilters, setDisabledDateFilters] = useState({ dosingOption: false, allPhasesOption: false })
  const [cachedModelData] = useState({})
  const [cachedModelMetaData, setCachedModelMetaData] = useState({})
  const setIndividualGridApi = useRef(null)
  const individualGridApi = setIndividualGridApi.current
  const [tumorVolumeIndividualGroups, setTumorVolumeIndividualGroups] = useState([])
  const [tumorVolumeRightIndividualGroups, setTumorVolumeRightIndividualGroups] = useState([])
  const [tumorVolumeLeftIndividualGroups, setTumorVolumeLeftIndividualGroups] = useState([])
  const [bliIndividualGroups, setBliIndividualGroups] = useState([])
  const [modelsDataLoading, setmodelsDataLoading] = useState(true);
  const [docAccessible, setDocAccessible] = useState(false);
  const [previewFilters, setPreviewFilters] = useState({});
  const [updateJsd, setUpdateJsd] = useState(false);
  const [isTumorVolumeDataAvailable, setIsTumorVolumeDataAvailable] = useState(true);
  const [
    mortalityObservationIndividualGridOptions, // eslint-disable-line
    setMortalityObservationIndividualGridOptions,
  ] = useState({})
  const [
    clinicalObservationIndividualGridOptions, // eslint-disable-line
    setClinicalObservationIndividualGridOptions,
  ] = useState({})
  const [toggleCommentsUpdate, setToggleCommentsUpdate] = useState(false);
  const [alreadyFetchedModels, setAlreadyFetchedModels] = useState([]);
  const [showSubscribeStudy, setSubscribeStudyModal] = useState(false);
  const [userInfo, setUserInfo] = useState({});

  const [displaySnackBarMessage, setDisplaySnackBarMessage] = useState('');
  const [confirmRollBack, setConfirmRollBack] = useState(false)
  const [showModalForRollBack, setShowModalForRollBack] = useState(false)
  const [rollBackButtonDisable, setRollBackButtonDisable] = useState(false)
  const [rollBackMetaData, setRollBackMetaData] = useState(false)
  const [rollBackDataSuccess, setRollBackDataSuccess] = useState(false)
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [lastReleasedDate, setLastReleasedDate] = useState('')
  const [reloadChartRollBackData, setReloadChartRollBackData] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModelTabModalOpen, setIsModelTabModalOpen] = useState(false);
  const [isStudySummaryModalOpen, setIsStudySummaryModalOpen] = useState(false);
  const [isStudyDocumentsModalOpen, setIsStudyDocumentsModalOpen] = useState(false);
  const [isModelSummaryModalOpen, setIsModelSummaryModalOpen] = useState(false);

  const analytics = useAnalytics();
  const analyticsTrack = useMemo(() => analytics?.startTrack('study-details-load'), [analytics])
  const analyticsSent = useRef(false);

  useEffect(() => {
    if (!analyticsTrack || analyticsSent.current) return;
    if (study) {
      analyticsTrack?.endTrack(user.email, user.groups);
      analyticsSent.current = true;
    }
  }, [analyticsTrack, study, user.email, user.groups]);
  // Check if "Oncology - In Vivo - Study Directors" is present in the array
  const hasOncologyStudyDirector = useMemo(() => study?.third_dimension?.includes('Oncology - In Vivo - Study Directors') ?? false, [study?.third_dimension])

  const createModelTabOpenHandler = useCallback((/** @type {boolean} */ tf) => () => {
    setIsModelTabModalOpen(tf);
  }, []);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  // show the snackbar Message
  const showSnackBarMessageForRollBackData = (errorAlert) => {
    const messages = {
      success: (
        <div className="LoginForm-SuccessMessage successText" style={{ marginLeft: '30px', width: '150%' }}>
          <div className="LoginForm-SuccessMessageText Mng-not-msg-txt">Successfully Rollback to its previous state</div>
        </div>
      ),
      error: (
        <div className="LoginForm-ErrorMessage errorText" style={{ marginLeft: '30px', width: '150%' }}>
          <div className="LoginForm-ErrorMessageText Mng-not-msg-txt">An error occurred try again later</div>
        </div>
      ),
      doNothing: null,
    };

    return (
      <Stack sx={{ width: '100%' }} spacing={2}>
        {messages[errorAlert]}
      </Stack>
    );
  };

  const shouldDisableModels = () => !canPreview && canDisableModels;

  const rollBackRelease = () => {
    setRollBackMetaData(true);
    setConfirmRollBack(true);
    setShowModalForRollBack(true);
  };

  const toggleRollBackModal = () => setShowModalForRollBack((prevShowModal) => !prevShowModal);

  const setIndividualGrid = (api) => {
    if (!individualGridApi || individualGridApi.destroyCalled) {
      setIndividualGridApi.current = api
    }
  }

  const renderComments = () => (
    (
      <Row style={{ marginTop: '32px' }}>
        <Col>
          <Card className="studyDetails-cardMain">
            <Card.Body className="studyDetails-cardComments">
              <Comments
                model={model}
                projectCode={project_code}
                study={study}
                toggleCommentsUpdate={toggleCommentsUpdate}
                modelsDataLoading={modelsDataLoading}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    )
  );

  const dataExportAudit = async (modelId) => {
    if (user?.id) {
      const messageBody = await createMessageForSQS({
        eventType: AUDIT_TRAIL_EVENT_TYPES.DATA_EXPORT,
        userId: user.id,
        projectId: project_code,
        companyName: study.company,
        attributes: [{ modelId: modelId || model.study_model || '' }],
      })
      sendMessageToQueueForAudits(messageBody)
    }
  };

  const isDocAccessible = useCallback(async () => {

    const response = await isAccessible(project_code);
    if (isMounted.current) setDocAccessible(response);
  });

  const getGraphQlData = (id) => {
    setTVStudyDays([]);
    setTVRStudyDays([]);
    setTVLStudyDays([]);
    setBWStudyDays([]);
    setBWCStudyDays([]);
    setMOStudyDays([]);
    setCOStudyDays([]);
    setDOStudyDays([]);

    if (canPreview) {
      setLoadingPreviewButton(true);
    }

    const isQCMetadataFetched = rollBackMetaData ? true : !cachedModelMetaData[model.study_id];

    setFiltersLoading(true);

    const allDataPromiseDataProcess = (response) => {
      if (response?.data?.tumorVolumeTaskSessionAvailable) {
        const isSessionAvailable = Boolean(response?.data?.tumorVolumeTaskSessionAvailable?.is_task_session_available)
        const isIndividualMeasurementAvailable = Boolean(response?.data?.tumorVolumeTaskMeasurementIndividual?.length)
        setIsTumorVolumeDataAvailable(isSessionAvailable && isIndividualMeasurementAvailable)
      }
      if (response?.data?.getQCMetadata) {
        const QCMetadata = response.data.getQCMetadata;

        try {
          const director = QCMetadata?.director
          const directorFilters = director?.filters
          const juniorDirector = QCMetadata?.juniorDirector
          const juniorDirectorFilters = juniorDirector?.filters
          const directorData = directorFilters ? JSON.parse(directorFilters) : {}
          const jsdData = juniorDirectorFilters ? JSON.parse(juniorDirectorFilters) : {}
          const sdTimestamp = director?.createdAt ? moment(parseInt(director.createdAt)).format('DD MMM YYYY h:mm A') : '';
          const jsdTimestamp = juniorDirector?.createdAt ? moment(parseInt(juniorDirector.createdAt)).format('DD MMM YYYY h:mm A') : '';

          const newMetaData = {
            ...cachedModelMetaData,
            [model.study_id]: {
              ...director, createdAt: sdTimestamp, filters: directorData, jsd: { ...juniorDirector, createdAt: jsdTimestamp, filters: jsdData },
            },
          }
          setCachedModelMetaData(newMetaData)
          setModelMetaData(newMetaData)
        // eslint-disable-next-line no-empty
        } catch (err) {}

      }

      if (canPreview) {
        setLoadingPreviewButton(false);

        if (response?.data?.getPreviewDataAvailability?.preview === 'AVAILABLE') {
          setHasPreviewData(true);
        } else {
          setHasPreviewData(false);
        }

        const finalDate = moment(response.data.getLastModifiedDate.last_modified_date).local().format('DD MMM YYYY h:mm A');
        setLastModifiedDate(finalDate);
      }

      if (response?.data?.getStudyDataApprovalState) {
        const studyDataApprovalState = response.data.getStudyDataApprovalState;
        if (studyDataApprovalState.roll_back_timeStamp === null) {
          setRollBackButtonDisable(true)
        } else {
          setRollBackButtonDisable(false)
          const timestamp = studyDataApprovalState.roll_back_timeStamp
          const lastReleasedRollBackDate = moment(String(timestamp), 'x').local().format('DD MMM YYYY h:mm A');
          setLastReleasedDate(lastReleasedRollBackDate);
        }
        // don't make this request for filtering
        if (!filtering && canPreview) {
          if (studyDataApprovalState.state === 'PREVIEW') {
            setModelHasData(true)
          } else {
            setModelHasData(false)
          }
        }
        setDisabledDateFilters({ dosingOption: !studyDataApprovalState.dosing_begins, allPhasesOption: !studyDataApprovalState.study_begins })
      } else {
        setModelHasData(false)
      }

      if (response?.data?.tumorVolumeTaskMeasurementIndividual?.length === 1) {
        setTumorVolumeIndividualGroups(response.data.tumorVolumeTaskMeasurementIndividual[0].groups);
      }

      if (response?.data?.tumorVolumeTaskMeasurementIndividual?.length === 2) {
        const concatenatedArray = Object.values(
          response.data.tumorVolumeTaskMeasurementIndividual[0].groups.concat(response.data.tumorVolumeTaskMeasurementIndividual[1].groups).reduce((uniqueObjs, obj) => {
            if (!uniqueObjs[obj.group_id]) {
              uniqueObjs[obj.group_id] = obj;
            }
            return uniqueObjs;
          }, {}),
        );

        setTumorVolumeIndividualGroups(concatenatedArray);
        setTumorVolumeRightIndividualGroups(response.data.tumorVolumeTaskMeasurementIndividual[0].groups);
        setTumorVolumeLeftIndividualGroups(response.data.tumorVolumeTaskMeasurementIndividual[1].groups);
      }

      if (response?.data?.bliTaskMeasurementIndividual?.length) {
        const concatenatedArray = Object.values(
          response.data.bliTaskMeasurementIndividual.reduce((uniqueObjs, item) => {
            item.groups.forEach((group) => {
              if (!uniqueObjs[group.group_id]) {
                uniqueObjs[group.group_id] = group;
              }
            });
            return uniqueObjs;
          }, {}),
        );

        setBliIndividualGroups(concatenatedArray);
      } else {
        setBliIndividualGroups([]);
      }

      if (response?.data?.bodyWeightTaskMeasurementIndividual?.length) {
        setBodyWeightIndividualGroups(response.data.bodyWeightTaskMeasurementIndividual[0].groups);
      } else {
        setBodyWeightIndividualGroups([]);
      }

      if (response?.data?.bodyWeightChangeTaskMeasurementIndividual?.length) {
        setBodyWeightChangeIndividualGroups(response.data.bodyWeightChangeTaskMeasurementIndividual[0].groups);
      } else {
        setBodyWeightChangeIndividualGroups([]);
      }

      if (response?.data?.mortalityObservationTaskMeasurementIndividual?.length) {
        setMortalityObservationIndividualGroups(response.data.mortalityObservationTaskMeasurementIndividual[0].groups);
      } else {
        setMortalityObservationIndividualGroups([]);
      }

      if (response?.data?.observationTaskMeasurementIndividual?.length) {
        setClinicalObservationIndividualGroups(response.data.observationTaskMeasurementIndividual[0].groups);
        setClinicalObservationIndividualGroupsLegend(response.data.observationTaskMeasurementIndividual[0].legend);
      } else {
        setClinicalObservationIndividualGroups([]);
        setClinicalObservationIndividualGridOptions({});
        setClinicalObservationIndividualGroupsLegend([]);
      }

      if (response?.data?.dosingTaskMeasurementIndividual?.length) {
        setDosingRecordIndividualGroups(response.data.dosingTaskMeasurementIndividual[0].groups);
      } else {
        setDosingRecordIndividualGroups([]);
      }
    }

    const allDataPromise = getStudyDetailsDataAPI(id, 'preview', undefined, canPreview, isQCMetadataFetched);
    allDataPromise.then((response) => {

      allDataPromiseDataProcess(response);

    }).catch((response) => {
      allDataPromiseDataProcess(response);

      setModelTabsDisabled(false);
    }).finally(() => {
      setTimeout(() => {
        setFiltersLoading(false);
        setLoadingPreviewButton(false);
        setModelTabsDisabled(false);
      }, 0);
    });
  }

  const onConfirmRollback = async () => {
    toggleRollBackModal();
    try {
      const res = await rollBackReleasedData(model.study_id);
      const response = res.data.rollBackReleasedData.rollBackReleasedData;

      if (response === 'Rolled back Successful') {
        getGraphQlData(model.study_id);
        setAlreadyFetchedModels([]);
        setReloadChartRollBackData(true);
        setDisplaySnackBarMessage('success');
      } else if (response === 'Failed to Rollback') {
        setDisplaySnackBarMessage('error');
      }
    // eslint-disable-next-line no-empty
    } catch (error) {
    }

    setRollBackDataSuccess(true);
    setOpenSnackBar(true);
  };

  const dataLoaded = () => {
    setModelTabsDisabled(false);
    setReleasing(true);
  };

  const setFilters = (filters) => {
    setPreviewFilters(filters);
  };

  const resetFlowStates = () => {
    setPreviewFilters({});
    previewing && setPreviewing(false) // eslint-disable-line no-unused-expressions
    filtering && setFiltering(false) // eslint-disable-line no-unused-expressions
    filtersLocked && setFiltersLocked(false) // eslint-disable-line no-unused-expressions
    releasing && setReleasing(false) // eslint-disable-line no-unused-expressions
    released && setReleased(false); // eslint-disable-line no-unused-expressions
    setToggleCommentsUpdate(!toggleCommentsUpdate);
  }
  // userAnal Collection SQSMessage
  const dataReleasedMessageSQS = (event) => {
    createMessageForSQS({
      eventType: event,
      userId: user.id,
      projectId: project_code,
      companyName: study.company,
    }).then((messageBody) => {
      sendMessageToQueueForAnalytics(messageBody)
    })
  }

  const releaseData = (filters, studyId, type) => {
    if (type === 'RELEASED') {
      setAlreadyFetchedModels([]);
      createMessageForSQS({
        eventType: AUDIT_TRAIL_EVENT_TYPES.DATA_RELEASE,
        userId: user.id,
        projectId: project_code,
        companyName: study.company,
        attributes: [{ modelId: model.study_model }],
      }).then((messageBody) => {
        sendMessageToQueueForAudits(messageBody)
      })
      // user data collection SqsMessage
      dataReleasedMessageSQS(DATA_COLLECTION_EVENT_TYPES.DATA_RELEASE)
    } else {
      createMessageForSQS({
        eventType: AUDIT_TRAIL_EVENT_TYPES.DATA_QC,
        userId: user.id,
        projectId: project_code,
        companyName: study.company,
        attributes: [{ modelId: model.study_model }],
      }).then((messageBody) => {
        sendMessageToQueueForAudits(messageBody)
      })
    }
    const updateData = (user) => {
      const studyDirector = user.first_name || user.last_name ? `${user.first_name} ${user.last_name}` : ''
      //! have BE pull user's name for improved security
      qualityControl(studyId, filters, studyDirector, type, project_code, model.study_model, study.company).then((res) => {
        const rollBackBtnDisable = res.data.setStudyQualityControl.rollBackTimeStamp;
        if (rollBackBtnDisable === null) {
          setRollBackButtonDisable(true);
        } else {
          setRollBackButtonDisable(false);
          const lastReleasedRollBackDate = moment(rollBackBtnDisable, 'x').local().format('DD MMM YYYY h:mm A');
          setLastReleasedDate(lastReleasedRollBackDate);
        }
        if (type === 'RELEASED') {
          setAlreadyFetchedModels([]);
          const { models } = study
          models.forEach((modelDetails) => {
            if (modelDetails.study_id === model.study_id) {
              modelDetails.director = { state: 'RELEASED' }
            }
          });
          setStudy({ ...study, models })
          cachedModelMetaData[studyId] = {
            ...cachedModelMetaData[studyId], name: studyDirector, filters, studyId, createdAt: moment().format('DD MMM YYYY h:mm A'),
          }
          // release data, update the cache
          _.set(cachedModelData, `${studyId}.normal`, _.get(cachedModelData, `${studyId}.filter`))
          setReleased(true);
        } else {
          cachedModelMetaData[studyId] = {
            ...cachedModelMetaData[studyId],
            jsd: {
              name: studyDirector, filters, studyId, state: type, createdAt: moment().format('DD MMM YYYY h:mm A'),
            },
          }
          setUpdateJsd(!updateJsd);
        }
        setModelHasData(false);
        // conditionally change the eventType for this, can be approved, action required, or release
      }).catch(() => {
        // implement some user error, buttered toast?
      })
    }

    // get user so we can save the first/last name
    // in future, might need to save user id and fetch on load in order to account for name changes
    getUser(user.id, user.email).then((res) => {
      updateData(res.data.getUser)
    }).catch(() => {
      updateData({})
    })
  }
  const concatenateAllExcludedGroups = tumorVolumeIndividualGroups.concat(bodyWeightChangeIndividualGroups, bodyWeightIndividualGroups, mortalityObservationIndividualGroups, clinicalObservationIndividualGroups, clinicalObservationIndividualGroupsLegend, dosingRecordIndividualGroups, bliStudyDays);
  const AllExcludedGroup = Object.values(
    concatenateAllExcludedGroups.reduce((uniqueObjs, obj) => {
      if (obj.group_id) { // Check if the object has a group_id
        if (!uniqueObjs[obj.group_id]) {
          uniqueObjs[obj.group_id] = obj;
        }
      }
      return uniqueObjs;
    }, {}),
  );
  const filterTgiStudyDays = tumorVolumeIndividualGroups.concat(tumorVolumeRightIndividualGroups, tumorVolumeLeftIndividualGroups);
  useEffect(() => {
    const fetchUser = async () => {
      const response = await getUser(user.id, user.email)
      setUserInfo(response.data.getUser)
      const studies = response.data.getUser.studyVisited || []
      if (!studies.includes(project_code) && !userPreferences.notificationOptIn.includes(project_code)) {
        setSubscribeStudyModal(true)
      }
    }
    fetchUser()
  }, [user])

  useEffect(() => {
    setmodelsDataLoading(true);
    const fetchStudy = async () => {
      let fetchedStudy;
      const result = await getStudyDetailsProjectsData(project_code, user.id);
      if (studies.length) {
        fetchedStudy = _.find(studies, ['project_code', project_code])
      } else {
        fetchedStudy = result && !result.errors && result.data.getProjectRecord;
      }
      hasLoadedData.current = true
      if (fetchedStudy && fetchedStudy.project_code) {

        fetchedStudy.models = result && !result.errors ? result.data.getProjectStudiesWithDetails.sort((a, b) => {
          const nameA = a.study_model.toUpperCase() // ignore upper and lowercase
          const nameB = b.study_model.toUpperCase() // ignore upper and lowercase
          if (nameA < nameB) {
            return -1
          }
          if (nameA > nameB) {
            return 1
          }
          // names must be equal
          return 0
        }) : []

        if (fetchedStudy.models && fetchedStudy.models.length) {
          fetchedStudy.models.map((model) => {
            if (model.study_model === '') {
              model.study_model = 'N/A'
            }
            return model
          })
          if (shouldDisableModels() && !fetchedStudy.models[0].director) {
            const index = fetchedStudy.models.findIndex((item) => item.director);
            if (index > -1) {
              [fetchedStudy.models[0], fetchedStudy.models[index]] = [fetchedStudy.models[index], fetchedStudy.models[0]];
            }
          }
          setModel(fetchedStudy.models[0])
        }

        setStudy(fetchedStudy)
        setmodelsDataLoading(false);
      } else {
        history.push('/landing')
      }
    }
    fetchStudy();
  }, [])

  useEffect(() => {
    if (tumorVolumeIndividualGroups?.length > 0) {
      const tumorVolumeIndividualGridOptions = formatTaskMeasurementIndividualGridOptions(tumorVolumeIndividualGroups, setIndividualGrid)
      if (excludeUnassigned) {
        tumorVolumeIndividualGridOptions.rowData = _.filter(
          tumorVolumeIndividualGridOptions.rowData,
          (row) => row.group !== 'Unassigned',
        )
      }
      if (tumorVolumeIndividualGridOptions && tumorVolumeIndividualGridOptions.columnDefs) {
        setTVStudyDays(
          tumorVolumeIndividualGridOptions.columnDefs.map((col) => {
            if (col.field.startsWith('studyDay')) {
              return Object.values(col.headerComponentParams)
            } return null
          }).filter(Boolean),
        )
      }
    }

    if (tumorVolumeRightIndividualGroups.length > 0) {
      const tumorVolumeRightIndividualGridOptions = formatTaskMeasurementIndividualGridOptions(tumorVolumeRightIndividualGroups, setIndividualGrid)
      if (excludeUnassigned) {
        tumorVolumeRightIndividualGridOptions.rowData = _.filter(
          tumorVolumeRightIndividualGridOptions.rowData,
          (row) => row.group !== 'Unassigned',
        )
      }
      if (tumorVolumeRightIndividualGridOptions && tumorVolumeRightIndividualGridOptions.columnDefs) {
        setTVRStudyDays(
          tumorVolumeRightIndividualGridOptions.columnDefs.map((col) => {
            if (col.field.startsWith('studyDay')) {
              return Object.values(col.headerComponentParams)
            } return null
          }).filter(Boolean),
        )
      }
    }

    if (tumorVolumeLeftIndividualGroups.length > 0) {
      const tumorVolumeLeftIndividualGridOptions = formatTaskMeasurementIndividualGridOptions(tumorVolumeLeftIndividualGroups, setIndividualGrid)
      if (excludeUnassigned) {
        tumorVolumeLeftIndividualGridOptions.rowData = _.filter(
          tumorVolumeLeftIndividualGridOptions.rowData,
          (row) => row.group !== 'Unassigned',
        )
      }
      if (tumorVolumeLeftIndividualGridOptions && tumorVolumeLeftIndividualGridOptions.columnDefs) {
        setTVLStudyDays(
          tumorVolumeLeftIndividualGridOptions.columnDefs.map((col) => {
            if (col.field.startsWith('studyDay')) {
              return Object.values(col.headerComponentParams)
            } return null
          }).filter(Boolean),
        )
      }
    }
  }, [tumorVolumeIndividualGroups, tumorVolumeRightIndividualGroups, tumorVolumeLeftIndividualGroups, excludeUnassigned])

  useEffect(() => {

    if (bliIndividualGroups?.length > 0) {
      const bliIndividualGridOptions = formatTaskMeasurementIndividualGridOptions(bliIndividualGroups, setIndividualGrid)
      if (excludeUnassigned) {
        bliIndividualGridOptions.rowData = _.filter(
          bliIndividualGridOptions.rowData,
          (row) => row.group !== 'Unassigned',
        )
      }

      if (bliIndividualGridOptions && bliIndividualGridOptions.columnDefs) {
        const filteredBLIStudyDays = bliIndividualGridOptions.columnDefs.map((col) => {
          if (col.field.startsWith('studyDay')) {
            return Object.values(col.headerComponentParams)
          } return null
        }).filter(Boolean)

        setBLIStudyDays(
          filteredBLIStudyDays,
        )
      }
    }
  }, [bliIndividualGroups, excludeUnassigned])

  useEffect(() => {

    if (bodyWeightIndividualGroups?.length > 0) {
      const bodyWeightIndividualGridOptions = formatTaskMeasurementIndividualGridOptions(bodyWeightIndividualGroups, setIndividualGrid)
      if (excludeUnassigned) {
        bodyWeightIndividualGridOptions.rowData = _.filter(
          bodyWeightIndividualGridOptions.rowData,
          (row) => row.group !== 'Unassigned',
        )
      }

      if (bodyWeightIndividualGridOptions && bodyWeightIndividualGridOptions.columnDefs) {
        const filteredBWStudyDays = bodyWeightIndividualGridOptions.columnDefs.map((col) => {
          if (col.field.startsWith('studyDay')) {
            return Object.values(col.headerComponentParams)
          } return null
        }).filter(Boolean)

        setBWStudyDays(
          filteredBWStudyDays,
        )
      }
    }
  }, [bodyWeightIndividualGroups, excludeUnassigned])

  useEffect(() => {
    if (bodyWeightChangeIndividualGroups?.length > 0) {
      const bodyWeightChangeIndividualGridOptions = formatBodyWeightChangeIndividualGridOptions(bodyWeightChangeIndividualGroups, setIndividualGrid)
      if (excludeUnassigned) {
        bodyWeightChangeIndividualGridOptions.rowData = _.filter(
          bodyWeightChangeIndividualGridOptions.rowData,
          (row) => row.group !== 'Unassigned',
        )
      }
      if (bodyWeightChangeIndividualGridOptions) {
        setBWCStudyDays(
          bodyWeightChangeIndividualGridOptions.columnDefs.map((col) => {
            if (col.field.startsWith('studyDay')) {
              return Object.values(col.headerComponentParams)
            } return null
          }).filter(Boolean),
        )
      }
    }
  }, [bodyWeightChangeIndividualGroups, excludeUnassigned])

  useEffect(() => {

    if (mortalityObservationIndividualGroups?.length > 0) {
      const mortalityObservationGridOptions = formatMortalityObservationIndividualGridOptions(mortalityObservationIndividualGroups, setIndividualGrid)
      if (excludeUnassigned) {
        mortalityObservationGridOptions.rowData = _.filter(
          mortalityObservationGridOptions.rowData,
          (row) => row.group !== 'Unassigned',
        )
      }

      setMortalityObservationIndividualGridOptions(mortalityObservationGridOptions)
      if (mortalityObservationGridOptions && mortalityObservationGridOptions.columnDefs) {
        const filteredGroups = mortalityObservationGridOptions.columnDefs.map((col) => {
          if (col.field.startsWith('studyDay')) {
            return Object.values(col.headerComponentParams)
          } return null
        }).filter(Boolean)

        setMOStudyDays(
          filteredGroups,
        )
      }
    }
  }, [mortalityObservationIndividualGroups, excludeUnassigned])

  useEffect(() => {
    if (clinicalObservationIndividualGroups?.length > 0 && clinicalObservationIndividualGroupsLegend?.length > 0) {
      const clinicalObservationGridOptions = formatClinicalObservationIndividualGridOptions(clinicalObservationIndividualGroups, setIndividualGrid, clinicalObservationIndividualGroupsLegend)
      if (excludeUnassigned) {
        clinicalObservationGridOptions.rowData = _.filter(
          clinicalObservationGridOptions.rowData,
          (row) => row.group !== 'Unassigned',
        )
      }
      setClinicalObservationIndividualGridOptions(clinicalObservationGridOptions)
      if (clinicalObservationGridOptions.columnDefs) {
        setCOStudyDays(
          clinicalObservationGridOptions.columnDefs.map((col) => {
            if (col.field.startsWith('studyDay')) {
              return Object.values(col.headerComponentParams)
            } return null
          }).filter(Boolean),
        )
      }
    }
  }, [clinicalObservationIndividualGroups, clinicalObservationIndividualGroupsLegend, excludeUnassigned])

  useEffect(() => {
    if (dosingRecordIndividualGroups?.length > 0) {
      const dosingRecordIndividualGridOptions = formatDosingRecordIndividualGridOptions(dosingRecordIndividualGroups, setIndividualGrid)
      if (excludeUnassigned) {
        dosingRecordIndividualGridOptions.rowData = _.filter(
          dosingRecordIndividualGridOptions.rowData,
          (row) => row.group !== 'Unassigned',
        )
      }
      if (dosingRecordIndividualGridOptions && dosingRecordIndividualGridOptions.columnDefs) {
        setDOStudyDays(
          dosingRecordIndividualGridOptions.columnDefs.map((col) => {
            if (col.field.startsWith('studyDay')) {
              return Object.values(col.headerComponentParams)
            } return null
          }).filter(Boolean)
            .map((x) => [x[0], x[1], x[2]]), // Need to remove the times from the study days
        )
      }
    }
  }, [dosingRecordIndividualGroups, excludeUnassigned])

  useEffect(() => {
    setModelTabsDisabled(true)
    if (!_.isEmpty(model)) {
      getGraphQlData(model.study_id);
    }
  }, [model.study_id])

  useEffect(() => {
    // On page load
    if (user && user.id && study.company) {
      createMessageForSQS({
        eventType: AUDIT_TRAIL_EVENT_TYPES.LAUNCH_PROJECT_DASHBOARD,
        userId: user.id,
        projectId: project_code,
        companyName: study.company,
      }).then((messageBody) => {
        sendMessageToQueueForAudits(messageBody)
      }).catch(() => {})
    }
  }, [user, study])

  useEffect(() => {
    // set the first mount state to be true.
    isMounted.current = true;
    isDocAccessible();
    return () => {
      isMounted.current = false;
    }
  }, []);

  const changeModelAndResetPreview = (model) => {
    setModel(model)
    setFiltersLocked(false)
    setFiltering(false)
    setPreviewing(false);
    setReleased(false);
    setReleasing(false);
    if (user?.id) {
      createMessageForSQS({
        eventType: AUDIT_TRAIL_EVENT_TYPES.MODEL_SWITCH,
        userId: user.id,
        projectId: project_code,
        companyName: study.company,
        attributes: [{ modelId: model.study_model }],
      }).then((messageBody) => {
        sendMessageToQueueForAudits(messageBody)
      })
    }
  };

  const breadcrumbs = useMemo(() => [
    <Link underline="hover" className="study-details-breadcrumb-homelink" key="1" to="/landing"/*  onClick={''} */>
      {hasOncologyStudyDirector ? <span><em>In Vivo Studies</em></span> : <><span><em>Study </em></span> List</>}
    </Link>,

    <Typography className="bc-study-det" key="3">
      Study Dashboard for {project_code}
    </Typography>,
  ], [hasOncologyStudyDirector, project_code]);

  const displayDateRange = (value) => (value?.startDay !== undefined ? `Study Day Range, Day ${value.startDay} to Day ${value.endDay}` : value?.range);
  // in progress trying to get these to not break in the middle
  const displayExcludedGroups = (value) => {
    if (value?.length && AllExcludedGroup?.length) {
      return value.map((group, index) => (
        group.length
          ? (
            /* eslint-disable-next-line react/no-array-index-key */
            <span key={index} className="groupText">
              {truncationOfLongStr(AllExcludedGroup.filter((excludeGroup) => excludeGroup.group_name === group)[0]?.group_name_to_show)}{index !== value.length - 1 ? ', ' : ''}
            </span>
          ) : group
      ))
    }
    return 'None';
  };
  const displayJsdIndicator = () => {

    // move this function into on get and then also run this on save. Better to do it there rather than on every render
    const sdTimestamp = _.get(cachedModelMetaData, `${model?.study_id}.createdAt`) ? new Date(_.get(cachedModelMetaData, `${model?.study_id}.createdAt`)) : null;
    const jsdTimestamp = _.get(cachedModelMetaData, `${model?.study_id}.jsd.createdAt`) ? new Date(_.get(cachedModelMetaData, `${model?.study_id}.jsd.createdAt`)) : null;
    const jsdState = _.get(cachedModelMetaData, `${model?.study_id}.jsd.state`);
    if (jsdState && jsdTimestamp) {
      if (jsdTimestamp > sdTimestamp || (jsdTimestamp && !sdTimestamp)) {
        return jsdState === 'APPROVED' ? (
          <Chip
            size="small"
            variant="outlined"
            color="success"
            label="Approved"
            sx={{
              fontFamily: 'AvenirNextLTPro-Medium', px: '5px', bgcolor: '#E9FFF3', marginRight: '25px',
            }}
            icon={<CheckCircleIcon />}
          />
        ) : (
          <Chip
            size="small"
            variant="outlined"
            color="warning"
            label="Action Required"
            sx={{
              fontFamily: 'AvenirNextLTPro-Medium', px: '5px', bgcolor: '#FFF5F3', marginRight: '25px',
            }}
            icon={<WarningAmberIcon />}
          />
        )
      }
    }
    return null
  };

  const togglePreviewMode = () => {
    setPreviewing(!previewing);
    setFiltering(!filtering);
  };

  useEffect(() => {

    study.models.forEach((modelDetails, index) => {
      if (modelDetails.study_id === model.study_id && !alreadyFetchedModels.filter((fetchedModel) => fetchedModel.data.study_id === modelDetails.study_id).length) {

        setAlreadyFetchedModels((prevState) => [
          ...prevState,
          {
            data: modelDetails,
            comp: <StudyCharts
              model={modelDetails}
              index={index}
              previewing={false}
              filtersLocked={filtersLocked}
              projectCode={project_code}
              filtering={filtering}
              dataLoaded={dataLoaded}
              study={study}
              previewFilters={previewFilters}
              released={released}
              // eslint-disable-next-line react/no-array-index-key
              key={`chart-${index}`}
            />,
          },
        ]);
      }
    });
  }, [study.models, model, alreadyFetchedModels, reloadChartRollBackData]);

  const disableModelLink = (index, model) => {
    if (shouldDisableModels()) {
      if (index === 0) {
        return false;
      }
      return !model.director;
    }
    return false;
  };

  const onNavClick = (index, model) => () => {
    if (!modelTabsDisabled && !disableModelLink(index, model)) {
      changeModelAndResetPreview(model)
    }
  };

  const showTGI = () => {
    if (cachedModelMetaData[model.study_id].filters?.controlGroup && tumorVolumeIndividualGroups?.length && cachedModelMetaData[model.study_id].filters?.controlGroup !== 'undefined') {
      return truncationOfLongStr(tumorVolumeIndividualGroups.filter((excludeGroup) => excludeGroup.group_name === cachedModelMetaData[model.study_id].filters?.controlGroup)[0]?.group_name_to_show)
    }
    return 'No TGI';
  }

  const showJsdTGI = () => {
    if (cachedModelMetaData[model.study_id].jsd.filters?.controlGroup && tumorVolumeIndividualGroups?.length && cachedModelMetaData[model.study_id].jsd.filters?.controlGroup !== 'undefined') {
      return truncationOfLongStr(tumorVolumeIndividualGroups.filter((excludeGroup) => excludeGroup.group_name === cachedModelMetaData[model.study_id].jsd.filters?.controlGroup)[0]?.group_name_to_show)
    }
    return 'No TGI';
  }
  const filterAllStudyDays = _.sortBy(_.unionWith(tvStudyDays, tvrStudyDays, tvlStudyDays, bliStudyDays, bwStudyDays, bwcStudyDays, moStudyDays, coStudyDays, doStudyDays, _.isEqual), (x) => parseInt(x[1].split(' ')[1]));
  const needsWrapper = !docAccessible || !canViewStudyDocuments;

  return (study && hasLoadedData.current) ? (
    <ColorPanelProvider>

      <div className="details-inner-container-mphs">
        <SubscribeStudy
          projectCode={project_code}
          userInfo={userInfo}
          showSubscribeStudy={showSubscribeStudy}
          setSubscribeStudyModal={setSubscribeStudyModal}
        />
        <ControlledTabsContainer defaultActiveKey="tab-0">
          <Stack spacing={1}>
            <Breadcrumbs
              underline="none"
              className="bc-margin-bl" /* sx={{fontSize: '14px', paddingBottom: '27px'}} */
              separator={<img src="../landing/arrow_right.svg" alt="logo" />} /* svg icon in place of text arrow */
              aria-label="breadcrumb"
            >
              {breadcrumbs}
            </Breadcrumbs>
          </Stack>

          <div className="d-flex newLine">
            <img className="landing-icon" src={IconStudyDetailsV2} alt="" />
            <div className="bcSub">Study Dashboard for {project_code}</div>
          </div>
          {hasOncologyStudyDirector && (
            <div className={newStyles.studySummaryInfoContainer}>
              <div className="d-flex newLine">
                <div className={newStyles.btnTileContainer}>
                  <Button
                    variant="outlined"
                    onClick={() => setIsStudySummaryModalOpen((prev) => !prev)}
                    className={`popup-tile-btn study-summary-button ${isStudySummaryModalOpen ? 'active' : ''}`}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                      <rect id="Rectangle_BG" data-name="Rectangle BG" width="32" height="32" opacity="0" />
                      <path id="summary" d="M9.222,23.4H20.111v3.2H9.222Zm0-6.4H24.778v3.2H9.222Zm0-6.4H24.778v3.2H9.222ZM27.889,4.2h-6.5a4.607,4.607,0,0,0-8.773,0h-6.5a2.652,2.652,0,0,0-.622.064,3.1,3.1,0,0,0-1.571.88,3.212,3.212,0,0,0-.669,1.024A3.158,3.158,0,0,0,3,7.4V29.8a3.363,3.363,0,0,0,.249,1.248,3.4,3.4,0,0,0,.669,1.024,3.1,3.1,0,0,0,1.571.88A3.927,3.927,0,0,0,6.111,33H27.889A3.166,3.166,0,0,0,31,29.8V7.4A3.166,3.166,0,0,0,27.889,4.2ZM17,3.8A1.2,1.2,0,1,1,15.833,5,1.192,1.192,0,0,1,17,3.8Zm10.889,26H6.111V7.4H27.889Z" transform="translate(-1 -1)" />
                    </svg>
                    Study Summary
                  </Button>
                  {isStudySummaryModalOpen && (
                    <div className={newStyles.studySummaryModalContainer}>
                      <IconButton
                        edge="end"
                        color="inherit"
                        onClick={() => setIsStudySummaryModalOpen(false)}
                        aria-label="close"
                        className={newStyles.studySummaryModalCloseButton}
                      >
                        <CloseIcon />
                      </IconButton>
                      <StudySummaryModal projectCode={project_code} study={study} />
                    </div>
                  )}
                </div>
                <div className={newStyles.btnTileContainer}>
                  <Button
                    variant="outlined"
                    onClick={() => setIsStudyDocumentsModalOpen((prev) => !prev)}
                    className={`popup-tile-btn study-summary-button ${isStudyDocumentsModalOpen ? 'active' : ''}`}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                      <path id="Rectangle_BG" data-name="Rectangle BG" d="M0,0H32V32H0Z" fill="none" />
                      <path id="study_documents" data-name="study documents" d="M23.221,1H5.032A2.981,2.981,0,0,0,2,3.909V24.273H5.032V3.909H23.221ZM21.705,6.818H11.095A2.968,2.968,0,0,0,8.078,9.727L8.063,30.091A2.968,2.968,0,0,0,11.08,33H27.768A2.981,2.981,0,0,0,30.8,30.091V15.545ZM11.095,30.091V9.727h9.095V17h7.579V30.091Z" transform="translate(0 -1)" />
                    </svg>
                    Study Documents
                  </Button>
                  {isStudyDocumentsModalOpen && (
                    <div className={newStyles.studySummaryModalContainer}>
                      <IconButton
                        edge="end"
                        color="inherit"
                        onClick={() => setIsStudyDocumentsModalOpen(false)}
                        aria-label="close"
                        className={newStyles.studySummaryModalCloseButton}
                      >
                        <CloseIcon />
                      </IconButton>
                      <StudyDocuments projectCode={project_code} study={study} />
                    </div>
                  )}
                </div>
                <div className={newStyles.btnTileContainer}>
                  <Button
                    variant="outlined"
                    onClick={() => setIsModelSummaryModalOpen((prev) => !prev)}
                    className={`popup-tile-btn study-summary-button ${isModelSummaryModalOpen ? 'active' : ''}`}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                      <rect id="Rectangle_BG" data-name="Rectangle BG" width="32" height="32" transform="translate(0)" fill="none" />
                      <path id="model_summary" data-name="model summary" d="M31.632,3H6.368A3.361,3.361,0,0,0,3,6.333V29.667A3.361,3.361,0,0,0,6.368,33H31.632A3.361,3.361,0,0,0,35,29.667V6.333A3.361,3.361,0,0,0,31.632,3Zm0,3.333v5H6.368v-5ZM23.211,29.667H14.789v-15h8.421Zm-16.842-15h5.053v15H6.368Zm20.211,15v-15h5.053v15Z" transform="translate(-3 -2)" />
                    </svg>
                    Model Summary
                  </Button>
                  {isModelSummaryModalOpen && (
                    <div className={newStyles.studySummaryModalContainer}>
                      <IconButton
                        edge="end"
                        color="inherit"
                        onClick={() => setIsModelSummaryModalOpen(false)}
                        aria-label="close"
                        className={newStyles.studySummaryModalCloseButton}
                      >
                        <CloseIcon />
                      </IconButton>
                      <ModelTab
                        disableModelLink={disableModelLink}
                        study={study}
                        setModel={setModel}
                        modelTabsDisabled={modelTabsDisabled}
                        dataExportAudit={dataExportAudit}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          <div className={styles.flexBox}>
            {canViewStudyDocuments && <StudySummary hasOncologyStudyDirector={hasOncologyStudyDirector} isModalOpen={isModalOpen} handleCloseModal={handleCloseModal} projectCode={project_code} study={study} />}
            {((canViewStudyDocuments && hasOncologyStudyDirector) || hasGroup([GROUPS.DEMO_DATA]))
              && (
                <ConditionalModelTab
                  hasOncologyStudyDirector={hasOncologyStudyDirector}
                  disableModelLink={disableModelLink}
                  study={study}
                  setModel={changeModelAndResetPreview}
                  modelTabsDisabled={modelTabsDisabled}
                  dataExportAudit={dataExportAudit}
                  isModelTabModalOpen={isModelTabModalOpen}
                  handleModelTabCloseModal={createModelTabOpenHandler(false)}
                />
              )}
            {needsWrapper ? (
              <div className={styles.flexBoxItem}>
                {!hasOncologyStudyDirector && <StudyDocuments projectCode={project_code} study={study} />}
              </div>
            ) : (
              !hasOncologyStudyDirector && <StudyDocuments projectCode={project_code} study={study} />
            )}
          </div>
          {
            ((canViewStudyDocuments && hasOncologyStudyDirector) || hasGroup([GROUPS.DEMO_DATA])) && (
              <Row>
                <Col>
                  <Card className="studyDetails-cardMain">
                    <Card.Header className="text-center AvenirNextLTProDemi text-md-left d-flex study-data-card-header"> {/* styling enhancements as per design */}
                      <img src={IconStudyDataV1} alt="logo" />
                      <Typography className="card-head-study AvenirNextLTProMedium">
                        Study Data
                      </Typography>
                      {cachedModelMetaData[model?.study_id]
                        && (
                          <>
                            <div className="flexSpacer" />
                            <div className="updateText">
                              {_.get(cachedModelMetaData, `${model?.study_id}.createdAt`) ? (
                                <LatestUpdateTooltip
                                  isTumorVolumeDataAvailable={isTumorVolumeDataAvailable}
                                  lable="Latest SD Update:"
                                  canPreview={canPreview}
                                  showTGI={showTGI}
                                  displayDateRange={displayDateRange}
                                  displayExcludedGroups={displayExcludedGroups}
                                  dateRange={cachedModelMetaData[model.study_id].filters?.dateRange}
                                  unassignedMice={cachedModelMetaData[model.study_id].filters?.unassignedMice}
                                  excludeGroups={cachedModelMetaData[model.study_id].filters?.excludeGroups}
                                  referenceDayForBWC={cachedModelMetaData[model.study_id].filters?.referenceDayForBWC}
                                  referenceDayForTGI={cachedModelMetaData[model.study_id].filters?.referenceDayForTGI}
                                  name={cachedModelMetaData[model.study_id].name}
                                  createdAt={cachedModelMetaData[model.study_id].createdAt}
                                />
                              )
                                : null}
                              {_.get(cachedModelMetaData, `${model?.study_id}.jsd.createdAt`) ? (
                                <LatestUpdateTooltip
                                  lable="Latest JSD Update:"
                                  isTumorVolumeDataAvailable={isTumorVolumeDataAvailable}
                                  canPreview={canPreview}
                                  showTGI={showJsdTGI}
                                  displayDateRange={displayDateRange}
                                  displayExcludedGroups={displayExcludedGroups}
                                  displayJsdIndicator={displayJsdIndicator}
                                  dateRange={cachedModelMetaData[model.study_id].jsd.filters?.dateRange}
                                  unassignedMice={cachedModelMetaData[model.study_id].jsd.filters?.unassignedMice}
                                  excludeGroups={cachedModelMetaData[model.study_id].jsd.filters?.excludeGroups}
                                  referenceDayForBWC={cachedModelMetaData[model.study_id].jsd.filters?.referenceDayForBWC}
                                  referenceDayForTGI={cachedModelMetaData[model.study_id].jsd.filters?.referenceDayForTGI}
                                  name={cachedModelMetaData[model.study_id].jsd.name}
                                  createdAt={cachedModelMetaData[model.study_id].jsd.createdAt}
                                />
                              )
                                : null}
                            </div>
                          </>
                        )}
                    </Card.Header>
                    <Tab.Content>
                      <Nav data-testid="studyDataTest" className={`card-header-pills mt-2 study-data-nav-pills-band ${modelTabsDisabled && 'study-data-nav-pills-band-disabled'}`} variant="pills">
                        {study.models.map((model, index) => (
                          disableModelLink(index, model)
                            ? (
                              <LightTooltip
                                title={(
                                  <>
                                    <Typography className="AvenirNextLTProRegular">
                                      No study data available
                                    </Typography>
                                  </>
                                )}
                                placement="bottom"
                              >
                                <Nav.Item
                                  className="disable-nav-item"
                                /* eslint-disable-next-line react/no-array-index-key */
                                  key={`nav-item-${index}`}
                                  onClick={onNavClick(index, model)}
                                >
                                  <Nav.Link
                                    disabled={modelTabsDisabled || disableModelLink(index, model)}
                                    className="badge nav-link study-data-pill"
                                    eventKey={`tab-${index}`}
                                  >
                                    {model.study_model}
                                  </Nav.Link>
                                </Nav.Item>
                              </LightTooltip>
                            ) : (
                              <Nav.Item
                                /* eslint-disable-next-line react/no-array-index-key */
                                key={`nav-item-${index}`}
                                onClick={onNavClick(index, model)}
                              >
                                <Nav.Link
                                  disabled={modelTabsDisabled || disableModelLink(index, model)}
                                  className="badge nav-link study-data-pill"
                                  eventKey={`tab-${index}`}
                                >
                                  {model.study_model}
                                </Nav.Link>
                              </Nav.Item>
                            )))}
                      </Nav>
                      {rollBackDataSuccess
                      && (
                      <Snackbar
                        style={{ position: 'unset' }}
                        open={openSnackBar}
                        autoHideDuration={2500}
                        onClose={() => setOpenSnackBar(false)}
                        message={showSnackBarMessageForRollBackData(displaySnackBarMessage)}
                      />
                      )}

                      {
                        canPreview && !_.isEmpty(model) && !filtering && loadingPreviewButton
                        && (
                          <Row style={{ margin: '10px 0px 5px 32px' }}>
                            <Button variant="primary" disabled>
                              <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                              Loading Preview ....
                            </Button>
                          </Row>
                        )
                      }

                      {canPreview && !_.isEmpty(model) && !filtering && !loadingPreviewButton
                        && (
                          <div className="previewBtnContainer">
                            {
                              !hasPreviewData
                                ? (
                                  <Button className="previewBtn study-details-data-filters-launcher-button button-font" disabled style={{ background: 'grey' }}>
                                    <b>{`No Preview Data Available for ${model.study_model}`}</b>
                                  </Button>
                                ) : (
                                  <Button
                                    disabled={modelTabsDisabled || !filterAllStudyDays.length}
                                    className="previewBtn study-details-data-filters-launcher-button button-font"
                                    onClick={() => {
                                      togglePreviewMode()
                                      if (user && user.id) {
                                        createMessageForSQS({
                                          eventType: AUDIT_TRAIL_EVENT_TYPES.DATA_VIEW,
                                          userId: user.id,
                                          projectId: project_code,
                                          companyName: study.company,
                                          attributes: [{ modelId: model.study_model }],
                                        }).then((messageBody) => {
                                          sendMessageToQueueForAudits(messageBody)
                                        })
                                      }
                                    }}
                                  >
                                    <Tooltip title={!filterAllStudyDays.length ? `Study Data Unavailable for ${model.study_model}` : ''} style={{ padding: '10px' }} disableInteractive>
                                      <b>{`Preview Latest Data For ${model.study_model}`}</b>
                                    </Tooltip>
                                  </Button>
                                )
                            }
                            {
                              !isJsd && (
                                <Tooltip disableInteractive title={rollBackButtonDisable ? 'Rollback Unavailable' : 'Rollback'} arrow>
                                  <span>
                                    <IconButton size="large" onClick={rollBackRelease} disabled={rollBackButtonDisable}>
                                      <ReplyIcon />
                                    </IconButton>
                                  </span>
                                </Tooltip>
                              )
                            }
                            {!modelHasData
                              ? (
                                <LightTooltip
                                  className="navHeader-notifications-toolTips"
                                  title={(
                                    <>
                                      <Typography className="AvenirNextLTProRegular">
                                        {`${model.study_model} is up-to-date. No new data available. Latest StudyLog Data: ${lastModifiedDate}`}
                                      </Typography>
                                    </>
                                  )}
                                  placement="bottom"
                                >
                                  {/* <Tooltip title={`${model.study_model} is up-to-date. No new data available.`}> */}
                                  <Chip
                                    size="small"
                                    icon={<CheckCircleIcon />}
                                    variant="outlined"
                                    color="primary"
                                    label={<div className="studyDetails-dataIndicatorchipLabel">No New Data - Latest StudyLog Data: {lastModifiedDate}</div>}
                                    sx={{ fontFamily: 'AvenirNextLTPro-Medium', px: '5px', bgcolor: '#F0F5FC' }}
                                  />
                                </LightTooltip>
                              )
                              : (
                                <LightTooltip
                                  className="navHeader-notifications-toolTips"
                                  title={(
                                    <>
                                      <Typography className="AvenirNextLTProRegular">
                                        {`${model.study_model} New data available. Latest StudyLog Data: ${lastModifiedDate}`}
                                      </Typography>
                                    </>
                                  )}
                                  placement="bottom"
                                >
                                  {/* <Tooltip title={`${model.study_model} New data available.`}> */}
                                  <Chip
                                    size="small"
                                    icon={<CheckCircleIcon />}
                                    variant="outlined"
                                    color="success"
                                    label={<div className="studyDetails-dataIndicatorchipLabel">New data available - Latest StudyLog Data: {lastModifiedDate}</div>}
                                    sx={{ fontFamily: 'AvenirNextLTPro-Medium', px: '5px', bgcolor: '#E9FFF3' }}
                                  />
                                </LightTooltip>
                              )}
                          </div>
                        )}

                      {confirmRollBack ? <ConfirmRollBackModal onConfirmRollback={onConfirmRollback} setRollBackMetaData={setRollBackMetaData} toggleRollBackModal={toggleRollBackModal} showModalForRollBack={showModalForRollBack} model={model} lastReleasedDate={lastReleasedDate} /> : null}
                      {filtering
                        && (
                          <Filters
                            isTumorVolumeDataAvailable={isTumorVolumeDataAvailable}
                            resetFlowStates={resetFlowStates}
                            model={model}
                            filtersLocked={filtersLocked}
                            setFiltersLocked={setFiltersLocked}
                            disabledDateFilters={disabledDateFilters}
                            releasing={releasing}
                            filterStudyDays={{
                              tvStudyDays,
                              tvrStudyDays,
                              tvlStudyDays,
                              bliStudyDays,
                              bwStudyDays,
                              bwcStudyDays,
                              moStudyDays,
                              coStudyDays,
                              doStudyDays,
                            }}
                            filterAllStudyDays={filterAllStudyDays}
                            filterTgiStudyDays={filterTgiStudyDays}
                            AllExcludedGroup={AllExcludedGroup}
                            TgiControlGroups={tumorVolumeIndividualGroups}
                            referenceDays={bodyWeightChangeIndividualGroups}
                            modelTabsDisabled={modelTabsDisabled}
                            releaseData={releaseData}
                            setPFilters={setFilters}
                            filtersLoading={filtersLoading}
                            cachedModelMetaData={cachedModelMetaData[model.study_id]}
                          >
                            <>
                              <StudyCharts
                                model={model}
                                index={100}
                                previewing={previewing}
                                filtersLocked={filtersLocked}
                                projectCode={project_code}
                                filtering={filtering}
                                dataLoaded={dataLoaded}
                                study={study}
                                previewFilters={previewFilters}
                                released={null}
                              />
                              {
                                (canPreview || canViewComments) && !modelTabsDisabled
                                  ? renderComments() : null
                              }
                            </>
                          </Filters>
                        )}

                      {alreadyFetchedModels.map((studyChart) => studyChart.comp)}

                    </Tab.Content>
                  </Card>
                </Col>
              </Row>
            )
          }

          {
            canViewComments
              ? (
                <Row style={{ marginTop: '32px' }}>
                  <Col>
                    <Card className="studyDetails-cardMain">
                      <Card.Body className="studyDetails-cardComments">
                        <Comments
                          model={model}
                          projectCode={project_code}
                          study={study}
                          toggleCommentsUpdate={toggleCommentsUpdate}
                          modelsDataLoading={modelsDataLoading}
                        />
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              ) : null
          }
        </ControlledTabsContainer>
      </div>
    </ColorPanelProvider>
  ) : (
    <Container className="authContainer" fluid>
      <Row className="justify-content-center">
        <Button variant="primary" disabled>
          <Spinner
            as="span"
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          Loading Study Dashboard...
        </Button>
      </Row>
    </Container>
  )
}
export default StudyDetails
