import React, { useState, useRef, useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/EditOutlined';
import ColorModal from './colorModal';

function ColorEditor({ currentGroups, colorMappings, getColorMappings }) {

  const [open, setOpen] = useState(false);
  const [newColorMappings, setNewColorMappings] = useState([...colorMappings]);
  const indexMappings = useRef({});
  const [currentIndex, setCurrentIndex] = useState(null);

  useEffect(() => {
    setNewColorMappings([...colorMappings]);
  }, [colorMappings]);

  const openChooser = (index) => () => {
    setCurrentIndex(index);
    setOpen(true);
  };

  const onApply = (newColor) => {
    indexMappings.current[currentIndex] = newColor;
    const colors = [...newColorMappings];
    colors[currentIndex] = newColor
    setNewColorMappings(colors);
    getColorMappings(indexMappings.current);
  };

  const onClose = () => {
    setOpen(false);
  };

  const getColor = () => newColorMappings[currentIndex];

  return (
    <>
      <ul className="groups-list">
        {
          currentGroups.map((group, index) => (
            <li className="group-item" key={group}>
              <div className="label"> {`Group ${index + 1}`} </div>
              <div className="palette" key={newColorMappings[index]} style={{ background: newColorMappings[index], marginLeft: '40px', marginTop: '-1px' }} data-testid="palette-item" />
              <div>
                <IconButton
                  data-testid="edit-custom-color-button"
                  aria-label="more"
                  id="long-button"
                  onClick={openChooser(index)}
                  size="medium"
                  sx={{
                    padding: '0px',
                    marginLeft: '30px',
                    marginTop: '-8px',
                  }}
                >
                  <MoreVertIcon />
                </IconButton>
              </div>
            </li>
          ))
        }
      </ul>
      <ColorModal
        open={open}
        onApply={onApply}
        onClose={onClose}
        currentColor={getColor()}
      />
    </>
  );
}

export default ColorEditor;
