/* eslint-disable react/forbid-prop-types */
import React, {
  useState, useEffect, useRef, useCallback,
} from 'react';
import {
  Card,
  Tab,
  Tabs,
  Button,
  Spinner,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import _ from 'lodash';
import TotalFluxPanel from '../TotalFluxPanel';
import TumorVolumePanel from '../TumorVolumePanel';
import BodyWeightPanel from './BodyWeightPanel';
import BodyWeightChangePanel from './BodyWeightChangePanel';
import TumorVolumeChangePanel from './TumorVolumeChangePanel';
import ClinicalObservationsPanel from '../ClinicalObservationsPanel';
import DosingRecordPanel from './DosingRecordPanel';
import MortalityObservationPanel from './MortalityObservationPanel';
// import ModelCharacteristics from './ModelCharacteristics'

import {
  formatTaskMeasurementSummaryData,
  formatTaskMeasurementIndividualData,
  formatTaskMeasurementSummaryGridOptions,
  formatTGISummaryGridOptions,
  formatTaskMeasurementIndividualGridOptions,
  formatMortalityObservationIndividualGridOptions,
  formatClinicalObservationIndividualGridOptions,
  formatDosingRecordIndividualGridOptions,
  // formatModelCharacteristicsOptions,
  // formatModelMoreCharacteristicsOptions,
  formatDosingRecordIndividualGridOptionsForSessionSummaryTable,
  formatBodyWeightChangeSummaryData,
  formatBodyWeightChangeSummaryGridOptions,
  formatBodyWeightChangeIndividualGridOptions,
  formatSurvivalAnalysisDataForChart,
  getFirstOrLastSessionDay,
  formatBodyWeightIndividualGridOptionsForSessionSummaryTable,
  formatTaskMeasurementIndividualGridOptionsForSessionSummaryTable,
  getDataForSessionSummaryTable,
} from '../../utils';
import { createMessageForSQS } from '../../utils.createMessage';

import {
  AUDIT_TRAIL_EVENT_TYPES,
} from '../../../constants'

import { useGraphQL } from '../../../providers/graphql';
import { useAuth } from '../../../providers/auth';
import { useSQS } from '../../../providers/sqs';
import { useColorPanel } from '../../../providers/colorPanel/colorPanel';
import SurvivalAnalysisPanel from './SurvivalAnalysisPanel';
import SessionSummaryPanel from './SessionSummaryPanel';

const dataTypeTabs = ['tumor-volume', 'body-weight', 'body-weight-change'];

const eventTabs = {
  tf: 'total-flux',
  tv: 'tumor-volume',
  tvc: 'tumor-volume-change',
  tvr: 'tumor-volume-right',
  tvl: 'tumor-volume-left',
  bw: 'body-weight',
  bwc: 'body-weight-change',
  co: 'clinical-observations',
  dr: 'dosing-record',
  mo: 'mortality-observations',
  sa: 'survival-analysis',
  ss: 'session-summary',
  mc: 'model-characteristics',
};

const StudyCharts = function StudyCharts({
  study, model, index, projectCode, previewing, filtersLocked, filtering, dataLoaded, previewFilters, released,
}) {

  const {
    getStudyChartsDataAPI,
    modelMetaData,
    createOrUpdateGraphAnnotation,
    listGraphAnnotations,
  } = useGraphQL();
  const { user } = useAuth();
  const {
    fetchStudySchemes, getColors, setRefreshCallback, handleClose,
  } = useColorPanel();

  const [bwiFetching, setBwiFetching] = useState(false);
  const [bwsFetching, setBwsFetching] = useState(false);
  const [bwciFetching, setBwciFetching] = useState(false);
  const [bwcsFetching, setBwcsFetching] = useState(false);
  const [tvciFetching, setTvciFetching] = useState(false);
  const [tvcsFetching, setTvcsFetching] = useState(false);
  const [tvsFetching, setTvsFetching] = useState(false);
  const [blisFetching, setBlisFetching] = useState(false);
  const [tviFetching, setTviFetching] = useState(false);
  const [bliiFetching, setBliiFetching] = useState(false);
  const [tvrsFetching, setTvrsFetching] = useState(false);
  const [tvlsFetching, setTvlsFetching] = useState(false);
  const [tvriFetching, setTvriFetching] = useState(false);
  const [tvliFetching, setTvliFetching] = useState(false);
  const [moFetching, setMoFetching] = useState(false);
  const [coFetching, setCoFetching] = useState(false);
  const [driFetching, setDriFetching] = useState(false);
  // const [mcFetching, setMcFetching] = useState(false);
  const [saFetching, setSAFetching] = useState(false);
  const [excludeUnassigned] = useState(false)
  const [cachedModelData] = useState({})
  const [toggleRefresh, setToggleRefresh] = useState('');

  const [tumorVolumeSummaryData, setTumorVolumeSummaryData] = useState([]);
  const [bliVolumeSummaryData, setBliVolumeSummaryData] = useState([]);
  const [bliMeanSummaryData, setBliMeanSummaryData] = useState([]);
  const [bliVentralSummaryData, setBliVentralSummaryData] = useState([]);
  const [bliDorsalSummaryData, setBliDorsalSummaryData] = useState([]);
  const [bodyWeightSummaryData, setBodyWeightSummaryData] = useState([]);
  const [bodyWeightChangeSummaryData, setBodyWeightChangeSummaryData] = useState([]);
  const [tumorVolumeChangeSummaryData, setTumorVolumeChangeSummaryData] = useState([]);
  const [tumorVolumeIndividualData, setTumorVolumeIndividualData] = useState({});
  const [tumorVolumeChangeIndividualData, setTumorVolumeChangeIndividualData] = useState({});
  const [bliVolumeIndividualData, setBliVolumeIndividualData] = useState({});
  const [bliMeanIndividualData, setBliMeanIndividualData] = useState({});
  const [bliVentralIndividualData, setBliVentralIndividualData] = useState({});
  const [bliDorsalIndividualData, setBliDorsalIndividualData] = useState({});
  const [tumorVolumeRightSummaryData, setTumorVolumeRightSummaryData] = useState([]);
  const [tumorVolumeLeftSummaryData, setTumorVolumeLeftSummaryData] = useState([]);
  const [tumorVolumeRightIndividualData, setTumorVolumeRightIndividualData] = useState({});
  const [tumorVolumeLeftIndividualData, setTumorVolumeLeftIndividualData] = useState({});
  const [bodyWeightIndividualData, setBodyWeightIndividualData] = useState({});

  const [bodyWeightChangeIndividualData, setBodyWeightChangeIndividualData] = useState({});

  const [bodyWeightIndividualGroups, setBodyWeightIndividualGroups] = useState([])

  const [bodyWeightChangeIndividualGroups, setBodyWeightChangeIndividualGroups] = useState([])

  const [mortalityObservationIndividualGroups, setMortalityObservationIndividualGroups] = useState([])
  const [mortalityObservationIndividualGroupsLegend, setMortalityObservationIndividualGroupsLegend] = useState([])

  const [survivalAnalysisChartData, setSurvivalAnalysisChartData] = useState([])
  const [maxStudyDayForSurvivalAnalysis, setMaxStudyDayForSurvivalAnalysis] = useState(0)
  const [minStudyDayForSurvivalAnalysis, setMinStudyDayForSurvivalAnalysis] = useState(0)

  const [clinicalObservationIndividualGroups, setClinicalObservationIndividualGroups] = useState([])
  const [clinicalObservationIndividualGroupsLegend, setClinicalObservationIndividualGroupsLegend] = useState([])
  const [dosingRecordIndividualGroups, setDosingRecordIndividualGroups] = useState([])
  const [tumorGrowthInhibitionSummaryGridOptions, setTumorGrowthInhibitionSummaryGridOptions] = useState({});
  const [tumorGrowthMeanInhibitionSummaryGridOptions, setTumorGrowthMeanInhibitionSummaryGridOptions] = useState({});
  const [tumorGrowthRightMeanInhibitionSummaryGridOptions, setTumorGrowthRightMeanInhibitionSummaryGridOptions] = useState({});
  const [tumorGrowthLeftMeanInhibitionSummaryGridOptions, setTumorGrowthLeftMeanInhibitionSummaryGridOptions] = useState({});
  const [tumorRightGrowthInhibitionSummaryGridOptions, setTumorRightGrowthInhibitionSummaryGridOptions] = useState({});
  const [tumorLeftGrowthInhibitionSummaryGridOptions, setTumorLeftGrowthInhibitionSummaryGridOptions] = useState({});

  const [tumorVolumeSummaryGroups, setTumorVolumeSummaryGroups] = useState([])
  const [bliVolumeSummaryGroups, setBliVolumeSummaryGroups] = useState([])
  const [bliMeanSummaryGroups, setBliMeanSummaryGroups] = useState([])
  const [bliVentralSummaryGroups, setBliVentralSummaryGroups] = useState([])
  const [bliDorsalSummaryGroups, setBliDorsalSummaryGroups] = useState([])
  const [bodyWeightSummaryGroups, setBodyWeightSummaryGroups] = useState([])
  const [bodyWeightChangeSummaryGroups, setBodyWeightChangeSummaryGroups] = useState([])
  const [tumorVolumeChangeSummaryGroups, setTumorVolumeChangeSummaryGroups] = useState([])
  const [survivalAnalysisGroups, setSurvivalAnalysisGroups] = useState([])
  const [tumorVolumeIndividualGroups, setTumorVolumeIndividualGroups] = useState([])
  const [tumorVolumeChangeIndividualGroups, setTumorVolumeChangeIndividualGroups] = useState([])
  const [bliVolumeIndividualGroups, setBliVolumeIndividualGroups] = useState([])
  const [bliMeanIndividualGroups, setBliMeanIndividualGroups] = useState([])
  const [bliVentralIndividualGroups, setBliVentralIndividualGroups] = useState([])
  const [bliDorsalIndividualGroups, setBliDorsalIndividualGroups] = useState([])
  const [bodyWeightSummaryGridOptions, setBodyWeightSummaryGridOptions] = useState({})
  const [bodyWeightChangeSummaryGridOptions, setBodyWeightChangeSummaryGridOptions] = useState({})
  const [tumorVolumeChangeSummaryGridOptions, setTumorVolumeChangeSummaryGridOptions] = useState({})
  const [tumorVolumeSummaryGridOptions, setTumorVolumeSummaryGridOptions] = useState({})
  const [bliVolumeSummaryGridOptions, setBliVolumeSummaryGridOptions] = useState({})
  const [bliMeanSummaryGridOptions, setBliMeanSummaryGridOptions] = useState({})
  const [bliVentralSummaryGridOptions, setBliVentralSummaryGridOptions] = useState({})
  const [bliDorsalSummaryGridOptions, setBliDorsalSummaryGridOptions] = useState({})

  const [tumorVolumeRightSummaryGroups, setTumorVolumeRightSummaryGroups] = useState([])
  const [tumorVolumeLeftSummaryGroups, setTumorVolumeLeftSummaryGroups] = useState([])
  const [tumorVolumeRightIndividualGroups, setTumorVolumeRightIndividualGroups] = useState([])
  const [tumorVolumeLeftIndividualGroups, setTumorVolumeLeftIndividualGroups] = useState([])
  const [tumorVolumeRightSummaryGridOptions, setTumorVolumeRightSummaryGridOptions] = useState({})
  const [tumorVolumeLeftSummaryGridOptions, setTumorVolumeLeftSummaryGridOptions] = useState({})
  const [allGrpRes, setAllGrpRes] = useState({})

  const setIndividualGridApi = useRef(null)
  const setSummaryGridApi = useRef(null)
  const setTGISummaryGridApi = useRef(null)
  const setTRGISummaryGridApi = useRef(null)
  const setTLGISummaryGridApi = useRef(null)
  const summaryGridApi = setSummaryGridApi.current
  const individualGridApi = setIndividualGridApi.current
  const TGIsummaryGridApi = setTGISummaryGridApi.current
  const TRGIsummaryGridApi = setTRGISummaryGridApi.current
  const TLGIsummaryGridApi = setTLGISummaryGridApi.current

  const [selectedTab, setSelectedTab] = useState(eventTabs.tf);

  const [isTumorVolumeTabVisible, setIsTumorVolumeTabVisible] = useState(true);
  const [isTotalFluxTabVisible, setIsTotalFluxTabVisible] = useState(true);
  const [isDualFlank, setIsDualFlank] = useState(false);
  const [isStudyChina, setIsStudyChina] = useState(false);
  const [loadingTabs, setLoadingTabs] = useState(false);

  useEffect(() => {
    if (isTotalFluxTabVisible) {
      setSelectedTab(eventTabs.tf);
    } else if (isTumorVolumeTabVisible) {
      setSelectedTab(eventTabs.tv);
    } else if (isDualFlank) {
      setSelectedTab(eventTabs.tvr);
    } else {
      setSelectedTab(eventTabs.bw);
    }
  }, [isTumorVolumeTabVisible, isDualFlank, isTotalFluxTabVisible]);

  useEffect(() => {
    transformDataForSurvivalAnalysisChart(allGrpRes, modelMetaData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allGrpRes, modelMetaData, toggleRefresh]);

  const { sendMessageToQueueForAudits } = useSQS();

  const [
    tumorVolumeIndividualGridOptions,
    setTumorVolumeIndividualGridOptions,
  ] = useState({})
  const [
    bliVolumeIndividualGridOptions,
    setBliVolumeIndividualGridOptions,
  ] = useState({})
  const [
    bliMeanIndividualGridOptions,
    setBliMeanIndividualGridOptions,
  ] = useState({})
  const [
    bliVentralIndividualGridOptions,
    setBliVentralIndividualGridOptions,
  ] = useState({})

  const [
    bliDorsalIndividualGridOptions,
    setBliDorsalIndividualGridOptions,
  ] = useState({})

  const [
    tumorVolumeRightIndividualGridOptions,
    setTumorVolumeRightIndividualGridOptions,
  ] = useState({})
  const [
    tumorVolumeLeftIndividualGridOptions,
    setTumorVolumeLeftIndividualGridOptions,
  ] = useState({})
  const [
    bodyWeightIndividualGridOptions,
    setBodyWeightIndividualGridOptions,
  ] = useState({})
  const [
    bodyWeightChangeIndividualGridOptions,
    setBodyWeightChangeIndividualGridOptions,
  ] = useState({})
  const [
    tumorVolumeChangeIndividualGridOptions,
    setTumorVolumeChangeIndividualGridOptions,
  ] = useState({})
  const [
    mortalityObservationIndividualGridOptions,
    setMortalityObservationIndividualGridOptions,
  ] = useState({})
  const [
    clinicalObservationIndividualGridOptions,
    setClinicalObservationIndividualGridOptions,
  ] = useState({})
  const [
    dosingRecordIndividualGridOptions,
    setDosingRecordIndividualGridOptions,
  ] = useState({})
  // const [
  //   modelCharacteristicsGridOptions,
  //   setModelCharacteristicsGridOptions,
  // ] = useState({})
  // const [
  //   modelMoreCharacteristicsGridOptions,
  //   setModelMoreCharacteristicsGridOptions,
  // ] = useState({})
  const [
    sessionSummaryGridOptionsForPinnedColumns,
    setSessionSummaryGridOptionsForPinnedColumns,
  ] = useState({})

  const [
    sessionDaysDosingRecords,
    setSessionDaysDosingRecords,
  ] = useState([])
  const [
    dosingRecordRows,
    setDosingRecordRows,
  ] = useState({})
  const [
    dosingStudyDayColumns,
    setDosingStudyDayColumns,
  ] = useState({})
  const [
    allArticles,
    setAllArticles,
  ] = useState({})

  const [
    sessionDaysBliVolume,
    setSessionDaysBliVolume,
  ] = useState([])
  const [
    bliVolumeStudyDayColumns,
    setBliVolumeStudyDayColumns,
  ] = useState({})
  const [
    bliVolumeRows,
    setBliVolumeRows,
  ] = useState([])

  const [
    sessionDaysBliMean,
    setSessionDaysBliMean,
  ] = useState([])
  const [
    bliMeanStudyDayColumns,
    setBliMeanStudyDayColumns,
  ] = useState({})
  const [
    bliMeanRows,
    setBliMeanRows,
  ] = useState([])

  const [
    sessionDaysBliVentral,
    setSessionDaysBliVentral,
  ] = useState([])
  const [
    bliVentralStudyDayColumns,
    setBliVentralStudyDayColumns,
  ] = useState({})
  const [
    bliVentralRows,
    setBliVentralRows,
  ] = useState([])

  const [
    sessionDaysBliDorsal,
    setSessionDaysBliDorsal,
  ] = useState([])
  const [
    bliDorsalStudyDayColumns,
    setBliDorsalStudyDayColumns,
  ] = useState({})
  const [
    bliDorsalRows,
    setBliDorsalRows,
  ] = useState([])

  const [
    sessionDaysTumorVolume,
    setSessionDaysTumorVolume,
  ] = useState([])
  const [
    tumorVolumeStudyDayColumns,
    setTumorVolumeStudyDayColumns,
  ] = useState({})
  const [
    tumorVolumeRows,
    setTumorVolumeRows,
  ] = useState([])

  const [
    sessionDaysTumorVolumeRight,
    setSessionDaysTumorVolumeRight,
  ] = useState([])
  const [
    tumorVolumeRightStudyDayColumns,
    setTumorVolumeRightStudyDayColumns,
  ] = useState({})
  const [
    tumorVolumeRightRows,
    setTumorVolumeRightRows,
  ] = useState([])

  const [
    sessionDaysTumorVolumeLeft,
    setSessionDaysTumorVolumeLeft,
  ] = useState([])
  const [
    tumorVolumeLeftStudyDayColumns,
    setTumorVolumeLeftStudyDayColumns,
  ] = useState({})
  const [
    tumorVolumeLeftRows,
    setTumorVolumeLeftRows,
  ] = useState([])

  const [
    sessionDaysBodyWeight,
    setSessionDaysBodyWeight,
  ] = useState([])
  const [
    bodyWeightStudyDayColumns,
    setBodyWeightStudyDayColumns,
  ] = useState({})
  const [
    bodyWeightRows,
    setBodyWeightRows,
  ] = useState([])

  const [
    sessionDaysBodyWeightChange,
    setSessionDaysBodyWeightChange,
  ] = useState([])
  const [
    bodyWeightChangeStudyDayColumns,
    setBodyWeightChangeStudyDayColumns,
  ] = useState({})
  const [
    bodyWeightChangeRows,
    setBodyWeightChangeRows,
  ] = useState([])

  const [
    sessionDaysTumorVolumeChange,
    setSessionDaysTumorVolumeChange,
  ] = useState([])
  const [
    tumorVolumeChangeStudyDayColumns,
    setTumorVolumeChangeStudyDayColumns,
  ] = useState({})
  const [
    tumorVolumeChangeRows,
    setTumorVolumeChangeRows,
  ] = useState([])

  const [
    sessionDaysClinicalObservation,
    setSessionDaysClinicalObservation,
  ] = useState([])
  const [
    clinicalObservationStudyDayColumns,
    setClinicalObservationStudyDayColumns,
  ] = useState({})
  const [
    clinicalObservationRows,
    setClinicalObservationRows,
  ] = useState([])

  const [
    sessionDaysMortalityObservation,
    setSessionDaysMortalityObservation,
  ] = useState([])
  const [
    mortalityObservationStudyDayColumns,
    setMortalityObservationStudyDayColumns,
  ] = useState({})
  const [
    mortalityObservationRows,
    setMortalityObservationRows,
  ] = useState([])

  const [showTGITable, setShowTGITable] = useState(null);
  const [showTRGITable, setShowTRGITable] = useState(null);
  const [showTLGITable, setShowTLGITable] = useState(null);

  const setIndividualGrid = useCallback((api) => {
    if (!individualGridApi || individualGridApi.destroyCalled) {
      setIndividualGridApi.current = api
    }
  }, [individualGridApi]);
  // eslint-disable-next-line no-unused-vars
  const setSummaryGrid = useCallback((api, tracePath) => {
    if (!setSummaryGridApi.current || setSummaryGridApi.current.destroyCalled) {
      setSummaryGridApi.current = api
    }
  }, [setSummaryGridApi])
  const setTGISummaryGrid = useCallback((api) => {
    if (!TGIsummaryGridApi || TGIsummaryGridApi.destroyCalled) {
      setTGISummaryGridApi.current = api
    }
  }, [TGIsummaryGridApi]);
  const setTRGISummaryGrid = useCallback((api) => {
    if (!TRGIsummaryGridApi || TRGIsummaryGridApi.destroyCalled) {
      setTRGISummaryGridApi.current = api
    }
  }, [TRGIsummaryGridApi]);
  const setTLGISummaryGrid = useCallback((api) => {
    if (!TLGIsummaryGridApi || TLGIsummaryGridApi.destroyCalled) {
      setTLGISummaryGridApi.current = api
    }
  }, [TLGIsummaryGridApi]);

  const transformDataForSurvivalAnalysisChart = (data = {}, modelMetaData = {}) => {
    let allgroups = []
    const groupMap = {}
    const allDataTypes = Object.keys(data);
    const acronym = 'SA';
    // parse all the animal ids from all data types
    allDataTypes.forEach((dataType) => {
      if (data[dataType] && data[dataType][0]) {
        data[dataType][0].groups.forEach((group) => {
          if (group.animals) {
            if (!groupMap[group.group_id]) {
              groupMap[group.group_id] = group
            } else {
              groupMap[group.group_id] = {
                ...groupMap[group.group_id],
                animals: _.uniqBy([...groupMap[group.group_id].animals, ...group.animals], 'subject_id'),
              }
            }
          }
        })
      }
    })
    allgroups = Object.values(groupMap)
    const mortalityGroups = (data.mortalityObservationTaskMeasurementIndividual && data.mortalityObservationTaskMeasurementIndividual[0]) ? data.mortalityObservationTaskMeasurementIndividual[0].groups : []
    const startDay = Number((previewing ? previewFilters.dateRange?.startDay : modelMetaData[model.study_id]?.filters?.dateRange?.startDay) || 0)
    let xMin = getFirstOrLastSessionDay(allgroups)
    xMin = xMin <= startDay ? xMin : startDay
    setMinStudyDayForSurvivalAnalysis(xMin - 1)
    setMaxStudyDayForSurvivalAnalysis(getFirstOrLastSessionDay(allgroups, false) + 1)
    setSurvivalAnalysisGroups(allgroups);
    const colors = getColors(acronym, `${acronym}-summary`);
    setSurvivalAnalysisChartData(formatSurvivalAnalysisDataForChart(
      mortalityGroups.filter((x) => x.animals.length > 0),
      allgroups,
      colors,
      startDay,
    ))
  }

  const refreshGraphs = (randomValue) => {
    setToggleRefresh(randomValue)
  };

  const getGraphQlData = useCallback((id, previewMode = previewing, filters) => {
    setLoadingTabs(true);
    setMoFetching(true)
    setCoFetching(true)
    setDriFetching(true)
    // setMcFetching(true)
    // this works because of the disabled buttons
    setMortalityObservationIndividualGroupsLegend([])
    setMortalityObservationIndividualGroups([])
    setMortalityObservationIndividualGridOptions({})
    setClinicalObservationIndividualGroupsLegend([])
    setDosingRecordIndividualGroups([])
    setDosingRecordIndividualGridOptions({})
    setClinicalObservationIndividualGroups([])
    setClinicalObservationIndividualGridOptions({})
    setBwiFetching(true)
    setBwsFetching(true)
    setBwciFetching(true)
    setBwcsFetching(true)
    setTvciFetching(true)
    setTvcsFetching(true)
    setTvsFetching(true)
    setBlisFetching(true)
    setBliiFetching(true)
    setTvrsFetching(true)
    setTvlsFetching(true)
    setTviFetching(true)
    setTvriFetching(true)
    setTvliFetching(true)
    setShowTGITable(false)
    setShowTRGITable(false)
    setShowTLGITable(false)
    setSAFetching(true)
    // will never use cached filter result since they're locked after submitting, prevent it from overriding preview cache
    /* eslint-disable no-nested-ternary */
    const previewKey = (!_.isEmpty(filters) || previewMode) ? 'filter' : 'normal';
    // dont use cache if filtering so if they cancel and try again with different filters it will refetch
    const cached = previewKey === 'filter' ? null : _.get(cachedModelData, `${id}.${previewKey}`);

    const fetchingListRecord = {
      isGetTumorVolumeTaskMeasurementSummaryAPI: false,
      isGetTumorVolumeChangeTaskMeasurementSummaryAPI: false,
      isGetBliTaskMeasurementSummaryAPI: false,
      isGetBodyWeightTaskMeasurementSummaryAPI: false,
      isGetBodyWeightChangeTaskMeasurementSummaryAPI: false,

      isGetTumorVolumeTaskMeasurementIndividualAPI: false,
      isGetTumorVolumeChangeTaskMeasurementIndividualAPI: false,
      isGetBliTaskMeasurementIndividualAPI: false,
      isGetBodyWeightTaskMeasurementIndividualAPI: false,
      isGetBodyWeightChangeTaskMeasurementIndividualAPI: false,
      isGetMortalityObservationTaskMeasurementIndividualAPI: false,
      isGetObservationTaskMeasurementIndividualAPI: false,
      isGetDosingTaskMeasurementIndividualAPI: false,
    };

    /**
     * Performs a join between two groups based on `group_id` and assign animals from `otherGroup` to `individualGroup` if matched
     * @param {Object} individualGroup - The group you want to have animals within
     * @param {Object} otherGroup - The group that contains animals
     * @returns {Object} returns group that contains group along with animals after the join
     */
    const getAnimalsAssociatedWithIndividualGroups = (individualGroup, otherGroup) => {
      if (!otherGroup) {
        return []
      }
      let groupsWithAnimals = []
      if (individualGroup?.length > 0) {
        groupsWithAnimals = individualGroup[0].groups.map((x) => {
          let groupWithAnimal = {};

          if (otherGroup?.length > 0) {
            const groupFound = otherGroup[0].groups.filter((y) => y.group_id === x.group_id)

            if (groupFound?.length > 0) {
              let { animals } = groupFound[0]
              animals = groupFound[0].animals.sort((a, b) => new Date(b.mortality_date) - new Date(a.mortality_date))
              groupWithAnimal = { ...x, animals }
            }
          }
          return groupWithAnimal;
        })
      }

      return groupsWithAnimals
    }

    const GetTumorVolumeTaskMeasurementSummaryRes = (responseData) => {

      // Note for TGI - As tgi value will be included in TVSGroups via API response from BE, nothing else is needed for TGI. Loop through all study days within groups to determine if BE is calculating / returning TGI data, for TGI Table to conditionally show.
      const { groups } = responseData
      loop1: // eslint-disable-line
      for (const group of groups) { // eslint-disable-line
        for (const studyDay of group.study_day_metrics) { // eslint-disable-line
          if (studyDay.delta_inhibition !== null) {
            setShowTGITable(true)
            break loop1 // eslint-disable-line
          }
        }
        setShowTGITable(false) // FIXME: Unwanted state sets O(#ofGroups) times if no delta_inhibition is found.
      }

      setTumorVolumeSummaryGroups(groups)
      if (groups.length === 0) {
        setTumorVolumeSummaryData([])
      }
      _.set(cachedModelData, `${id}.${previewKey}.tvs`, responseData);
      return groups
    }

    const GetTumorVolumeRightTaskMeasurementSummaryRes = (responseData) => {
      // Note for TGI - As tgi value will be included in TVSGroups via API response from BE, nothing else is needed for TGI. Loop through all study days within groups to determine if BE is calculating / returning TGI data, for TGI Table to conditionally show.

      const { groups } = responseData
      loop1: // eslint-disable-line
      for (const group of groups) { // eslint-disable-line
        for (const studyDay of group.study_day_metrics) { // eslint-disable-line
          if (studyDay.delta_inhibition !== null) {
            setShowTRGITable(true)
            break loop1 // eslint-disable-line
          }
        }
        setShowTRGITable(false)
      }

      setTumorVolumeRightSummaryGroups(groups)
      if (groups.length === 0) {
        setTumorVolumeRightSummaryData([])
      }
      _.set(cachedModelData, `${id}.${previewKey}.tvrs`, responseData);
    }

    const GetTumorVolumeLeftTaskMeasurementSummaryRes = (responseData) => {
      // Note for TGI - As tgi value will be included in TVSGroups via API response from BE, nothing else is needed for TGI. Loop through all study days within groups to determine if BE is calculating / returning TGI data, for TGI Table to conditionally show.

      const { groups } = responseData
      loop1: // eslint-disable-line
      for (const group of groups) { // eslint-disable-line
        for (const studyDay of group.study_day_metrics) { // eslint-disable-line
          if (studyDay.delta_inhibition !== null) {
            setShowTLGITable(true)
            break loop1 // eslint-disable-line
          }
        }
        setShowTLGITable(false)
      }

      setTumorVolumeLeftSummaryGroups(groups)
      if (groups.length === 0) {
        setTumorVolumeLeftSummaryData([])
      }
      _.set(cachedModelData, `${id}.${previewKey}.tvls`, responseData);
    }

    const GetBliVolumeTaskMeasurementSummaryRes = (responseData) => {
      // Note for TGI - As tgi value will be included in TVSGroups via API response from BE, nothing else is needed for TGI. Loop through all study days within groups to determine if BE is calculating / returning TGI data, for TGI Table to conditionally show.

      const { groups } = responseData

      setBliVolumeSummaryGroups(groups)
      if (groups.length === 0) {
        setBliVolumeSummaryData([])
      }
      _.set(cachedModelData, `${id}.${previewKey}.blivos`, responseData);
    }

    const GetBliMeanTaskMeasurementSummaryRes = (responseData) => {
      // Note for TGI - As tgi value will be included in TVSGroups via API response from BE, nothing else is needed for TGI. Loop through all study days within groups to determine if BE is calculating / returning TGI data, for TGI Table to conditionally show.

      const { groups } = responseData

      setBliMeanSummaryGroups(groups)
      if (groups.length === 0) {
        setBliMeanSummaryData([])
      }
      _.set(cachedModelData, `${id}.${previewKey}.blims`, responseData);
    }

    const GetBliVentralTaskMeasurementSummaryRes = (responseData) => {
      // Note for TGI - As tgi value will be included in TVSGroups via API response from BE, nothing else is needed for TGI. Loop through all study days within groups to determine if BE is calculating / returning TGI data, for TGI Table to conditionally show.

      const { groups } = responseData

      setBliVentralSummaryGroups(groups)
      if (groups.length === 0) {
        setBliVentralSummaryData([])
      }
      _.set(cachedModelData, `${id}.${previewKey}.blivs`, responseData);
    }

    const GetBliDorsalTaskMeasurementSummaryRes = (responseData) => {
      // Note for TGI - As tgi value will be included in TVSGroups via API response from BE, nothing else is needed for TGI. Loop through all study days within groups to determine if BE is calculating / returning TGI data, for TGI Table to conditionally show.

      const { groups } = responseData

      setBliDorsalSummaryGroups(groups)
      if (groups.length === 0) {
        setBliDorsalSummaryData([])
      }
      _.set(cachedModelData, `${id}.${previewKey}.blids`, responseData);
    }

    const GetBodyWeightTaskMeasurementSummaryRes = (responseData) => {
      setBodyWeightSummaryGroups(responseData.groups)
      if (responseData.groups.length === 0) {
        setBodyWeightSummaryData([])
      }
      _.set(cachedModelData, `${id}.${previewKey}.bws`, responseData);
    }

    const GetBodyWeightChangeTaskMeasurementSummaryRes = (responseData) => {
      setBodyWeightChangeSummaryGroups(responseData.groups)
      if (responseData.groups.length === 0) {
        setBodyWeightChangeSummaryData([])
      }
      _.set(cachedModelData, `${id}.${previewKey}.bwcs`, responseData);
    }

    const GetTumorVolumeTaskMeasurementIndividualRes = (responseData) => {
      setTumorVolumeIndividualGroups(responseData.groups);
      if (responseData.groups.length === 0) {
        setTumorVolumeIndividualData({})
      }
      _.set(cachedModelData, `${id}.${previewKey}.tvi`, responseData);
    }

    const GetTumorVolumeChangeTaskMeasurementSummaryRes = (responseData) => {
      setTumorVolumeChangeSummaryGroups(responseData.groups)
      if (responseData.groups.length === 0) {
        setTumorVolumeChangeSummaryData([])
      }
      _.set(cachedModelData, `${id}.${previewKey}.tvcs`, responseData);
    }

    const GetTumorVolumeChangeTaskMeasurementIndividualRes = (responseData) => {
      setTumorVolumeChangeIndividualGroups(responseData.groups);
      if (responseData.groups.length === 0) {
        setTumorVolumeChangeIndividualData({})
      }
      _.set(cachedModelData, `${id}.${previewKey}.tvci`, responseData);
    }

    const GetBliVolumeTaskMeasurementIndividualRes = (responseData) => {
      setBliVolumeIndividualGroups(responseData.groups);
      if (responseData.groups.length === 0) {
        setBliVolumeIndividualData({})
      }
      _.set(cachedModelData, `${id}.${previewKey}.blivoi`, responseData);
    }

    const GetBliMeanTaskMeasurementIndividualRes = (responseData) => {
      setBliMeanIndividualGroups(responseData.groups);
      if (responseData.groups.length === 0) {
        setBliMeanIndividualData({})
      }
      _.set(cachedModelData, `${id}.${previewKey}.blimi`, responseData);
    }

    const GetBliVentralTaskMeasurementIndividualRes = (responseData) => {
      setBliVentralIndividualGroups(responseData.groups);
      if (responseData.groups.length === 0) {
        setBliVentralIndividualData({})
      }
      _.set(cachedModelData, `${id}.${previewKey}.blivi`, responseData);
    }

    const GetBliDorsalTaskMeasurementIndividualRes = (responseData) => {
      setLoadingTabs(true);
      setBliDorsalIndividualGroups(responseData.groups);
      if (responseData.groups.length === 0) {
        setBliDorsalIndividualData({})
        setLoadingTabs(false);
      }
      _.set(cachedModelData, `${id}.${previewKey}.blidi`, responseData);
      setLoadingTabs(false);
    }

    const GetTumorVolumeRightTaskMeasurementIndividualRes = (responseData) => {
      setTumorVolumeRightIndividualGroups(responseData.groups);
      if (responseData.groups.length === 0) {
        setTumorVolumeRightIndividualData({})
      }
      _.set(cachedModelData, `${id}.${previewKey}.tvri`, responseData);
    }

    const GetTumorVolumeLeftTaskMeasurementIndividualRes = (responseData) => {
      setTumorVolumeLeftIndividualGroups(responseData.groups);
      if (responseData.groups.length === 0) {
        setTumorVolumeLeftIndividualData({})
      }
      _.set(cachedModelData, `${id}.${previewKey}.tvli`, responseData);
    }

    const GetBodyWeightTaskMeasurementIndividualRes = (responseData) => {
      setBodyWeightIndividualGroups(responseData.groups);
      if (responseData.groups.length === 0) {
        setBodyWeightIndividualData({})
      }
      _.set(cachedModelData, `${id}.${previewKey}.bwi`, responseData);
    }

    const GetBodyWeightChangeTaskMeasurementIndividualRes = (responseData) => {
      setBodyWeightChangeIndividualGroups(responseData.groups);
      if (responseData.groups.length === 0) {
        setBodyWeightChangeIndividualData({})
      }
      _.set(cachedModelData, `${id}.${previewKey}.bwci`, responseData);
    }

    const GetMortalityObservationTaskMeasurementIndividualRes = (responseData) => {
      setMortalityObservationIndividualGroups(responseData.groups)
      setMortalityObservationIndividualGroupsLegend(responseData.legend)
      _.set(cachedModelData, `${id}.${previewKey}.mo`, responseData);
    }

    const GetObservationTaskMeasurementIndividualRes = (responseData) => {
      setClinicalObservationIndividualGroups(responseData.groups)
      setClinicalObservationIndividualGroupsLegend(responseData.legend)
      _.set(cachedModelData, `${id}.${previewKey}.co`, responseData);
    }

    const GetDosingTaskMeasurementIndividualRes = (responseData) => {
      setDosingRecordIndividualGroups(responseData.groups)
      _.set(cachedModelData, `${id}.${previewKey}.dri`, responseData);
    }

    if (cached?.tvs || cached?.tvrs || cached?.tvls) {
      if (cached?.tvs) {
        GetTumorVolumeTaskMeasurementSummaryRes(cached.tvs)
      }
      if (cached?.tvrs) {
        GetTumorVolumeRightTaskMeasurementSummaryRes(cached.tvrs);
      }
      if (cached?.tvls) {
        GetTumorVolumeLeftTaskMeasurementSummaryRes(cached.tvls);
      }
    } else {
      fetchingListRecord.isGetTumorVolumeTaskMeasurementSummaryAPI = true;
    }

    if (cached?.blims || cached?.blivs || cached?.blids || cached?.blivos) {
      if (cached?.blims) {
        GetBliMeanTaskMeasurementSummaryRes(cached.blims);
      }
      if (cached?.blivs) {
        GetBliVentralTaskMeasurementSummaryRes(cached.blivs);
      }
      if (cached?.blids) {
        GetBliDorsalTaskMeasurementSummaryRes(cached.blids);
      }
      if (cached?.blivos) {
        GetBliVolumeTaskMeasurementSummaryRes(cached.blivos);
      }
    } else {
      fetchingListRecord.isGetBliTaskMeasurementSummaryAPI = true;
    }

    if (cached?.bws) {
      GetBodyWeightTaskMeasurementSummaryRes(cached.bws);
    } else {
      fetchingListRecord.isGetBodyWeightTaskMeasurementSummaryAPI = true;
    }

    if (cached?.bwcs) {
      GetBodyWeightChangeTaskMeasurementSummaryRes(cached.bwcs);
    } else {
      fetchingListRecord.isGetBodyWeightChangeTaskMeasurementSummaryAPI = true;
    }

    if (cached?.tvcs) {
      GetTumorVolumeChangeTaskMeasurementSummaryRes(cached.tvcs);
    } else {
      fetchingListRecord.isGetTumorVolumeChangeTaskMeasurementSummaryAPI = true;
    }
    if (cached?.tvci) {
      GetTumorVolumeChangeTaskMeasurementIndividualRes(cached.tvci);
    } else {
      fetchingListRecord.isGetTumorVolumeChangeTaskMeasurementIndividualAPI = true;
    }

    if (cached?.tvi || cached?.tvri || cached?.tvli) {
      if (cached?.tvi) {
        GetTumorVolumeTaskMeasurementIndividualRes(cached.tvi)
      }
      if (cached?.tvri) {
        GetTumorVolumeRightTaskMeasurementIndividualRes(cached.tvri)
      }
      if (cached?.tvli) {
        GetTumorVolumeLeftTaskMeasurementIndividualRes(cached.tvli)
      }

    } else {
      fetchingListRecord.isGetTumorVolumeTaskMeasurementIndividualAPI = true;
    }

    if (cached?.blimi || cached?.blivi || cached?.blidi || cached?.blivoi) {
      if (cached?.blimi) {
        GetBliMeanTaskMeasurementIndividualRes(cached.blimi)
      }
      if (cached?.blivi) {
        GetBliVentralTaskMeasurementIndividualRes(cached.blivi)
      }
      if (cached?.blidi) {
        GetBliDorsalTaskMeasurementIndividualRes(cached.blidi)
      }
      if (cached?.blivoi) {
        GetBliVolumeTaskMeasurementIndividualRes(cached.blivoi)
      }
    } else {
      fetchingListRecord.isGetBliTaskMeasurementIndividualAPI = true;
    }

    if (cached?.bwi) {
      GetBodyWeightTaskMeasurementIndividualRes(cached.bwi);
    } else {
      fetchingListRecord.isGetBodyWeightTaskMeasurementIndividualAPI = true;
    }

    if (cached?.bwci) {
      GetBodyWeightChangeTaskMeasurementIndividualRes(cached.bwci)
    } else {
      fetchingListRecord.isGetBodyWeightChangeTaskMeasurementIndividualAPI = true;
    }

    if (cached?.mo) {
      GetMortalityObservationTaskMeasurementIndividualRes(cached.mo);
    } else {
      fetchingListRecord.isGetMortalityObservationTaskMeasurementIndividualAPI = true;
    }

    if (cached?.co) {
      GetObservationTaskMeasurementIndividualRes(cached.co);
    } else {
      fetchingListRecord.isGetObservationTaskMeasurementIndividualAPI = true;
    }

    if (cached?.dri) {
      GetDosingTaskMeasurementIndividualRes(cached.dri);
    } else {
      fetchingListRecord.isGetDosingTaskMeasurementIndividualAPI = true;
    }

    const isAllValuesFalse = Object.values(fetchingListRecord).every(
      (value) => value === false,
    );
    if (isAllValuesFalse) {
      return;
    }

    if (!isAllValuesFalse) {

      const processResponsaData = (response) => {

        if (previewing) {
          dataLoaded();
        }

        if (response?.data?.tumorVolumeTaskSessionAvailable) {
          const isSessionAvailable = Boolean(response?.data?.tumorVolumeTaskSessionAvailable?.is_task_session_available)
          const isSummaryAvailable = Boolean(response?.data?.tumorVolumeTaskMeasurementSummary?.length)
          setIsTumorVolumeTabVisible(isSessionAvailable && isSummaryAvailable);
          setIsTotalFluxTabVisible(true);
        }

        if (response?.data?.tumorVolumeTaskMeasurementSummary?.length === 1) {
          const tumorVolumeTaskMeasurementSummaryWithAnimals = response.data?.tumorVolumeTaskMeasurementSummary;
          tumorVolumeTaskMeasurementSummaryWithAnimals[0].groups = getAnimalsAssociatedWithIndividualGroups(response.data?.tumorVolumeTaskMeasurementSummary, response.data?.tumorVolumeTaskMeasurementIndividual)
          GetTumorVolumeTaskMeasurementSummaryRes(tumorVolumeTaskMeasurementSummaryWithAnimals[0]);
        } else {
          setTumorVolumeSummaryGroups([]);
          setTumorVolumeSummaryData([]);
        }

        if (response?.data?.tumorVolumeTaskMeasurementSummary?.length === 2) {
          setIsDualFlank(true);
          setIsTumorVolumeTabVisible(false);
          GetTumorVolumeRightTaskMeasurementSummaryRes(response.data.tumorVolumeTaskMeasurementSummary[0]);
          GetTumorVolumeLeftTaskMeasurementSummaryRes(response.data.tumorVolumeTaskMeasurementSummary[1]);
        } else {
          setTumorVolumeRightSummaryGroups([]);
          setTumorVolumeLeftSummaryGroups([]);
          setTumorVolumeRightSummaryData([]);
          setTumorVolumeLeftSummaryData([]);
        }

        if (response?.data?.bodyWeightTaskMeasurementSummary?.length) {
          const bodyWeightTaskMeasurementSummaryWithAnimals = response.data?.bodyWeightTaskMeasurementSummary;
          bodyWeightTaskMeasurementSummaryWithAnimals[0].groups = getAnimalsAssociatedWithIndividualGroups(response.data?.bodyWeightTaskMeasurementSummary, response.data?.bodyWeightTaskMeasurementIndividual)

          GetBodyWeightTaskMeasurementSummaryRes(bodyWeightTaskMeasurementSummaryWithAnimals[0]);
        } else {
          setBodyWeightSummaryGroups([]);
          setBodyWeightSummaryData([]);
        }

        if (response?.data?.bliTaskMeasurementSummary?.length) {
          setIsTotalFluxTabVisible(false);
          response.data.bliTaskMeasurementSummary.forEach((taskSummary) => {

            if (taskSummary?.groups?.length) {
              setIsTotalFluxTabVisible(true);
            }

            if (taskSummary?.task_name === 'Mean') {
              setIsStudyChina(false);
              GetBliMeanTaskMeasurementSummaryRes(taskSummary);
            }
            if (taskSummary?.task_name === 'Ventral') {
              setIsStudyChina(false);
              GetBliVentralTaskMeasurementSummaryRes(taskSummary);
            }
            if (taskSummary?.task_name === 'Dorsal') {
              setIsStudyChina(false);
              GetBliDorsalTaskMeasurementSummaryRes(taskSummary);
            }
            if (taskSummary?.task_name === 'Volume') {
              setIsStudyChina(true);
              GetBliVolumeTaskMeasurementSummaryRes(taskSummary);
            }
          })
        } else {
          setIsTotalFluxTabVisible(false);
          setBliVolumeSummaryGroups([]);
          setBliMeanSummaryGroups([]);
          setBliVentralSummaryGroups([]);
          setBliDorsalSummaryGroups([]);
          setBliVolumeSummaryData([]);
          setBliMeanSummaryData([]);
          setBliVentralSummaryData([]);
          setBliDorsalSummaryData([]);
        }

        if (response?.data?.bodyWeightChangeTaskMeasurementSummary?.length) {
          const bodyWeightChangeTaskMeasurementSummaryWithAnimals = response.data?.bodyWeightTaskMeasurementSummary;
          bodyWeightChangeTaskMeasurementSummaryWithAnimals[0].groups = getAnimalsAssociatedWithIndividualGroups(response.data?.bodyWeightChangeTaskMeasurementSummary, response.data?.bodyWeightChangeTaskMeasurementIndividual)

          GetBodyWeightChangeTaskMeasurementSummaryRes(bodyWeightChangeTaskMeasurementSummaryWithAnimals[0]);
        } else {
          setBodyWeightChangeSummaryGroups([]);
          setBodyWeightChangeSummaryData([]);
        }

        if (response?.data?.tumorVolumeTaskMeasurementIndividual?.length === 1) {
          GetTumorVolumeTaskMeasurementIndividualRes(response.data.tumorVolumeTaskMeasurementIndividual[0]);
        } else {
          setTumorVolumeIndividualGroups([]);
          setTumorVolumeIndividualData({});
        }
        if (response?.data?.tumorVolumeChangeTaskMeasurementSummary?.length === 1) {
          GetTumorVolumeChangeTaskMeasurementSummaryRes(response.data.tumorVolumeChangeTaskMeasurementSummary[0]);
        } else {
          setTumorVolumeChangeSummaryGroups([]);
          setTumorVolumeChangeSummaryData({});
        }

        if (response?.data?.tumorVolumeChangeTaskMeasurementIndividual?.length === 1) {
          GetTumorVolumeChangeTaskMeasurementIndividualRes(response.data.tumorVolumeChangeTaskMeasurementIndividual[0]);
        } else {
          setTumorVolumeChangeIndividualGroups([]);
          setTumorVolumeChangeIndividualData({});
        }

        if (response?.data?.tumorVolumeTaskMeasurementIndividual?.length === 2) {
          GetTumorVolumeRightTaskMeasurementIndividualRes(response.data.tumorVolumeTaskMeasurementIndividual[0]);
          GetTumorVolumeLeftTaskMeasurementIndividualRes(response.data.tumorVolumeTaskMeasurementIndividual[1]);
        } else {
          setTumorVolumeRightIndividualGroups([]);
          setTumorVolumeLeftIndividualGroups([]);
          setTumorVolumeRightIndividualData({});
          setTumorVolumeLeftIndividualData({});
        }

        if (response?.data?.bliTaskMeasurementIndividual?.length) {
          response.data.bliTaskMeasurementIndividual.forEach((taskIndividual) => {
            if (taskIndividual?.task_name === 'Mean') {
              GetBliMeanTaskMeasurementIndividualRes(taskIndividual);
            }
            if (taskIndividual?.task_name === 'Ventral') {
              GetBliVentralTaskMeasurementIndividualRes(taskIndividual);
            }
            if (taskIndividual?.task_name === 'Dorsal') {
              GetBliDorsalTaskMeasurementIndividualRes(taskIndividual);
            }
            if (taskIndividual?.task_name === 'Volume') {
              GetBliVolumeTaskMeasurementIndividualRes(taskIndividual);
            }
          })
        } else {
          setBliVolumeIndividualGroups([]);
          setBliMeanIndividualGroups([]);
          setBliVentralIndividualGroups([]);
          setBliDorsalIndividualGroups([]);
          setBliVolumeIndividualData({});
          setBliMeanIndividualData({});
          setBliVentralIndividualData({});
          setBliDorsalIndividualData({});
        }

        if (response?.data?.bodyWeightTaskMeasurementIndividual?.length) {
          GetBodyWeightTaskMeasurementIndividualRes(response.data.bodyWeightTaskMeasurementIndividual[0]);
        } else {
          setBodyWeightIndividualGroups([]);
          setBodyWeightIndividualData({});
        }

        if (response?.data?.bodyWeightChangeTaskMeasurementIndividual?.length) {
          GetBodyWeightChangeTaskMeasurementIndividualRes(response.data.bodyWeightChangeTaskMeasurementIndividual[0]);
        } else {
          setBodyWeightChangeIndividualGroups([]);
          setBodyWeightChangeIndividualData({});
        }

        if (response?.data?.mortalityObservationTaskMeasurementIndividual?.length) {
          GetMortalityObservationTaskMeasurementIndividualRes(response.data.mortalityObservationTaskMeasurementIndividual[0]);
          setAllGrpRes(response.data)
        } else {
          setMortalityObservationIndividualGroups([]);
          setMortalityObservationIndividualGridOptions({});
          setMortalityObservationIndividualGroupsLegend([]);
        }

        if (response?.data?.observationTaskMeasurementIndividual?.length) {
          GetObservationTaskMeasurementIndividualRes(response.data.observationTaskMeasurementIndividual[0]);
        } else {
          setClinicalObservationIndividualGroups([]);
          setClinicalObservationIndividualGridOptions({});
          setClinicalObservationIndividualGroupsLegend([]);
        }

        if (response?.data?.dosingTaskMeasurementIndividual?.length) {
          GetDosingTaskMeasurementIndividualRes(response.data.dosingTaskMeasurementIndividual[0]);
        } else {
          setDosingRecordIndividualGroups([]);
        }

      }
      const { dosingTimes, survivalAnalysis, ...restF } = filters
      const studyChartDataPromise = getStudyChartsDataAPI(id, previewMode, restF, fetchingListRecord);
      studyChartDataPromise.then((response) => {
        processResponsaData(response);
      })
        .catch((response) => {
          processResponsaData(response);
        }).finally(() => {
          setTvsFetching(false);
          setBlisFetching(false);
          setBliiFetching(false);
          setTvrsFetching(false);
          setTvlsFetching(false);
          setBwsFetching(false);
          setBwcsFetching(false);
          setTvcsFetching(false);
          setTvciFetching(false);
          setTviFetching(false);
          setTvriFetching(false);
          setTvliFetching(false);
          setBwiFetching(false);
          setBwciFetching(false);
          setMoFetching(false);
          setCoFetching(false);
          setDriFetching(false);
          // setMcFetching(false)
          setSAFetching(false) // this uses MO data
          setLoadingTabs(false);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const acronym = 'TV';
    if (tumorVolumeSummaryGroups.length > 0) {
      const colors = getColors(acronym, `${acronym}-summary`);
      let newTumorVolumeData = formatTaskMeasurementSummaryData(tumorVolumeSummaryGroups, acronym, colors)
      if (excludeUnassigned) {
        // eslint-disable-next-line no-unused-vars
        newTumorVolumeData = _.filter(
          newTumorVolumeData,
          (o) => o.name !== 'Unassigned',
        )
      }
      setTumorVolumeSummaryData(newTumorVolumeData)

      // potentially only need to send setters on tumor volume
      const tumorVolumeSummaryGridOptions = formatTaskMeasurementSummaryGridOptions(
        tumorVolumeSummaryGroups,
        setSummaryGrid,
      )
      if (excludeUnassigned) {
        tumorVolumeSummaryGridOptions.rowData = _.filter(
          tumorVolumeSummaryGridOptions.rowData,
          (row) => !row.group.includes('Unassigned'),
        )
      }

      setTumorVolumeSummaryGridOptions(tumorVolumeSummaryGridOptions)
    }
    if (tumorVolumeIndividualGroups.length > 0) {
      const newTumorVolumeIndividualData = formatTaskMeasurementIndividualData(
        tumorVolumeIndividualGroups,
        acronym,
        getColors,
      )
      if (excludeUnassigned) {
        delete newTumorVolumeIndividualData.chartValues.Unassigned
      }
      setTumorVolumeIndividualData(newTumorVolumeIndividualData)

      const tumorVolumeIndividualGridOptions = formatTaskMeasurementIndividualGridOptions(
        tumorVolumeIndividualGroups,
        setIndividualGrid,
      )
      const {
        taskMeasurementRows,
        taskMeasurementStudyDayColumns,
        sessionDaysTaskMeasurement,
      } = formatTaskMeasurementIndividualGridOptionsForSessionSummaryTable(
        tumorVolumeIndividualGroups,
        'Tumor',
        'Volume',
        'TV',
      )
      if (excludeUnassigned) {
        tumorVolumeIndividualGridOptions.rowData = _.filter(
          tumorVolumeIndividualGridOptions.rowData,
          (row) => !row.group.includes('Unassigned'),
        )
      }
      setTumorVolumeIndividualGridOptions(tumorVolumeIndividualGridOptions)
      setTumorVolumeStudyDayColumns(taskMeasurementStudyDayColumns)
      setTumorVolumeRows(taskMeasurementRows)
      setSessionDaysTumorVolume(sessionDaysTaskMeasurement)
    }
  }, [tumorVolumeIndividualGroups, tumorVolumeSummaryGroups, excludeUnassigned, setIndividualGrid, setSummaryGrid, getColors, toggleRefresh])

  useEffect(() => {
    const acronym = 'TVR';
    if (tumorVolumeRightSummaryGroups.length > 0) {
      const colors = getColors(acronym, `${acronym}-summary`);
      let newTumorVolumeRightData = formatTaskMeasurementSummaryData(tumorVolumeRightSummaryGroups, acronym, colors)
      if (excludeUnassigned) {
        newTumorVolumeRightData = _.filter(
          newTumorVolumeRightData,
          (o) => o.name !== 'Unassigned',
        )
      }

      setTumorVolumeRightSummaryData(newTumorVolumeRightData)

      // potentially only need to send setters on tumor volume
      const tumorVolumeRightSummaryGridOptions = formatTaskMeasurementSummaryGridOptions(
        tumorVolumeRightSummaryGroups,
        setSummaryGrid,
      )
      if (excludeUnassigned) {
        tumorVolumeRightSummaryGridOptions.rowData = _.filter(
          tumorVolumeRightSummaryGridOptions.rowData,
          (row) => row.group !== 'Unassigned',
        )
      }

      setTumorVolumeRightSummaryGridOptions(tumorVolumeRightSummaryGridOptions)
    }
    if (tumorVolumeRightIndividualGroups.length > 0) {
      const newTumorVolumeRightIndividualData = formatTaskMeasurementIndividualData(
        tumorVolumeRightIndividualGroups,
        acronym,
        getColors,
      )
      if (excludeUnassigned) {
        delete newTumorVolumeRightIndividualData.chartValues.Unassigned
      }
      setTumorVolumeRightIndividualData(newTumorVolumeRightIndividualData)

      const tumorVolumeRightIndividualGridOptions = formatTaskMeasurementIndividualGridOptions(
        tumorVolumeRightIndividualGroups,
        setIndividualGrid,
      )
      const {
        taskMeasurementRows,
        taskMeasurementStudyDayColumns,
        sessionDaysTaskMeasurement,
      } = formatTaskMeasurementIndividualGridOptionsForSessionSummaryTable(
        tumorVolumeRightIndividualGroups,
        'Tumor Volume',
        'Right',
        'TVR',
      )
      if (excludeUnassigned) {
        tumorVolumeRightIndividualGridOptions.rowData = _.filter(
          tumorVolumeRightIndividualGridOptions.rowData,
          (row) => row.group !== 'Unassigned',
        )
      }
      setTumorVolumeRightIndividualGridOptions(tumorVolumeRightIndividualGridOptions)
      setTumorVolumeRightStudyDayColumns(taskMeasurementStudyDayColumns)
      setTumorVolumeRightRows(taskMeasurementRows)
      setSessionDaysTumorVolumeRight(sessionDaysTaskMeasurement)
    }
  }, [tumorVolumeRightIndividualGroups, tumorVolumeRightSummaryGroups, excludeUnassigned, setIndividualGrid, setSummaryGrid, setTumorVolumeRightIndividualGridOptions, getColors, toggleRefresh])

  useEffect(() => {
    const acronym = 'TVL';
    if (tumorVolumeLeftSummaryGroups.length > 0) {
      const colors = getColors(acronym, `${acronym}-summary`);
      let newTumorVolumeLeftData = formatTaskMeasurementSummaryData(tumorVolumeLeftSummaryGroups, acronym, colors);
      if (excludeUnassigned) {
        newTumorVolumeLeftData = _.filter(
          newTumorVolumeLeftData,
          (o) => o.name !== 'Unassigned',
        )
      }

      setTumorVolumeLeftSummaryData(newTumorVolumeLeftData)

      // potentially only need to send setters on tumor volume
      const tumorVolumeLeftSummaryGridOptions = formatTaskMeasurementSummaryGridOptions(
        tumorVolumeLeftSummaryGroups,
        setSummaryGrid,
      )
      if (excludeUnassigned) {
        tumorVolumeLeftSummaryGridOptions.rowData = _.filter(
          tumorVolumeLeftSummaryGridOptions.rowData,
          (row) => row.group !== 'Unassigned',
        )
      }

      setTumorVolumeLeftSummaryGridOptions(tumorVolumeLeftSummaryGridOptions)
    }
    if (tumorVolumeLeftIndividualGroups.length > 0) {
      const newTumorVolumeLeftIndividualData = formatTaskMeasurementIndividualData(
        tumorVolumeLeftIndividualGroups,
        acronym,
        getColors,
      )
      if (excludeUnassigned) {
        delete newTumorVolumeLeftIndividualData.chartValues.Unassigned
      }
      setTumorVolumeLeftIndividualData(newTumorVolumeLeftIndividualData)

      const tumorVolumeLeftIndividualGridOptions = formatTaskMeasurementIndividualGridOptions(
        tumorVolumeLeftIndividualGroups,
        setIndividualGrid,
      )
      const {
        taskMeasurementRows,
        taskMeasurementStudyDayColumns,
        sessionDaysTaskMeasurement,
      } = formatTaskMeasurementIndividualGridOptionsForSessionSummaryTable(
        tumorVolumeLeftIndividualGroups,
        'Tumor Volume',
        'Left',
        'TVL',
      )
      if (excludeUnassigned) {
        tumorVolumeLeftIndividualGridOptions.rowData = _.filter(
          tumorVolumeLeftIndividualGridOptions.rowData,
          (row) => row.group !== 'Unassigned',
        )
      }
      setTumorVolumeLeftIndividualGridOptions(tumorVolumeLeftIndividualGridOptions)
      setTumorVolumeLeftStudyDayColumns(taskMeasurementStudyDayColumns)
      setTumorVolumeLeftRows(taskMeasurementRows)
      setSessionDaysTumorVolumeLeft(sessionDaysTaskMeasurement)
    }
  }, [tumorVolumeLeftIndividualGroups, tumorVolumeLeftSummaryGroups, excludeUnassigned, setIndividualGrid, setSummaryGrid, getColors, toggleRefresh])

  useEffect(() => {
    const acronym = 'BLIVO';
    if (bliVolumeSummaryGroups.length > 0) {
      const colors = getColors(acronym, `${acronym}-summary`);
      let newbliVolumeData = formatTaskMeasurementSummaryData(bliVolumeSummaryGroups, acronym, colors, 'Total Flux (p/s)')
      if (excludeUnassigned) {
        newbliVolumeData = _.filter(
          newbliVolumeData,
          (o) => !o.name.includes('Unassigned'),
        )
      }

      setBliVolumeSummaryData(newbliVolumeData)

      const bliVolumeSummaryGridOptions = formatTaskMeasurementSummaryGridOptions(
        bliVolumeSummaryGroups,
        setSummaryGrid,
        true,
      )
      if (excludeUnassigned) {
        bliVolumeSummaryGridOptions.rowData = _.filter(
          bliVolumeSummaryGridOptions.rowData,
          (row) => row.group !== 'Unassigned',
        )
      }

      setBliVolumeSummaryGridOptions(bliVolumeSummaryGridOptions)
    }
    if (bliVolumeIndividualGroups.length > 0) {
      const newbliVolumeIndividualData = formatTaskMeasurementIndividualData(
        bliVolumeIndividualGroups,
        acronym,
        getColors,
      )
      if (excludeUnassigned) {
        delete newbliVolumeIndividualData.chartValues.Unassigned
      }
      setBliVolumeIndividualData(newbliVolumeIndividualData)

      const bliVolumeIndividualGridOptions = formatTaskMeasurementIndividualGridOptions(
        bliVolumeIndividualGroups,
        setIndividualGrid,
        true,
      )
      const {
        taskMeasurementRows,
        taskMeasurementStudyDayColumns,
        sessionDaysTaskMeasurement,
      } = formatTaskMeasurementIndividualGridOptionsForSessionSummaryTable(
        bliVolumeIndividualGroups,
        'BLI',
        'Volume',
        'BLIVO',
        true,
      )
      if (excludeUnassigned) {
        bliVolumeIndividualGridOptions.rowData = _.filter(
          bliVolumeIndividualGridOptions.rowData,
          (row) => row.group !== 'Unassigned',
        )
      }
      setBliVolumeIndividualGridOptions(bliVolumeIndividualGridOptions)
      setBliVolumeStudyDayColumns(taskMeasurementStudyDayColumns)
      setBliVolumeRows(taskMeasurementRows)
      setSessionDaysBliVolume(sessionDaysTaskMeasurement)
    }
  }, [bliVolumeIndividualGroups, bliVolumeSummaryGroups, excludeUnassigned, setIndividualGrid, setSummaryGrid, getColors, toggleRefresh])

  useEffect(() => {
    const acronym = 'BLIM';
    if (bliMeanSummaryGroups.length > 0) {
      const colors = getColors(acronym, `${acronym}-summary`);
      let newbliMeanData = formatTaskMeasurementSummaryData(bliMeanSummaryGroups, acronym, colors, 'Mean Total Flux (p/s)')
      if (excludeUnassigned) {
        newbliMeanData = _.filter(
          newbliMeanData,
          (o) => o.name !== 'Unassigned',
        )
      }

      setBliMeanSummaryData(newbliMeanData)

      const bliMeanSummaryGridOptions = formatTaskMeasurementSummaryGridOptions(
        bliMeanSummaryGroups,
        setSummaryGrid,
        true,
      )
      if (excludeUnassigned) {
        bliMeanSummaryGridOptions.rowData = _.filter(
          bliMeanSummaryGridOptions.rowData,
          (row) => row.group !== 'Unassigned',
        )
      }

      setBliMeanSummaryGridOptions(bliMeanSummaryGridOptions)
    }
    if (bliMeanIndividualGroups.length > 0) {
      const newbliMeanIndividualData = formatTaskMeasurementIndividualData(
        bliMeanIndividualGroups,
        acronym,
        getColors,
      )
      if (excludeUnassigned) {
        delete newbliMeanIndividualData.chartValues.Unassigned
      }
      setBliMeanIndividualData(newbliMeanIndividualData)

      const bliMeanIndividualGridOptions = formatTaskMeasurementIndividualGridOptions(
        bliMeanIndividualGroups,
        setIndividualGrid,
        true,
      )
      const {
        taskMeasurementRows,
        taskMeasurementStudyDayColumns,
        sessionDaysTaskMeasurement,
      } = formatTaskMeasurementIndividualGridOptionsForSessionSummaryTable(
        bliMeanIndividualGroups,
        'BLI',
        'Mean',
        'BLIM',
        true,
      )
      if (excludeUnassigned) {
        bliMeanIndividualGridOptions.rowData = _.filter(
          bliMeanIndividualGridOptions.rowData,
          (row) => row.group !== 'Unassigned',
        )
      }
      setBliMeanIndividualGridOptions(bliMeanIndividualGridOptions)
      setBliMeanStudyDayColumns(taskMeasurementStudyDayColumns)
      setBliMeanRows(taskMeasurementRows)
      setSessionDaysBliMean(sessionDaysTaskMeasurement)
    }
  }, [bliMeanIndividualGroups, bliMeanSummaryGroups, excludeUnassigned, setIndividualGrid, setSummaryGrid, getColors, toggleRefresh])

  useEffect(() => {
    const acronym = 'BLIV';
    if (bliVentralSummaryGroups.length > 0) {
      const colors = getColors(acronym, `${acronym}-summary`);
      let newbliVentralData = formatTaskMeasurementSummaryData(bliVentralSummaryGroups, acronym, colors, 'Mean Total Flux (p/s)')
      if (excludeUnassigned) {
        newbliVentralData = _.filter(
          newbliVentralData,
          (o) => o.name !== 'Unassigned',
        )
      }

      setBliVentralSummaryData(newbliVentralData)

      const bliVentralSummaryGridOptions = formatTaskMeasurementSummaryGridOptions(
        bliVentralSummaryGroups,
        setSummaryGrid,
        true,
      )
      if (excludeUnassigned) {
        bliVentralSummaryGridOptions.rowData = _.filter(
          bliVentralSummaryGridOptions.rowData,
          (row) => row.group !== 'Unassigned',
        )
      }

      setBliVentralSummaryGridOptions(bliVentralSummaryGridOptions)
    }
    if (bliVentralIndividualGroups.length > 0) {
      const newbliVentralIndividualData = formatTaskMeasurementIndividualData(
        bliVentralIndividualGroups,
        acronym,
        getColors,
      )
      if (excludeUnassigned) {
        delete newbliVentralIndividualData.chartValues.Unassigned
      }
      setBliVentralIndividualData(newbliVentralIndividualData)

      const bliVentralIndividualGridOptions = formatTaskMeasurementIndividualGridOptions(
        bliVentralIndividualGroups,
        setIndividualGrid,
        true,
      )
      const {
        taskMeasurementRows,
        taskMeasurementStudyDayColumns,
        sessionDaysTaskMeasurement,
      } = formatTaskMeasurementIndividualGridOptionsForSessionSummaryTable(
        bliVentralIndividualGroups,
        'BLI',
        'Ventral',
        'BLIV',
        true,
      )
      if (excludeUnassigned) {
        bliVentralIndividualGridOptions.rowData = _.filter(
          bliVentralIndividualGridOptions.rowData,
          (row) => row.group !== 'Unassigned',
        )
      }
      setBliVentralIndividualGridOptions(bliVentralIndividualGridOptions)
      setBliVentralStudyDayColumns(taskMeasurementStudyDayColumns)
      setBliVentralRows(taskMeasurementRows)
      setSessionDaysBliVentral(sessionDaysTaskMeasurement)
    }
  }, [bliVentralIndividualGroups, bliVentralSummaryGroups, excludeUnassigned, setIndividualGrid, setSummaryGrid, getColors, toggleRefresh])

  useEffect(() => {
    const acronym = 'BLID';
    if (bliDorsalSummaryGroups.length > 0) {
      const colors = getColors(acronym, `${acronym}-summary`);
      let newbliDorsalData = formatTaskMeasurementSummaryData(bliDorsalSummaryGroups, acronym, colors, 'Mean Total Flux (p/s)')
      if (excludeUnassigned) {
        newbliDorsalData = _.filter(
          newbliDorsalData,
          (o) => o.name !== 'Unassigned',
        )
      }

      setBliDorsalSummaryData(newbliDorsalData)

      const bliDorsalSummaryGridOptions = formatTaskMeasurementSummaryGridOptions(
        bliDorsalSummaryGroups,
        setSummaryGrid,
        true,
      )
      if (excludeUnassigned) {
        bliDorsalSummaryGridOptions.rowData = _.filter(
          bliDorsalSummaryGridOptions.rowData,
          (row) => row.group !== 'Unassigned',
        )
      }

      setBliDorsalSummaryGridOptions(bliDorsalSummaryGridOptions)
    }
    if (bliDorsalIndividualGroups?.length > 0) {
      const newbliDorsalIndividualData = formatTaskMeasurementIndividualData(
        bliDorsalIndividualGroups,
        acronym,
        getColors,
      )
      if (excludeUnassigned) {
        delete newbliDorsalIndividualData.chartValues.Unassigned
      }
      setBliDorsalIndividualData(newbliDorsalIndividualData)

      const bliDorsalIndividualGridOptions = formatTaskMeasurementIndividualGridOptions(
        bliDorsalIndividualGroups,
        setIndividualGrid,
        true,
      )
      const {
        taskMeasurementRows,
        taskMeasurementStudyDayColumns,
        sessionDaysTaskMeasurement,
      } = formatTaskMeasurementIndividualGridOptionsForSessionSummaryTable(
        bliDorsalIndividualGroups,
        'BLI',
        'Dorsal',
        'BLID',
        true,
      )
      if (excludeUnassigned) {
        bliDorsalIndividualGridOptions.rowData = _.filter(
          bliDorsalIndividualGridOptions.rowData,
          (row) => row.group !== 'Unassigned',
        )
      }
      setBliDorsalIndividualGridOptions(bliDorsalIndividualGridOptions)
      setBliDorsalStudyDayColumns(taskMeasurementStudyDayColumns)
      setBliDorsalRows(taskMeasurementRows)
      setSessionDaysBliDorsal(sessionDaysTaskMeasurement)
    }
  }, [bliDorsalIndividualGroups, bliDorsalSummaryGroups, excludeUnassigned, setIndividualGrid, setSummaryGrid, getColors, toggleRefresh])

  // Note for TGI - As tgi value will be included in TVSGroups via API response from BE, nothing else is needed for TGI
  useEffect(() => {
    if (tumorVolumeSummaryGroups.length > 0) {
      const tumorGrowthInhibitionSummaryGridOptions = formatTGISummaryGridOptions(
        tumorVolumeSummaryGroups,
        setTGISummaryGrid,
      )
      const tumorGrowthMeanInhibitionSummaryGridOptions = formatTGISummaryGridOptions(
        tumorVolumeSummaryGroups,
        null,
        'mean_inhibition',
      )
      if (excludeUnassigned) {
        tumorGrowthInhibitionSummaryGridOptions.rowData = _.filter(
          tumorGrowthInhibitionSummaryGridOptions.rowData,
          (row) => !row.group.includes('Unassigned'),
        )
        tumorGrowthMeanInhibitionSummaryGridOptions.rowData = _.filter(
          tumorGrowthMeanInhibitionSummaryGridOptions.rowData,
          (row) => !row.group.includes('Unassigned'),
        )
      }

      setTumorGrowthInhibitionSummaryGridOptions(tumorGrowthInhibitionSummaryGridOptions)
      setTumorGrowthMeanInhibitionSummaryGridOptions(tumorGrowthMeanInhibitionSummaryGridOptions)
    }
  }, [tumorVolumeSummaryGroups, excludeUnassigned, setTGISummaryGrid])

  useEffect(() => {
    if (tumorVolumeRightSummaryGroups.length > 0) {

      const tumorRightGrowthInhibitionSummaryGridOptions = formatTGISummaryGridOptions(
        tumorVolumeRightSummaryGroups,
        setTRGISummaryGrid,
      )
      const tumorGrowthRightMeanInhibitionSummaryGridOptions = formatTGISummaryGridOptions(
        tumorVolumeRightSummaryGroups,
        null,
        'mean_inhibition',
      )
      if (excludeUnassigned) {
        tumorRightGrowthInhibitionSummaryGridOptions.rowData = _.filter(
          tumorRightGrowthInhibitionSummaryGridOptions.rowData,
          (row) => row.group !== 'Unassigned',
        )
        tumorGrowthRightMeanInhibitionSummaryGridOptions.rowData = _.filter(
          tumorGrowthRightMeanInhibitionSummaryGridOptions.rowData,
          (row) => !row.group.includes('Unassigned'),
        )
      }

      setTumorRightGrowthInhibitionSummaryGridOptions(tumorRightGrowthInhibitionSummaryGridOptions)
      setTumorGrowthRightMeanInhibitionSummaryGridOptions(tumorGrowthRightMeanInhibitionSummaryGridOptions)
    }
  }, [tumorVolumeRightSummaryGroups, excludeUnassigned, setTRGISummaryGrid])

  useEffect(() => {
    if (tumorVolumeLeftSummaryGroups.length > 0) {

      const tumorLeftGrowthInhibitionSummaryGridOptions = formatTGISummaryGridOptions(
        tumorVolumeLeftSummaryGroups,
        setTLGISummaryGrid,
      )
      const tumorGrowthLeftMeanInhibitionSummaryGridOptions = formatTGISummaryGridOptions(
        tumorVolumeLeftSummaryGroups,
        null,
        'mean_inhibition',
      )
      if (excludeUnassigned) {
        tumorLeftGrowthInhibitionSummaryGridOptions.rowData = _.filter(
          tumorLeftGrowthInhibitionSummaryGridOptions.rowData,
          (row) => row.group !== 'Unassigned',
        )
        tumorGrowthLeftMeanInhibitionSummaryGridOptions.rowData = _.filter(
          tumorGrowthLeftMeanInhibitionSummaryGridOptions.rowData,
          (row) => !row.group.includes('Unassigned'),
        )
      }

      setTumorLeftGrowthInhibitionSummaryGridOptions(tumorLeftGrowthInhibitionSummaryGridOptions)
      setTumorGrowthLeftMeanInhibitionSummaryGridOptions(tumorGrowthLeftMeanInhibitionSummaryGridOptions)

    }
  }, [tumorVolumeLeftSummaryGroups, excludeUnassigned, setTLGISummaryGrid])

  useEffect(() => {
    const acronym = 'BW';
    if (bodyWeightSummaryGroups.length > 0) {
      const colors = getColors(acronym, `${acronym}-summary`);
      let newBodyWeightSummaryData = formatTaskMeasurementSummaryData(bodyWeightSummaryGroups, acronym, colors);
      if (excludeUnassigned) {
        newBodyWeightSummaryData = _.filter(
          newBodyWeightSummaryData,
          (o) => o.name !== 'Unassigned',
        )
      }
      setBodyWeightSummaryData(newBodyWeightSummaryData)

      const bodyWeightSummaryGridOptions = formatTaskMeasurementSummaryGridOptions(
        bodyWeightSummaryGroups,
        setSummaryGrid,
      )
      if (excludeUnassigned) {
        bodyWeightSummaryGridOptions.rowData = _.filter(
          bodyWeightSummaryGridOptions.rowData,
          (row) => !row.group.includes('Unassigned'),
        )
      }
      setBodyWeightSummaryGridOptions(bodyWeightSummaryGridOptions)
    }
    if (bodyWeightIndividualGroups.length > 0) {
      const newBodyWeightIndividualData = formatTaskMeasurementIndividualData(bodyWeightIndividualGroups, acronym, getColors)
      if (excludeUnassigned) {
        delete newBodyWeightIndividualData.chartValues.Unassigned
      }
      setBodyWeightIndividualData(newBodyWeightIndividualData)

      const bodyWeightIndividualGridOptions = formatTaskMeasurementIndividualGridOptions(
        bodyWeightIndividualGroups,
        setIndividualGrid,
      )
      const {
        gridOptions: bodyWeightGridOptionsForSessionSummaryTable,
        bodyWeightRows,
        bodyWeightStudyDayColumns,
        sessionDaysBodyWeight,
      } = formatBodyWeightIndividualGridOptionsForSessionSummaryTable(
        bodyWeightIndividualGroups,
        setIndividualGrid,
      )
      if (excludeUnassigned) {
        bodyWeightIndividualGridOptions.rowData = _.filter(
          bodyWeightIndividualGridOptions.rowData,
          (row) => !row.group.includes('Unassigned'),
        )
      }
      setBodyWeightIndividualGridOptions(bodyWeightIndividualGridOptions)
      setSessionSummaryGridOptionsForPinnedColumns(bodyWeightGridOptionsForSessionSummaryTable)
      setBodyWeightStudyDayColumns(bodyWeightStudyDayColumns)
      setBodyWeightRows(bodyWeightRows)
      setSessionDaysBodyWeight(sessionDaysBodyWeight)
    }
  }, [bodyWeightIndividualGroups, bodyWeightSummaryGroups, excludeUnassigned, setSummaryGrid, setIndividualGrid, getColors, toggleRefresh])

  useEffect(() => {
    const acronym = 'BWC'
    if (bodyWeightChangeSummaryGroups.length > 0) {
      const colors = getColors(acronym, `${acronym}-summary`);
      let newBodyWeightChangeSummaryData = formatBodyWeightChangeSummaryData(
        bodyWeightChangeSummaryGroups,
        acronym,
        colors,
      )
      if (excludeUnassigned) {
        newBodyWeightChangeSummaryData = _.filter(
          newBodyWeightChangeSummaryData,
          (o) => o.name !== 'Unassigned',
        )
      }
      setBodyWeightChangeSummaryData(newBodyWeightChangeSummaryData)

      const bodyWeightChangeSummaryGridOptions = formatBodyWeightChangeSummaryGridOptions(
        bodyWeightChangeSummaryGroups,
        setSummaryGrid,
      )
      if (excludeUnassigned) {
        bodyWeightChangeSummaryGridOptions.rowData = _.filter(
          bodyWeightChangeSummaryGridOptions.rowData,
          (row) => !row.group.includes('Unassigned'),
        )
      }
      setBodyWeightChangeSummaryGridOptions(bodyWeightChangeSummaryGridOptions)
    }
    if (bodyWeightChangeIndividualGroups.length > 0) {
      const newBodyWeightChangeIndividualData = formatTaskMeasurementIndividualData(bodyWeightChangeIndividualGroups, acronym, getColors)
      if (excludeUnassigned) {
        delete newBodyWeightChangeIndividualData.chartValues.Unassigned
      }
      setBodyWeightChangeIndividualData(newBodyWeightChangeIndividualData)

      const bodyWeightChangeIndividualGridOptions = formatBodyWeightChangeIndividualGridOptions(
        bodyWeightChangeIndividualGroups,
        setIndividualGrid,
      )
      const {
        rows,
        columns,
        sessionDays,
      } = getDataForSessionSummaryTable(
        bodyWeightChangeIndividualGroups,
        [],
        'Body Weight',
        'Change',
        'BWC',
      )
      if (excludeUnassigned) {
        bodyWeightChangeIndividualGridOptions.rowData = _.filter(
          bodyWeightChangeIndividualGridOptions.rowData,
          (row) => !row.group.includes('Unassigned'),
        )
      }
      setBodyWeightChangeIndividualGridOptions(bodyWeightChangeIndividualGridOptions)
      setBodyWeightChangeStudyDayColumns(columns)
      setBodyWeightChangeRows(rows)
      setSessionDaysBodyWeightChange(sessionDays)
    }
  }, [bodyWeightChangeIndividualGroups, bodyWeightChangeSummaryGroups, excludeUnassigned, setIndividualGrid, setSummaryGrid, getColors, toggleRefresh]);

  useEffect(() => {
    const acronym = 'TVC'
    if (tumorVolumeChangeSummaryGroups.length > 0) {
      const colors = getColors(acronym, `${acronym}-summary`);
      let newTumorVolumeChangeSummaryData = formatBodyWeightChangeSummaryData(
        tumorVolumeChangeSummaryGroups,
        acronym,
        colors,
      )
      if (excludeUnassigned) {
        newTumorVolumeChangeSummaryData = _.filter(
          newTumorVolumeChangeSummaryData,
          (o) => o.name !== 'Unassigned',
        )
      }
      setTumorVolumeChangeSummaryData(newTumorVolumeChangeSummaryData)

      const tumorVolumeChangeSummaryGridOptions = formatBodyWeightChangeSummaryGridOptions(
        tumorVolumeChangeSummaryGroups,
        setSummaryGrid,
      )
      if (excludeUnassigned) {
        tumorVolumeChangeSummaryGridOptions.rowData = _.filter(
          tumorVolumeChangeSummaryGridOptions.rowData,
          (row) => !row.group.includes('Unassigned'),
        )
      }
      setTumorVolumeChangeSummaryGridOptions(tumorVolumeChangeSummaryGridOptions)
    }
    if (tumorVolumeChangeIndividualGroups.length > 0) {
      const newTumorVolumeChangeIndividualData = formatTaskMeasurementIndividualData(tumorVolumeChangeIndividualGroups, acronym, getColors)
      if (excludeUnassigned) {
        delete newTumorVolumeChangeIndividualData.chartValues.Unassigned
      }
      setTumorVolumeChangeIndividualData(newTumorVolumeChangeIndividualData)

      const tumorVolumeChangeIndividualGridOptions = formatBodyWeightChangeIndividualGridOptions(
        tumorVolumeChangeIndividualGroups,
        setIndividualGrid,
      )
      const {
        rows,
        columns,
        sessionDays,
      } = getDataForSessionSummaryTable(
        tumorVolumeChangeIndividualGroups,
        [],
        'Tumor Volume',
        'Change',
        'TVC',
      )
      if (excludeUnassigned) {
        tumorVolumeChangeIndividualGridOptions.rowData = _.filter(
          tumorVolumeChangeIndividualGridOptions.rowData,
          (row) => !row.group.includes('Unassigned'),
        )
      }
      setTumorVolumeChangeIndividualGridOptions(tumorVolumeChangeIndividualGridOptions)
      setTumorVolumeChangeStudyDayColumns(columns)
      setTumorVolumeChangeRows(rows)
      setSessionDaysTumorVolumeChange(sessionDays)
    }
  }, [tumorVolumeChangeIndividualGroups, tumorVolumeChangeSummaryGroups, excludeUnassigned, setIndividualGrid, setSummaryGrid, getColors, toggleRefresh]);

  useEffect(() => {
    if (mortalityObservationIndividualGroups.length > 0 && mortalityObservationIndividualGroupsLegend.length > 0 && _.isEmpty(mortalityObservationIndividualGridOptions)) {
      const mortalityObservationGridOptions = formatMortalityObservationIndividualGridOptions(
        mortalityObservationIndividualGroups,
        setIndividualGrid,
        mortalityObservationIndividualGroupsLegend,
      )
      const {
        rows,
        columns,
        sessionDays,
      } = getDataForSessionSummaryTable(
        mortalityObservationIndividualGroups,
        mortalityObservationIndividualGroupsLegend,
        'Mortality',
        'Observation',
        'MO',
      )
      if (excludeUnassigned) {
        mortalityObservationGridOptions.rowData = _.filter(
          mortalityObservationGridOptions.rowData,
          (row) => !row.group.includes('Unassigned'),
        )
      }
      setMortalityObservationIndividualGridOptions(mortalityObservationGridOptions);
      setMortalityObservationStudyDayColumns(columns)
      setMortalityObservationRows(rows)
      setSessionDaysMortalityObservation(sessionDays)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mortalityObservationIndividualGroups, mortalityObservationIndividualGroupsLegend, excludeUnassigned])

  useEffect(() => {
    if (clinicalObservationIndividualGroups.length > 0 && clinicalObservationIndividualGroupsLegend.length > 0 && _.isEmpty(clinicalObservationIndividualGridOptions)) {
      const clinicalObservationGridOptions = formatClinicalObservationIndividualGridOptions(
        clinicalObservationIndividualGroups,
        setIndividualGrid,
        clinicalObservationIndividualGroupsLegend,
      )
      const {
        columns,
        rows,
        sessionDays,

      } = getDataForSessionSummaryTable(
        clinicalObservationIndividualGroups,
        clinicalObservationIndividualGroupsLegend,
        'Clinical',
        'Observations',
        'CO',
      )
      if (excludeUnassigned) {
        clinicalObservationGridOptions.rowData = _.filter(
          clinicalObservationGridOptions.rowData,
          (row) => !row.group.includes('Unassigned'),
        )
      }
      setClinicalObservationIndividualGridOptions(clinicalObservationGridOptions)
      setClinicalObservationStudyDayColumns(columns)
      setClinicalObservationRows(rows)
      setSessionDaysClinicalObservation(sessionDays)
      // if (clinicalObservationIndividualGridOptions && clinicalObservationIndividualGridOptions.columnDefs) {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clinicalObservationIndividualGroups, clinicalObservationIndividualGroupsLegend, excludeUnassigned])

  useEffect(() => {
    if (dosingRecordIndividualGroups.length > 0) {
      const excludeDosingTimes = previewFilters.dosingTimes === 'Exclude' || (previewFilters.dosingTimes !== 'Include' && modelMetaData && modelMetaData[model.study_id]?.filters?.dosingTimes === 'Exclude')
      const dosingRecordIndividualGridOptions = formatDosingRecordIndividualGridOptions(
        dosingRecordIndividualGroups,
        setIndividualGrid,
        excludeDosingTimes,
      )
      const {
        sessionDaysDosingRecords,
        dosingStudyDayColumns,
        dosingRecordRows,
        allArticles,
      } = formatDosingRecordIndividualGridOptionsForSessionSummaryTable(
        dosingRecordIndividualGroups,
        excludeDosingTimes,
      )
      if (excludeUnassigned) {
        dosingRecordIndividualGridOptions.rowData = _.filter(
          dosingRecordIndividualGridOptions.rowData,
          (row) => !row.group.includes('Unassigned'),
        )
      }
      setDosingRecordIndividualGridOptions(dosingRecordIndividualGridOptions)
      setSessionDaysDosingRecords(sessionDaysDosingRecords)
      setDosingRecordRows(dosingRecordRows)
      setDosingStudyDayColumns(dosingStudyDayColumns)
      setAllArticles(allArticles)
    }
  }, [dosingRecordIndividualGroups, excludeUnassigned, setIndividualGrid, previewFilters.dosingTimes, modelMetaData, model.study_id]);

  // useEffect(() => {
  //   const modelCharacteristicsGridOptions = formatModelCharacteristicsOptions()
  //   setModelCharacteristicsGridOptions(modelCharacteristicsGridOptions)
  //   const modelMoreCharacteristicsGridOptions = formatModelMoreCharacteristicsOptions()
  //   setModelMoreCharacteristicsGridOptions(modelMoreCharacteristicsGridOptions)
  // }, []);
  // TODO Need to check if this useeffect is needed in case of preview chart display
  useEffect(() => {
    if (!_.isEmpty(model)) {
      if (previewing && !_.isEmpty(previewFilters)) {
        setRefreshCallback(refreshGraphs);
        getGraphQlData(model.study_id, previewing, previewFilters, modelMetaData);
      } if (!previewing && !filtering) {
        setRefreshCallback(refreshGraphs);
        getGraphQlData(model.study_id, !_.isEmpty(previewFilters), previewFilters, modelMetaData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model, previewFilters]);

  useEffect(() => {
    if (released) {
      _.set(cachedModelData, `${model.study_id}.normal`, _.get(cachedModelData, `${model.study_id}.filter`));
      setRefreshCallback(refreshGraphs);
      getGraphQlData(model.study_id, false, previewFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [released, model]);

  useEffect(() => {
    fetchStudySchemes(projectCode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectCode]);

  useEffect(() => {
    handleClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtering, previewing]);

  useEffect(() => {
    if (individualGridApi && !individualGridApi.destroyCalled && selectedTab === eventTabs.bw) {
      individualGridApi.setRowData(bodyWeightIndividualGridOptions.rowData)
      individualGridApi.setColumnDefs(bodyWeightIndividualGridOptions.columnDefs)
    }
  }, [bodyWeightIndividualGridOptions, individualGridApi, selectedTab])

  useEffect(() => {
    if (summaryGridApi && !summaryGridApi.destroyCalled && selectedTab === eventTabs.bw) {
      summaryGridApi.setRowData(bodyWeightSummaryGridOptions.rowData)
      summaryGridApi.setColumnDefs(bodyWeightSummaryGridOptions.columnDefs)
    }
  }, [bodyWeightSummaryGridOptions, selectedTab, summaryGridApi])

  useEffect(() => {
    if (individualGridApi && !individualGridApi.destroyCalled && selectedTab === eventTabs.bwc) {
      individualGridApi.setRowData(bodyWeightChangeIndividualGridOptions.rowData)
      individualGridApi.setColumnDefs(bodyWeightChangeIndividualGridOptions.columnDefs)
    }
  }, [bodyWeightChangeIndividualGridOptions, individualGridApi, selectedTab])

  useEffect(() => {
    if (summaryGridApi && !summaryGridApi.destroyCalled && selectedTab === eventTabs.bwc) {
      summaryGridApi.setRowData(bodyWeightChangeSummaryGridOptions.rowData)
      summaryGridApi.setColumnDefs(bodyWeightChangeSummaryGridOptions.columnDefs)
    }
  }, [bodyWeightChangeSummaryGridOptions, selectedTab, summaryGridApi])

  useEffect(() => {
    if (summaryGridApi && !summaryGridApi.destroyCalled && selectedTab === eventTabs.tv) {
      summaryGridApi.setRowData(tumorVolumeSummaryGridOptions.rowData)
      summaryGridApi.setColumnDefs(tumorVolumeSummaryGridOptions.columnDefs)
    }
  }, [selectedTab, summaryGridApi, tumorVolumeSummaryGridOptions])

  useEffect(() => {
    if (summaryGridApi && !summaryGridApi.destroyCalled && selectedTab === eventTabs.tvr) {
      summaryGridApi.setRowData(tumorVolumeRightSummaryGridOptions.rowData)
      summaryGridApi.setColumnDefs(tumorVolumeRightSummaryGridOptions.columnDefs)
    }
  }, [selectedTab, summaryGridApi, tumorVolumeRightSummaryGridOptions])

  useEffect(() => {
    if (summaryGridApi && !summaryGridApi.destroyCalled && selectedTab === eventTabs.tvl) {
      summaryGridApi.setRowData(tumorVolumeLeftSummaryGridOptions.rowData)
      summaryGridApi.setColumnDefs(tumorVolumeLeftSummaryGridOptions.columnDefs)
    }
  }, [selectedTab, summaryGridApi, tumorVolumeLeftSummaryGridOptions])

  useEffect(() => {
    if (TGIsummaryGridApi && !TGIsummaryGridApi.destroyCalled && selectedTab === eventTabs.tv) {
      TGIsummaryGridApi.setRowData(tumorGrowthInhibitionSummaryGridOptions.rowData)
      TGIsummaryGridApi.setColumnDefs(tumorGrowthInhibitionSummaryGridOptions.columnDefs)
    }
  }, [TGIsummaryGridApi, selectedTab, tumorGrowthInhibitionSummaryGridOptions])

  useEffect(() => {
    if (TRGIsummaryGridApi && !TRGIsummaryGridApi.destroyCalled && selectedTab === eventTabs.tvr) {
      TRGIsummaryGridApi.setRowData(tumorRightGrowthInhibitionSummaryGridOptions.rowData)
      TRGIsummaryGridApi.setColumnDefs(tumorRightGrowthInhibitionSummaryGridOptions.columnDefs)
    }
  }, [TRGIsummaryGridApi, selectedTab, tumorRightGrowthInhibitionSummaryGridOptions])

  useEffect(() => {
    if (TLGIsummaryGridApi && !TLGIsummaryGridApi.destroyCalled && selectedTab === eventTabs.tvl) {
      TLGIsummaryGridApi.setRowData(tumorLeftGrowthInhibitionSummaryGridOptions.rowData)
      TLGIsummaryGridApi.setColumnDefs(tumorLeftGrowthInhibitionSummaryGridOptions.columnDefs)
    }
  }, [TLGIsummaryGridApi, selectedTab, tumorLeftGrowthInhibitionSummaryGridOptions])

  useEffect(() => {
    if (individualGridApi && !individualGridApi.destroyCalled && selectedTab === eventTabs.tv) {
      individualGridApi.setRowData(tumorVolumeIndividualGridOptions.rowData)
      individualGridApi.setColumnDefs(tumorVolumeIndividualGridOptions.columnDefs)
    }
  }, [individualGridApi, selectedTab, tumorVolumeIndividualGridOptions])

  useEffect(() => {
    if (individualGridApi && !individualGridApi.destroyCalled && selectedTab === eventTabs.tvr) {
      individualGridApi.setRowData(tumorVolumeRightIndividualGridOptions.rowData)
      individualGridApi.setColumnDefs(tumorVolumeRightIndividualGridOptions.columnDefs)
    }
  }, [individualGridApi, selectedTab, tumorVolumeRightIndividualGridOptions])

  useEffect(() => {
    if (individualGridApi && !individualGridApi.destroyCalled && selectedTab === eventTabs.tvl) {
      individualGridApi.setRowData(tumorVolumeLeftIndividualGridOptions.rowData)
      individualGridApi.setColumnDefs(tumorVolumeLeftIndividualGridOptions.columnDefs)
    }
  }, [individualGridApi, selectedTab, tumorVolumeLeftIndividualGridOptions])

  useEffect(() => {
    if (individualGridApi && !individualGridApi.destroyCalled && selectedTab === eventTabs.mo) {
      individualGridApi.setColumnDefs(mortalityObservationIndividualGridOptions.columnDefs)
      individualGridApi.setRowData(mortalityObservationIndividualGridOptions.rowData)
    }
  }, [individualGridApi, mortalityObservationIndividualGridOptions, selectedTab])

  useEffect(() => {
    if (individualGridApi && !individualGridApi.destroyCalled && selectedTab === eventTabs.co) {
      individualGridApi.setColumnDefs(clinicalObservationIndividualGridOptions.columnDefs)
      individualGridApi.setRowData(clinicalObservationIndividualGridOptions.rowData)
    }
  }, [clinicalObservationIndividualGridOptions, individualGridApi, selectedTab])

  useEffect(() => {
    if (individualGridApi && !individualGridApi.destroyCalled && selectedTab === eventTabs.dr) {
      individualGridApi.setRowData(dosingRecordIndividualGridOptions.rowData)
      individualGridApi.setColumnDefs(dosingRecordIndividualGridOptions.columnDefs)
    }
  }, [dosingRecordIndividualGridOptions, individualGridApi, selectedTab])

  useEffect(() => {
    if (mortalityObservationIndividualGridOptions.rowData && mortalityObservationIndividualGridOptions.rowData.length) {
      let legendsKeys = {}
      mortalityObservationIndividualGridOptions.rowData.forEach((item) => {
        const keys = Object.keys(item).filter((key) => key.includes('studyDay'))
        keys.forEach((key) => {
          legendsKeys[item[key]] = item[key]
        })
      })
      legendsKeys = Object.keys(legendsKeys)
      const legends = mortalityObservationIndividualGroupsLegend.filter((leg) => legendsKeys.includes(leg.key))
      setMortalityObservationIndividualGroupsLegend(legends)
    }
    // eslint-disable-next-line
  }, [mortalityObservationIndividualGridOptions])

  useEffect(() => {
    if (clinicalObservationIndividualGridOptions.rowData && clinicalObservationIndividualGridOptions.rowData.length) {
      let legendsKeys = {}
      clinicalObservationIndividualGridOptions.rowData.forEach((item) => {
        const keys = Object.keys(item).filter((key) => key.includes('studyDay'))
        keys.forEach((key) => {
          if (item[key] && item[key].includes(', ')) {
            const subLegends = item[key].split(', ')
            subLegends.forEach((leg) => {
              legendsKeys[leg] = leg
            })
          } else if (item[key]) {
            legendsKeys[item[key]] = item[key]
          }
        })
      })
      legendsKeys = Object.keys(legendsKeys)
      const legends = clinicalObservationIndividualGroupsLegend.filter((leg) => legendsKeys.includes(leg.key))
      setClinicalObservationIndividualGroupsLegend(legends)
    }
    // eslint-disable-next-line
  }, [clinicalObservationIndividualGridOptions])

  const dataViewChangeAudit = async (field) => {
    if (user && user.id) {
      const messageBody = await createMessageForSQS({
        eventType: AUDIT_TRAIL_EVENT_TYPES.DATA_VIEW_SWITCH,
        userId: user.id,
        projectId: projectCode,
        companyName: study.company,
        attributes: [{ modelId: model.study_model }, { dataView: selectedTab }, { dataType: field }],
      })
      sendMessageToQueueForAudits(messageBody)
    }
  };

  const chartExportAudit = (field) => async () => {
    if (user && user.id) {
      const messageBody = await createMessageForSQS({
        eventType: AUDIT_TRAIL_EVENT_TYPES.CHART_EXPORT,
        userId: user.id,
        projectId: projectCode,
        companyName: study.company,
        attributes: [{ modelId: model.study_model }, { dataView: selectedTab }, { dataType: field }],
      })
      sendMessageToQueueForAudits(messageBody)
    }
  };
  const tabsList = [
    {
      eventKey: eventTabs.tf,
      title: 'Total Flux',
      panel: (
        <TotalFluxPanel
          bliVolumeIndividualData={bliVolumeIndividualData}
          bliMeanIndividualData={bliMeanIndividualData}
          bliVentralIndividualData={bliVentralIndividualData}
          bliDorsalIndividualData={bliDorsalIndividualData}
          bliVolumeIndividualGridOptions={bliVolumeIndividualGridOptions}
          bliMeanIndividualGridOptions={bliMeanIndividualGridOptions}
          bliVentralIndividualGridOptions={bliVentralIndividualGridOptions}
          bliDorsalIndividualGridOptions={bliDorsalIndividualGridOptions}
          bliVolumetotalFluxData={bliVolumeSummaryData}
          bliMeantotalFluxData={bliMeanSummaryData}
          bliVentraltotalFluxData={bliVentralSummaryData}
          bliDorsaltotalFluxData={bliDorsalSummaryData}
          bliVolumetotalFluxSummaryGroups={bliVolumeSummaryGroups}
          bliMeantotalFluxSummaryGroups={bliMeanSummaryGroups}
          bliVentraltotalFluxSummaryGroups={bliVentralSummaryGroups}
          bliDorsaltotalFluxSummaryGroups={bliDorsalSummaryGroups}
          bliVolumeSummaryGridOptions={bliVolumeSummaryGridOptions}
          bliMeanSummaryGridOptions={bliMeanSummaryGridOptions}
          bliVentralSummaryGridOptions={bliVentralSummaryGridOptions}
          bliDorsalSummaryGridOptions={bliDorsalSummaryGridOptions}
          totalFluxSummaryFetching={blisFetching}
          totalFluxIndividualFetching={bliiFetching}
          showTable={!previewing || filtersLocked}
          dataViewChangeAudit={dataViewChangeAudit}
          chartExportAudit={chartExportAudit}
          selectedTab={selectedTab}
          model={model}
          isStudyChina={isStudyChina}
          studyId={projectCode}
        />
      ),
    },
    {
      eventKey: eventTabs.tv,
      title: 'Tumor Volume',
      panel: (
        <TumorVolumePanel
          individualTumorVolumeData={tumorVolumeIndividualData}
          individualTumorVolumeGridOptions={tumorVolumeIndividualGridOptions}
          tumorVolumeData={tumorVolumeSummaryData}
          tumorVolumeSummaryGroups={tumorVolumeSummaryGroups}
          tumorVolumeGridOptions={tumorVolumeSummaryGridOptions}
          tumorGrowthInhibitionGridOptions={tumorGrowthInhibitionSummaryGridOptions} // Mean Delta Inhibision
          tumorGrowthMeanInhibitionGridOptions={tumorGrowthMeanInhibitionSummaryGridOptions} // Mean % Inhibition
          tumorVolumeSummaryFetching={tvsFetching}
          tumorVolumeIndividualFetching={tviFetching}
          showTable={!previewing || filtersLocked}
          dataViewChangeAudit={dataViewChangeAudit}
          chartExportAudit={chartExportAudit}
          selectedTab={selectedTab}
          model={model}
          showTGITable={showTGITable}
          studyId={projectCode}
          acronym="TV"
        />
      ),
    },
    {
      eventKey: eventTabs.tvr,
      title: 'Tumor Volume (Right)',
      panel: (
        <TumorVolumePanel
          individualTumorVolumeData={tumorVolumeRightIndividualData}
          individualTumorVolumeGridOptions={tumorVolumeRightIndividualGridOptions}
          tumorVolumeData={tumorVolumeRightSummaryData}
          tumorVolumeGridOptions={tumorVolumeRightSummaryGridOptions}
          tumorGrowthInhibitionGridOptions={tumorRightGrowthInhibitionSummaryGridOptions}
          tumorGrowthMeanInhibitionGridOptions={tumorGrowthRightMeanInhibitionSummaryGridOptions} // Mean % Inhibition
          tumorVolumeSummaryFetching={tvrsFetching}
          tumorVolumeIndividualFetching={tvriFetching}
          showTable={!previewing || filtersLocked}
          dataViewChangeAudit={dataViewChangeAudit}
          chartExportAudit={chartExportAudit}
          selectedTab={selectedTab}
          model={model}
          showTGITable={showTRGITable}
          studyId={projectCode}
          rltv="Right Flank"
          acronym="TVR"
        />
      ),
    },
    {
      eventKey: eventTabs.tvl,
      title: 'Tumor Volume (Left)',
      panel: (
        <TumorVolumePanel
          individualTumorVolumeData={tumorVolumeLeftIndividualData}
          individualTumorVolumeGridOptions={tumorVolumeLeftIndividualGridOptions}
          tumorVolumeData={tumorVolumeLeftSummaryData}
          tumorVolumeGridOptions={tumorVolumeLeftSummaryGridOptions}
          tumorGrowthInhibitionGridOptions={tumorLeftGrowthInhibitionSummaryGridOptions}
          tumorGrowthMeanInhibitionGridOptions={tumorGrowthLeftMeanInhibitionSummaryGridOptions} // Mean % Inhibition
          tumorVolumeSummaryFetching={tvlsFetching}
          tumorVolumeIndividualFetching={tvliFetching}
          showTable={!previewing || filtersLocked}
          dataViewChangeAudit={dataViewChangeAudit}
          chartExportAudit={chartExportAudit}
          selectedTab={selectedTab}
          model={model}
          showTGITable={showTLGITable}
          studyId={projectCode}
          rltv="Left Flank"
          acronym="TVL"
        />
      ),
    },
    {
      eventKey: eventTabs.tvc,
      title: 'Tumor Volume Change',
      panel: (<TumorVolumeChangePanel
        tumorVolumeChangeIndividualData={tumorVolumeChangeIndividualData}
        tumorVolumeChangeIndividualGridOptions={tumorVolumeChangeIndividualGridOptions}
        tumorVolumeChangeSummaryData={tumorVolumeChangeSummaryData}
        tumorVolumeChangeSummaryGroups={tumorVolumeChangeSummaryGroups}
        tumorVolumeChangeSummaryGridOptions={tumorVolumeChangeSummaryGridOptions}
        tumorVolumeChangeIndividualFetching={tvciFetching}
        tumorVolumeChangeSummaryFetching={tvcsFetching}
        showTable={!previewing || filtersLocked}
        dataViewChangeAudit={dataViewChangeAudit}
        chartExportAudit={chartExportAudit}
        model={model}
        studyId={projectCode}
        acronym="TVC"
      />),
    },
    {
      eventKey: eventTabs.bw,
      title: 'Body Weight',
      panel: (
        <BodyWeightPanel
          bodyWeightIndividualData={bodyWeightIndividualData}
          bodyWeightIndividualGridOptions={bodyWeightIndividualGridOptions}
          bodyWeightSummaryData={bodyWeightSummaryData}
          bodyWeightSummaryGroups={bodyWeightSummaryGroups}
          bodyWeightSummaryGridOptions={bodyWeightSummaryGridOptions}
          bodyWeightIndividualFetching={bwiFetching}
          bodyWeightSummaryFetching={bwsFetching}
          showTable={!previewing || filtersLocked}
          dataViewChangeAudit={dataViewChangeAudit}
          chartExportAudit={chartExportAudit}
          model={model}
          studyId={projectCode}
          acronym="BW"
        />
      ),
    },
    {
      eventKey: eventTabs.bwc,
      title: 'Body Weight Change',
      panel: (<BodyWeightChangePanel
        bodyWeightChangeIndividualData={bodyWeightChangeIndividualData}
        bodyWeightChangeIndividualGridOptions={bodyWeightChangeIndividualGridOptions}
        bodyWeightChangeSummaryData={bodyWeightChangeSummaryData}
        bodyWeightChangeSummaryGroups={bodyWeightChangeSummaryGroups}
        bodyWeightChangeSummaryGridOptions={bodyWeightChangeSummaryGridOptions}
        bodyWeightChangeIndividualFetching={bwciFetching}
        bodyWeightChangeSummaryFetching={bwcsFetching}
        showTable={!previewing || filtersLocked}
        dataViewChangeAudit={dataViewChangeAudit}
        chartExportAudit={chartExportAudit}
        model={model}
        studyId={projectCode}
        acronym="BWC"
      />),
    },
    {
      eventKey: eventTabs.co,
      title: 'Clinical Observations',
      panel: (
        <ClinicalObservationsPanel
          clinicalObservationIndividualGroupsLegend={clinicalObservationIndividualGroupsLegend}
          clinicalObservationIndividualGridOptions={clinicalObservationIndividualGridOptions}
          clinicalObservationFetching={coFetching}
          showTable={!previewing || filtersLocked}
        />
      ),
    },
    {
      eventKey: eventTabs.dr,
      title: 'Dosing Record',
      panel: (
        <DosingRecordPanel
          dosingRecordIndividualGridOptions={dosingRecordIndividualGridOptions}
          dosingRecordIndividualFetching={driFetching}
          showTable={!previewing || filtersLocked}
        />),
    },
    {
      eventKey: eventTabs.mo,
      title: 'Mortality Observations',
      panel: (
        <MortalityObservationPanel
          mortalityObservationIndividualGroupsLegend={mortalityObservationIndividualGroupsLegend}
          mortalityObservationIndividualGridOptions={mortalityObservationIndividualGridOptions}
          mortalityObservationFetching={moFetching}
          showTable={!previewing || filtersLocked}
        />
      ),
    },
    {
      eventKey: eventTabs.ss,
      title: 'Session Summary',
      panel: (
        <SessionSummaryPanel
          selectedTab={selectedTab}
          studyId={projectCode}
          modelId={model.study_model}
          sessionSummaryFetching={driFetching}
          excludeDosingTimes={previewFilters.dosingTimes === 'Exclude' || (previewFilters.dosingTimes !== 'Include' && modelMetaData && modelMetaData[model.study_id]?.filters?.dosingTimes === 'Exclude')}
          showTable={!previewing || filtersLocked}
          allArticles={allArticles}
          dosingRecordRows={dosingRecordRows}
          dosingStudyDayColumns={dosingStudyDayColumns}
          sessionDaysDosingRecords={sessionDaysDosingRecords}
          bliVolumeRows={bliVolumeRows}
          sessionDaysBliVolume={sessionDaysBliVolume}
          bliVolumeStudyDayColumns={bliVolumeStudyDayColumns}
          bliMeanRows={bliMeanRows}
          sessionDaysBliMean={sessionDaysBliMean}
          bliMeanStudyDayColumns={bliMeanStudyDayColumns}
          bliVentralRows={bliVentralRows}
          sessionDaysBliVentral={sessionDaysBliVentral}
          bliVentralStudyDayColumns={bliVentralStudyDayColumns}
          bliDorsalRows={bliDorsalRows}
          sessionDaysBliDorsal={sessionDaysBliDorsal}
          bliDorsalStudyDayColumns={bliDorsalStudyDayColumns}
          bodyWeightRows={bodyWeightRows}
          sessionDaysBodyWeight={sessionDaysBodyWeight}
          bodyWeightChangeRows={bodyWeightChangeRows}
          bodyWeightStudyDayColumns={bodyWeightStudyDayColumns}
          sessionDaysBodyWeightChange={sessionDaysBodyWeightChange}
          tumorVolumeChangeRows={tumorVolumeChangeRows}
          sessionDaysTumorVolumeChange={sessionDaysTumorVolumeChange}
          tumorVolumeChangeStudyDayColumns={tumorVolumeChangeStudyDayColumns}
          bodyWeightChangeStudyDayColumns={bodyWeightChangeStudyDayColumns}
          mortalityObservationRows={mortalityObservationRows}
          sessionDaysMortalityObservation={sessionDaysMortalityObservation}
          mortalityObservationStudyDayColumns={mortalityObservationStudyDayColumns}
          clinicalObservationRows={clinicalObservationRows}
          sessionDaysClinicalObservation={sessionDaysClinicalObservation}
          clinicalObservationStudyDayColumns={clinicalObservationStudyDayColumns}
          tumorVolumeRows={tumorVolumeRows}
          sessionDaysTumorVolume={sessionDaysTumorVolume}
          tumorVolumeStudyDayColumns={tumorVolumeStudyDayColumns}
          tumorVolumeRightRows={tumorVolumeRightRows}
          sessionDaysTumorVolumeRight={sessionDaysTumorVolumeRight}
          tumorVolumeRightStudyDayColumns={tumorVolumeRightStudyDayColumns}
          tumorVolumeLeftRows={tumorVolumeLeftRows}
          sessionDaysTumorVolumeLeft={sessionDaysTumorVolumeLeft}
          tumorVolumeLeftStudyDayColumns={tumorVolumeLeftStudyDayColumns}
          sessionSummaryGridOptionsForPinnedColumns={sessionSummaryGridOptionsForPinnedColumns}
          clinicalObservationIndividualGroupsLegend={clinicalObservationIndividualGroupsLegend}
          tumorVolumeChangeGraphdata={tumorVolumeChangeIndividualGridOptions.rowData}
          chartExportAudit={chartExportAudit}
          createOrUpdateGraphAnnotation={createOrUpdateGraphAnnotation}
          listGraphAnnotations={listGraphAnnotations}
        />
      ),
    },
  ];

  if (previewFilters.survivalAnalysis === 'Include' || (!previewFilters.survivalAnalysis && modelMetaData?.[model?.study_id]?.filters?.survivalAnalysis === 'Include')) {
    tabsList.push({
      eventKey: eventTabs.sa,
      title: 'Survival Analysis',
      panel: (
        <SurvivalAnalysisPanel
          survivalAnalysisChartData={survivalAnalysisChartData}
          survivalAnalysisFetching={saFetching}
          maxStudyDay={maxStudyDayForSurvivalAnalysis}
          minStudyDay={minStudyDayForSurvivalAnalysis}
          saFetching={saFetching}
          groups={survivalAnalysisGroups}
          model={model}
          studyId={projectCode}
          acronym="SA"
        />
      ),
    })
  }

  // TODO: Display this once the data fetching task has been complete
  // tabsList.push({
  //   eventKey: eventTabs.mc,
  //   title: 'Model Characteristics',
  //   panel: (
  //     <ModelCharacteristics
  //       modelCharacteristicsGridOptions={modelCharacteristicsGridOptions}
  //       modelMoreCharacteristicsGridOptions={modelMoreCharacteristicsGridOptions}
  //       modelCharacteristicsFetching={mcFetching}
  //       showTable={!previewing || filtersLocked}
  //     />),
  // })

  return (
    /* eslint-disable-next-line react/no-array-index-key */
    <Tab.Pane data-testid="js-plot-chart" key={`tab-${index}`} eventKey={`tab-${index}`}>
      <Card.Body className="study-details-study-data-card-body">
        {loadingTabs
          ? (
            <Button variant="primary" disabled>
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              Loading Metrics ....
            </Button>
          )
          : (
            <Tabs
              indicatorcolor="secondary"
              textcolor="inherit"
              transition={false}
              className="border-none data-rb-event-tab"
              variant="tabs"
              activeKey={selectedTab}
              onSelect={(tab) => {
                if (user && user.id) {
                  const messageData = {
                    eventType: AUDIT_TRAIL_EVENT_TYPES.DATA_VIEW_SWITCH,
                    userId: user.id,
                    projectId: projectCode,
                    companyName: study.company,
                    attributes: [{ modelId: model.study_model }, { dataView: tab }],
                  }
                  if (dataTypeTabs.includes(tab)) {
                    messageData.attributes.push({ dataType: 'summary' })
                  }
                  createMessageForSQS(messageData).then((messageBody) => {
                    sendMessageToQueueForAudits(messageBody)
                  })
                }
                handleClose();
                setSelectedTab(tab)
              }}
            >
              {tabsList.map((tab, index) => {
                if (!isTumorVolumeTabVisible && tab.eventKey === eventTabs.tv) {
                  return undefined;
                }
                if (!isTumorVolumeTabVisible && tab.eventKey === eventTabs.tvc) {
                  return undefined;
                }
                if (!isDualFlank && tab.eventKey === eventTabs.tvr) {
                  return undefined;
                }
                if (!isDualFlank && tab.eventKey === eventTabs.tvl) {
                  return undefined;
                }
                if (!isTotalFluxTabVisible && tab.eventKey === eventTabs.tf) {
                  return undefined;
                }

                return (
                  /* eslint-disable-next-line react/no-array-index-key */
                  <Tab key={index} className="border-none" unmountOnExit mountOnEnter eventKey={tab.eventKey} title={tab.title}>
                    {tab.panel}
                  </Tab>
                )
              })}
            </Tabs>
          )}
      </Card.Body>
    </Tab.Pane>
  );

};
SessionSummaryPanel.propTypes = {
  model: PropTypes.object,
  index: PropTypes.number,
  previewing: PropTypes.bool,
  filtersLocked: PropTypes.bool,
  projectCode: PropTypes.string,
  filtering: PropTypes.bool,
  dataLoaded: PropTypes.func,
  study: PropTypes.object,
  previewFilters: PropTypes.shape({
    dosingTimes: PropTypes.string,
    survivalAnalysis: PropTypes.string,
  }),
  released: PropTypes.bool,
}
export default StudyCharts;
