// DateFilterComponent.jsx

import React from 'react';
import { Col, Form } from 'react-bootstrap';

const DateFilterComponent = ({
  label,
  onChange,
  defaultValue,
  filterAllStudyDays,
  filterStudyDays,
  parseDayToDigit,
  testIdPrefix,
}) => (
  <Col>
    <Form.Group controlId="formBasicSelect" className="studyDetails-dataFilters-formGroup">
      <Form.Label className="studyDetails-dataFilters-formLabel">{label}</Form.Label>
      <Form.Control
        name="dateRange"
        as="select"
        style={{ height: '56px' }}
        defaultValue={defaultValue}
        onChange={(event) => onChange(event)}
        required
        disabled={!filterAllStudyDays?.length && !filterStudyDays?.bwStudyDays?.length}
      >
        {/* Common Options */}
        {(filterAllStudyDays.filter((x) => x[1] === 'Day 0').length > 0
          || filterAllStudyDays.filter((x) => x[1] === 'Day 1').length > 0) && (
          <option value="" data-testid={`${testIdPrefix}_Common_Options`} disabled>
            Common Options
          </option>
        )}

        {/* Day 0 Option */}
        {filterAllStudyDays.filter((x) => x[1] === 'Day 0').length > 0 && (
          <option data-testid={`${testIdPrefix}_Option1`} value={0}>
            Day 0 ({filterAllStudyDays.filter((x) => x[1] === 'Day 0')[0][0]})
          </option>
        )}

        {/* Day 1 Option */}
        {filterAllStudyDays.filter((x) => x[1] === 'Day 1').length > 0 && (
          <option data-testid={`${testIdPrefix}_Option2`} value={1}>
            Day 1 ({filterAllStudyDays.filter((x) => x[1] === 'Day 1')[0][0]})
          </option>
        )}

        {/* Additional Options */}
        {!filterAllStudyDays.length
        && filterStudyDays?.bwStudyDays?.length
        && filterStudyDays.bwStudyDays[0] ? (
          <option
            disabled={!filterAllStudyDays.length}
            value={parseDayToDigit(filterStudyDays?.bwStudyDays[0][1])}
          >
            {filterStudyDays?.bwStudyDays[0][1]} ({filterStudyDays?.bwStudyDays[0][0]})
          </option>
          ) : null}

        {/* All Options */}
        <option value="" data-testid={`${testIdPrefix}_All_Options`} disabled>
          All Options
        </option>

        {/* Study Days */}
        {filterAllStudyDays.map((studyDay) => (
          <option key={studyDay[1]} value={parseDayToDigit(studyDay[1])}>
            {studyDay[1]} ({studyDay[0]})
          </option>
        ))}
      </Form.Control>
    </Form.Group>
  </Col>
);

export default DateFilterComponent;
