import React from 'react';
import { Button, Row, Spinner } from 'react-bootstrap';
import { AgGridReact } from 'ag-grid-react';
import _ from 'lodash';
import BodyWeightChart from './BodyWeightChart';
import TumorVolumeTable from '../../TumorVolumeTable';
import RadioList from '../RadioList';
import { truncationOfLongStr } from '../../utils';

const BodyWeightPanel = function BodyWeightPanel({
  studyId, model, acronym, dataViewChangeAudit, chartExportAudit, ...props
}) {
  const spinner = (
    <Row className="justify-content-center">
      <Button variant="primary" disabled>
        <Spinner
          as="span"
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
        />
        Loading Body Weight Metrics...
      </Button>
    </Row>
  )

  return (
    <RadioList
      auditCallback={dataViewChangeAudit}
      items={[
        {
          id: 'summary',
          label: 'Summary',
          content: (
            <div>
              <hr className="mlr-10" />
              <div style={props.bodyWeightSummaryFetching ? { display: 'none' } : {}}>
                <div style={props.showTable && props.bodyWeightSummaryData.length ? {} : { display: 'none' }}>
                  <Row
                    style={{
                      width: '100%',
                      height: '450px',
                      margin: 'auto',
                    }}
                    className="summary-chart"
                  >
                    <BodyWeightChart
                      chartExportAudit={chartExportAudit('summary')}
                      margin={{ r: 0, t: 30, pad: 0 }}
                      yaxisTitle="<b>Mean Body Weight (g)</b>"
                      title="Mean Body Weight Graph (g)"
                      xaxisTitle="<b>Study Day</b>"
                      data={props.bodyWeightSummaryData}
                      divId="tumor-volume-mean-summary"
                      groups={props.bodyWeightSummaryGroups}
                      studyId={studyId}
                      modelId={model.study_id}
                      graphName={`${acronym}-summary`}
                      acronym={acronym}
                    />
                  </Row>
                  <hr className="mlr-10" />
                  <Row
                    style={{
                      width: '100%',
                      height: '350px',
                      margin: 'auto',
                    }}
                  >
                    <h4>Mean Body Weight Table (g)</h4>
                    {!_.isEmpty(props.bodyWeightSummaryGridOptions) && (
                      <TumorVolumeTable
                        divStyles={{
                          height: '100%',
                          width: '100%',
                        }}
                        gridOptions={props.bodyWeightSummaryGridOptions}
                      />
                    )}
                  </Row>
                </div>
                {/* error handle */}
                {(!props.showTable || !props.bodyWeightSummaryData.length) && <div>No Data Available</div>}
              </div>
              {props.bodyWeightSummaryFetching && spinner}
              <hr className="mlr-10" />
            </div>
          ),
        },
        {
          id: 'individual',
          label: 'Individual',
          content: (
            <div>
              <hr className="mlr-10" />
              <h5 className="text-center text-md-left">
                Absolute Body Weight (g): Individual Measurements
              </h5>
              <div style={props.bodyWeightIndividualFetching ? { display: 'none' } : {}}>
                <div style={!props.showTable || _.isEmpty(props.bodyWeightIndividualData) ? { display: 'none' } : {}}>
                  <div
                    className="ag-theme-alpine"
                    style={{
                      width: '100%',
                      height: '450px',
                      margin: 'auto',
                    }}
                  >
                    {props.bodyWeightIndividualGridOptions && props.bodyWeightIndividualGridOptions.onGridReady
                      ? (
                        <AgGridReact
                          gridOptions={props.bodyWeightIndividualGridOptions}
                        />
                      )
                      : (
                        <Row className="justify-content-center">
                          <Button variant="primary" disabled>
                            <Spinner
                              as="span"
                              animation="grow"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                            Loading Body Weight Metrics...
                          </Button>
                        </Row>
                      )}
                  </div>

                  {!_.isEmpty(props.bodyWeightIndividualData.chartValues)
                    && Object.entries(props.bodyWeightIndividualData.chartValues).map(
                      (groupChartValues) => (
                        <Row
                          key={groupChartValues[0]}
                          style={{
                            height: '450px',
                            marginTop: '40px',
                          }}
                        >
                          <BodyWeightChart
                            chartExportAudit={chartExportAudit('individual')}
                            margin={{
                              r: 20,
                              t: 40,
                              pad: 0,
                            }}
                            yaxisTitle="<b>Body Weight (g)</b>"
                            title={
                              `Individual Body Weight (g): ${truncationOfLongStr(groupChartValues[0], 80)}`
                              }
                            xaxisTitle="<b>Study Day</b>"
                            data={groupChartValues[1]}
                            xaxisRange={props.bodyWeightIndividualData.xaxisRange}
                            yaxisRange={props.bodyWeightIndividualData.yaxisRange}
                            divId="individual-tumor-volumes"
                            studyId={studyId}
                            modelId={model.study_id}
                            graphName={`${acronym}-individual-${groupChartValues[0]}`}
                            acronym={acronym}
                          />
                        </Row>
                      ),
                    )}
                </div>
                {(!props.showTable || _.isEmpty(props.bodyWeightIndividualData)) && <div>No Data Available</div>}
              </div>
              {props.bodyWeightIndividualFetching && spinner}
              <hr className="mlr-10" />
            </div>
          ),
        },
      ]}
    />
  );
}

export default BodyWeightPanel;
