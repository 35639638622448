import { AnalyticsBrowser } from '@segment/analytics-next';
import heap from '@segment/analytics-browser-actions-heap';
import React, {
  createContext,
  useContext,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { Tracker } from './tracker';

class Analytics extends AnalyticsBrowser {
  startTrack(event, startTime = undefined) {
    const track = new Tracker(this, event, startTime);
    return track;
  }
}

/** @type {React.Context<Analytics | undefined>} */
const AnalyticsContext = createContext(undefined);

export const AnalyticsProvider = ({
  children,
  writeKey,
}) => {
  const analytics = useMemo(() => {
    const analyticsInst = new Analytics();
    if (typeof writeKey === 'string') {
      analyticsInst.load({ writeKey, plugins: [heap] }, { obfuscate: true });
    } else {
      writeKey.then((writeKeyRes) => {
        analyticsInst.load({ writeKey: writeKeyRes, plugins: [heap] }, { obfuscate: true });
      });
    }
    return analyticsInst;
  }, [writeKey]);

  return (
    <AnalyticsContext.Provider value={analytics}>
      {children}
    </AnalyticsContext.Provider>
  );
};

AnalyticsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  writeKey: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Promise),
  ]).isRequired,
};

export const useAnalytics = () => {
  const analytics = useContext(AnalyticsContext);

  return analytics;
};
