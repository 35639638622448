import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@mui/material/Modal';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ModelTab from './ModelTab';

const CustomModal = ({ isOpen, onClose, children }) => (
  <Modal
    open={isOpen}
    onClose={onClose}
    aria-labelledby="title"
    aria-describedby="description"
    className="study-summary-modal-wrapper"
  >
    <div className="study-summary-modal-content">
      <IconButton
        edge="end"
        color="inherit"
        onClick={onClose}
        aria-label="close"
        className="close-button"
      >
        <CloseIcon />
      </IconButton>
      {children}
    </div>
  </Modal>
);

CustomModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

const RenderModelTab = ({
  disableModelLink,
  study,
  setModel,
  modelTabsDisabled,
  dataExportAudit,
}) => (
  <ModelTab
    disableModelLink={disableModelLink}
    study={study}
    setModel={setModel}
    modelTabsDisabled={modelTabsDisabled}
    dataExportAudit={dataExportAudit}
  />
);

const studyPropType = PropTypes.shape({
  company: PropTypes.string.isRequired,
  project_code: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  site: PropTypes.string.isRequired,
  study_type: PropTypes.string.isRequired,
  model_id: PropTypes.string.isRequired,
  sponsor: PropTypes.string.isRequired,
  director: PropTypes.string.isRequired,
  project_manager: PropTypes.string.isRequired,
  business_director: PropTypes.string.isRequired,
  study_title: PropTypes.string.isRequired,
  models: PropTypes.arrayOf(
    PropTypes.shape({
      study_id: PropTypes.string.isRequired,
      study_type: PropTypes.string.isRequired,
      study_site: PropTypes.string.isRequired,
      study_status: PropTypes.string.isRequired,
      study_model: PropTypes.string.isRequired,
      director: PropTypes.shape({
        name: PropTypes.string.isRequired,
        createdAt: PropTypes.string.isRequired,
        state: PropTypes.string.isRequired,
      }).isRequired,
      juniorDirector: PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.string,
      }),
    }),
  ).isRequired,
})

RenderModelTab.propTypes = {
  disableModelLink: PropTypes.func.isRequired,
  study: studyPropType.isRequired,
  setModel: PropTypes.func.isRequired,
  modelTabsDisabled: PropTypes.bool.isRequired,
  dataExportAudit: PropTypes.func.isRequired,
};

function ConditionalModelTab({
  hasOncologyStudyDirector,
  disableModelLink,
  study,
  setModel,
  modelTabsDisabled,
  dataExportAudit,
  isModelTabModalOpen,
  handleModelTabCloseModal,
}) {
  if (hasOncologyStudyDirector) {
    return (
      <CustomModal
        isOpen={isModelTabModalOpen}
        onClose={handleModelTabCloseModal}
      >
        <RenderModelTab
          disableModelLink={disableModelLink}
          study={study}
          setModel={setModel}
          modelTabsDisabled={modelTabsDisabled}
          dataExportAudit={dataExportAudit}
        />
      </CustomModal>
    );
  }
  return (
    <div>
      <RenderModelTab
        disableModelLink={disableModelLink}
        study={study}
        setModel={setModel}
        modelTabsDisabled={modelTabsDisabled}
        dataExportAudit={dataExportAudit}
      />
    </div>
  );
}

ConditionalModelTab.propTypes = {
  hasOncologyStudyDirector: PropTypes.bool.isRequired,
  isModelTabModalOpen: PropTypes.bool.isRequired,
  handleModelTabCloseModal: PropTypes.func.isRequired,
  disableModelLink: PropTypes.func.isRequired,
  study: studyPropType.isRequired,
  setModel: PropTypes.func.isRequired,
  modelTabsDisabled: PropTypes.bool.isRequired,
  dataExportAudit: PropTypes.func.isRequired,
};

export default ConditionalModelTab;
