import React from 'react';

const DummyProgressBar = ({ cancelDummyUpload, name }) => (
  <>
    <div className="progress-bar-xs progress">
      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
      <div
        className="progress-bar bg-success"
        role="progressbar"
        aria-valuenow={0}
        aria-valuemin="0"
        aria-valuemax="100"
        style={{ width: `${0}%` }}
      />
    </div>
    <small className="drop-zone-info-text float-start" style={{ color: '#fff' }}>Time</small>
    <button
      type="button"
      onClick={() => cancelDummyUpload(name)}
      className="btn cncl-bttn btn-danger drop-zone-info-text float-right"
    >
      Cancel
    </button>
  </>
);
export default DummyProgressBar;
