/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-plusplus */
/* eslint-disable object-shorthand */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import PropTypes from 'prop-types';
import ExcelColumn from './ExcelColumn';

class ExcelSheet extends React.Component {
  render() {
    return null;
  }
}

ExcelSheet.propTypes = {
  name: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
  dataSet: PropTypes.arrayOf(PropTypes.object),
  value: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.func,
  ]).isRequired,
  children: function (props, propName, componentName) {
    const propValue = props[propName];
    for (let i = 0; i < propValue.length; i++) {
      const { type } = propValue[i];
      if (type !== ExcelColumn) {
        return new Error(
          '<ExcelSheet> can only have <ExcelColumn> as children',
        );
      }
    }
  },
};

export default ExcelSheet;
