/* eslint-disable no-debugger */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
/* eslint-disable no-restricted-globals */
/* eslint-disable react/sort-comp */
/* eslint-disable consistent-return */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable class-methods-use-this */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';
import XLSX from 'xlsx';

// eslint-disable-next-line no-unused-vars
import ExcelSheet from '../elements/ExcelSheet';
import {
  strToArrBuffer,
  excelSheetFromAoA,
  excelSheetFromDataSet,
} from '../utils/DataUtil';

const fileExtensions = ['xlsx', 'xls', 'csv', 'txt', 'html'];
const defaultFileExtension = 'xlsx';
class ExcelFile extends React.Component {

  constructor(props) {
    super(props);

    if (this.props.hideElement) {
      this.download();
    } else {
      this.handleDownload = this.download.bind(this);
    }

    this.createSheetData = this.createSheetData.bind(this);
  }

  createSheetData(sheet) {
    const columns = sheet.props.children;
    const sheetData = [
      React.Children.map(columns, (column) => column.props.label),
    ];
    const data = typeof sheet.props.data === 'function'
      ? sheet.props.data()
      : sheet.props.data;

    data.forEach((row) => {
      const sheetRow = [];

      React.Children.forEach(columns, (column) => {
        const getValue = typeof column.props.value === 'function'
          ? column.props.value
          : (row) => row[column.props.value];
        const itemValue = getValue(row);
        sheetRow.push(isNaN(itemValue) ? itemValue || '' : itemValue);
      });

      sheetData.push(sheetRow);
    });

    return sheetData;
  }

  download() {
    const wb = {
      SheetNames: React.Children.map(this.props.children, (sheet) => {
        if (sheet == null) {
          return;
        }
        return sheet.props.name;
      }),
      Sheets: {},
    };

    React.Children.forEach(this.props.children, (sheet) => {
      if (sheet == null) {
        return;
      }
      if (
        typeof sheet.props.dataSet === 'undefined'
        || sheet.props.dataSet.length === 0
      ) {
        wb.Sheets[sheet.props.name] = excelSheetFromAoA(
          this.createSheetData(sheet),
        );
      } else {
        wb.Sheets[sheet.props.name] = excelSheetFromDataSet(
          sheet.props.dataSet,
        );
      }
    });

    const fileExtension = this.getFileExtension();
    const fileName = this.getFileName();
    const wbout = XLSX.write(wb, {
      bookType: fileExtension,
      bookSST: true,
      type: 'binary',
    });

    this.props.onUpdateState([
      new Blob([strToArrBuffer(wbout)], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      }),
      fileName,
    ]);
  }

  getFileName() {
    if (
      this.props.filename === null
      || typeof this.props.filename !== 'string'
    ) {
      throw Error('Invalid file name provided');
    }
    return this.getFileNameWithExtension(
      this.props.filename,
      this.getFileExtension(),
    );
  }

  getFileExtension() {
    let extension = this.props.fileExtension;

    if (extension.length === 0) {
      const slugs = this.props.filename.split('.');
      if (slugs.length === 0) {
        throw Error('Invalid file name provided');
      }
      extension = slugs[slugs.length - 1];
    }

    if (fileExtensions.indexOf(extension) !== -1) {
      return extension;
    }

    return defaultFileExtension;
  }

  getFileNameWithExtension(filename, extension) {
    return `${filename}.${extension}`;
  }

  render() {
    const { hideElement, element } = this.props;

    if (hideElement) {
      return null;
    }
    return <span onClick={this.handleDownload}>{element}</span>;

  }
}

ExcelFile.propTypes = {
  hideElement: PropTypes.bool,
  filename: PropTypes.string,
  fileExtension: PropTypes.string,
  element: PropTypes.any,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

ExcelFile.defaultProps = {
  hideElement: false,
  filename: 'Download',
  fileExtension: 'xlsx',
  element: <button>Download</button>,
};

ExcelFile.defaultProps = {
  hideElement: false,
  filename: 'Download',
  fileExtension: 'xlsx',
  element: <button>Download</button>,
};

ExcelFile.defaultProps = {
  hideElement: false,
  filename: 'Download',
  fileExtension: 'xlsx',
  element: <button>Download</button>,
};

ExcelFile.defaultProps = {
  hideElement: false,
  filename: 'Download',
  fileExtension: 'xlsx',
  element: <button>Download</button>,
};

export default ExcelFile;
