/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import moment from 'moment';

import InputLabel from '@mui/material/InputLabel';
import { makeStyles } from '@mui/styles';
import {
  Button,
  Container,
} from 'react-bootstrap';
import { TextField } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import './auth.css'
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useAuth } from '../../../providers/auth'
import NewPassword from './NewPassword';
import ForgotPassword from './ForgotPassword';
import CrownlinkLogo from './LogoCrownlink.png';
import { ReactComponent as CheckBoxIconDesign } from './checkboxactive.svg';
import ResetPassword from './ResetPassword';
import { testAccounts } from '../../../config';
import { createMessageForSQS } from '../../utils.createMessage';
import {
  BRAND_COLORS,
  DATA_COLLECTION_EVENT_TYPES,
  GROUPS,
} from '../../../constants';
import { useSQS } from '../../../providers/sqs';
import { useGraphQL } from '../../../providers/graphql';

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    fontFamily: 'AvenirNextLTPro-Regular',
    width: '400px',
    '&.Mui-focused': {
      backgroundColor: '#fff',
      fontFamily: 'AvenirNextLTPro-Regular',
    },
  },
  focused: {},
  notchedOutline: {},

  label: {
    color: '#FFFFFFDE',
  },
}));

const Login = function Login() {
  const {
    doLogin,
    changeUserPassword,
    passwordExpiredFlow,
    setPasswordExpiredFlow,
    isPasswordExpired,
    signOut,
  } = useAuth();

  const [userEmail, setUserEmail] = useState('');
  const { sendMessageToQueueForAnalytics } = useSQS();
  const { getUser, updateUser } = useGraphQL();
  const [userPassword, setUserPassword] = useState('');
  const [otp, setOtp] = useState('')
  const [forgotPasswordFlow, setForgotPasswordFlow] = useState(false);
  const [newPasswordFlow, setNewPasswordFlow] = useState(false);
  const [otpFlow, setOtpFlow] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMsg, setSuccessMsg] = useState('')
  const [rememberDevice, setRememberDevice] = useState(false);
  const classes = useStyles();

  const getDeviceKey = (email) => window.localStorage.getItem(email) || ''

  const clearSuccessMsg = () => {
    if (successMsg) {
      setSuccessMsg('')
    }
  }

  const renderBackToLogin = (setView) => (
    <>
      <span className="forgotPasswordLabel" role="none" onKeyPress={() => {}} onClick={() => { setView(false); }} style={{ textDecoration: 'underline' }}>
        <ArrowBackIcon style={{ fontSize: 16 }} /> {'  '}
        <span>Go back to login page</span>
      </span><br />
    </>
  )
  const sendMessageToSQS = async (user) => {
    const { signInUserSession: { accessToken: { payload } } } = user;
    const checkExternalGroup = payload['cognito:groups'] ? payload['cognito:groups'].includes(GROUPS.EXTERNAL) : false;
    const result = await getUser(user.username);
    const {
      id, email, favorites, notifications, first_name: firstName, last_name: lastName, phone,
    } = result.data.getUser
    await updateUser(
      id, email, favorites, notifications, firstName, lastName, phone, moment().unix(),
    );
    if (checkExternalGroup) {
      const messageForSqs = await createMessageForSQS({ eventType: DATA_COLLECTION_EVENT_TYPES.USER_LOGIN_EXTERNAL, userId: user.username })
      sendMessageToQueueForAnalytics(messageForSqs)
    } else {
      const messageForSqs = await createMessageForSQS({ eventType: DATA_COLLECTION_EVENT_TYPES.USER_LOGIN, userId: user.username })
      sendMessageToQueueForAnalytics(messageForSqs)
    }

  }

  const changePassword = ({ oldPassword, newPassword }) => {
    setLoading(true);
    changeUserPassword(oldPassword, newPassword)
      .then(() => {
        Auth.currentAuthenticatedUser().then((user) => {
          doLogin(user);
          setForgotPasswordFlow(false);
          setNewPasswordFlow(false);
          setOtpFlow(false);
        })
      })
      .catch((err) => {
        setErrorMessage(err.message);
      })
      .finally(() => setLoading(false));
  }

  const handleLogin = async (email = userEmail, password = userPassword) => {
    const startTime = Date.now();
    localStorage.setItem('loginStartTime', startTime);

    setLoading(true);
    const deviceKey = getDeviceKey(email)
    clearSuccessMsg()

    // disable MFA for e2e tests
    let promise;
    if (testAccounts.includes(email)) {
      if (Auth._config.authenticationFlowType !== 'USER_SRP_AUTH') {
        Auth.configure({ authenticationFlowType: 'USER_SRP_AUTH' });
      }
      promise = Auth.signIn(email, password)
    } else {
      // enable MFA for any user otherwise
      if (Auth._config.authenticationFlowType !== 'CUSTOM_AUTH') {
        Auth.configure({ authenticationFlowType: 'CUSTOM_AUTH' });
      }
      promise = Auth.signIn(email, password, { deviceKey })
    }
    const res = await promise.catch((err) => {
      if (err.code === 'PasswordResetRequiredException') {
        // this occurs if you reset password through the Cognito UI
        setForgotPasswordFlow(true);
      }
      setErrorMessage(err.message)
      setLoading(false);
    })

    // successful login
    if (res) {
      setCurrentUser(res);

      // reset error msg if there is one
      if (errorMessage) {
        setErrorMessage('');
      }
      setLoading(false);
      if (res.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setNewPasswordFlow(true);
      } else if (res.authenticationFlowType === 'CUSTOM_AUTH') {
        // is this enough to determine if MFA is enabled on this user?
        if (res.challengeParam?.expDevice === 'true') {
          const cognitoUser = await Auth.signIn(email, password);
          setCurrentUser(cognitoUser);
        } else {
          setCurrentUser(res);
        }
        setOtpFlow(true);
      } else {
        const passwordExpired = await isPasswordExpired({ userId: res.username });
        if (passwordExpired) {
          setPasswordExpiredFlow(true);
          setNewPasswordFlow(true);
          setOtpFlow(false);
          setLoading(false);
        } else {
          doLogin(res)
          setLoading(false);
          sendMessageToSQS(res)
        }
      }
    }
  }
  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    handleLogin(userEmail, userPassword)
  }

  const handleSubmitOTP = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const cognitoUser = await Auth.sendCustomChallengeAnswer(currentUser, otp);
      if (cognitoUser.authenticationFlowType === 'USER_SRP_AUTH') {
        setErrorMessage('');
        cognitoUser.getCachedDeviceKeyAndPassword();
        // potentially use something different than email here, obfuscate maybe?
        window.localStorage.setItem(userEmail, cognitoUser.deviceKey)
        const passwordExpired = await isPasswordExpired({ userId: cognitoUser.username });
        if (rememberDevice) {
          cognitoUser.setDeviceStatusRemembered({
            onSuccess() {},
            onFailure() {},
          })
          if (passwordExpired) {
            setPasswordExpiredFlow(true);
            setNewPasswordFlow(true);
            setOtpFlow(false);
            setLoading(false);
          } else {
            doLogin(cognitoUser);
          }
        } else if (rememberDevice === false) {
          if (passwordExpired) {
            setPasswordExpiredFlow(true);
            setNewPasswordFlow(true);
            setOtpFlow(false);
            setLoading(false);
          } else {
            doLogin(cognitoUser);
          }
        }
        sendMessageToSQS(cognitoUser)
      } else {
        if (cognitoUser.challengeParam.hasExpired === 'true') {
          setErrorMessage('Passcode has expired, please click resend passcode')
          clearSuccessMsg()
        } else {
          setErrorMessage('Incorrect passcode, please try again')
          clearSuccessMsg()
        }
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      // use specific error message for this so its more clear
      if (err.code === 'NotAuthorizedException') {
        setErrorMessage("User's session has expired, please sign in again")
        /* eslint-disable-next-line no-unused-expressions */
        otp && setOtp('')
        setOtpFlow(false);
      } else {
        setErrorMessage(err.message);
      }
    }
  }

  const handleOnInputChange = (value, setCredential) => {
    setCredential(value);
  };

  const resendCode = async () => {
    try {
      setLoading(true);
      const cognitoUser = await Auth.signIn(userEmail, userPassword);
      setCurrentUser(cognitoUser);
      setErrorMessage(false);
      setSuccessMsg('A new passcode has been sent to your email')
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setErrorMessage(err.message);
    }
  }

  // entering in a one time passcode
  if (otpFlow) {
    return (
      <Container className="authContainer" fluid>
        <div className="loginPage-imageLogo">
          <img src={CrownlinkLogo} alt="Crown Link logo on login page" width="200px" />
        </div>
        <div className="loginPage-loginFormInnerContainer">
          <form className="form" onSubmit={handleSubmitOTP} noValidate>
            <span className="containerTitle">One Time Passcode</span><br />

            {
            successMsg
            && (
            <div className="LoginForm-SuccessMessage successText">
              <div className="LoginForm-SuccessIcon"><CheckCircleIcon /></div>
              <div className="LoginForm-SuccessMessageText">{successMsg}</div>
            </div>
            )
          }
            {
            errorMessage
            && (
            <div className="LoginForm-ErrorMessage errorText">
              <div className="LoginForm-ErrorIcon"><CancelIcon /></div>
              <div className="LoginForm-ErrorMessageText">{errorMessage}</div>
            </div>
            )
          }

            <span className="otpcontainerSubTitle containerSubTitle">An email has been sent containing a one time passcode. Please enter it below:</span>
            <div className="inputFieldOTP">
              <InputLabel className="inputLabel" htmlFor="otp">
                Passcode
              </InputLabel>
              <TextField
                variant="outlined"
                placeholder="Enter one time passcode"
                required
                id="otp"
                name="otp"
                autoComplete="otp"
                type="text"
                value={otp}
                autoFocus
                InputProps={{
                  classes: {
                    root: classes.root,
                    focused: classes.focused,
                  },
                }}
                sx={{
                  width: '100%',
                }}
                onChange={(e) => handleOnInputChange(e.target.value, setOtp)}
              />
              <br />
              <div className="rememberDevice">
                <FormControlLabel
                  control={(
                    <Checkbox
                      color="secondary"
                      disableRipple
                      checkedIcon={<CheckBoxIconDesign />}
                      id="remember"
                      name="remember"
                      value={rememberDevice}
                      onChange={(e) => {
                        setRememberDevice(e.target.checked)
                      }}
                      sx={{
                        '& .MuiSvgIcon-root': {
                          fontSize: '24px',
                        },
                        color: '#FFFFFFDE',
                        '&.Mui-checked': {
                          color: BRAND_COLORS.orange,
                        },
                      }}
                    />
                  )}
                  label={(
                    <Typography
                      className="LoginFormRememberCheckboxLabel"
                    >
                      Remember this device for 28 days
                    </Typography>
                  )}
                />
              </div>
            </div>

            <Button
              className="LoginFormSignInButton"
              type="submit"
              variant="primary"
              data-test-id="authSignInBtn"
              disabled={loading}
            >
              {loading ? 'Loading...' : 'Sign In'}
            </Button>

            <Button
              className="LoginFormOutlineButtonResend"
              type="button"
              variant="light"
              onClick={() => resendCode()}
            >
              Resend Passcode
            </Button>
            <Button
              className="LoginFormOutlineButton"
              type="button"
              variant="light"
              onClick={() => setOtpFlow()}
            >
              Back
            </Button>
          </form>
        </div>
      </Container>
    )
  }

  // new user first login, force setting new password
  if (newPasswordFlow) {
    return (
      <Container className="authContainer" fluid>
        <div className="loginPage-imageLogo">
          <img src={CrownlinkLogo} alt="Crown Link logo on login page" width="200px" />
        </div>
        <div className="loginPage-loginFormInnerContainer">
          <div className="form">

            {
              errorMessage
              && (
              <div className="LoginForm-ErrorMessage errorText">
                <div className="LoginForm-ErrorIcon"><CancelIcon /></div>
                <div className="LoginForm-ErrorMessageText">{errorMessage}</div>
              </div>
              )
            }

            <span className="containerTitle">{passwordExpiredFlow ? 'Your Password has Expired Please Change to Continue' : 'Password Reset' }</span>
            {
              passwordExpiredFlow ? (
                <ResetPassword
                  handleResetPassword={changePassword}
                  cancel={() => {
                    setPasswordExpiredFlow(false);
                    setNewPasswordFlow(false);
                    signOut()
                  }}
                  error={errorMessage}
                  loading={loading}
                />
              ) : (
                <NewPassword
                  user={currentUser}
                  cancel={() => setNewPasswordFlow(false)}
                  handleLogin={handleLogin}
                  setUserPassword={setUserPassword}
                  setErrorMessage={setErrorMessage}
                />
              )
            }
          </div>
        </div>
      </Container>
    )
  }

  // forgot password
  if (forgotPasswordFlow) {
    return (
      <Container className="authContainer" fluid>
        <div className="loginPage-imageLogo">
          <img src={CrownlinkLogo} alt="Crown Link logo on login page" width="200px" />
        </div>
        <div className="loginPage-loginFormInnerContainer">
          <span className="containerTitle">Password Reset</span>
          <div className="form">

            {
              successMsg
              && (
              <div className="LoginForm-SuccessMessage successText">
                <div className="LoginForm-SuccessIcon"><CheckCircleIcon /></div>
                <div className="LoginForm-SuccessMessageText">{successMsg}</div>
              </div>
              )
            }
            {
              errorMessage
              && (
              <div className="LoginForm-ErrorMessage errorText">
                <div className="LoginForm-ErrorIcon"><CancelIcon /></div>
                <div className="LoginForm-ErrorMessageText">{errorMessage}</div>
              </div>
              )
            }

            <ForgotPassword
              email={userEmail}
              setForgotPasswordFlow={setForgotPasswordFlow}
              setErrorMessage={setErrorMessage}
              setSuccessMsg={setSuccessMsg}
              renderBackToLogin={renderBackToLogin(setForgotPasswordFlow)}
            />
          </div>
        </div>
      </Container>
    )
  }

  //  Regular Sign in page
  return (
    <Container className="authContainer" fluid>
      <div className="loginPage-imageLogo">
        <img src={CrownlinkLogo} alt="Crown Link logo on login page" width="200px" />
      </div>
      <div className="loginPage-loginFormInnerContainer">
        <form className="form" onSubmit={handleLoginSubmit}>
          <span className="containerTitle">Sign in to your account</span>

          {
            successMsg
            && (
            <div className="LoginForm-SuccessMessage successText">
              <div className="LoginForm-SuccessIcon"><CheckCircleIcon /></div>
              <div className="LoginForm-SuccessMessageText">{successMsg}</div>
            </div>
            )
          }
          {
            errorMessage
            && (
            <div className="LoginForm-ErrorMessage errorText">
              <div className="LoginForm-ErrorIcon"><CancelIcon /></div>
              <div className="LoginForm-ErrorMessageText">{errorMessage}</div>
            </div>
            )
          }

          <div className="inputField">
            <InputLabel className="inputLabel" htmlFor="email">
              Email Address
            </InputLabel>
            <TextField
              id="email"
              variant="outlined"
              placeholder="Enter your email address"
              name="email"
              autoComplete="email"
              type="email"
              value={userEmail}
              required
              InputProps={{
                classes: {
                  root: classes.root,
                  focused: classes.focused,
                },
              }}
              sx={{
                width: '100%',
              }}
              onChange={(e) => handleOnInputChange(e.target.value, setUserEmail)}
            />
          </div>
          <div className="inputField">
            <InputLabel className="inputLabel" htmlFor="password">
              Password
            </InputLabel>
            <TextField
              name="password"
              variant="outlined"
              placeholder="Enter your password"
              type="password"
              value={userPassword}
              id="password"
              InputProps={{
                classes: {
                  root: classes.root,
                  focused: classes.focused,
                },
              }}
              onChange={(e) => handleOnInputChange(e.target.value, setUserPassword)}
              required
            />
          </div>
          <span className="forgotPasswordLabel">
            Forgot Password?
          </span><br />
          <span className="forgotPasswordLabelSmall">
            No worries, click&nbsp;
          </span>
          <Button
            className="forgotPasswordButton"
            type="button"
            variant="text"
            onClick={() => setForgotPasswordFlow(true)}
          >
            <b>here</b>
          </Button>
          <span className="forgotPasswordLabelSmall">
            &nbsp;to reset your password.
          </span><br />
          <div className="forgotPasswordLabelSmall" style={{ marginBottom: '5%' }}>
            <b><span className="forgotPasswordLabel">Need an Account? </span></b> <br />
            <span>
              Please click
            </span>

            <a
              style={{ color: 'white' }}
              data-testid="need-account"
              href="https://www.crownbio.com/crownlink-registration"
            > <u><b>here</b></u>
            </a>
            <span>&nbsp;to navigate to the registration page.</span>
          </div>

          <Button
            className="LoginFormSignInButton"
            type="submit"
            variant="primary"
            disabled={loading}
            data-test-id="authSignInBtn"
          >
            {loading ? 'Loading...' : 'Sign In'}
          </Button>

        </form>
      </div>
    </Container>
  )
}

export default Login;
