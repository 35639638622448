import React, { useRef, useEffect } from 'react'
import Plot from 'react-plotly.js';
import _ from 'lodash';
import { useColorPanel } from '../../../providers/colorPanel/colorPanel';

import {
  displayTooltip,
  modeBarButtons,
} from '../../utils';

const SurvivalAnalysisChart = ({
  acronym, studyId, modelId, graphName, groups = [], chartExportAudit, ...props
}) => {
  const { handleClickOpen, chartMenu, ColorButton } = useColorPanel();
  const plotRef = useRef(null);
  const plotlyRef = useRef(null);
  const modeBar = modeBarButtons({
    ColorButton, chartMenu, handleClickOpen, studyId, modelId, graphName, acronym, data: _.isEmpty(props.data) ? [] : props.data,
  });
  const config = {
    responsive: true,
    displaylogo: false,
    modeBarButtons: [modeBar],
  };
  const layout = {
    margin: props.margin,
    hovermode: 'closest',
    showlegend: true,
    hoverlabel: {
      bgcolor: 'white',
      font_size: 8,
    },
    xaxis: {
      title: props.xaxisTitle,
      linecolor: 'rgba(0, 0, 0, 0.25)',
      linewidth: 1,
      mirror: true,
      opacity: 0.5,
      range: [props.minStudyDay, props.maxStudyDay + 2],
    },
    yaxis: {
      title: props.yaxisTitle,
      tickfont: { size: 12 },
      ticksuffix: props?.yAxisTickSuffix ?? '',
      autorange: true,
      rangemode: 'tozero',
      range: [0, 100],
    },
    title: {
      text: props.title,
      x: 0.05,
      y: 1.5,
    },
  }

  useEffect(() => {
    if (plotlyRef.current === null) {
      const plotDownloadButton = document.querySelectorAll('[data-title="Download plot as a png"]')
      if (plotDownloadButton.length) {
        const downloadButton = plotDownloadButton[0]
        plotlyRef.current = downloadButton
        plotlyRef.current.addEventListener('mouseup', chartExportAudit)
      }
    }
  });

  return (
    <Plot
      ref={plotRef}
      style={{ width: '100%', height: '100%' }}
      data={props.data}
      layout={layout}
      config={config}
      useResizeHandler
      onAfterExport={chartExportAudit}
      onAfterPlot={displayTooltip(groups, plotRef.current?.el)}
      onInitialized={displayTooltip(groups)}
    />
  )
}

export default SurvivalAnalysisChart
