import React from 'react';
import { Button as Btn, Spinner, Row } from 'react-bootstrap';

/**
 * Basic Loader button to show while components are being loaded.
 *
 * @param {String} loadingMessage line to display in loader.
 */
const CustomLoader = ({ loadingMessage = 'Loading...' }) => (
  <Row className="justify-content-center">
    <Btn variant="primary">
      <Spinner
        as="span"
        animation="grow"
        size="sm"
        role="status"
        aria-hidden="true"
        style={{ marginRight: '.5rem' }}
        disabled
      />
      {loadingMessage}
    </Btn>
  </Row>
);

export default CustomLoader;
