import React from 'react';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import CircularProgress from '@mui/material/CircularProgress';
import { CUSTOM_BOX_STYLE } from '../../../../../constants';
import styles from '../StudyDocuments.module.scss';

const StudyDocumentsCreateFolderModal = function (props) {
  const {
    showCreatingFolderModal,
    setShowCreatingFolderModal,
    newFolderNameRef,
    createFolderHandler,
    isCreatingFolder,
    setIsCreatingFolder,
    createFolder,
    foldersNavigation,
    projectCode,
    setIsLoadingTable,
    getCustomS3Folders,
    setCustomS3Folders,
  } = props;

  return (
    <Modal open={showCreatingFolderModal}>
      <form onSubmit={
        (e) => {
          e.preventDefault()
          // eslint-disable-next-line
          createFolderHandler(
            newFolderNameRef,
            setIsCreatingFolder,
            createFolder,
            foldersNavigation,
            projectCode,
            setShowCreatingFolderModal,
            setIsLoadingTable,
            getCustomS3Folders,
            setCustomS3Folders,
          )
        }
      }
      >
        <Box sx={CUSTOM_BOX_STYLE}>
          <span className="containerTitle">Add Folder</span>
          <div className="inputField">
            <InputLabel className="inputLabel" htmlFor="folderName">
              Folder Name
            </InputLabel>
            <TextField
              id="folderName"
              variant="outlined"
              placeholder="Enter the new folder name"
              name="folderName"
              inputRef={newFolderNameRef}
              type="text"
              required
              className={styles.inputField}
            />
          </div>
          <Button
            className="LoginFormSignInButtonChangePassword"
            type="submit"
            variant="primary"
          >
            {isCreatingFolder ? <CircularProgress size={20} /> : 'Create Folder'}
          </Button>
          <Button
            className="LoginFormOutlineButton"
            type="button"
            variant="light"
            onClick={() => setShowCreatingFolderModal(false)}
          >
            Cancel
          </Button>
        </Box>
      </form>
    </Modal>
  );
};

export default StudyDocumentsCreateFolderModal;
