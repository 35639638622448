import React from 'react';
import { FontDownload } from '@styled-icons/material/FontDownload';
import { FormatColorText } from '@styled-icons/material/FormatColorText';
import { Check } from '@styled-icons/material/Check'

import { ColorPickerToolbarDropdown, MARK_COLOR, MARK_BG_COLOR } from '@udecode/plate';

import tooltip from '../tooltip/tooltip';

// eslint-disable-next-line arrow-body-style
const ColorButtons = () => {
  return (
    <>
      <ColorPickerToolbarDropdown
        pluginKey={MARK_COLOR}
        icon={<FormatColorText />}
        selectedIcon={<Check />}
        tooltip={tooltip('Text Color')}
      />
      <ColorPickerToolbarDropdown
        pluginKey={MARK_BG_COLOR}
        icon={<FontDownload />}
        selectedIcon={<Check />}
        tooltip={tooltip('Highlight Color')}
      />
    </>
  );
};

export default ColorButtons;
