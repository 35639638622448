import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
import React, {
  useState,
} from 'react';
import {
  Form,
} from 'react-bootstrap';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import {
  CUSTOM_BOX_STYLE, EMAIL_DOMAINS_FOR_INTERNAL_USERS, FONT_FAMILY, AUDIT_TRAIL_EVENT_TYPES, BRAND_COLORS,
} from '../../../constants';
import { useGraphQL } from '../../../providers/graphql';
import { noop } from '../../utils';
import { createMessageForSQS } from '../../utils.createMessage';
import { useSQS } from '../../../providers/sqs';

function ModifyWhiteListModal({
  companyAccountId, // Account id to modify.
  companyName, // Company name whose domains are being modified.
  userId, // User modifying the domains.
  showModal = true, // Whether to show modal or not.
  initialDefaultValue = '', // Initial value to set for input field.
  setExternalLoader = noop, // helper function for parent to show their own loader.
  setShowModal = noop, // helper function to toggle showModal state.
  sendData = noop, // helper function for parent to receive data after message is sent.
  setSnackbarMessage = noop, // helper function for parent to set a snack bar message type (success/error).
  setShowSnackBar = noop, // helper function for parent to show snack bar message.
}) {
  const [whitelistedDomains, setWhitelistedDomains] = useState(initialDefaultValue)
  const [whitelistedDomainsError, setWhitelistedDomainsError] = useState('')
  const [disableConfirmButton, setDisableConfirmButton] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const { updateCompanyAccount } = useGraphQL();

  const { sendMessageToQueueForAudits } = useSQS()

  const onHide = () => {
    setWhitelistedDomains('')
    setWhitelistedDomainsError('');
    setDisableConfirmButton(true);
    setIsLoading(false);
    setExternalLoader(false);
    setShowModal(false);
  };

  const onSubmit = async (event) => {
    // To prevent refresh of page.
    event.preventDefault();
    event.stopPropagation();
    // --------------------------
    // prevent double click
    setIsLoading(true)
    setExternalLoader(true)
    // get information
    try {
      // NOTE: Adding the audit logic before so that we can check following scenarios
      // 1. To make sure an audit call is absolutely sent without error for a modification
      // and to error out if not possible.
      // 2. To have an audit trail created even if the actual modification call fails for
      // any reason.
      const messageBody = await createMessageForSQS({
        eventType: AUDIT_TRAIL_EVENT_TYPES.WHITELISTED_DOMAINS_UPDATED,
        userId,
        companyName,
        attributes: [{
          whitelistedDomainsBefore: initialDefaultValue,
          whitelistedDomainsAfter: whitelistedDomains,
        }],
      });
      await sendMessageToQueueForAudits(messageBody)
    } catch (error) {
      setSnackbarMessage('error')
      setShowSnackBar({ show: true, text: 'Could not update domains due to technical issue.' })
      onHide();
      return
    }

    try {
      const payload = {
        id: companyAccountId,
        whitelistedDomains: whitelistedDomains.split(','),
      }
      const response = await updateCompanyAccount(payload)
      const data = response.data.updateCompanyAccount

      setSnackbarMessage('success')
      setShowSnackBar({ show: true, text: 'Email Whitelist Modified Successfully!' })
      sendData(data.whitelistedDomains)
      onHide();
    } catch (error) {
      setSnackbarMessage('error')
      setShowSnackBar({ show: true, text: 'Could not update domains due to technical issue.' })
      onHide();
    }

  }

  const whitelistedDomainsOnChange = (event) => {
    const {
      target: { value },
    } = event;
    setWhitelistedDomains(value)
    let noValidationError = true
    if (value === '' || value === initialDefaultValue) {
      noValidationError = false
      setWhitelistedDomainsError('')
      setDisableConfirmButton(true)
    } else {
      const domains = value.split(',')
      domains.map((val) => {
        if (val.length === 0 || (!(/^@[a-zA-Z0-9 \- ]{2,61}(\.[a-zA-Z]{2,})+$/.test(val)))) {
          setWhitelistedDomainsError('Improper format. Please enter the whitelist domain in proper format. Example: @yourcompany.com')
          setDisableConfirmButton(true)
          noValidationError = false
        } else if (EMAIL_DOMAINS_FOR_INTERNAL_USERS.includes(val)) {
          setWhitelistedDomainsError('Not allowed to use this email domain. Please use another email domain.')
          setDisableConfirmButton(true)
          noValidationError = false
        }
        return null
      })
    }
    if (noValidationError) {
      setWhitelistedDomainsError('')
      setDisableConfirmButton(false)
    }
  }

  const renderFormOrLoader = () => {
    if (isLoading) {
      return (
        <>
          <div style={{ textAlign: 'center' }}>
            <Typography className={`${FONT_FAMILY}-Regular containerTitle`}>
              Processing...
            </Typography>
            <CircularProgress size="3rem" style={{ color: BRAND_COLORS.orange }} />

          </div>
        </>
      )
    }
    return (
      <Form onSubmit={onSubmit}>
        <Form.Group
          controlId="formDomainWhiteListing"
        >
          <Form.Label className="addAccount-formLabel" data-testid="whitelisted_email_domain">Whitelisted Email Domain</Form.Label><span style={{ content: ' *', color: 'red' }}><sup> *</sup></span>
          <Form.Control
            name="domainWhiteListing"
            data-testid="test-input-control"
            style={{ height: '45px', marginBottom: '-20px' }}
            as="input"
            defaultValue={initialDefaultValue}
            placeholder="@1stdomain.com,@2nddomain.com,@3rddomain.com"
            required
            onChange={whitelistedDomainsOnChange}
          />
          <p
            style={{ color: '#E2AAA0', marginTop: '20px' }}
            data-testid="email_error"
          > {whitelistedDomainsError}
          </p>
        </Form.Group>
        <Button
          className="LoginFormSignInButtonChangePassword"
          type="submit"
          data-testid="test-modify-whitelist-confirm"
          variant="primary"
          disabled={disableConfirmButton}
        >
          Confirm Whitelisted Domains
        </Button>

        <Button
          className="LoginFormOutlineButton"
          type="button"
          variant="light"
          onClick={onHide}
          data-testid="test-modify-whitelist-cancel"
        >
          Cancel
        </Button>
      </Form>
    )
  }
  return (
    <Modal open={showModal} data-testid="test-modify-whitelist-modal" aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box sx={CUSTOM_BOX_STYLE}>
        <div style={{ textAlign: 'center' }}>
          <span className="containerTitle" data-testid="container_Title">Modify Domains</span>
        </div>
        {renderFormOrLoader()}
      </Box>
    </Modal>
  );
}

export default ModifyWhiteListModal;
