import React, { createContext, useCallback, useRef } from 'react';
import { Amplify, Auth } from 'aws-amplify';
import { SQSClient, SendMessageCommand, GetQueueUrlCommand } from '@aws-sdk/client-sqs';
import { AUDIT_TRAIL_SQS_QUEUE, SQS_DATA_COLLECTION } from '../constants';
import awsExports from '../aws-exports'; // eslint-disable-line import/extensions

const amplifyConfig = Amplify.configure(awsExports);
export const SQSContext = createContext();
export const SQSProvider = function SQSProvider({ children }) {

  /** @type {import('react').MutableRefObject<{}>} */
  const queueUrls = useRef({});

  const getAuthenticatedSQSClient = useCallback(async () => {
    try {
      const creds = await Auth.currentCredentials();
      const client = new SQSClient({
        apiVersion: '2012-11-05',
        credentials: Auth.essentialCredentials(creds),
        region: amplifyConfig.aws_project_region,
      });
      return client;
    // eslint-disable-next-line no-empty
    } catch (e) {}
    return null;
  }, []);

  const sendMessageToQueue = useCallback((QueueName) => async (message = '', queueUrl = '') => {
    try {
      const sqsClient = await getAuthenticatedSQSClient();
      let queue = queueUrl;
      if (!queue && !queueUrls.current[QueueName]) {
        const response = await sqsClient.send(new GetQueueUrlCommand({ QueueName }));
        const url = response.QueueUrl;
        queue = url;
        queueUrls.current[QueueName] = url;
      }
      const params = {
        MessageBody: JSON.stringify(message),
        QueueUrl: queue || queueUrls.current[QueueName],
      };
      return sqsClient.send(new SendMessageCommand(params));
    // eslint-disable-next-line no-empty
    } catch (error) {}
    return null;
  }, [getAuthenticatedSQSClient]);

  return (
    <SQSContext.Provider
      value={{
        sendMessageToQueueForAudits: sendMessageToQueue(AUDIT_TRAIL_SQS_QUEUE),
        sendMessageToQueueForAnalytics: sendMessageToQueue(SQS_DATA_COLLECTION),
      }}
    >
      {children}
    </SQSContext.Provider>
  )
}

export const useSQS = () => {
  const context = React.useContext(SQSContext);

  if (context === undefined) {
    throw new Error(
      '`useSQS` hook must be used within a `SQSProvider` component',
    );
  }
  return context;
};
