import React, { createContext, useState } from 'react'

export const ClinicalObservationLegendContext = createContext()

export const ClinicalObservationLegendProvider = function ({ children }) {
  const [selectedCoCodes, setSelectedCoCodes] = useState([])

  return (
    <ClinicalObservationLegendContext.Provider
      value={{
        selectedCoCodes, setSelectedCoCodes,
      }}
    >
      {children}
    </ClinicalObservationLegendContext.Provider>
  )
}

export const useClinicalObservationLegendContext = () => {
  const context = React.useContext(ClinicalObservationLegendContext)

  if (context === undefined) {
    throw new Error(
      '`useClinicalObservationLegendContext` hook must be used within a `ClinicalObservationLegendContextProvider` component',
    )
  }
  return context
}
