import React, { useMemo, useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';

import { Tooltip } from '@mui/material';
import Menu from '@mui/material/Menu';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import { styled } from '@mui/styles';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Snackbar from '@mui/material/Snackbar';
import { Button as Btn, Spinner, Row } from 'react-bootstrap';
import CircularProgress from '@mui/material/CircularProgress';
import AddAccountModal from './AddAccount'
import ModelDataModal from './ModelData'
import AddAccountAdminsModal from './AddAccountAdmins'

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import { BRAND_COLORS, DEFAULT_GRIDOPTS } from '../../../constants';
import { useGraphQL } from '../../../providers/graphql';

const ColorButton = styled(Button)(() => ({
  color: '#222222',
  backgroundColor: '#FFFFFF',
  border: `1px solid ${BRAND_COLORS.orange}`,
  borderRadius: '4px',
  marginRight: '12px',
  fontFamily: 'AvenirNextLTPro-Medium',
  marginBottom: '25px',
  '&:hover': {
    backgroundColor: '#F9A868',
    borderRadius: '4px',
    border: '1px solid transparent',
    fontFamily: 'AvenirNextLTPro-Medium',
  },
}));

export const StatusCellRenderer = ({ value }) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <Avatar style={{ marginRight: '8px' }} sx={{ width: 15, height: 15 }} data-testid="status-indicator"> </Avatar>
    {value}
  </div>
);

export const StudyIdentifierCellRenderer = ({ value }) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    { /* eslint-disable-next-line */}
    {value.length ? (value[0].split(':').length > 1 ? value[0].split(':')[1] : value[0]) : ''}
  </div>
)

export const ActionsCellRenderer = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const ITEM_HEIGHT = 48;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        size="large"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}
      >
        <MenuItem data-testid="disable-opt" onClick={handleClose}>Disable Account</MenuItem>
        <MenuItem data-testid="modify-opt" onClick={handleClose}>Modify Account</MenuItem>
        <MenuItem data-testid="enable-opt" onClick={handleClose}>Enable Account</MenuItem>
      </Menu>
    </div>
  );
}

const headerHeightGetter = () => {
  const columnHeaderTexts = [
    ...document.querySelectorAll('.ag-header-cell-text'),
  ];
  const clientHeights = columnHeaderTexts.map((headerText) => headerText.clientHeight);
  const tallestHeaderTextHeight = Math.max(...clientHeights);

  return tallestHeaderTextHeight;
};

const headerHeightSetter = (params) => {
  const padding = 24;
  const height = headerHeightGetter() + padding;
  params.api.setHeaderHeight(height);
};

export const demoAccountsData = [{
  account_name: 'Old Ac # 1',
  study_identifier: '2',
  status: 'Active',
  total_admins: 2,
  total_users: 12,
  active_users: 8,
  inactive_users: 4,
  total_studies: 78,
  whitelisted_domain: 'pfizer.com',
}, {
  account_name: 'Old Ac # 2',
  study_identifier: '3',
  status: 'Active',
  total_admins: 3,
  total_users: 35,
  active_users: 27,
  inactive_users: 8,
  total_studies: 138,
  whitelisted_domain: 'merck.com',
}];

const getGridOptions = (companyAccounts, AccountCellRenderer) => ({
  ...DEFAULT_GRIDOPTS,
  rowData: companyAccounts,
  headerHeight: 60,
  rowHeight: 50,
  overlayNoRowsTemplate: 'No Data To Display',
  suppressDragLeaveHidesColumns: true,
  suppressContextMenu: true,
  suppressMultiSort: true,
  defaultColDef: {
    flex: 1,
    resizable: true,
    sortable: true,
    wrapText: true,
    autoHeight: true,
    minWidth: 100,
    lockPinned: true,
    suppressMenu: true,
    headerClass: ['text-align-center', 'justify-content-center', 'wrap-header'],
    cellClass: ['centered-cell-content'],
    tooltipValueGetter: (data) => {
      const value = data.valueFormatted || data.value;
      return [null, undefined].includes(value) ? '' : value;
    },
  },
  components: {
    accountCellRenderer: AccountCellRenderer,
    statusCellRenderer: StatusCellRenderer,
    actionsCellRenderer: ActionsCellRenderer,
    studyIdentifierCellRenderer: StudyIdentifierCellRenderer,
  },
  onColumnResized: headerHeightSetter,
  onFirstDataRendered: (params) => {
    params.api.sizeColumnsToFit();
    headerHeightSetter(params);
  },
  onGridReady: (params) => {
    params.api.sizeColumnsToFit();
  },
  columnDefs: [
    {
      headerName: 'Account Name',
      field: 'name',
      cellRenderer: 'accountCellRenderer',
      minWidth: 150,
    },
    { headerName: 'Study Identifier', field: 'studyIdentifiers', cellRenderer: 'studyIdentifierCellRenderer' },
    { headerName: 'Status', field: 'status', cellRenderer: 'statusCellRenderer' },
    { headerName: 'Total Admins', field: 'total_admins' },
    { headerName: 'Total Users', field: 'total_users' },
    { headerName: 'Active Users', field: 'active_users' },
    { headerName: 'Inactive Users', field: 'inactive_users' },
    { headerName: 'Total Studies', field: 'total_studies' },
    {
      headerName: 'Whitelisted Domain', field: 'whitelistedDomains', minWidth: 150, cellClass: 'truncate',
    },
    { headerName: 'Actions', sortable: false, cellRenderer: 'actionsCellRenderer' },
  ],
});

const AdminCenter = () => {
  // const [rowData, setRowData] = useState([]);
  const [showResponseMessage, setShowResponseMessage] = useState(false)
  const [messageType, setMessageType] = useState('None')
  const [companyAccounts, setCompanyAccounts] = useState([])
  const [loading, setLoading] = useState(true)
  const [loader, setShowLoader] = useState(false)
  const [showAddAccountModal, setAddAccountModal] = useState(false);
  const [showModelDataModal, setShowModelDataModal] = useState(false);
  const [showAddAccountAdminModal, setshowAddAccountAdminModal] = useState(false);
  const [whiteListedDomains, setwhiteListedDomains] = useState([])
  const [createdCompany, setCreatedCompany] = useState('')
  const {
    createCompanyAccount, getCompanyAccountForSystemAdmin, getCompanyNames, getStudyIdentifierForCompanyName, getCompanyInfoById, createUserForCompany,
  } = useGraphQL()

  const AccountCellRenderer = ({ value }) => {
    const history = useHistory();
    const rowData = companyAccounts.find((val) => val.name === value);
    return (
      <Tooltip title={value} disableInteractive>
        <Button
          style={{
            color: 'blue', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', textDecoration: 'underline',
          }}
          onClick={() => history.push(`/admin-center?company=${encodeURIComponent(rowData.id)}`)}
        >{value}
        </Button>
      </Tooltip>

    );

  }
  // eslint-disable-next-line
  const gridOptions = useMemo(() => getGridOptions(companyAccounts, AccountCellRenderer), [companyAccounts]);

  const breadcrumbs = [
    <Link underline="hover" className="study-details-breadcrumb-homelink" key="1" to="/landing" data-testid="landing-crumb">
      <span><em>Study </em></span>List
    </Link>,

    <Typography className="bc-study-det" key="3" data-testid="system-admin-crumb">
      System Admin Center
    </Typography>,
  ];

  useEffect(() => {
    const populateData = async () => {
      setLoading(true);
      let companies = [];
      let nextToken = null;
      // eslint-disable-next-line no-constant-condition
      while (true) {
        // eslint-disable-next-line no-await-in-loop
        const response = await getCompanyAccountForSystemAdmin(nextToken);
        if (response.data && response.data.listCompanyAccounts) {
          companies = companies.concat(response.data.listCompanyAccounts.items);
          nextToken = response.data.listCompanyAccounts.nextToken;
          if (!nextToken) {
            break;
          }
        }
      }
      setCompanyAccounts(companies);
      setLoading(false);
    }
    populateData()
    // eslint-disable-next-line
  }, [])

  const companyCreationConfirmed = (name, studyIdentifiers, whitelistedDomains) => setCompanyAccounts([{ name, studyIdentifiers, whitelistedDomains }, ...companyAccounts])

  const setwhiteListedDomainsForAdminCheck = (whiteListedDomainsToSet) => {
    setwhiteListedDomains(whiteListedDomainsToSet);
  };
  const setCreatedCompanyValue = (CreatedCompanyId) => {
    setCreatedCompany(CreatedCompanyId);
  };

  const toggleAddAccountAdminModal = () => {
    setshowAddAccountAdminModal(!showAddAccountAdminModal);
  };

  const toggleAddAccountModal = () => {
    setAddAccountModal(!showAddAccountModal);
  };

  const toggleModelDataModal = () => {
    setShowModelDataModal((curr) => !curr);
  };

  const showUpdatePreferencesResult = (errorAlert) => {
    const message = {
      success: (
        <div className="LoginForm-SuccessMessage successText" style={{ padding: '2px 5px', width: '250%' }}>
          <div className="LoginForm-SuccessMessageText Mng-not-msg-txt">Company account has been successfully created.</div>
        </div>
      ),
      duplicate: (
        <div className="LoginForm-ErrorMessage errorText" style={{ padding: '2px 5px', width: '250%' }}>
          <div className="LoginForm-ErrorMessageText Mng-not-msg-txt">Account creation failed.  Duplicate accounts cannot be created.</div>
        </div>
      ),
      error: (
        <div className="LoginForm-ErrorMessage errorText" style={{ padding: '2px 5px', width: '250%' }}>
          <div className="LoginForm-ErrorMessageText Mng-not-msg-txt">Error.  Please try again.</div>
        </div>
      ),
      invited: (
        <div className="LoginForm-SuccessMessage successText" style={{ padding: '2px 5px', width: '250%' }}>
          <div className="LoginForm-SuccessMessageText Mng-not-msg-txt">Invite email sent to admin(s).</div>
        </div>
      ),
      doNothing: null,
    }
    return (
      <Stack sx={{ width: '100%' }} spacing={2}> {message[errorAlert]} </Stack>
    )
  }

  return (
    <div className="landing-inner-container-mphs">

      <Stack spacing={1}>
        <Breadcrumbs
          underline="none"
          className="bc-margin-bl"
          sx={{ fontSize: '14px', marginTop: '15px' }}
          separator={<img src="../landing/arrow_right.svg" alt="logo" />}
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Stack>
      <div className="d-flex" data-testid="system-admin-title">
        <span className="icon-txt AvenirNextLTProRegular">&nbsp;System Admin Center</span>
      </div>

      <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}>
        <div>
          <ColorButton disabled={loader} data-testid="manage-model-data-btn" variant="outlined" onClick={toggleModelDataModal}>Manage Model Data</ColorButton>
          <ColorButton disabled={loader} data-testid="add-account" variant="outlined" onClick={toggleAddAccountModal}>{loader ? 'Creating Account' : 'Add Account'}</ColorButton>
          {loader && <CircularProgress />}
        </div>
      </div>

      <Snackbar
        style={{ position: 'unset' }}
        open={showResponseMessage}
        autoHideDuration={2500}
        onClose={() => setShowResponseMessage(false)}
        message={showUpdatePreferencesResult(messageType)}
      />

      <div className="ag-theme-alpine" style={{ height: '500px', width: '100%', marginBottom: '32px' }} data-testid="system-admin-grid">
        {
          loading ? (
            <Row className="justify-content-center">
              <Btn variant="primary" disabled>
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                Loading Company Accounts...
              </Btn>
            </Row>
          ) : (
            <AgGridReact
              headerHeight="60"
              rowHeight="60"
              {...gridOptions}
            />
          )
        }
      </div>
      {
        !loading && <ModelDataModal showModelDataModal={showModelDataModal} toggleModelDataModal={toggleModelDataModal} />
      }
      {
        !loading && <AddAccountModal companyCreationConfirmed={companyCreationConfirmed} getStudyIdentifierForCompanyName={getStudyIdentifierForCompanyName} companyAccounts={companyAccounts} getCompanyNames={getCompanyNames} setShowLoader={setShowLoader} setShowResponseMessage={setShowResponseMessage} setMessageType={setMessageType} createCompanyAccount={createCompanyAccount} showAddAccountModal={showAddAccountModal} toggleAddAccountModal={toggleAddAccountModal} toggleAddAccountAdminModal={toggleAddAccountAdminModal} setwhiteListedDomainsForAdminCheck={setwhiteListedDomainsForAdminCheck} setCreatedCompanyValue={setCreatedCompanyValue} />
      }
      {
        !loading && <AddAccountAdminsModal showAddAccountAdminModal={showAddAccountAdminModal} toggleAddAccountAdminModal={toggleAddAccountAdminModal} whiteListedDomains={whiteListedDomains} createdCompany={createdCompany} setMessageType={setMessageType} getCompanyInfoById={getCompanyInfoById} createUserForCompany={createUserForCompany} setShowLoader={setShowLoader} setShowResponseMessage={setShowResponseMessage} />
      }

    </div>
  );
};

export default AdminCenter;
