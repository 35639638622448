import React from 'react';
import { Button, Row, Spinner } from 'react-bootstrap'
import { AgGridReact } from 'ag-grid-react'
import _ from 'lodash'

const DosingRecordPanel = function ({
  dosingRecordIndividualGridOptions, dosingRecordIndividualFetching, showTable,
}) {
  const spinner = (
    <Row className="justify-content-center">
      <Button variant="primary" disabled>
        <Spinner
          as="span"
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
        />
        Loading Dosing Record Metrics...
      </Button>
    </Row>
  );

  return (

    <div>
      <hr className="mlr-10" />
      <div style={dosingRecordIndividualFetching ? { display: 'none' } : {}}>
        <div style={(showTable && !_.isEmpty(dosingRecordIndividualGridOptions) && dosingRecordIndividualGridOptions.rowData) ? {} : { display: 'none' }}>
          <h5 className="text-center text-md-left">
            Dosing Record
          </h5>
          <div
            className="ag-theme-alpine"
            style={{
              width: '100%',
              height: '450px',
              margin: 'auto',
            }}
          >
            {!_.isEmpty(dosingRecordIndividualGridOptions) && dosingRecordIndividualGridOptions.onGridReady
              ? <AgGridReact gridOptions={dosingRecordIndividualGridOptions} /> : spinner}
          </div>
        </div>
        {/* error handle */}
        {(!showTable || _.isEmpty(dosingRecordIndividualGridOptions) || !dosingRecordIndividualGridOptions.onGridReady) && <div>No Data Available</div>}
      </div>
      {dosingRecordIndividualFetching && spinner}
      {/* <hr className="mlr-10" /> */}
    </div>
  );
}

export default DosingRecordPanel;
