import React from 'react';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { CUSTOM_BOX_STYLE } from '../../../../../../constants';

const StudyDocumentsDeleteFolderModal = function (props) {
  const {
    showDeleteFolderModal,
    setShowDeleteFolderModal,
    deleteFolderName,
    isDeletingFolder,
    onDeleteConfirmation,
  } = props;

  return (
    <Modal open={showDeleteFolderModal}>
      <Box sx={CUSTOM_BOX_STYLE}>
        <div className="containerTitle">Delete Folder</div>
        <div className="containerSubTitle">
          If you delete this folder, then all contents within the folder will be
          deleted. Do you wish to proceed?
        </div>
        <Button
          className="LoginFormSignInButtonChangePassword"
          type="submit"
          variant="primary"
          onClick={() => onDeleteConfirmation(deleteFolderName)}
        >
          {isDeletingFolder ? <CircularProgress size={20} /> : 'Confirm'}
        </Button>
        <Button
          className="LoginFormOutlineButton"
          type="button"
          variant="light"
          onClick={() => setShowDeleteFolderModal(false)}
        >
          Cancel
        </Button>
      </Box>
    </Modal>
  );
};

export default StudyDocumentsDeleteFolderModal;
