import React, {
  useState, useEffect, useMemo, useRef, useCallback,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Box,
  Menu,
  MenuItem,
} from '@mui/material';
import _ from 'lodash';
import { styled, withStyles } from '@mui/styles';
import PasswordIcon from '@mui/icons-material/Password';
import SettingsIcon from '@mui/icons-material/Settings';
import Home from '@mui/icons-material/Home';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import MessageIcon from '@mui/icons-material/Message';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useAuth } from '../providers/auth';
import { useS3 } from '../providers/s3';
import { useGraphQL } from '../providers/graphql';
import NavbarDropdown from './components/dropdown/NavbarDropdown';
import WarningPrompt from './components/documents/WarningPrompt';
import { getCurrentTimeInSeconds } from './utils';
import { createMessageForSQS } from './utils.createMessage';
import {
  AUDIT_TRAIL_EVENT_TYPES, GROUPS, NOTIFICATION_EVENT_TYPE,
} from '../constants';
import { useSQS } from '../providers/sqs';
import ExportAuditTrail from './ExportAuditTrail/ExportAuditTrail';
import ReportGeneration from './ReportGeneration';
import Logo from './components/auth/LogoCrownlink.png'
import HelpNew from './HelpContent/HelpNew';
import { ReactComponent as IconFeedback } from './IconFeedback.svg';
import GetSystemMessagesModal from './components/admin/SystemMessages/GetSystemMessages';

let flName = '  ';
// eslint-disable-next-line no-unused-vars
let fName = ' ';

const LightTooltip = styled(({ className, ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Tooltip {...props} classes={{ popper: className }} disableInteractive />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#FFFBEC',
    color: '#222222',
    top: '20px',
    border: '1px solid #FEE7D5',
    borderRadius: '2px',
    fontSize: '12px',
  },
}));

const styles = (theme) => ({
  margin: {
    margin: theme.spacing(2),
  },
  customBadge: {
    backgroundColor: '#FFD200',
    color: '#222222',
    top: '3px',
    right: '5px',
    fontWeight: 'bold',
  },
});

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    backgroundColor: '#002334',
    borderRadius: '0px 12px',
    marginTop: 40,
    minWidth: '200px',
    color: '#444444',
    boxShadow: '0px 4px 10px #00000033;',
    '& .MuiMenu-list': {
      py: 16,
      pl: 16,
    },
    '& .MuiMenuItem-root': {
      fontFamily: 'AvenirNextLTPro-Medium',
      fontSize: 14,
      color: '#FFFFFFDE',
      marginTop: theme.spacing(0),
      '& .MuiSvgIcon-root': {
        fontSize: 32,
        color: '#002334',
        backgroundColor: '#FFFFFF',
        borderRadius: '0px 6px',
        paddingTop: '4px',
        paddingBottom: '4px',
        marginRight: 16,
      },
      '&:active': {
        backgroundColor: '#002334',
      },
      '&:hover': {
        backgroundColor: '#002334',
      },
      '&:selected': {
        backgroundColor: '#002334',
      },
    },
  },
}));

/* const messages = Array(5)
  .fill(1)
  .map((item, index) => ({
    message:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore dolore et.",
    updatedOn: `${index} minutes ago`,
  })); */

const NavHeader = function NavHeader(props) {
  const history = useHistory();

  const {
    signOut, user, hasGroup, getSystemMessagesNotifications, setGetSystemMessagesNotifications,
  } = useAuth();
  const {
    uploadProgress,
    currentUploadId,
    smallFileLoader,
    setFlushUploadFile,
  } = useS3();
  const location = useLocation()

  const [name, setName] = useState('')
  const [helpData, setHelpData] = useState();
  const [firstName, setFirstName] = useState('')
  const { sendMessageToQueueForAudits } = useSQS();
  const [systemMessages, setSystemMessages] = useState([])
  const [showSystemMessagesModal, setShowSystemMessagesModal] = useState(false)
  const [showModalWarning, setShowModalWarning] = useState(false)

  const canViewInAppNotifications = hasGroup([GROUPS.EXTERNAL, GROUPS.STUDY_DIRECTOR, GROUPS.JUNIOR_STUDY_DIRECTOR, GROUPS.BUSINESS_DEVELOPMENT, GROUPS.BIOMARKER, GROUPS.GENERAL_ACCESS]);
  const showCompanyName = useMemo(() => {
    if (user && user.groups && user.groups.length) {
      return user.groups.includes(GROUPS.ALL_PROJECTS)
    }
    return false
  }, [user])

  const showNotificationForStudyDirector = useMemo(() => {
    if (user && user.groups && user.groups.length) {
      return user.groups.includes(GROUPS.STUDY_DIRECTOR) || user.groups.includes(GROUPS.JUNIOR_STUDY_DIRECTOR);
    }
    return false
  }, [user]);

  const showInternalComments = useMemo(() => {
    if (user && user.groups && user.groups.length) {
      return hasGroup([GROUPS.STUDY_DIRECTOR, GROUPS.JUNIOR_STUDY_DIRECTOR, GROUPS.BUSINESS_DEVELOPMENT]);
    }
    return false;
  }, [user, hasGroup]);

  const showExtAuditTrail = useMemo(() => {
    if (user && user.groups && user.groups.length) {
      return user.groups.includes(GROUPS.ADMIN)
    }
    return false
  }, [user])

  const showReport = useMemo(() => {
    if (user && user.groups && user.groups.length) {
      return user.groups.includes(GROUPS.ADMIN)
    }
    return false
  }, [user])

  const {
    loadedNotificationEvents, loadedActionEvents, userPreferences, createActionEventAndAppendToArray, getHeaderDataApi, getSystemMessagesReception, getAllNotificationsForUser, refreshUserName,
  } = useGraphQL()

  // const { classes } = props;

  const [showNotification, setShowNotification] = useState(false)
  // clickAwayHandler = () => setShowNotification(false);
  // const notes = messages

  const onNotificationClick = (projectCode, eventId, notificationType, isRead) => {
    const id = (Math.random() + 1).toString(36).substring(3);
    if (!isRead) {
      createActionEventAndAppendToArray({
        id, eventId, userId: user.id, action: 'READ', createdAt: getCurrentTimeInSeconds(),
      })
    }
    if (Object.values(NOTIFICATION_EVENT_TYPE.REDIRECT_TO_DASHBOARD).includes(notificationType)) {
      history.push(`/details/${projectCode}`)
    }
  }

  const auditNotificationInteraction = async (projectCode, companyName, notificationId) => {
    if (user && user.id) {
      const messageBody = await createMessageForSQS({
        eventType: AUDIT_TRAIL_EVENT_TYPES.NOTIFICATION_INTERACTION,
        userId: user.id,
        projectId: projectCode,
        companyName,
        attributes: [{ notificationId }],
      })
      sendMessageToQueueForAudits(messageBody)
    }
  }

  const onClickManageNotifications = () => {
    history.push('/manage-notifications')
  }

  const onClickMarkAllAsRead = () => {
    const id = (Math.random() + 1).toString(36).substring(3);
    createActionEventAndAppendToArray({
      id, eventId: null, userId: user.id, action: 'MARK_ALL_READ', createdAt: getCurrentTimeInSeconds(),
    })
  }

  const toggleFeedback = () => {
    const { toggleFeedbackForm } = props;
    toggleFeedbackForm();
  }

  const toggleChangePasswordModal = () => {
    const { toggleChangePassword } = props;
    toggleChangePassword();
    handleClose();
  };

  /* const handleNotification = () => {
    setShowNotification(true);
  }; */

  // const [anchorEl1, setAnchorEl1] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  // const [anchorEl, setAnchorEl] = React.useState(null);
  // const open = Boolean(anchorEl);
  // eslint-disable-next-line no-unused-vars
  const handleClose = () => {
    // setAnchorEl1(null);
    setAnchorEl2(null);
  };

  /* const closeHelp = () => {
    setAnchorEl1(null);
  }; */

  /* const NotificationPopper = ({ showNotification, clickAwayHandler }) => (
    <ClickAwayListener onClickAway={clickAwayHandler}>
      <Popper open={showNotification}>
        <Box className="popper">
          <div className="notifications">
            <div className="noti_text">Notifications</div>
            <div className="noti_actions">
              <img src={MARK_ALL} className="noti_icons mark_all_icon" alt="Mark All"/>
              <img src={VIEW_ALL} className="noti_icons" alt="View All"/>
              <img src="" className="noti_icons mark_all_icon" alt="Mark All"/>
              <img src="" className="noti_icons" alt="View All"/>
            </div>
          </div>
          <Grid
            sx={{
              height: "46vh",
              overflowY: "auto",
              margin: "10px 16px 20px 21px",
            }}
          >
            {notes?.length
              ? notes.map(({ message, updatedOn }) => (
                  <div className="notes">
                    <div className="notes_icon">
                      <CircleIcon sx={{ color: "#EEB200", fontSize: "10px" }} />
                    </div>
                    <div className="notes_container">
                      <div className="notes_message"> {message}</div>
                      <div className="notes_update"> {updatedOn}</div>
                    </div>
                  </div>
                ))
              : "No notifications available"}
          </Grid>
        </Box>
      </Popper>
    </ClickAwayListener>
  ); */

  const currentUser = useRef(null);

  const fetchHeaderData = useCallback(async () => {

    const processUser = (userData) => {
      currentUser.current = userData;
      if (currentUser.current && currentUser.current.first_name) {
        flName = currentUser.current.first_name[0] + currentUser.current.last_name[0]
        setName(`${currentUser.current.first_name} ${currentUser.current.last_name}`)
        fName = currentUser.current.first_name
        setFirstName(currentUser.current.first_name)

      }
    }

    const processApiData = (result) => {
      if (result?.data?.getUser) {
        processUser(result.data.getUser);
      }

      if (result?.data?.listHelps) {
        setHelpData(result.data.listHelps)
      }
    }

    if (!!refreshUserName || (user.id && !currentUser?.current?.first_name)) {
      try {
        const result = await getHeaderDataApi(user.id);
        processApiData(result);
      } catch (error) {
        processApiData(error);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, refreshUserName]);

  useEffect(() => {
    fetchHeaderData();
  }, [fetchHeaderData, refreshUserName]);

  useEffect(() => {
    if (user && userPreferences.notificationOptIn) {
      getAllNotificationsForUser(user, userPreferences.notificationOptIn);
    }
  }, [getAllNotificationsForUser, userPreferences, user])

  useEffect(() => {
    async function getSystemMessagesReceptionData() {
      if (!getSystemMessagesNotifications) { return }
      if (showSystemMessagesModal) { return }
      setShowSystemMessagesModal(false)
      const messages = await getSystemMessagesReception(user.id);
      const data = messages.data.getUserSystemMessages ? messages.data.getUserSystemMessages : []
      let flatMap = []
      if (data) {
        flatMap = data.map((item) => ({
          id: item.id,
          userId: item.userId,
          status: item.status,
          messageContent: item.message.messageContent,
          createdAt: item.message.createdAt,
        })).sort((a, b) => (Date.parse(a.createdAt) - Date.parse(b.createdAt)))
      }

      // set all
      setSystemMessages([...flatMap])
      setGetSystemMessagesNotifications(false)
      if (flatMap.length !== 0) { setShowSystemMessagesModal(true) }
    }

    getSystemMessagesReceptionData();
  }, [getSystemMessagesReception, user, showSystemMessagesModal, getSystemMessagesNotifications, setGetSystemMessagesNotifications])

  // eslint-disable-next-line no-unused-vars
  const renderTooltip = (prop) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Tooltip id="button-tooltip" {...prop} disableInteractive>
      Give Feedback
    </Tooltip>
  );
  const showAdminCenter = user.groups.includes(GROUPS.ACCOUNT_ADMIN)
  const hasSystemAdminGroup = user.groups.includes(GROUPS.ADMIN)
  const pushHistory = (path) => {
    if (location.pathname !== path) {
      history.push(path)
    }
    handleClose();
  }

  const renderSystemMessages = () => {
    if (!getSystemMessagesNotifications && !systemMessages) return <></>
    return (
      <GetSystemMessagesModal props={{ showModal: showSystemMessagesModal, toggleModal: () => { setShowSystemMessagesModal(!showSystemMessagesModal); setGetSystemMessagesNotifications(false); }, messageContents: systemMessages }} />
    )
  }

  const pushSystemMessagesHistory = () => pushHistory('/system-messages')
  const initSignOut = () => {
    setAnchorEl2(null)
    if (!!currentUploadId || smallFileLoader || !_.isEmpty(uploadProgress)) {
      setShowModalWarning(true)
    } else {
      signOut()
    }
  }
  const yesFunc = () => {
    // to cancel all the multipart api calls
    setFlushUploadFile(true)
  }

  return (
    <AppBar className="sticky-navbar" position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <WarningPrompt
        noText="Cancel"
        yesText="Continue"
        show={showModalWarning}
        noFunc={() => setShowModalWarning(false)}
        yesFunc={yesFunc}
        description="Logging out of CrownLink will stop the ongoing uploads. Do you wish to continue?"
      />
      <Toolbar className="toolbar">
        <Box className="logo-box" onClick={() => pushHistory('/landing')}>
          <img className="logo-img" src={Logo} alt="logo" />
          {/* <span className="logo-title AvenirNextLTProDemi">Crownlink</span> */}
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        {/* <Box className="nav-link">
          <span onClick={(e) => setAnchorEl1(e.currentTarget)}>
            <HelpOutlineRoundedIcon sx={{ fontSize: "32px" }} />
          </span>
          <Popover
            id="Help"
            keepMounted
            anchorEl1={anchorEl1}
            open={Boolean(anchorEl1)}
            onClose={() => setAnchorEl1(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
          >
            <Box
              sx={{
                p: 1,
                width: "40vw",
                height: "80vh",
                overflowY: "auto",
                position: "relative",
                right: "0",
                top: "81px",
                boxShadow: "0 2px 10px #666",
                bgcolor: "background.paper",
                color: "#666666",
                scrollBehavior: "auto",
                textAlign: "left",
              }}
            >

              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr auto",
                  gap: "10px",
                }}
              >
                <h2>Help and Feedback</h2>
                <HighlightOffIcon
                  className="close-icon"
                  size="small"
                  onClick={() => closeHelp()}
                >
                  Close
                </HighlightOffIcon>
              </Box>
              <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                <AccountCircle
                  sx={{ color: "action.active", mr: 1, my: 0.5 }}
                />
                <TextField
                  id="input-with-sx"
                  label="With sx"
                  variant="standard"
                />
              </Box>
              <Box>
                <TextField
                  className="input"
                  placeholder="User Nickname"
                  onChangeText={(searchString) => {
                    this.setState({ searchString });
                  }}
                  underlineColorAndroid="transparent"
                />
                <SearchIcon className="searchIcon" size={20} color="#000" />
              </Box>
              <Typography variant="h4" component="div" gutterBottom>
                Main Section Heading
              </Typography>
              <Typography variant="h5" component="div" gutterBottom>
                Sub-Section 1 Heading
              </Typography>
              <Typography variant="body1" gutterBottom>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Maecenas placerat placerat feugiat. Pellentesque semper aliquam
                justo, congue finibus sapien faucibus vitae. Etiam euismod augue
                sed libero vulputate blandit. Morbi hendrerit aliquet ex, auctor
                egestas arcu consequat vitae. Aliquam dignissim pharetra
                molestie. Nullam id libero non felis sagittis iaculis eu at
                mauris. Duis varius nisl eu turpis blandit ultricies.
              </Typography>
              <Typography variant="body1" gutterBottom>
                Morbi lectus risus, pulvinar a lobortis fringilla, hendrerit ut
                velit. Maecenas nibh nisi, commodo in tellus sed, tempor
                consequat ex. Suspendisse et imperdiet mauris. Nullam pulvinar
                facilisis nulla in tincidunt. Cras volutpat turpis et ligula
                mollis, at convallis justo lacinia. Cras pharetra aliquam
                tempus. Donec iaculis tellus cursus felis pellentesque, placerat
                porttitor lorem feugiat. Sed neque eros, rutrum at dapibus at,
                elementum blandit lorem. Curabitur id massa vestibulum, cursus
                orci vel, feugiat lorem. Praesent enim nunc, egestas vel velit
                et, condimentum dapibus ante. Nulla quis pellentesque libero.
                Vivamus consectetur tortor in risus volutpat efficitur vitae vel
                dolor. Etiam cursus in mauris eu luctus. Vestibulum tincidunt
                tortor quis cursus vehicula.
              </Typography>
              <Typography variant="body1" gutterBottom>
                Donec vestibulum feugiat neque et lobortis. Nunc a bibendum
                nulla. Sed in ex vitae tortor tempor pretium vitae id ligula.
                Nunc ut gravida arcu. Donec blandit nisl vel pretium suscipit.
                Maecenas semper arcu blandit est congue aliquam. Aenean varius
                et magna a consectetur. Ut rutrum, elit at egestas sodales, nisl
                est dignissim felis, id varius nisi ipsum sit amet lorem. Donec
                vulputate, tortor sed bibendum gravida, elit mauris condimentum
                ante, ac mollis nulla ante id quam. Maecenas rutrum tortor in
                justo blandit, quis lobortis metus pulvinar. Nam risus enim,
                aliquet ut malesuada at, imperdiet at leo. Nam posuere varius mi
                sed placerat. In mollis purus congue, pulvinar mi ac,
                ullamcorper tortor.
              </Typography>
              <Box>
                <img src="/landing/sp_landing.png" alt="logo" />
              </Box>
              <Typography variant="h5" component="div" gutterBottom>
                Sub-Section 1 Heading
              </Typography>
              <Typography variant="body1" gutterBottom>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Maecenas placerat placerat feugiat. Pellentesque semper aliquam
                justo, congue finibus sapien faucibus vitae. Etiam euismod augue
                sed libero vulputate blandit. Morbi hendrerit aliquet ex, auctor
                egestas arcu consequat vitae. Aliquam dignissim pharetra
                molestie. Nullam id libero non felis sagittis iaculis eu at
                mauris. Duis varius nisl eu turpis blandit ultricies.
              </Typography>
              <Typography variant="body1" gutterBottom>
                Morbi lectus risus, pulvinar a lobortis fringilla, hendrerit ut
                velit. Maecenas nibh nisi, commodo in tellus sed, tempor
                consequat ex. Suspendisse et imperdiet mauris. Nullam pulvinar
                facilisis nulla in tincidunt. Cras volutpat turpis et ligula
                mollis, at convallis justo lacinia. Cras pharetra aliquam
                tempus. Donec iaculis tellus cursus felis pellentesque, placerat
                porttitor lorem feugiat. Sed neque eros, rutrum at dapibus at,
                elementum blandit lorem. Curabitur id massa vestibulum, cursus
                orci vel, feugiat lorem. Praesent enim nunc, egestas vel velit
                et, condimentum dapibus ante. Nulla quis pellentesque libero.
                Vivamus consectetur tortor in risus volutpat efficitur vitae vel
                dolor. Etiam cursus in mauris eu luctus. Vestibulum tincidunt
                tortor quis cursus vehicula.
              </Typography>
              <Typography variant="body1" gutterBottom>
                Donec vestibulum feugiat neque et lobortis. Nunc a bibendum
                nulla. Sed in ex vitae tortor tempor pretium vitae id ligula.
                Nunc ut gravida arcu. Donec blandit nisl vel pretium suscipit.
                Maecenas semper arcu blandit est congue aliquam. Aenean varius
                et magna a consectetur. Ut rutrum, elit at egestas sodales, nisl
                est dignissim felis, id varius nisi ipsum sit amet lorem. Donec
                vulputate, tortor sed bibendum gravida, elit mauris condimentum
                ante, ac mollis nulla ante id quam. Maecenas rutrum tortor in
                justo blandit, quis lobortis metus pulvinar. Nam risus enim,
                aliquet ut malesuada at, imperdiet at leo. Nam posuere varius mi
                sed placerat. In mollis purus congue, pulvinar mi ac,
                ullamcorper tortor.
              </Typography>
              <Toolbar className="popper-footer">
                <div className="popper-list-footer">
                  <div>
                    <Box className="box-subhead">Related Links</Box>
                    <div>
                      <span className="bullet" />
                      <Link className="footer-link" href="/" color="inherit">
                        Related Link 01
                      </Link>
                    </div>
                    <div>
                      <Link className="footer-link" href="/" color="inherit">
                        Related Link 02
                      </Link>
                    </div>
                    <div>
                      <Link className="footer-link" href="/" color="inherit">
                        Related Link 03
                      </Link>
                    </div>
                    <div>
                      <Link className="footer-link" href="/">
                        Related Link 04
                      </Link>
                    </div>
                  </div>
                </div>
              </Toolbar>
            </Box>
          </Popover>
        </Box> */}
        {/* <Box className="nav-link">
          <NotificationsOutlined />
          <span className="badge-col">4</span>
        </Box> */}

        {/* // MPHASIS NOTIFICATION
         <Box className="nav-link">
          <span onClick={handleNotification}>
            <Badge
              badgeContent={notes?.length}
              classes={{ badge: classes.customBadge }}
            >
              <NotificationsOutlined sx={{ fontSize: "32px" }} />
            </Badge>
          </span>
          {showNotification && (
            <NotificationPopper {...{ clickAwayHandler, showNotification }} />
          )}
        </Box>
        //  MPHASIS NOTIFICATON - END

        */}
        {showReport ? <ReportGeneration user={user} name={name} /> : null}
        {showExtAuditTrail ? <ExportAuditTrail user={user} name={name} /> : null}
        {canViewInAppNotifications ? <NavbarDropdown auditNotificationInteraction={auditNotificationInteraction} onClickManageNotifications={onClickManageNotifications} showCompanyName={showCompanyName} onClickMarkAllAsRead={onClickMarkAllAsRead} show={showNotification} eventActions={loadedActionEvents} onClickItem={onNotificationClick} items={loadedNotificationEvents} onClick={() => { setShowNotification(!showNotification) }} idValue="main-header-notifications" showNotificationForStudyDirector={showNotificationForStudyDirector} showInternalComments={showInternalComments} /> : null}
        <HelpNew helpData={helpData} />
        <LightTooltip
          className="navHeader-GetFeedback-toolTips AvenirNextLTProMedium"
          title={(
            <>
              <Typography className="AvenirNextLTProRegular">
                Give Feedback
              </Typography>
            </>
          )}
          placement="bottom"
        >
          <Box className="nav-link help-content">
            <IconFeedback className="Header-FeedbackIcon" onClick={toggleFeedback} />
          </Box>
        </LightTooltip>

        <Box className="nav-link">
          <span className="user-profile-outer">
            <span className="user-name AvenirNextLTProRegular">
              Hi {(firstName.trim().length === 0) ? user.email.split('@')[0] : firstName}
            </span> {/* fallbacks to username part of the email id if name field is blank */}
          </span>
        </Box>
        <Box className="landing-userProfile-iconContainer">
          <span className="user-profile1">
            {/* currently takes first-two characters of the email address if user name is null */}
            {
              (flName.trim().length === 0)
                ? user.email.split('@')[0].charAt(0).toUpperCase() + user.email.split('@')[0].charAt(1).toUpperCase()
                : flName
            }
          </span>
        </Box>
        <Box className="landing-userProfile-drpDownContainer">
          <span
            className="drop-down"
            role="button"
            tabIndex={0}
            onClick={(e) => setAnchorEl2(e.currentTarget)}
            aria-hidden="true"
            data-testid="menu-icon"
          >
            <img src="/landing/arrow_down.svg" alt="click" />
          </span>
          <StyledMenu
            className="nav-header-profile-menu-box"
            id="header-profile-menu"
            anchorEl={anchorEl2}
            keepMounted
            open={Boolean(anchorEl2)}
            onClose={() => setAnchorEl2(null)}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem sx={{ marginBottom: '12px' }} className="nav-header-profile-menu-item" disableRipple onClick={() => pushHistory('/landing')}>
              <Home />
              Home
            </MenuItem>

            <MenuItem sx={{ marginBottom: '12px' }} className="nav-header-profile-menu-item" disableRipple onClick={() => pushHistory('/account-settings')}>
              <SettingsIcon />
              Account Settings
            </MenuItem>

            {showAdminCenter && (
              <MenuItem sx={{ marginBottom: '12px' }} className="nav-header-profile-menu-item" disableRipple onClick={() => pushHistory('/admin-center')}>
                <ManageAccountsIcon />
                Admin Center
              </MenuItem>
            )}

            {hasSystemAdminGroup && (
              <MenuItem sx={{ marginBottom: '12px' }} data-testid="sm-test" className="nav-header-profile-menu-item" disableRipple onClick={pushSystemMessagesHistory}>
                <MessageIcon />
                System Messages
              </MenuItem>
            )}

            <MenuItem
              sx={{ marginTop: '12px' }}
              className="nav-header-profile-menu-item"
              onClick={toggleChangePasswordModal}
              disableRipple
            >
              <PasswordIcon />
              Change Password
            </MenuItem>
            {user.groups.includes('SystemAdmin') && <MenuItem sx={{ marginTop: '12px' }} className="nav-header-profile-menu-item" onClick={() => pushHistory('/system-admin')} disableRipple> <AdminPanelSettingsIcon data-testid="admin-center-test" /> System Admin Center </MenuItem>}
            <MenuItem sx={{ marginBottom: '12px' }} className="nav-header-profile-menu-item" onClick={initSignOut} disableRipple>
              <LogoutOutlinedIcon />
              Sign Out
            </MenuItem>
          </StyledMenu>
        </Box>
      </Toolbar>
      {(showSystemMessagesModal) ? renderSystemMessages() : <></>}
    </AppBar>

  );
}

export default withStyles(styles)(NavHeader);
