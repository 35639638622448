import { FOLDER_HIDDEN_FILE } from '../constants';

export const adjustModifyDates = (folder, lastModified = null) => {
  const keys = Object.keys(folder);
  let tempModified = lastModified;
  keys.forEach((key) => {
    const childFolder = folder[key];
    if (key.endsWith('/')) {
      if (childFolder.folders !== undefined) {
        tempModified = adjustModifyDates(childFolder.folders, childFolder.lastModified);
        childFolder.lastModified = tempModified;
        if (childFolder.files && childFolder.files.length) {
          childFolder.folders.lastModified = tempModified;
        }
      } else if (lastModified === null || lastModified === '-' || lastModified < childFolder.lastModified) {
        tempModified = childFolder.lastModified
      }
    }
  });
  return tempModified;
}

// Get the path from the key of the file in the formar required
export const getPathFromKey = (key) => {
  const keyArr = key.split('/');
  keyArr.shift();

  let path = '';
  keyArr.forEach((i, idx) => {
    if (idx === 0) {
      path += `s3Folders['${i}/'].`;
    } else if (idx !== keyArr.length - 1) {
      path += `folders['${i}/'].`;
    }
  });

  const pathResult = path.slice(0, -1);
  return pathResult;
};

// Get file data and return it in the object format required
export const getFileData = (file, s3Key, s3LastModified) => {
  const lastModified = file[s3LastModified];
  const fileArr = file[s3Key].split('/');
  const name = fileArr[fileArr.length - 1];
  const s3Path = file[s3Key];
  const description = '-';

  return {
    lastModified,
    name,
    s3Path,
    description,
  };
};

// Spread or start the files array in the folder
export const addFileToFolder = (obj, fileData) => {
  if (obj.files && obj.files.length) {
    obj.files = [...obj.files, fileData];
    if (fileData.lastModified > obj.lastModified) {
      obj.lastModified = fileData.lastModified;
    }
  } else {
    obj.files = [fileData];
    obj.lastModified = fileData.lastModified;
  }
};

// Find folder path in s3Folders and create it if it doesn't exist
export const findOrCreatePath = (obj, path, fileData) => {
  const keys = path.match(/(?:\['[^']+'\]|\.\w+|\[\d+\])/g);
  let currentObj = obj;

  keys.forEach((key, idx) => {
    const cleanKey = key.replace(/[[\]'.]/g, '');
    if (currentObj[cleanKey] === undefined) {
      currentObj[cleanKey] = {};
    }
    currentObj = currentObj[cleanKey];
    const [name] = cleanKey.split('/');
    currentObj.name = name;
    currentObj.files = currentObj.files || [];

    if (idx === keys.length - 1 && fileData.name !== FOLDER_HIDDEN_FILE) {
      addFileToFolder(currentObj, fileData);
    }
  });
};

export const encodeFileDescription = (description) => btoa(encodeURIComponent(description))

export async function getFileDescriptions(objectTagsPromises) {
  const s3ObjectTagsResponse = await Promise.all(objectTagsPromises)
    .then((data) => data)
    .catch(() => {});
  const descriptionsArr = [];
  if (Array.isArray(s3ObjectTagsResponse)) {
    s3ObjectTagsResponse.forEach((resolved) => {
      const s3ObjectPath = resolved.$response.request.params.Key;
      resolved.TagSet.forEach((tag) => {
        if (tag.Key === 'description' && tag.Value) {
          try {
            const description = decodeURIComponent(atob(tag.Value));
            descriptionsArr.push({
              s3ObjectPath,
              description,
            });
          } catch (error) {
            const description = tag.Value;
            descriptionsArr.push({
              s3ObjectPath,
              description,
            });
          }
        }
      });
    });
  }
  return descriptionsArr;
}

export function updateDescription(data, targetPath, newDescription) {
  Object.keys(data).forEach((key) => {
    if (key === 'files') {
      data[key].forEach((file) => {
        if (file.s3Path === targetPath) {
          file.description = newDescription;
        }
      });
    } else if (typeof data[key] === 'object') {
      updateDescription(data[key], targetPath, newDescription);
    }
  });
}
