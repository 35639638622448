import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap'
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import moment from 'moment'
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import { useGraphQL } from '../../providers/graphql';
import { BRAND_COLORS, CUSTOM_BOX_STYLE } from '../../constants';

function DeleteAccount() {
  const [open, setOpen] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const { userPreferences, updateUserPreferences } = useGraphQL();
  const [deleteAccountAt, setdeleteAccountAt] = useState(userPreferences.deleteAccountAt || 0);
  const [deleteAccountDateAt, setdeleteAccountDateAt] = useState('');

  useEffect(() => {
    if (userPreferences.deleteAccountAt > 0) {
      const currentDate = userPreferences.deleteAccountAt + 86400 // to add a day
      // moment.unix(0).format('hh:mm A') === 12:00 AM in UTC
      const dateToShow = `${moment.unix(currentDate).local().format('DD MMM YYYY')} ${moment.unix(0).format('hh:mm A')}`
      setdeleteAccountDateAt(dateToShow)
    }
  }, [userPreferences]);

  const deleteAccount = () => {
    setOpen(true);
  }

  const cancelDeleteAccount = () => {
    updateUserPreferences(
      {
        ...userPreferences,
        deleteAccountAt: 0,
      },
    ).then(
      () => {
        setShowSpinner(false);
        setdeleteAccountAt(0);
        setdeleteAccountDateAt('');
      },
    )
  }

  const onHide = () => {
    setOpen(false);
  }

  const onYes = () => {
    const currentDate = moment().utc();
    const next14DaysTimeStampUTC = currentDate.add('days', 14).unix();
    const finalDate = `${moment.unix(next14DaysTimeStampUTC + 86400).local().format('DD MMM YYYY')} ${moment.unix(0).format('hh:mm A')}`
    setShowSpinner(true);
    updateUserPreferences(
      {
        ...userPreferences,
        deleteAccountAt: next14DaysTimeStampUTC,
      },
    ).then(
      () => {
        setShowSpinner(false);
        setdeleteAccountAt(next14DaysTimeStampUTC);
        setdeleteAccountDateAt(finalDate);
      },
    )
    onHide();
  }

  return (
    <>
      <div style={{ position: 'absolute', bottom: '15px' }}>
        <p data-testid="gdpr-text">Please contact <a href="mailto:customersupport@crownbio.com">customersupport@crownbio.com</a> to submit requests for download, anonymization, or deletion of personal data.</p>
        <h5>Delete CrownLink Account</h5>
        <p>When you delete your account, you will lose access to CrownLink, and we will permanently delete your personal data. You can cancel deletion within 14 days.</p>
        <Stack direction="row" spacing={1}>
          {
            showSpinner && <CircularProgress size={30} style={{ color: BRAND_COLORS.orange }} display="inline" />
          }
          {
            !deleteAccountAt ? (
              <Button
                size="small"
                variant="contained"
                onClick={deleteAccount}
                className="accountSettings-saveChanges"
              >
                Delete Account
              </Button>
            ) : (
              <>
                <Button
                  size="small"
                  variant="contained"
                  onClick={cancelDeleteAccount}
                  className="accountSettings-saveChanges"
                >
                  Cancel Request
                </Button>
                <span style={{ padding: '5px', fontSize: '18px' }}>Account deletion scheduled for { deleteAccountDateAt }</span>
              </>
            )
          }
        </Stack>
      </div>
      <Modal open={open} onClose={onHide} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={{ ...CUSTOM_BOX_STYLE }}>
          <span className="containerTitle">Delete CrownLink Account</span>
          <p
            style={{
              color: 'white',
              fontSize: '16px',
            }}
          >
            Are you sure you want to delete your CrownLink account?
          </p>
          <Button
            type="submit"
            variant="primary"
            onClick={onYes}
            className="LoginFormSignInButtonChangePassword"
          >
            Yes
          </Button>
          <Button
            type="button"
            variant="light"
            onClick={onHide}
            className="LoginFormOutlineButton"
          >
            No
          </Button>
        </Box>
      </Modal>
    </>
  );
}
export default DeleteAccount;
