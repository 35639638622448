import React, { createContext, useCallback, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

export const SnackBarContext = createContext();

export const SnackBarProvider = function ({ children }) {

  const [state, setState] = useState({
    type: 'success',
    message: '',
    open: false,
  });

  const closeAlert = useCallback(() => {
    setState((currState) => ({ ...currState, open: false }));
  }, []);

  const openAlert = useCallback(() => {
    setState((currState) => ({ ...currState, open: true }));
  }, []);

  const changeState = useCallback((type, message, open) => {
    setState({ type, message, open });
  }, []);

  return (
    <SnackBarContext.Provider
      value={{
        closeAlert,
        changeState,
        openAlert,
        snackBarState: state,
      }}
    >
      <Snackbar
        open={state.open}
        autoHideDuration={4000}
        onClose={closeAlert}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Alert onClose={closeAlert} severity={state.type} sx={{ width: '100%' }}>
          {state.message}
        </Alert>
      </Snackbar>
      {children}
    </SnackBarContext.Provider>
  )
}

export const useSnackBar = () => {
  const context = React.useContext(SnackBarContext)

  if (context === undefined) {
    throw new Error(
      '`useSnackBar` hook must be used within a `SnackBarProvider` component',
    )
  }
  return context
}
