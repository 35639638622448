import React from 'react';

const StudyListHeaderRenderer = function ({ headerName, column }) {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span>{headerName}</span><span id={headerName} className={`ag-icon ag-icon-filter${column.filterActive ? '' : ' display-none'}`} role="presentation" />
    </div>
  );
}
export default StudyListHeaderRenderer;
