/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://a4s4xuznhzffjiabvsn7ecx3li.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-3ezo2v6flrby7irush4dtkfi4u",
    "aws_cognito_identity_pool_id": "us-east-1:889f8f32-b2c3-4827-9667-b977a29836e5",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_gCbpf3Aax",
    "aws_user_pools_web_client_id": "2499b1d8v60io5fjf0eql3tpqa",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "crownbiosaturnportal-20230822111433-hostingbucket-test",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d4nf5wf62ug61.cloudfront.net",
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "approveddata-test",
            "region": "us-east-1"
        },
        {
            "tableName": "study_quality_control-test",
            "region": "us-east-1"
        }
    ],
    "aws_user_files_s3_bucket": "crownlink-document-storage-test",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
