import React from 'react';
import PropTypes from 'prop-types';

// eslint-disable-next-line react/prefer-stateless-function
class ExcelColumn extends React.Component {
  render() {
    return null;
  }
}

ExcelColumn.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  label: PropTypes.string.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.bool,
    PropTypes.string,
    PropTypes.func,
  ]).isRequired,
};

export default ExcelColumn;
