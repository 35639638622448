import React, {
  createContext,
  useCallback,
  useState,
  useMemo,
  useRef,
} from 'react';
import {
  Row,
  Spinner,
  Button as Btn,
} from 'react-bootstrap';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { v4 as uuidv4 } from 'uuid';
import { useGraphQL } from '../graphql';
import { useAuth } from '../auth';
import ColorButton from './colorButton';
import ColorEditor from './colorEditor';
import { getCustomColors } from './utils';

import './colorPanel.css';

export const ColorPanelContext = createContext();

export const CHART_MENU = ['resetScale2d', 'toImage', 'zoom2d', 'pan2d', 'zoomIn2d', 'zoomOut2d', 'toggleSpikelines', 'hoverClosestCartesian', 'hoverCompareCartesian'];

const DEFAULT_CHART_COLORS = [
  '#056A78',
  '#002334',
  '#FFD200',
  '#F58232',
  '#B9283F',
  '#1E7986',
  '#193948',
  '#FFD619',
  '#F68E46',
  '#C03D52',
  '#378893',
  '#334F5D',
  '#FFDB33',
  '#F79B5B',
  '#C75365',
  '#5097A0',
  '#4D6571',
  '#FFE04D',
  '#F8A870',
  '#CE6979',
  '#69A6AE',
  '#667B85',
  '#FFE466',
  '#F9B484',
  '#D57E8C',
  '#82B4BC',
  '#80919A',
  '#FFE880',
  '#FAC098',
  '#DC949F',
  '#9BC3C9',
  '#99A7AE',
  '#FFED99',
  '#FBCDAD',
  '#E3A9B2',
  '#B4D2D7',
  '#B3BDC2',
  '#FFF2B3',
  '#FCDAC2',
  '#EABFC5',
  '#CDE1E4',
  '#CCD3D6',
  '#FFF6CC',
  '#FDE6D6',
  '#F1D4D9',
  '#E6F0F2',
  '#E6E9EB',
  '#FFFAE6',
  '#FEF2EA',
  '#F8EAEC',
];

const CUSTOM_COLORS = [
  '#00B0F0',
  '#FF0000',
  '#00B050',
  '#FFFF00',
  '#FFC000',
  '#19B8F2',
  '#FF1919',
  '#19B862',
  '#FFFF19',
  '#FFC619',
  '#33C0F3',
  '#FF3333',
  '#33C073',
  '#FFFF33',
  '#FFCD33',
  '#4DC8F4',
  '#FF4D4D',
  '#4DC884',
  '#FFFF4D',
  '#FFD34D',
  '#66D0F6',
  '#FF6666',
  '#66D096',
  '#FFFF66',
  '#FFD966',
  '#80D8F8',
  '#FF8080',
  '#80D8A8',
  '#FFFF80',
  '#FFE080',
  '#99DFF9',
  '#FF9999',
  '#99DFB9',
  '#FFFF99',
  '#FFE699',
  '#B3E7FA',
  '#FFB3B3',
  '#B3E7CB',
  '#FFFFB3',
  '#FFECB3',
  '#CCEFFC',
  '#FFCCCC',
  '#CCEFDC',
  '#FFFFCC',
  '#FFF2CC',
  '#E6F7FE',
  '#FFE6E6',
  '#E6F7EE',
  '#FFFFE6',
  '#FFF9E6',
];

function CustomTabPanel(props) {
  const {
    children,
    value,
    index,
    ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, padding: '10px 0px' }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const PaperComponent = function (props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper style={{ height: '100%' }} {...props} />
    </Draggable>
  );
}

export const ColorPanelProvider = function ({ children }) {
  const {
    createUserColorScheme,
    updateUserColorScheme,
    listUserColorSchemes,
  } = useGraphQL();
  const { user } = useAuth();

  const [open, setOpen] = useState(false);
  const [applying, setApplying] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [editorValue, setEditorValue] = useState('');
  const [value, setValue] = useState(0);
  const colorSchemes = useRef([]);
  const [graphInfo, setGraphInfo] = useState({
    studyId: '',
    modelId: '',
    graphName: '',
    taskType: '',
    groups: [],
  });
  const [radioValue, setRadioValue] = useState('default');
  const [colorMappings, setColorMappings] = useState({});

  const refreshCallback = useRef(null);

  const defaultColors = useMemo(() => DEFAULT_CHART_COLORS, []);
  const customColors = useMemo(() => CUSTOM_COLORS, []);
  const chartMenu = useMemo(() => CHART_MENU, []);

  const fetchColorSchemes = useCallback(async (study) => {
    try {
      const result = await listUserColorSchemes(user.id, study);
      return result.data.listColorSchemes.items;
    } catch (error) {
      console.log(error);
    }
    return []
  }, [listUserColorSchemes, user]);

  const fetchStudySchemes = async (study) => {
    const results = await fetchColorSchemes(study);
    colorSchemes.current = results;
  };

  const getColorMappings = (mappings) => {
    setColorMappings({ ...mappings });
  };

  const setRefreshCallback = (func) => {
    refreshCallback.current = func;
  };

  const getSelectedScheme = useCallback((task, graph) => colorSchemes.current.filter((scheme) => scheme.taskType === task && scheme.graphName === graph), [colorSchemes]);

  const getValue = useCallback((task, graph) => {
    const selectedScheme = getSelectedScheme(task, graph);
    if (!selectedScheme.length || selectedScheme[0].value === 'default') {
      return 'default';
    }
    return 'custom';
  }, [getSelectedScheme]);

  const getMappedColors = useCallback((task, graph) => {
    const selectedScheme = getSelectedScheme(task, graph);
    const mappedColors = getCustomColors(selectedScheme, customColors, colorMappings);
    return mappedColors;
  }, [getSelectedScheme, customColors, colorMappings]);

  const getColors = useCallback((task, graph) => {
    const value = getValue(task, graph);
    if (value === 'default') {
      return defaultColors;
    }
    const mappedColors = getMappedColors(task, graph);
    return mappedColors;

  }, [defaultColors, getValue, getMappedColors]);

  const handleClickOpen = (study, model, graph = '', task = '', groups = []) => {
    setGraphInfo((prevState) => ({
      ...prevState,
      studyId: study,
      modelId: model,
      graphName: graph,
      taskType: task,
      groups,
    }));
    const value = getValue(task, graph);
    setRadioValue(value);
    setOpen(true);
    setOpenMenu(false);
    setEditorValue('');
    setColorMappings({});
  };

  const handleClose = () => {
    setOpen(false);
    setOpenMenu(false);
    setEditorValue('');
    setColorMappings({});
  };

  const handleMenuOpen = () => {
    setOpenMenu(!openMenu);
  }

  const onClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
  };

  const handleTabChange = (event, value) => {
    setValue(value);
  };

  const handleRadioChange = (event) => {
    setRadioValue(event.target.value);
  };

  const handleMenuItemClick = (value) => (event) => {
    event.stopPropagation();
    setEditorValue(value);
    setOpenMenu(false);
  };

  const onSubmit = async () => {
    try {
      setApplying(true);
      const input = {
        userId: user.id,
        studyId: graphInfo.studyId,
        modelId: graphInfo.modelId,
        schemeType: 'GRAPH',
        value: radioValue,
        graphName: graphInfo.graphName,
        taskType: graphInfo.taskType,
      };
      const selectedScheme = getSelectedScheme(graphInfo.taskType, graphInfo.graphName);
      let result = null;
      const tempSchemes = [...colorSchemes.current];
      if (selectedScheme.length) {
        input.id = selectedScheme[0].id;
        if (!selectedScheme[0].colorMapping) {
          input.colorMapping = Object.keys(colorMappings).map((key) => ({ group: key, color: colorMappings[key] }));
        } else {
          input.colorMapping = [
            ...selectedScheme[0].colorMapping.map((item) => ({ group: item.group, color: item.color })),
          ];
          Object.keys(colorMappings).forEach((key) => {
            const index = input.colorMapping.findIndex((item) => item.group.toString() === key.toString());
            if (index === -1) {
              input.colorMapping.push({ group: key, color: colorMappings[key] });
            } else {
              input.colorMapping[index] = { group: key, color: colorMappings[key] };
            }
          });
        }
        result = await updateUserColorScheme(input);
        const index = tempSchemes.findIndex((scheme) => scheme.id === input.id);
        if (index !== -1) {
          tempSchemes[index] = result.data.updateColorScheme;
        }
      } else {
        input.id = uuidv4();
        input.colorMapping = Object.keys(colorMappings).map((key) => ({ group: key, color: colorMappings[key] }));
        result = await createUserColorScheme(input);
        tempSchemes.push(result.data.createColorScheme);
      }
      colorSchemes.current = tempSchemes;
      if (refreshCallback.current !== null) {
        refreshCallback.current(`${input.id}-${radioValue}`);
      }
    } catch (e) {
      console.log('error', e);
    } finally {
      setApplying(false);
    }
    setOpen(false);
    setEditorValue('');
  }

  return (
    <ColorPanelContext.Provider
      value={{
        handleClickOpen,
        handleClose,
        onClose,
        fetchStudySchemes,
        getColors,
        setRefreshCallback,
        colorPanelState: open,
        defaultColors,
        customColors,
        chartMenu,
        ColorButton,
        colorSchemes: colorSchemes.current,
      }}
    >
      <Dialog
        onClose={onClose}
        open={open}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-title"
        style={{
          position: 'absolute', left: '70%', top: '51%', transform: 'translate(5%, -49%)', height: '92vh',
        }}
        className="color-panel"
        disableEnforceFocus
        hideBackdrop
      >
        <div className="title">
          <DialogTitle className="CcTitle" id="draggable-title">
            Change Colors
          </DialogTitle>
        </div>
        <DialogContent>
          <Tabs
            value={value}
            onChange={handleTabChange}
            aria-label="secondary tabs example"
            className="tabs"
          >
            <Tab value={0} label="Graph" {...a11yProps(0)} />
            <Tab value={1} label="Model" {...a11yProps(0)} disabled />
            <Tab value={2} label="Study" {...a11yProps(0)} disabled />
          </Tabs>
          <CustomTabPanel value={value} index={0}>
            {
              !applying
                ? (
                  <>
                    <div className="help-text">
                      This will apply to the selected graph.
                    </div>
                    <FormControl className="form-control">
                      <RadioGroup
                        aria-labelledby="demo-form-control-label-placement"
                        name="position"
                        value={radioValue}
                        onChange={handleRadioChange}
                        className="default-radio-label"
                      >
                        <div className="default-colors">
                          <FormControlLabel value="default" control={<Radio />} label="Default" style={{ marginRight: '20px' }} />
                          {
                            defaultColors.slice(0, 5).map((color) => <div className="palette" key={color} style={{ background: color }} />)
                          }
                          <div className="dots">
                            ....
                          </div>
                        </div>
                        <div className="custom-colors">
                          <FormControlLabel value="custom" control={<Radio />} label="Custom" />
                          {
                            getMappedColors(graphInfo.taskType, graphInfo.graphName).slice(0, 5).map((color) => <div className="palette" key={color} style={{ background: color }} />)
                          }
                          <div className="dots">
                            ....
                          </div>
                          <div className="action-dropdown">
                            <IconButton
                              aria-label="more"
                              id="long-button"
                              aria-controls={open ? 'long-menu' : undefined}
                              aria-expanded={open ? 'true' : undefined}
                              aria-haspopup="true"
                              onClick={handleMenuOpen}
                              size="large"
                            >
                              <MoreVertIcon />
                            </IconButton>
                          </div>
                          {
                            openMenu
                            && (
                              <div className="menu">
                                <ul className="menu-list">
                                  {
                                    editorValue === ''
                                    && (
                                      <li className="menu-item">
                                        <Button
                                          variant="text"
                                          onClick={handleMenuItemClick('edit')}
                                          className="menu-button"
                                        >
                                          Edit
                                        </Button>
                                      </li>
                                    )
                                  }
                                  {
                                    editorValue === 'edit'
                                    && (
                                      <li className="menu-item">
                                        <Button
                                          variant="text"
                                          onClick={handleMenuItemClick('')}
                                          className="menu-button"
                                        >
                                          Close Editor
                                        </Button>
                                      </li>
                                    )
                                  }
                                  <li className="menu-item">
                                    <Button
                                      variant="text"
                                      className="menu-button"
                                    >
                                      Reset
                                    </Button>
                                  </li>
                                </ul>
                              </div>
                            )
                          }
                        </div>
                        {
                          editorValue === 'edit'
                          && (
                            <ColorEditor
                              currentGroups={graphInfo.groups}
                              colorMappings={getMappedColors(graphInfo.taskType, graphInfo.graphName)}
                              getColorMappings={getColorMappings}
                            />
                          )
                        }
                      </RadioGroup>
                    </FormControl>
                  </>
                ) : (
                  <Row style={{ position: 'absolute', top: '50%', left: '40%' }}>
                    <Btn variant="primary" disabled>
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      Applying ....
                    </Btn>
                  </Row>
                )
            }
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            Item Two
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            Item Three
          </CustomTabPanel>
        </DialogContent>
        <DialogActions className="actionButtonDialog">
          <Button
            sx={{
              left: '17px',
              position: 'absolute',
            }}
            className="action-button"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            className="action-button"
            onClick={onSubmit}
          >
            Apply
          </Button>
        </DialogActions>
      </Dialog>
      {children}
    </ColorPanelContext.Provider>
  )
}

export const useColorPanel = () => {
  const context = React.useContext(ColorPanelContext)

  if (context === undefined) {
    throw new Error(
      '`useColorPanel` hook must be used within a `SnackBarProvider` component',
    )
  }
  return context
}
