import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import CircularProgress from '@mui/material/CircularProgress';
import { TableFooter } from '@mui/material';
import Typography from '@mui/material/Typography';
import { styled, makeStyles } from '@mui/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { Button } from '@mui/base/Button';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import './Comments.css'

// Icons
import DeleteIconM from './icon-delete.svg';
import EditIconM from './icon-edit-comment.svg';
import ApproveIconM from './icon-ok-comment.svg';
import CancelIconM from './icon-cancel-comment.svg';

import 'draft-js/dist/Draft.css';
import RichTextEditor from '../ReusableComponents/RichTextEditor/RichTextEditor';
import './CommentsGrid.css';
import { useAuth } from '../../../providers/auth'
import { GROUPS, isDomainWhiteListedForHyperLlink } from '../../../constants';

const useStyles = makeStyles(() => ({
  customTableCell: {
    width: '0 !important',
  },
}));

const LightTooltip = styled(({ className, ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Tooltip {...props} classes={{ popper: className }} disableInteractive />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#FFFBEC',
    color: '#222222',
    top: '20px',
    border: '1px solid #FEE7D5',
    borderRadius: '2px',
    fontSize: '12px',
  },
}));

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.root}`]: {
    borderBottom: '1px solid rgb(112, 112, 112, 0.5)',
    borderRight: '1px solid rgb(112, 112, 112, 0.5)',
  },
  [`&.${tableCellClasses.head}`]: {
    width: '10%',
  },
  [`&.${tableCellClasses.body}`]: {
    width: '10%',
  },
}));

const StyledTableCellHeader = styled(TableCell)(() => ({
  [`&.${tableCellClasses.root}`]: {
    borderBottom: '1px solid rgb(112, 112, 112, 0.5)',
    borderRight: '1px solid rgb(112, 112, 112, 0.5)',
  },
}));

const StyledTableCellFirstColumn = styled(TableCell)(() => ({
  [`&.${tableCellClasses.root}`]: {
    borderBottom: '1px solid rgb(112, 112, 112, 0.5)',
    borderRight: '1px solid rgb(112, 112, 112, 0.5)',
  },
}));

const StyledTableCellActionColumn = styled(TableCell)(() => ({
  [`&.${tableCellClasses.root}`]: {
    borderBottom: '1px solid rgb(112, 112, 112, 0.5)',
    borderRight: '1px solid rgb(112, 112, 112, 0.5)',
  },
}));

const StyledTableCellTopRight = styled(TableCell)(() => ({
  [`&.${tableCellClasses.root}`]: {
    borderRadius: '0px 20px 0px 0px',
    backgroundColor: '#2222221A',
    borderBottom: '1px solid rgb(112, 112, 112, 0.5)',

  },
}));
const CommentRow = (props) => {
  const {
    row,
    models,
    userId,
    onEdit,
    onSave,
    loading,
    onChange,
    onCancel,
    getModel,
    canEditComment,
    onDeleteComment,
    commentsGridClasses,
    isAllowedToAddHyperLinks,
    getEmptyOrInitializedNotes,
  } = props;
  const [modelIdError, setModelIdError] = useState(false)
  const setModelId = useCallback((e) => {
    setModelIdError(false)
    onChange(e, row)
  }, [onChange, setModelIdError, row])
  const onDone = useCallback((e) => {
    e.preventDefault()
    e.stopPropagation()
    if (!row.modelID) {
      setModelIdError(true)
    } else {
      onSave(row)
    }
  }, [row, onSave, setModelIdError])
  return (
    <TableRow data-testid="comment-table-row" key={row.id}>
      <StyledTableCellFirstColumn align="left" className="comments-colWidth10Percent comments-tableRowCell AvenirNextLTProRegular">{row.createdAt}</StyledTableCellFirstColumn>
      <StyledTableCell align="left" className={`${commentsGridClasses.customTableCell} customTableCell comments-colWidth5Percent comments-tableRowCell AvenirNextLTProRegular`}>
        {row.isEditMode || row.isAddMode ? (
          <FormControl fullWidth variant="outlined" error={modelIdError}>
            <Select
              /* labelId="model-label" */
              id="model-select"
              value={row.modelID || ''}
              /* label="Model" */
              name="modelID"
              onChange={setModelId}
              variant="outlined"
            >
              <MenuItem className="comments-menuItem" value="general">General</MenuItem>
              {models.map((m) => (<MenuItem className="comments-menuItem" key={m.study_id} value={m.study_id}>{m.study_model}</MenuItem>))}
            </Select>
            { modelIdError && <FormHelperText>Required field</FormHelperText>}
          </FormControl>
        ) : (
          getModel(row.modelID)
        )}
      </StyledTableCell>
      <StyledTableCell align="left" className={`${commentsGridClasses.customTableCell} comments-colWidth5Percent comments-tableRowCell AvenirNextLTProRegular`}>{row.reviewer}</StyledTableCell>
      <StyledTableCell align="left" className={`${commentsGridClasses.customTableCell} commentTable-notesCell comments-tableRowCell AvenirNextLTProRegular`}>
        {row.isEditMode || row.isAddMode ? (
          <RichTextEditor
            showToolbar
            containerClass="DraftEditor-root comments-inputTextField"
            initialValue={row.notes}
            pluginsConfig={{
              hyperLinkConfig: {
                showHyperLinkIcon: isAllowedToAddHyperLinks,
                enableHyperLinkPlugin: true,
                onValidateURL: (url) => isAllowedToAddHyperLinks && isDomainWhiteListedForHyperLlink(url),
              },
            }}
            onChange={(values) => {
              if (values?.length === 0) { // empty array creates panic, so lets add an empty text in that case
                row.notes = getEmptyOrInitializedNotes()
              } else {
                row.notes = values
              }
            }}
          />
        ) : (
          <RichTextEditor
            readOnly
            showToolbar={false}
            containerClass={`DraftEditor-root ${row.notes}`}
            initialValue={row.notes}
            pluginsConfig={{
              hyperLinkConfig: {
                enableHyperLinkPlugin: true,
              },
            }}
          />
        )}
        {(!(row.isEditMode || row.isAddMode) && row.isEdited)
          ? (
            <LightTooltip
              className="AvenirNextLTProMedium"
              title={(
                <>
                  <Typography className="AvenirNextLTProRegular">
                    {row.updatedAt}
                  </Typography>
                </>
              )}
              placement="bottom"
            >
              <span className="edited">(Edited)</span>
            </LightTooltip>
          ) : null}
      </StyledTableCell>
      <StyledTableCellActionColumn className="commentTable-actionCell comments-colWidth7Dot5Percent comments-tableRowCell AvenirNextLTProRegular">
        {row.isEditMode || row.isAddMode ? (
          <>
            {loading && <CircularProgress size={40} />}
            {!loading && (
              <>
                <Button
                  aria-label="done"
                  onClick={onDone}
                  className="buttonWithTransparentBackground"
                >
                  <img
                    src={ApproveIconM}
                    alt="Approve icon on Comments row"
                  />
                </Button>
                <Button
                  aria-label="revert"
                  onClick={onCancel(row.id)}
                  className="buttonWithTransparentBackground"
                >
                  <img
                    src={CancelIconM}
                    alt="Edit icon on Comments row"
                  />
                </Button>
              </>
            )}
          </>
        ) : (
          <>
            {userId === row.commentedBy && canEditComment()
              ? (
                <>
                  <Button
                    aria-label="edit"
                    onClick={onEdit(row)}
                    className="buttonWithTransparentBackground"
                  >
                    <img src={EditIconM} alt="Edit icon on Comments row" />
                  </Button>

                  <Button
                    aria-label="delete"
                    onClick={onDeleteComment(row.id)}
                    className="buttonWithTransparentBackground"
                  >
                    <img src={DeleteIconM} alt="Delete icon on Comments row" />
                  </Button>
                </>
              ) : null}
          </>
        )}
      </StyledTableCellActionColumn>
    </TableRow>
  )
}

CommentRow.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.string,
    createdAt: PropTypes.string,
    isEditMode: PropTypes.bool,
    isAddMode: PropTypes.bool,
    isEdited: PropTypes.bool,
    notes: PropTypes.arrayOf({
      children: PropTypes.arrayOf({
        text: PropTypes.string,
      }),
      type: PropTypes.string,
    }),
    commentedBy: PropTypes.string,
  }),
  models: PropTypes.arrayOf({
    director: PropTypes.null || PropTypes.shape({
      createdAt: PropTypes.string,
      filters: PropTypes.null,
      name: PropTypes.string,
      state: PropTypes.string,
    }),
    juniorDirector: PropTypes.null || PropTypes.shape({
      createdAt: PropTypes.string,
      filters: PropTypes.null,
      name: PropTypes.string,
      state: PropTypes.string,
    }),
    study_id: PropTypes.string,
    study_model: PropTypes.string,
    study_site: PropTypes.string,
    study_status: PropTypes.string,
    study_type: PropTypes.string,
  }),
  userId: PropTypes.string,
  onEdit: PropTypes.func,

  onSave: PropTypes.func,
  loading: PropTypes.bool,
  onChange: PropTypes.func,

  onCancel: PropTypes.func,
  getModel: PropTypes.func,
  canEditComment: PropTypes.func,

  onDeleteComment: PropTypes.func,
  commentsGridClasses: PropTypes.shape({
    customTableCell: PropTypes.string,
  }),

  isAllowedToAddHyperLinks: PropTypes.bool,
  getEmptyOrInitializedNotes: PropTypes.func,
};
CommentRow.defaultProps = {
  row: {
    id: '',
    createdAt: '',
    isEditMode: false,
    isAddMode: false,
    isEdited: false,
    notes: [],
    commentedBy: '',
  },
  models: [],
  userId: '',
  onEdit: () => {},

  onSave: () => {},
  loading: false,
  onChange: () => {},

  onCancel: () => {},
  getModel: () => {},
  canEditComment: () => {},

  onDeleteComment: () => {},
  commentsGridClasses: { customTableCell: '' },

  isAllowedToAddHyperLinks: false,
  getEmptyOrInitializedNotes: () => {},
};
const CommentsGrid = React.forwardRef((props, ref) => {
  const commentsGridClasses = useStyles();
  const { hasGroup } = useAuth();
  const isAllowedToAddHyperLinks = hasGroup([GROUPS.STUDY_DIRECTOR, GROUPS.BIOMARKER])

  const {
    classes,
    rows,
    page,
    study,
    rowsPerPage,
    CustomTablePagination,
    onPageChange,
    onRowsPerPageChange,
    user,
    onEdit,
    onSave,
    loading,
    onChange,
    onCancel,
    getModel,
    canEditComment,
    onDeleteComment,
    getEmptyOrInitializedNotes,
  } = props;

  return (
    <div className="comments-tableWrapper custom" ref={ref}>
      <Table className={classes.table} aria-label="caption table " padding="normal">
        {!rows.length
          ? <caption data-testid="no-commets-text">No Comments added</caption> : null}
        <TableHead>
          <TableRow
            className="AvenirNextLTProBold"
          >
            <StyledTableCellFirstColumn data-testid="comment-table-col-title-timestamp" className="comments-colWidth10Percent comments-tableHeaderCell AvenirNextLTProBold" align="left">Comment Timestamp</StyledTableCellFirstColumn>
            <StyledTableCellHeader data-testid="comment-table-col-title-model" className="comments-colWidth5Percent comments-tableHeaderCell AvenirNextLTProBold" align="left">Model</StyledTableCellHeader>
            <StyledTableCellHeader data-testid="comment-table-col-title-reviewer" className="comments-colWidth5Percent comments-tableHeaderCell AvenirNextLTProBold" align="left">Reviewer</StyledTableCellHeader>
            <StyledTableCellHeader data-testid="comment-table-col-title-notes" className="commentTable-notesCell comments-tableHeaderCell AvenirNextLTProBold" align="left">Notes</StyledTableCellHeader>
            <StyledTableCellTopRight className="commentTable-actionCell" align="left" />
          </TableRow>
        </TableHead>
        <TableBody className="comments-tableBody">
          {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => row && (
            <CommentRow
              key={row.id}
              row={row}
              models={study.models}
              userId={user.id}
              onEdit={onEdit}
              onSave={onSave}
              loading={loading}
              onChange={onChange}
              onCancel={onCancel}
              getModel={getModel}
              canEditComment={canEditComment}
              onDeleteComment={onDeleteComment}
              StyledTableCell={StyledTableCell}
              commentsGridClasses={commentsGridClasses}
              isAllowedToAddHyperLinks={isAllowedToAddHyperLinks}
              getEmptyOrInitializedNotes={getEmptyOrInitializedNotes}
              StyledTableCellActionColumn={StyledTableCellActionColumn}
              StyledTableCellFirstColumn={StyledTableCellFirstColumn}
            />
          ))}
        </TableBody>
        {rows.length
          ? (
            <TableFooter>
              <TableRow>
                <CustomTablePagination
                  className="comments-customTablePagination AvenirNextLTProRegular"
                  rowsPerPage={rowsPerPage}
                  onPageChange={onPageChange}
                  page={page}
                  count={rows.length}
                  rowsPerPageOptions={[
                    { label: '10 records per page', value: 10 },
                    { label: '50 records per page', value: 50 },
                    { label: '100 records per page', value: 100 },
                    { label: '200 records per page', value: 200 },
                  ]}
                  labelRowsPerPage={<span>Display</span>}
                  onRowsPerPageChange={onRowsPerPageChange}
                  SelectProps={{
                    IconComponent: KeyboardArrowDownIcon,
                  }}
                  labelDisplayedRows={({ from, to, count }) => `Displaying ${from}-${to} of ${count} records`}
                />
              </TableRow>
            </TableFooter>
          ) : null}
      </Table>
    </div>
  )
})

export default CommentsGrid
