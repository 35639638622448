import React from 'react';
import S3Uploader from '../../../documents/S3Uploader';

const StudyDocumentsUploader = (props) => {
  const {
    customS3folders,
    study,
    projectCode,
    selectedFolderData,
    onUploadCompleted,
    foldersNavigation,
    setIsLoadingTable,
    getCustomS3Folders,
    setCustomS3Folders,
  } = props;

  return (
    <S3Uploader
      isCustomFoldersEnabled
      customS3Folders={customS3folders}
      folder={selectedFolderData}
      study={study}
      projectCode={projectCode}
      onUploadCompleted={onUploadCompleted}
      foldersNavigation={foldersNavigation}
      setIsLoadingTable={setIsLoadingTable}
      getCustomS3Folders={getCustomS3Folders}
      setCustomS3Folders={setCustomS3Folders}
    />
  );
};

export default StudyDocumentsUploader;
