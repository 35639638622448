import React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { CUSTOM_BOX_STYLE } from '../../../constants';

const PasswordExpireModal = function PasswordExpire(props) {

  const {
    showPasswordExpire,
    togglePasswordExpire,
    toggleChangePassword,
    passwordExpiryDiff,
  } = props;

  const onHide = () => {
    togglePasswordExpire();
    sessionStorage.setItem('passwordExpiryWarning', true);
  }

  const onChangePassword = () => {
    onHide();
    toggleChangePassword();
  }

  return (
    <Modal open={showPasswordExpire} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box sx={CUSTOM_BOX_STYLE}>
        <span className="containerTitle">Password Expiry Warning</span>
        <p style={{ color: '#E2AAA0' }}>
          Your password will expire in {passwordExpiryDiff} days.  Please set a new password
        </p>
        <Button
          className="LoginFormSignInButtonChangePassword"
          type="button"
          onClick={onChangePassword}
          variant="primary"
        >
          Change Password
        </Button>

        <Button
          className="LoginFormOutlineButton"
          type="button"
          variant="light"
          onClick={onHide}
        >
          Close
        </Button>
      </Box>
    </Modal>
  );
};

export default PasswordExpireModal;
