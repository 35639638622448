import React from 'react';
import { Button, Row, Spinner } from 'react-bootstrap'
import SurvivalAnalysisChart from './SurvivalAnalysisChart';

const SurvivalAnalysisPanel = ({
  studyId, model, acronym, dataViewChangeAudit, ...props
}) => {

  const spinner = (
    <Row className="justify-content-center">
      <Button variant="primary" disabled>
        <Spinner
          as="span"
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
        />
        Loading Survival Analysis Chart...
      </Button>
    </Row>
  )

  const getDisplayCSSForGraph = () => {
    const displayCSS = { display: 'none' };
    if ((props.saFetching) || (props.survivalAnalysisChartData?.length < 1) || (props.survivalAnalysisChartData.every((grp) => grp.x.length <= 0))) { return displayCSS }
    if (!props.saFetching && props.survivalAnalysisChartData?.length > 0) {
      displayCSS.display = 'visible';
    }

    return displayCSS
  }

  return (
    <div>
      <hr className="mlr-10" />
      <div id="survival-analysis-chart-container" style={getDisplayCSSForGraph()}>
        <Row
          style={{
            height: '450px',
            marginTop: '40px',
          }}
        >

          <SurvivalAnalysisChart
            margin={{ r: 10, t: 30, pad: 0 }}
            yaxisTitle="<b>Survival (%)</b>"
            title="Survival Curve"
            xaxisTitle="<b>Study Days</b>"
            yAxisTickSuffix="%"
            data={props.survivalAnalysisChartData}
            maxStudyDay={props.maxStudyDay}
            minStudyDay={props.minStudyDay}
            divId="tumor-volume-mean-summary"
            groups={props.groups}
            studyId={studyId}
            modelId={model.study_id}
            graphName={`${acronym}-summary`}
            acronym={acronym}
          />

        </Row>
      </div>
      {/* error handle */}
      {(!props.survivalAnalysisChartData.length && !props.saFetching) && <div>No Data Available</div>}
      {props?.saFetching && (spinner)}
    </div>
  )
}

export default SurvivalAnalysisPanel;
