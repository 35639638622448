import React, { createContext } from 'react';
import { Amplify, Auth } from 'aws-amplify';
import { SNSClient, PublishCommand } from '@aws-sdk/client-sns';
import awsExports from '../aws-exports'; // eslint-disable-line import/extensions
import * as utils from '../resources/utils';

import { NOTIFICATION_SNS_TOPIC } from '../constants';

const amplifyConfig = Amplify.configure(awsExports);

export const SNSContext = createContext();
export const SNSProvider = function SNSProvider({ children }) {

  const getAuthenticatedSNSClient = async () => {
    try {
      const creds = await Auth.currentCredentials();
      const client = new SNSClient({
        apiVersion: '2010-03-31',
        credentials: Auth.essentialCredentials(creds),
      });
      return client;
    // eslint-disable-next-line no-empty
    } catch (e) {}
    return null;
  };

  const sendMessageToSNSTopic = async (message = '', snsTopic = NOTIFICATION_SNS_TOPIC) => {
    try {
      const snsClient = await getAuthenticatedSNSClient()
      const accountId = utils.getAWSAccountId();

      const snsArn = `arn:aws:sns:${amplifyConfig.aws_project_region}:${accountId}:${snsTopic}`;

      const params = {
        Message: JSON.stringify(message),
        TopicArn: snsArn,
      };
      return await snsClient.send(new PublishCommand(params));
    // eslint-disable-next-line no-empty
    } catch (error) {}
    return null
  }

  return (
    <SNSContext.Provider
      value={{
        sendMessageToSNSTopic,
      }}
    >
      {children}
    </SNSContext.Provider>
  )
}

export const useSNS = () => {
  const context = React.useContext(SNSContext);

  if (context === undefined) {
    throw new Error(
      '`useSNS` hook must be used within a `SNSProvider` component',
    );
  }
  return context;
};
