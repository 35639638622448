import React, {
  useEffect,
  useCallback,
  useState,
  useRef,
} from 'react';
import {
  Container,
  Card,
  Button,
} from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import MarkdownIt from 'markdown-it';
import MdEditor from 'react-markdown-editor-lite';
import ArrowBackOutlined from '@mui/icons-material/ArrowBackOutlined';
import { useHistory } from 'react-router-dom';

import { useGraphQL } from '../../providers/graphql';
import { useAuth } from '../../providers/auth';
import { useSnackBar } from '../../providers/snackbar'
import { defaultText, defaultHtml } from './constants';

// import style manually
import 'react-markdown-editor-lite/lib/index.css';
import './edit-style.css';

const MarkdownItNamedHeadings = require('markdown-it-named-headings');

const EditHelp = function EditHelp() {

  const {
    createHelpText,
    updateHelpText,
    getHelpText,
  } = useGraphQL();
  const { user } = useAuth();
  const history = useHistory();
  const { changeState } = useSnackBar();
  const mdParser = new MarkdownIt();
  mdParser.use(MarkdownItNamedHeadings);
  const [helpText, setHelpText] = useState(defaultText);
  const helpHtml = useRef(defaultHtml);
  const newHelp = useRef(null);

  const fetchHelpText = useCallback(async () => {
    try {
      const result = await getHelpText();
      if (result.data.listHelps.items.length) {
        const help = result.data.listHelps.items[0];
        newHelp.current = help;
        if (help && help.helpHtml.length) {
          helpHtml.current = help.helpHtml;
        }
        if (help && help.helpText.length) {
          setHelpText(help.helpText);
        }
      }
    // eslint-disable-next-line no-empty
    } catch (error) {
    }
  }, [getHelpText]);

  useEffect(() => {
    fetchHelpText();
  }, [fetchHelpText]);

  const handleEditorChange = ({ html, text }) => {
    helpHtml.current = html;
    setHelpText(text);
  };

  const submitHelpText = async () => {
    if (!newHelp.current) {
      try {
        const result = await createHelpText(uuidv4(), helpText, helpHtml.current, user.id);
        newHelp.current = result.data.createHelp;
        changeState('success', 'Successfully saved', true);
      // eslint-disable-next-line no-empty
      } catch (error) {
      }
    } else {
      try {
        await updateHelpText(newHelp.current.id, helpText, helpHtml.current, user.id);
        changeState('success', 'Successfully saved', true);
      // eslint-disable-next-line no-empty
      } catch (error) {
      }
    }
  };

  const goBack = () => {
    history.goBack();
  };

  return (
    <Container fluid className="edit-help-container">
      <div className="back">
        <Button variant="link" onClick={goBack}> <ArrowBackOutlined /> Back </Button>
      </div>
      <Card>
        <Card.Header className="text-center text-md-left">
          <div className="heading">
            <h5 className="my-0 font-weight-normal">Help Text</h5>
          </div>
          <div className="action-buttons">
            <Button className="btn btn-sm float-right m-1" variant="secondary" onClick={submitHelpText}>
              Save
            </Button>
          </div>
        </Card.Header>
        <Card.Body>
          <MdEditor
            style={{ height: '500px' }}
            value={helpText}
            renderHTML={(text) => mdParser.render(text)}
            onChange={handleEditorChange}
            canView={{
              menu: true,
              md: true,
              html: true,
              fullScreen: false,
              hideMenu: true,
            }}
          />
        </Card.Body>
      </Card>
    </Container>
  );
};

export default EditHelp;
