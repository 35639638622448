/* import { Upload } from "react-bootstrap-icons"; */
import React, {
  useMemo, useState, useRef, useEffect,
} from 'react';
import { useHistory } from 'react-router';
import { useDropzone } from 'react-dropzone';
import Button from '@mui/material/Button';
import path from 'path';
import {
  Modal,
  Button as BootBtn,
  Row,
  Spinner,
} from 'react-bootstrap';
import _ from 'lodash';

import DocumentUploadModal from './DocumentUploadModal';
import MultiDocumentUploadModal from './MultiDocumentUploadModal';
import RouterPrompt from './RouterPrompt';
import 'react-dropzone-uploader/dist/styles.css';
import {
  MODAL_INIT_STATE,
  WHITE_LISTED_FILETYPES,
  GROUPS,
  AUDIT_TRAIL_EVENT_TYPES,
  DATA_COLLECTION_EVENT_TYPES,
  NOTIFICATION_EVENT_TYPE,
} from '../../../constants';
import { createMessageForSNS, createMessageForSQS } from '../../utils.createMessage';
import { useS3 } from '../../../providers/s3';
import { useSQS } from '../../../providers/sqs';
import { useGraphQL } from '../../../providers/graphql';
import { useSNS } from '../../../providers/sns';
import ProgressBar from './ProgressBar'
import DummyProgressBar from './DummyProgressBar'
import { useAuth } from '../../../providers/auth';

const baseStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '12px',
  backgroundColor: '#FBFDFD', /** border related customizations */
  border: '1px dashed #B6B6B6', /** BG color change */
  color: '#bdbdbd',
  transition: 'border .4s ease-in-out',
  height: '170px',
  marginTop: '40px',
  marginBottom: '32px',
  borderOpacity: '0.5',
};

const activeStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

const S3Uploader = function S3Uploader({
  folder,
  study,
  projectCode,
  onUploadCompleted,
  foldersNavigation,
  setIsLoadingTable,
  getCustomS3Folders,
  setCustomS3Folders,
  isCustomFoldersEnabled,
  // refreshFolders,
}) {

  const history = useHistory();
  const {
    user,
    doTask,
  } = useAuth();
  const { sendMessageToSNSTopic } = useSNS();
  const [modalProps, setModalProps] = useState(MODAL_INIT_STATE);
  const [bulkFiles, setBulkFiles] = useState([]);
  const [files, setFiles] = useState([]);
  const [currentFile, setCurrentFile] = useState({});
  const [uploadingOneFile, setUploadingOneFile] = useState(false);
  const currentFileKey = useRef('');
  const currentProjectCode = useRef('');
  const unblockRef = useRef();
  const [showPrompt, setShowPrompt] = useState(false);
  const [queueLength, setQueueLength] = useState(0);
  const [currentPath, setCurrentPath] = useState('');
  const {
    s3Folders,
    errorModal,
    setErrorModal,
    uploadProgress,
    uploadDocument,
    flushUploadFile,
    currentUploadId,
    setUploadProgress,
    setFlushUploadFile,
    cancelFileUpload,
    cancellingFileUpload,
  } = useS3();
  const { sendMessageToQueueForAudits, sendMessageToQueueForAnalytics } = useSQS();
  const { getOrCreateUser } = useGraphQL();
  const uploadFile = async ({
    s3ObjectPath,
    file,
    newFileDescription,
  }) => {
    try {
      setUploadingOneFile(true)
      currentFileKey.current = s3ObjectPath;
      currentProjectCode.current = projectCode;
      if (user && user.id) {
        const messageBody = await createMessageForSQS({
          eventType: AUDIT_TRAIL_EVENT_TYPES.DOCUMENT_UPLOAD_INITIATED,
          userId: user.id,
          projectId: projectCode,
          companyName: study.summary,
          attributes: [{ description: newFileDescription }],
        })
        sendMessageToQueueForAudits(messageBody)
      }
      return uploadDocument(s3ObjectPath, file, newFileDescription, projectCode)
        // eslint-disable-next-line no-unused-vars
        .then((res) => {
          if (user?.id && res?.ETag) {
            createMessageForSQS({
              eventType: AUDIT_TRAIL_EVENT_TYPES.DOCUMENT_UPLOAD_COMPLETED,
              userId: user.id,
              projectId: projectCode,
              companyName: study.summary,
              attributes: [{ description: newFileDescription }],
            }).then((messageBody) => {
              sendMessageToQueueForAudits(messageBody)
            });
            createMessageForSQS({
              eventType: DATA_COLLECTION_EVENT_TYPES.DOCUMENT_UPLOAD,
              userId: user.id,
              projectId: projectCode,
              companyName: study.summary,
              attributes: [{ description: newFileDescription }],
            }).then((messageBody) => {
              sendMessageToQueueForAnalytics(messageBody)
            });
            setUploadingOneFile(false)
            onUploadCompleted(
              setIsLoadingTable,
              getCustomS3Folders,
              setCustomS3Folders,
              projectCode,
            );
          }
        }).catch((e) => {
          if (e.code !== 'RequestAbortedError') {
            sendDocumentUploadNotification(NOTIFICATION_EVENT_TYPE.REDIRECT_TO_DASHBOARD.DOCUMENT_UPLOAD_FAILED)
          }
          setUploadingOneFile(false)
        });
    } catch (e) {
      setUploadingOneFile(false)
    }
    return null;
  }

  const uploadFileTask = async ({ s3ObjectPath, file, newFileDescription }) => doTask(async () => {
    await uploadFile({
      s3ObjectPath,
      file,
      newFileDescription,
    })
  });

  // eslint-disable-next-line
  useEffect(() => {
    return () => {
      unblockRef.current = false
    };
  }, []);
  useEffect(() => {
    if (files.length && !uploadingOneFile && !cancellingFileUpload) {
      const newFiles = [...files]
      const file = newFiles.shift()
      if (file) {
        setCurrentFile(file)
        setFiles(newFiles)
        uploadFileTask(file)
      }
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [files, uploadingOneFile, cancellingFileUpload]);

  useEffect(() => {
    // !!BIG TODO!!: Cannot reference state values and setters in the same useEffect! Need to use setter callback
    // Exhaustive peer deps is also a major issue
    if (!currentUploadId && !files.length && !uploadingOneFile && _.isEmpty(uploadProgress)) {
      setCurrentFile({})
      currentFileKey.current = '';
      currentProjectCode.current = '';
      if (queueLength) {
        sendDocumentUploadNotification(NOTIFICATION_EVENT_TYPE.REDIRECT_TO_DASHBOARD.DOCUMENT_UPLOAD, queueLength)
        setQueueLength(0)
      }
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [files, uploadingOneFile, uploadProgress, currentUploadId, queueLength]);
  useEffect(() => {
    // to re initiate next upload
    if (!cancellingFileUpload) {
      setUploadingOneFile(false)
      setCurrentFile({})
      currentFileKey.current = '';
      currentProjectCode.current = '';
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [cancellingFileUpload]);

  useEffect(() => {
    if (flushUploadFile) {
      clearState()
      setFlushUploadFile(false)
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [flushUploadFile]);

  useEffect(() => {
    unblockRef.current = history.block((prompt) => {
      if (!!currentUploadId || !!files.length || uploadingOneFile || !_.isEmpty(uploadProgress)) {
        setCurrentPath(prompt.pathname);
        setShowPrompt(true);
        return false
      }
      return true
    });

    return () => {
      if (unblockRef.current) {
        unblockRef.current();
      }
    };
  }, [history, currentUploadId, files.length, uploadingOneFile, uploadProgress]);

  const getExistingFile = (droppedFile) => {
    let existingFile = false;
    if (foldersNavigation.length > 0 && isCustomFoldersEnabled) {
      // eslint-disable-next-line
      existingFile = _.filter(
        folder.files,
        (file) => file.name === droppedFile.name,
      )[0];
    } else {
      // eslint-disable-next-line
      existingFile = _.filter(
        s3Folders[`${folder.name}/`].files,
        (file) => file.name === droppedFile.name,
      )[0];
    }
    return existingFile;
  }
  const getS3ObjectPath = (droppedFile) => {
    let s3ObjectPath = '';
    if (foldersNavigation.length > 0 && isCustomFoldersEnabled) {
      s3ObjectPath = path.join(
        projectCode,
        ...foldersNavigation,
        droppedFile.name,
      );
    } else {
      s3ObjectPath = path.join(
        projectCode,
        `${folder.name}/`,
        droppedFile.name,
      );
    }
    return s3ObjectPath;
  }

  const addModalProps = (droppedFile) => {
    const existingFile = getExistingFile(droppedFile);
    const s3ObjectPath = getS3ObjectPath(droppedFile);

    let fileTypeAllowed = true;
    try {
      const parsedObjectPath = s3ObjectPath.split('/');
      const fileName = parsedObjectPath[parsedObjectPath.length - 1];
      // below accounts for multiple dots in file name
      const regex = /\.[0-9a-z]+$/i;
      const fileExtension = fileName
        .match(regex)
        .toString()
        .toLowerCase()
        .split('.')[1];
      fileTypeAllowed = WHITE_LISTED_FILETYPES.includes(fileExtension);
    // eslint-disable-next-line no-empty
    } catch (e) {}
    setModalProps({
      s3ObjectPath,
      description: !_.isEmpty(existingFile) ? existingFile.description : '',
      file: droppedFile,
      existing: !!existingFile,
      uploadingSame: currentFile?.file?.name === droppedFile.name,
      alreadyInQue: !!files.find(
        (file) => file?.file?.name === droppedFile.name,
      ),
      setModalProps,
      pushToQueue,
      fileTypeAllowed,
    });
  };
  const setBulkFilesProps = (droppedFile) => {
    const existingFile = getExistingFile(droppedFile);
    const s3ObjectPath = getS3ObjectPath(droppedFile);

    let fileTypeAllowed = true;
    try {
      const parsedObjectPath = s3ObjectPath.split('/');
      const fileName = parsedObjectPath[parsedObjectPath.length - 1];
      // below accounts for multiple dots in file name
      const regex = /\.[0-9a-z]+$/i;
      const fileExtension = fileName
        .match(regex)
        .toString()
        .toLowerCase()
        .split('.')[1];
      fileTypeAllowed = WHITE_LISTED_FILETYPES.includes(fileExtension);
    // eslint-disable-next-line no-empty
    } catch (e) {}
    return {
      s3ObjectPath,
      file: droppedFile,
      existing: !!existingFile,
      uploadingSame: currentFile?.file?.name === droppedFile.name,
      alreadyInQue: !!files.find(
        (file) => file?.file?.name === droppedFile.name,
      ),
      fileTypeAllowed,
    };
  };

  const onDrop = (dropped) => {
    if (Array.isArray(dropped) && dropped.length > 1) {
      const droppedFiles = dropped.map((file) => setBulkFilesProps(file))
      setBulkFiles(droppedFiles)
    } else {
      addModalProps(dropped[0]);
    }
  }

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept],
  );
  const cancelDummyUpload = (name) => {
    const newFiles = files.filter((file) => file.file.name !== name)
    setQueueLength(newFiles.length + 1)
    setFiles(newFiles)
  }
  const pushToQueue = (file) => {
    if (files.length) {
      setQueueLength(files.length + 1)
      setFiles([...files, file])
    } else if (currentFile.file?.name) {
      setFiles([file])
      setQueueLength(2)
    } else {
      setCurrentFile(file)
      uploadFileTask(file)
      setQueueLength(1)
    }
  }
  const sendDocumentUploadNotification = async (type, queueLength = 0) => {
    try {
      const response = await getOrCreateUser(user);
      let createdBy = `${response.first_name || ''} ${response.last_name || ''}`;
      createdBy = createdBy.trim()
      const snsMessageText = createMessageForSNS(
        createdBy,
        study.company,
        study.project_code,
        type,
        user.id,
        type === NOTIFICATION_EVENT_TYPE.REDIRECT_TO_DASHBOARD.DOCUMENT_UPLOAD
          ? [{ key: 'fileCount', value: queueLength }] : [],
      );
      sendMessageToSNSTopic(snsMessageText);
      setQueueLength(0)
      if (type === NOTIFICATION_EVENT_TYPE.REDIRECT_TO_DASHBOARD.DOCUMENT_UPLOAD_FAILED) {
        clearState()
      }
    // eslint-disable-next-line no-empty
    } catch (e) {
    }
  }
  const clearState = async () => {
    // clean whole state
    setFiles([])
    setCurrentFile({})
    setUploadProgress({})
    setUploadingOneFile(false)
    cancelFileUpload(currentProjectCode.current, currentFileKey.current)
    currentFileKey.current = '';
    currentProjectCode.current = '';
    setQueueLength(0)
  };

  return (
    <>
      <RouterPrompt
        onOK={clearState}
        history={history}
        showPrompt={showPrompt}
        unblockRef={unblockRef}
        currentPath={currentPath}
        setShowPrompt={setShowPrompt}
        when={!!currentUploadId || !!files.length || uploadingOneFile || !_.isEmpty(uploadProgress)}
        description="Closing or navigating away from this page will stop the ongoing upload(s).  Instead, you may open a new tab to continue navigating CrownLink from there.  Do you wish to continue?"
      />
      {!_.isEqual(modalProps, MODAL_INIT_STATE) && (
        <DocumentUploadModal {...{
          study,
          user,
          ...modalProps,
        }}
        />
      )}
      {!!bulkFiles.length && (
        <MultiDocumentUploadModal
          bulkFiles={bulkFiles}
          setBulkFiles={setBulkFiles}
          setFiles={setFiles}
          setQueueLength={setQueueLength}
          projectCode={projectCode}
        />
      )}
      {(user.groups.includes(GROUPS.STUDY_DIRECTOR) || user.groups.includes(GROUPS.BIOMARKER))
        && (
          <>
            <div {...getRootProps({ style })}>
              <input {...getInputProps()} />
              <img src="../landing/Icon-File-Upload.svg" alt="study documents upload icon" /> {/** upload icon changed to svg */}
              <div className="upload-txt AvenirNextLTProRegular">
                Drag and drop file here to upload <br />
                Or
              </div>
              <Button className="ul-button AvenirNextLTProMedium" disabled={!currentUploadId && uploadingOneFile}>
                BROWSE AND UPLOAD
              </Button>
            </div>
            <div style={{ maxHeight: '210px', overflowY: 'scroll' }}>
              {!currentUploadId && uploadingOneFile && !cancellingFileUpload && (
                <Row className="justify-content-center">
                  <BootBtn variant="primary" disabled>
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    &nbsp;<span>Uploading</span>&nbsp;<b>{ currentFile?.file?.name }</b>...
                  </BootBtn>
                </Row>
              )}
              {cancellingFileUpload && (
                <Row className="justify-content-center">
                  <BootBtn variant="primary" disabled>
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    &nbsp;<span>Cancelling File Upload...</span>
                  </BootBtn>
                </Row>
              )}
              {!_.isEmpty(uploadProgress) && (
                <div style={{ margin: '5px', padding: '2px' }}>
                  <div>Uploading <b>{ currentFile?.file?.name }</b></div>
                  <ProgressBar
                    setUploadProgress={setUploadProgress}
                    setQueueLength={setQueueLength}
                    progress={uploadProgress}
                    projectCode={currentProjectCode.current}
                    fileKey={currentFileKey.current}
                  />
                </div>
              )}
              {!!files.length && files.map((fileName) => (
                <div key={fileName?.file?.name} style={{ margin: '5px', padding: '2px' }}>
                  <div>Queued <b>{ fileName?.file?.name }</b></div>
                  <DummyProgressBar name={fileName?.file?.name} cancelDummyUpload={cancelDummyUpload} />
                </div>
              ))}
            </div>
          </>
        )}

      {!_.isEmpty(errorModal)

        && (
          <Modal
            show={!_.isEmpty(errorModal)}
            onHide={() => setErrorModal({})}
          >
            <Modal.Header closeButton={() => setErrorModal({})} />
            <Modal.Body>There was an error processing your upload.  Please try again.</Modal.Body>

          </Modal>
        )}

    </>
  );
}
export default S3Uploader;
