import React, { useState } from 'react'
import { NavDropdown, Form, Button } from 'react-bootstrap';
import { useGraphQL } from '../../providers/graphql'
import { useSQS } from '../../providers/sqs'
import { invokeLambdaFunction } from '../utils';
import { createMessageForSQS } from '../utils.createMessage';
import { ENV, AUDIT_TRAIL_EVENT_TYPES } from '../../constants';
import { useSnackBar } from '../../providers/snackbar';
import './ExportAuditTrail.css'

const dataFields = {
  eventType: { label: 'Event Type', value: false },
  appName: { label: 'App Name', value: false },
  timeStamp: { label: 'Time Stamp', value: false },
  ipAddress: { label: 'IP Address', value: false },
  userId: { label: 'User Id', value: false },
  companyName: { label: 'Company Name', value: false },
  projectId: { label: 'Project Id', value: false },
  notificationId: { label: 'Notification Id', value: false },
  firstNameBefore: { label: 'First Name Before', value: false },
  lastNameBefore: { label: 'Last Name Before', value: false },
  firstNameAfter: { label: 'First Name After', value: false },
  lastNameAfter: { label: 'Last Name After', value: false },
  description: { label: 'Description', value: false },
  modelId: { label: 'Model Id', value: false },
  dataView: { label: 'Data View', value: false },
  dataType: { label: 'DataType', value: false },
  privacyVersion: { label: 'Privacy Version', value: false },
  termsVersion: { label: 'T&C Version', value: false },
  dateAndTime: { label: 'Date & Time', value: false },
  whitelistedDomainsBefore: { label: 'Whitelisted Domains Before', value: false },
  whitelistedDomainsAfter: { label: 'Whitelisted Domains After', value: false },
}

const DOWNLOAD_AUDIT_LAMBDA = `downloadAuditTrail-${ENV}`;

const ExportAuditTrail = function ExportAuditTrail({ user, name }) {
  const [showDropdown, setShowDropdown] = useState(false)

  const { studies } = useGraphQL();
  const { sendMessageToQueueForAudits } = useSQS()
  const { changeState } = useSnackBar();

  const title = <div role="button" tabIndex={0} onKeyPress={() => { }} onClick={() => setShowDropdown(!showDropdown)} style={{ display: 'inline-flex', fontSize: '18px', color: '#FFFFFF' }}>Export Audit Data</div>
  const [selectedFields, setSelectedFields] = useState(dataFields)
  const [enableDownloadButton, setEnableDownloadButton] = useState(false)

  const getNonEmptyAuditFields = (selections) => {
    const nonEmptyAuditFields = []
    Object.keys(dataFields).map((k) => {
      if (selections[k].value) {
        nonEmptyAuditFields.push(k)
      }
      return null
    })
    return nonEmptyAuditFields
  }

  const modifySelections = (key) => {
    const newSelectedFields = { ...selectedFields }
    newSelectedFields[key] = { label: selectedFields[key].label, value: !selectedFields[key].value }
    setSelectedFields(newSelectedFields)
    if (getNonEmptyAuditFields(newSelectedFields).length) {
      setEnableDownloadButton(true)
    } else {
      setEnableDownloadButton(false)
    }
  };

  const exportAuditTrailData = async () => {
    const messageData = await createMessageForSQS({
      eventType: AUDIT_TRAIL_EVENT_TYPES.DOWNLOAD_AUDIT_TRAIL,
      userId: user.id,
    })
    sendMessageToQueueForAudits(messageData)
    const data = {
      email: user.email,
      auditFields: [],
      userName: name,
      userId: user.id,
    }
    if (studies.length) {
      data.companyName = studies[0].company
    }

    data.auditFields = getNonEmptyAuditFields(selectedFields)

    if (data.auditFields.length) {

      try {

        await invokeLambdaFunction(DOWNLOAD_AUDIT_LAMBDA, data)
        const message = 'Your data is being prepared. You will receive an email with the download link once your data is ready!';
        changeState('success', message, true);
      } catch (e) {
        changeState('warning', e.message || e, true);
      }

    }
    setShowDropdown(false)
  }

  const renderDropDownContent = () => (
    <div>
      <Form style={{
        width: '300px', maxHeight: '350px', padding: '0px 16px', overflowY: 'auto',
      }}
      >
        <Form.Group className="mb-3">
          <Form.Label style={{ height: '2rem' }}>Select Fields To Download:</Form.Label>
          <div className="audit-items-div">
            {Object.keys(dataFields).map((k) => (
              <Form.Check
                className="audit-dropdown-item"
                label={dataFields[k].label}
                key={k}
                onClick={() => modifySelections(k)}
              />
            ))}
          </div>
        </Form.Group>
        <Button disabled={!enableDownloadButton} onClick={exportAuditTrailData}>Download</Button>
      </Form>
    </div>
  )

  return (
    <NavDropdown className="audit-dropdown" show={showDropdown} title={title}>
      {renderDropDownContent()}
    </NavDropdown>
  )
}

export default ExportAuditTrail
