import React from 'react';
import { styled } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const LightTooltipTwoCol = styled(({ className, ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Tooltip {...props} classes={{ popper: className }} disableInteractive />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#FFF3EA',
    color: '#222222',
    top: '20px',
    border: '1px solid #FEE7D5',
    borderRadius: '2px',
    fontSize: '12px',
    padding: '0px',
    maxWidth: '500px',
  },
}));

const LatestUpdateTooltip = ({
  canPreview,
  isTumorVolumeDataAvailable,
  displayDateRange,
  displayExcludedGroups,
  dateRange,
  unassignedMice,
  excludeGroups,
  referenceDayForBWC,
  referenceDayForTGI,
  name,
  createdAt,
  lable,
  showTGI,
  displayJsdIndicator = () => {},
}) => (
  <>
    {
      canPreview
        ? (
          <LightTooltipTwoCol
            data-testid="tooltip"
            className="navHeader-notifications-toolTips"
            title={(
              <>
                <Typography component="div" className="AvenirNextLTProRegular">
                  <div className="studyDetails-twoColTooptipTitle">Filters Applied For This Data</div>
                  <div>
                    <div className="studyDetails-twoColTooptipBase-Row">
                      <div className="studyDetails-twoColTooptipBase-leftCol studyDetails-twoColTooptipBase-leftColCotent">
                        Date Range:
                      </div>
                      <div className="studyDetails-twoColTooptipBase-rightCol studyDetails-twoColTooptipBase-leftColCotent">
                        {displayDateRange(dateRange)}
                      </div>
                    </div>
                    <div className="studyDetails-twoColTooptipBase-Row">
                      <div className="studyDetails-twoColTooptipBase-leftCol studyDetails-twoColTooptipBase-leftColCotent">
                        Unassigned Mice:
                      </div>
                      <div className="studyDetails-twoColTooptipBase-rightCol studyDetails-twoColTooptipBase-leftColCotent">
                        {unassignedMice ? 'Included' : 'Excluded'}
                      </div>
                    </div>
                    <div className="studyDetails-twoColTooptipBase-Row">
                      <div className="studyDetails-twoColTooptipBase-leftCol studyDetails-twoColTooptipBase-leftColCotent">
                        Excluded Groups:
                      </div>
                      <div className="studyDetails-twoColTooptipBase-rightCol studyDetails-twoColTooptipBase-leftColCotent">
                        {displayExcludedGroups(excludeGroups)}
                      </div>
                    </div>
                    <div className="studyDetails-twoColTooptipBase-Row">
                      <div className="studyDetails-twoColTooptipBase-leftCol studyDetails-twoColTooptipBase-leftColCotent">
                        Reference Day (BWC):
                      </div>
                      <div className="studyDetails-twoColTooptipBase-rightCol studyDetails-twoColTooptipBase-leftColCotent">
                        {`Day ${referenceDayForBWC}`}
                      </div>
                    </div>
                    {isTumorVolumeDataAvailable && (
                      <>
                        <div className="studyDetails-twoColTooptipBase-Row">
                          <div className="studyDetails-twoColTooptipBase-leftCol studyDetails-twoColTooptipBase-leftColCotent">
                            TGI Control Group:
                          </div>
                          <div className="studyDetails-twoColTooptipBase-rightCol studyDetails-twoColTooptipBase-leftColCotent">
                            {showTGI()}
                          </div>
                        </div>
                        <div className="studyDetails-twoColTooptipBase-Row">
                          <div className="studyDetails-twoColTooptipBase-leftCol studyDetails-twoColTooptipBase-leftColCotent">
                            Reference Day (TVC & TGI):
                          </div>
                          <div className="studyDetails-twoColTooptipBase-rightCol studyDetails-twoColTooptipBase-leftColCotent">
                            {(referenceDayForTGI !== -1) ? `Day ${referenceDayForTGI}` : 'None'}
                          </div>
                        </div>
                      </>
                    )}

                  </div>
                </Typography>
              </>
            )}
            placement="bottom"
          >
            <div>{displayJsdIndicator()} {`${lable}${name ? ` ${name},` : ''} ${createdAt}`}</div>
          </LightTooltipTwoCol>
        )
        : <div>{`${lable}${name ? ` ${name},` : ''} ${createdAt}`}</div>
    }
  </>
)

export default LatestUpdateTooltip
