const editablePropsConfig = {
  spellCheck: false,
  autoFocus: false,
};

// eslint-disable-next-line import/prefer-default-export
export const editableProps = (readOnly = true, placeholder = 'Enter comment here') => {
  if (readOnly) {
    editablePropsConfig.placeholder = '';
  } else {
    editablePropsConfig.placeholder = placeholder
  }

  return editablePropsConfig
}
