import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Checkbox from '@mui/material/Checkbox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function MultiModalSelectComments({
  commentsModelsDownloadFilterComments,
  setSelectedValues,

}) {
  const handleChange = (event, value) => {
    setSelectedValues(value)
  }

  return (
    <Autocomplete
      disableCloseOnSelect
      multiple
      limitTags={4}
      id="multiple-limit-tags"
      disableClearable
      options={commentsModelsDownloadFilterComments}
      getOptionLabel={(option) => option.model}
      onChange={handleChange}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            key={option.model}
            icon={icon}
            checkedIcon={checkedIcon}
            checked={selected}
          />
          {option.model}
        </li>
      )}
      sx={{
        width: '100%', backgroundColor: 'white', borderRadius: '5px!important', border: 'none',
      }}
      renderInput={(params) => (
        <TextField {...params} placeholder="Selected Models" />
      )}
    />

  );
}
export default MultiModalSelectComments;
