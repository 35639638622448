import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ColorPicker from './colorPicker';
import { decimalToHex } from './utils';

function ColorModal({
  open, onApply, onClose, currentColor,
}) {
  const [newColor, setNewColor] = useState(null);

  const onYes = () => {
    onApply(newColor || currentColor || '#FFFFFF');
    setNewColor(null);
    onClose();
  }

  const onCancel = () => {
    setNewColor(null);
    onClose();
  };

  const onHide = (event) => {
    event.preventDefault();
  };

  const onColorChange = (color) => {
    const hexCode = `${color.hex}${decimalToHex(color.rgb.a)}`;
    setNewColor(hexCode);
  };

  return (
    <Modal open={open} onClose={onHide} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box className="color-modal">
        <div className="color-modal-title">Choose Colors</div>
        <div className="color-modal-content">
          <ColorPicker
            color={newColor || currentColor || '#FFFFFF'}
            onChange={onColorChange}
          />
        </div>
        <div className="color-modal-footer">
          <Button
            type="button"
            variant="light"
            onClick={onCancel}
            className="color-chooser-action-button"
          >
            Cancel
          </Button>
          <Button
            data-testid="color-modal-ok-button"
            type="button"
            variant="light"
            onClick={onYes}
            className="color-chooser-action-button"
            sx={{
              float: 'right',
            }}
          >
            Ok
          </Button>
        </div>
      </Box>
    </Modal>
  );
}

export default ColorModal;
