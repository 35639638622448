import _ from 'lodash';

export const getCustomColors = (selectedScheme, customColors, colorMappings) => {
  if ((!selectedScheme.length || !selectedScheme[0].colorMapping || !selectedScheme[0].colorMapping.length)
    && _.isEmpty(colorMappings)) {
    return customColors;
  }
  const mappedColors = [...customColors];
  if (selectedScheme.length && selectedScheme[0].colorMapping && selectedScheme[0].colorMapping.length) {
    selectedScheme[0].colorMapping.forEach((colorObj) => mappedColors[colorObj.group] = colorObj.color)
  }
  if (!_.isEmpty(colorMappings)) {
    Object.keys(colorMappings).forEach((key) => mappedColors[key] = colorMappings[key]);
  }
  return mappedColors;
};

export const decimalToHex = (alpha) => (alpha === 0 ? '00' : Math.round(255 * alpha).toString(16));
