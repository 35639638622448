import React, { useEffect, useState } from 'react';
import {
  Modal,
} from 'react-bootstrap';
import CloseOutlined from '@mui/icons-material/CloseOutlined';
import CircularProgress from '@mui/material/CircularProgress';

import './style.css';

const FeedbackModal = function Feedback(props) {

  const { showFeedbackForm } = props;

  const [showLoader, setShowLoader] = useState(true);

  const toggleFeedback = () => {
    const { toggleFeedbackForm } = props;
    toggleFeedbackForm();
  };

  useEffect(() => {
    const iframe = document.getElementById('freshwidget-embedded-form');
    if (iframe) {
      iframe.addEventListener('load', () => {
        setShowLoader(false);
      });
    }
    if (!showFeedbackForm) {
      setShowLoader(true);
    }
  }, [showFeedbackForm, showLoader]);

  return (
    <Modal show={showFeedbackForm} onHide={toggleFeedback} className="feedback-modal">
      <Modal.Body>
        <div className={showLoader ? 'modal-loader' : 'modal-loader hide-div'}>
          <CircularProgress />
        </div>
        <div className={!showLoader ? '' : 'hide-div'}>
          <div className="modal-close">
            <CloseOutlined onClick={toggleFeedback} />
          </div>
          <iframe
            id="freshwidget-embedded-form"
            className="freshwidget-embedded-form"
            title="Help & Support"
            width="750"
            src="https://onedigitaljsr.freshservice.com/widgets/feedback_widget/new?&widgetType=embedded&screenshot=no"
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default FeedbackModal;
