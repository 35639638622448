/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
import ExcelFile from './ExcelPlugin/components/ExcelFile';
import ExcelSheet from './ExcelPlugin/elements/ExcelSheet';
import ExcelColumn from './ExcelPlugin/elements/ExcelColumn';

ExcelFile.ExcelSheet = ExcelSheet;
ExcelFile.ExcelColumn = ExcelColumn;

const ReactExport = {
  ExcelFile,
};

export default ReactExport;
export {
  ExcelFile,
  ExcelSheet,
  ExcelColumn,
};
