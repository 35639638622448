import React, {
  useState, useEffect,
} from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import {
  Form, Row, Col,
} from 'react-bootstrap'
import { CUSTOM_BOX_STYLE } from '../../../constants';

function AddUserModal({
  companyAccount, toggleUserAccountModal, showAddUserModal, inviteUser, setLoading, setShowSnackBar, setSnackbarMessage, reloadUsers, companyUsers,
}) {

  const [whitelistedDomains, setWhitelistedDomains] = useState([])
  const [whitelistedDomainsError, setWhitelistedDomainsError] = useState('')
  const [createButton, setCreateButton] = useState(true);

  const [email, setEmail] = useState('')

  const onHide = () => {
    setCreateButton(true)
    setEmail('')
    toggleUserAccountModal()
  };

  const onAddUser = () => {
    const userEmail = email.toLowerCase()
    const companyGroup = companyAccount.studyIdentifiers.length ? companyAccount.studyIdentifiers[0] : ''
    const companyId = companyAccount.id
    setLoading(true);
    const isUserExists = companyUsers.some((user) => user.email === userEmail);
    if (isUserExists) {
      setSnackbarMessage('error')
      setShowSnackBar({ show: true, text: 'User already exists.  Invitation cannot be sent.' });
      setLoading(false);
      onHide()
      return;
    }
    const users = [{
      email: userEmail,
      company_id: companyId,
      group: companyGroup,
      other_groups: [],
      force_creation: false,
    }];
    inviteUser(users).then(async (res) => {
      const response = res.data.createUserForCompany;
      // if (response.requiresForceCreation) {
      //   const inviteUserWrapper = (em, cId, grp) => () => inviteUser(em, cId, grp, true)
      //   setForceUserInviteProps({
      //     message: response.error,
      //     inviteUser: inviteUserWrapper(userEmail, companyId, companyGroup),
      //     onComplete: (r) => {
      //       if (r && r.message) {
      //         setSnackbarMessage('success')
      //         setShowSnackBar(true)
      //         reloadUsers()
      //       } else if (r && r.error) {
      //         setSnackbarMessage('error')
      //         setShowSnackBar(true)
      //       }
      //       setLoading(false)
      //       setForceUserInviteProps({})
      //     },
      //   })
      //   setForceUserInviteModal(true)
      //   return null
      // }
      if (response.message) {
        setSnackbarMessage('success')
        setShowSnackBar({ show: true, text: response.message })
        await reloadUsers(true)
      } else if (response.error) {
        setSnackbarMessage('error')
        setShowSnackBar({ show: true, text: response.error })
      }

      setLoading(false)
      return null
    })
    onHide();
  }

  useEffect(() => {
    if (companyAccount) {
      setWhitelistedDomains(companyAccount.whitelistedDomains)
    }
  }, [companyAccount])

  const whitelistedDomainsOnChange = (e) => {
    const val = e.target.value;
    setEmail(val)
    if (val === '') {
      setWhitelistedDomainsError('')
      setCreateButton(true)
      return null
    }
    let domainCheck = false;
    whitelistedDomains.map((domain) => {
      if (val.endsWith(domain)) {
        domainCheck = true
      }
      return null
    })
    if (domainCheck) {
      setCreateButton(false)
      setWhitelistedDomainsError('')
    } else {
      setWhitelistedDomainsError('Error: User does not meet the whitelisted email domain requirements')
      setCreateButton(true)
    }
    return null
  }

  return (
    <Modal open={showAddUserModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box sx={CUSTOM_BOX_STYLE}>
        <div style={{ textAlign: 'center' }}>
          <span className="containerTitle" data-testid="container_Title">Add User</span>
        </div>
        <Form onSubmit={onAddUser}>
          <Row>
            <Col>
              <Row>
                <div style={{ width: '100%' }}>

                  <Form.Group
                    // controlId="formBasicSelect"
                    className="studyDetails-dataFilters-formGroup"
                    style={{ marginLeft: '10px' }}
                  >
                    <Form.Label className="addAccount-formLabel" htmlFor="email_input" data-testid="addAccount_formLabel">User Email</Form.Label><span style={{ content: ' *', color: 'red' }} data-testid="whitelisted_email"><sup>*</sup> {` (white listed domain(s): ${whitelistedDomains.join(',')})`}</span>
                    <Form.Control
                      id="email_input"
                      data-testid="email_input"
                      style={{ height: '45px', marginBottom: '-20px' }}
                      as="input"
                      defaultValue=""
                      value={email}
                      placeholder="user@companydomian.com"
                      required
                      onChange={(e) => whitelistedDomainsOnChange(e)}
                    />
                    <p
                      style={{ color: '#E2AAA0', marginTop: '20px' }}
                      data-testid="email_error"
                    > {whitelistedDomainsError}
                    </p>
                  </Form.Group>
                </div>
              </Row>
            </Col>
          </Row>
          <Button
            data-testid="invite_user_button"
            className="LoginFormSignInButtonChangePassword"
            type="submit"
            variant="primary"
            disabled={createButton}
          >
            Invite User
          </Button>

          <Button
            data-testid="cancel_invite_button"
            className="LoginFormOutlineButton"
            type="button"
            variant="light"
            onClick={onHide}
          >
            Cancel
          </Button>
        </Form>

      </Box>
    </Modal>
  );
}

export default AddUserModal;
