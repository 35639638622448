import React, { useEffect, useState } from 'react';
import {
  Button,
  Modal,
} from 'react-bootstrap';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import CircularProgress from '@mui/material/CircularProgress';

const RouterPrompt = (props) => {
  const {
    onOK,
    when,
    history,
    showPrompt,
    unblockRef,
    currentPath,
    description,
    setShowPrompt,
  } = props;

  const [loader, setLoader] = useState(false)

  useEffect(() => {
    initBeforeUnLoad(when);
  }, [when]);

  // eslint-disable-next-line
  useEffect(() => {
    return () => {
      window.onbeforeunload = () => {}
    }
  }, [])

  const initBeforeUnLoad = (when) => {
    // eslint-disable-next-line consistent-return
    window.onbeforeunload = (event) => {
      // Show prompt based on state
      if (when) {
        const e = event || window.event;
        e.preventDefault();
        if (e) {
          e.returnValue = ''
        }
        return '';
      }
    };
  };

  const handleOK = () => {
    setLoader(true)
    onOK()
    setTimeout(() => {
      if (unblockRef.current) {
        unblockRef.current();
      }
      setLoader(false)
      history.push(currentPath);
      // eslint-disable-next-line
    }, 1000)
  };

  const handleCancel = () => {
    setShowPrompt(false);
  };

  const handleOpenNewTab = () => {
    window.open(`${window.location.origin}${currentPath}`, '_blank');
    handleCancel()
  }
  return (
    <Modal
      show={showPrompt}
      onHide={() => handleCancel()}
    >
      <Modal.Header className="documents-oldModal-header" closeButton={() => handleCancel()}>
        <Modal.Title style={{ color: '#fff', marginBottom: '2px' }}>
          File upload in progress
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="documents-oldModal-body">
        <Alert className="documents-AlertOnTop" sx={{ fontFamily: 'AvenirNextLTPro-Regular' }} severity="warning">
          <AlertTitle sx={{ fontFamily: 'AvenirNextLTPro-Regular' }}>Warning</AlertTitle>
          { description }
        </Alert>
      </Modal.Body>
      <Modal.Footer className="documents-oldModal-footer">
        <Button
          type="submit"
          variant="primary"
          className="LoginFormSignInButton"
          onClick={() => handleOK()}
        >
          Continue
          { loader && (
            <CircularProgress
              size={20}
              style={{
                color: '#fff',
                marginLeft: '3px',
                marginTop: '1px',
                position: 'absolute',
              }}
            />
          )}
        </Button>
        <Button
          className="LoginFormOutlineButton"
          type="button"
          variant="light"
          onClick={() => handleOpenNewTab()}
        >
          Open New Tab
        </Button>
        <Button
          className="LoginFormOutlineButton"
          type="button"
          variant="light"
          onClick={() => handleCancel()}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default RouterPrompt;
