const isValidPassword = (password) => {
  if (!password.length) {
    return true;
  }
  // Password must have at least 15 characters, 1 lowercase character, 1 uppercase character, 1 number, 1 special character
  const passRegex = '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{15,}$';
  return password.match(passRegex);
};

export default isValidPassword;
