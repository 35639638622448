/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      email
      favorites
      notifications
      first_name
      last_name
      phone
      companyAccountID
      lastLoginAt
      studyVisited
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUserNotificationEventAction = /* GraphQL */ `
  mutation CreateUserNotificationEventAction(
    $input: CreateUserNotificationEventActionInput!
    $condition: ModelUserNotificationEventActionConditionInput
  ) {
    createUserNotificationEventAction(input: $input, condition: $condition) {
      id
      eventId
      userId
      action
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUserNotificationEventAction = /* GraphQL */ `
  mutation UpdateUserNotificationEventAction(
    $input: UpdateUserNotificationEventActionInput!
    $condition: ModelUserNotificationEventActionConditionInput
  ) {
    updateUserNotificationEventAction(input: $input, condition: $condition) {
      id
      eventId
      userId
      action
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUserNotificationEventAction = /* GraphQL */ `
  mutation DeleteUserNotificationEventAction(
    $input: DeleteUserNotificationEventActionInput!
    $condition: ModelUserNotificationEventActionConditionInput
  ) {
    deleteUserNotificationEventAction(input: $input, condition: $condition) {
      id
      eventId
      userId
      action
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUserPreferences = /* GraphQL */ `
  mutation CreateUserPreferences(
    $input: CreateUserPreferencesInput!
    $condition: ModelUserPreferencesConditionInput
  ) {
    createUserPreferences(input: $input, condition: $condition) {
      id
      userId
      notificationOptIn
      emailFrequencyPreferences
      userRegion
      userTimeZone
      lastEmailSentAt
      lastUpdatedPasswordAt
      deleteAccountAt
      sharePersonalData
      terms {
        ipAddress
        timestamp
        termsVersion
        privacyVersion
        __typename
      }
      isImmediate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUserPreferences = /* GraphQL */ `
  mutation DeleteUserPreferences(
    $input: DeleteUserPreferencesInput!
    $condition: ModelUserPreferencesConditionInput
  ) {
    deleteUserPreferences(input: $input, condition: $condition) {
      id
      userId
      notificationOptIn
      emailFrequencyPreferences
      userRegion
      userTimeZone
      lastEmailSentAt
      lastUpdatedPasswordAt
      deleteAccountAt
      sharePersonalData
      terms {
        ipAddress
        timestamp
        termsVersion
        privacyVersion
        __typename
      }
      isImmediate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUserNotificationSubscriptionCheckpoint = /* GraphQL */ `
  mutation CreateUserNotificationSubscriptionCheckpoint(
    $input: CreateUserNotificationSubscriptionCheckpointInput!
    $condition: ModelUserNotificationSubscriptionCheckpointConditionInput
  ) {
    createUserNotificationSubscriptionCheckpoint(
      input: $input
      condition: $condition
    ) {
      id
      userId
      projectCode
      createdAt
      type
      updatedAt
      __typename
    }
  }
`;
export const updateUserNotificationSubscriptionCheckpoint = /* GraphQL */ `
  mutation UpdateUserNotificationSubscriptionCheckpoint(
    $input: UpdateUserNotificationSubscriptionCheckpointInput!
    $condition: ModelUserNotificationSubscriptionCheckpointConditionInput
  ) {
    updateUserNotificationSubscriptionCheckpoint(
      input: $input
      condition: $condition
    ) {
      id
      userId
      projectCode
      createdAt
      type
      updatedAt
      __typename
    }
  }
`;
export const deleteUserNotificationSubscriptionCheckpoint = /* GraphQL */ `
  mutation DeleteUserNotificationSubscriptionCheckpoint(
    $input: DeleteUserNotificationSubscriptionCheckpointInput!
    $condition: ModelUserNotificationSubscriptionCheckpointConditionInput
  ) {
    deleteUserNotificationSubscriptionCheckpoint(
      input: $input
      condition: $condition
    ) {
      id
      userId
      projectCode
      createdAt
      type
      updatedAt
      __typename
    }
  }
`;
export const createComment = /* GraphQL */ `
  mutation CreateComment(
    $input: CreateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    createComment(input: $input, condition: $condition) {
      id
      modelID
      projectID
      commentedBy
      deletedAt
      note
      commentType
      parentID
      createdAt
      updatedAt
      user {
        id
        email
        favorites
        notifications
        first_name
        last_name
        phone
        companyAccountID
        lastLoginAt
        studyVisited
        createdAt
        updatedAt
        __typename
      }
      parentComment {
        id
        modelID
        projectID
        commentedBy
        deletedAt
        note
        commentType
        parentID
        createdAt
        updatedAt
        user {
          id
          email
          favorites
          notifications
          first_name
          last_name
          phone
          companyAccountID
          lastLoginAt
          studyVisited
          createdAt
          updatedAt
          __typename
        }
        parentComment {
          id
          modelID
          projectID
          commentedBy
          deletedAt
          note
          commentType
          parentID
          createdAt
          updatedAt
          user {
            id
            email
            favorites
            notifications
            first_name
            last_name
            phone
            companyAccountID
            lastLoginAt
            studyVisited
            createdAt
            updatedAt
            __typename
          }
          parentComment {
            id
            modelID
            projectID
            commentedBy
            deletedAt
            note
            commentType
            parentID
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const updateComment = /* GraphQL */ `
  mutation UpdateComment(
    $input: UpdateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    updateComment(input: $input, condition: $condition) {
      id
      modelID
      projectID
      commentedBy
      deletedAt
      note
      commentType
      parentID
      createdAt
      updatedAt
      user {
        id
        email
        favorites
        notifications
        first_name
        last_name
        phone
        companyAccountID
        lastLoginAt
        studyVisited
        createdAt
        updatedAt
        __typename
      }
      parentComment {
        id
        modelID
        projectID
        commentedBy
        deletedAt
        note
        commentType
        parentID
        createdAt
        updatedAt
        user {
          id
          email
          favorites
          notifications
          first_name
          last_name
          phone
          companyAccountID
          lastLoginAt
          studyVisited
          createdAt
          updatedAt
          __typename
        }
        parentComment {
          id
          modelID
          projectID
          commentedBy
          deletedAt
          note
          commentType
          parentID
          createdAt
          updatedAt
          user {
            id
            email
            favorites
            notifications
            first_name
            last_name
            phone
            companyAccountID
            lastLoginAt
            studyVisited
            createdAt
            updatedAt
            __typename
          }
          parentComment {
            id
            modelID
            projectID
            commentedBy
            deletedAt
            note
            commentType
            parentID
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const deleteComment = /* GraphQL */ `
  mutation DeleteComment(
    $input: DeleteCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    deleteComment(input: $input, condition: $condition) {
      id
      modelID
      projectID
      commentedBy
      deletedAt
      note
      commentType
      parentID
      createdAt
      updatedAt
      user {
        id
        email
        favorites
        notifications
        first_name
        last_name
        phone
        companyAccountID
        lastLoginAt
        studyVisited
        createdAt
        updatedAt
        __typename
      }
      parentComment {
        id
        modelID
        projectID
        commentedBy
        deletedAt
        note
        commentType
        parentID
        createdAt
        updatedAt
        user {
          id
          email
          favorites
          notifications
          first_name
          last_name
          phone
          companyAccountID
          lastLoginAt
          studyVisited
          createdAt
          updatedAt
          __typename
        }
        parentComment {
          id
          modelID
          projectID
          commentedBy
          deletedAt
          note
          commentType
          parentID
          createdAt
          updatedAt
          user {
            id
            email
            favorites
            notifications
            first_name
            last_name
            phone
            companyAccountID
            lastLoginAt
            studyVisited
            createdAt
            updatedAt
            __typename
          }
          parentComment {
            id
            modelID
            projectID
            commentedBy
            deletedAt
            note
            commentType
            parentID
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const createSystemMessages = /* GraphQL */ `
  mutation CreateSystemMessages(
    $input: CreateSystemMessagesInput!
    $condition: ModelSystemMessagesConditionInput
  ) {
    createSystemMessages(input: $input, condition: $condition) {
      id
      status
      recalledTimestamp
      sentBy
      recipients
      messageContent
      createdAt
      updatedAt
      user {
        id
        email
        favorites
        notifications
        first_name
        last_name
        phone
        companyAccountID
        lastLoginAt
        studyVisited
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateSystemMessages = /* GraphQL */ `
  mutation UpdateSystemMessages(
    $input: UpdateSystemMessagesInput!
    $condition: ModelSystemMessagesConditionInput
  ) {
    updateSystemMessages(input: $input, condition: $condition) {
      id
      status
      recalledTimestamp
      sentBy
      recipients
      messageContent
      createdAt
      updatedAt
      user {
        id
        email
        favorites
        notifications
        first_name
        last_name
        phone
        companyAccountID
        lastLoginAt
        studyVisited
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteSystemMessages = /* GraphQL */ `
  mutation DeleteSystemMessages(
    $input: DeleteSystemMessagesInput!
    $condition: ModelSystemMessagesConditionInput
  ) {
    deleteSystemMessages(input: $input, condition: $condition) {
      id
      status
      recalledTimestamp
      sentBy
      recipients
      messageContent
      createdAt
      updatedAt
      user {
        id
        email
        favorites
        notifications
        first_name
        last_name
        phone
        companyAccountID
        lastLoginAt
        studyVisited
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const createSystemMessagesReception = /* GraphQL */ `
  mutation CreateSystemMessagesReception(
    $input: CreateSystemMessagesReceptionInput!
    $condition: ModelSystemMessagesReceptionConditionInput
  ) {
    createSystemMessagesReception(input: $input, condition: $condition) {
      id
      userId
      messageId
      status
      message {
        id
        status
        recalledTimestamp
        sentBy
        recipients
        messageContent
        createdAt
        updatedAt
        user {
          id
          email
          favorites
          notifications
          first_name
          last_name
          phone
          companyAccountID
          lastLoginAt
          studyVisited
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      user {
        id
        email
        favorites
        notifications
        first_name
        last_name
        phone
        companyAccountID
        lastLoginAt
        studyVisited
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateSystemMessagesReception = /* GraphQL */ `
  mutation UpdateSystemMessagesReception(
    $input: UpdateSystemMessagesReceptionInput!
    $condition: ModelSystemMessagesReceptionConditionInput
  ) {
    updateSystemMessagesReception(input: $input, condition: $condition) {
      id
      userId
      messageId
      status
      message {
        id
        status
        recalledTimestamp
        sentBy
        recipients
        messageContent
        createdAt
        updatedAt
        user {
          id
          email
          favorites
          notifications
          first_name
          last_name
          phone
          companyAccountID
          lastLoginAt
          studyVisited
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      user {
        id
        email
        favorites
        notifications
        first_name
        last_name
        phone
        companyAccountID
        lastLoginAt
        studyVisited
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteSystemMessagesReception = /* GraphQL */ `
  mutation DeleteSystemMessagesReception(
    $input: DeleteSystemMessagesReceptionInput!
    $condition: ModelSystemMessagesReceptionConditionInput
  ) {
    deleteSystemMessagesReception(input: $input, condition: $condition) {
      id
      userId
      messageId
      status
      message {
        id
        status
        recalledTimestamp
        sentBy
        recipients
        messageContent
        createdAt
        updatedAt
        user {
          id
          email
          favorites
          notifications
          first_name
          last_name
          phone
          companyAccountID
          lastLoginAt
          studyVisited
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      user {
        id
        email
        favorites
        notifications
        first_name
        last_name
        phone
        companyAccountID
        lastLoginAt
        studyVisited
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteCompanyAccount = /* GraphQL */ `
  mutation DeleteCompanyAccount(
    $input: DeleteCompanyAccountInput!
    $condition: ModelCompanyAccountConditionInput
  ) {
    deleteCompanyAccount(input: $input, condition: $condition) {
      id
      name
      studyIdentifiers
      whitelistedDomains
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCompanyGroups = /* GraphQL */ `
  mutation CreateCompanyGroups(
    $input: CreateCompanyGroupsInput!
    $condition: ModelCompanyGroupsConditionInput
  ) {
    createCompanyGroups(input: $input, condition: $condition) {
      id
      name
      companyAccountId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCompanyGroups = /* GraphQL */ `
  mutation UpdateCompanyGroups(
    $input: UpdateCompanyGroupsInput!
    $condition: ModelCompanyGroupsConditionInput
  ) {
    updateCompanyGroups(input: $input, condition: $condition) {
      id
      name
      companyAccountId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCompanyGroups = /* GraphQL */ `
  mutation DeleteCompanyGroups(
    $input: DeleteCompanyGroupsInput!
    $condition: ModelCompanyGroupsConditionInput
  ) {
    deleteCompanyGroups(input: $input, condition: $condition) {
      id
      name
      companyAccountId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createHelp = /* GraphQL */ `
  mutation CreateHelp(
    $input: CreateHelpInput!
    $condition: ModelhelpConditionInput
  ) {
    createHelp(input: $input, condition: $condition) {
      id
      helpText
      helpHtml
      addedBy
      createdAt
      updatedAt
      user {
        id
        email
        favorites
        notifications
        first_name
        last_name
        phone
        companyAccountID
        lastLoginAt
        studyVisited
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateHelp = /* GraphQL */ `
  mutation UpdateHelp(
    $input: UpdateHelpInput!
    $condition: ModelhelpConditionInput
  ) {
    updateHelp(input: $input, condition: $condition) {
      id
      helpText
      helpHtml
      addedBy
      createdAt
      updatedAt
      user {
        id
        email
        favorites
        notifications
        first_name
        last_name
        phone
        companyAccountID
        lastLoginAt
        studyVisited
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteHelp = /* GraphQL */ `
  mutation DeleteHelp(
    $input: DeleteHelpInput!
    $condition: ModelhelpConditionInput
  ) {
    deleteHelp(input: $input, condition: $condition) {
      id
      helpText
      helpHtml
      addedBy
      createdAt
      updatedAt
      user {
        id
        email
        favorites
        notifications
        first_name
        last_name
        phone
        companyAccountID
        lastLoginAt
        studyVisited
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const createColorScheme = /* GraphQL */ `
  mutation CreateColorScheme(
    $input: CreateColorSchemeInput!
    $condition: ModelColorSchemeConditionInput
  ) {
    createColorScheme(input: $input, condition: $condition) {
      id
      userId
      studyId
      modelId
      schemeType
      value
      graphName
      taskType
      colorMapping {
        group
        color
        __typename
      }
      createdAt
      updatedAt
      user {
        id
        email
        favorites
        notifications
        first_name
        last_name
        phone
        companyAccountID
        lastLoginAt
        studyVisited
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateColorScheme = /* GraphQL */ `
  mutation UpdateColorScheme(
    $input: UpdateColorSchemeInput!
    $condition: ModelColorSchemeConditionInput
  ) {
    updateColorScheme(input: $input, condition: $condition) {
      id
      userId
      studyId
      modelId
      schemeType
      value
      graphName
      taskType
      colorMapping {
        group
        color
        __typename
      }
      createdAt
      updatedAt
      user {
        id
        email
        favorites
        notifications
        first_name
        last_name
        phone
        companyAccountID
        lastLoginAt
        studyVisited
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteColorScheme = /* GraphQL */ `
  mutation DeleteColorScheme(
    $input: DeleteColorSchemeInput!
    $condition: ModelColorSchemeConditionInput
  ) {
    deleteColorScheme(input: $input, condition: $condition) {
      id
      userId
      studyId
      modelId
      schemeType
      value
      graphName
      taskType
      colorMapping {
        group
        color
        __typename
      }
      createdAt
      updatedAt
      user {
        id
        email
        favorites
        notifications
        first_name
        last_name
        phone
        companyAccountID
        lastLoginAt
        studyVisited
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const createGraphAnnotations = /* GraphQL */ `
  mutation CreateGraphAnnotations(
    $input: CreateGraphAnnotationsInput!
    $condition: ModelGraphAnnotationsConditionInput
  ) {
    createGraphAnnotations(input: $input, condition: $condition) {
      id
      studyId
      modelId
      graphName
      value
      taskType
      isActive
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateGraphAnnotations = /* GraphQL */ `
  mutation UpdateGraphAnnotations(
    $input: UpdateGraphAnnotationsInput!
    $condition: ModelGraphAnnotationsConditionInput
  ) {
    updateGraphAnnotations(input: $input, condition: $condition) {
      id
      studyId
      modelId
      graphName
      value
      taskType
      isActive
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteGraphAnnotations = /* GraphQL */ `
  mutation DeleteGraphAnnotations(
    $input: DeleteGraphAnnotationsInput!
    $condition: ModelGraphAnnotationsConditionInput
  ) {
    deleteGraphAnnotations(input: $input, condition: $condition) {
      id
      studyId
      modelId
      graphName
      value
      taskType
      isActive
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      email
      favorites
      notifications
      first_name
      last_name
      phone
      companyAccountID
      lastLoginAt
      studyVisited
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      email
      favorites
      notifications
      first_name
      last_name
      phone
      companyAccountID
      lastLoginAt
      studyVisited
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createNotificationEvent = /* GraphQL */ `
  mutation CreateNotificationEvent(
    $input: CreateNotificationEventInput!
    $condition: ModelNotificationEventConditionInput
  ) {
    createNotificationEvent(input: $input, condition: $condition) {
      id
      type
      createdBy
      companyName
      projectCode
      createdAt
      description
      userId
      attributes {
        key
        value
        __typename
      }
      updatedAt
      __typename
    }
  }
`;
export const updateNotificationEvent = /* GraphQL */ `
  mutation UpdateNotificationEvent(
    $input: UpdateNotificationEventInput!
    $condition: ModelNotificationEventConditionInput
  ) {
    updateNotificationEvent(input: $input, condition: $condition) {
      id
      type
      createdBy
      companyName
      projectCode
      createdAt
      description
      userId
      attributes {
        key
        value
        __typename
      }
      updatedAt
      __typename
    }
  }
`;
export const deleteNotificationEvent = /* GraphQL */ `
  mutation DeleteNotificationEvent(
    $input: DeleteNotificationEventInput!
    $condition: ModelNotificationEventConditionInput
  ) {
    deleteNotificationEvent(input: $input, condition: $condition) {
      id
      type
      createdBy
      companyName
      projectCode
      createdAt
      description
      userId
      attributes {
        key
        value
        __typename
      }
      updatedAt
      __typename
    }
  }
`;
export const updateUserPreferences = /* GraphQL */ `
  mutation UpdateUserPreferences(
    $input: UpdateUserPreferencesInput!
    $condition: ModelUserPreferencesConditionInput
  ) {
    updateUserPreferences(input: $input, condition: $condition) {
      id
      userId
      notificationOptIn
      emailFrequencyPreferences
      userRegion
      userTimeZone
      lastEmailSentAt
      lastUpdatedPasswordAt
      deleteAccountAt
      sharePersonalData
      terms {
        ipAddress
        timestamp
        termsVersion
        privacyVersion
        __typename
      }
      isImmediate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCompanyAccount = /* GraphQL */ `
  mutation CreateCompanyAccount(
    $input: CreateCompanyAccountInput!
    $condition: ModelCompanyAccountConditionInput
  ) {
    createCompanyAccount(input: $input, condition: $condition) {
      id
      name
      studyIdentifiers
      whitelistedDomains
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCompanyAccount = /* GraphQL */ `
  mutation UpdateCompanyAccount(
    $input: UpdateCompanyAccountInput!
    $condition: ModelCompanyAccountConditionInput
  ) {
    updateCompanyAccount(input: $input, condition: $condition) {
      id
      name
      studyIdentifiers
      whitelistedDomains
      createdAt
      updatedAt
      __typename
    }
  }
`;
