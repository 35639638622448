import React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { CUSTOM_BOX_STYLE } from '../../../constants';

const ForceUserInviteModal = function PasswordExpire({
  showModal, closeModal, inviteUser = () => {}, message = '', onComplete = () => {},
}) {

  const onHide = () => {
    closeModal()
    onComplete()
  }

  const onInviteUser = async () => {
    onHide();
    const response = await inviteUser();
    onComplete(response.data.createUserForCompany)
  }

  return (
    <Modal open={showModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box sx={CUSTOM_BOX_STYLE}>
        <span className="containerTitle">Force Invite User</span>
        <p style={{ color: '#E2AAA0' }}>
          {message}
        </p>
        <Button
          className="LoginFormSignInButtonChangePassword"
          type="button"
          onClick={onInviteUser}
          variant="primary"
        >
          Invite
        </Button>

        <Button
          className="LoginFormOutlineButton"
          type="button"
          variant="light"
          onClick={onHide}
        >
          Close
        </Button>
      </Box>
    </Modal>
  );
};

export default ForceUserInviteModal;
