import React, { useState, useEffect } from 'react';
import { useS3 } from '../../../providers/s3';

const ProgressBar = ({
  progress,
  projectCode,
  fileKey,
  setQueueLength,
}) => {

  const { setUploadProgress } = useS3();

  const [uploadPercent, setUploadPercent] = useState({});

  const [timeRemaining, setTimeRemaining] = useState({
    sec: 0,
    min: 0,
    hour: 0,
  });
  const [start, setStart] = useState();
  const { cancelFileUpload } = useS3();

  useEffect(() => {
    if (!start) {
      setStart(new Date());
    } else {

      const timeElapsedSec = (new Date() - start) / 1000;
      const speedInSec = progress.loaded / timeElapsedSec;
      const sec = Math.round((progress.total - progress.loaded) / speedInSec)
      const min = Math.floor(sec / 60);
      const hour = Math.floor(min / 60);
      const remainingSec = sec % 60;
      setTimeRemaining({
        sec: remainingSec,
        min,
        hour,
      });
    }
    if (progress.loaded !== progress.total) {
      setUploadPercent(
        Number(((progress.loaded / progress.total) * 100).toFixed(1)),
      );
    } else {
      setUploadProgress({})
    }
  }, [progress, start, setUploadProgress]);

  const handleCancelUpload = () => {
    cancelFileUpload(projectCode, fileKey);
    setQueueLength((prev) => prev - 1)
    setUploadProgress({})
  }
  const humanFileSize = (bytes, si, dp = 1) => {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
      return `${bytes} B`;
    }

    const units = si
      ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10 ** dp;

    do {
      /* eslint-disable-next-line no-param-reassign */
      bytes /= thresh;
      /* eslint-disable-next-line no-plusplus */
      ++u;
    } while (
      Math.round(Math.abs(bytes) * r) / r >= thresh
      && u < units.length - 1
    );

    return `${bytes.toFixed(dp)} ${units[u]}`;
  };

  return (
    <>
      <div className="progress-bar-xs progress">
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <div
          className="progress-bar bg-success"
          role="progressbar"
          aria-valuenow={uploadPercent}
          aria-valuemin="0"
          aria-valuemax="100"
          style={{ width: `${uploadPercent}%` }}
        />

        <small className="progress-bar-title">{`${humanFileSize(
          progress.loaded,
          'MB',
        )} of ${humanFileSize(progress.total, 'MB')} (${uploadPercent}%)`}
        </small>
      </div>

      <small className="drop-zone-info-text float-start">{`Time Remaining: ${timeRemaining.hour} hour(s), ${timeRemaining.min} minute(s), ${timeRemaining.sec} second(s)`}</small>
      <button
        type="button"
        onClick={() => handleCancelUpload()}
        className="btn cncl-bttn btn-danger drop-zone-info-text float-right"
      >
        Cancel
      </button>
    </>
  );
}
export default ProgressBar;
