import React, { useMemo, useState } from 'react';
import moment from 'moment';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useAuth } from '../../providers/auth';
import { useGraphQL } from '../../providers/graphql';
import { useSnackBar } from '../../providers/snackbar';
import DeleteAdminModal from './DeleteAdminUserModal';
import ModifyGroupsModal from './ModifyGroups';

moment.relativeTimeThreshold('d', 7);
moment.relativeTimeThreshold('w', 4);

const UserGrid = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [modifyGroupsModal, setModifyGroupsModal] = useState(false);
  const [currentUser, setCurrentUser] = useState({});
  const { updateUserForCompany } = useGraphQL();
  const { changeState } = useSnackBar();
  const { user } = useAuth();

  const {
    companyUsers, setCompanyUsers, reloadUsers, companyInfo,
  } = props;

  const toggleUserDeleteAccountModal = () => setShowModal(!showModal)
  const togglemodifyGroupsModal = () => setModifyGroupsModal(!modifyGroupsModal)
  const userInfoColumnDefs = [
    {
      field: 'name',
      sort: 'asc',
      headerName: 'Name',
      filter: 'agTextColumnFilter',
    },
    {
      field: 'email',
      headerName: 'Email',
      minWidth: 250,
      filter: 'agTextColumnFilter',
    },
    { field: 'role', headerName: 'Role' },
    { field: 'status', headerName: 'Status' },
    {
      field: 'lastLogin',
      headerName: 'Last Login',
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'Actions',
      sortable: false,
      cellRenderer: 'actionsCellRenderer',
    },
  ];

  if (user.groups.includes('SystemAdmin')) {
    userInfoColumnDefs.splice(3, 0, {
      field: 'groups',
      minWidth: 170,
      filter: 'agTextColumnFilter',
      headerName: 'Groups',
      cellRenderer: 'groupCellRenderer',
      tooltipField: 'groups',
    })
  }

  // move this function to utils in future
  const headerHeightGetter = () => {
    const columnHeaderTexts = [
      ...document.querySelectorAll('.ag-header-cell-text'),
    ];
    const clientHeights = columnHeaderTexts.map(
      (headerText) => headerText.clientHeight,
    );
    const tallestHeaderTextHeight = Math.max(...clientHeights);

    return tallestHeaderTextHeight;
  };

  // move this function to utils in future
  const headerHeightSetter = (params) => {
    const padding = 25;
    const height = headerHeightGetter() + padding;
    params.api.setHeaderHeight(height);
  };

  const GroupRenderer = ({ valueFormatted }) => <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}> {valueFormatted}</div>

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: true,
      flex: 1,
      floatingFilter: true,
      resizable: true,
    }),
    [],
  );

  const ActionsCellRenderer = ({ data }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const ITEM_HEIGHT = 48;

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const userData = { email: data.email, name: data.name };
    const assignAdmin = () => {
      updateUserForCompany(userData, ['AccountAdmin'], []).then((response) => {
        const res = response.data.updateUserForCompany;
        if (res) {
          if (res.message) {
            if (res.message === 'User Updated Successfully') {
              (async () => { await reloadUsers(true) })()
              changeState('success', `Admin group assigned to ${data.email}`, true);
            } else {
              changeState('error', 'Error while assigning admin group', true);
            }
          } else {
            changeState('error', 'Error while assigning admin group', true);
          }
        } else {
          // eslint-disable-next-line
          changeState('error', 'Error while assigning admin group', true);
        }
      });
      handleClose();
    };
    const unAssignAdmin = () => {
      updateUserForCompany(userData, [], ['AccountAdmin']).then((response) => {
        const res = response.data.updateUserForCompany;
        if (res) {
          if (res.message) {
            if (res.message === 'User Updated Successfully') {
              (async () => { await reloadUsers(true) })()
              changeState('success', `Admin group unassigned to ${data.email}`, true);
            } else {
              changeState('error', 'Error while unassigning admin group', true);
            }
          } else {
            changeState('error', 'Error while unassigning admin group', true);
          }
        } else {
          changeState('error', 'Error while unassigning admin group', true);
        }
      });
      handleClose();
    };
    const removeFromCompany = () => {
      setCurrentUser({ email: data.email });
      setShowModal(true);
      handleClose();
    };
    const modifyGroup = () => {
      setCurrentUser({ email: data.email });
      setModifyGroupsModal(true);
      handleClose();
    };
    const showModifyGroups = user.groups.includes('SystemAdmin')
    return (
      <div>
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleClick}
          size="large"
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          MenuListProps={{
            'aria-labelledby': 'long-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: '20ch',
            },
          }}
        >
          {
            data.role === 'User' ? (
              <MenuItem data-testid="assignadmin-opt" onClick={assignAdmin}>
                Assign Admin
              </MenuItem>
            ) : (
              <MenuItem data-testid="unassignadmin-opt" onClick={unAssignAdmin}>
                Remove Admin
              </MenuItem>
            )
          }

          <MenuItem
            data-testid="removeFromcompany-opt"
            onClick={removeFromCompany}
          >
            Delete
          </MenuItem>

          {showModifyGroups && (
            <MenuItem
              data-testid="modifyGroup-opt"
              onClick={modifyGroup}
            >
              Modify Groups
            </MenuItem>
          )}
        </Menu>
      </div>
    );
  };

  return (
    <div
      className="ag-theme-alpine"
      style={{ height: '500px', width: '100%', marginBottom: '32px' }}
    >
      <AgGridReact
        rowData={companyUsers}
        columnDefs={userInfoColumnDefs}
        defaultColDef={defaultColDef}
        gridOptions={{
          rowHeight: 50,
          suppressColumnVirtualisation: true,
          onFilterChanged: (params) => {
            if (params.api.getDisplayedRowCount() === 0) {
              params.api.showNoRowsOverlay();
            } else {
              params.api.hideOverlay();
            }
          },
          onGridReady: (params) => {
            params.api.sizeColumnsToFit();
          },
          onFirstDataRendered: (params) => {
            params.api.sizeColumnsToFit();
            headerHeightSetter(params);
          },
          components: {
            actionsCellRenderer: ActionsCellRenderer,
            groupCellRenderer: GroupRenderer,
          },
          defaultColDef: {
            flex: 1,
            resizable: true,
            sortable: true,
            wrapText: true,
            minWidth: 100,
            lockPinned: true,
            suppressMenu: true,
            headerClass: [
              'text-align-center',
              'justify-content-center',
              'wrap-header',
            ],
            cellClass: ['centered-cell-content'],
            tooltipValueGetter: (data) => {
              const value = data.valueFormatted || data.value;
              return [null, undefined].includes(value) ? '' : value;
            },
          },
        }}
      />
      {modifyGroupsModal ? <ModifyGroupsModal togglemodifyGroupsModal={togglemodifyGroupsModal} modifyGroupsModal={modifyGroupsModal} currentUser={currentUser} companyUsers={companyUsers} reloadUsers={reloadUsers} changeState={changeState} companyInfo={companyInfo} setCompanyUsers={setCompanyUsers} /> : null}
      {showModal ? <DeleteAdminModal reloadUsers={reloadUsers} toggleUserDeleteAccountModal={toggleUserDeleteAccountModal} showModal={showModal} currentUser={currentUser} companyUsers={companyUsers} setCompanyUsers={setCompanyUsers} /> : null}
    </div>
  );
};

export default UserGrid;
