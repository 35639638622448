/* eslint-disable react/no-danger */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
/* import PropTypes from 'prop-types';
import Button from '@mui/material/Button'; */
import { styled } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
/* import Typography from '@mui/material/Typography'; */

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    maxWidth: '60%',
  },
  '& .MuiTypography-root': {
    fontFamily: 'AvenirNextLTPro-Regular',
    color: '#FFFFFF',
  },
  '& .MuiDialogTitle-root': {
    background: '#3D5261',
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    borderRadius: '0px 8px 0px 0px',
    '&::-webkit-scrollbar': {
      width: '30px',
      marginRight: '5px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#ffffff',
      padding: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#D2D2D2',
      width: '6px',
      border: '12px solid #ffffff',
      borderRadius: '25px',
    },
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

// eslint-disable-next-line func-names
const StyledDialogTitle = function (props) {
  const { children, onClose, ...other } = props;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          size="large"
        >
          <CancelIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const TermsModalS = function TModal(props) {

  const {
    onHideModal,
    modalContent,
    showModal,
    showLogout,
    onLogout,
  } = props;

  /* const [open, setOpen] = React.useState(false); */

  /* const handleClickOpen = () => {
    setOpen(true);
  }; */

  const hideModal = () => {
    onHideModal();
  };

  // eslint-disable-next-line no-unused-vars
  const logout = () => {
    onLogout();
  };

  return (
    <StyledDialog
      open={showModal}
        /* onHide={hideModal} */
      className={showLogout ? 'logout-modal' : 'terms-modal'}
    >
      <StyledDialogTitle id="customized-dialog-title" onClose={hideModal}>
        {modalContent.title}
      </StyledDialogTitle>

      <DialogContent
        dividers
        className={showLogout ? '' : 'content-body'}
      >
        <div dangerouslySetInnerHTML={{ __html: modalContent.content }} />
      </DialogContent>

    </StyledDialog>
  );
}

export default TermsModalS;
