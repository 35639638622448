import React, { useState } from 'react'
import { makeStyles } from '@mui/styles';
import {
  Container, Card, Button, Form,
} from 'react-bootstrap'
import { useAuth } from '../providers/auth'
import { useGraphQL } from '../providers/graphql'

import { createMessageForSQS } from './utils.createMessage';
import { AUDIT_TRAIL_EVENT_TYPES } from '../constants';
import { useSQS } from '../providers/sqs';
import { useSnackBar } from '../providers/snackbar';
import IconPersonalInformation from './IconPersonalInformation.svg';

const useStyles = makeStyles({
  card: {
    border: '0px',
  },
});

const UserFullName = function UserFullName({ loginRouteCheck }) {
  const { user } = useAuth()
  const { updateUser } = useGraphQL()
  const { sendMessageToQueueForAudits } = useSQS();
  const { changeState } = useSnackBar();
  const [fullName, setFullName] = useState({ firstName: '', lastName: '' })
  const classes = useStyles();

  const handleOnChange = (event) => {
    const { name } = event.target
    setFullName({ ...fullName, [name]: event.target.value })
  }

  const handleOnSubmit = async (event) => {
    event.preventDefault()
    await updateUser(user.id, user.email, user.favorites, user.notifications, user.first_name = fullName.firstName, user.last_name = fullName.lastName)
    const sqsMessage = await createMessageForSQS({
      eventType: AUDIT_TRAIL_EVENT_TYPES.FIRST_LAST_NAME_UPDATE,
      userId: user.id,
      attributes: [{
        firstNameBefore: user.first_name,
        lastNameBefore: user.last_name,
        firstNameAfter: fullName.firstName,
        lastNameAfter: fullName.lastName,
      }],
    })
    await sendMessageToQueueForAudits(sqsMessage)
    const message = `Your full name has been saved, ${fullName.firstName} ${fullName.lastName}!`;
    changeState('success', message, true);
    loginRouteCheck(true)
  }

  return (
    <div className="userFullName-ContainerParent">
      <Container fluid>
        <>
          <Card className={classes.card}>
            <Card.Header className="userFullName-CardHeader text-center text-md-left">
              <img className="landing-icon" src={IconPersonalInformation} alt="Personal Information Page Title icon" />
              <span className="userFullName-PageTitle AvenirNextLTProRegular my-0 font-weight-normal">Personal Information</span>
            </Card.Header>
            <Card.Body className="userFullName-CardBody">
              <Form className="userFullName-FormBody" onSubmit={(event) => handleOnSubmit(event)}>
                <Form.Group>
                  <Form.Label className="userFullName-FormLabel AvenirNextLTProRegular">First Name<span style={{ color: 'red' }}>*</span></Form.Label>
                  <Form.Control
                    className="userFullName-TextBox AvenirNextLTProRegular"
                    name="firstName"
                    type="text"
                    value={fullName.firstName}
                    placeholder="Enter First Name"
                    onChange={(event) => handleOnChange(event)}
                    required
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label className="userFullName-FormLabel AvenirNextLTProRegular">Last Name<span style={{ color: 'red' }}>*</span></Form.Label>
                  <Form.Control
                    className="userFullName-TextBox AvenirNextLTProRegular"
                    name="lastName"
                    type="text"
                    value={fullName.lastName}
                    placeholder="Enter Last Name"
                    onChange={(event) => handleOnChange(event)}
                    required
                  />
                </Form.Group>
                <Button className="userFullName-SubmitButton" variant="primary" type="submit">Submit</Button>
              </Form>
            </Card.Body>
          </Card>
        </>
      </Container>
    </div>
  )
}
export default UserFullName
