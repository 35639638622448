import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Button } from 'react-bootstrap'
import Radio from '@mui/material/Radio';
import CircularProgress from '@mui/material/CircularProgress';
import MultiModalSelectComments from './MultiModalSelectComments';
import { CUSTOM_BOX_STYLE } from '../../../constants';

function DownloadCommentsModal({
  showModal,
  onConfirmDownloadComment,
  onCancelDownloadComments,
  commentsModelsDownloadFilterComments,
  selectedValues,
  setSelectedValues,
  showDropdown,
  setShowDropdown,
  commentType,
  showSpinner,
}) {
  const DownloadAllComments = () => {
    setShowDropdown('DownloadAllComments')
    setSelectedValues([])
  }

  return (
    <Modal
      style={{ color: 'white' }}
      open={showModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <>
        <Box sx={{ ...CUSTOM_BOX_STYLE }}>
          <p
            style={{
              color: 'white',
              fontSize: '16px',
            }}
            data-testid="confirm_text"
          >
            {`Select the option to download ${commentType.toUpperCase()} comments.`}
          </p>
          <FormControl>
            <RadioGroup>
              <div style={{ alignItems: 'center', color: 'white', textTransform: 'none' }}>
                <FormControlLabel
                  onClick={DownloadAllComments}
                  control={<Radio checked={showDropdown === 'DownloadAllComments'} style={{ color: '#FFC455' }} />}
                  label={`Download all ${commentType} comments`}
                />
                <FormControlLabel
                  onClick={() => setShowDropdown('DownloadAllCommentsfilter')}
                  control={<Radio checked={showDropdown === 'DownloadAllCommentsfilter'} style={{ color: '#FFC455' }} />}
                  label={`Download filtered ${commentType} comments`}
                />
              </div>
            </RadioGroup>
          </FormControl>
          <div style={{ margin: '8px 0px' }}>
            {showDropdown === 'DownloadAllCommentsfilter' && (
              <MultiModalSelectComments
                commentsModelsDownloadFilterComments={commentsModelsDownloadFilterComments}
                setSelectedValues={setSelectedValues}
              />
            )}
          </div>
          <Button
            disabled={(!selectedValues.length && showDropdown === 'DownloadAllCommentsfilter') || (showSpinner)}
            type="submit"
            variant="primary"
            className="LoginFormSignInButton"
            onClick={onConfirmDownloadComment}
          >
            Download
            {showSpinner && <CircularProgress size={20} style={{ color: '#6495ED', marginLeft: '5px' }} />}

          </Button>
          <Button
            type="button"
            variant="light"
            className="LoginFormOutlineButton"
            onClick={onCancelDownloadComments}
          >
            Cancel
          </Button>
        </Box>
      </>
    </Modal>
  )
}
export default DownloadCommentsModal;
