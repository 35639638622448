import React, { useMemo, useState } from 'react'
import { NavDropdown, Form, Button } from 'react-bootstrap';
import * as utils from './utils';
import { useSnackBar } from '../providers/snackbar';
import { DOWNLOAD_USAGE_ANALYTICS_LAMBDA, TYPE_TO_RUN } from '../constants'
import { useAuth } from '../providers/auth';

const ReportGeneration = function ReportGeneration({ user, name }) {
  const { doTask } = useAuth();
  const {
    changeState,
  } = useSnackBar();
  const [showDropdown, setShowDropdown] = useState(false);

  const title = useMemo(
    () => (
      <div
        role="button"
        tabIndex={0}
        onKeyPress={() => {}}
        onClick={() => setShowDropdown((curr) => !curr)}
        data-testid="main_title_test"
        style={{ display: 'inline-flex', fontSize: '18px', color: '#FFFFFF' }}
      >
        Usage Analytics Report
      </div>
    ),
    [],
  );

  const download = () => doTask(async () => {
    const data = {
      email: user.email,
      userName: name,
      type_to_run: TYPE_TO_RUN.DOWNLOAD_USAGE_ANALYTICS,
    }
    try {
      await utils.invokeLambdaFunction(DOWNLOAD_USAGE_ANALYTICS_LAMBDA, data)
      const message = 'Your data is being prepared. You will receive an email with the download link once your data is ready!';
      changeState('success', message, true);

    } catch (e) {
      changeState('warning', e.message || e, true);
    }
  });

  const renderDropDownContent = () => (
    <div>
      <Form style={{
        maxHeight: '350px', padding: '0px 6px', overflowY: 'auto',
      }}
      >
        <Button onClick={download} data-testid="download_button_test">Download Report</Button>
      </Form>
    </div>
  );

  return (
    <NavDropdown className="audit-dropdown" data-testid="dropdown_test" show={showDropdown} title={title}>
      {renderDropDownContent()}
    </NavDropdown>
  )
}

export default ReportGeneration
