export const defaultText = `
# Help
## Table of Contents
- [Getting Started](#getting-started)
- [Tips to Maximize Your Experience](#tips-to-maximize-your-experience)
- [FAQs](#fa-qs)
---
## Getting Started
### Study List & Timelines
Manage your studies with the study list! It provides you with relevant study information while featuring timelines, favorites, and notification subscription.



### Study Dashboard
Launch your study dashboard be selecting a project code of interest on the study list! You can access your latest study data and documents on the study dashboard.

For multiple model studies, select your model of interest on the study dashboard to switch data set visualizations.

### Notifications
Stay up-to-date on your latest study activity! To ensure you only receive the most relevant notifications, you have control over notification subscription for each study. You can subscribe to notifications by selecting the bell icon on the study list.

**Note:** You can add or remove studies from notification subscription at any time.  The choice is yours!

---
## Tips to Maximize Your Experience

### Project Timelines
#### Study List
- You can optimize study planning and minimize time between data production cycles by comparing forecasted timelines. Project timelines will react to filtering and sorting on the study list, so this can be used to create easy-to-compare timeline views. 
- Right-click on the timeline header columns to change zoom and date range options.

### Notification Settings
#### Notifications
- Receive notifications on your schedule! Choose how frequently your email notifications are sent by selecting Manage Notifications in the Notification Center.
    * Options include daily and your selection of weekdays.

### Favorites
#### Study List
- Quickly access your favorite studies by adding them to your personalized list and filtering on the favorites column. Studies can be favorited by selecting the star icon in the study list.

### Raw Data Download
#### Study Dashboard
- Download your data by selecting using the download data column under 'Model Summary' on your study dashboard.
    * Selecting the download header will download a zip folder containing files for all models.
    * Selecting the download icon for a specific model will only download one file for the selected model.

### Graph Tools & Download
#### Study Dashboard
- Hold your mouse over a data point to launch a tooltip containing in-depth information about the data point.
- In the top right corner of each graph, explore tools to help you make the most of your data! You can zoom into hard-to-see data, compare multiple tooltips, and more!
- Quickly share a graph or add it to a presentation! Download a graph of interest by selecting the camera icon in the top right corner of the graph.

### Table Tools
#### Study Dashboard
- For tables under Clinical Observations and Mortality Observations, select a value of interest in the table or legend to highlight that value and make it easier to identify.
    * Multiple values can be selected at once.
    * Highlighting can be toggled on or off by selecting the value.

---
## FAQs
- What type of services can I access?
    * Only *in vivo* studies are supported at this time.
- How do I see the full text within a cell?
    * Hold your mouse over the text to launch a tooltip containing the full text.
---
`;
export const defaultHtml = `
<h1 id="help">Help</h1>
<h2 id="table-of-contents">Table of Contents</h2>
<ul>
   <li><a href="#getting-started">Getting Started</a></li>
   <li><a href="#tips-to-maximize-your-experience">Tips to Maximize Your Experience</a></li>
   <li><a href="#fa-qs">FAQs</a></li>
</ul>
<hr>
<h2 id="getting-started">Getting Started</h2>
<h3 id="study-list-amp-timelines">Study List &amp; Timelines</h3>
<p>Manage your studies with the study list! It provides you with relevant study information while featuring timelines, favorites, and notification subscription.</p>
<h3 id="study-dashboard">Study Dashboard</h3>
<p>Launch your study dashboard be selecting a project code of interest on the study list! You can access your latest study data and documents on the study dashboard.</p>
<p>For multiple model studies, select your model of interest on the study dashboard to switch data set visualizations.</p>
<h3 id="notifications">Notifications</h3>
<p>Stay up-to-date on your latest study activity! To ensure you only receive the most relevant notifications, you have control over notification subscription for each study. You can subscribe to notifications by selecting the bell icon on the study list.</p>
<p><strong>Note:</strong> You can add or remove studies from notification subscription at any time.  The choice is yours!</p>
<hr>
<h2 id="tips-to-maximize-your-experience">Tips to Maximize Your Experience</h2>
<h3 id="project-timelines">Project Timelines</h3>
<h4 id="study-list">Study List</h4>
<ul>
   <li>You can optimize study planning and minimize time between data production cycles by comparing forecasted timelines. Project timelines will react to filtering and sorting on the study list, so this can be used to create easy-to-compare timeline views.</li>
   <li>Right-click on the timeline header columns to change zoom and date range options.</li>
</ul>
<h3 id="notification-settings">Notification Settings</h3>
<h4 id="notifications-1">Notifications</h4>
<ul>
   <li>
      Receive notifications on your schedule! Choose how frequently your email notifications are sent by selecting Manage Notifications in the Notification Center.
      <ul>
         <li>Options include daily and your selection of weekdays.</li>
      </ul>
   </li>
</ul>
<h3 id="favorites">Favorites</h3>
<h4 id="study-list-1">Study List</h4>
<ul>
   <li>Quickly access your favorite studies by adding them to your personalized list and filtering on the favorites column. Studies can be favorited by selecting the star icon in the study list.</li>
</ul>
<h3 id="raw-data-download">Raw Data Download</h3>
<h4 id="study-dashboard-1">Study Dashboard</h4>
<ul>
   <li>
      Download your data by selecting using the download data column under 'Model Summary' on your study dashboard.
      <ul>
         <li>Selecting the download header will download a zip folder containing files for all models.</li>
         <li>Selecting the download icon for a specific model will only download one file for the selected model.</li>
      </ul>
   </li>
</ul>
<h3 id="graph-tools-amp-download">Graph Tools &amp; Download</h3>
<h4 id="study-dashboard-2">Study Dashboard</h4>
<ul>
   <li>Hold your mouse over a data point to launch a tooltip containing in-depth information about the data point.</li>
   <li>In the top right corner of each graph, explore tools to help you make the most of your data! You can zoom into hard-to-see data, compare multiple tooltips, and more!</li>
   <li>Quickly share a graph or add it to a presentation! Download a graph of interest by selecting the camera icon in the top right corner of the graph.</li>
</ul>
<h3 id="table-tools">Table Tools</h3>
<h4 id="study-dashboard-3">Study Dashboard</h4>
<ul>
   <li>
      For tables under Clinical Observations and Mortality Observations, select a value of interest in the table or legend to highlight that value and make it easier to identify.
      <ul>
         <li>Multiple values can be selected at once.</li>
         <li>Highlighting can be toggled on or off by selecting the value.</li>
      </ul>
   </li>
</ul>
<hr>
<h2 id="fa-qs">FAQs</h2>
<ul>
   <li>
      What type of services can I access?
      <ul>
         <li>Only <em>in vivo</em> studies are supported at this time.</li>
      </ul>
   </li>
   <li>
      How do I see the full text within a cell?
      <ul>
         <li>Hold your mouse over the text to launch a tooltip containing the full text.</li>
      </ul>
   </li>
</ul>
<hr>`;
