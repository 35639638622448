import React from 'react';
import PropTypes from 'prop-types';
import { AnalyticsProvider } from './analytics-context';

const segmentKeys = {
  'crownlink.dev.crownbio.com': 'DWdPp9vdk2fAf4w9NEZB04JdhUTAYANz',
  'crownlink.dev.crownbio.cn': '3z7WhccqyumjvD1zHBA3Q68rMVBygDhn',
  'crownlink.test.crownbio.com': 'sCoUJIkE2NslcH9L3iCMEYkYh2f4pt1G',
  'crownlink.test.crownbio.cn': 'mI05VVVyEjl2AZnsnAjowpTxESGIWDU2',
  'crownlink.crownbio.com': 'd0VGriHWTbUujKOk0qipg18DfG3o6kDN',
  'crownlink.crownbio.cn': 'FYgzymovSlp4EGC2mIteBfUyp3qrVRmx',
}
const SEGMENT_KEY = segmentKeys[window.location.host];

export function Segment({ children }) {
  // Disable analytics if SEGMENT_KEY is not valid
  if (SEGMENT_KEY) {
    return (
      <AnalyticsProvider writeKey={SEGMENT_KEY}>
        {children}
      </AnalyticsProvider>
    );
  }
  return children;
}

Segment.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Segment;
