import React, {
  createContext,
  useState,
  useCallback,
  useRef,
} from 'react';
import _ from 'lodash';
import { Amplify, API, graphqlOperation } from 'aws-amplify';
import moment from 'moment';
import * as queries from '../graphql/queries';
import * as customQueries from '../graphql/customQueries';
import * as mutations from '../graphql/mutations';
import awsExports from '../aws-exports';
import { projectCodesForOptOut, createNotificationTimelinesForProjects, parseNotificationsDataForProjects } from '../resources/utils.notification';
import { NOTIFICATION_EVENT_TYPE } from '../constants';

Amplify.configure(awsExports);

export const GraphQLContext = createContext();

export const GraphQLProvider = function GraphQLProvider({ children }) {
  Amplify.configure(awsExports);
  const [loading, setLoading] = useState(true);
  const [studies, setStudies] = useState([]);
  const [studiesData, setStudiesData] = useState({});
  const [category, setCategory] = useState('active');
  const [user, setUser] = useState();
  const [favorites, setFavorites] = useState([]);
  const [userPreferences, setUserPreferences] = useState({})
  const [updatingOptIns, setUpdatingOptIns] = useState(false)

  const [modelMetaData, setModelMetaData] = useState({})

  // Notification
  const [loadedNotificationEvents, setLoadedNotificationEvents] = useState([])
  const [loadedActionEvents, setLoadedActionEvents] = useState([])
  const [refreshUserName, setRefreshUserName] = useState(0)
  const optingOutInProgress = useRef(false)

  // eslint-disable-next-line camelcase
  const updateUser = (id, email, favorites, notifications, first_name, last_name, phone, lastLoginAt) => API.graphql(
    graphqlOperation(mutations.updateUser, {
      input: {
        // eslint-disable-next-line camelcase
        id, email, favorites, notifications, first_name, last_name, phone, lastLoginAt,
      },
    }),
  )

  const updateUserName = (id, firstName, lastName) => API.graphql(
    graphqlOperation(mutations.updateUser, {
      input: {
        // eslint-disable-next-line camelcase
        id, first_name: firstName, last_name: lastName,
      },
    }),
  )

  const updateUserStudyVisited = (id, studyVisited) => API.graphql(
    graphqlOperation(mutations.updateUser, {
      input: {
        // eslint-disable-next-line camelcase
        id, studyVisited,
      },
    }),
  )

  const removeFavorite = (user, projectCode) => {
    if (user && user.id) {
      const filtered = favorites.filter((fav) => fav !== projectCode)
      updateUser(user.id, user.email, filtered).then((res) => { setFavorites(res.data.updateUser.favorites) }).catch(() => {})
    }
  }

  const createNotificationEvent = ({
    id, createdBy, companyName, type, projectCode, createdAt, description, attributes, userId,
  }) => API.graphql(graphqlOperation(mutations.createNotificationEvent, {
    input: {
      id, createdBy, companyName, type, projectCode, createdAt, description, attributes, userId,
    },
  }))

  const createUserNotificationEventAction = ({
    id, eventId, userId, action, createdAt,
  }) => API.graphql(graphqlOperation(mutations.createUserNotificationEventAction, {
    input: {
      id, eventId, userId, action, createdAt,
    },
  }))

  const getCompanyAccountForSystemAdmin = (nextToken = null) => API.graphql(graphqlOperation(queries.listCompanyAccounts, {
    nextToken,
  }))

  const getNotificationsForDocumentUploadFailed = (filter, limit = 200, nextToken = null) => API.graphql(graphqlOperation(queries.listNotificationEvents, {
    filter, limit, nextToken,
  }))

  const getNotificationsForProject = (projectCode, createdAtCondition, sortDirection = 'DESC', limit = 200, nextToken = null) => API.graphql(graphqlOperation(queries.notificationEventPerProjectCode, {
    projectCode, createdAt: createdAtCondition, sortDirection, limit, nextToken,
  }))

  const notificationEventActionPerUser = (userId, createdAtCondition, sortDirection = 'DESC', limit = 200, nextToken = null) => API.graphql(graphqlOperation(queries.notificationEventActionPerUser, {
    userId, createdAt: createdAtCondition, sortDirection, limit, nextToken,
  }))

  const createUserNotificationSubscriptionCheckpoint = ({
    id, userId, projectCode, createdAt, type,
  }) => API.graphql(graphqlOperation(mutations.createUserNotificationSubscriptionCheckpoint, {
    input: {
      id, userId, projectCode, createdAt, type,
    },
  }))

  const deleteUserNotificationSubscriptionCheckpoint = (id) => API.graphql(
    graphqlOperation(mutations.deleteUserNotificationSubscriptionCheckpoint, {
      input: { id },
    }),
  );

  const getSubUnsubCheckpoints = (userId, createdAtCondition, sortDirection = 'DESC', limit = 200, nextToken = null) => API.graphql(graphqlOperation(queries.getSubUnsubCheckpoints, {
    userId, createdAt: createdAtCondition, sortDirection, limit, nextToken,
  }))

  const createActionEventAndAppendToArray = async ({
    id, eventId, userId, action, createdAt,
  }) => {
    const createdActionEvent = await createUserNotificationEventAction({
      id, eventId, userId, action, createdAt,
    })
    setLoadedActionEvents([createdActionEvent.data.createUserNotificationEventAction, ...loadedActionEvents])
  }

  const createUserPreferences = ({
    id, userId, notificationOptIn, frequency, region, lastUpdatedPasswordAt, deleteAccountAt, isImmediate, userTimeZone,
  }) => (resolve) => {
    API.graphql(graphqlOperation(mutations.createUserPreferences, {
      input: {
        id, userId, notificationOptIn, emailFrequencyPreferences: frequency, userRegion: region, lastUpdatedPasswordAt, deleteAccountAt, isImmediate, userTimeZone,
      },
    })).then((result) => {
      resolve(result);
    }).catch(() => {
      resolve(null);
    });
  };

  const getUserPreferences = (userId, limit = 200, nextToken = null) => API.graphql(graphqlOperation(queries.preferencesForUser, { userId, limit, nextToken }))

  const updateUserPreferences = ({
    id, userId, notificationOptIn, frequency, region, lastUpdatedPasswordAt, sharePersonalData, deleteAccountAt, isImmediate, userTimeZone,
  }) => API.graphql(graphqlOperation(mutations.updateUserPreferences, {
    input: {
      id, userId, notificationOptIn, lastUpdatedPasswordAt, emailFrequencyPreferences: frequency, userRegion: region, sharePersonalData, deleteAccountAt, isImmediate, userTimeZone,
    },
  }))

  const updateorCreateTermsandConditions = (id, terms, sharePersonalData, userId) => {
    if (id) {
      return API.graphql(graphqlOperation(mutations.updateUserPreferences, { input: { id, terms, sharePersonalData } }))
    }
    const newId = Date.now().toString(36) + Math.random().toString(36).substr(2);
    return API.graphql(graphqlOperation(mutations.createUserPreferences, {
      input: {
        id: newId, userId, terms, sharePersonalData, notificationOptIn: [], emailFrequencyPreferences: ['DAILY'], userRegion: new Date().getTimezoneOffset(), userTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone || 'UTC',
      },
    }))
  }

  const getCompanyInfoById = (id) => API.graphql(graphqlOperation(queries.getCompanyAccount, { id }))

  const createCompanyAccount = ({ name, studyIdentifiers = [], whitelistedDomains = [] }) => API.graphql(graphqlOperation(queries.createCompanyAccount, {
    CompanyAccountData: {
      name,
      studyIdentifiers,
      whitelistedDomains,
      createdAt: moment().unix(),
    },
  }))

  /**
   * Update Company Account object.
   *
   * @param {Object} input Input dictionary.
   */
  const updateCompanyAccount = (input) => API.graphql(graphqlOperation(mutations.updateCompanyAccount, {
    input: { ...input },
  }))

  const loadOrCreateUserPreferences = async (userId) => {
    const res = await getUserPreferences(userId);
    if (res.data.preferencesForUser.items && res.data.preferencesForUser.items.length) {
      let preferences = res.data.preferencesForUser.items[0];
      if (!preferences.lastUpdatedPasswordAt || !preferences.deleteAccountAt || !preferences.isImmediate) {
        const response = await updateUserPreferences({
          id: preferences.id,
          userId: preferences.userId,
          notificationOptIn: preferences.notificationOptIn ? preferences.notificationOptIn : [],
          emailFrequencyPreferences: preferences.frequency ? preferences.frequency : ['IMMEDIATE'],
          region: preferences.userRegion ? preferences.userRegion : new Date().getTimezoneOffset(),
          userTimeZone: preferences.userTimeZone ? preferences.userTimeZone : (Intl.DateTimeFormat().resolvedOptions().timeZone || 'UTC'),
          lastUpdatedPasswordAt: preferences.lastUpdatedPasswordAt ? preferences.lastUpdatedPasswordAt : moment().unix(),
          deleteAccountAt: preferences.deleteAccountAt ? preferences.deleteAccountAt : 0,
          isImmediate: preferences.isImmediate ? preferences.isImmediate : 'IMMEDIATE',
        });
        preferences = response.data.updateUserPreferences;
      }
      setUserPreferences(preferences);
      return preferences;
    }
    const id = Date.now().toString(36) + Math.random().toString(36).substr(2);
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone || 'UTC';
    const preferenceData = await new Promise((resolve) => {
      createUserPreferences({
        id,
        userId,
        notificationOptIn: [],
        frequency: ['IMMEDIATE'],
        region: new Date().getTimezoneOffset(),
        userTimeZone,
        lastUpdatedPasswordAt: moment().unix(),
        isImmediate: 'IMMEDIATE',
        deleteAccountAt: 0,
      })(resolve);
    });

    setUserPreferences(preferenceData.data.createUserPreferences);
    return preferenceData.data.createUserPreferences;
  };

  const subscribeForNotification = async (user, projectCode) => {
    if (user && user.id) {
      const {
        id,
        notificationOptIn,
        emailFrequencyPreferences,
        emailNotificationLimit,
        emailNotificationTime,
        lastUpdatedPasswordAt,
        deleteAccountAt,
      } = userPreferences
      const newNotificationOptIns = [...notificationOptIn, projectCode]
      const updatedPreferences = await updateUserPreferences({
        id,
        userId: user.id,
        notificationOptIn: newNotificationOptIns,
        frequency: emailFrequencyPreferences,
        notificationLimit: emailNotificationLimit,
        notificationTime: emailNotificationTime,
        region: new Date().getTimezoneOffset(),
        lastUpdatedPasswordAt,
        deleteAccountAt,
      })
      const checkpointId = Date.now().toString(36) + Math.random().toString(36).substr(2);
      await createUserNotificationSubscriptionCheckpoint({
        id: checkpointId, userId: user.id, projectCode, createdAt: moment().unix(), type: 'SUB',
      })
      setUserPreferences(updatedPreferences.data.updateUserPreferences)
    }
  }

  const unsubscribeFromNotification = useCallback(async (user, projectCode, skipCheckpoint = false) => {
    if (user && user.id) {
      const {
        id,
        notificationOptIn,
        emailFrequencyPreferences,
        emailNotificationLimit,
        emailNotificationTime,
        lastUpdatedPasswordAt,
        deleteAccountAt,
      } = userPreferences
      if (!notificationOptIn || !notificationOptIn.length) {
        return
      }
      const newNotificationOptIns = notificationOptIn.filter((code) => code !== projectCode)
      const updatedPreferences = await updateUserPreferences({
        id,
        userId: user.id,
        notificationOptIn: newNotificationOptIns,
        frequency: emailFrequencyPreferences,
        notificationLimit: emailNotificationLimit,
        notificationTime: emailNotificationTime,
        region: new Date().getTimezoneOffset(),
        lastUpdatedPasswordAt,
        deleteAccountAt,
      })
      const checkpointId = Date.now().toString(36) + Math.random().toString(36).substr(2);
      if (!skipCheckpoint) {
        await createUserNotificationSubscriptionCheckpoint({
          id: checkpointId,
          userId: user.id,
          projectCode,
          createdAt: moment().unix(),
          type: 'UNSUB',
        })
      }
      setUserPreferences(updatedPreferences.data.updateUserPreferences)
    }
  }, [userPreferences])

  const optOutForMultipleProjects = useCallback(async (user, projectCodes, checkpoints = []) => {
    const optOutPromises = projectCodes.map((code) => unsubscribeFromNotification(user, code, true))
    const checkpointPromises = checkpoints.map(({ id }) => deleteUserNotificationSubscriptionCheckpoint(id))
    await Promise.all([...optOutPromises, ...checkpointPromises])
  }, [unsubscribeFromNotification])

  const getAllNotificationsForUser = useCallback(async (user, notificationOptIns) => {
    if (user && user.id) {
      const allProjectCodes = notificationOptIns || userPreferences.notificationOptIn;
      const { projectCodes, optOutCodes } = projectCodesForOptOut(user, allProjectCodes)
      const dateLimit = moment().subtract(31, 'days').unix();
      const createdAtCondition = { ge: dateLimit }
      const checkpoints = await getSubUnsubCheckpoints(user.id, createdAtCondition)
      const { queryObjs, clearCheckpoints } = createNotificationTimelinesForProjects(projectCodes, checkpoints.data.getSubUnsubCheckpoints.items, dateLimit, optOutCodes, user)
      const notificationPromises = queryObjs.map(({ projectCode, createdAtCondition }) => getNotificationsForProject(projectCode, createdAtCondition, 'DESC'))
      const notificationPromisesResolved = await Promise.all(notificationPromises)
      const notificationEventActions = await notificationEventActionPerUser(user.id, createdAtCondition, 'DESC')
      // get document upload faile notification only from logged in user's id
      const notificationPromiseForDocumentUploadFailed = await getNotificationsForDocumentUploadFailed(
        {
          type: { eq: NOTIFICATION_EVENT_TYPE.REDIRECT_TO_DASHBOARD.DOCUMENT_UPLOAD_FAILED },
          userId: { eq: user.id },
          createdAt: createdAtCondition,
        },
      )
      if (notificationPromiseForDocumentUploadFailed?.data?.listNotificationEvents?.items?.length) {
        notificationPromisesResolved.push(notificationPromiseForDocumentUploadFailed)
      }
      const compiledNotificationsArraySorted = parseNotificationsDataForProjects(notificationPromisesResolved, user)
      setLoadedNotificationEvents([...compiledNotificationsArraySorted])
      setLoadedActionEvents([...notificationEventActions.data.notificationEventActionPerUser.items])
      if (!optingOutInProgress.current) {
        optingOutInProgress.current = true;
        await optOutForMultipleProjects(user, optOutCodes, clearCheckpoints)
        optingOutInProgress.current = false
      }
      return compiledNotificationsArraySorted
    }
    return []
  }, [userPreferences, optOutForMultipleProjects])

  const clearAllNotificationStates = () => {
    setLoadedNotificationEvents([])
    setLoadedActionEvents([])
  }

  const addFavorite = (user, projectCode) => {
    if (user && user.id) {
      const newFavorites = [...favorites, projectCode];
      updateUser(user.id, user.email, newFavorites, user.notifications).then((res) => { setFavorites(res.data.updateUser.favorites) }).catch(() => {})
    }
  }

  // eslint-disable-next-line camelcase
  const getStudy = (project_code) => _.find(studies, ['project_code', project_code]);

  const responseFormatter = (value) => (value ? value.filter((item) => item !== null && item !== ' ').join().split(',').join(', ') : '');

  const getProjectRecord = async (projectCode) => {
    let record = null;
    try {
      const response = await API.graphql(graphqlOperation(queries.getProjectRecord, { projectCode }))
      const data = response.data.getProjectRecord;
      record = response.data.getProjectRecord ? {
        company: responseFormatter(data.companies),
        project_code: data.code,
        status: responseFormatter(data.statuses),
        site: responseFormatter(data.sites),
        study_type: responseFormatter(data.study_types),
        model_id: responseFormatter(data.model_ids),
        sponsor: responseFormatter(data.sponsors),
        director: responseFormatter(data.study_directors),
        project_manager: responseFormatter(data.project_managers),
        business_director: responseFormatter(data.business_directors),
        study_title: responseFormatter(data.study_title),
        models: data.model_ids,
        third_dimension: data.third_dimension,

      } : null;
    // eslint-disable-next-line no-empty
    } catch (err) {
    }
    return record
  }

  const getStudyDetailsProjectsData = async (projectCode, userId) => {
    let record = null;
    try {
      const response = await API.graphql(graphqlOperation(customQueries.getStudyDetailsProjectsData, { projectCode, userId }))

      if (response?.data?.getUser) {
        setUser(response.data.getUser);
      }

      const data = response.data.getProjectRecord;

      record = response.data.getProjectRecord ? {
        company: responseFormatter(data.companies),
        project_code: data.code,
        status: responseFormatter(data.statuses),
        site: responseFormatter(data.sites),
        study_type: responseFormatter(data.study_types),
        model_id: responseFormatter(data.model_ids),
        sponsor: responseFormatter(data.sponsors),
        director: responseFormatter(data.study_directors),
        project_manager: responseFormatter(data.project_managers),
        business_director: responseFormatter(data.business_directors),
        study_title: responseFormatter(data.study_title),
        models: data.model_ids,
        third_dimension: data.third_dimension,
      } : null;

      response.data.getProjectRecord = record;
      record = response;

    // eslint-disable-next-line no-empty
    } catch (err) {
    }
    return record;
  }

  const createNewUser = (id, email) => API.graphql(
    graphqlOperation(mutations.createUser, {
      input: { id, email },
    }),
  )
  const getLastModifiedDate = (studyId) => API.graphql(graphqlOperation(
    queries.getLastModifiedDate, { studyId },
  ))

  const rollBackReleasedData = (studyId) => API.graphql(graphqlOperation(
    queries.rollBackReleasedData, { studyId },
  ))

  const getUser = (id, email) => API.graphql(
    graphqlOperation(queries.getUser, {
      id, email,
    }),
  );
  /* eslint-disable-next-line */
  const getOrCreateUser = useCallback((async (user) => await getUser(user.id, user.email).then((res) => {

    if (!_.isEmpty(res.data.getUser)) {
      return res.data.getUser
    }
    return createNewUser(user.id, user.email).then((r) => r.data.createUser).catch(() => {})

  })), [])

  const getTaskMeasurementSummaryAPI = async (studyId, taskType, preview, dataFilters = {}) => API.graphql(
    graphqlOperation(
      queries.getTaskMeasurementSummary,
      {
        studyId, taskType, dataFilters, taskStatus: preview ? 'IN_REVIEW' : 'APPROVED',
      },
    ),
  );

  const getStudyChartsDataAPI = async (studyId, preview, dataFilters = {}, fetchingListRecord = {}) => API.graphql(
    graphqlOperation(
      customQueries.getStudyChartsData,
      {
        studyId,
        dataFilters,
        taskStatus: preview ? 'IN_REVIEW' : 'APPROVED',

        ...fetchingListRecord,
      },
    ),
  );

  const getTaskMeasurementIndividualAPI = async (studyId, taskType, preview, dataFilters = {}) => API.graphql(
    graphqlOperation(
      queries.getTaskMeasurementIndividual,
      {
        studyId, taskType, dataFilters, taskStatus: preview ? 'IN_REVIEW' : 'APPROVED',
      },
    ),
  );

  const getStudyDetailsDataAPI = async (studyId, preview, dataFilters = {}, canPreview, isQCMetadataFetched) => API.graphql(
    graphqlOperation(
      customQueries.getStudyDetailsData,
      {
        studyId, dataFilters, taskStatus: preview ? 'IN_REVIEW' : 'APPROVED', canPreview, isQCMetadataFetched,
      },
    ),
  );

  const getStudyDataApprovalState = (studyId) => API.graphql(graphqlOperation(
    queries.getStudyDataApprovalState, { studyId },
  ))

  const createModelDataFileRecord = async (ModelDataFileInput) => API.graphql(graphqlOperation(
    queries.createModelDataFileRecord, { ModelDataFileInput },
  ))

  const removeModelDataFileRecord = async (Id) => API.graphql(graphqlOperation(
    queries.removeModelDataFileRecord, { Id },
  ))

  const getCurrentModelDataFileRecordsByType = async (ModelDataFilesByTypeInput) => API.graphql(graphqlOperation(
    queries.getCurrentModelDataFileRecordsByType, { ModelDataFilesByTypeInput },
  ))

  const getPreviewDataAvailability = (studyId) => API.graphql(graphqlOperation(
    queries.getPreviewDataAvailability, { studyId },
  ))

  const qualityControl = (studyId, filters, director, state, projectCode = '', modelId = '', companyName = '') => API.graphql(graphqlOperation(
    queries.setStudyQualityControl,
    {
      QCDataParams: {
        studyDirector: director,
        filters,
        studyId,
        state,
        projectCode,
        modelId,
        companyName,
      },
    },
  ))

  const getApprovalMetaData = (studyId) => API.graphql(graphqlOperation(
    queries.getQCMetadata, { studyId },
  ))

  const getModels = (modelCode) => API.graphql(
    graphqlOperation(
      queries.getProjectStudies,
      { projectRecordCode: modelCode },
    ),
  );

  const getAllComments = (projectID, commentType, getDeleted = false) => API.graphql(
    (() => {
      const options = {
        projectID,
        commentType: { eq: commentType },
      }
      if (getDeleted === false) {
        options.filter = {
          and: [
            {
              or: [
                {
                  deletedAt: {
                    attributeExists: false,
                  },
                },
                {
                  deletedAt: {
                    eq: null,
                  },
                },
              ],
            },
            {
              or: [
                {
                  parentID: {
                    attributeExists: false,
                  },
                },
                {
                  parentID: {
                    eq: null,
                  },
                },
              ],
            },
          ],
        }

      }
      return graphqlOperation(queries.commentsByProjectAndType, {
        ...options,
      })
    })(),

  );

  const getUserByEmail = (email) => API.graphql(graphqlOperation(queries.getUserByEmail, { email }))

  const getUserByCompanyId = (companyAccountID) => API.graphql(graphqlOperation(queries.getUserByCompany, { companyAccountID }))

  const companyWhitelistedGroups = (companyAccountID) => API.graphql(graphqlOperation(queries.companyWhitelistedGroups, { companyAccountID }))

  const createUserForCompany = (users) => API.graphql(graphqlOperation(queries.createUserForCompany, {
    users,
  }));

  const updateUserForCompany = (user, addGroups, removeGroups, event) => API.graphql(graphqlOperation(queries.updateUserForCompany, {
    user, add_groups: addGroups, remove_groups: removeGroups, event,
  }));

  const createNewComment = (id, modelID, projectID, commentedBy, note, commentType, parentID = null) => API.graphql(
    graphqlOperation(mutations.createComment, {
      input: {
        id, modelID, projectID, commentedBy, note, commentType, deletedAt: null, parentID,
      },
    }),
  );

  /**
   * Fetch a single comment.
   *
   * @param {*} id ID of comment.
   * @param {boolean} [getDeleted=false] Whether to fetch from soft-deleted/history comments.
   */
  const getComment = (id, getDeleted = false) => API.graphql(
    graphqlOperation(queries.getComment, { id }),
  ).then((value) => new Promise((resolve, reject) => {
    const isDeleted = value.data?.getComment?.deletedAt || null
    if (getDeleted === true) {
      resolve(value)
    } else if (isDeleted === null) {
      resolve(value)
    }
    reject()
  }))

  const deleteComment = (id) => API.graphql(
    graphqlOperation(mutations.deleteComment, {
      input: { id },
    }),
  );

  const updateComment = (id, modelID, projectID, commentedBy, note, commentType, deletedAt = null) => API.graphql(
    (() => {
      const options = {
        id, modelID, projectID, commentedBy, note, commentType,
      }
      if (deletedAt) {
        options.deletedAt = deletedAt
      }
      return graphqlOperation(mutations.updateComment, {
        input: {
          ...options,
        },
      })
    })(),
  );

  const createHelpText = (id, helpText, helpHtml, addedBy) => API.graphql(
    graphqlOperation(mutations.createHelp, {
      input: {
        id, helpText, helpHtml, addedBy,
      },
    }),
  );

  const updateHelpText = (id, helpText, helpHtml, addedBy) => API.graphql(
    graphqlOperation(mutations.updateHelp, {
      input: {
        id, helpText, helpHtml, addedBy,
      },
    }),
  );

  const getHelpText = () => API.graphql(
    graphqlOperation(queries.listHelps, {}),
  );

  const createUserColorScheme = (input) => API.graphql(
    graphqlOperation(mutations.createColorScheme, {
      input,
    }),
  );

  const updateUserColorScheme = (input) => API.graphql(
    graphqlOperation(mutations.updateColorScheme, {
      input,
    }),
  );

  const listUserColorSchemes = (userId, studyId) => API.graphql(
    graphqlOperation(queries.listColorSchemes, {
      filter: {
        userId: {
          eq: userId,
        },
        studyId: {
          eq: studyId,
        },
      },
    }),
  );

  const createGraphAnnotation = (input) => API.graphql(
    graphqlOperation(mutations.createGraphAnnotations, {
      input,
    }),
  );

  const updateGraphAnnotation = (input) => API.graphql(
    graphqlOperation(mutations.updateGraphAnnotations, {
      input,
    }),
  );

  const listGraphAnnotations = (studyId, modelId, graphName, taskType) => {
    // Initialize an empty filter object
    const filter = {};

    // Conditionally add parameters to the filter object
    if (studyId) {
      filter.studyId = { eq: studyId };
    }

    if (modelId) {
      filter.modelId = { eq: modelId };
    }

    if (graphName) {
      filter.graphName = { eq: graphName };
    }

    if (taskType) {
      filter.taskType = { eq: taskType };
    }

    // Build the GraphQL query with the dynamic filter object
    return API.graphql(graphqlOperation(queries.listGraphAnnotationss, { filter }));
  };

  const createOrUpdateGraphAnnotation = async (input) => {
    const {
      studyId, modelId, graphName, taskType,
    } = input;

    // Check if an entry with the given combination already exists
    const existingEntries = await listGraphAnnotations(studyId, modelId, graphName, taskType);

    if (existingEntries?.data?.listGraphAnnotationss?.items?.length > 0) {
      // If entry exists, update the value
      const existingEntry = existingEntries?.data?.listGraphAnnotationss?.items[0];
      const updateInput = {
        id: existingEntry.id,
        value: input.value,
        isActive: input.isActive,
      };
      // return input;
      return updateGraphAnnotation(updateInput);
    }
    // If entry doesn't exist, create a new one
    return createGraphAnnotation(input);
    // return input;

  };

  const getGraphAnnotation = (id) => API.graphql(
    graphqlOperation(queries.getGraphAnnotations, {
      id,
    }),
  );

  const getS3Credentials = (projectPrefix, isChina) => API.graphql(graphqlOperation(queries.getS3Credentials, {
    project_prefix: projectPrefix,
    is_china: isChina,
  }));

  const getCompanyUsers = (companyAccountID) => API.graphql(graphqlOperation(queries.getCompanyUsers, { companyAccountID }))

  const getCompanyAccounts = (id) => API.graphql(graphqlOperation(queries.getCompanyAccount, { id }))

  const getCompanyNames = (pageNumber = 0, pageSize = 100, searchString = '') => API.graphql(graphqlOperation(queries.getCompanyNames, { pageNumber, pageSize, searchString }))

  const getStudyIdentifierForCompanyName = (companyName) => API.graphql(graphqlOperation(queries.getStudyIdentifierForCompanyName, { companyName }))

  const updateStudiesV2 = async (results = [], pageNumber = 1) => {
    // recursion failsafe in case of a bug on backend
    if (pageNumber > 10) {
      setStudies(results)
      return;
    }

    try {
      setLoading(true)
      const response = await API.graphql(graphqlOperation(queries.getProjectRecords, { pageNumber, status: category }))

      const filterData = {
        statuses: response.data.getProjectRecords.Statuses,
        sites: response.data.getProjectRecords.Sites,
        studyTypes: response.data.getProjectRecords.StudyTypes,
      }
      const finalResponse = response.data.getProjectRecords.ProjectRecords.map((res) => {
        const milestoneDateParser = (milestones) => (milestones ? milestones.map((ms) => {
          let actual = null; let
            forecast = null;
          if (ms.actual) {
            try {
              const [year, month, day] = ms.actual.split('-');
              actual = new Date(year, month - 1, day);
            // eslint-disable-next-line no-empty
            } catch (err) {}
          }
          if (ms.forecast) {
            try {
              const [year, month, day] = ms.forecast.split('-');
              forecast = new Date(year, month - 1, day);
            // eslint-disable-next-line no-empty
            } catch (err) {}
          }
          return { ...ms, actual, forecast }
        }) : []);
        return {
          company: responseFormatter(res.companies),
          project_code: res.code,
          status: responseFormatter(res.statuses),
          site: responseFormatter(res.sites),
          study_type: responseFormatter(res.study_types),
          model_id: responseFormatter(res.model_ids),
          sponsor: responseFormatter(res.sponsors),
          director: responseFormatter(res.study_directors),
          project_manager: responseFormatter(res.project_managers),
          business_director: responseFormatter(res.business_directors),
          study_title: responseFormatter(res.study_title),
          models: res.model_ids,
          milestones: milestoneDateParser(res.milestones),
          third_dimension: res.third_dimension,
          latest_release_date: res.latest_release_date?.length ? new Date(parseInt(res.latest_release_date)) : null,
        };
      });
      const filteredResponse = _.filter(finalResponse, (o) => !_.isEmpty(o.project_code));
      /* eslint-disable-next-line no-param-reassign */
      results = results.concat(filteredResponse)
      if (response.data.getProjectRecords.ProjectRecords.length === 2500) {
        /* eslint-disable-next-line */
        updateStudiesV2(results, ++pageNumber);
      } else {
        setStudies(results);
        setStudiesData(filterData);
        Window.loadedStudies = new Date()
      }

    } catch (err) {
      if (results.length) {
        setStudies(results);
      }
    } finally {
      setLoading(false)
    }
  }

  const getHeaderDataApi = (id) => API.graphql(graphqlOperation(customQueries.getHeaderData, { id }));

  const createSystemMessage = (messageContent, recipients) => API.graphql(graphqlOperation(customQueries.createSystemMessage, {
    message: {
      message_content: messageContent,
      recipients,
    },
  }))

  const listCognitoGroups = () => API.graphql(graphqlOperation(queries.listCognitoGroups))

  const getSystemMessagesReception = () => API.graphql(graphqlOperation(customQueries.getUserSystemMessages))

  const listSystemMessages = () => API.graphql(graphqlOperation(customQueries.listSystemMessages))

  const updateSystemMessagesReceptionStatus = (id, status) => {
    API.graphql(graphqlOperation(mutations.updateSystemMessagesReception, {
      input: {
        id,
        status,
      },
    }))
  }

  const recallSystemMessage = (id) => API.graphql(graphqlOperation(queries.recallSystemMessage, {
    messageId: id,
  }))

  return (
    <GraphQLContext.Provider
      value={{
        category,
        setCategory,
        user,
        studies,
        updateStudiesV2,
        studiesData,
        getStudy,
        getTaskMeasurementSummaryAPI,
        getTaskMeasurementIndividualAPI,
        getUser,
        getOrCreateUser,
        updateUser,
        removeFavorite,
        addFavorite,
        setStudies,
        setFavorites,
        getModels,
        getStudyDataApprovalState,
        qualityControl,
        getApprovalMetaData,
        createNotificationEvent,
        favorites,
        getAllNotificationsForUser,
        clearAllNotificationStates,
        updateUserName,
        updateUserStudyVisited,
        loadedNotificationEvents,
        loadedActionEvents,
        refreshUserName,
        setRefreshUserName,
        createActionEventAndAppendToArray,
        loadOrCreateUserPreferences,
        getUserPreferences,
        userPreferences,
        subscribeForNotification,
        unsubscribeFromNotification,
        updateUserPreferences,
        setUserPreferences,
        getComment,
        getAllComments,
        createNewComment,
        deleteComment,
        updateComment,
        createHelpText,
        updateHelpText,
        getHelpText,
        updateorCreateTermsandConditions,
        getProjectRecord,
        updatingOptIns,
        setUpdatingOptIns,
        getPreviewDataAvailability,
        getS3Credentials,
        getLastModifiedDate,
        createCompanyAccount,
        updateCompanyAccount,
        getCompanyAccountForSystemAdmin,
        getCompanyNames,
        companyWhitelistedGroups,
        getStudyIdentifierForCompanyName,
        getCompanyUsers,
        getCompanyAccounts,
        getUserByEmail,
        getUserByCompanyId,
        createUserForCompany,
        updateUserForCompany,
        getCompanyInfoById,
        getStudyDetailsDataAPI,
        getStudyDetailsProjectsData,
        getStudyChartsDataAPI,
        getHeaderDataApi,
        createSystemMessage,
        listCognitoGroups,
        getSystemMessagesReception,
        listSystemMessages,
        updateSystemMessagesReceptionStatus,
        recallSystemMessage,
        createModelDataFileRecord,
        removeModelDataFileRecord,
        getCurrentModelDataFileRecordsByType,
        rollBackReleasedData,
        modelMetaData,
        setModelMetaData,
        setLoading,
        createUserColorScheme,
        updateUserColorScheme,
        listUserColorSchemes,
        createGraphAnnotation,
        updateGraphAnnotation,
        listGraphAnnotations,
        getGraphAnnotation,
        createOrUpdateGraphAnnotation,
        loading,
      }}
    >
      {children}
    </GraphQLContext.Provider>
  );
};

// export default GraphQLProvider
export const useGraphQL = () => {
  const context = React.useContext(GraphQLContext);

  if (context === undefined) {
    throw new Error(
      '`useGraphQL` hook must be used within a `GraphQLProvider` component',
    );
  }
  return context;
};
