import React from 'react'
import { AgGridReact } from 'ag-grid-react'
import _ from 'lodash'
import {
  Button, Row, Col, Spinner, Card,
} from 'react-bootstrap'
import Typography from '@mui/material/Typography';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/styles';
import { useMortalityObservationLegendContext } from '../../../providers/mortalityObservationLegend'

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip
    disableInteractive
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    PopperProps={{
      popperOptions: {
        modifiers: [
          {
            name: 'offset',
            options: { offset: [50, -10] },
          },
        ],
      },
    }}
    classes={{
      popper: className,
    }}
  />
  // eslint-disable-next-line no-unused-vars
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#FFFBEC',
    color: '#222222',
    border: '1px solid #FEE7D5',
    borderRadius: '2px',
    fontSize: '12px',
    top: '20px',
  },
}));
const MortalityObservationPanel = function (props) {
  const spinner = (
    <Row className="justify-content-center">
      <Button variant="primary" disabled>
        <Spinner
          as="span"
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
        />
        Loading Mortality Observation Metrics...
      </Button>
    </Row>
  )

  const { mortalityObservationFetching, showTable, mortalityObservationIndividualGridOptions } = props
  const DisplayLegend = function () {
    const { mortalityObservationIndividualGroupsLegend: legend } = props
    const alphabeticalLegend = _.sortBy(legend, 'key')

    const { selectedFateCode } = useMortalityObservationLegendContext()
    const { setSelectedFateCode } = useMortalityObservationLegendContext()

    return (
      <>
        {
          selectedFateCode.length > 0 && selectedFateCode.length < legend.length
          && (
            /* eslint-disable-next-line jsx-a11y/click-events-have-key-events */
            <div // eslint-disable-line jsx-a11y/no-static-element-interactions
              className="blackHyperlink"
              onClick={() => setSelectedFateCode([])}
              style={{ cursor: 'pointer' }}
            >
              Restore Default Highlighting
              <hr />
            </div>
          )
        }
        {
          alphabeticalLegend.map((value, index) => {
            const tempIndx = index
            return (
              <LightTooltip
                title={(
                  <Typography className="AvenirNextLTProRegular">
                    {value.value}
                  </Typography>
                )}
                placement="bottom"
                key={value + tempIndx}
                data-testid={value.key}
              >
                {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                <p /* eslint-disable-line jsx-a11y/click-events-have-key-events */
                  className={selectedFateCode.length > 0 && !selectedFateCode.includes(value.key) ? 'lightGrayHyperlink' : 'blackHyperlink'}
                  onClick={() => (!selectedFateCode.includes(value.key) ? setSelectedFateCode([...selectedFateCode, value.key]) : setSelectedFateCode(selectedFateCode.filter((el) => el !== value.key)))}
                  style={{ cursor: 'pointer' }}
                  data-toggle="tooltip"
                >
                  {value.key}: {value.value && value.value.length > 20 ? `${value.value.substr(0, 20)}...` : value.value}
                </p>
              </LightTooltip>
            )
          })
        }
      </>
    )
  }

  return (
    <div>
      <hr className="mlr-10" />
      <div style={mortalityObservationFetching ? { display: 'none' } : {}}>
        <div style={(showTable && !_.isEmpty(mortalityObservationIndividualGridOptions) && mortalityObservationIndividualGridOptions.rowData) ? {} : { display: 'none' }}>
          <p><span style={{ fontWeight: 'bold' }}>Tip:</span> Selecting observation code(s) in the table or legend will highlight the selected value(s).</p>
          <Row>
            <Col md="9">
              <h4 className="text-center text-md-left">Absolute Mortality Observation</h4>
              <div
                className="ag-theme-alpine"
                style={{
                  width: '100%',
                  height: '450px',
                  margin: 'auto',
                }}
              >
                {!_.isEmpty(mortalityObservationIndividualGridOptions) && mortalityObservationIndividualGridOptions.onGridReady
                  ? <AgGridReact gridOptions={mortalityObservationIndividualGridOptions} />
                  : spinner}
              </div>
            </Col>
            <Col>
              <h4>&nbsp;</h4>
              <div
                className="ag-theme-alpine"
                style={{
                  width: '100%',
                  height: '450px',
                  margin: 'auto',
                }}
              >
                <Card className="legend-list-card">
                  <Card.Header>
                    <h5 className="studyDetails-legendsCardHeader text-center text-md-left" id="mo_legend">Legend</h5>
                  </Card.Header>
                  <Card.Body className="legend-list"> {/* custom class added for styling enhancements */}
                    <DisplayLegend />
                  </Card.Body>
                </Card>
              </div>
            </Col>
          </Row>
        </div>
        {(!showTable || _.isEmpty(mortalityObservationIndividualGridOptions) || !mortalityObservationIndividualGridOptions.onGridReady) && <div>No Data Available</div>}
      </div>
      {mortalityObservationFetching && spinner}
    </div>
  )
}

export default MortalityObservationPanel
