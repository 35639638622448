import {
  useHistory, Switch, Route, Redirect,
  useLocation,
} from 'react-router-dom';
import React, { useEffect } from 'react';
import { AuthState } from '@aws-amplify/ui-components';
import _ from 'lodash';
import {
  Box,
} from '@mui/material';
import Login from './components/auth/Login';
import PrivacyPolicyPdf from './components/PrivacyPolicyPdf';
import TermsOfUsePdf from './components/TermsOfUsePdf';
import AcceptableUsePolicy from './components/AcceptableUsePolicy';
import ProtectedRoute from './ProtectedRoute';
import UserLanding from './UserLanding';
import UserFullName from './UserFullName';
import TermsNConditions from './components/terms/TermsNConditions';
import EditHelp from './HelpContent/EditHelp';
import StudyDetails from './components/study/StudyDetails';
import SystemAdmin from './components/admin/SystemAdminCenter';
import ManageNotifications from './ManageNotifications';
import AccountSettings from './AccountSettings';
import { useAuth } from '../providers/auth';

import { useGraphQL } from '../providers/graphql';

import TermsContent from './components/terms/constants';
import { GROUPS } from '../constants';

import AdminCenter from './components/AdminCenter'
import SystemMessages from './components/admin/SystemMessages/SystemMessages';

const USER_LANDING_PATH = '/landing';
const USER_FULL_NAME_PATH = '/full-name';
const TERMS_AND_CONDITIONS_PATH = '/term-conditions';
const MANAGE_NOTIFICATIONS = '/manage-notifications'
const ACCOUNT_SETTINGS = '/account-settings'
const STUDY_DETAILS_PATH = '/details/:project_code';
const EDIT_HELP_PATH = '/edit_help';
const SYSTEM_ADMIN = '/system-admin';
const ADMIN_CENTER = '/admin-center';
const SYSTEM_MESSAGES = '/system-messages'

const Routing = function ({ toggleChangePassword }) {
  const {
    user, authState, loginRedirect, setLoginRedirect,
  } = useAuth();
  const { getOrCreateUser, userPreferences } = useGraphQL();
  const history = useHistory();
  const location = useLocation();

  // param skips calls we know are already satisfied because it's called on successful completion of that portion
  const loginRouteCheck = async (onlyTermsCheck) => {
    let path = ''
    try {
      path = loginRedirect || (location.pathname !== '/login' ? location.pathname : '/landing');
      let result;
      if (!onlyTermsCheck) {
        result = await getOrCreateUser(user);
        if (!(result.first_name && result.last_name)) {
          path = location.state ? location.state.from : '/full-name';
          history.replace({ pathname: path });
          return;
        }
      }
      // for first login, prefs are created once table loads for first time. Optional chain here to prevent erroring for first time users
      /* eslint-disable */
      if (TermsContent.terms.version !== userPreferences?.terms?.termsVersion || TermsContent.privacy.version !== userPreferences?.terms?.privacyVersion) {
        path = `/term-conditions`;
        history.replace({ pathname: path });
      }
      setLoginRedirect('') //reset this to avoid any relog issues
      history.push(path);
    } catch (error) {
      //push anyway, otherwise error here blocks the entire application from loading
      setLoginRedirect('')
      history.push(path);
    }
  }

  //this login route check can be done from any component within the auth provider
  //it is done here so that it can implemented in one place and passed down to UserFullName (potentially futures as well)
  //this login check is currently 2 parts (user info + t&c) so after user info, needs to check again for part 2 
  useEffect(() => {
    if (authState === AuthState.SignedIn && !_.isEmpty(user)) {
      loginRouteCheck()
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [authState, user])

  return (
    /* <Box sx={{position: 'relative', top: '64px', padding: '48px'}}> */
    <Box>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path={["/privacypolicy", "/privacypolicy/:date"]} component={PrivacyPolicyPdf} />
        <Route exact path={["/termsofuse", "/termsofuse/:date"]} component={TermsOfUsePdf} />
        <Route exact path={["/acceptableusepolicy", "/acceptableusepolicy/:date"]} component={AcceptableUsePolicy} />
        <Route exact path="/">
          {authState === AuthState.SignedIn && !_.isEmpty(user) ? <Redirect to={`/landing`} /> : null}
        </Route>
        <ProtectedRoute path={ACCOUNT_SETTINGS} component={AccountSettings} />
        <ProtectedRoute path={MANAGE_NOTIFICATIONS} component={ManageNotifications} />
        <ProtectedRoute path={USER_LANDING_PATH} component={UserLanding} componentProps={{ toggleChangePassword }} />
        <ProtectedRoute path={USER_FULL_NAME_PATH} componentProps={{ loginRouteCheck }} component={UserFullName} />
        <ProtectedRoute path={TERMS_AND_CONDITIONS_PATH} component={TermsNConditions} />
        <ProtectedRoute path={EDIT_HELP_PATH} component={EditHelp} />
        <ProtectedRoute path={STUDY_DETAILS_PATH} component={StudyDetails} />
        <ProtectedRoute path={SYSTEM_ADMIN} accessibleBy={[GROUPS.ADMIN]} component={SystemAdmin} />
        <ProtectedRoute path={ADMIN_CENTER} accessibleBy={[GROUPS.ACCOUNT_ADMIN, GROUPS.ADMIN]} component={AdminCenter} />
        <ProtectedRoute path={SYSTEM_MESSAGES} accessibleBy={[GROUPS.ADMIN]} component={SystemMessages} />
      </Switch>
    </Box>
  );
}
export default Routing;
