import React, {
  useState, useEffect, useCallback, useRef,
} from 'react';
import _ from 'lodash';
import { Card } from 'react-bootstrap';
import styles from './StudyDocuments/StudyDocuments.module.scss';
import { useS3 } from '../../../providers/s3';
import { useAuth } from '../../../providers/auth';
import { useSQS } from '../../../providers/sqs';
import DeleteDocumentModal from '../documents/DeleteDocumentModal';
import StudyDocumentsHeader from './StudyDocuments/StudyDocumentsHeader/StudyDocumentsHeader';
import StudyDocumentsUploader from './StudyDocuments/StudyDocumentsUploader/StudyDocumentsUploader';
import StudyDocumentsBreadcrumbs from './StudyDocuments/StudyDocumentsBreadcrumbs/StudyDocumentsBreadcrumbs';
import StudyDocumentsTable from './StudyDocuments/StudyDocumentsTable/StudyDocumentsTable';
import StudyDocumentsCreateFolderModal from './StudyDocuments/StudyDocumentsCreateFolderModal/StudyDocumentsCreateFolderModal';
import CustomLoader from '../common/CustomLoader';
import {
  createFolderHandler,
  deleteDocumentAudit,
  getCustomS3FoldersHandler,
  goToTopLevelHandler,
  openCreateFolderModalHandler,
  searchFolderData,
  selectedFolderGridOptsHandler,
  uploadCompletedHandler,
} from './StudyDocuments/StudyDocuments.utils';
import { getTopLevelGridOpts } from '../../utils';
import { GROUPS } from '../../../constants';
import StudyDocumentsDeleteFolderModal from './StudyDocuments/StudyDocumentsDeleteFolderModal/StudyDocumentsCreateFolderModal/StudyDocumentsDeleteFolderModal';

const StudyDocuments = function ({ study, projectCode }) {
  const { user } = useAuth();
  const { getCustomS3Folders, createFolder, deleteFolder } = useS3();
  const { sendMessageToQueueForAudits } = useSQS();
  const [isLoadingTable, setIsLoadingTable] = useState(false);
  const [customS3folders, setCustomS3Folders] = useState({});
  const [selectedFolder, setSelectedFolder] = useState({});
  const [selectedFolderData, setSelectedFolderData] = useState({});
  const [foldersNavigation, setFoldersNavigation] = useState([]);
  const [agGridOptions, setAgGridOptions] = useState({});
  const [loadingComponent, setLoadingComponent] = useState(true);
  const [isCreatingFolder, setIsCreatingFolder] = useState(false);
  const [isDeletingFolder, setIsDeletingFolder] = useState(false);
  const [showCreatingFolderModal, setShowCreatingFolderModal] = useState(false);
  const [showDeleteFolderModal, setShowDeleteFolderModal] = useState(false);
  const [deleteFolderName, setDeleteFolderName] = useState('');
  const newFolderNameRef = useRef();
  const isCustomFoldersEnabled = user.groups.includes(GROUPS.STUDY_DIRECTOR) || user.groups.includes(GROUPS.BIOMARKER);
  const isExternalUser = user.groups.includes(GROUPS.EXTERNAL);
  const hideEmptyFolders = isExternalUser
  || user.groups.includes(GROUPS.GENERAL_ACCESS)
  || user.groups.includes(GROUPS.BUSINESS_DEVELOPMENT);
  const tableDataAvailable = agGridOptions?.rowData?.length > 0;
  const isTopLevel = foldersNavigation.length === 0;
  const showNoDocumentsMessage = !loadingComponent
    && !isLoadingTable
    && !tableDataAvailable
    && hideEmptyFolders
    && isTopLevel;
  // CALLBACK FUNCTIONS
  const enterFolderHandler = useCallback(
    (folderName) => {
      setFoldersNavigation((prevFolders) => [...prevFolders, folderName]);
      setSelectedFolder(folderName);
    },
    [setFoldersNavigation],
  );

  const enterBreadbrumbFolderHandler = useCallback(
    (folderName) => {
      const newFoldersNavigation = [...foldersNavigation];
      const index = newFoldersNavigation.indexOf(folderName);
      newFoldersNavigation.splice(
        index + 1,
        newFoldersNavigation.length - index,
      );
      setFoldersNavigation(newFoldersNavigation);
      setSelectedFolder(folderName);
    },
    [setFoldersNavigation, foldersNavigation],
  );

  const onDeleteCompletedHandler = () => {
    getCustomS3FoldersHandler(
      setIsLoadingTable,
      getCustomS3Folders,
      setCustomS3Folders,
      projectCode,
    );
  };

  const deleteFolderHandler = (folderName) => {
    setDeleteFolderName(folderName);
    setShowDeleteFolderModal(true);
  };

  const deleteFolderConfirmationHandler = async (folderName) => {
    setIsDeletingFolder(true);
    let folderPath = '';
    if (foldersNavigation.length > 0) {
      const folderString = foldersNavigation
        .map((folderName) => folderName)
        .join('/');
      folderPath = `${projectCode}/${folderString}/${folderName}/`;
    } else {
      folderPath = `${projectCode}/${folderName}/`;
    }
    await deleteFolder(projectCode, folderPath);
    setIsDeletingFolder(false);
    setShowDeleteFolderModal(false);
    getCustomS3FoldersHandler(
      setIsLoadingTable,
      getCustomS3Folders,
      setCustomS3Folders,
      projectCode,
    );
  };

  // FETCHING CUSTOM S3 FOLDERS AND FILES ON RENDER
  useEffect(() => {
    getCustomS3FoldersHandler(
      setIsLoadingTable,
      getCustomS3Folders,
      setCustomS3Folders,
      projectCode,
    );
    // eslint-disable-next-line
  }, []);

  // CREATING TOP LEVEL TABLE GRID OPTIONS
  useEffect(() => {
    const fetchFoldersData = async () => {
      try {
        const topLevelGripOpts = getTopLevelGridOpts(
          customS3folders,
          enterFolderHandler,
          // eslint-disable-next-line
          deleteFolderHandler,
          isCustomFoldersEnabled,
          hideEmptyFolders,
        );
        if (foldersNavigation.length === 0) {
          setAgGridOptions(topLevelGripOpts);
          setLoadingComponent(false);
        }
      // eslint-disable-next-line no-empty
      } catch (error) {
      }
    };
    fetchFoldersData();
    // eslint-disable-next-line
  }, [customS3folders, foldersNavigation]);

  // CREATING SELECTED FOLDER TABLE GRID OPTIONS
  useEffect(() => {
    if (selectedFolder) {
      const folderData = searchFolderData(
        `${selectedFolder}/`,
        customS3folders,
      );
      if (folderData) {
        selectedFolderGridOptsHandler(
          folderData.files,
          folderData.folders,
          isCustomFoldersEnabled,
          enterFolderHandler,
          deleteFolderHandler,
          setAgGridOptions,
          isExternalUser,
        );
        setSelectedFolderData(folderData);
      }
    }
    // eslint-disable-next-line
  }, [customS3folders, selectedFolder]);

  return (
    <div className={styles.studyDocumentsContainer}>
      <Card.Header className={styles.cardHeader}>
        <StudyDocumentsHeader
          openCreateFolderModalHandler={openCreateFolderModalHandler}
          setShowCreatingFolderModal={setShowCreatingFolderModal}
          isCustomFoldersEnabled={isCustomFoldersEnabled}
        />
      </Card.Header>
      <Card.Body className={styles.cardBody}>
        {!_.isEmpty(selectedFolderData) && !_.isEmpty(selectedFolder) && (
          <>
            <StudyDocumentsUploader
              customS3folders={customS3folders}
              study={study}
              projectCode={projectCode}
              selectedFolderData={selectedFolderData}
              onUploadCompleted={uploadCompletedHandler}
              foldersNavigation={foldersNavigation}
              setIsLoadingTable={setIsLoadingTable}
              getCustomS3Folders={getCustomS3Folders}
              setCustomS3Folders={setCustomS3Folders}
            />
            <StudyDocumentsBreadcrumbs
              foldersNavigation={foldersNavigation}
              goToTopLevelHandler={goToTopLevelHandler}
              enterBreadbrumbFolderHandler={enterBreadbrumbFolderHandler}
              setFoldersNavigation={setFoldersNavigation}
              setSelectedFolder={setSelectedFolder}
            />
          </>
        )}
        {isLoadingTable && (
          <div className={styles.tableLoader}>
            <CustomLoader />
          </div>
        )}
        {showNoDocumentsMessage && (
          <div className={styles.noDataText}>
            No documents are available at this time.
          </div>
        )}
        {!isLoadingTable && !showNoDocumentsMessage && (
          <StudyDocumentsTable agGridOptions={agGridOptions} />
        )}
      </Card.Body>
      <StudyDocumentsCreateFolderModal
        showCreatingFolderModal={showCreatingFolderModal}
        setShowCreatingFolderModal={setShowCreatingFolderModal}
        newFolderNameRef={newFolderNameRef}
        createFolderHandler={createFolderHandler}
        isCreatingFolder={isCreatingFolder}
        setIsCreatingFolder={setIsCreatingFolder}
        createFolder={createFolder}
        foldersNavigation={foldersNavigation}
        projectCode={projectCode}
        setIsLoadingTable={setIsLoadingTable}
        getCustomS3Folders={getCustomS3Folders}
        setCustomS3Folders={setCustomS3Folders}
      />
      <StudyDocumentsDeleteFolderModal
        showDeleteFolderModal={showDeleteFolderModal}
        setShowDeleteFolderModal={setShowDeleteFolderModal}
        deleteFolderName={deleteFolderName}
        isDeletingFolder={isDeletingFolder}
        onDeleteConfirmation={deleteFolderConfirmationHandler}
      />
      <DeleteDocumentModal
        projectCode={projectCode}
        onDeleteCompleted={onDeleteCompletedHandler}
        deleteDocumentAudit={
          () =>
            // eslint-disable-next-line
            deleteDocumentAudit(
              user,
              projectCode,
              study,
              sendMessageToQueueForAudits,
            )
          // eslint-disable-next-line
        }
      />
    </div>
  );
};

export default StudyDocuments;
