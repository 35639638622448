import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { useGraphQL } from '../../providers/graphql';

import { GROUPS, USER_STATUS_MAP } from '../../constants';
import { useAuth } from '../../providers/auth';
import AddUserModal from './admin/AddUser';
import ForceUserInviteModal from './admin/ForceUserInviteModal';
import ModifyWhiteListModal from './admin/ModifyWhiteListModal';
import ColorButton from './ReusableComponents/ColorButton';
import UserGrid from './UserGrid';

const loaderStates = {
  INIT: 'INIT',
  LOADING: 'LOADING',
  LOADED: 'LOADED',
}

const AdminCenter = () => {
  const {
    getCompanyUsers, getOrCreateUser, getCompanyInfoById, createUserForCompany,
  } = useGraphQL()
  const { search } = useLocation();
  const queryParams = React.useMemo(() => new URLSearchParams(search), [search]);
  const { user } = useAuth()
  const [loader, setLoader] = useState(loaderStates.INIT)
  const [invitingUserInProgress, setInvitingUserInProgress] = useState(false)
  const [updatingWhitelistInProgress, setUpdatingWhitelistInProgress] = useState(false)
  const [showAddUserModal, setShowAddUserModal] = useState(false)
  const [showWhiteListModal, setShowWhiteListModal] = useState(false)
  const [companyInfo, setCompanyInfo] = useState(null)
  const [companyUsers, setCompanyUsers] = useState([])
  const [forceUserInviteModal, setForceUserInviteModal] = useState(false)
  const [forceUserInviteProps, setForceUserInviteProps] = useState({})
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [showSnackBar, setShowSnackBar] = useState({ show: false, text: '' })
  // eslint-disable-next-line
  const isSystemAdmin = useMemo(() => {
    if (user?.groups?.length) {
      return user.groups.includes(GROUPS.ADMIN)
    }
    return false
  }, [user])

  const toggleUserAccountModal = () => {
    setShowAddUserModal(!showAddUserModal)
  }
  const emptySnackBarText = () => {
    setShowSnackBar({ show: false, text: '' });
    setSnackbarMessage('');
  }
  const loadUsersForCompany = useCallback(async () => {
    if (user && loader === loaderStates.INIT) {
      setLoader(loaderStates.LOADING)
      const userInfo = await getOrCreateUser(user)
      if (userInfo) {
        let companyID = ''
        if (isSystemAdmin && queryParams.get('company')) {
          companyID = queryParams.get('company')
        } else if (userInfo.companyAccountID) {
          companyID = userInfo.companyAccountID
        }
        const response = await Promise.all([getCompanyUsers(companyID), getCompanyInfoById(companyID)])
        if (response[0]?.data?.getCompanyUsers) {
          const users = response[0].data.getCompanyUsers.map((item) => ({
            ...item,
            name: item.name || '-',
            status: USER_STATUS_MAP[item.status],
            lastLogin: item.lastLogin ? moment(item.lastLogin * 1000).format('MMMM D, YYYY h:mm:ss A') : '-',
          }))
          setCompanyUsers(users)
        }
        if (response[1]?.data?.getCompanyAccount) {
          setCompanyInfo(response[1].data.getCompanyAccount)
        }
      }
      setLoader(loaderStates.LOADED)
    }
    // eslint-disable-next-line
  }, [getCompanyUsers, user])

  useEffect(() => {
    loadUsersForCompany()
  }, [getCompanyUsers, loadUsersForCompany])

  useEffect(() => {
    setUpdatingWhitelistInProgress(showWhiteListModal)
  }, [showWhiteListModal])

  /**
   * Receive updated domains from modal and update current state accordingly.
   *
   * @param {Array.<string>} domains list of new domains.
   */
  const getModifiedWhiteListedDomains = (domains) => {
    setCompanyInfo({ ...companyInfo, whitelistedDomains: domains })
  }

  const renderSnackbarMessage = () => {
    const message = {
      success: (
        <div className="LoginForm-SuccessMessage successText" style={{ padding: '2px 5px', width: '250%' }}>
          <div className="LoginForm-SuccessMessageText Mng-not-msg-txt">{showSnackBar.text || 'Invitation has been sent successfully.'}</div>
        </div>
      ),
      error: (
        <div className="LoginForm-ErrorMessage errorText" style={{ padding: '2px 5px', width: '250%' }}>
          <div className="LoginForm-ErrorMessageText Mng-not-msg-txt">{showSnackBar.text || 'Error.  Please try again.'}</div>
        </div>
      ),
      doNothing: null,
    }
    return (
      <Stack sx={{ width: '100%' }} spacing={2}> {message[snackbarMessage]} </Stack>
    )
  }

  const breadcrumbs = [
    <Link underline="hover" className="study-details-breadcrumb-homelink" key="manage_notification_breadcrumb_1" to="/landing">
      <span><em>Study </em></span>List
    </Link>,

    <Link underline="hover" className="study-details-breadcrumb-homelink" key="2" to="/system-admin">
      <span><em>System Admin </em></span>Center
    </Link>,

    <Typography className="bc-study-det" key="manage_notification_breadcrumb_2">
      Admin Center
    </Typography>,
  ];

  const renderButtons = () => {
    if (companyInfo) {
      return (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Box>
            <ColorButton
              disabled={invitingUserInProgress}
              data-testid="add-account"
              variant="outlined"
              onClick={() => {
                toggleUserAccountModal();
                emptySnackBarText();
              }}
            >{invitingUserInProgress ? 'Inviting User' : 'Invite User'}
            </ColorButton>
            {invitingUserInProgress && <CircularProgress />}
          </Box>
          <Box sx={{ position: 'relative' }}>
            <ColorButton
              disabled={updatingWhitelistInProgress}
              data-testid="test-update-whitelist"
              variant="outlined"
              onClick={() => {
                setShowWhiteListModal(true);
                emptySnackBarText();
              }}
            >{updatingWhitelistInProgress ? 'Processing' : 'Modify Email Whitelisting'}
            </ColorButton>
            {updatingWhitelistInProgress && (
            <CircularProgress
              size={24}
              sx={{
                position: 'absolute',
                top: '10%',
                left: '35%',
              }}
            />
            )}
          </Box>
        </div>
      )

    }
    return <></>
  }

  const renderModals = () => {
    if (companyInfo) {
      const whiteListedDomains = companyInfo.whitelistedDomains.join(',')
      return (
        <>
          <AddUserModal
            setCompanyUsers={setCompanyUsers}
            companyUsers={companyUsers}
            companyAccount={companyInfo}
            toggleUserAccountModal={toggleUserAccountModal}
            showAddUserModal={showAddUserModal}
            inviteUser={createUserForCompany}
            setLoading={setInvitingUserInProgress}
            setForceUserInviteProps={setForceUserInviteProps}
            setForceUserInviteModal={setForceUserInviteModal}
            setShowSnackBar={setShowSnackBar}
            setSnackbarMessage={setSnackbarMessage}
            reloadUsers={loadUsersForCompany}
          />
          <ForceUserInviteModal
            {...forceUserInviteProps}
            showModal={forceUserInviteModal}
            closeModal={() => {
              setForceUserInviteModal(false)
            }}
          />
          <ModifyWhiteListModal
            companyAccountId={companyInfo.id}
            companyName={companyInfo.name}
            userId={user.id}
            showModal={showWhiteListModal}
            initialDefaultValue={whiteListedDomains}
            setShowModal={setShowWhiteListModal}
            setShowSnackBar={setShowSnackBar}
            setSnackbarMessage={setSnackbarMessage}
            sendData={getModifiedWhiteListedDomains}
          />
        </>
      )
    }
    return <></>
  }

  const renderNavigation = () => {
    if (breadcrumbs.length === 3 && !queryParams.get('company')) {
      breadcrumbs.splice(1, 1)
    }
    return (
      <Breadcrumbs
        underline="none"
        className="bc-margin-bl"
        sx={{ fontSize: '14px', marginTop: '16px', padding: '0px' }}
        separator={<img src="./landing/arrow_right.svg" alt="breadcrumb separator" />}
        aria-label="breadcrumb"
      >
        {breadcrumbs}
      </Breadcrumbs>
    )
  }

  return (
    queryParams.get('company') && !isSystemAdmin ? <Redirect to="/landing" />
      : (
        <div className="landing-inner-container-mphs">
          <div>
            {renderNavigation()}
            <div className="manage-notification-svg">

              <span
                className="AvenirNextLTProRegular"
                style={{
                  textAlign: 'left', letterSpacing: '0px', color: '#056A7B', opacity: 1, fontSize: '32px',
                }}
                data-testid="manage-notifications-title"
              >
                Admin Center
              </span>
            </div>
            {companyInfo
                    && (
                    <div style={{ display: 'flex' }}>
                      <h4>
                        {companyInfo.name}
                      </h4>
                    </div>
                    )}
            {companyInfo && (
            <div style={{ display: 'flex', marginBottom: '16px' }}>
              <h5>
                {companyInfo.studyIdentifiers.join(',')}
              </h5>
            </div>
            )}
          </div>

          {loader === loaderStates.LOADING
            ? (
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <Button variant="primary" disabled>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  Loading Users...
                </Button>
              </div>
            )
            : (
              <>
                {
                  renderButtons()
                }
                <Snackbar
                  style={{ position: 'unset' }}
                  open={showSnackBar.show}
                  autoHideDuration={2500}
                  onClose={() => {
                    setTimeout(() => {
                      setShowSnackBar({ show: false, text: '' });
                      setSnackbarMessage('');
                    }, 1000); // 1 seconds timeout to prevent closing on click
                  }}
                  message={renderSnackbarMessage()}
                />

                <UserGrid
                  companyUsers={companyUsers}
                  setCompanyUsers={setCompanyUsers}
                  companyInfo={companyInfo}
                  reloadUsers={loadUsersForCompany}
                />
                {
                    renderModals()
                }
              </>
            )}

        </div>
      )
  )
}

export default AdminCenter;
