import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

let currentIpAddress = ''

export const createMessageForSQS = async ({
  eventType, userId = '', companyName = '', projectId = '', attributes = [],
}) => {
  if (!currentIpAddress) {
    await getUserIpAddress()
  }
  const currentTime = new Date()
  const ipAddress = currentIpAddress
  let data = {
    eventType,
    appName: 'Crownlink',
    timeStamp: parseInt(currentTime.valueOf() / 1000),
    dateAndTime: currentTime.toString(),
    ipAddress,
    userId,
    companyName,
    projectId,
  }
  attributes.forEach((obj) => {
    data = { ...data, ...obj }
    return null
  })
  return data
}

export const createMessageForSNS = (createdBy, company, projectId, notificationType, userId, attributes) => ({
  id: uuidv4(),
  createdBy,
  companyName: company,
  type: notificationType,
  projectCode: projectId,
  createdAt: moment().unix(),
  description: '',
  userId,
  attributes: attributes || [],
})

export const getUserIpAddress = async () => {
  currentIpAddress = '255.255.255.255'; // This is a dummy IP address. We no longer collect user IP addresses.
  return currentIpAddress || ''
}
