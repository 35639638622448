/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react'
import { AgGridReact } from 'ag-grid-react'
import _ from 'lodash'
import {
  Button, Row, Col, Spinner, Card,
} from 'react-bootstrap'
import Typography from '@mui/material/Typography';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/styles';
import { useClinicalObservationLegendContext } from '../../providers/clinicalObservationLegend'

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip
    disableInteractive
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    PopperProps={{
      popperOptions: {
        modifiers: [
          {
            name: 'offset',
            options: { offset: [50, -10] },
          },
        ],
      },
    }}
    classes={{
      popper: className,
    }}
  />
// eslint-disable-next-line no-unused-vars
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#FFFBEC',
    color: '#222222',
    border: '1px solid #FEE7D5',
    borderRadius: '2px',
    fontSize: '12px',
    top: '20px',
  },
}));

const ClinicalObservationsPanel = function (props) {
  const spinner = (
    <Row className="justify-content-center">
      <Button variant="primary" disabled>
        <Spinner
          as="span"
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
        />
        Loading Clinical Observation Metrics...
      </Button>
    </Row>
  )

  const { clinicalObservationIndividualGridOptions, clinicalObservationFetching, showTable } = props
  const DisplayLegend = function () {
    const { clinicalObservationIndividualGroupsLegend: legend } = props
    const alphabeticalLegend = _.sortBy(legend, 'key')

    const { selectedCoCodes } = useClinicalObservationLegendContext()
    const { setSelectedCoCodes } = useClinicalObservationLegendContext()

    return (
      <>
        {
          selectedCoCodes.length > 0 && selectedCoCodes.length < legend.length
          && (
          <div
            className="blackHyperlink"
            onClick={() => setSelectedCoCodes([])}
            style={{ cursor: 'pointer' }}
          >
            Restore Default Highlighting
            <hr />
          </div>
          )
        }
        {
          alphabeticalLegend.map((value, index) => {
            const tempIndx = index
            return (
              <LightTooltip
                title={(
                  <Typography className="AvenirNextLTProRegular">
                    {value.value}
                  </Typography>
                )}
                placement="bottom"
                key={value + tempIndx}
              >
                {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                <p
                  className={selectedCoCodes.length > 0 && !selectedCoCodes.includes(value.key) ? 'lightGrayHyperlink' : 'blackHyperlink'}
                  onClick={() => (!selectedCoCodes.includes(value.key) ? setSelectedCoCodes([...selectedCoCodes, value.key]) : setSelectedCoCodes(selectedCoCodes.filter((el) => el !== value.key)))}
                  style={{ cursor: 'pointer' }}
                  data-toggle="tooltip"
                >
                  {value.key}: {value.value.length > 20 ? `${value.value.substr(0, 20)}...` : value.value}
                </p>
              </LightTooltip>
            )
          })
        }
      </>
    )
  }

  return (
    <div>
      <hr className="mlr-10" />
      <div style={clinicalObservationFetching ? { display: 'none' } : {}}>
        <div style={(showTable && !_.isEmpty(clinicalObservationIndividualGridOptions) && clinicalObservationIndividualGridOptions.rowData) ? {} : { display: 'none' }}>
          <p><span style={{ fontWeight: 'bold' }}>Tip:</span> Selecting observation code(s) in the table or legend will highlight the selected value(s).</p>
          <Row>
            <Col md="9">
              <h4 className="text-center text-md-left">Absolute Clinical Observation</h4>
              <div
                className="ag-theme-alpine"
                style={{
                  width: '100%',
                  height: '450px',
                  margin: 'auto',
                }}
              >
                {!_.isEmpty(clinicalObservationIndividualGridOptions) && clinicalObservationIndividualGridOptions.onGridReady
                  ? <AgGridReact gridOptions={clinicalObservationIndividualGridOptions} />
                  : spinner}
              </div>
            </Col>
            <Col>
              <h4>&nbsp;</h4>
              <div
                className="ag-theme-alpine"
                style={{
                  width: '100%',
                  height: '450px',
                  margin: 'auto',
                }}
              >
                <Card className="legend-list-card">
                  <Card.Header>
                    <h5 className="studyDetails-legendsCardHeader text-center text-md-left" id="co_legend">Legend</h5>
                  </Card.Header>
                  <Card.Body className="legend-list"> {/* custom class added for styling enhancements */}
                    <DisplayLegend />
                  </Card.Body>
                </Card>
              </div>
            </Col>
          </Row>
        </div>
        {(!showTable || _.isEmpty(clinicalObservationIndividualGridOptions) || !clinicalObservationIndividualGridOptions.onGridReady) && <div>No Data Available</div>}
      </div>
      {clinicalObservationFetching && spinner}
    </div>
  )
}

export default ClinicalObservationsPanel
