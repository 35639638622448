import React from 'react';
import {
  Plate,
  PlateProvider,
} from '@udecode/plate';

import AlignToolbarButtons from './align/AlignToolbarButtons';
import { editableProps } from './common/editableProps';
import Toolbar from './toolbar/Toolbar';
import FontPropertiesButtons from './fontProperties/fontPropertiesButtons';
import ColorButtons from './color/fontPropertiesButtons';
import TableButtons from './table/tableButtons';
import BalloonToolbarRTE from './toolbar/balloonToolbar';
import HyperLinkButtons from './hyperLink/hyperLink';
import defaultPluginsConfig from './defaultPluginsConfig';
import resolvePlugins from './pluginResolver';

export default ({
  initialValue, showToolbar, containerClass, onChange, placeHolder, showBalloonToolbar, readOnly = false,
  pluginsConfig = defaultPluginsConfig,
}) => (
  <PlateProvider
    plugins={resolvePlugins(pluginsConfig)}
    initialValue={initialValue}
    onChange={onChange}
    key={`key-${new Date().getTime()}`}
    readOnly={readOnly}
  >
    <div className={showToolbar === true ? containerClass : ''}>
      {showToolbar && (
        <Toolbar key={`key-toolbar-${new Date().getTime()}`}>
          <AlignToolbarButtons key={`key-align-${new Date().getTime()}`} />
          <FontPropertiesButtons key={`key-font-${new Date().getTime()}`} />
          <ColorButtons key={`key-color-${new Date().getTime()}`} />
          <TableButtons key={`key-table-${new Date().getTime()}`} />
          {pluginsConfig?.hyperLinkConfig?.showHyperLinkIcon
            && (<HyperLinkButtons {...pluginsConfig.hyperLinkConfig} key={`key-hyperlink-${new Date().getTime()}`} />)}
        </Toolbar>
      )}

      <Plate
        editableProps={editableProps(readOnly, placeHolder)}
        key={`key-plate-${new Date().getTime()}`}
      >
        {showBalloonToolbar && <BalloonToolbarRTE />}
      </Plate>
    </div>
  </PlateProvider>
)
