/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-danger */
import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { Link } from 'react-router-dom'
import EditOutlined from '@mui/icons-material/EditOutlined';
import HelpOutlineOutlined from '@mui/icons-material/HelpOutlineOutlined';
import { defaultHtml } from './constants'
import { useAuth } from '../../providers/auth';
import { BRAND_COLORS, GROUPS } from '../../constants';
import './styleNew.css';

const PaperComponent = function (props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function DraggableDialog({ helpData }) {

  const [helpHTML, setHelpHtml] = useState();

  const { user } = useAuth();
  const showEditOption = useMemo(() => user && user.groups && user.groups.includes(GROUPS.ADMIN), [user]);
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    const processHelp = (helpData) => {
      if (helpData.items.length) {
        const help = helpData.items[0];
        if (help && help.helpHtml.length) {
          setHelpHtml(help.helpHtml);
        } else {
          setHelpHtml(defaultHtml);
        }
      } else {
        setHelpHtml(defaultHtml);
      }
    }

    if (helpData) {
      processHelp(helpData)
    } else {
      setHelpHtml(defaultHtml);
    }
  }, [helpData]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
  };

  return (
    <div style={{ borderRadius: '0px 16px' }}>
      <Box className="nav-link help-content">
        <HelpOutlineOutlined className="Header-HelpIcon" onClick={handleClickOpen} />
      </Box>
      <Dialog
        onClose={onClose}
        open={open}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        style={{
          position: 'absolute', left: '70%', top: '51%', transform: 'translate(5%, -49%)', height: '92vh',
        }}
        disableEnforceFocus
        hideBackdrop
      >
        <div style={{ height: '92px', background: '#F9F9F9 0% 0% no-repeat padding-box' }}>
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
            Help
            <DialogActions style={{ padding: '0px' }}>
              {
                showEditOption
                  ? (
                    <Link key="1" to="/edit_help" className="edit-help" onClick={handleClose} style={{ paddingRight: '15px' }} underline="hover">
                      <EditOutlined />
                    </Link>
                  ) : null
              }
              <Button onClick={handleClose} style={{ padding: '0px', minWidth: '0px' }}>
                <CancelOutlinedIcon sx={{ color: BRAND_COLORS.orange }} />
              </Button>
            </DialogActions>
          </DialogTitle>
        </div>
        <DialogContent style={{ padding: '34px 14px 34px 24px' }}>
          <div dangerouslySetInnerHTML={{ __html: helpHTML }} />
        </DialogContent>
      </Dialog>
    </div>
  );
}
