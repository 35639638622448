// Main component for modal on adding system message.

import { Typography, Chip } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';

import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useGraphQL } from '../../../../providers/graphql';
import { BRAND_COLORS, CUSTOM_BOX_STYLE, FONT_FAMILY } from '../../../../constants';
import { noop, getEmptyOrInitializedMessageContents } from '../../../utils';
import RichTextEditor from '../../ReusableComponents/RichTextEditor/RichTextEditor';

import './AddSystemMessage.css';

const noOp = () => { }

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, itemsSelected, theme) {
  return {
    fontWeight:
      itemsSelected.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function MultipleSelectPlaceholder(
  {
    onChange = noOp, // Can be called to get the state value of this component.
    items = [], // List of items to show inside select menu.
    placeholderText = 'Select an option', // text to show when no items are selected.
  },
) {

  const theme = useTheme();
  const [itemsSelected, setItemsSelected] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    // On autofill we get a stringified value.
    const changeValue = typeof value === 'string' ? value.split(',') : value;
    setItemsSelected(
      changeValue,
    );
    onChange(changeValue)
  };

  return (
    <div>
      <FormControl
        variant="standard"
        sx={{
          width: '100%',
        }}
      >
        <Select
          style={{ color: '#FFFF' }}
          multiple
          displayEmpty
          required
          value={itemsSelected}
          onChange={handleChange}
          input={<OutlinedInput />}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <em>{placeholderText}</em>;
            }
            return (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip key={value} label={value} style={{ backgroundColor: '#E0E0E0' }} />
                ))}
              </Box>
            )
          }}
          MenuProps={MenuProps}
          inputProps={{ 'aria-label': 'Without label' }}
          variant="outlined"
        >
          <MenuItem disabled value="">
            <em>{placeholderText}</em>
          </MenuItem>
          <MenuItem
            key="All Users"
            value="All Users"
            style={getStyles('All Users', itemsSelected, theme)}
          >
            All Users
          </MenuItem>
          {items.map((item) => (
            <MenuItem
              key={item}
              value={item}
              style={getStyles(item, itemsSelected, theme)}
            >
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

// --------------------
let messageContents = getEmptyOrInitializedMessageContents();

const AddSystemMessageModal = ({ props }) => {
  const {
    showModal, // Whether to show modal or not.
    toggleModal, // helper function to toggle showModal state.
    sendData = noop, // helper function for parent to receive data after message is sent.
  } = props
  const [isLoading, setIsLoading] = useState(false);
  const [recipients, setRecipients] = useState([])
  const [items, setItems] = useState([]);

  const { createSystemMessage, listCognitoGroups } = useGraphQL();

  // load groups to list.
  useEffect(() => {
    async function fetchGroups() {
      if (items.length !== 0) { return }
      const groups = await listCognitoGroups();
      setItems([...groups.data.listCognitoGroups])
    }
    fetchGroups()

  }, [items.length, listCognitoGroups])

  const getValue = (value) => {
    // set form select value.
    setRecipients([...value])
  }

  // function sleep(ms) {
  //   return new Promise((resolve) => setTimeout(resolve, ms));
  // }
  const onHide = () => {
    setIsLoading(false)
    messageContents = getEmptyOrInitializedMessageContents();
    setRecipients([])
    toggleModal()
  };

  const onSubmit = async (event) => {
    // To prevent refresh of page.
    event.preventDefault();
    event.stopPropagation();
    // --------------------------
    // prevent double click
    setIsLoading(true)
    // get information

    const message = JSON.stringify(messageContents)
    const submitData = {
      recipients: [...recipients],
      messageContent: message,
    }

    // TODO: add  API Call to add message.
    const rest = await createSystemMessage(submitData.messageContent, submitData.recipients)
    sendData(rest)
    // TODO: reset state

    // close modal
    onHide()
  }
  const noOp = (event) => {
    // prevent default and do no operation.
    event.preventDefault();
    event.stopPropagation();
    // --------------------------
  }

  const renderFormOrLoader = () => {
    if (isLoading) {
      return (
        <>
          <div style={{ textAlign: 'center' }}>
            <Typography className={`${FONT_FAMILY}-Regular containerTitle`} style={{ fontSize: '1rem' }}>
              Processing...
            </Typography>
            <CircularProgress size="3rem" style={{ color: BRAND_COLORS.orange }} />

          </div>
        </>
      )
    }
    return (
      <Form onSubmit={isLoading ? noOp : (e) => onSubmit(e)}>
        <Form.Group controlId="formMessageRecipients">
          <Form.Label style={{ color: '#FFFFFFDE', fontFamily: `${FONT_FAMILY}-Regular` }}>Message Recipients</Form.Label>
          <MultipleSelectPlaceholder onChange={getValue} items={items} placeholderText="Select Recipients" />
        </Form.Group>
        <Form.Group controlId="formMessageContent">
          <Form.Label style={{ color: '#FFFFFFDE', fontFamily: `${FONT_FAMILY}-Regular` }}>Message Content</Form.Label>
          <RichTextEditor
            name="messageContent"
            showToolbar
            showBalloonToolbar={false}
            initialValue={messageContents}
            placeHolder="Type your message here"
            readOnly={false}
            onChange={(value) => (messageContents = value)}
            containerClass="addSystemMessageRTE"
          />
        </Form.Group>
        <Button
          md={4}
          data-testid="submit_message_button"
          className="LoginFormSignInButtonChangePassword"
          type="submit"
          variant="primary"
          disabled={isLoading}
        >
          Submit
        </Button>
        <Button
          data-testid="cancel_message_button"
          className="LoginFormOutlineButton"
          type="button"
          variant="light"
          onClick={onHide}
          disabled={isLoading}
        >
          Cancel
        </Button>
      </Form>
    )
  }

  return (
    <Modal data-testid="test-system-message-modal" open={showModal}>
      <Box sx={CUSTOM_BOX_STYLE}>
        <div style={{ textAlign: 'center' }}>
          <span className="containerTitle" data-testid="container_Title">Add System Message</span>
        </div>
        {renderFormOrLoader()}
      </Box>
    </Modal>
  )
}

export default AddSystemMessageModal;
