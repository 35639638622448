import React, { useState, useEffect } from 'react';
import { Button, Row, Spinner } from 'react-bootstrap';
import { AgGridReact } from 'ag-grid-react';
import _ from 'lodash';
import TotalFluxChart from './TotalFluxChart';
import RadioList from './RadioList';
import { truncationOfLongStr } from '../utils';
import TumorVolumeTable from '../TumorVolumeTable';

const TotalFluxPanel = function TotalFluxPanel({
  bliVolumetotalFluxData,
  bliMeantotalFluxData,
  bliVentraltotalFluxData,
  bliDorsaltotalFluxData,
  bliVolumetotalFluxSummaryGroups,
  bliMeantotalFluxSummaryGroups,
  bliVentraltotalFluxSummaryGroups,
  bliDorsaltotalFluxSummaryGroups,
  bliVolumeIndividualData,
  bliMeanIndividualData,
  bliVentralIndividualData,
  bliDorsalIndividualData,
  bliVolumeIndividualGridOptions,
  bliMeanIndividualGridOptions,
  bliVentralIndividualGridOptions,
  bliDorsalIndividualGridOptions,
  bliVolumeSummaryGridOptions,
  bliMeanSummaryGridOptions,
  bliVentralSummaryGridOptions,
  bliDorsalSummaryGridOptions,
  totalFluxSummaryFetching,
  totalFluxIndividualFetching,
  showTable,
  dataViewChangeAudit,
  chartExportAudit,
  isStudyChina,
  model, studyId,
}) {
  const [isDorsalVentralVisible, setIsDorsalVentralVisible] = useState(false);
  useEffect(() => {
    if (bliDorsaltotalFluxData?.length && bliVentraltotalFluxData?.length) {
      setIsDorsalVentralVisible(true);
    } else {
      setIsDorsalVentralVisible(false);
    }

  }, [bliDorsaltotalFluxData, bliVentraltotalFluxData])

  const spinner = (
    <Row className="justify-content-center">
      <Button variant="primary" disabled>
        <Spinner
          as="span"
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
        />
        Loading Total Flux Metrics...
      </Button>
    </Row>
  );

  return (
    <RadioList
      auditCallback={dataViewChangeAudit}
      items={[
        {
          id: 'summary',
          label: 'Summary',
          content: (
            <div>
              <hr className="mlr-10" />
              <div style={totalFluxSummaryFetching ? { display: 'none' } : {}}>

                {isStudyChina ? (
                  <div style={showTable && bliVolumetotalFluxData.length ? {} : { display: 'none' }}>
                    <Row
                      style={{
                        width: '100%',
                        height: '450px',
                        margin: 'auto',
                      }}
                      className="summary-chart"
                    >
                      <TotalFluxChart
                        margin={{ r: 0, t: 40, pad: 0 }}
                        yaxisTitle="<b>Total Flux (p/s)</b>"
                        title="Total Flux Graph (p/s)"
                        xaxisTitle="<b>Study Day</b>"
                        data={bliVolumetotalFluxData}
                        divId="bli-mean-summary"
                        chartExportAudit={chartExportAudit('summary')}
                        groups={bliVolumetotalFluxSummaryGroups}
                        studyId={studyId}
                        modelId={model.study_id}
                        graphName="BLIVO-summary"
                        acronym="BLIVO"
                      />
                    </Row>

                    <hr className="mlr-10" />
                    <Row
                      style={{
                        width: '100%',
                        height: '350px',
                        margin: 'auto',
                      }}
                    >
                      <h4>Total Flux (p/s)</h4>
                      {!_.isEmpty(bliVolumeSummaryGridOptions) && (

                      <TumorVolumeTable
                        divStyles={{
                          height: '100%',
                          width: '100%',
                        }}
                        gridOptions={bliVolumeSummaryGridOptions}
                      />
                      )}
                    </Row>

                  </div>
                ) : (
                  <>
                    <div style={showTable && isDorsalVentralVisible && bliMeantotalFluxData.length ? {} : { display: 'none' }}>
                      <Row
                        style={{
                          width: '100%',
                          height: '450px',
                          margin: 'auto',
                        }}
                        className="summary-chart"
                      >
                        <TotalFluxChart
                          margin={{ r: 0, t: 40, pad: 0 }}
                          yaxisTitle="<b>Total Flux (p/s)</b>"
                          title="Mean Total Flux Graph (p/s)"
                          xaxisTitle="<b>Study Day</b>"
                          data={bliMeantotalFluxData}
                          divId="bli-mean-summary"
                          chartExportAudit={chartExportAudit('summary')}
                          groups={bliMeantotalFluxSummaryGroups}
                          studyId={studyId}
                          modelId={model.study_id}
                          graphName="BLIM-summary"
                          acronym="BLIM"
                        />
                      </Row>

                      <hr className="mlr-10" />
                      <Row
                        style={{
                          width: '100%',
                          height: '350px',
                          margin: 'auto',
                          marginBottom: '50px',
                        }}
                      >
                        <h4>Mean Total Flux (p/s)</h4>
                        {!_.isEmpty(bliMeanSummaryGridOptions) && (

                        <TumorVolumeTable
                          divStyles={{
                            height: '100%',
                            width: '100%',
                          }}
                          gridOptions={bliMeanSummaryGridOptions}
                        />
                        )}
                      </Row>
                    </div>
                    <hr className="mlr-10" />
                    <div style={showTable && bliVentraltotalFluxData.length ? {} : { display: 'none' }}>
                      <Row
                        style={{
                          width: '100%',
                          height: '450px',
                          margin: 'auto',
                        }}
                        className="summary-chart"
                      >
                        <TotalFluxChart
                          margin={{ r: 0, t: 80, pad: 0 }}
                          yaxisTitle="<b>Total Flux (p/s)</b>"
                          title="Mean Total Flux Graph (p/s) - Ventral Only"
                          xaxisTitle="<b>Study Day</b>"
                          data={bliVentraltotalFluxData}
                          divId="bli-Ventral-summary"
                          chartExportAudit={chartExportAudit('summary')}
                          groups={bliVentraltotalFluxSummaryGroups}
                          studyId={studyId}
                          modelId={model.study_id}
                          graphName="BLIV-summary"
                          acronym="BLIV"
                        />
                      </Row>

                      <hr className="mlr-10" />
                      <Row
                        style={{
                          width: '100%',
                          height: '350px',
                          margin: 'auto',
                          marginBottom: '50px',
                        }}
                      >
                        <h4>Mean Total Flux (p/s) - Ventral Only</h4>
                        {!_.isEmpty(bliVentralSummaryGridOptions) && (

                        <TumorVolumeTable
                          divStyles={{
                            height: '100%',
                            width: '100%',
                          }}
                          gridOptions={bliVentralSummaryGridOptions}
                        />
                        )}
                      </Row>
                    </div>
                    <hr className="mlr-10" />
                    <div style={showTable && bliDorsaltotalFluxData.length ? {} : { display: 'none' }}>
                      <Row
                        style={{
                          width: '100%',
                          height: '450px',
                          margin: 'auto',
                        }}
                        className="summary-chart"
                      >
                        <TotalFluxChart
                          margin={{ r: 0, t: 80, pad: 0 }}
                          yaxisTitle="<b>Total Flux (p/s)</b>"
                          title="Mean Total Flux Graph (p/s) - Dorsal Only"
                          xaxisTitle="<b>Study Day</b>"
                          data={bliDorsaltotalFluxData}
                          divId="bli-Dorsal-summary"
                          chartExportAudit={chartExportAudit('summary')}
                          groups={bliDorsaltotalFluxSummaryGroups}
                          studyId={studyId}
                          modelId={model.study_id}
                          graphName="BLID-summary"
                          acronym="BLID"
                        />
                      </Row>
                      <hr className="mlr-10" />
                      <Row
                        style={{
                          width: '100%',
                          height: '350px',
                          margin: 'auto',
                        }}
                      >
                        <h4>Mean Total Flux (p/s) - Dorsal Only</h4>
                        {!_.isEmpty(bliDorsalSummaryGridOptions) && (

                        <TumorVolumeTable
                          divStyles={{
                            height: '100%',
                            width: '100%',
                          }}
                          gridOptions={bliDorsalSummaryGridOptions}
                        />
                        )}
                      </Row>
                    </div>
                  </>
                )}

                {/* error handle */}
                {(!showTable || (!bliMeantotalFluxData.length && !bliVentraltotalFluxData.length && !bliDorsaltotalFluxData.length && !bliVolumetotalFluxData.length)) && <div>No Data Available</div>}
              </div>
              {totalFluxSummaryFetching && spinner}
              <hr className="mlr-10" />
            </div>
          ),
        },
        {
          id: 'individual',
          label: 'Individual',
          content: isStudyChina ? (
            <div style={!totalFluxIndividualFetching && (!showTable || _.isEmpty(bliVolumeIndividualData)) ? { display: 'none' } : {}}>
              <hr className="mlr-10" />
              <h5 className="text-center text-md-left">
                Individual Total Flux (p/s)
              </h5>
              <div style={totalFluxIndividualFetching ? { display: 'none' } : {}}>
                <div style={!showTable || _.isEmpty(bliVolumeIndividualData) ? { display: 'none' } : {}}>
                  <div
                    className="ag-theme-alpine"
                    style={{
                      width: '100%',
                      height: '450px',
                      margin: 'auto',
                    }}
                  >
                    {!_.isEmpty(bliVolumeIndividualGridOptions) && <AgGridReact gridOptions={bliVolumeIndividualGridOptions} />}
                  </div>

                  {!_.isEmpty(bliVolumeIndividualData.chartValues)
                    && Object.entries(bliVolumeIndividualData.chartValues).map(
                      (groupChartValues) => (
                        <Row
                          key={groupChartValues[0]}
                          style={{
                            height: '450px',
                            marginTop: '40px',
                          }}
                        >
                          <TotalFluxChart
                            chartExportAudit={chartExportAudit('individual')}
                            margin={{
                              r: 20,
                              t: 40,
                              pad: 0,
                            }}
                            yaxisTitle="<b>Total Flux (p/s)</b>"
                            title={
                              `Individual Total Flux (p/s): ${
                                truncationOfLongStr(groupChartValues[0], 40)}`
                              }
                            xaxisTitle="<b>Study Day</b>"
                            data={groupChartValues[1]}
                            xaxisRange={
                                bliVolumeIndividualData.xaxisRange
                              }
                            yaxisRange={
                                bliVolumeIndividualData.yaxisRange
                              }
                            divId="individual-tumor-volumes"
                            studyId={studyId}
                            modelId={model.study_id}
                            graphName={`BLIVO-individual-${groupChartValues[0]}`}
                            acronym="BLIVO"
                          />
                        </Row>
                      ),
                    )}
                </div>
                {/* error handle */}
                {(!showTable || _.isEmpty(bliVolumeIndividualData)) && <div>No Data Available</div>}
              </div>
              {totalFluxIndividualFetching && spinner}
              <hr className="mlr-10" />
            </div>
          ) : (
            <>
              <div style={(!totalFluxIndividualFetching && (!isDorsalVentralVisible || (!showTable || _.isEmpty(bliMeanIndividualData)))) ? { display: 'none' } : {}}>
                <hr className="mlr-10" />
                <h5 className="text-center text-md-left">
                  Individual Total Flux (p/s): Mean
                </h5>
                <div style={totalFluxIndividualFetching ? { display: 'none' } : {}}>
                  <div style={(!isDorsalVentralVisible || (!showTable || _.isEmpty(bliMeanIndividualData))) ? { display: 'none' } : {}}>
                    <div
                      className="ag-theme-alpine"
                      style={{
                        width: '100%',
                        height: '450px',
                        margin: 'auto',
                      }}
                    >
                      {!_.isEmpty(bliMeanIndividualGridOptions) && <AgGridReact gridOptions={bliMeanIndividualGridOptions} />}
                    </div>

                    {!_.isEmpty(bliMeanIndividualData.chartValues)
                    && Object.entries(bliMeanIndividualData.chartValues).map(
                      (groupChartValues) => (
                        <Row
                          key={groupChartValues[0]}
                          style={{
                            height: '450px',
                            marginTop: '40px',
                          }}
                        >
                          <TotalFluxChart
                            chartExportAudit={chartExportAudit('individual')}
                            margin={{
                              r: 20,
                              t: 40,
                              pad: 0,
                            }}
                            yaxisTitle="<b>Total Flux (p/s)</b>"
                            title={
                              `Mean - Individual Total Flux (p/s): ${
                                truncationOfLongStr(groupChartValues[0], 40)}`
                              }
                            xaxisTitle="<b>Study Day</b>"
                            data={groupChartValues[1]}
                            xaxisRange={
                                bliMeanIndividualData.xaxisRange
                              }
                            yaxisRange={
                                bliMeanIndividualData.yaxisRange
                              }
                            divId="individual-tumor-volumes"
                            studyId={studyId}
                            modelId={model.study_id}
                            graphName={`BLIM-individual-${groupChartValues[0]}`}
                            acronym="BLIM"
                          />
                        </Row>
                      ),
                    )}
                  </div>
                  {/* error handle */}
                  {(!isDorsalVentralVisible || (!showTable || _.isEmpty(bliMeanIndividualData))) && <div>No Data Available</div>}
                </div>
                {totalFluxIndividualFetching && spinner}
                <hr className="mlr-10" />
              </div>

              <div style={(!totalFluxIndividualFetching && (!showTable || _.isEmpty(bliVentralIndividualData))) ? { display: 'none' } : {}}>
                <hr className="mlr-10" />
                <h5 className="text-center text-md-left">
                  Individual Total Flux (p/s): Ventral Only
                </h5>
                <div style={totalFluxIndividualFetching ? { display: 'none' } : {}}>
                  <div style={!showTable || _.isEmpty(bliVentralIndividualData) ? { display: 'none' } : {}}>
                    <div
                      className="ag-theme-alpine"
                      style={{
                        width: '100%',
                        height: '450px',
                        margin: 'auto',
                      }}
                    >
                      {!_.isEmpty(bliVentralIndividualGridOptions) && <AgGridReact gridOptions={bliVentralIndividualGridOptions} />}
                    </div>

                    {!_.isEmpty(bliVentralIndividualData.chartValues)
                    && Object.entries(bliVentralIndividualData.chartValues).map(
                      (groupChartValues) => (
                        <Row
                          key={groupChartValues[0]}
                          style={{
                            height: '450px',
                            marginTop: '40px',
                          }}
                        >
                          <TotalFluxChart
                            chartExportAudit={chartExportAudit('individual')}
                            margin={{
                              r: 20,
                              t: 40,
                              pad: 0,
                            }}
                            yaxisTitle="<b>Total Flux (p/s)</b>"
                            title={
                              `Ventral - Individual Total Flux (p/s): ${
                                truncationOfLongStr(groupChartValues[0], 40)}`
                              }
                            xaxisTitle="<b>Study Day</b>"
                            data={groupChartValues[1]}
                            xaxisRange={
                                bliVentralIndividualData.xaxisRange
                              }
                            yaxisRange={
                                bliVentralIndividualData.yaxisRange
                              }
                            divId="individual-tumor-volumes"
                            studyId={studyId}
                            modelId={model.study_id}
                            graphName={`BLIV-individual-${groupChartValues[0]}`}
                            acronym="BLIV"
                          />
                        </Row>
                      ),
                    )}
                  </div>
                  {/* error handle */}
                  {(!showTable || _.isEmpty(bliVentralIndividualData)) && <div>No Data Available</div>}
                </div>
                {totalFluxIndividualFetching && spinner}
                <hr className="mlr-10" />
              </div>

              <div style={(!totalFluxIndividualFetching && (!showTable || _.isEmpty(bliDorsalIndividualData))) ? { display: 'none' } : {}}>
                <hr className="mlr-10" />
                <h5 className="text-center text-md-left">
                  Individual Total Flux (p/s): Dorsal Only
                </h5>
                <div style={totalFluxIndividualFetching ? { display: 'none' } : {}}>
                  <div style={!showTable || _.isEmpty(bliDorsalIndividualData) ? { display: 'none' } : {}}>
                    <div
                      className="ag-theme-alpine"
                      style={{
                        width: '100%',
                        height: '450px',
                        margin: 'auto',
                      }}
                    >
                      {!_.isEmpty(bliDorsalIndividualGridOptions) && <AgGridReact gridOptions={bliDorsalIndividualGridOptions} />}
                    </div>

                    {!_.isEmpty(bliDorsalIndividualData.chartValues)
                    && Object.entries(bliDorsalIndividualData.chartValues).map(
                      (groupChartValues) => (
                        <Row
                          key={groupChartValues[0]}
                          style={{
                            height: '450px',
                            marginTop: '40px',
                          }}
                        >
                          <TotalFluxChart
                            chartExportAudit={chartExportAudit('individual')}
                            margin={{
                              r: 20,
                              t: 40,
                              pad: 0,
                            }}
                            yaxisTitle="<b>Total Flux (p/s)</b>"
                            title={
                              `Dorsal - Individual Total Flux (p/s): ${
                                truncationOfLongStr(groupChartValues[0], 40)}`
                              }
                            xaxisTitle="<b>Study Day</b>"
                            data={groupChartValues[1]}
                            xaxisRange={
                                bliDorsalIndividualData.xaxisRange
                              }
                            yaxisRange={
                                bliDorsalIndividualData.yaxisRange
                              }
                            divId="individual-tumor-volumes"
                            studyId={studyId}
                            modelId={model.study_id}
                            graphName={`BLID-individual-${groupChartValues[0]}`}
                            acronym="BLID"
                          />
                        </Row>
                      ),
                    )}
                  </div>
                  {/* error handle */}
                  {(!showTable || _.isEmpty(bliDorsalIndividualData)) && <div>No Data Available</div>}
                </div>
                {totalFluxIndividualFetching && spinner}
                <hr className="mlr-10" />
              </div>
            </>
          ),
        },
      ]}
    />
  );
}

export default TotalFluxPanel;
