// Main Grid component to hold System Messages data.
import Button from '@mui/material/Button';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CustomLoader from '../../common/CustomLoader';
import { useGraphQL } from '../../../../providers/graphql';

const SystemMessagesGrid = ({ props }) => {
  const {
    setPreviewMessageContent, // function for cell renderer to call when clicked to view message.
    newMessageSent = null, // used to check if new message is to be added to grid.
  } = props
  const gridRef = useRef(); // Optional - for accessing Grid's API
  const [rowData, setRowData] = useState([]); // Set rowData to Array of Objects, one Object per Row
  const [loading, setLoading] = useState(true); // loader

  const StatusEnums = {
    SENT: 'SENT',
    RECALLED: 'RECALLED',
  }

  // VALUE GETTER DEFINITIONS
  const nameGetter = (params) => (params.data.sender ? `${params.data.sender.first_name} ${params.data.sender.last_name}` : 'N/A')

  const statusGetter = (params) => {
    if (params.data.status === StatusEnums.SENT) {
      return params.data.status
    }
    return `${params.data.status} - ${params.data.recalled_timestamp}`

  }
  // --- DEFINITIONS END ---

  // Each Column Definition results in one Column.
  const [columnDefs] = useState([
    {
      field: 'date',
      sort: 'desc',
    },
    {
      headerName: 'status',
      valueGetter: statusGetter,
      cellClass: 'truncate',
    },
    {
      valueGetter: nameGetter,
      headerName: 'Sender',
    },
    {
      field: 'recipients',
      cellRenderer: 'recipientsCellRenderer',
      tooltipField: 'recipients',
    },
    {
      field: 'content',
      cellRenderer: 'messageContentRenderer',
    },
    {
      cellRenderer: 'actionsCellRenderer',
      sort: false,
      sortable: false,
      headerName: 'Actions',
    },
  ]);

  // GRAPHQL Imports
  const { listSystemMessages, recallSystemMessage } = useGraphQL();

  // Example of consuming Grid Event
  const cellClickedListener = useCallback(() => {
  }, []);

  useEffect(() => {
    if (rowData.length !== 0) { return }
    listSystemMessages()
      .then((data) => {
        setRowData(data.data.listSystemMessages)
        setLoading(false)
      })
      .catch(() => {
      })
  }, [listSystemMessages, rowData.length])

  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
      gridRef.current.api.sizeColumnsToFit();
    }
  }, [rowData]);

  useEffect(() => {
    if (newMessageSent === null) return;
    if (rowData.length !== 0 && newMessageSent === rowData[0]) return;
    // update data rows.
    setRowData([newMessageSent, ...rowData])

  }, [newMessageSent, rowData])

  // RENDERER DEFINITIONS
  const RecipientsRenderer = ({ valueFormatted }) => <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}> {valueFormatted}</div>

  const MessageContentRenderer = ({ value }) => {
    const handleClick = () => {
      setPreviewMessageContent(value)
    }
    return (
      <Button size="small" onClick={handleClick}>
        Show Message
      </Button>
    )
  }

  const ActionsCellRenderer = ({ data, node }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const handleRecall = () => {
      // TODO: populate this after backend is complete.
      gridRef.current.api.showLoadingOverlay()
      recallSystemMessage(data.id)
        .then((resp) => {
          data.status = StatusEnums.RECALLED
          data.recalled_timestamp = resp.data.recallSystemMessage.recalledTimestamp
          node.setData(data)
          gridRef.current.api.hideOverlay();
        })
        .catch(() => {
          gridRef.current.api.hideOverlay();
          // TODO: may be add snackbar here on failure.
        })

      handleClose()
    }

    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          data-testid="test-action"
          onClick={handleClick}
          size="large"
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          MenuListProps={{
            'aria-labelledby': 'long-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuItem data-testid="test-recall-opt" disabled={data.status === StatusEnums.RECALLED} onClick={handleRecall}>Recall Message</MenuItem>
        </Menu>
      </div>
    );
  }

  // --- DEFINITIONS END ----
  const gridOptions = {
    onFilterChanged: (params) => {
      if (params.api.getDisplayedRowCount() === 0) {
        params.api.showNoRowsOverlay();
      } else {
        params.api.hideOverlay();
      }
    },
    // NOTE: because a loader is added on full table. Even if the grid is ready
    // it is not visible on screen and throws a warning.
    // onGridReady: (params) => {
    //   params.api.sizeColumnsToFit();
    // },
    onFirstDataRendered: (params) => {
      params.api.sizeColumnsToFit();
    },
    components: {
      messageContentRenderer: MessageContentRenderer,
      actionsCellRenderer: ActionsCellRenderer,
      recipientsCellRenderer: RecipientsRenderer,
    },
    defaultColDef: {
      flex: 1,
      resizable: true,
      sortable: true,
      wrapText: true,
      minWidth: 100,
      lockPinned: true,
      suppressMenu: true,
      headerClass: [
        'text-align-center',
        'justify-content-center',
        'wrap-header',
      ],
      cellClass: ['centered-cell-content'],
      tooltipValueGetter: (data) => {
        const value = data.valueFormatted || data.value;
        return [null, undefined].includes(value) ? '' : value;
      },
    },
  }

  return (
    <div
      className="ag-theme-alpine"
      style={{ marginBottom: '32px', height: '100%', width: '100%' }}
      data-testid="test-system-message-grid"
    >
      {
        loading ? <CustomLoader loadingMessage=" Loading Table..." />
          : (
            <AgGridReact
              ref={gridRef} // Ref for accessing Grid's API
              rowData={rowData} // Row Data for Rows
              columnDefs={columnDefs} // Column Defs for Columns
              onCellClicked={cellClickedListener} // Optional - registering for Grid Event
              gridOptions={{ ...gridOptions }}
            />
          )
      }

    </div>
  );
};

export default SystemMessagesGrid;
