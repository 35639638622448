import { AgGridReact } from 'ag-grid-react';
import React from 'react';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

const TumorVolumeTable = function ({ gridOptions, divStyles }) {

  return (
    <div className="ag-theme-alpine" style={{ ...divStyles }}>
      <AgGridReact
        headerHeight="60"
        rowHeight="60"
        rowSelection="multiple"
        suppressRowClickSelection
        {...gridOptions}
      />
    </div>

  );
}

export default TumorVolumeTable;
