import React, {
  useState, useRef, useEffect,
} from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import {
  Form, Row, Col,
} from 'react-bootstrap'
import { CUSTOM_BOX_STYLE, EMAIL_DOMAINS_FOR_INTERNAL_USERS, PAGE_SIZE } from '../../../constants';

function AddAccountModal({
  companyAccounts,
  setShowResponseMessage, setShowLoader, setMessageType, showAddAccountModal, toggleAddAccountModal, toggleAddAccountAdminModal, setwhiteListedDomainsForAdminCheck, setCreatedCompanyValue, createCompanyAccount = () => {}, getCompanyNames = () => {}, getStudyIdentifierForCompanyName = () => {}, companyCreationConfirmed = () => {},
}) {

  const [companyName, setCompanyName] = useState([])
  const [studyIdentifiers, setStudyIdentifiers] = useState([])
  const [whitelistedDomains, setWhitelistedDomains] = useState([])
  const [whitelistedDomainsError, setwhitelistedDomainsError] = useState('')
  const [identifier, setIdentifier] = useState(true);
  const [createButton, setcreateButton] = useState(true);
  const [studyIdentifierList, setStudyIdentifiersList] = useState([])
  const [loadedCompanyNames, setLoadedCompanyNames] = useState([])
  const Domainref = useRef(null);
  const TypeaheadRef = useRef(null)
  const studyIdentifierListRef = useRef(null)

  const onHide = () => {
    setIdentifier(true);
    setStudyIdentifiersList([])
    setStudyIdentifiers([])
    studyIdentifierListRef.current.clear()
    setCompanyName([])
    Domainref.current.value = '';
    toggleAddAccountModal();
    setwhitelistedDomainsError('');
    setcreateButton(true);
  };

  const onAddAccount = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    const modifiedStudyIdentifiers = studyIdentifiers.map((val) => `C:${val.split(' ')[0]}`)
    setShowLoader(true)
    createCompanyAccount({ name: companyName[0], studyIdentifiers: modifiedStudyIdentifiers, whitelistedDomains: whitelistedDomains[0].split(',') }).then((response) => {
      setShowLoader(false)
      const res = response.data.createCompanyAccount
      if (res) {
        if (res.message) {
          // eslint-disable-next-line
          if (res.message === 'Company account has been successfully created.') {
            setMessageType('success')
            companyCreationConfirmed(companyName[0], modifiedStudyIdentifiers, whitelistedDomains)
            setCreatedCompanyValue(res.company_id);
            setwhiteListedDomainsForAdminCheck(whitelistedDomains);
            toggleAddAccountAdminModal();
          } else {
            setMessageType('duplicate')
          }
        } else {
          setMessageType('error')
        }
      } else {
        // eslint-disable-next-line
        setMessageType('error')
      }
      setShowResponseMessage(true)
    })
    onHide();
  }

  const loadCompanyNames = async (pageNumber, pageSize, searchString) => {
    const response = await getCompanyNames(pageNumber, pageSize, searchString)
    return response.data.getCompanyNames
  }

  const getStudyIdentifiers = async (companySelection) => {
    if (companySelection.length) {
      const response = await getStudyIdentifierForCompanyName(companySelection[0])
      return response.data.getStudyIdentifierForCompanyName
    }
    return []
  }

  // eslint-disable-next-line
  const addCompanyNamesToLoadedList = async () => {
    let names = await loadCompanyNames(0, 0, '')
    const createdCompanies = companyAccounts.map(({ name }) => name)
    names = names.filter((val) => !createdCompanies.includes(val))
    setLoadedCompanyNames([...loadedCompanyNames, ...names])
  }

  // eslint-disable-next-line
  const addStudyIdentifiersToLoadedList = async (companySelected) => {
    const studyIdentifiers = await getStudyIdentifiers(companySelected)
    setStudyIdentifiersList([...studyIdentifiers])
  }

  useEffect(() => {
    addCompanyNamesToLoadedList()
    // eslint-disable-next-line
  }, [])

  const whitelistedDomainsOnChange = (e) => {
    setWhitelistedDomains([e.target.value])
    let noValidationError = true
    if (e.target.value === '') {
      noValidationError = false
      setwhitelistedDomainsError('')
      setcreateButton(true)
    } else {
      const domains = e.target.value.split(',')
      domains.map((val) => {
        // eslint-disable-next-line no-useless-escape
        if (val.length === 0 || (!(/^@[a-zA-Z0-9 \-]+(\.[a-zA-Z0-9\-]+)*(\.[a-zA-Z]{2,})+$/.test(val)))) {
          setwhitelistedDomainsError('Improper format. Please enter the whitelist domain in proper format. Example: @yourcompany.com')
          setcreateButton(true)
          noValidationError = false
        } else if (EMAIL_DOMAINS_FOR_INTERNAL_USERS.includes(val)) {
          setwhitelistedDomainsError('Not allowed to use this email domain. Please use another email domain.')
          setcreateButton(true)
          noValidationError = false
        }
        return null
      })
    }
    if (noValidationError) {
      setwhitelistedDomainsError('')
      setcreateButton(false)
    }
  }

  return (
    <Modal open={showAddAccountModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box sx={CUSTOM_BOX_STYLE}>
        <div style={{ textAlign: 'center' }}>
          <span className="containerTitle" data-testid="container_Title">Add Account</span>
        </div>
        <Form onSubmit={onAddAccount}>
          <Row>
            <Col>
              <Row>
                <div style={{ width: '100%' }}>
                  <Form.Group
                    // controlId="formBasicSelect"
                    className="studyDetails-dataFilters-formGroup"
                    style={{ marginLeft: '10px' }}
                  >
                    <Form.Label className="addAccount-formLabel" data-testid="addAccount_formLabel">Account Name</Form.Label><span style={{ content: ' *', color: 'red' }}><sup>*</sup></span>
                    <Typeahead
                      onBlur={() => {
                        if (!companyName.length) {
                          TypeaheadRef.current.clear()
                        }
                      }}
                      className="typeahead-position-fix"
                      id="add-account-typeahead"
                      style={{ height: '45px', marginBottom: '-20px' }}
                      clearButton
                      onChange={(value) => {
                        setStudyIdentifiers([])
                        studyIdentifierListRef.current.clear()
                        if (value.length) {
                          setIdentifier(false)
                        } else {
                          setIdentifier(true)
                        }
                        setCompanyName(value)
                        addStudyIdentifiersToLoadedList(value)
                      }}
                      placeholder="Select Account Name"
                      options={loadedCompanyNames}
                      selected={companyName}
                      maxResults={PAGE_SIZE}
                      paginate
                      ref={TypeaheadRef}
                    />
                  </Form.Group>
                </div>
              </Row>
              <Row>
                <div style={{ width: '100%' }}>
                  <Form.Group
                    // controlId="formBasicSelect"
                    className="studyDetails-dataFilters-formGroup"
                    style={{ marginLeft: '10px' }}
                  >
                    <Form.Label className="addAccount-formLabel">Study Identifier</Form.Label><span style={{ content: ' *', color: 'red' }}><sup>*</sup></span>
                    <Typeahead
                      onBlur={() => {
                        if (!studyIdentifiers.length) {
                          studyIdentifierListRef.current.clear()
                        }
                      }}
                      className="typeahead-position-fix"
                      id="select-identifier-typeahead"
                      style={{ height: '45px', marginBottom: '-20px' }}
                      clearButton
                      onChange={(value) => {
                        setStudyIdentifiers(value)
                      }}
                      placeholder="Select Study Identifier"
                      options={studyIdentifierList}
                      selected={studyIdentifiers}
                      maxResults={PAGE_SIZE}
                      paginate
                      ref={studyIdentifierListRef}
                      disabled={identifier}
                    />
                  </Form.Group>
                </div>
              </Row>

              <Row>
                <div style={{ width: '100%' }}>

                  <Form.Group
                    // controlId="formBasicSelect"
                    className="studyDetails-dataFilters-formGroup"
                    style={{ marginLeft: '10px' }}
                  >
                    <Form.Label className="addAccount-formLabel" htmlFor="domainWhiteListing" data-testid="whitelisted_email_domain">Whitelisted Email Domain</Form.Label><span style={{ content: ' *', color: 'red' }}><sup>*</sup></span>
                    <Form.Control
                      id="domainWhiteListing"
                      data-testid="domainWhiteListing"
                      style={{ height: '45px', marginBottom: '-20px' }}
                      as="input"
                      defaultValue=""
                      placeholder="@1stdomain.com,@2nddomain.com,@3rddomain.com"
                      required
                      ref={Domainref}
                      onChange={(e) => whitelistedDomainsOnChange(e)}
                      disabled={identifier}
                    />
                    <p
                      style={{ color: '#E2AAA0', marginTop: '20px' }}
                      data-testid="email_error"
                    > {whitelistedDomainsError}
                    </p>
                  </Form.Group>
                </div>
              </Row>
            </Col>
          </Row>
          <Button
            className="LoginFormSignInButtonChangePassword"
            type="submit"
            variant="primary"
            disabled={createButton}
            data-testid="LoginFormSignInButtonChangePassword"
          >
            Confirm Account Detail

          </Button>

          <Button
            className="LoginFormOutlineButton"
            type="button"
            variant="light"
            onClick={onHide}
            data-testid="LoginFormOutlineButton"
          >
            Cancel
          </Button>
        </Form>

      </Box>
    </Modal>
  );
}

export default AddAccountModal;
