import React, { useState } from 'react';
import {
  EditableInput,
  Hue,
  Saturation,
  Alpha,
} from 'react-color/lib/components/common/';
import { CustomPicker } from 'react-color';

function ColorPicker(props) {
  const {
    hsl, hsv, hex, rgb, onChange,
  } = props;

  const [model, setModel] = useState('rgb');

  const onColorModelChange = (event) => {
    setModel(event.target.value);
  };

  return (
    <div style={{ position: 'relative', zIndex: 0 }}>
      <div
        style={{
          height: '200px',
          width: '90%',
          position: 'relative',
          float: 'left',
          zIndex: 1000000,
        }}
      >
        <Saturation hsl={hsl} hsv={hsv} onChange={onChange} />
      </div>
      <div
        style={{
          marginLeft: '10px',
          height: '200px',
          width: '10px',
          position: 'relative',
          float: 'left',
          zIndex: 1000000,
        }}
      >
        <Hue hsl={hsl} direction="vertical" onChange={onChange} />
      </div>
      <div className="inputs">
        <div className="input-group">
          <div className="input">
            <span className="label">Hex:</span>
            <EditableInput value={hex} onChange={onChange} />
          </div>
          <div className="input">
            <span className="label">Color Model:</span>
            <select
              value={model}
              onChange={onColorModelChange}
            >
              <option value="rgb">RGB</option>
              <option value="hsl">HSL</option>
              <option value="hsv">HSV</option>
            </select>
          </div>
        </div>
        {
          model === 'rgb'
          && (
            <div className="input-group">
              <div className="input">
                <span className="label">R:</span>
                <EditableInput value={rgb.r} onChange={onChange} />
              </div>
              <div className="input">
                <span className="label">G:</span>
                <EditableInput value={rgb.g} onChange={onChange} />
              </div>
              <div className="input">
                <span className="label">B:</span>
                <EditableInput value={rgb.b} onChange={onChange} />
              </div>
              <div className="input">
                <span className="label">A:</span>
                <EditableInput value={rgb.a} onChange={onChange} />
              </div>
            </div>
          )
        }
        {
          model === 'hsl'
          && (
            <div className="input-group">
              <div className="input">
                <span className="label">H:</span>
                <EditableInput value={hsl.h} onChange={onChange} />
              </div>
              <div className="input">
                <span className="label">S:</span>
                <EditableInput value={hsl.s} onChange={onChange} />
              </div>
              <div className="input">
                <span className="label">L:</span>
                <EditableInput value={hsl.l} onChange={onChange} />
              </div>
              <div className="input">
                <span className="label">A:</span>
                <EditableInput value={hsl.a} onChange={onChange} />
              </div>
            </div>
          )
        }
        {
          model === 'hsv'
          && (
            <div className="input-group">
              <div className="input">
                <span className="label">H:</span>
                <EditableInput value={hsv.h} onChange={onChange} />
              </div>
              <div className="input">
                <span className="label">S:</span>
                <EditableInput value={hsv.s} onChange={onChange} />
              </div>
              <div className="input">
                <span className="label">V:</span>
                <EditableInput value={hsv.v} onChange={onChange} />
              </div>
              <div className="input">
                <span className="label">A:</span>
                <EditableInput value={hsv.a} onChange={onChange} />
              </div>
            </div>
          )
        }
        <div className="input-group">
          <div className="input" style={{ clear: 'both', position: 'relative' }}>
            <span className="label">Transparency:</span>
            <div className="alpha">
              <Alpha
                {...props}
                direction="horizontal"
                onChange={onChange}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomPicker(ColorPicker);
