import React, { useState, useRef, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Container,
  Card,
  Button,
  Form,
} from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import TermsModalS from './modalStyled';
import { useAuth } from '../../../providers/auth';
import { useGraphQL } from '../../../providers/graphql';
import { useSQS } from '../../../providers/sqs';
import { AUDIT_TRAIL_EVENT_TYPES, BRAND_COLORS } from '../../../constants';
import { createMessageForSQS, getUserIpAddress } from '../../utils.createMessage';
import TermsContent from './constants';
import IconTermsandConditions from './IconTermsandConditions.svg';
import './styles.css';
import { ReactComponent as CheckBoxIconDesign } from '../auth/checkboxactive.svg';

const useStyles = makeStyles({
  card: {
    border: '0px',
  },
});

const TermsNConditions = function Terms() {

  const { getUserPreferences, updateorCreateTermsandConditions } = useGraphQL();
  const { user, signOut } = useAuth();
  const { sendMessageToQueueForAudits } = useSQS();

  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [tnc, setTNC] = useState(false);
  const [privacy, setPrivacy] = useState(false);
  const [dataShare, setDataShare] = useState(false);
  const [termsNConditions, setTermsNConditions] = useState(null);

  const modalContent = useRef({});
  const preferences = useRef({});
  const showLogout = useRef(false);
  const classes = useStyles();

  useEffect(() => {
    const getTermsNConditions = async () => {
      try {
        const pref = await getUserPreferences(user.id);
        // eslint-disable-next-line prefer-destructuring
        preferences.current = pref.data.preferencesForUser.items[0];
        setTermsNConditions(preferences.current?.terms);
        if (preferences.current?.sharePersonalData) {
          setDataShare(true);
        }
      // eslint-disable-next-line no-empty
      } catch (e) {
      }
    };
    if (!_.isEmpty(user)) {
      getTermsNConditions();
    }
    if (history.action === 'POP') {
      history.replace('/login');
    }
  }, [user, getUserPreferences, history]);

  const postAuditTrail = async (type) => {
    const {
      PERSONAL_DATA_SHARING_OPTIN,
      PERSONAL_DATA_SHARING_OPTOUT,
      TERMS_AND_CONDITIONS_VERSION,
      PRIVACY_NOTICE_VERSION,
    } = AUDIT_TRAIL_EVENT_TYPES;
    const attributes = [];
    let eventType = dataShare ? PERSONAL_DATA_SHARING_OPTIN : PERSONAL_DATA_SHARING_OPTOUT;
    if (type === 'terms') {
      eventType = TERMS_AND_CONDITIONS_VERSION;
      attributes.push({ termsVersion: TermsContent.terms.version });
    } else if (type === 'privacy') {
      eventType = PRIVACY_NOTICE_VERSION;
      attributes.push({ privacyVersion: TermsContent.privacy.version });
    }

    const messageBody = await createMessageForSQS({
      eventType,
      userId: user.id,
      projectId: '',
      companyName: '',
      attributes,
    });
    sendMessageToQueueForAudits(messageBody);
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    const ipAddress = await getUserIpAddress();
    const termsInput = {
      ipAddress,
      timestamp: moment().unix(),
      termsVersion: TermsContent.terms.version,
      privacyVersion: TermsContent.privacy.version,
    };
    try {
      await updateorCreateTermsandConditions(preferences.current?.id, termsInput, dataShare, user.id);
      postAuditTrail('datashare');
      postAuditTrail('terms');
      postAuditTrail('privacy');
      history.push('/landing');
    // eslint-disable-next-line no-empty
    } catch (error) {
    }
  };

  const handleOnChange = (e) => {
    const { name } = e.target;
    if (name === 'tnc') {
      setTNC(!tnc);
    } else if (name === 'privacy') {
      setPrivacy(!privacy);
    } else {
      setDataShare(!dataShare);
    }
  };

  const onHideModal = () => {
    showLogout.current = false;
    setShowModal(false);
  };

  const onShowModal = (key) => () => {
    modalContent.current = TermsContent[key];
    if (key === 'not_now') {
      showLogout.current = true;
    }
    setShowModal(true);
  };

  return (
    <div className="terms-ContainerParent">
      <Container fluid className="terms-container">
        <Card className={classes.card}>
          <Card.Header className="terms-CardHeader text-center text-md-left">
            <img className="landing-icon" src={IconTermsandConditions} alt="Terms and Conditions Page Title icon" />
            <span className="terms-PageTitle AvenirNextLTProRegular my-0 font-weight-normal">Terms and Conditions</span>
          </Card.Header>
          <Card.Body className="terms-CardBody">
            <Form className="terms-FormBody terms-form" onSubmit={(event) => handleOnSubmit(event)}>
              <Form.Group>
                <div className="rememberDeviceTC">
                  <FormControlLabel
                    className="terms-formsControlLabel"
                    control={(
                      <Checkbox
                        color="secondary"
                        disableRipple
                        checkedIcon={<CheckBoxIconDesign />}
                        id="tnc"
                        name="tnc"
                        value={tnc}
                        checked={tnc}
                        onChange={(event) => handleOnChange(event)}
                        sx={{
                          '& .MuiSvgIcon-root': {
                            fontSize: '24px',
                          },
                          padding: '0px',
                          color: '#C4C4C4',
                          borderRadius: '4px',
                          '&.Mui-checked': {
                            color: BRAND_COLORS.orange,
                          },
                        }}
                      />
                    )}
                    label={(
                      <Typography
                        className="terms-FormLabel AvenirNextLTProMedium"
                      >
                        I have read and accept the
                        <Button variant="link" className="terms-ModalLink AvenirNextLTProMedium modal-opener" onClick={onShowModal('terms')}> Terms and Conditions </Button>
                      </Typography>
                    )}
                  />
                </div>
              </Form.Group>
              <Form.Group>
                <div className="rememberDeviceTC">
                  <FormControlLabel
                    className="terms-formsControlLabel"
                    control={(
                      <Checkbox
                        color="secondary"
                        disableRipple
                        checkedIcon={<CheckBoxIconDesign />}
                        id="privacy"
                        name="privacy"
                        value={privacy}
                        checked={privacy}
                        onChange={(event) => handleOnChange(event)}
                        sx={{
                          '& .MuiSvgIcon-root': {
                            fontSize: '24px',
                          },
                          padding: '0px',
                          color: '#C4C4C4',
                          borderRadius: '4px',
                          '&.Mui-checked': {
                            color: BRAND_COLORS.orange,
                          },
                        }}
                      />
                    )}
                    label={(
                      <Typography
                        className="terms-FormLabel AvenirNextLTProMedium"
                      >
                        I have read and accept the
                        <Button variant="link" className="terms-ModalLink AvenirNextLTProMedium modal-opener" onClick={onShowModal('privacy')}> Privacy Notice </Button>
                      </Typography>
                    )}
                  />
                </div>
              </Form.Group>
              <Form.Group>
                <div className="rememberDeviceTC">
                  <FormControlLabel
                    className="terms-formsControlLabel"
                    control={(
                      <Checkbox
                        color="secondary"
                        disableRipple
                        checkedIcon={<CheckBoxIconDesign />}
                        id="dataShare"
                        name="dataShare"
                        value={dataShare}
                        checked={dataShare}
                        onChange={(event) => handleOnChange(event)}
                        sx={{
                          '& .MuiSvgIcon-root': {
                            fontSize: '24px',
                          },
                          padding: '0px',
                          color: '#C4C4C4',
                          borderRadius: '4px',
                          '&.Mui-checked': {
                            color: BRAND_COLORS.orange,
                          },
                        }}
                      />
                    )}
                    label={(
                      <Typography
                        className="terms-FormLabel AvenirNextLTProMedium"
                      >
                        I agree to share my personal data for the improvement of Crownlink
                      </Typography>
                    )}
                  />
                </div>
              </Form.Group>
              {
                !termsNConditions
                  ? (
                    <>
                      <Button variant="primary" type="submit" disabled={!(tnc && privacy)} className="terms-SubmitButton register action-button">Register</Button>
                      <Button variant="link" className="terms-BackButton back-to-signin action-button" onClick={() => signOut()}>Back to Sign In</Button>

                    </>
                  )
                  : (
                    <>
                      <Button type="submit" disabled={!(tnc && privacy)} className="terms-SubmitButton">Accept</Button>
                      <Button onClick={onShowModal('not_now')} className="terms-BackButton">Not now</Button>
                    </>
                  )
              }
            </Form>
          </Card.Body>
        </Card>
        {/* <TermsModal showModal={showModal} onHideModal={onHideModal} modalContent={modalContent.current} showLogout={showLogout.current} onLogout={() => signOut()} /> */}
        <TermsModalS showModal={showModal} onHideModal={onHideModal} modalContent={modalContent.current} showLogout={showLogout.current} onLogout={() => signOut()} />
      </Container>
    </div>

  );
}
export default TermsNConditions;
