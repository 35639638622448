import React from 'react';
import { Button, Row, Spinner } from 'react-bootstrap';
import { AgGridReact } from 'ag-grid-react';
import _ from 'lodash';
import TumorVolumeChart from './TumorVolumeChart';
import TumorVolumeTable from '../TumorVolumeTable';
import TumorGrowthInhibitionTable from '../TumorGrowthInhibitionTable';
import RadioList from './RadioList';
import { truncationOfLongStr } from '../utils'

const TumorVolumePanel = function TumorVolumePanel({
  acronym, model, studyId, tumorVolumeData, tumorVolumeSummaryGroups, individualTumorVolumeData, tumorVolumeGridOptions, tumorGrowthInhibitionGridOptions, tumorGrowthMeanInhibitionGridOptions, individualTumorVolumeGridOptions, tumorVolumeSummaryFetching, tumorVolumeIndividualFetching, showTable, dataViewChangeAudit, chartExportAudit, showTGITable, rltv,
}) {
  const spinner = (
    <Row className="justify-content-center">
      <Button variant="primary" disabled>
        <Spinner
          as="span"
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
        />
        Loading Tumor Volume Metrics...
      </Button>
    </Row>
  );

  const getPrefix = (rltvPrefix = '') => (rltvPrefix ? `${rltvPrefix} - ` : '')

  return (
    <RadioList
      auditCallback={dataViewChangeAudit}
      items={[
        {
          id: 'summary',
          label: 'Summary',
          content: (
            <div>
              <hr className="mlr-10" />
              <div style={tumorVolumeSummaryFetching ? { display: 'none' } : {}}>
                <div style={showTable && tumorVolumeData.length ? {} : { display: 'none' }}>
                  <Row
                    style={{
                      width: '100%',
                      height: '450px',
                      margin: 'auto',
                    }}
                    className="summary-chart"
                  >
                    <TumorVolumeChart
                      margin={{ r: 0, t: 40, pad: 0 }}
                      yaxisTitle="<b>Mean Tumor Volume (mm<sup>3</sup>)</b>"
                      title={`${getPrefix(rltv)}Mean Tumor Volume Graph (mm<sup>3</sup>)`}
                      xaxisTitle="<b>Study Day</b>"
                      data={tumorVolumeData}
                      divId="tumor-volume-mean-summary"
                      chartExportAudit={chartExportAudit('summary')}
                      groups={tumorVolumeSummaryGroups}
                      studyId={studyId}
                      modelId={model.study_id}
                      graphName={`${acronym}-summary`}
                      acronym={acronym}
                    />
                  </Row>
                  <hr className="mlr-10" />
                  <Row
                    style={{
                      width: '100%',
                      height: '350px',
                      margin: 'auto',
                    }}
                  >
                    <h4>{getPrefix(rltv)}Mean Tumor Volume Table (mm<sup>3</sup>)</h4>
                    {!_.isEmpty(tumorVolumeGridOptions) && (

                      <TumorVolumeTable
                        divStyles={{
                          height: '100%',
                          width: '100%',
                        }}
                        gridOptions={tumorVolumeGridOptions}
                      />
                    )}
                  </Row>
                  {
                    showTGITable
                    && (
                      <div>
                        <Row>
                          <div>
                            <hr className="mlr-10" />
                          </div>
                        </Row>
                        <div className="mtb-20" />
                        <Row
                          style={{
                            width: '100%',
                            height: '350px',
                            margin: 'auto',
                          }}
                        >
                          <h4>{getPrefix(rltv)}Tumor Growth Inhibition (Mean % Δ Inhibition)</h4>
                          {!_.isEmpty(tumorGrowthInhibitionGridOptions) && (

                            <TumorGrowthInhibitionTable
                              divStyles={{
                                height: '100%',
                                width: '100%',
                              }}
                              gridOptions={tumorGrowthInhibitionGridOptions}
                            />
                          )}
                        </Row>
                        <Row
                          style={{
                            width: '100%',
                            height: 'auto',
                            margin: 'auto',
                          }}
                        >
                          <div>
                            <br />
                            <br />
                            {/* potentially modify the M1 text to be lower-cased */}
                            Mean % Δ Inhibition = ((mean(C)-mean(C0)) - (mean(T)-mean(T0))) / (mean(C)-mean(C0)) * 100%<br />
                            T - treatment group value<br />
                            T0 - treatment group initial value<br />
                            C - control group value<br />
                            C0 - control group initial value
                          </div>
                        </Row>
                      </div>
                    )
                  }
                  {
                    showTGITable
                    && (
                      <div>
                        <Row>
                          <div>
                            <hr className="mlr-10" />
                          </div>
                        </Row>
                        <div className="mtb-20" />
                        <Row
                          style={{
                            width: '100%',
                            height: '350px',
                            margin: 'auto',
                          }}
                        >
                          <h4>{`${getPrefix(rltv)} Tumor Growth Inhibition (Mean % Inhibition)`}</h4>
                          {!_.isEmpty(tumorGrowthMeanInhibitionGridOptions) && (

                            <TumorGrowthInhibitionTable
                              divStyles={{
                                height: '100%',
                                width: '100%',
                              }}
                              gridOptions={tumorGrowthMeanInhibitionGridOptions}
                            />
                          )}
                        </Row>
                        <Row
                          style={{
                            width: '100%',
                            height: 'auto',
                            margin: 'auto',
                          }}
                        >
                          <div>
                            <br />
                            <br />
                            Mean % Inhibition = ((mean(C)-mean(T))/mean(C)) * 100%<br />
                            T - current group value<br />
                            C - control group value
                          </div>
                        </Row>
                      </div>
                    )
                  }
                </div>
                {/* error handle */}
                {(!showTable || !tumorVolumeData.length) && <div>No Data Available</div>}
              </div>
              {tumorVolumeSummaryFetching && spinner}
              <hr className="mlr-10" />
            </div>
          ),
        },
        {
          id: 'individual',
          label: 'Individual',
          content: (
            <div>
              <hr className="mlr-10" />
              <h5 className="text-center text-md-left">
                {getPrefix(rltv)}Absolute Tumor Volume (mm
                <sup>3</sup>
                ): Individual Measurements
              </h5>
              <div style={tumorVolumeIndividualFetching ? { display: 'none' } : {}}>
                <div style={!showTable || _.isEmpty(individualTumorVolumeData) ? { display: 'none' } : {}}>
                  <div
                    className="ag-theme-alpine"
                    style={{
                      width: '100%',
                      height: '450px',
                      margin: 'auto',
                    }}
                  >
                    {!_.isEmpty(individualTumorVolumeGridOptions) && <AgGridReact gridOptions={individualTumorVolumeGridOptions} />}
                  </div>

                  {!_.isEmpty(individualTumorVolumeData.chartValues)
                    && Object.entries(individualTumorVolumeData.chartValues).map(
                      (groupChartValues) => (
                        <Row
                          key={groupChartValues[0]}
                          style={{
                            height: '450px',
                            marginTop: '40px',
                          }}
                        >
                          <TumorVolumeChart
                            chartExportAudit={chartExportAudit('individual')}
                            margin={{
                              r: 20,
                              t: 40,
                              pad: 0,
                            }}
                            yaxisTitle="<b>Tumor Volume (mm<sup>3</sup>)</b>"
                            title={
                              `${getPrefix(rltv)}Individual Tumor Volume (mm<sup>3</sup>): ${
                                truncationOfLongStr(groupChartValues[0], 40)}`
                              }
                            xaxisTitle="<b>Study Day</b>"
                            data={groupChartValues[1]}
                            xaxisRange={
                                individualTumorVolumeData.xaxisRange
                              }
                            yaxisRange={
                                individualTumorVolumeData.yaxisRange
                              }
                            divId="individual-tumor-volumes"
                            studyId={studyId}
                            modelId={model.study_id}
                            graphName={`${acronym}-individual-${groupChartValues[0]}`}
                            acronym={acronym}
                          />
                        </Row>
                      ),
                    )}
                </div>
                {/* error handle */}
                {(!showTable || _.isEmpty(individualTumorVolumeData)) && <div>No Data Available</div>}
              </div>
              {tumorVolumeIndividualFetching && spinner}
              <hr className="mlr-10" />
            </div>
          ),
        },
      ]}
    />
  );
}

export default TumorVolumePanel;
