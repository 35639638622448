/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/styles';
import Divider from '@mui/material/Divider';
import IconStudaySummaryV1 from './IconStudaySummaryV1.svg';

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip disableInteractive {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#FFFBEC',
    color: '#222222',
    top: '20px',
    border: '1px solid #FEE7D5',
    borderRadius: '2px',
    fontSize: '12px',
  },
}));

const StudySummaryInfo1Line = function StudySummaryInfo1Line({ title, description }) {
  return (
    <>
      <Typography data-testid={`${title.toLowerCase().replace(' ', '-')}-title`} className="studyDetails-studySummary-textTrim1Line study-summ-content-hd AvenirNextLTProRegular">
        {title}
      </Typography>
      <LightTooltip
        className="navHeader-notifications-toolTips"
        title={(
          <>
            <Typography className="AvenirNextLTProRegular">
              {description}
            </Typography>
          </>
        )}
        placement="bottom-start"
      >
        <Typography className="study-summ-content AvenirNextLTProMedium">
          <span
            data-testid={`${title.toLowerCase().replace(' ', '-')}-desc`}
            className="studyDetails-studySummary-textTrim1Line"
          >
            {description || 'NA'}
          </span>
        </Typography>
      </LightTooltip>
    </>
  );
};

StudySummaryInfo1Line.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

const StudySummaryContent = function StudySummaryContent({ projectCode, study }) {
  return (
    <div className="study-summary-card">
      <div className="d-flex ln-spacing">
        <img src={IconStudaySummaryV1} alt="study summary icon" />
        <Typography data-testid="study-summary-text" className="card-head-study AvenirNextLTProMedium">
          Study Summary
        </Typography>
      </div>
      <Typography data-testid="project-information-text" className="card-head-sub AvenirNextLTProDemi">
        Project Information
      </Typography>

      <Box
        sx={{
          display: 'grid',
          gap: 1,
          gridTemplateColumns: 'repeat(3, 1fr)',
        }}
      >
        <CardContent className="card-mid-lt">
          <StudySummaryInfo1Line title="Company" description={study.company} />
        </CardContent>
        <CardContent className="card-mid-lt">
          <StudySummaryInfo1Line title="Project Code" description={projectCode} />
        </CardContent>
        <CardContent className="card-mid-rt">
          <StudySummaryInfo1Line title="Study Status" description={study.status} />
        </CardContent>
        <CardContent style={{ gridColumn: 'span 3' }} className="card-mid-rt">
          <StudySummaryInfo1Line title="Study Title" description={study.study_title} />
        </CardContent>
      </Box>

      <Divider className="div-sep-study" />

      <Typography data-testid="team-members-text" className="card-head-sub pad-tp-sub-head AvenirNextLTProDemi">
        Team Members
      </Typography>

      <Box
        sx={{
          display: 'grid',
          gap: 1,
          gridTemplateColumns: 'repeat(2, 1fr)',
        }}
      >
        <CardContent className="card-bel-lt">
          <StudySummaryInfo1Line title="Project Sponsor" description={study.sponsor} />
          <Typography sx={{ mb: 4.5 }} color="text.secondary" />
          <StudySummaryInfo1Line title="Business Development" description={study.business_director} />
        </CardContent>

        <CardContent className="card-bel-rt">
          <StudySummaryInfo1Line title="Study Director" description={study.director} />
          <Typography sx={{ mb: 4.5 }} color="text.secondary" />
          <StudySummaryInfo1Line title="Project Manager" description={study.project_manager} />
        </CardContent>
      </Box>
    </div>
  );
};

StudySummaryContent.propTypes = {
  projectCode: PropTypes.string.isRequired,
  study: PropTypes.shape({
    company: PropTypes.string.isRequired,
    project_code: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    site: PropTypes.string.isRequired,
    study_type: PropTypes.string.isRequired,
    model_id: PropTypes.string.isRequired,
    sponsor: PropTypes.string.isRequired,
    director: PropTypes.string.isRequired,
    project_manager: PropTypes.string.isRequired,
    business_director: PropTypes.string.isRequired,
    study_title: PropTypes.string.isRequired,
    models: PropTypes.arrayOf(PropTypes.shape({
      study_id: PropTypes.string.isRequired,
      study_type: PropTypes.string.isRequired,
      study_site: PropTypes.string.isRequired,
      study_status: PropTypes.string.isRequired,
      study_model: PropTypes.string.isRequired,
      director: PropTypes.shape({
        filters: PropTypes.any,
        name: PropTypes.string.isRequired,
        createdAt: PropTypes.string.isRequired,
        state: PropTypes.string.isRequired,
      }),
      juniorDirector: PropTypes.any,
    })),
  }),
};

StudySummaryContent.defaultProps = {
  study: {
    // Default values for study object properties
    company: '',
    project_code: '',
    status: '',
    site: '',
    study_type: '',
    model_id: '',
    sponsor: '',
    director: '',
    project_manager: '',
    business_director: '',
    study_title: '',
    models: [],
  },
};

export default StudySummaryContent;
