import React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import CircularProgress from '@mui/material/CircularProgress';
import { useGraphQL } from '../../../providers/graphql';
import { useAuth } from '../../../providers/auth';
import { useSnackBar } from '../../../providers/snackbar';
import { CUSTOM_BOX_STYLE } from '../../../constants';

const SubscribeStudyModal = function SubscribeStudy(props) {

  const [loading, setLoading] = React.useState(false);
  const { updateUserStudyVisited, subscribeForNotification } = useGraphQL();
  const { user } = useAuth();
  const { changeState } = useSnackBar();
  const {
    userInfo,
    projectCode,
    showSubscribeStudy,
    setSubscribeStudyModal,
  } = props;

  const onHide = async () => {
    const studyVisited = [...(userInfo.studyVisited || []), projectCode]
    await updateUserStudyVisited(user.id, studyVisited)
    setSubscribeStudyModal(false);
  }

  const onSubscribeStudy = async () => {
    setLoading(true)
    const studyVisited = [...(userInfo.studyVisited || []), projectCode]
    await subscribeForNotification(user, projectCode);
    await updateUserStudyVisited(user.id, studyVisited)
    setLoading(false)
    setSubscribeStudyModal(false);
    changeState('success', `You have successfully subscribed ${projectCode} study for notifications.`, true);
  }

  return (
    <Modal open={showSubscribeStudy} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box sx={CUSTOM_BOX_STYLE}>
        <span className="containerTitle">Subscribe for notifications</span>
        <p style={{ color: '#E2AAA0' }}>
          Do you want to subscribe this study for notifications.
        </p>
        <Button
          className="LoginFormSignInButtonChangePassword"
          type="button"
          onClick={onSubscribeStudy}
          variant="primary"
        >
          {loading && <CircularProgress size={20} style={{ marginRight: 10, color: 'white' }} />} Yes
        </Button>

        <Button
          className="LoginFormOutlineButton"
          type="button"
          variant="light"
          onClick={onHide}
        >
          No
        </Button>
      </Box>
    </Modal>
  );
};

export default SubscribeStudyModal;
