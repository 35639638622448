import React from 'react';
import {
  Button, Container, Row, Spinner,
} from 'react-bootstrap';
import './auth.css'

const LoadingSession = () => (
  <Container className="authContainer" fluid>
    <Row className="justify-content-center">
      <Button variant="primary" disabled>
        <Spinner
          as="span"
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
        />
        Loading Session...
      </Button>
    </Row>
  </Container>
)

export default LoadingSession;
