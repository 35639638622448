import React from 'react';
import { Button } from 'react-bootstrap';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  bgcolor: '#002334',
  border: '1px solid #002334',
  borderRadius: '0px 24px',
  p: 4,
};

function ConfirmRollBackModal({
  showModalForRollBack,
  toggleRollBackModal,
  model,
  onConfirmRollback,
  setRollBackMetaData,
  lastReleasedDate,
}) {
  const onCancel = () => {
    setRollBackMetaData(false)
    toggleRollBackModal()
  };

  return (
    <Modal
      open={showModalForRollBack}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{ ...style }}>
        <p
          style={{
            color: 'white',
            fontSize: '16px',
          }}
          data-testid="second-Confirmation-Title"
        >
          Please confirm that the study data for {model.study_model} should be rolled back to its previous state.
          <br />
          Last Released data timestamp: {lastReleasedDate}.
        </p>
        <Button
          style={{ backgroundColor: '#F8A131' }}
          type="submit"
          variant="primary"
          className="LoginFormOutlineButton"
          onClick={onConfirmRollback}
        >
          Confirm Rollback
        </Button>
        <Button
          type="button"
          variant="light"
          className="LoginFormOutlineButton"
          onClick={onCancel}
        >
          Cancel
        </Button>
      </Box>
    </Modal>
  );
}
export default ConfirmRollBackModal;
