/**
 * Default configurations for all plugins, for now only hyper link has configurations
 */
const defaultPluginsConfig = {
  hyperLinkConfig: {
    enableHyperLinkPlugin: false,
    showHyperLinkIcon: false,
    /**
     * Hyperlinks wont be highlighted in default if below function returns false
     */
    onValidateURL: () => false,
  },
}
export default defaultPluginsConfig
