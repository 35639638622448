/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@mui/material/Modal';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import StudySummaryContent from './StudySummaryContent';
import styles from './StudySummary/StudySummary.module.scss';

const StudySummary = function StudySummary({
  hasOncologyStudyDirector,
  isModalOpen,
  handleCloseModal,
  projectCode,
  study,
}) {
  return (
    <>
      {hasOncologyStudyDirector ? (
        <Modal
          open={isModalOpen}
          onClose={handleCloseModal}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          className="study-summary-modal-wrapper"
        >
          {/* TODO: Make this a reusable component */}
          <div className={styles.studySummaryModalContainer}>
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleCloseModal}
              aria-label="close"
              className={styles.studySummaryModalCloseButton}
            >
              <CloseIcon />
            </IconButton>
            <StudySummaryContent projectCode={projectCode} study={study} />
          </div>
        </Modal>
      ) : (
        <StudySummaryContent projectCode={projectCode} study={study} />
      )}
    </>
  );
};

StudySummary.propTypes = {
  hasOncologyStudyDirector: PropTypes.bool.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  projectCode: PropTypes.string.isRequired,
  study: PropTypes.shape({
    company: PropTypes.string.isRequired,
    project_code: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    site: PropTypes.string.isRequired,
    study_type: PropTypes.string.isRequired,
    model_id: PropTypes.string.isRequired,
    sponsor: PropTypes.string.isRequired,
    director: PropTypes.string.isRequired,
    project_manager: PropTypes.string.isRequired,
    business_director: PropTypes.string.isRequired,
    study_title: PropTypes.string.isRequired,
    models: PropTypes.arrayOf(
      PropTypes.shape({
        study_id: PropTypes.string.isRequired,
        study_type: PropTypes.string.isRequired,
        study_site: PropTypes.string.isRequired,
        study_status: PropTypes.string.isRequired,
        study_model: PropTypes.string.isRequired,
        director: PropTypes.shape({
          filters: PropTypes.any,
          name: PropTypes.string.isRequired,
          createdAt: PropTypes.string.isRequired,
          state: PropTypes.string.isRequired,
        }).isRequired,
        juniorDirector: PropTypes.any,
      }),
    ),
  }),
};

StudySummary.defaultProps = {
  study: {
    company: '',
    project_code: '',
    status: '',
    site: '',
    study_type: '',
    model_id: '',
    sponsor: '',
    director: '',
    project_manager: '',
    business_director: '',
    study_title: '',
    models: [],
  },
};

export default StudySummary;
