import React, {
  useState, useRef, useEffect, useCallback,
} from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { v4 as uuidv4 } from 'uuid';
import {
  Row, Col,
} from 'react-bootstrap';
import { S3Client, HeadObjectCommand, PutObjectCommand } from '@aws-sdk/client-s3';
import {
  FONT_FAMILY, CUSTOM_MODEL_DATA_MODAL_BOX_STYLE, US_SYSTEM_ADMIN_CONFIG, MODEL_DATA_FILE_TYPES,
} from '../../../constants';
import { useGraphQL } from '../../../providers/graphql';
import { useAuth } from '../../../providers/auth';
import { readFileContent, getCurrentFileLabel, convertFileContentToString } from './ModelData.utils';

function ModelDataModal({
  showModelDataModal,
  toggleModelDataModal,
}) {
  const [isLoading, setIsLoading] = useState(false);
  // This state saves the most recent metadata records for a given type. We currently only have one type, PDX.
  const [currentModelDataFiles, setCurrentModelDataFiles] = useState([]);
  // This state is used to identify the most recent file in the DB for the current model data type. This is metadata and not the actual file.
  const [mostRecentModelDataFileOnDb, setMostRecentModelDataFileOnDb] = useState(null);
  const [showStepper, setShowStepper] = useState(false);
  // This state is used to store the current model data file. The actual xlsx file which will be stored in the S3 bucket.
  const [currentFile, setCurrentFile] = useState(null);
  const [error, setError] = useState('');
  // This state is used to keep track of the current step in the model data upload process.
  const [counter, setCounter] = useState(1);
  const fileInputRef = useRef(null);
  const {
    getS3Credentials, createModelDataFileRecord, getCurrentModelDataFileRecordsByType, removeModelDataFileRecord,
  } = useGraphQL();
  const { user } = useAuth();

  /*
  Removes the most recent model data file from S3 and updates the database records.
  This function sets the hidden flag to true for the given model data file. This is a soft delete.
  It represents the file as removed, but does not actually remove the record from the database.
  However, the S3 file will be deleted.
  */
  const removeFile = async () => {
    if (mostRecentModelDataFileOnDb?.s3_file_id && !mostRecentModelDataFileOnDb?.hidden) {
      setIsLoading(true);
      const client = await getS3Client();

      const deletedObjectResponse = await client.deleteObject({
        Bucket: US_SYSTEM_ADMIN_CONFIG.BUCKET,
        Key: `admin/pdx/${mostRecentModelDataFileOnDb.filename}`,
        VersionId: mostRecentModelDataFileOnDb.s3_file_id,
      }).promise();

      if (deletedObjectResponse?.$response?.httpResponse?.statusCode === 204) {

        const res = await removeModelDataFileRecord(mostRecentModelDataFileOnDb.id);
        if (res?.data?.removeModelDataFileRecord?.data?.id) {
          await handleGetCurrentModelDataFileRecordsByType();
          setCurrentFile(null);
        }
      }
      setIsLoading(false);
    }
  };

  const getS3Client = async () => {
    const response = await getS3Credentials(null, false).catch(() => {});
    const config = response && response.data && response.data.getS3Credentials ? {
      credentials: {
        accessKeyId: response.data.getS3Credentials.AccessKeyId,
        secretAccessKey: response.data.getS3Credentials.SecretAccessKey,
        sessionToken: response.data.getS3Credentials.SessionToken,
      },
      Bucket: US_SYSTEM_ADMIN_CONFIG.BUCKET,
      region: US_SYSTEM_ADMIN_CONFIG.REGION,
    } : {};
    return new S3Client(config);
  };

  /**
  Handles the process of uploading a file to S3, creating metadata records in the database,
  and updating the currentModelDataFiles state.
  @param {ArrayBuffer} fileContent - The content of the file to be uploaded.
  @param {string} selectedFileName - The name of the selected file.
  @returns {Promise} - A promise that resolves to true if the process is successful, otherwise false.
  */
  const handleS3 = async (fileContent, selectedFileName) => {
    try {
      const client = await getS3Client();

      const folders = ['admin', 'pdx'];
      const filePath = folders.concat(selectedFileName).join('/');

      // Check if subfolders exist, create them if necessary
      await Promise.all(folders.map(async (folder, index) => {
        const currentFolder = folders.slice(0, index + 1).join('/');
        try {
          const input = {
            Bucket: US_SYSTEM_ADMIN_CONFIG.BUCKET,
            Key: currentFolder,
          }
          const command = new HeadObjectCommand(input);
          await client.send(command);
        } catch (error) {
          // Folder does not exist, create it
          const input = {
            Bucket: US_SYSTEM_ADMIN_CONFIG.BUCKET,
            Key: currentFolder,
          }
          const command = new PutObjectCommand(input);
          await client.send(command);
        }
      }));

      const input = {
        Bucket: US_SYSTEM_ADMIN_CONFIG.BUCKET,
        Key: filePath,
        Body: fileContent,
      }
      const command = new PutObjectCommand(input);
      const uploadedFile = await client.send(command);

      if (!uploadedFile?.ETag) {
        return false;
      }

      const headObjectResultCommand = new HeadObjectCommand({
        Bucket: US_SYSTEM_ADMIN_CONFIG.BUCKET,
        Prefix: filePath,
      });
      const headObjectResult = await client.send(headObjectResultCommand);

      // This gives the VersionId of the latest version since the list is sorted in reverse chronological order.
      const s3FileId = headObjectResult?.Versions?.[0]?.VersionId?.replace(/"/g, '') || '';

      if (!s3FileId || !user?.id) {
        return false;
      }

      const fileExtension = selectedFileName.slice(selectedFileName.lastIndexOf('.'));
      const fileContentAsString = await convertFileContentToString(fileContent, fileExtension);

      await createModelDataFileRecord({
        id: uuidv4(),
        filename: selectedFileName,
        type: 'PDX',
        subtype: MODEL_DATA_FILE_TYPES.PDX[counter - 1],
        s3_file_id: s3FileId,
        previous_file_id: mostRecentModelDataFileOnDb?.id || null,
        user_id: user.id,
        file_content: fileContentAsString,
        hidden: false,
      });

      await handleGetCurrentModelDataFileRecordsByType();

      return true;
    } catch (e) {
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  /**
  Handles the process of file upload after a user selects a file from their file system.
  It checks if the file is of the correct format, reads the content, and uploads it to the S3 bucket.
  @param {Event} event - The event object triggered when a file is selected.
  */
  const handleFileChange = async (event) => {
    const selectedFile = event?.target?.files?.[0];
    if (selectedFile) {
      const allowedFormats = ['.xlsx', '.xls', '.csv', '.list'];
      const fileExtension = selectedFile.name.slice(selectedFile.name.lastIndexOf('.') + 1).toLowerCase();

      if (!allowedFormats.includes(`.${fileExtension.toLowerCase()}`)) {
        setError('Invalid file format. Please upload a valid file.');
        return;
      }
      setIsLoading(true);
      // Read the content of the selected file
      const fileContent = await readFileContent(selectedFile);
      const didUpload = await handleS3(fileContent, selectedFile.name);

      if (didUpload) {
        setError('');
        setCurrentFile(selectedFile);
      } else {
        setError('Error upload. Please try again later.');
      }
    }
  };

  const handleGetCurrentModelDataFileRecordsByType = useCallback(async () => {
    try {
      const modelFiles = await getCurrentModelDataFileRecordsByType({
        type: 'PDX',
      });

      if (modelFiles?.data?.getCurrentModelDataFileRecordsByType?.data?.length) {
        setCurrentModelDataFiles(modelFiles.data.getCurrentModelDataFileRecordsByType.data || []);
      }
    } catch (error) {
      //
    }
  }, [getCurrentModelDataFileRecordsByType]);

  useEffect(() => {
    (async () => {
      await handleGetCurrentModelDataFileRecordsByType();
    })();
  }, [handleGetCurrentModelDataFileRecordsByType]);

  useEffect(() => {
    setIsLoading(true);
    const metadata = currentModelDataFiles.find((file) => (
      file.type === 'PDX' && file.subtype === MODEL_DATA_FILE_TYPES.PDX[counter - 1]
    ));
    setMostRecentModelDataFileOnDb(metadata?.id ? metadata : null);
    setIsLoading(false);
  }, [currentModelDataFiles, counter]);

  return (
    <Modal open={showModelDataModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box sx={CUSTOM_MODEL_DATA_MODAL_BOX_STYLE}>
        <div>
          <span className="containerTitle" data-testid="container_Title">Model Data</span>
        </div>
        <Row>
          {
            showStepper
              ? (
                <Col>
                  <Row>
                    <div className="modelDataRow">
                      <div>
                        PDX (Step {counter} of 4)
                      </div>
                      <div>
                        {getCurrentFileLabel(counter)}
                      </div>
                    </div>
                  </Row>

                  <Row>
                    <div className="modelDataRow">
                      <div className="file-info" title={currentFile?.name || mostRecentModelDataFileOnDb?.filename}>
                        Current File: {mostRecentModelDataFileOnDb?.hidden ? 'No File' : (currentFile?.name || mostRecentModelDataFileOnDb?.filename || 'No File')}
                      </div>
                      <div>
                        {/* Previous Version will be handled on a future ticket release */}
                        <button
                          type="button"
                          className="modelDataBtn"
                          onClick={() => {}}
                        >
                          Revert to Previous Version
                        </button>
                      </div>
                      <div>
                        {/* Remove File will be handled on a future ticket release */}
                        <button
                          type="button"
                          className="modelDataBtn"
                          onClick={removeFile}
                        >
                          Remove File
                        </button>
                      </div>
                    </div>
                  </Row>

                  <Row>
                    <div className="modelDataRow">
                      <div>
                        <input
                          type="file"
                          ref={fileInputRef}
                          style={{ display: 'none' }}
                          onChange={handleFileChange}
                        />
                        {
                          isLoading
                            ? (
                              <>
                                <div>
                                  <Typography className={`${FONT_FAMILY}-Regular`}>
                                    Processing...<CircularProgress size={20} />
                                  </Typography>
                                </div>
                              </>
                            ) : (
                              <button
                                type="button"
                                className="modelDataBtn"
                                onClick={() => {
                                  setError(null);
                                  if (fileInputRef.current) {
                                    fileInputRef.current.value = null;
                                  }
                                  fileInputRef.current.click();
                                }}
                              >
                                Upload a new File...
                              </button>
                            )
                        }
                        {error && <p className="modelDataError">{error}</p>}
                      </div>
                    </div>
                  </Row>

                  <Row>
                    <div className="modelDataBtnContainer">
                      <Button
                        disabled={isLoading}
                        className="modelDataBackNextBtn"
                        type="submit"
                        variant="primary"
                        data-testid="ModelDataBackBtn"
                        onClick={() => {
                          setError('');
                          setCurrentFile(null);
                          if (counter > 1) {
                            setCounter((prev) => prev - 1);
                          } else {
                            setShowStepper(false);
                          }
                        }}
                      >
                        Back
                      </Button>
                      <Button
                        disabled={isLoading}
                        className="modelDataBackNextBtn"
                        type="submit"
                        variant="primary"
                        data-testid="ModelDataBackNextBtn"
                        onClick={() => {
                          setError('');
                          setCurrentFile(null);
                          if (counter >= 4) {
                            setCounter(1);
                            toggleModelDataModal();
                            setShowStepper(false);
                          } else {
                            setCounter((prev) => prev + 1);
                          }
                        }}
                      >
                        {counter === 4 ? 'Finish' : 'Next'}
                      </Button>
                    </div>
                  </Row>
                </Col>
              )
              : (
                <Col>
                  <Row>
                    <div className="modelDataCloseBtnRow">
                      <div>
                        Uploaded data for...
                      </div>
                      <div>
                        <button
                          type="button"
                          className="modelDataBtn"
                          onClick={() => setShowStepper(true)}
                        >
                          PDX
                        </button>
                      </div>
                    </div>
                  </Row>

                  <Row>
                    <div className="modelDataCloseBtnContainer">
                      <Button
                        className="modelDataBackNextBtn"
                        type="submit"
                        variant="primary"
                        data-testid="ModelDataCloseBtn"
                        onClick={() => toggleModelDataModal()}
                      >
                        Close
                      </Button>
                    </div>
                  </Row>
                </Col>
              )
          }
        </Row>
      </Box>
    </Modal>
  );
}

export default ModelDataModal;
