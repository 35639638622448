export const getStudyDetailsProjectsData = /* GraphQL */ `
  query GetProjectStudiesData($projectCode: String, $userId: ID!) {

    getUser(id: $userId) {
      id
      email
      favorites
      notifications
      first_name
      last_name
      phone
      companyAccountID
      lastLoginAt
      createdAt
      updatedAt
    }

    getProjectStudiesWithDetails(projectRecordCode: $projectCode) {
      study_id
      study_type
      study_site
      study_status
      study_model

      director {
        filters
        name
        createdAt
        state
      }
      juniorDirector {
        filters
        name
        createdAt
        state
      }
    }
    
    getProjectRecord(projectCode: $projectCode) {
      code
      companies
      statuses
      sites
      study_types
      model_ids
      sponsors
      milestones {
        name
        forecast
        actual
      }
      study_directors
      project_managers
      business_directors
      study_title
      third_dimension
    }

  }
`;

export const getStudyDetailsData = /* GraphQL */ `
  query GetStudyDetailsData(
    $studyId: String
    $dataFilters: DataFilters
    $taskStatus: String
    $canPreview: Boolean!
    $isQCMetadataFetched: Boolean!
  ) {
    
    getQCMetadata(studyId: $studyId) @include(if: $isQCMetadataFetched) {
      studyId
      director {
        filters
        name
        createdAt
        state
      }
      juniorDirector {
        filters
        name
        createdAt
        state
      }
    }

    getPreviewDataAvailability(studyId: $studyId) @include(if: $canPreview) {
      preview
    }

    getLastModifiedDate(studyId: $studyId) @include(if: $canPreview) {
      last_modified_date
    }

    getStudyDataApprovalState(studyId: $studyId) {
      state
      dosing_begins
      study_begins
      roll_back_timeStamp
    }
    tumorVolumeTaskSessionAvailable : isTaskTypeSessionAvailable(studyId: $studyId, filters: {task_types:["TumorVolume"], task_names: ["Tumor Volume", "Right Tumor Volume", "Left Tumor Volume"]}) {
      is_task_session_available
    }

    tumorVolumeTaskMeasurementIndividual : getTaskMeasurementIndividual(
      studyId: $studyId
      taskType: "TumorVolume"
      dataFilters: $dataFilters
      taskStatus: $taskStatus
    ) {
      groups {
        group_id
        group_name
        dose_volume
        article
        group_name_to_show
        animals {
          subject_id
          subject_type
          temp_id
          fate_code
          fate_date
          fate_day
          staging_date
          mortality_date
          sessions {
            study_day
            study_date
            study_time
            value
          }
        }
      }
      legend {
        key
        value
      }
      task_name
    }

    bliTaskMeasurementIndividual : getTaskMeasurementIndividual(
      studyId: $studyId
      taskType: "TotalFlux"
      dataFilters: $dataFilters
      taskStatus: $taskStatus
    ) {
      groups {
        group_id
        group_name
        dose_volume
        article
        group_name_to_show
        animals {
          subject_id
          subject_type
          temp_id
          fate_code
          fate_date
          fate_day
          staging_date
          mortality_date
          sessions {
            study_day
            study_date
            study_time
            value
          }
        }
      }
      legend {
        key
        value
      }
      task_name
    }

    bodyWeightTaskMeasurementIndividual : getTaskMeasurementIndividual(
      studyId: $studyId
      taskType: "BodyWeight"
      dataFilters: $dataFilters
      taskStatus: $taskStatus
    ) {
      groups {
        group_id
        group_name
        dose_volume
        article
        group_name_to_show
        animals {
          subject_id
          subject_type
          temp_id
          fate_code
          fate_date
          fate_day
          staging_date
          mortality_date
          sessions {
            study_day
            study_date
            study_time
            value
          }
        }
      }
      legend {
        key
        value
      }
      task_name
    }

    bodyWeightChangeTaskMeasurementIndividual : getTaskMeasurementIndividual(
      studyId: $studyId
      taskType: "BodyWeightChange"
      dataFilters: $dataFilters
      taskStatus: $taskStatus
    ) {
      groups {
        group_id
        group_name
        dose_volume
        article
        group_name_to_show
        animals {
          subject_id
          subject_type
          temp_id
          fate_code
          fate_date
          fate_day
          staging_date
          mortality_date
          sessions {
            study_day
            study_date
            study_time
            value
          }
        }
      }
      legend {
        key
        value
      }
      task_name
    }

    mortalityObservationTaskMeasurementIndividual : getTaskMeasurementIndividual(
      studyId: $studyId
      taskType: "MortalityObservation"
      dataFilters: $dataFilters
      taskStatus: $taskStatus
    ) {
      groups {
        group_id
        group_name
        dose_volume
        article
        group_name_to_show
        animals {
          subject_id
          subject_type
          temp_id
          fate_code
          fate_date
          fate_day
          staging_date
          mortality_date
          sessions {
            study_day
            study_date
            study_time
            value
          }
        }
      }
      legend {
        key
        value
      }
      task_name
    }
    
    observationTaskMeasurementIndividual : getTaskMeasurementIndividual(
      studyId: $studyId
      taskType: "Observation"
      dataFilters: $dataFilters
      taskStatus: $taskStatus
    ) {
      groups {
        group_id
        group_name
        dose_volume
        article
        group_name_to_show
        animals {
          subject_id
          subject_type
          temp_id
          fate_code
          fate_date
          fate_day
          staging_date
          mortality_date
          sessions {
            study_day
            study_date
            study_time
            value
          }
        }
      }
      legend {
        key
        value
      }
      task_name
    }

    dosingTaskMeasurementIndividual : getTaskMeasurementIndividual(
      studyId: $studyId
      taskType: "Dosing"
      dataFilters: $dataFilters
      taskStatus: $taskStatus
    ) {
      groups {
        group_id
        group_name
        dose_volume
        article
        group_name_to_show
        animals {
          subject_id
          subject_type
          temp_id
          fate_code
          fate_date
          fate_day
          staging_date
          mortality_date
          sessions {
            study_day
            study_date
            study_time
            value
          }
        }
      }
      legend {
        key
        value
      }
      task_name
    }
    
  }
`;

export const getStudyChartsData = /* GraphQL */ `
  query GetStudyChartsData(
    $studyId: String
    $dataFilters: DataFilters
    $taskStatus: String


    $isGetTumorVolumeTaskMeasurementSummaryAPI: Boolean!
    $isGetTumorVolumeChangeTaskMeasurementSummaryAPI: Boolean!
    $isGetBliTaskMeasurementSummaryAPI: Boolean!
    $isGetBodyWeightTaskMeasurementSummaryAPI: Boolean!
    $isGetBodyWeightChangeTaskMeasurementSummaryAPI: Boolean!
          
    $isGetTumorVolumeTaskMeasurementIndividualAPI: Boolean!
    $isGetTumorVolumeChangeTaskMeasurementIndividualAPI: Boolean!
    $isGetBliTaskMeasurementIndividualAPI: Boolean!
    $isGetBodyWeightTaskMeasurementIndividualAPI: Boolean!
    $isGetBodyWeightChangeTaskMeasurementIndividualAPI: Boolean!
    $isGetMortalityObservationTaskMeasurementIndividualAPI: Boolean!
    $isGetObservationTaskMeasurementIndividualAPI: Boolean!
    $isGetDosingTaskMeasurementIndividualAPI: Boolean!
  ) {

    tumorVolumeTaskSessionAvailable : isTaskTypeSessionAvailable(studyId: $studyId, filters: {task_types:["TumorVolume"], task_names: ["Tumor Volume", "Right Tumor Volume", "Left Tumor Volume"]}) {
      is_task_session_available
    }

    tumorVolumeTaskMeasurementSummary : getTaskMeasurementSummary(
      studyId: $studyId
      taskType: "TumorVolume"
      dataFilters: $dataFilters
      taskStatus: $taskStatus
    ) @include(if: $isGetTumorVolumeTaskMeasurementSummaryAPI) {
      groups {
        group_id
        group_name
        group_name_to_show
        study_day_metrics {
          study_day
          study_date
          mean
          std
          sem
          count
          delta_inhibition
          mean_inhibition
        }
      }
      task_name
    }

    tumorVolumeChangeTaskMeasurementSummary : getTaskMeasurementSummary(
      studyId: $studyId
      taskType: "TumorVolumeChange"
      dataFilters: $dataFilters
      taskStatus: $taskStatus
    ) @include(if: $isGetTumorVolumeChangeTaskMeasurementSummaryAPI) {
      groups {
        group_id
        group_name
        group_name_to_show
        study_day_metrics {
          study_day
          study_date
          mean
          std
          sem
          count
          delta_inhibition
          mean_inhibition
        }
      }
      task_name
    }

    bliTaskMeasurementSummary : getTaskMeasurementSummary(
      studyId: $studyId
      taskType: "TotalFlux"
      dataFilters: $dataFilters
      taskStatus: $taskStatus
    ) @include(if: $isGetBliTaskMeasurementSummaryAPI) {
      groups {
        group_id
        group_name
        group_name_to_show
        study_day_metrics {
          study_day
          study_date
          mean
          std
          sem
          count
          delta_inhibition
        }
      }
      task_name
    }
    
    bodyWeightTaskMeasurementSummary : getTaskMeasurementSummary(
      studyId: $studyId
      taskType: "BodyWeight"
      dataFilters: $dataFilters
      taskStatus: $taskStatus
    ) @include(if: $isGetBodyWeightTaskMeasurementSummaryAPI) {
      groups {
        group_id
        group_name
        group_name_to_show
        study_day_metrics {
          study_day
          study_date
          mean
          std
          sem
          count
          delta_inhibition
          mean_inhibition
        }
      }
      task_name
    }

    bodyWeightChangeTaskMeasurementSummary : getTaskMeasurementSummary(
      studyId: $studyId
      taskType: "BodyWeightChange"
      dataFilters: $dataFilters
      taskStatus: $taskStatus
    ) @include(if: $isGetBodyWeightChangeTaskMeasurementSummaryAPI) {
      groups {
        group_id
        group_name
        group_name_to_show
        study_day_metrics {
          study_day
          study_date
          mean
          std
          sem
          count
          delta_inhibition
          mean_inhibition
        }
      }
      task_name
    }

    tumorVolumeTaskMeasurementIndividual : getTaskMeasurementIndividual(
      studyId: $studyId
      taskType: "TumorVolume"
      dataFilters: $dataFilters
      taskStatus: $taskStatus
    ) @include(if: $isGetTumorVolumeTaskMeasurementIndividualAPI) {
      groups {
        group_id
        group_name
        dose_volume
        article
        group_name_to_show
        animals {
          subject_id
          subject_type
          temp_id
          fate_code
          fate_date
          fate_day
          staging_date
          mortality_date
          sessions {
            study_day
            study_date
            study_time
            value
          }
        }
      }
      legend {
        key
        value
      }
      task_name
    }

    tumorVolumeChangeTaskMeasurementIndividual : getTaskMeasurementIndividual(
      studyId: $studyId
      taskType: "TumorVolumeChange"
      dataFilters: $dataFilters
      taskStatus: $taskStatus
    ) @include(if: $isGetTumorVolumeChangeTaskMeasurementIndividualAPI) {
      groups {
        group_id
        group_name
        dose_volume
        article
        group_name_to_show
        animals {
          subject_id
          subject_type
          temp_id
          fate_code
          fate_date
          fate_day
          staging_date
          mortality_date
          sessions {
            study_day
            study_date
            study_time
            value
          }
        }
      }
      legend {
        key
        value
      }
      task_name
    }

    bliTaskMeasurementIndividual : getTaskMeasurementIndividual(
      studyId: $studyId
      taskType: "TotalFlux"
      dataFilters: $dataFilters
      taskStatus: $taskStatus
    ) @include(if: $isGetBliTaskMeasurementIndividualAPI) {
      groups {
        group_id
        group_name
        dose_volume
        article
        group_name_to_show
        animals {
          subject_id
          subject_type
          temp_id
          fate_code
          fate_date
          fate_day
          staging_date
          mortality_date
          sessions {
            study_day
            study_date
            study_time
            value
          }
        }
      }
      legend {
        key
        value
      }
      task_name
    }

    bodyWeightTaskMeasurementIndividual : getTaskMeasurementIndividual(
      studyId: $studyId
      taskType: "BodyWeight"
      dataFilters: $dataFilters
      taskStatus: $taskStatus
    ) @include(if: $isGetBodyWeightTaskMeasurementIndividualAPI) {
      groups {
        group_id
        group_name
        dose_volume
        article
        group_name_to_show
        animals {
          subject_id
          subject_type
          temp_id
          fate_code
          fate_date
          fate_day
          staging_date
          mortality_date
          sessions {
            study_day
            study_date
            study_time
            value
          }
        }
      }
      legend {
        key
        value
      }
      task_name
    }

    bodyWeightChangeTaskMeasurementIndividual : getTaskMeasurementIndividual(
      studyId: $studyId
      taskType: "BodyWeightChange"
      dataFilters: $dataFilters
      taskStatus: $taskStatus
    ) @include(if: $isGetBodyWeightChangeTaskMeasurementIndividualAPI) {
      groups {
        group_id
        group_name
        dose_volume
        article
        group_name_to_show
        animals {
          subject_id
          subject_type
          temp_id
          fate_code
          fate_date
          fate_day
          staging_date
          mortality_date
          sessions {
            study_day
            study_date
            study_time
            value
          }
        }
      }
      legend {
        key
        value
      }
      task_name
    }

    mortalityObservationTaskMeasurementIndividual : getTaskMeasurementIndividual(
      studyId: $studyId
      taskType: "MortalityObservation"
      dataFilters: $dataFilters
      taskStatus: $taskStatus
    ) @include(if: $isGetMortalityObservationTaskMeasurementIndividualAPI) {
      groups {
        group_id
        group_name
        dose_volume
        article
        group_name_to_show
        animals {
          subject_id
          subject_type
          temp_id
          fate_code
          fate_date
          fate_day
          staging_date
          mortality_date
          sessions {
            study_day
            study_date
            study_time
            value
          }
        }
      }
      legend {
        key
        value
      }
      task_name
    }
    
    observationTaskMeasurementIndividual : getTaskMeasurementIndividual(
      studyId: $studyId
      taskType: "Observation"
      dataFilters: $dataFilters
      taskStatus: $taskStatus
    ) @include(if: $isGetObservationTaskMeasurementIndividualAPI) {
      groups {
        group_id
        group_name
        dose_volume
        article
        group_name_to_show
        animals {
          subject_id
          subject_type
          temp_id
          fate_code
          fate_date
          fate_day
          staging_date
          mortality_date
          sessions {
            study_day
            study_date
            study_time
            value
          }
        }
      }
      legend {
        key
        value
      }
      task_name
    }

    dosingTaskMeasurementIndividual : getTaskMeasurementIndividual(
      studyId: $studyId
      taskType: "Dosing"
      dataFilters: $dataFilters
      taskStatus: $taskStatus
    ) @include(if: $isGetDosingTaskMeasurementIndividualAPI) {
      groups {
        group_id
        group_name
        dose_volume
        article
        group_name_to_show
        animals {
          subject_id
          subject_type
          temp_id
          fate_code
          fate_date
          fate_day
          staging_date
          mortality_date
          sessions {
            study_day
            study_date
            study_time
            value
          }
        }
      }
      legend {
        key
        value
      }
      task_name
    }
    
  }
`;

export const getHeaderData = /* GraphQL */ `
  query GetHeaderData(
    $id: ID!
    ) {

    getUser(id: $id) {
      id
      email
      favorites
      notifications
      first_name
      last_name
      phone
      companyAccountID
      lastLoginAt
      createdAt
      updatedAt
    }

    listHelps {
      items {
        id
        helpText
        helpHtml
        addedBy
        createdAt
        updatedAt
        user {
          id
          email
          favorites
          notifications
          first_name
          last_name
          phone
          companyAccountID
          lastLoginAt
          createdAt
          updatedAt
        }
      }
      nextToken
    }

  }
`;

export const getUserSystemMessages = /* GraphQL */ `
  query GetUserSystemMessages {
    getUserSystemMessages {
      id
      userId
      messageId
      status
      message {
        messageContent
        createdAt
      }
    }
  }
`;

export const listSystemMessages = /* GraphQL */ `
  query ListSystemMessages {
    listSystemMessages {
      id
      recipients
      date: createdAt
      recalled_timestamp: recalledTimestamp
      sentBy
      content: messageContent
      status
      sender: user {
        email
        first_name
        last_name
      }
    }
  }
`;

export const createSystemMessage = /* GraphQL */ `
  query CreateSystemMessage($message: SystemMessagesInput) {
    createSystemMessage(message: $message) {
      id
      sent_by
      status
      sent_timestamp
      recalled_timestamp
      message_content
      recipients
      user {
        id
        email
        first_name
        last_name
      }
    }
  }
`;
