/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-param-reassign */
import React, {
  useEffect, useState, useRef, useCallback,
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash'
import moment from 'moment';
import {
  Button as RBbtn, Row, Spinner,
} from 'react-bootstrap'
import { makeStyles, styled } from '@mui/styles';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FormControl from '@mui/material/FormControl';
import CircularProgress from '@mui/material/CircularProgress';
import TablePagination from '@mui/material/TablePagination';
import { IconButton } from '@mui/material';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { useReactToPrint } from 'react-to-print';

import DownloadCommentsModal from './DownloadCommentsModalMutiSelect';
import { useSQS } from '../../../providers/sqs';
import { useAuth } from '../../../providers/auth';
import './Comments.css'

// Icons

import 'draft-js/dist/Draft.css';
import { useGraphQL } from '../../../providers/graphql';
import { Radio } from '../RadioList';
import { isJson } from '../../utils';
import { createMessageForSNS, createMessageForSQS } from '../../utils.createMessage';
import { useSNS } from '../../../providers/sns';
import {
  NOTIFICATION_EVENT_TYPE,
  COMMENT_TYPES,
  DATA_COLLECTION_EVENT_TYPES,
  GROUPS,
  BRAND_COLORS,
} from '../../../constants';
import IconComment from './IconCommentV1.svg';
import CommentsGrid from './CommentsGrid';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '98%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 600,
    /* marginLeft: '12px',
    marginRight: '12px', */
  },
  selectTableCell: {
    width: 60,
  },
  tableCell: {
    width: '12%',
  },
  commentCell: {
    width: '56%',
  },
  commentP: {
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    marginBottom: '0px',
  },
  actionCell: {
    width: '8%',
  },
  input: {
    width: '100%',
  },
}));

const LightTooltip = styled(({ className, ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Tooltip {...props} classes={{ popper: className }} disableInteractive />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#FFFBEC',
    color: '#222222',
    top: '20px',
    border: '1px solid #FEE7D5',
    borderRadius: '2px',
    fontSize: '12px',
  },
}));

const CommentsTable = function Comments(props) {
  const commentsGridRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => commentsGridRef.current,
    onAfterPrint: () => {
      setRowsPerPage(rowsPerPage)
      setShowSpinnerWhileDownloadingComments(false);
      setSelectedModel(copiedSelectedModel)
    },
  });
  const [showSpinnerWhileDownloadingComments, setShowSpinnerWhileDownloadingComments] = useState(false)
  const { sendMessageToSNSTopic } = useSNS();
  let copiedSelectedModel = {}
  // this function gets called by several other function
  // this function is responsible for converting simple string value into
  // plate compatible input
  // if the 'value' is null, it will assign text to empty so that empty RTE can be created
  const getEmptyOrInitializedNotes = (value) => {
    let resolvedValue = '';
    // eslint-disable-next-line no-prototype-builtins
    if (value && value?.hasOwnProperty(Object.keys(value)[0]) && (value?.notes || value?.note)) {
      resolvedValue = value?.notes ?? value?.note
    } else if (value && Object.keys(value).length === 0) {
      resolvedValue = value
    }

    return [{
      type: 'p',
      children: [
        {
          text: resolvedValue,
        },
      ],
    }]
  };

  const {
    getComment,
    getAllComments,
    createNewComment,
    user: userDetails,
    updateComment,
  } = useGraphQL();

  const { sendMessageToQueueForAnalytics } = useSQS();
  const { user, hasGroup } = useAuth();
  const canEditInternal = hasGroup([GROUPS.STUDY_DIRECTOR, GROUPS.BUSINESS_DEVELOPMENT, GROUPS.JUNIOR_STUDY_DIRECTOR, GROUPS.BIOMARKER]);
  const canViewInternal = hasGroup([GROUPS.STUDY_DIRECTOR, GROUPS.BUSINESS_DEVELOPMENT, GROUPS.JUNIOR_STUDY_DIRECTOR, GROUPS.BIOMARKER]);
  const canEditExternal = hasGroup([GROUPS.EXTERNAL, GROUPS.STUDY_DIRECTOR, GROUPS.BUSINESS_DEVELOPMENT, GROUPS.BIOMARKER]);
  const canViewExternal = hasGroup([GROUPS.STUDY_DIRECTOR, GROUPS.BUSINESS_DEVELOPMENT, GROUPS.JUNIOR_STUDY_DIRECTOR, GROUPS.EXTERNAL, GROUPS.BIOMARKER, GROUPS.GENERAL_ACCESS, GROUPS.BIOMARKER]);
  const [rows, setRows] = useState([]);
  const [previous, setPrevious] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [commentType, setCommentType] = useState(canViewExternal ? COMMENT_TYPES.EXTERNAL : COMMENT_TYPES.INTERNAL);
  const [loading, setLoading] = useState(false);
  const [commentsModels, setCommentsModels] = useState([]);
  const [selectedModel, setSelectedModel] = useState({ value: 'ByModel', key: 'ByModel' });
  const [disableFilter, setdisableFilter] = useState(true);
  const [commentsLoading, setCommentsLoading] = useState(false);
  const [inEditeMode, setInEditeMode] = useState(false);
  const [showResponseMessage, setShowResponseMessage] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [snackbarMessage, setSnackbarMessage] = useState('None')
  const [showModal, setShowModal] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [commentsModelsDownloadFilterComments, setcommentsModelsDownloadFilterComments] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [showDropdown, setShowDropdown] = useState('DownloadAllComments')
  const currentUser = useRef(null);
  const classes = useStyles();
  const {
    study,
    model,
    projectCode,
    toggleCommentsUpdate,
    modelsDataLoading,
  } = props;

  const getModel = (modelID) => {
    if (modelID === 'general') {
      return 'General';
    }
    const resultModel = study.models.find((m) => m.study_id === modelID);
    if (resultModel) {
      return resultModel.study_model;
    }
    return '';
  }
  const showSnackbarForDownloadComments = (messageAlert) => {
    const message = {
      success: (
        <div className="LoginForm-SuccessMessage successText">
          <div className="LoginForm-SuccessMessageText Mng-not-msg-txt">Your data is being prepared. You will receive an email with the download link once your data is ready.</div>
        </div>
      ),
      error: (
        <div className="LoginForm-ErrorMessage errorText">
          <div className="LoginForm-ErrorMessageText Mng-not-msg-txt">Error.  Please try again.</div>
        </div>
      ),
    }
    return (
      <Stack sx={{ width: '100%' }} spacing={2}> {message[messageAlert]} </Stack>
    )
  }
  const toggleUserDownloadCommentsModal = () => setShowModal(!showModal)

  const formateUpdatedAt = (date) => {
    const today = moment().endOf('day');
    const yesterday = moment().subtract(1, 'day').endOf('day');
    const dayBeforeYesterday = moment().subtract(2, 'day').endOf('day');
    const updatedDate = moment(date);
    const time = moment(date).local().format('hh:mm A');
    if (updatedDate < today && updatedDate > yesterday) {
      return `Today at ${time}`;
    }
    if (updatedDate < yesterday && updatedDate > dayBeforeYesterday) {
      return `Yesterday at ${time}`;
    }
    return moment(date).local().format('Do MMM YYYY [at] hh:mm A');
  };

  const parseNoteToPlateObject = (row) => {
    /*
   we fetched data from backend, there is a possibility that notes (comment) may not be compatible with
   plate input object, so lets transform it for plate
   */
    let notes;
    if (isJson(row?.note)) {
      notes = JSON.parse(row.note);
    } else {
      notes = getEmptyOrInitializedNotes(row);
    }

    return notes
  }

  const fetchComments = useCallback(async (type = COMMENT_TYPES.EXTERNAL, model = selectedModel) => {
    if (!study) return; // return early if study is not available yet
    setdisableFilter(true);
    setCommentsLoading(true);

    try {
      const result = await getAllComments(projectCode, type);
      const { items } = result.data.commentsByProjectAndType;
      const displayRows = items.map((item) => ({
        id: item.id,
        createdAt: moment(item.createdAt).local().format('DD MMM YYYY hh:mm A'),
        createdAtOriginal: item.createdAt,
        updatedAt: formateUpdatedAt(item.updatedAt),
        reviewer: item.user ? `${item.user.first_name} ${item.user.last_name}` : 'Not Available',
        modelID: item.modelID,
        prjectID: item.projectID,
        commentedBy: item.commentedBy,
        isAddMode: false,
        isEditMode: false,
        notes: parseNoteToPlateObject(item),
        isEdited: item.createdAt !== item.updatedAt,
      }));
      const sortedRows = displayRows.sort((row1, row2) => new Date(row2.createdAtOriginal) - new Date(row1.createdAtOriginal));
      const newList = [];
      const uniqueModelIds = new Set();

      for (let i = 0; i < sortedRows.length; i += 1) {
        if (!uniqueModelIds.has(sortedRows[i].modelID)) {
          uniqueModelIds.add(sortedRows[i].modelID);
          newList.push({ model: getModel(sortedRows[i].modelID), studyId: sortedRows[i].modelID });
        }
      }
      setCommentsModels(newList);
      setcommentsModelsDownloadFilterComments(newList)

      if (model.value !== 'ByModel') {
        setRows(sortedRows.filter((obj) => obj.modelID === model.value))
        setdisableFilter(false);
      } else {
        setRows(sortedRows);
        setdisableFilter(true);

      }
    // eslint-disable-next-line no-empty
    } catch (e) {
    }
    setCommentsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectCode, getAllComments, selectedModel, study]);

  useEffect(() => {
    if (!modelsDataLoading) {
      fetchComments(commentType);
    }
  }, [fetchComments, commentType, toggleCommentsUpdate, study, modelsDataLoading]);

  useEffect(() => {
    if (userDetails) {
      currentUser.current = userDetails;
    }
  }, [userDetails]);

  const onToggleEditMode = (id, copyRow = false) => {
    setRows(() => (
      rows.map((row) => {
        if (row.id === id) {
          // ignore following line because eslint is not understanding object.assign
          // eslint-disable-next-line prefer-const
          let rowToReturn = { ...row, isEditMode: row.isAddMode ? false : !row.isEditMode, isAddMode: false };
          if (copyRow) {
            Object.assign(rowToReturn, { copied: JSON.parse(JSON.stringify(row)) })
          }
          return rowToReturn
        }
        return row;
      })
    ));
  };

  const onRevert = (id) => {
    const newRows = rows.map((row) => {
      if (row.id === id) {
        return previous[id] ? previous[id] : row;
      }
      return row;
    });
    setRows(newRows);
    setPrevious((state) => {
      delete state[id];
      return state;
    });
    onToggleEditMode(id);
  };

  const onCancel = (id) => () => {
    const result = rows.find((row) => row.isAddMode);
    if (result) {
      const newRows = rows.filter((row) => !row.isAddMode);
      setRows(newRows);
    } else {
      onRevert(id);
    }

    if (!result) {
      const revertedRows = rows.map((r) => (r?.copied ? JSON.parse(JSON.stringify(r.copied)) : r))
      setRows(revertedRows)
    }

    setInEditeMode(false)
  };

  const onSave = async (savedRow) => {
    const result = rows.find((row) => row.id === savedRow.id);
    setLoading(true);
    const notes = JSON.stringify(savedRow.notes)
    if (result.isAddMode && savedRow.modelID !== '') {
      try {
        const newRow = await createNewComment(savedRow.id, savedRow.modelID, projectCode, user.id, notes, commentType);
        const attributesForNotification = [{ key: 'isInternal', value: commentType === COMMENT_TYPES.INTERNAL }]
        if (savedRow.modelID !== 'general') {
          attributesForNotification.push({ key: 'modelId', value: getModel(savedRow.modelID) })
        } else {
          attributesForNotification.push({ key: 'modelId', value: 'general' })
        }
        const notificationData = createMessageForSNS(`${currentUser.current.first_name} ${currentUser.current.last_name}`, study.company, projectCode, NOTIFICATION_EVENT_TYPE.REDIRECT_TO_DASHBOARD.NEW_COMMENT, user.id, attributesForNotification);
        sendMessageToSNSTopic(notificationData);
        // MESSAGE FOR SQS
        const commentEventType = commentType === 'internal' ? DATA_COLLECTION_EVENT_TYPES.NEW_COMMENT_INTERNAL : DATA_COLLECTION_EVENT_TYPES.NEW_COMMENT_EXTERNAL
        createMessageForSQS({
          eventType: commentEventType,
          userId: user.id,
          projectId: projectCode,
          companyName: study.company,
        }).then((res) => {
          sendMessageToQueueForAnalytics(res)
        })
        savedRow.createdAt = moment(newRow.data.createComment.createdAt).local().format('DD MMM YYYY hh:mm A');
        savedRow.createdAtOriginal = newRow.data.createComment.createdAt;
        savedRow.updatedAt = formateUpdatedAt(newRow.data.createComment.updatedAt);
        savedRow.commentedBy = newRow.data.createComment.commentedBy;
        savedRow.isEdited = false;
        onToggleEditMode(savedRow.id);
      // eslint-disable-next-line no-empty
      } catch (error) {
      }
    } else if (result.isEditMode && savedRow.modelID !== '') {
      try {
        const comment = await getComment(savedRow.id);
        const commentData = comment.data.getComment;
        await createNewComment(uuidv4(), commentData.modelID, commentData.projectID, user.id, notes, commentData.commentType, savedRow.id)
        const updatedRow = await updateComment(savedRow.id, savedRow.modelID, projectCode, user.id, notes, commentType);
        savedRow.updatedAt = formateUpdatedAt(updatedRow.data.updateComment.updatedAt);
        savedRow.isEdited = true;
        onToggleEditMode(savedRow.id);
      // eslint-disable-next-line no-empty
      } catch (error) {
      }
    }
    setLoading(false);
    if (savedRow.modelID !== '') {
      fetchComments(commentType);
    }
    setInEditeMode(false)
  };

  const onChange = (e, row) => {
    if (!previous[row.id]) {
      setPrevious((state) => ({ ...state, [row.id]: row }));
    }
    const { value } = e.target;
    const { name } = e.target;
    const { id } = row;
    const newRows = rows.map((r) => {
      if (r.id === id) {
        const currentRow = { ...r, [name]: value };
        return currentRow
      }
      return r;
    });
    setRows(newRows);
  };

  const onEdit = (row) => () => {
    setInEditeMode(true)
    onToggleEditMode(row.id, true);
  };

  const addNewComment = () => {
    const result = rows.find((row) => row.isAddMode);
    let modelForNew;
    if (selectedModel.value !== 'ByModel') {
      modelForNew = selectedModel.value
    } else {
      modelForNew = '';
    }
    if (!result) {
      // can edit or create only one comment
      const newRow = {
        id: uuidv4(),
        createdAt: '',
        model: model ? model.study_model : 'General',
        modelID: modelForNew,
        reviewer: `${currentUser.current.first_name} ${currentUser.current.last_name}`,
        notes: getEmptyOrInitializedNotes(),
        isAddMode: true,
      };
      const newRows = rows.map((row) => {
        row.isEditMode = false;
        return row;
      });
      newRows.unshift(newRow);
      setRows(newRows);
    }
    setInEditeMode(true)
  };

  const onDeleteComment = (commentId) => async () => {
    try {
      const comment = await getComment(commentId);
      const data = comment.data.getComment;
      await updateComment(data.id, data.modelID, data.projectID, data.commentedBy, data.note, data.commentType, (new Date()).toISOString());
      const newRows = _.reject(rows, (row) => row.id === commentId);
      setRows(newRows);
      fetchComments(commentType);
    // eslint-disable-next-line no-empty
    } catch (error) {
    }
  };
  const OnDownloadComment = () => {
    setShowSpinner(true)
    setShowModal(true)
  };

  const onConfirmDownloadComment = async () => {
    copiedSelectedModel = { ...selectedModel }
    setShowSpinnerWhileDownloadingComments(true);
    const result = await getAllComments(projectCode, commentType);
    const { items } = result.data.commentsByProjectAndType;
    const displayRows = items.map((item) => ({
      id: item.id,
      createdAt: moment(item.createdAt).local().format('DD MMM YYYY hh:mm A'),
      createdAtOriginal: item.createdAt,
      updatedAt: formateUpdatedAt(item.updatedAt),
      reviewer: item.user ? `${item.user.first_name} ${item.user.last_name}` : 'Not Available',
      modelID: item.modelID,
      prjectID: item.projectID,
      commentedBy: item.commentedBy,
      isAddMode: false,
      isEditMode: false,
      notes: parseNoteToPlateObject(item),
      isEdited: item.createdAt !== item.updatedAt,
    }));
    const sortedRows = displayRows.sort((row1, row2) => new Date(row2.createdAtOriginal) - new Date(row1.createdAtOriginal));

    setRowsPerPage(200);

    if (showDropdown === 'DownloadAllCommentsfilter') {
      const found = selectedValues.map((currItem) => sortedRows.filter((f) => f.modelID === currItem.studyId))
      setRows(found.flat())
    } else {
      setRows(sortedRows)
    }

    toggleUserDownloadCommentsModal()
    setShowResponseMessage(true);
    setShowSpinner(false);
    setShowSpinnerWhileDownloadingComments(false)
    setSelectedValues([])
    setShowDropdown('DownloadAllComments')

    setTimeout(() => {
      handlePrint()
    }, 0);
  };

  const onCancelDownloadComments = () => {
    setSelectedValues([])
    setShowDropdown('DownloadAllComments')
    toggleUserDownloadCommentsModal();
    setShowSpinnerWhileDownloadingComments(false);
    setShowSpinner(false)
  };

  const onPageChange = (event, changedPage) => {
    setPage(changedPage);
  };

  const onRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onCommentTypeChange = (type) => () => {
    setSelectedModel({ value: 'ByModel', key: 'ByModel' });
    setCommentType(type);
    // Umer: why below function is being used like 'fire and forget' ?
    fetchComments(type, { value: 'ByModel', key: 'ByModel' });
  };

  const handleChange = (event) => {
    setSelectedModel({ value: event.target.value, key: getModel(event.target.value) });
    if (event.target.value === 'ByModel') {
      setdisableFilter(true);
    } else {
      setdisableFilter(false);
    }
  }

  const canViewCommentToggle = () => canViewInternal;

  const canEditComment = () => (commentType === COMMENT_TYPES.EXTERNAL && canEditExternal) || (commentType === COMMENT_TYPES.INTERNAL && canEditInternal);

  // eslint-disable-next-line no-unused-vars
  const ColorButton = styled(Button)(({ theme }) => ({
    color: '#222222',
    backgroundColor: '#FFFFFF',
    border: `1px solid ${BRAND_COLORS.orange}`,
    borderRadius: '4px',
    marginRight: '12px',
    fontFamily: 'AvenirNextLTPro-Medium',
    '&:hover': {
      backgroundColor: '#F9A868',
      borderRadius: '4px',
      border: '1px solid transparent',
      fontFamily: 'AvenirNextLTPro-Medium',
    },
  }));

  // eslint-disable-next-line no-unused-vars
  const CustomTablePagination = styled(TablePagination)(({ theme }) => ({
    '& .MuiTablePagination-selectIcon': {
      top: '0px',
    },
    '& .MuiTablePagination-displayedRows': {
      fontFamily: 'AvenirNextLTPro-Regular',
      color: '#222222',
    },
    '& .MuiTablePagination-actions': {
      marginTop: '-10px',
      marginLeft: '16px',
    },
    '& .MuiTablePagination-toolbar': {
      display: 'flex',
      alignItems: 'center',
      paddingTop: '20px',
    },
  }));

  const FilterOnClick = () => {
    setSelectedModel({ value: 'ByModel', key: 'ByModel' });
    setdisableFilter(true);
    fetchComments(commentType, { value: 'ByModel', key: 'ByModel' });
  };

  // const getJSONForNotes = () => JSON.stringify(currentCommentBeingEdited.notes)

  return (
    <>
      <Grid
        className="studyDetails-commentsHeader"
        container
        direction="row"
        justifyContent="space-between"
      >
        <Grid
          justifyContent="flex-start"
          alignItems="flex-start"
          sx={{ paddingLeft: '12px' }}
        >
          <img src={IconComment} alt="comments icon" />
          <Typography
            display="inline"
            data-testid="comments-heading-text"
            className="card-head-study AvenirNextLTProMedium"
          >
            Comments
          </Typography>
        </Grid>
        <Grid
          justifyContent="flex-end"
          alignItems="flex-end"
        >
          {
            modelsDataLoading
              ? (
                <div className="loading-container">
                  <RBbtn variant="primary" disabled>
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    Loading Model(s)...
                  </RBbtn>
                </div>
              )
              : (
                <div className="loading-container">

                  <FormControl
                    style={{ padding: '9px 18px 9px 18px' }}
                    value={selectedModel.value}
                    onChange={handleChange}
                    name="commentFilter"
                    data-testid="container_Model_Filter"
                    as="select"
                  >
                    <option value="ByModel" data-testid="ByModel">
                      By Model
                    </option>
                    {commentsModels.map((option) => (
                      <option key={option.studyId} value={option.studyId} data-testid={option.studyId}>
                        {option.model}
                      </option>
                    ))}
                  </FormControl>
                </div>

              )
          }
          <div style={{ float: 'left' }}>
            <Tooltip title="Clear Filters" disableInteractive>
              <IconButton onClick={FilterOnClick} disabled={disableFilter} size="large">
                <FilterAltOffIcon />
              </IconButton>
            </Tooltip>
          </div>
          {
            canViewCommentToggle()
              ? (
                <div className="button-wrapper comment-filter">
                  {[{ id: COMMENT_TYPES.EXTERNAL, label: 'External' }, { id: COMMENT_TYPES.INTERNAL, label: 'Internal' }].map((item) => (
                    <Radio
                      item={item}
                      key={item.id}
                      checked={item.id === commentType}
                      onChange={onCommentTypeChange(item.id)}
                    />
                  ))}
                </div>
              ) : null
          }
          {
            canEditComment()
              ? (
                <>
                  <Stack direction="row" spacing={1}>
                    <ColorButton variant="outlined" data-testid="create-comment-btn" onClick={addNewComment}>
                      Add Comment
                    </ColorButton>
                    {showSpinner && <CircularProgress size={25} style={{ color: '#6495ED', marginTop: '3px' }} />}
                    <LightTooltip title="Download">
                      <span>
                        <Button
                          onClick={OnDownloadComment}
                          disabled={!rows.length || showSpinner || inEditeMode}
                          label="Download"
                          startIcon={<FileDownloadIcon style={{ pointerEvents: 'none' }} />}
                        />
                      </span>
                    </LightTooltip>
                  </Stack>
                </>
              )
              : null
          }
          {showModal && <DownloadCommentsModal showSpinner={showSpinnerWhileDownloadingComments} commentType={commentType} showModal={showModal} onConfirmDownloadComment={onConfirmDownloadComment} onCancelDownloadComments={onCancelDownloadComments} commentsModelsDownloadFilterComments={commentsModelsDownloadFilterComments} selectedValues={selectedValues} setSelectedValues={setSelectedValues} showDropdown={showDropdown} setShowDropdown={setShowDropdown} />}
        </Grid>
      </Grid>
      <Snackbar
        style={{ position: 'unset' }}
        open={showResponseMessage}
        autoHideDuration={2500}
        onClose={() => setShowResponseMessage(false)}
        message={showSnackbarForDownloadComments(snackbarMessage)}
      />
      {
        commentsLoading
          ? (
            <Row style={{ margin: '10px 0px 5px 32px' }} className="justify-content-center">
              <RBbtn variant="primary" disabled>
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                Loading Comments...
              </RBbtn>
            </Row>
          )
          : (
            <CommentsGrid
              classes={classes}
              rows={rows}
              page={page}
              rowsPerPage={rowsPerPage}
              onChange={onChange}
              study={study}
              getModel={getModel}
              getEmptyOrInitializedNotes={getEmptyOrInitializedNotes}
              loading={loading}
              onSave={onSave}
              onCancel={onCancel}
              user={user}
              canEditComment={canEditComment}
              onEdit={onEdit}
              onDeleteComment={onDeleteComment}
              CustomTablePagination={CustomTablePagination}
              onPageChange={onPageChange}
              onRowsPerPageChange={onRowsPerPageChange}
              ref={commentsGridRef}
            />
          )
      }

    </>
  );
};

export default CommentsTable;
