import React from 'react';
import { BorderAll } from '@styled-icons/material/BorderAll';
import { BorderClear } from '@styled-icons/material/BorderClear';
import { BorderBottom } from '@styled-icons/material/BorderBottom';
import { BorderTop } from '@styled-icons/material/BorderTop';
import { BorderLeft } from '@styled-icons/material/BorderLeft';
import { BorderRight } from '@styled-icons/material/BorderRight';

import {
  deleteColumn,
  deleteRow,
  deleteTable,
  insertTable,
  insertTableColumn,
  insertTableRow,
  TableToolbarButton,
} from '@udecode/plate';

import tooltip from '../tooltip/tooltip';

// eslint-disable-next-line arrow-body-style
const TableButtons = () => {
  return (
    <>
      <TableToolbarButton
        icon={<BorderAll />}
        transform={insertTable}
        tooltip={tooltip('Insert Table')}

      />
      <TableToolbarButton
        icon={<BorderClear />}
        transform={deleteTable}
        tooltip={tooltip('Delete Table')}
      />
      <TableToolbarButton
        icon={<BorderBottom />}
        transform={insertTableRow}
        tooltip={tooltip('Insert Table Row')}

      />
      <TableToolbarButton
        icon={<BorderTop />}
        transform={deleteRow}
        tooltip={tooltip('Delete Table Row')}
      />
      <TableToolbarButton
        icon={<BorderLeft />}
        transform={insertTableColumn}
        tooltip={tooltip('Insert Table Column')}
      />
      <TableToolbarButton
        icon={<BorderRight />}
        transform={deleteColumn}
        tooltip={tooltip('Delete Table Column')}
      />
    </>
  );
};

export default TableButtons;
