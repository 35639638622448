import React from 'react';
import { BryntumGantt } from '@bryntum/gantt-react';
import './Gantt.scss';
import './mphs.css';

const Gantt = function Gantt({ ganttConfig, ...props }) {
  return (
    <>
      <BryntumGantt {...ganttConfig} {...props} />
      <div className="noteDis">
        <span className="AvenirNextLTProRegular">Note: One year of timeline data is available (</span>

        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-slash-minus" viewBox="0 0 16 16">
          <path d="m1.854 14.854 13-13a.5.5 0 0 0-.708-.708l-13 13a.5.5 0 0 0 .708.708ZM4 1a.5.5 0 0 1 .5.5v2h2a.5.5 0 0 1 0 1h-2v2a.5.5 0 0 1-1 0v-2h-2a.5.5 0 0 1 0-1h2v-2A.5.5 0 0 1 4 1Zm5 11a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5A.5.5 0 0 1 9 12Z" />
        </svg>

        <span className="m-1 AvenirNextLTProRegular">6 months from current date).</span>
      </div>
      <div className="noteDis rnd-bdr">
        Note: Black symbols represent forecasted milestones.  Green symbols represent completed milestones.  Forecasts are subject to change.
      </div>
    </>
  )
}

export default Gantt;
