import React, { createContext, useState } from 'react';

export const FolderContext = createContext();

export const FolderProvider = function ({ children }) {
  const [selectedFolderName, setSelectedFolderName] = useState('');
  const [downloadFileName, setDownloadFileName] = useState('');
  const [deleteFileName, setDeleteFileName] = useState('');

  return (
    <FolderContext.Provider
      value={{
        selectedFolderName, setSelectedFolderName, setDownloadFileName, downloadFileName, deleteFileName, setDeleteFileName,
      }}
    >
      {children}
    </FolderContext.Provider>
  );
}

// export default GraphQLProvider
export const useFolder = () => {
  const context = React.useContext(FolderContext);

  if (context === undefined) {
    throw new Error(
      '`useFolder` hook must be used within a `FolderProvider` component',
    );
  }
  return context;
};
