import React, { useState, useEffect } from 'react';
import docsConfig from '../DocsVersionConfig.json';
import DocsVersionSelect from './DocsVersionSelectComponent';
import DocsMuiltiLangSelectComponent from './DocsMuiltiLangSelectComponent';
import { getPDFEmbededObj } from '../utils';

const { termsOfUse } = docsConfig;

export default ({ location: { state } }) => {
  // Initialize state variables to hold defaultDate and defaultLang
  const [defaultDate, setDefaultDate] = useState(termsOfUse[0].date);
  const [defaultLang, setDefaultLang] = useState(termsOfUse[0].lang);
  const [filteredTermsOfUse, setFilteredTermsOfUse] = useState(termsOfUse);
  const [fileEntry, setFileEntry] = useState(termsOfUse[0]);

  // useEffect to update the state variables when state changes
  useEffect(() => {
    if (state?.date) {
      const fileEntry = termsOfUse.find((x) => x.date === state.date && x.lang === defaultLang);
      const fileEntryLang = termsOfUse.find((x) => x.date === state.date);

      if (fileEntry) {
        // Check if the object with the new values exists
        setDefaultDate(state.date);
      } else if (fileEntryLang) {
        setDefaultDate(state.date);
        setDefaultLang(fileEntryLang.lang);
      }
    }
    if (state?.lang) {
      const fileEntry = termsOfUse.find(
        (x) => x.date === defaultDate && x.lang === state.lang,
      );
      if (fileEntry) {
        // Check if the object with the new values exists
        setDefaultLang(state.lang);
      }
    }
    // Filter the file entry based on the defaultDate and defaultLang
    setFileEntry(termsOfUse.find(
      (x) => x.date === defaultDate && x.lang === defaultLang,
    ))
    setFilteredTermsOfUse(termsOfUse.map((item) => ({
      ...item,
      disable: item.date !== defaultDate,
    })))
  }, [state, defaultLang, defaultDate]);
  return (
    <div>
      <DocsVersionSelect
        versionList={termsOfUse}
        defaultValue={fileEntry?.date}
      />
      <DocsMuiltiLangSelectComponent
        versionList={filteredTermsOfUse}
        defaultValue={fileEntry?.lang}
      />
      {getPDFEmbededObj('CrownLinkTermsOfUse', fileEntry)}
    </div>
  );
};
