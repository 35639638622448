import { AgGridReact } from 'ag-grid-react'
import React from 'react'

import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'

const BodyWeightChangeTable = function ({ gridOptions, divStyles }) {
  return (
    <div className="ag-theme-alpine" style={{ ...divStyles }}>
      <AgGridReact
        rowSelection="multiple"
        suppressRowClickSelection
        {...gridOptions}
      />
    </div>
  )
}

export default BodyWeightChangeTable
